/**
 * @fileoverview Provides a context-based system for managing process visualization settings
 * and UI state throughout the Fabublox application. This provider handles settings such as
 * stack visibility, 3D rendering, and process information panel state.
 */
import React, { createContext, useState, ReactNode } from 'react';
import { noOp } from '../../utils/empty-arrow-function-helper';

/**
 * Props interface for the ProcessSettingsProvider component.
 * 
 * @interface ProcessSettingsProviderProps
 * @property {ReactNode} children - Child components that will have access to the settings context
 * @property {boolean} [isModule] - Optional flag indicating if the process is a module, affecting initial stack visibility
 */
interface ProcessSettingsProviderProps {
    children: ReactNode;
    isModule?: boolean;
}

/**
 * Interface defining the shape of the settings context value.
 * Contains boolean flags and their setters for various process visualization settings.
 * 
 * @interface SettingsContextValue
 * @property {boolean} isStackHidden - Controls the visibility of the process stack
 * @property {Function} setIsStackHidden - Setter function for stack visibility
 * @property {boolean} isStack3D - Controls whether the stack is rendered in 3D
 * @property {Function} setIsStack3D - Setter function for 3D rendering
 * @property {boolean} isProcessInfoOpen - Controls the visibility of the process information panel
 * @property {Function} setIsProcessInfoOpen - Setter function for process info panel visibility
 * @property {boolean} showAllParameters - Controls whether to show all parameters in table view
 * @property {Function} setShowAllParameters - Setter function for toggling all parameters visibility
 * @property {boolean} splitWorksheet - Controls whether to split worksheet in table view
 * @property {Function} setSplitWorksheet - Setter function for toggling worksheet split
 * @property {boolean} smoothScroll - Controls whether to smooth scroll in the process view
 * @property {Function} setSmoothScroll - Setter function for toggling smooth scroll
 */
export interface ProcessSettingsContextType {
    isStack3D: boolean;
    setIsStack3D: (is3D: boolean) => void;
    isStackHidden: boolean;
    setIsStackHidden: (isHidden: boolean) => void;
    showAllParameters: boolean;
    setShowAllParameters: (show: boolean) => void;
    splitWorksheet: boolean;
    setSplitWorksheet: (split: boolean) => void;
    isProcessInfoOpen: boolean;
    setIsProcessInfoOpen: (isOpen: boolean) => void;
    smoothScroll: boolean;
    setSmoothScroll: (smooth: boolean) => void;
}

/**
 * Default values for the settings context.
 * Uses noOp for setter functions to avoid errors when accessed outside provider.
 */
const defaultValue: ProcessSettingsContextType = {
    isStackHidden: false,
    setIsStackHidden: noOp,
    isStack3D: false,
    setIsStack3D: noOp,
    isProcessInfoOpen: false,
    setIsProcessInfoOpen: noOp,
    showAllParameters: true,
    setShowAllParameters: noOp,
    splitWorksheet: false,
    setSplitWorksheet: noOp,
    smoothScroll: true,
    setSmoothScroll: noOp,
};

/**
 * Context instance for process settings. Provides access to visualization and UI settings
 * throughout the component tree.
 */
export const ProcessSettingsContext = createContext<ProcessSettingsContextType>(defaultValue);

/**
 * Provider component that manages process visualization and UI settings state.
 * Makes settings available to all child components through React Context.
 * 
 * @component
 * @param {ProcessSettingsProviderProps} props - Component props
 * @param {ReactNode} props.children - Child components that will have access to settings
 * @param {boolean} [props.isModule] - Optional flag indicating if the process is a module
 */
export const ProcessSettingsProvider: React.FC<ProcessSettingsProviderProps> = ({ children, isModule }) => {
    // Initialize state with isModule flag affecting initial stack visibility
    const [isStackHidden, setIsStackHidden] = useState(!!isModule);
    const [isStack3D, setIsStack3D] = useState(false);
    const [isProcessInfoOpen, setIsProcessInfoOpen] = useState(false);
    const [showAllParameters, setShowAllParameters] = useState(true);
    const [splitWorksheet, setSplitWorksheet] = useState(false);
    const [smoothScroll, setSmoothScroll] = useState(true);

    return (
        <ProcessSettingsContext.Provider value={{ 
            isStackHidden, setIsStackHidden, 
            isStack3D, setIsStack3D,
            isProcessInfoOpen, setIsProcessInfoOpen,
            showAllParameters, setShowAllParameters,
            splitWorksheet, setSplitWorksheet,
            smoothScroll, setSmoothScroll,
        }}>
            {children}
        </ProcessSettingsContext.Provider>
    );
};
