/**
 * @fileoverview ProcessTableGrid component provides a reusable data grid for the process table view.
 * It encapsulates all the MUI DataGridPro configuration and rendering logic, providing a consistent
 * interface for both split and non-split worksheet views.
 * 
 * Key Features:
 * - Configurable pagination
 * - Row selection handling
 * - Automatic height adjustment
 * - Custom row styling
 * - Section header support
 */

import React, { useMemo } from 'react';
import {
  DataGridPro,
  GridRowParams,
  GridPaginationModel,
  type GridApiPro,
  type GridApiCommon,
} from '@mui/x-data-grid-pro';
import { processTableStyles } from '../styles/TableView.styles';
import {
  getRowId,
  getRowClassName,
  getColumnDefs
} from '../utils/processTableViewUtils';
import { ProcessTableRow } from '../types/ProcessTableTypes';

/**
 * Props interface for the ProcessTableGrid component
 * @interface ProcessTableGridProps
 * 
 * @property {ProcessTableRow[]} rows - Array of rows to display in the grid
 * @property {React.MutableRefObject<GridApiPro & GridApiCommon<any, any>>} apiRef - Reference to the grid API for external control
 * @property {string | null} selectedBloxId - ID of the currently selected blox
 * @property {GridPaginationModel} paginationModel - Current pagination state
 * @property {Function} setPaginationModel - Function to update pagination state
 * @property {Function} onRowClick - Handler for row click events
 * @property {number} [rowCount] - Optional total row count for pagination
 * @property {boolean} [autoHeight] - Optional flag to enable auto-height
 * @property {Function} [getRowClassName] - Optional function to get row class name
 */
export interface ProcessTableGridProps {
  rows: ProcessTableRow[];
  apiRef: React.MutableRefObject<GridApiPro & GridApiCommon<any, any>>;
  selectedBloxId: string | null;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  onRowClick: (params: GridRowParams) => void;
  rowCount?: number;
  autoHeight?: boolean;
  getRowClassName?: (params: GridRowParams<ProcessTableRow>) => string;
}

/**
 * ProcessTableGrid Component
 * 
 * A wrapper around MUI's DataGridPro that provides consistent configuration and behavior
 * for the process table view. It handles both regular and section header rows, supports
 * pagination, and manages row selection.
 */
export const ProcessTableGrid: React.FC<ProcessTableGridProps> = ({
  rows,
  apiRef,
  selectedBloxId,
  paginationModel,
  setPaginationModel,
  onRowClick,
  rowCount,
  autoHeight = false,
  getRowClassName
}) => {
  // Memoize column definitions to prevent unnecessary re-renders
  const columns = useMemo(() => getColumnDefs(), []);

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      getRowClassName={getRowClassName}
      autoHeight={false}
      disableColumnMenu
      disableVirtualization={false}
      density="compact"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[25]}
      paginationMode="client"
      rowCount={rowCount ?? rows.length}
      disableRowSelectionOnClick
      showColumnVerticalBorder={true}
      showCellVerticalBorder={true}
      getRowHeight={() => 'auto'}
      sx={{
        ...processTableStyles.dataGrid,
        height: '100%',
        maxHeight: '100%',
        '& .MuiDataGrid-virtualScroller': {
          overflow: 'auto',
          maxHeight: '100%'
        }
      }}
      onRowClick={onRowClick}
    />
  );
}; 