/**
 * @fileoverview Provides a centralized factory system for creating new process blocks (Bloxes)
 * in the Fabublox application. This module consolidates all Blox type creators and provides
 * a single entry point for instantiating any type of process block with proper initialization.
 * 
 * The module supports a wide range of semiconductor fabrication processes including:
 * - Lithography processes (e-beam, optical, imprint, etc.)
 * - Deposition methods (ALD, CVD, evaporation, etc.)
 * - Etching processes (wet etch, dry etch, RIE, etc.)
 * - Thermal processes (RTA, baking, oxidation)
 * - Surface treatments and cleaning
 * - Substrate handling and bonding
 */
import { v4 as uuidv4 } from 'uuid'
import { AllBloxes, BloxTypes } from "../Data/BloxSchema/base-blox";
import { getCustomClean } from '../Data/BloxSchema/customclean';
import { getSpinCoat } from "../Data/BloxSchema/spin-coat";
import { getBake } from '../Data/BloxSchema/bake';
import { getMaskAlign } from '../Data/BloxSchema/mask-align';
import { getDevelop } from "../Data/BloxSchema/develop";
import { getRIE } from '../Data/BloxSchema/rie';
import { getSputter } from '../Data/BloxSchema/sputter';
import { getWetEtch } from '../Data/BloxSchema/wetetch';
import { getFlipStack } from '../Data/BloxSchema/flipstack';
import { getALD } from '../Data/BloxSchema/ald';
import { geteBeamEvap } from '../Data/BloxSchema/ebeamevap';
import { getThermalEvap } from '../Data/BloxSchema/thermalevap';
import { getPECVD } from '../Data/BloxSchema/pecvd';
import { getRCAClean } from '../Data/BloxSchema/rcaclean';
import { getStartBlox } from '../Data/BloxSchema/start-blox';
import { getDirectWriteLitho } from '../Data/BloxSchema/direct-write-litho';
import { getHMDSVapor } from '../Data/BloxSchema/HMDS-vapor';
import { getICPRIE } from '../Data/BloxSchema/icp-rie';
import { getLiftOff } from '../Data/BloxSchema/liftoff';
import { getStripResist } from '../Data/BloxSchema/strip-resist';
import { getSpinCoatResist } from '../Data/BloxSchema/spin-coat-resist';
import { getEbeamLitho } from '../Data/BloxSchema/ebeam-litho';
import { geteSubstrateStack } from '../Data/BloxSchema/substrate-stack';
import { getIonImplantation } from '../Data/BloxSchema/ion-implantation';
import { getDopeDiffusion } from '../Data/BloxSchema/doping-diffusion';
import { getPlasmaAsh } from '../Data/BloxSchema/plasma-ash';
import { getPlasmaDescum } from '../Data/BloxSchema/plasma-descum';
import { getIonMilling } from '../Data/BloxSchema/ion-milling';
import { getDropcast } from '../Data/BloxSchema/drop-cast';
import { getWetGrowth } from '../Data/BloxSchema/wet-growth';
import { getInterferenceLitho } from '../Data/BloxSchema/interference-litho';
import { getSpinDopant } from '../Data/BloxSchema/spin-coat-dopant';
import { getWaferBonding } from '../Data/BloxSchema/wafer-bonding';
import { getThermalOxide } from '../Data/BloxSchema/thermal-oxide';
import { getRTA } from '../Data/BloxSchema/rta';
import { getCMP } from '../Data/BloxSchema/cmp';
import { getLPCVD } from '../Data/BloxSchema/lpcvd';
import { getAPCVD } from '../Data/BloxSchema/apcvd';
import { getStepperLitho } from '../Data/BloxSchema/stepper-litho';
import { getCustomBlox } from '../Data/BloxSchema/customblox';
import { getMOCVD } from '../Data/BloxSchema/mocvd';
import { getMBE } from '../Data/BloxSchema/mbe';
import { getImprintLitho } from '../Data/BloxSchema/imprint-litho';
import { getScanningProbeLitho } from '../Data/BloxSchema/scanning-probe-litho';
import { getElectroplate } from '../Data/BloxSchema/electroplate';
import { getFloodExpose } from '../Data/BloxSchema/flood-expose';
import { getParyleneDep } from '../Data/BloxSchema/parylene-dep';
import { getSpinCoatUnified } from '../Data/BloxSchema/spin-coat-unified';

/**
 * Factory function that creates a new process block (Blox) of the specified type.
 * This function serves as the central point for instantiating any type of process
 * block in the Fabublox application, ensuring consistent initialization and proper
 * type handling.
 * 
 * @param {BloxTypes} bloxType - The type of process block to create
 * @param {number | string} stepNumber - The step number in the process sequence
 * @returns {AllBloxes} A new process block instance of the specified type
 * 
 * @throws Will return a default StartBlox if an unknown block type is provided
 * 
 * @remarks
 * Each block type is initialized with its specific parameters and default values.
 * The function handles all semiconductor fabrication processes including:
 * - Lithography (optical, e-beam, imprint, etc.)
 * - Deposition (ALD, CVD, PVD, etc.)
 * - Etching (wet, dry, RIE, etc.)
 * - Thermal processes
 * - Surface treatments
 * - Substrate handling
 */
export const getNewBlox = (bloxType: BloxTypes, stepNumber: number | string): AllBloxes => {
    switch (bloxType) {
        case BloxTypes.SubstrateStack:
            return geteSubstrateStack({stepNumber});
        case BloxTypes.CustomBlox:
            return getCustomBlox({stepNumber});
        case BloxTypes.CustomClean:
            return getCustomClean({stepNumber});
        case BloxTypes.RCAClean:
            return getRCAClean({stepNumber});
        case BloxTypes.Bake:
            return getBake({stepNumber});
        case BloxTypes.SpinCoat:
            return getSpinCoat({stepNumber});
        case BloxTypes.SpinCoatResist:
            return getSpinCoatResist({stepNumber});
        case BloxTypes.SpinCoatUnified:
            return getSpinCoatUnified({stepNumber});
        case BloxTypes.SpinDopant:
            return getSpinDopant({stepNumber});
        case BloxTypes.DropCast:
            return getDropcast({stepNumber});
        case BloxTypes.WetGrowth:
            return getWetGrowth({stepNumber});
        case BloxTypes.MaskAlign:
            return getMaskAlign({stepNumber});
        case BloxTypes.StepperLitho:
            return getStepperLitho({stepNumber});
        case BloxTypes.InterferenceLitho:
            return getInterferenceLitho({stepNumber});        
        case BloxTypes.DirectWriteLitho:
            return getDirectWriteLitho({stepNumber});
        case BloxTypes.EbeamLitho:
            return getEbeamLitho({stepNumber});
        case BloxTypes.ImprintLitho:
            return getImprintLitho({stepNumber});
        case BloxTypes.ScanningProbeLitho:
            return getScanningProbeLitho({stepNumber});
        case BloxTypes.FloodExpose:
            return getFloodExpose({stepNumber});
        case BloxTypes.Develop:
            return getDevelop({stepNumber});
        case BloxTypes.ICPRIE:
            return getICPRIE({stepNumber});
        case BloxTypes.RIE:
            return getRIE({stepNumber});
        case BloxTypes.IonMilling:
            return getIonMilling({stepNumber});
        case BloxTypes.PlasmaAsh:
            return getPlasmaAsh({stepNumber});
        case BloxTypes.PlasmaDescum:
            return getPlasmaDescum({stepNumber});
        case BloxTypes.HMDSVapor:
            return getHMDSVapor({stepNumber});
        case BloxTypes.Sputter:
            return getSputter({stepNumber});
        case BloxTypes.ALD:
            return getALD({stepNumber});
        case BloxTypes.MOCVD:
            return getMOCVD({stepNumber});
        case BloxTypes.EbeamEvap:
            return geteBeamEvap({stepNumber});
        case BloxTypes.ThermalEvap:
            return getThermalEvap({stepNumber});
        case BloxTypes.MBE:
            return getMBE({stepNumber});
        case BloxTypes.PECVD:
           return getPECVD({stepNumber});
        case BloxTypes.ThermalOxide:
            return getThermalOxide({stepNumber});
        case BloxTypes.Electroplate:
            return getElectroplate({stepNumber}); 
        case BloxTypes.ParyleneDep:
            return getParyleneDep({stepNumber});       
        case BloxTypes.LPCVD:
            return getLPCVD({stepNumber});
        case BloxTypes.APCVD:
            return getAPCVD({stepNumber});
        case BloxTypes.Wetetch:
            return getWetEtch({stepNumber});
        case BloxTypes.CMP:
            return getCMP({stepNumber});
        case BloxTypes.LiftOff:
            return getLiftOff({stepNumber});
        case BloxTypes.FlipStack:
            return getFlipStack({stepNumber});
        case BloxTypes.StripResist:
            return getStripResist({stepNumber});
        case BloxTypes.StartBlox:
            return getStartBlox();
        case BloxTypes.IonImplantation:
            return getIonImplantation({stepNumber});
        case BloxTypes.DopeDiffusion:
            return getDopeDiffusion({stepNumber});
        case BloxTypes.RTA:
            return getRTA({stepNumber});
        case BloxTypes.WaferBonding:
            return getWaferBonding({stepNumber});

        default:
            return {
                bloxType: BloxTypes.StartBlox,
                name: "PROCESS START",
                id: uuidv4(),
                customFields: {}
            }
    }
}
