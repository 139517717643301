import {
  ButtonGroup,
  Tag,
  Icon,
  Colors,
  Tooltip,
  H5,
} from '@blueprintjs/core'
import { useLocation } from 'react-router-dom'; 
import { Row } from '../Layout/layouts'
import { IconNames } from '@blueprintjs/icons'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as LogoIcon } from '../components/svg/STARTSTACK.svg';
import { ContactDialog } from '../dialogs/ContactDialog';
import { IconButton } from '@mui/material';
import { Navbar } from '@blueprintjs/core';
import { Column } from '../Layout/layouts';
import { HamburgerMenu } from './HamburgerMenu'
import React from 'react'
import { FabuSearch } from './FabuSearch';
import { PageNamesSlug } from '../Data/enums';
import { PAGE_INFO } from '../utils/constants';


export const PageHeader: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const matchedSlug = Object.values(PageNamesSlug).find((slug) =>
    pathname.includes(slug)
  );

  const pageHeader = matchedSlug ? PAGE_INFO[matchedSlug].pageName : undefined;

  const pixNo = 30;
  const iconStyle = {
    width: `${pixNo}px`,
    height: `${pixNo}px`,
  };

  const betaStyle = {
    backgroundColor: 'var(--background-light)',
    color: 'var(--text-primary)', 
    fontSize: "var(--font-size-lg)", 
    height: '36px',
    width: '92px',
    marginLeft: '5px',
    marginTop: '2px',
  };
  
  return (
  <Navbar className='tw-bg-[var(--background-header)] tw-flex tw-justify-center tw-items-center tw-w-full tw-z-50 tw-min-h-[50px]'>
      <Row>
        <Column className = 'tw-absolute tw-left-2 tw-flex tw-items-center tw-top-0' style={{ marginRight: 'auto', height: '50px' }}>
          <Row style={{margin: 'auto'}}>
              <HamburgerMenu />
              <ButtonGroup>
                {pageHeader && <H5 style={{margin: 'auto 20px', color: 'var(--text-white)'}}>{pageHeader}</H5>}
              </ButtonGroup>
          </Row>
        </Column>
        {matchedSlug !== PageNamesSlug.Fabubase && <Column>
          <FabuSearch />
        </Column>}
        <Column className='tw-absolute tw-flex tw-justify-center tw-right-0 tw-top-0'>
        <Row>
          <ContactDialog>
            {(handleOpen: () => void) => (
              <IconButton onClick={handleOpen} className = 'sm:tw-mr-2' style={{ height: 50 , color: 'var(--text-white)'}}>
                <MailOutlineIcon sx={{ height: 30, width: 30, stroke: 'var(--background-header)', strokeWidth:0.5}}/>
              </IconButton>
            )}
          </ContactDialog>  
          <Tooltip className= 'tw-mt-1.5 tw-mr-2' content={<>
                  <p>Fabublox is in beta testing, and feedback is encouraged.
                  <br/>Please use the mail button to share your thoughts with us.</p>
                  <p>Thank you!</p>
                  </>}>
                <Tag icon={<LogoIcon style={iconStyle} />} style={betaStyle} large={true}>Beta</Tag>
          </Tooltip>
        </Row>
        </Column>
        
      </Row>
    </Navbar>
    
  )
}

export const envelopeIcon = <Icon size={25} color="var(--primary-blue)" icon={IconNames.ENVELOPE} />;
