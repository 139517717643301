import { AllBloxes, LayerProperties } from "../Data/BloxSchema/base-blox";
import { Section } from "../__generated__/Process";
import { Units } from "../Data/enums";
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { ViewMode } from "../components/IFrameComponents/hooks/useFabuUIState";

// Type definitions for listview items, only for rendering view on client side
export type SectionedItem = 
    | { type: 'sectionHeader'; sectionName: string; sectionId: string }
    | { type: 'blox'; blox: AllBloxes; stepNumber: number; sectionId: string };

/**
 * Prepares a flat array of sectioned items from process sections and bloxes.
 * This function is crucial for the list view as it:
 * 1. Maintains the hierarchical structure while flattening for display
 * 2. Assigns sequential step numbers to bloxes based on their actual position
 * 3. Preserves section relationships for collapsible UI
 * 
 * @param {Section[]} sections - Array of process sections containing blox IDs
 * @param {AllBloxes[]} bloxes - Array of all blox objects
 * @returns {SectionedItem[]} Flattened array of section headers and bloxes with proper ordering
 */
export const prepareSectionedItems = (processSections: Section[], processBloxes: AllBloxes[]): SectionedItem[] => {
  const items: SectionedItem[] = [];
  let stepNumber = 0;

  processSections.forEach((section) => {
    items.push({
      type: 'sectionHeader',
      sectionId: section.sectionId,
      sectionName: section.sectionName || 'Untitled Section'
    });

    section.bloxIds.forEach((bloxId) => {
      const blox = processBloxes.find(b => b.id === bloxId);
      if (blox) {
        items.push({
          type: 'blox',
          blox,
          stepNumber: stepNumber++,
          sectionId: section.sectionId
        });
      }
    });
  });

  return items;
};

/**
 * Ensures backward compatibility for layer properties and initializes default values
 * @param layers Array of layer properties to process
 * @returns Processed array of layer properties with defaults
 */
export const ensureLayerCompatibility = (layers: LayerProperties[]): LayerProperties[] => {
  return layers.map((layer: LayerProperties, index: number) => {
    if (!layer.materialId) {
      layer.materialId = uuidv4();
    }
    if (!layer.layerSimulationThickness) {
      layer.layerSimulationThickness = index === 0 ? 1000 : 100;
    }
    if (!layer.layerSimulationThicknessUnit) {
      layer.layerSimulationThicknessUnit = Units.NM;
    }
    return layer;
  });
};

/**
 * Represents the different view modes available in the process editor.
 * The process editor supports three mutually exclusive view modes:
 * - List View: A compact, linear representation of process steps
 * - Table View: A spreadsheet-like view for detailed parameter comparison
 * - Standard View: The default view showing visual process flow
 */
export interface ProcessViewMode {
  /** Whether the process is being shown in list view mode */
  isListView: boolean;
  /** Whether the process is being shown in table view mode */
  isTableView: boolean;
  /** Whether the process is being shown in standard view mode */
  isStandardView: boolean;
}

/**
 * Core props shared across all process view modes.
 * These are the essential props needed to render any view of the process.
 */
export interface ProcessViewProps {
  processBloxes: AllBloxes[];
  processSections: Section[];
  selectedBloxId?: string;
  closedSections: { [key: string]: boolean };
  containerRef: React.RefObject<HTMLDivElement>;
  processIsReadOnly: boolean;
}

/**
 * Props specific to the list view mode.
 * List view focuses on a linear, compact representation of process steps
 * with collapsible sections and step navigation.
 */
export interface ListViewProps extends ProcessViewProps {
  initialScrollTarget: string | null;
  onScrollComplete: () => void;
  onSelectItem: (item: SectionedItem) => void;
  onToggleSection: (sectionId: string) => void;
  bloxSVGs: React.ReactElement[];
}

/**
 * Props specific to the table view mode.
 * Table view displays process data in a spreadsheet format,
 * optimized for parameter comparison and bulk editing.
 */
export interface TableViewProps extends ProcessViewProps {
  height: string;
  showAllParameters: boolean;
  splitWorksheet: boolean;
}

/**
 * Props specific to the standard view mode.
 * Standard view shows the traditional process flow visualization
 * with detailed blox representations and section management.
 */
export interface StandardViewProps extends ProcessViewProps {
  bloxSVGs: React.ReactElement[];
  handleBloxActions: (bloxId: string, sectionId: string, idx: number) => {
    onClickBlox: () => void;
    onDelete: () => void;
    handleInsertBlox?: (item: any) => void;
    handleInsertModule?: (item: any) => void;
  };
  getEmptySectionTarget: (sectionId: string, sectionIdx: number) => JSX.Element;
  bloxDisplayProps: {
    hideStack: boolean;
    processIsReadOnly?: boolean;
  };
  setClosedSections: (value: React.SetStateAction<{ [key: string]: boolean }>) => void;
  setProcessSections: (value: React.SetStateAction<Section[]>) => void;
}

/**
 * Props for the renderProcessView function, combining all possible view mode props
 * with a mode indicator to determine which view to render.
 */
export interface RenderProcessViewProps {
  mode: ViewMode;
  props: ListViewProps | TableViewProps | StandardViewProps;
}

/**
 * Determines the current view mode from URL parameters.
 * The view mode affects how the process is displayed and interacted with:
 * - List View ('list'): Shows a compact, scrollable list of process steps
 * - Table View ('table'): Shows a detailed grid view of process parameters
 * - Standard View (default): Shows the traditional process flow visualization
 * 
 * @param queryParams - URLSearchParams object containing the 'view' parameter
 * @returns ProcessViewMode object indicating which view mode is active
 */
export const getProcessViewMode = (queryParams: URLSearchParams): ProcessViewMode => {
  const viewMode = queryParams.get('view');
  return {
    isListView: viewMode === 'list',
    isTableView: viewMode === 'table',
    isStandardView: viewMode !== 'list' && viewMode !== 'table'
  };
};

/**
 * Finds the last blox ID from previous sections.
 * This is used when creating drop targets for empty sections,
 * to determine where new bloxes should be inserted.
 * 
 * @param {Section[]} processSections - Array of all process sections
 * @param {number} currentSectionIdx - Index of the current section
 * @returns {{ lastBloxId: string | null, bloxIdx: number }} The last blox ID and its index
 */
export const findLastBloxInPreviousSections = (
  processSections: Section[],
  currentSectionIdx: number,
  processBloxes: AllBloxes[]
): { lastBloxId: string | null; bloxIdx: number } => {
  let lastBloxId: string | null = null;
  
  // Find the last blox ID from previous sections
  for (let i = currentSectionIdx - 1; i >= 0; i--) {
    const section = processSections[i];
    if (section && section.bloxIds.length > 0) {
      lastBloxId = section.bloxIds[section.bloxIds.length - 1];
      break; // Exit the loop once the last non-empty section's last bloxId is found.
    }
  }

  const bloxIdx = lastBloxId ? processBloxes.findIndex(blox => blox.id === lastBloxId) : -1;
  return { lastBloxId, bloxIdx };
};

/**
 * Handles view mode transitions and updates UI state accordingly.
 * This function manages:
 * 1. View mode change detection
 * 2. Section expansion for selected items
 * 3. Scroll target setup for maintaining focus
 * 
 * @param {ProcessViewMode} currentView - The new view mode state
 * @param {ProcessViewMode} previousView - The previous view mode state
 * @param {string | undefined} selectedBloxId - Currently selected blox ID
 * @param {Section[]} processSections - Array of process sections
 * @param {(value: React.SetStateAction<{ [key: string]: boolean }>) => void} setClosedSections - Function to update closed sections
 * @param {(value: string | null) => void} setInitialScrollTarget - Function to set scroll target
 * @param {(value: boolean) => void} setShouldScrollToSelectedBlox - Function to trigger scrolling to selected blox
 * @returns {{ shouldUpdate: boolean, newPreviousView: ProcessViewMode }} Update status and new previous view
 */
export const handleViewModeTransition = (
  currentView: ProcessViewMode,
  previousView: ProcessViewMode,
  selectedBloxId: string | undefined,
  processSections: Section[],
  setClosedSections: (value: React.SetStateAction<{ [key: string]: boolean }>) => void,
  setInitialScrollTarget: (value: string | null) => void,
  setShouldScrollToSelectedBlox?: (value: boolean) => void
): { shouldUpdate: boolean; newPreviousView: ProcessViewMode } => {
  // Check if view actually changed
  const hasViewChanged = previousView.isListView !== currentView.isListView ||
                        previousView.isTableView !== currentView.isTableView ||
                        previousView.isStandardView !== currentView.isStandardView;

  if (!hasViewChanged) {
    return { shouldUpdate: false, newPreviousView: previousView };
  }

  // Handle view transitions for list and standard views
  if (selectedBloxId) {
    const sectionIdx = processSections.findIndex(section => section.bloxIds.includes(selectedBloxId));
    const section = processSections[sectionIdx];
    if (section) {
      // Ensure section is expanded
      setClosedSections(prevState => ({
        ...prevState,
        [section.sectionId]: false
      }));

      // Set up scroll target for list view
      if (currentView.isListView) {
        setInitialScrollTarget(selectedBloxId);
      }
      
      // Trigger scroll for standard view with proper timing
      if (currentView.isStandardView && setShouldScrollToSelectedBlox) {
        // First set to false to ensure the effect triggers even if it was already true
        setShouldScrollToSelectedBlox(false);
        
        // Use a small delay to ensure the view has switched and rendered
        setTimeout(() => {
          setShouldScrollToSelectedBlox(true);
        }, 50);
      }
    }
  }

  return { shouldUpdate: true, newPreviousView: currentView };
};