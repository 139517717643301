/**
 * @fileoverview Process initialization hook for the IFrame application.
 * This custom hook manages fetching and initializing process data from the API.
 * It handles authentication, loading states, error handling, and data processing,
 * providing a streamlined interface for loading processes into the application context.
 * Used by the IFrameProcessInitializer component to coordinate process data loading.
 */

import { useCallback, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIFrameProcess } from '../IFrameProcessContext';
import { notifyProcessLoaded, notifyError } from '../../../utils/iframeMessaging';

// Import any necessary utilities and constants
const BASE_FABUBLOX_API_URL = process.env.REACT_APP_REST_API || '';

// Mock implementation - replace with actual implementation
const initializeProcessState = (result: any) => {
  // Simple implementation that returns the minimum needed structure
  const defaultedBloxes = result.bloxes || [];
  const tempSections = result.sections || [];
  
  return {
    defaultedBloxes,
    sections: {
      tempSections,
      initialOpenSections: {}
    },
    metadata: {
      processName: result.processName || 'Untitled Process',
      username: result.username || 'Unknown User',
      processId: result.processId || 'unknown-id',
      isPrivate: result.isPrivate || true,
      additionalOwners: result.additionalOwners || [],
      desc: result.desc || '',
      reference: result.reference || '',
      groups: result.groups || []
    }
  };
};

interface ProcessInitializationOptions {
  onInitializationComplete?: (data: any) => void;
  signature?: string | null;
}

export function useIFrameProcessInitialization(options: ProcessInitializationOptions = {}) {
  const { state, actions } = useIFrameProcess();
  
  // Reference for tracking the current fetch request
  const abortControllerRef = useRef<AbortController | null>(null);
  
  // Function to load a process by ID with proper request cancellation
  const loadProcess = useCallback(async (processId: string, signature?: string | null) => {
    // Special handling for the 'new' process - don't apply the check for 'new'
    if (processId === 'new' && state.lastLoadedProcessId === 'new') {
      return;
    }
    
    // Always load the process regardless of lastLoadedProcessId
    
    // Cancel any in-flight requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Create a new abort controller for this request
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    
    actions.setLoading(true);
    
    try {
      // Set current process being loaded
      actions.setLastLoadedId(processId);
      actions.setProcessId(processId);
      
      // Check if this is a request for a new empty process
      if (processId === 'new') {
        // Create empty process template
        const emptyProcess = {
          processName: 'New Process',
          username: 'Anonymous User',
          processId: 'new',
          isPrivate: true,
          additionalOwners: [],
          desc: 'A new empty process',
          reference: '',
          groups: [],
          bloxes: [], // No steps initially
          sections: [] // No sections initially
        };
        
        // Initialize empty process state
        const { defaultedBloxes, sections, metadata } = initializeProcessState(emptyProcess);
        
        // Simulate network delay to ensure state stability and prevent flashing
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // Check if request was aborted during the delay
        if (signal.aborted) {
          throw new Error('Process load cancelled');
        }
        
        // Update process data using context actions
        actions.setProcessData({
          processBloxes: defaultedBloxes,
          processSections: sections.tempSections,
          processName: metadata.processName
        });
        
        // Notify parent that process has been loaded
        notifyProcessLoaded('new', 'New Process');
        
        // Call the onInitializationComplete callback with the process data
        if (options.onInitializationComplete) {
          options.onInitializationComplete({
            processId: 'new',
            processName: metadata.processName,
            processBloxes: defaultedBloxes,
            processSections: sections.tempSections
          });
        }
        
        return;
      }
      
      // For iframe viewer, we're always in viewer mode
      const headers = { 'Content-Type': 'application/json' };
      
      // Construct the URL with processId and signature if available
      const fetchUrl = `${BASE_FABUBLOX_API_URL}/api/iframeprocess/read/${processId}`;
      
      // Make the fetch request with the abort signal
      const response = await fetch(fetchUrl, { 
        headers, 
        signal: signal 
      });

      // Check if request was aborted
      if (signal.aborted) {
        throw new Error('Process load cancelled');
      }

      if (!response.ok) {
        const error = `Failed to fetch process: ${response.statusText}`;
        notifyError(error);
        throw new Error(error);
      }
      
      const result = await response.json();
      
      // Check if request was aborted after receiving data
      if (signal.aborted) {
        throw new Error('Process load cancelled');
      }

      if (result?.bloxes) {
        // Initialize process state
        const { defaultedBloxes, sections, metadata } = initializeProcessState(result);

        // Update process data using context actions
        actions.setProcessData({
          processBloxes: defaultedBloxes,
          processSections: sections.tempSections,
          processName: metadata.processName
        });
        
        // Notify parent that process has been loaded
        notifyProcessLoaded(metadata.processId, metadata.processName);
        
        // Get the first blox ID if available
        const firstBloxId = defaultedBloxes.length > 0 ? defaultedBloxes[0].id : undefined;
        
        // Call the onInitializationComplete callback with process data and first blox ID
        if (options.onInitializationComplete) {
          options.onInitializationComplete({
            processId: metadata.processId,
            processName: metadata.processName,
            processBloxes: defaultedBloxes,
            processSections: sections.tempSections,
            firstBloxId
          });
        }
      }
    } catch (error: any) {
      if (error instanceof Error && error.name === 'AbortError') {
        return; // Don't update state for aborted requests
      }
      
      notifyError(error instanceof Error ? error.message : String(error));
      
      // Update error state
      actions.setError(error instanceof Error ? error.message : String(error));
      
      // Call the callback with error indication
      if (options.onInitializationComplete) {
        options.onInitializationComplete({
          processId: processId,
          processName: 'Error loading process',
          processBloxes: [],
          processSections: [],
          error: error instanceof Error ? error.message : String(error)
        });
      }
    } finally {
      // Only update loading state if this request wasn't aborted
      if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
        actions.setLoading(false);
        // Clear the abort controller reference
        abortControllerRef.current = null;
      }
    }
  }, [
    state.lastLoadedProcessId,
    state.processBloxes.length,
    actions,
    options.onInitializationComplete
  ]);

  // Function to reload the current process
  const reloadCurrentProcess = useCallback(() => {
    if (state.processId) {
      // Signal that a reload is happening
      if (options.onInitializationComplete) {
        options.onInitializationComplete({
          processId: state.processId,
          processName: '', 
          processBloxes: [],
          processSections: [],
          firstBloxId: undefined,
          isReloading: true
        });
      }
      
      // Force reload by resetting lastLoadedProcessId
      actions.setLastLoadedId('');
      loadProcess(state.processId, options.signature);
    }
  }, [state.processId, actions, loadProcess, options.onInitializationComplete, options.signature]);
  
  // Clean up function for unmounting
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    };
  }, []);
  
  return {
    loadProcess,
    reloadCurrentProcess,
    isLoading: state.isLoading,
    error: state.error,
    processId: state.processId,
    processBloxes: state.processBloxes,
    processSections: state.processSections
  };
} 