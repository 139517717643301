/**
 * @fileoverview ProcessListViewContent is a core component that manages the rendering of process items in a list format.
 * It serves as the main content container for the ProcessListView, handling both section headers and individual items.
 * 
 * Key Features:
 * - Section-based content organization
 * - Animated section collapse/expand
 * - Flexible item rendering with customization
 * - Type-safe through generic typing
 * - Efficient section grouping
 * 
 * Component Hierarchy:
 * ProcessListView
 * └─ ProcessListViewContent (This Component)
 *    └─ ProcessListContainer[]
 *       ├─ Section Headers
 *       │  ├─ Collapse Controls
 *       │  └─ Section Information
 *       └─ Process Items (In Collapse Animation Wrapper)
 *          ├─ BloxListItem (For process block rendering)
 *          │  ├─ Block Metadata
 *          │  ├─ SVG Visualization
 *          │  └─ Property Display
 *          └─ Custom Item Content (When using renderItem)
 * 
 * Animation Features:
 * - Smooth section collapse/expand with 200ms duration
 * - Custom easing using cubic-bezier
 * - Hardware-accelerated transitions
 * 
 * State Flow:
 * 1. Groups items by section using reduce
 * 2. Manages section visibility through closedSections
 * 3. Delegates rendering to ProcessListContainer
 * 4. Wraps section items in Collapse for animation
 * 
 * Performance Considerations:
 * - Uses React.memo for optimal re-rendering
 * - Efficient section grouping with reduce
 * - Hardware-accelerated animations
 * 
 * @component
 * @template T - The type of items being rendered
 */

import React from 'react';
import { Box } from '@mui/material';
import { ProcessListContainer } from './ProcessListContainer';
import { Collapse } from '@mui/material';

/**
 * Props interface for the ProcessListViewContent component.
 * Defines the complete set of properties required to render the list content.
 * 
 * @interface ProcessListViewContentProps
 * @template T - The type of items being rendered
 * 
 * @property {T[]} items - Array of items to be rendered in the list
 * @property {React.Key | null} selectedItemKey - Key of the currently selected item
 * @property {(item: T) => React.Key} getItemKey - Function to generate unique keys for items
 * @property {(item: T) => string} getSectionId - Function to determine section ID for an item
 * @property {(item: T) => boolean} isSectionHeader - Function to identify section header items
 * @property {{ [key: string]: boolean }} closedSections - Map of section IDs to their closed state
 * @property {(item: T) => string} getItemPrimaryText - Required function to get item's primary display text unless renderItem is provided
 * @property {(item: T) => string} [getItemSecondaryText] - Optional function to get item's secondary display text
 * @property {(item: T) => React.ReactNode} [renderItem] - Optional custom render function for complete item control
 * @property {(sectionId: string) => void} onSectionHeaderToggle - Callback when a section header is toggled
 * @property {(item: T) => void} onItemSelect - Callback when an item is selected
 */
export interface ProcessListViewContentProps<T> {
  items: T[];
  selectedItemKey: React.Key | null;
  getItemKey: (item: T) => React.Key;
  getSectionId: (item: T) => string;
  isSectionHeader: (item: T) => boolean;
  closedSections: { [key: string]: boolean };
  getItemPrimaryText: (item: T) => string;
  getItemSecondaryText?: (item: T) => string;
  renderItem?: (item: T) => React.ReactNode;
  onSectionHeaderToggle: (sectionId: string) => void;
  onItemSelect: (item: T) => void;
}

/**
 * ProcessListViewContent Component
 * Renders the main content area of the process list view, managing both section headers
 * and individual items with efficient state management and rendering optimizations.
 * 
 * Features:
 * - Section-based content organization
 * - Flexible item rendering with customization
 * - Efficient state management
 * - Type-safe through generics
 * - Performance optimized with React.memo
 * 
 * @component
 * @template T - The type of items being rendered
 */
export const ProcessListViewContent = <T,>({
  items,
  selectedItemKey,
  getItemKey,
  getSectionId,
  isSectionHeader,
  closedSections,
  getItemPrimaryText,
  getItemSecondaryText,
  renderItem,
  onSectionHeaderToggle,
  onItemSelect,
}: ProcessListViewContentProps<T>) => {
  // Group items by section
  const sections = items.reduce((acc, item) => {
    const sectionId = getSectionId(item);
    if (!acc[sectionId]) {
      acc[sectionId] = {
        header: null,
        items: []
      };
    }
    
    if (isSectionHeader(item)) {
      acc[sectionId].header = item;
    } else {
      acc[sectionId].items.push(item);
    }
    
    return acc;
  }, {} as { [key: string]: { header: T | null; items: T[] } });

  return (
    <>
      {Object.entries(sections).map(([sectionId, { header, items: sectionItems }]) => {
        const isOpen = !closedSections[sectionId];

        // Render section header if it exists
        const headerElement = header && (
          <ProcessListContainer
            key={getItemKey(header)}
            item={header}
            isHeader={true}
            isOpen={isOpen}
            sectionId={sectionId}
            itemKey={getItemKey(header)}
            isSelected={getItemKey(header) === selectedItemKey}
            getItemPrimaryText={getItemPrimaryText}
            getItemSecondaryText={getItemSecondaryText}
            renderItem={renderItem}
            onSectionHeaderToggle={onSectionHeaderToggle}
            onItemSelect={onItemSelect}
          />
        );

        // Render section items with animation
        const itemElements = sectionItems.map((item) => (
          <ProcessListContainer
            key={getItemKey(item)}
            item={item}
            isHeader={false}
            isOpen={isOpen}
            sectionId={sectionId}
            itemKey={getItemKey(item)}
            isSelected={getItemKey(item) === selectedItemKey}
            getItemPrimaryText={getItemPrimaryText}
            getItemSecondaryText={getItemSecondaryText}
            renderItem={renderItem}
            onSectionHeaderToggle={onSectionHeaderToggle}
            onItemSelect={onItemSelect}
          />
        ));

        return (
          <Box key={sectionId}>
            {headerElement}
            <Collapse 
              in={isOpen} 
              timeout={200}
              easing={{
                enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
                exit: 'cubic-bezier(0.4, 0, 0.2, 1)'
              }}
            >
              {itemElements}
            </Collapse>
          </Box>
        );
      })}
    </>
  );
};

// Optimize re-rendering with React.memo
export default React.memo(ProcessListViewContent) as <T>(props: ProcessListViewContentProps<T>) => JSX.Element;