import React, { useCallback, useState } from "react";
import { useDrag } from "react-dnd";
import { AllBloxes, BloxTypes } from "../Data/BloxSchema/base-blox";
import { Draggables } from "../Data/enums";
import { Column, Row } from "../Layout/layouts";
import { BloxImage, BloxImageProps } from "./BloxImage";
import { BetweenBlox } from "./BetweenBlox";
import { Stack } from "./Stack";
import { ContextMenu, Divider, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { BloxDialog } from "../dialogs/BloxDialog";
import { ExportDialog } from "../dialogs/ExportDialog";
import { ReportProblemDialog } from "../dialogs/ReportProblemDialog";
import { StateAndSetter } from "../hooks/state/parameter-context";
import { BloxInsertItem } from "./BloxDraggable";
import { ModuleInsertItem } from "./ModuleDraggable";
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from "lodash";
export interface ProcessBloxProps {
    copiedBloxState: StateAndSetter<AllBloxes | null>;
    bloxData: AllBloxes; // data for blox in this step
    bloxSVG: JSX.Element;
    processBloxes: AllBloxes[];
    handleInsertBlox?: (item: BloxInsertItem) => void;
    handleInsertModule?: (item: ModuleInsertItem) => void;
    onDelete?: (id: string) => void;
    onClickBlox: () => void;
    handleSplitSection?: () => void;
    showDropTarget: boolean;
    hideStack: boolean;
    leftMargin: boolean;
    isSelected: boolean;
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[];
    processIsReadOnly: boolean;
}

/**
 * Component for rendering a single blox in a process. Handles rendering a menu for
 * copying, saving, exporting, reporting, and deleting the blox. Also handles rendering a
 * stack of blox for this step when applicable. Finally, handles rendering a BetweenBlox
 * component for this step when applicable.
 *
 * @param {Object} props
 * @param {StateAndSetter<AllBloxes | null>} props.copiedBloxState - state for whether a blox is currently being copied
 * @param {AllBloxes} props.bloxData - data for blox in this step
 * @param {JSX.Element} props.bloxSVG - SVG for this blox
 * @param {Function} [props.handleInsertBlox] - function to call when user inserts a blox
 * @param {Function} [props.handleInsertModule] - function to call when user inserts a module
 * @param {Function} [props.onDelete] - function to call when user deletes this blox
 * @param {Function} props.onClickBlox - function to call when user clicks on this blox
 * @param {boolean} props.leftMargin - whether to render a left margin
 * @param {boolean} props.showDropTarget - whether to render a drop target
 * @param {boolean} props.hideStack - whether to hide the stack
 * @param {boolean} props.isSelected - whether this blox is currently selected
 * @param {boolean} props.processIsReadOnly - whether the process is in read-only mode
 */
export const ProcessBlox: React.FC<ProcessBloxProps> = ({copiedBloxState, handleSplitSection, processBloxes, bloxData, bloxSVG, handleInsertBlox, handleInsertModule, onDelete, onClickBlox, leftMargin, showDropTarget, hideStack, isSelected, processSections, processIsReadOnly}) => {
    const [isBloxDialogOpen, setIsBloxDialogOpen] = useState(false);
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const isStartBlox = bloxData.bloxType === BloxTypes.StartBlox;

    const bloxIdx = processBloxes.findIndex(blox => blox.id === bloxData.id)

    const handleOpenDialog = useCallback(() => {setIsBloxDialogOpen(true)}, [setIsBloxDialogOpen]);

    const canDrag = !isStartBlox && !!handleInsertBlox;
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

    const closeDialogCallback = useCallback(() => setIsExportDialogOpen(false), []);

    const [{ isDragging }, dragBlox] = useDrag(() => ({
        type: Draggables.Blox,
        item: { bloxType: bloxData.bloxType, id: bloxData.id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
        canDrag: () => canDrag
    }), [bloxData, canDrag]);

    const [copiedBlox, setCopiedBlox] = copiedBloxState;

    /**
     * Renders a menu for the given blox in the process.
     * The menu includes options for copying, saving, exporting, reporting, and deleting the blox.
     * @returns {JSX.Element} The rendered menu
     */
    const renderMenu = () => (
        <Menu>
            <MenuItem icon={IconNames.Duplicate} text="Copy Blox" onClick={() => { setCopiedBlox({...cloneDeep(bloxData), id: uuidv4()}) }} />
            <MenuItem icon="plus" text="Save to Library" onClick={handleOpenDialog} />
            <MenuItem icon="export" text="Export Image" onClick={() => setIsExportDialogOpen(true)} />
            <MenuItem
                icon={IconNames.ERROR}
                text="Report Image Problem"
                onClick={() => setIsReportDialogOpen(true)}
            />
            <Divider />
            <MenuItem icon="delete" text="Delete" onClick={() => onDelete && onDelete(bloxData.id)} intent="danger" />
        </Menu>
    );

    const bloxImageProps: BloxImageProps = {
        selected: isSelected,
        bloxType: bloxData.bloxType,
        displayName: bloxData.name,
        stepNumber: bloxIdx,
        noDrag: !canDrag,
        toolName: bloxData.toolName ?? undefined,
        moduleId: bloxData.moduleId ?? undefined,
        moduleName: bloxData.moduleName ?? undefined
    };

    return (
        <>
            <Row>
                <Column 
                    id={`blox-${bloxData.id}`}
                    onClick={() => { onClickBlox(); }} 
                    style={{marginLeft: leftMargin ? '20px' : '0px', marginBottom: 'auto', marginTop: 'auto'}}
                >
                    <ContextMenu
                        disabled={isStartBlox}
                        content={
                            renderMenu()
                        }>
                        <Row style={{ "marginBottom": "10px", "marginTop": "10px" }}>

                            <div ref={dragBlox} style={{ opacity: isDragging ? 0.5 : 1 }}>
                                <BloxImage {...bloxImageProps} />
                            </div>
                            
                        </Row>
                        {hideStack ? "" : <Stack 
                            bloxName={bloxData.name ? `${bloxIdx + 1}. ${bloxData.name}` : undefined} 
                            bloxSVG={bloxSVG} 
                            renderMenu={renderMenu} 
                            blox={bloxData}
                            processBloxes={processBloxes}
                            bloxIdx={bloxIdx}
                            processSections={processSections}
                            hideMenu={processIsReadOnly}
                        />}
                    </ContextMenu>
                </Column>
                <Column>
                    <Row style={{height: '100%', "marginTop": "10px" }}>
                        <BetweenBlox copiedBlox={copiedBlox} showDropTarget={showDropTarget} handleInsertBlox={handleInsertBlox} handleInsertModule={handleInsertModule} handleSplitSection={handleSplitSection}/>
                    </Row>
                </Column>
            </Row>
            <ExportDialog filename={bloxData.name.trim()} isOpen={isExportDialogOpen} closeDialog={closeDialogCallback} bloxes={processBloxes} initialIndex={bloxIdx} processSections={processSections} />
            <BloxDialog isOpen={isBloxDialogOpen} setIsOpen={setIsBloxDialogOpen} bloxData={bloxData} />
            <ReportProblemDialog bloxes={processBloxes} bloxIdx={bloxIdx} isOpen={isReportDialogOpen} setIsOpen={(isOpen: boolean) => setIsReportDialogOpen(isOpen)} />
        </>
    );
}