import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { IColor } from "../../utils/Color";
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { ShadowmaskDialogContent } from "../../dialogs/ShadowMaskDialogContent";
import { getDefaultColor } from "../material-mappings";

export interface ParyleneDep extends BaseBlox {
    //EXPERIMENTAL
    paryleneType: string | null;
    weight: number | null;
    weightUnit: Units | null;
    depRate: number | null;
    depRateUnit: Units | null;
    depTime: number | null;
    depTimeUnit: Units | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    sublTemp: number | null;
    sublTempUnit: Units | null;
    pyroTemp: number | null;
    pyroTempUnit: Units | null;


    // DISPLAY
    doubleSided: boolean | null;
    selectiveGrowth : boolean | null;
    layerLabelsToGrow: string[] | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    hasShadowMask: boolean | null;
    shadowMaskPattern: string | null;
    shadowMaskPatternDisabled: () => boolean | null;
    shadowMaskInvertPattern: boolean;
}

export const paryleneDepDisplay: DisplayMap = {
    paryleneType: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Parylene Type",
        placeholder: "e.g. Parylene N,C,D,F",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    weight: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor Weight",
        placeholder: "Enter dimer weight",
        isNumber: true,
        units: [Units.MICROGRAM,Units.MILLIGRAM,Units.GRAM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.NMPERMIN,Units.NMPERHOUR,Units.UMPERHOUR,Units.MILPERHOUR],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    depTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Time",
        placeholder: "Enter deposition time",
        isNumber: true,
        units: [Units.MILLISEC,Units.SEC,Units.MIN,Units.HOUR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Base Pressure",
        placeholder: "Enter base pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.BAR],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    sublTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sublimation Temperature",
        placeholder: "Enter vaporizer temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    pyroTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Pyrolysis Furnace Temperature",
        placeholder: "Enter pyrolysis temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },


    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY,Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Thickness",
        order: 1,
        tabs: [Tab.DISPLAY]
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness (%)",
        order: 2,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 3,
        tabs: [Tab.DISPLAY]
    },
    separator: {
        fieldType: DisplayFieldTypes.Separator,
        order: 10,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    hasShadowMask: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Shadow Mask",
        order: 11,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    shadowMaskPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Shadow Mask Pattern",
        order: 12,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: ShadowmaskDialogContent,
        isOptionalSemifab: true,
    },
    shadowMaskInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 13,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
}

export const getParyleneDep = ({stepNumber}: BloxArgs): ParyleneDep => ({
    name: `Parylene Deposition ${stepNumber}`,
    id: uuidv4(),
    bloxType: BloxTypes.ParyleneDep,
    paryleneType: null,
    weight: null,
    weightUnit: Units.GRAM,
    thickness: null,
    thicknessUnit: Units.MICRON,
    depRate: null,
    depRateUnit: Units.UMPERHOUR,
    depTime: null,
    depTimeUnit: Units.MIN,
    chamberPressure: null,
    chamberPressureUnit: Units.TORR,
    sublTemp: null,
    sublTempUnit: Units.CELSIUS,
    pyroTemp: null,
    pyroTempUnit: Units.CELSIUS,
    commentField: null,
    customFields: {},
    doubleSided: false,
    layerColor: getDefaultColor(BloxTypes.ParyleneDep),
    layerThickness: 5,
    sidewallThickness: 100,
    layerLabel: null,
    selectiveGrowth: false,
    layerLabelsToGrow: [],
    hasShadowMask: false,
    shadowMaskPattern: "1,2,4,2,1",
    shadowMaskPatternDisabled: function () {
        return !this.hasShadowMask;
    },
    shadowMaskInvertPattern: false,
});
