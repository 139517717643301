import { useCallback } from 'react';

interface UseSectionStateProps {
  closedSections?: { [key: string]: boolean };
  openSections?: { [key: string]: boolean };
  onToggleSection?: (sectionId: string) => void;
}

/**
 * Custom hook for managing section states in ListView components.
 * Core part of the ListView state management system that:
 * 
 * Responsibilities:
 * - Manages section collapse/expand states
 * - Syncs with parent component's section states
 * - Provides section toggle functionality
 * - Integrates with section manager for operations
 * 
 * Integration:
 * - Used by ProcessListView as primary section state manager
 * - Controls ProcessListViewHeader's breadcrumb navigation
 * - Drives ProcessListViewContent's section visibility
 * 
 * State Flow:
 * Input: closedSections, openSections, onToggleSection
 * Output: {closedSectionsState, handleSectionToggle}
 * 
 * @hook
 */
export const useSectionState = ({
  closedSections = {},
  openSections,
  onToggleSection = () => undefined
}: UseSectionStateProps) => {
  // Convert openSections to closedSections format if provided
  const effectiveClosedSections = openSections
    ? Object.fromEntries(
        Object.entries(openSections).map(([sectionId, isOpen]) => [
          sectionId,
          !isOpen
        ])
      )
    : closedSections;

  const handleSectionToggle = useCallback((sectionId: string) => {
    onToggleSection(sectionId);
  }, [onToggleSection]);

  return {
    closedSectionsState: effectiveClosedSections,
    handleSectionToggle,
  };
}; 