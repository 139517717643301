import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { getDefaultColor } from "../material-mappings";

export interface DropCast extends BaseBlox {
    material: string | null;
    solution: string | null;
    dispenseVolume: number | null;
    dispenseVolumeUnit: Units | null;
    filmThickness: number | null;
    filmThicknessUnit: Units | null;
    fillGaps: boolean | null;

    layerColor: IColor | null;
    layerThickness: number | null;
    layerLabel: string | null;
}

export const dropCastDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Solute Material",
        placeholder: "Enter material name",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    solution: {
        fieldType: DisplayFieldTypes.Input,
        label: "Solvents and Concentrations",
        placeholder: "e.g. 5 wt% in toluene",
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    dispenseVolume: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dispense Volume",
        placeholder: "Enter dispense volume",
        isNumber: true,
        order: 3,
        units: [Units.NL,Units.UL,Units.ML],
        tabs: [Tab.EXPERIMENTAL],
    },
    filmThickness: {
      fieldType: DisplayFieldTypes.Input,
      label: "Film Thickness",
      placeholder: "Enter film thickness",
      isNumber: true,
      order: 4,
      units: [Units.NM, Units.MICRON, Units.MM],
      tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },

    //DISPLAY
    fillGaps: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Fill Gaps",
        order: 0,
        tabs: [Tab.DISPLAY],
        defaultValue: true
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },
}

export const getDropcast = ({stepNumber}: BloxArgs): DropCast => ({
        name: `Drop-Cast ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.DropCast,
        material: null,
        fillGaps: true,
        solution: null,
        dispenseVolume: null,
        dispenseVolumeUnit: Units.UL,
        filmThickness: null,
        filmThicknessUnit: Units.MICRON,
        commentField: null,
        layerColor: getDefaultColor(BloxTypes.DropCast),
        layerThickness: 10,
        layerLabel: null,
        customFields: { },
});
