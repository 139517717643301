import { useState, useEffect } from "react";
import {
  Button,
  Callout,
  Checkbox,
  Classes,
  Dialog,
  Intent,
  Collapse,
  Tag,
} from "@blueprintjs/core";
import { BloxRef } from "../../hooks/material-targets";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { Column, Row } from "../../Layout/layouts";
import { getBloxRefDisplayString } from "./hooks/use-material-prompts";

interface WarningDialogMultipleSourcesProps {
  isOpen: boolean;
  currentBlox: AllBloxes;
  pendingUpdate: {
    newMaterialName: string;
    previousMaterialName: string;
    newColor?: any;
    bloxRefs: BloxRef[];
  } | null;
  onClose: () => void;
  onConfirm: (selectedUpdateSteps?: BloxRef[]) => void;
}

export default function WarningDialogMultipleSources({
  isOpen,
  currentBlox,
  pendingUpdate,
  onClose,
  onConfirm: confirmMaterialUpdate,
}: WarningDialogMultipleSourcesProps) {
  const currentBloxId = currentBlox.id;
  const [selectedBloxRefs, setSelectedBloxRefs] = useState<BloxRef[]>([]);
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});
  const [processSections] = useFabuState("processSections");

  // Tracks whether we've "selected all" or not.
  // This state is purely for toggling the button text/behavior and does NOT track partial user changes.
  const [areAllSelected, setAreAllSelected] = useState(false);

  useEffect(() => {
    // Whenever the dialog opens or updates, ensure currentBlox is always selected.
    if (pendingUpdate?.bloxRefs) {
      setSelectedBloxRefs(
        pendingUpdate.bloxRefs.filter((bloxRef) => bloxRef.bloxId === currentBloxId)
      );
    }

    // Default all sections to expanded
    setExpandedSections(
      processSections.reduce((acc, section) => {
        acc[section.sectionId] = true;
        return acc;
      }, {} as { [key: string]: boolean })
    );
  }, [pendingUpdate, currentBloxId, processSections]);

  const toggleSelection = (bloxRef: BloxRef) => {
    // Prevent toggling the forced currentBlox
    if (bloxRef.bloxId === currentBloxId) return;

    setSelectedBloxRefs((prev) =>
      prev.some((ref) => ref.bloxId === bloxRef.bloxId)
        ? prev.filter((ref) => ref.bloxId !== bloxRef.bloxId)
        : [...prev, bloxRef]
    );
  };

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({ ...prev, [sectionId]: !prev[sectionId] }));
  };

  const toggleSelectAll = () => {
    if (!pendingUpdate) return;

    // Always keep the currentBlox selected
    const currentBloxOnly = pendingUpdate.bloxRefs.filter(
      (bloxRef) => bloxRef.bloxId === currentBloxId
    );
    // All except currentBlox
    const nonDisabledBloxRefs = pendingUpdate.bloxRefs.filter(
      (bloxRef) => bloxRef.bloxId !== currentBloxId
    );

    if (areAllSelected) {
      // Deselect all except currentBlox
      setSelectedBloxRefs(currentBloxOnly);
    } else {
      // Select all plus currentBlox
      setSelectedBloxRefs([...currentBloxOnly, ...nonDisabledBloxRefs]);
    }

    // Flip the toggle
    setAreAllSelected(!areAllSelected);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={`Switch Material`}
    >
      <div className={Classes.DIALOG_BODY} style={{ maxWidth: "40rem" }}>
        <p style={{ fontWeight: 600 }}>
          Multiple Material Sources Found
        </p>
        <p style={{ marginBottom: "3em" }}>
          Select process steps to replace <Tag>{pendingUpdate?.previousMaterialName}</Tag> with <Tag intent={Intent.PRIMARY}>{pendingUpdate?.newMaterialName}</Tag>.
        </p>
        
        <Button
          onClick={toggleSelectAll}
          icon={!areAllSelected ? "tick" : "cross"}
          style={{ marginBottom: "1em" }}
          disabled={!pendingUpdate || pendingUpdate.bloxRefs.length <= 1}
        >
          {areAllSelected ? "Deselect All" : "Select All"}
        </Button>

        <div
          style={{
            maxHeight: "250px",
            overflowY: "auto",
            border: "1px solid #ccc",
            padding: "0.5em",
            borderRadius: "4px",
          }}
        >
          {processSections.map((section) => {
            const sectionBloxRefs = pendingUpdate?.bloxRefs.filter((bloxRef) =>
              section.bloxIds.includes(bloxRef.bloxId)
            ) || [];

            return (
              <div key={section.sectionId} style={{ marginBottom: "1em" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    cursor: sectionBloxRefs.length > 0 ? "pointer" : "not-allowed",
                    opacity: sectionBloxRefs.length > 0 ? 1 : 0.5,
                    display: "flex",
                    alignItems: "center",
                    padding: "0.5em",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    gap: "0.5em",
                  }}
                  onClick={() => sectionBloxRefs.length > 0 && toggleSection(section.sectionId)}
                >
                  {sectionBloxRefs.length > 0 && (
                    <Button
                      minimal
                      small
                      icon={expandedSections[section.sectionId] ? "caret-down" : "caret-right"}
                    />
                  )}
                  <span>{section.sectionName || `Section ${section.sectionId}`}</span>
                </div>
                <Collapse
                  isOpen={
                    expandedSections[section.sectionId] && sectionBloxRefs.length > 0
                  }
                >
                  <ul style={{ listStyle: "none", padding: 0, margin: "0.5em 0 0 0" }}>
                    {sectionBloxRefs.map((bloxRef) => (
                      <li key={bloxRef.bloxId} style={{ marginBottom: "0.5em" }}>
                        <div
                          style={{ display: "flex", alignItems: "center", gap: "0.5em" }}
                        >
                          <Checkbox
                            checked={selectedBloxRefs.some(
                              (ref) => ref.bloxId === bloxRef.bloxId
                            )}
                            onChange={() => toggleSelection(bloxRef)}
                            disabled={bloxRef.bloxId === currentBloxId}
                            style={{ marginBottom: 0 }}
                          />
                          <span
                            style={{
                              fontWeight:
                                bloxRef.bloxId === currentBloxId ? "bold" : "normal",
                            }}
                          >
                            {getBloxRefDisplayString(bloxRef.stepNumber, bloxRef.bloxName)}
                          </span>
                          <span
                            className={`${Classes.TEXT_MUTED} ${Classes.TEXT_SMALL}`}
                            style={{ fontSize: "0.85em" }}
                          >
                            {bloxRef.isTarget ? "Target" : "Source"}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </div>
            );
          })}
        </div>

        <Column className={Classes.DIALOG_FOOTER_ACTIONS} style={{ marginTop: "1em" }}>
        <Callout intent={Intent.WARNING} style={{ margin: "0 0 1em 0" }}>
          <strong>Warning:</strong> Replacing a source without updating its targets may require manual updates.
        </Callout>
          <Row style={{ justifyContent: "flex-end" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={() =>
              confirmMaterialUpdate(selectedBloxRefs.length ? selectedBloxRefs : undefined)
            }
          >
            {selectedBloxRefs.length ? "Update Selected" : "Update All"}
          </Button>
          </Row>
        </Column>
      </div>
    </Dialog>
  );
}
