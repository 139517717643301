import React from 'react';
import { Box, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormFieldWithCopyProps } from './types';

/**
 * A form field component with a copy to clipboard button
 */
const FormField: React.FC<FormFieldWithCopyProps> = ({
  field,
  isSubmitting,
  copiedField,
  handleCopy
}) => (
  <>
    <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
      {field.icon}
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {field.label} {field.required === false && "(optional)"}
      </Typography>
    </Box>
    <Box sx={{ position: 'relative' }}>
      <TextField
        fullWidth
        placeholder={field.placeholder}
        value={field.value}
        onChange={(e) => field.setValue(e.target.value)}
        variant="outlined"
        size="small"
        disabled={isSubmitting}
        sx={{ mb: 2 }}
        multiline={field.multiline}
        rows={field.rows}
        helperText={field.helperText}
        required={field.required}
      />
    </Box>
  </>
);

export default FormField; 