import { Typography } from "@mui/material";
import JobPosting, { KeyInformationItem } from "../../components/Careers/JobPosting";
import { InfoList } from "../../components/Careers/InfoList";
import { WhyJoinFabuBlox } from "../../components/Careers/WhyJoinFabuBlox";
import { ApplicationDialog } from "../../components/Careers/ApplicationDialog";

export function SemiconductorEngineerPage() {
    const keyInformationItems: KeyInformationItem[] = [
        {
            label: "Location",
            value: "Boston Office or Hybrid",
        },
        {
            label: "Title",
            value: "Semiconductor Fabrication Engineer • Product and Strategy",
        },
        {
            label: "Experience",
            value: "3+ years",
        },
        {
            label: "Type",
            value: "Full or Part time",
        }
    ];

    const aboutTheRole = (
        <>
          <Typography variant="h6" className="tw-font-semibold">
            About the Role
          </Typography>
          <Typography className="tw-my-4">
            Are you passionate about advancing semiconductor innovation and redefining how the industry collaborates, innovates, and learns? FabuBlox is seeking a semiconductor fabrication engineer to support our Product and Strategy roadmap. You’ll play a pivotal role in shaping our product’s strategy, supporting our semiconductor fabrication market entry, and empowering the next generation of engineers through innovative educational solutions.
          </Typography>
          <Typography className="tw-my-4">
            This role isn’t just about semiconductor expertise — it’s about ownership, innovation, and impact. You’ll work directly with the founders, shape the roadmap, and contribute to building an innovative collaboration platform for the rapidly-evolving semiconductor industry.
          </Typography>
        </>
      );
      

    const jobSpecs = <>
       <InfoList
      title="Technical Experience"
      items={[
        "3+ years of semiconductor or similar nanofabrication experience with expertise in process design and execution.",
        "Hands-on cleanroom experience and familiarity with thin film deposition, etching, and patterning technologies.",
        "Familiarity with fundamentals of semiconductor and fabrication physics.",
        "Experience with commercial simulation software preferred."
      ]}
    />

<InfoList
      title="You Will"
      items={[
        "Help shape our core product strategy in the semiconductor fabrication market, inform new feature development.",
        "Work closely with our simulation engineers to implement new functionality.",
        "Engage directly with customers and support new user onboarding.",
        "Translate customer feedback into actionable insights to refine the platform.",
        "Design and develop interactive educational content and learning modules tailored to university programs as well as industrial workforce development.",
        "Collaborate with top educators and contribute to governmental initiatives. Harness the interactive FabuBlox process design interface to help create a first-of-its-kind virtual learning environment."
      ]}
    />

<InfoList
      title="Bonus Points"
      items={[
        "Experience in working with medium-to-large scale foundries; having led or participated in a chip tapeout process.",
        "Prior experience in semiconductor education.",
        "Semiconductor industry experience in a hardware start-up or large company.",
        "Basic programming skills and understanding of algorithmic logic.",
        "Previous role at a SaaS company."
      ]}
    />
    </>;

    const jobPostingProps = {
        keyInformationItems,
        jobTitle: "Semiconductor Fabrication Engineer • Product and Strategy"
    };

    return (
        <JobPosting {...jobPostingProps}>
            {aboutTheRole}
            <ApplicationDialog />
            {jobSpecs}
            <WhyJoinFabuBlox />
        </JobPosting>
    );
} 
