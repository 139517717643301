import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { TemplateCard } from './TemplateCard';
import { TemplateDetails } from './TemplateDetails';
import { TemplateFilters } from './TemplateFilters';
import { ProcessTemplate, TemplatesBaseProps } from './types';
import { processTemplates } from './data';
import { useIFrameProcess } from '../IFrameProcessContext';

/**
 * Main TemplatesSection component that renders the templates UI
 */
export const TemplatesSection: React.FC<TemplatesBaseProps> = ({
  iframeStyles,
  sizeConfig,
  isMobile,
  showTemplates,
  setShowTemplates,
  onLoadTemplate,
  onStartNewProcess = () => {/* No operation */},
  hideTitle = false,
  showToast,
  selectedProcessId: propSelectedProcessId
}) => {
  // Get process state from context (if available)
  const processContext = useIFrameProcess();
  
  // Use process ID from context or fall back to prop
  const contextProcessId = processContext?.state?.processId || null;
  const effectiveProcessId = contextProcessId || propSelectedProcessId;
  
  // Local state for search and filtering
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  
  // State for template details modal
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [templateForDetailView, setTemplateForDetailView] = useState<ProcessTemplate | null>(null);
  
  // Initialize selectedTemplate based on effectiveProcessId if available
  const [selectedTemplate, setSelectedTemplate] = useState<ProcessTemplate | null>(() => {
    if (effectiveProcessId) {
      const matchingTemplate = processTemplates.find(template => template.id === effectiveProcessId);
      return matchingTemplate || null;
    }
    return null;
  });
  
  // Update selectedTemplate when effectiveProcessId changes
  useEffect(() => {
    if (effectiveProcessId) {
      const matchingTemplate = processTemplates.find(template => template.id === effectiveProcessId);
      if (matchingTemplate && (!selectedTemplate || selectedTemplate.id !== effectiveProcessId)) {
        setSelectedTemplate(matchingTemplate);
      }
    }
  }, [effectiveProcessId, selectedTemplate]);
  
  // Get history from router
  const routerHistory = useHistory();

  // Filter templates based on search query and category
  const filteredTemplates = processTemplates.filter(template => {
    // First apply category filter if selected
    if (selectedCategory && template.category !== selectedCategory) {
      return false;
    }
    
    // Then apply search query filter
    if (!searchQuery) return true;
    
    const query = searchQuery.toLowerCase();
    return (
      template.name.toLowerCase().includes(query) ||
      template.description.toLowerCase().includes(query) ||
      template.category.toLowerCase().includes(query) ||
      template.tags.some(tag => tag.toLowerCase().includes(query))
    );
  });

  // Get unique categories for filter chips
  const categories = Array.from(new Set(processTemplates.map(template => template.category)));
  
  // Handler for opening template details
  const handleTemplateClick = (template: ProcessTemplate) => {
    setTemplateForDetailView(template);
    setDetailsOpen(true);
  };

  // Handler for selecting a category
  const handleCategorySelect = (category: string) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };
  
  // Function to handle when Launch Template is clicked
  const handleLaunchTemplate = useCallback(() => {
    if (!templateForDetailView) return;
    
    // Update local state immediately
    setSelectedTemplate(templateForDetailView);
    
    try {
      // Use the context to set process ID if available, otherwise use prop callback
      if (processContext?.actions?.setProcessId) {
        // If we have access to the context actions, set the process ID
        processContext.actions.setProcessId(templateForDetailView.id);
      }
      
      // Always call the onLoadTemplate callback for backward compatibility
      onLoadTemplate(templateForDetailView.id);
      
      // Notify user with toast if available
      if (showToast && typeof showToast === 'function') {
        showToast(`Loading process: ${templateForDetailView.name}`);
      } else if (showToast && typeof showToast === 'object' && showToast.message) {
        showToast.message(`Loading process: ${templateForDetailView.name}`);
      }
      
      // Close the details modal
      setDetailsOpen(false);
    } catch (error) {
      // Report error via toast instead of console
      if (showToast && typeof showToast === 'function') {
        showToast(`Error loading template: ${error instanceof Error ? error.message : String(error)}`);
      } else if (showToast && typeof showToast === 'object' && showToast.message) {
        showToast.message(`Error loading template: ${error instanceof Error ? error.message : String(error)}`);
      }
    }
  }, [templateForDetailView, onLoadTemplate, processContext, showToast]);
  
  // If templates are not shown, return null
  if (!showTemplates) return null;
  
  const templatesSection = {
    ...iframeStyles.templatesSection,
    height: 'auto',
    minHeight: '100px',
    maxHeight: '400px',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '16px',
    marginBottom: '16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  };

  const templateGrid = {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '12px',
    justifyContent: 'flex-start',
    padding: '8px 4px',
    height: 'auto',
    maxHeight: 'calc(400px - 80px)',
    overflowY: 'auto',
    backgroundColor: 'white',
  };

  return (
    <Box 
      sx={{
        position: 'relative',
        zIndex: 5,
        width: '100%',
        marginBottom: 0,
        borderRadius: 0,
        borderTop: '1px solid rgba(0, 0, 0, 0.08)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box sx={templatesSection}>
        {/* Header with title - only show if hideTitle is false */}
        {!hideTitle && (
          <Box sx={{
            ...iframeStyles.collapsedHeader,
            padding: '6px 12px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            backgroundColor: '#f5f5f5',
            animation: 'slideDown 0.3s ease-in-out',
            '@keyframes slideDown': {
              '0%': {
                transform: 'translateY(-100%)',
                opacity: 0
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1
              }
            }
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '4px'
            }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: '1rem',
                  fontWeight: 600,
                  color: '#1565C0',
                }}
              >
                Process Templates
              </Typography>
            </Box>
          </Box>
        )}
        
        {/* Search and filter area */}
        <TemplateFilters 
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
          isMobile={isMobile}
        />

        {/* Template grid - scrollable container */}
        <Box sx={templateGrid}>
          {filteredTemplates.length === 0 ? (
            <Box sx={{ py: 1, textAlign: 'center', width: '100%' }}>
              <Typography variant="body1" color="textSecondary">
                No templates found matching "{searchQuery}"
                {selectedCategory && ` in category "${selectedCategory}"`}
              </Typography>
            </Box>
          ) : (
            filteredTemplates.map((template, index) => (
              <TemplateCard
                key={template.id}
                template={template}
                index={index}
                isSelected={Boolean((selectedTemplate && template.id === selectedTemplate.id) || 
                            (effectiveProcessId && template.id === effectiveProcessId))}
                selectedCategory={selectedCategory}
                onSelectTemplate={handleTemplateClick}
                onSelectCategory={handleCategorySelect}
              />
            ))
          )}
        </Box>
      </Box>

      {/* Template Details Modal */}
      <TemplateDetails
        template={templateForDetailView}
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        onLaunch={handleLaunchTemplate}
      />
    </Box>
  );
}; 