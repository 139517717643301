/**
 * @fileoverview ProcessTableView is the main component for displaying process data in a tabular format.
 * It serves as the container for the table view functionality, managing the data grid, section navigation,
 * and view states. The component supports both unified and split worksheet views, with dynamic pagination
 * and section-based navigation.
 * 
 * Component Hierarchy:
 * ProcessTableView
 * ├── TableViewHeader
 * │   └── SectionButton (with tooltips)
 * └── ProcessTableGrid
 *     ├── DataGrid (MUI X-Data-Grid-Pro)
 *     └── Custom Grid Components
 *         ├── Cell Renderers
 *         └── Column Definitions
 * 
 * Key Features:
 * - Section-based navigation with breadcrumb header
 * - Split/Unified worksheet views
 * - Dynamic row generation based on process data
 * - Integrated selection and pagination
 * - Resize handling for optimal display
 */

import React, {
    useMemo,
    useState,
    useCallback,
  } from 'react';
  import {
    GridRowParams,
    useGridApiRef,
    type GridApiPro,
    type GridApiCommon,
  } from '@mui/x-data-grid-pro';
  import { LicenseInfo } from '@mui/x-license-pro';
  import { useFabuState } from '../../hooks/state/use-fabu-state';
  import { processTableStyles } from './styles/TableView.styles';
  import TableViewHeader from './TableViewHeader';
  import { tableStyles } from './styles/TableView.styles';
  import { 
    useResizeObserver
  } from './utils/processTableViewScrollUtils';
  import { ProcessTableViewProps } from './types/ProcessTableTypes';
  import {
    generateSectionRows,
  } from './utils/processTableViewUtils';
  import { ProcessTableGrid } from './DataGrids/ProcessTableGrid';
  import { useProcessTableState } from './hooks/useProcessTableState';
  import { useLocation } from 'react-router-dom';
  
  // Set up the license key for MUI X-Data-Grid-Pro
  LicenseInfo.setLicenseKey(
    '92c7753f884dd3c5242616f9a718d48aTz0xMDY3MDUsRT0xNzY5NDcxOTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
  );
  
  /**
   * ProcessTableView Component
   * Main container component for the table view of process data.
   * Manages the display of process data in a tabular format with section navigation.
   * 
   * @component
   * @param {ProcessTableViewProps} props - Component properties
   * @param {Array} props.processBloxes - Array of process blocks to display
   * @param {Array} props.processSections - Array of sections for navigation
   * @param {number} [props.height=400] - Height of the table view container
   * @param {boolean} [props.showAllParameters=true] - Whether to show all parameters
   * @param {boolean} [props.splitWorksheet=false] - Whether to split the view by sections
   * @returns {JSX.Element} The rendered table view component
   */
  const ProcessTableView: React.FC<ProcessTableViewProps> = ({
    processBloxes,
    processSections,
    height = 400,
    showAllParameters = true,
    splitWorksheet = false
  }): JSX.Element => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const viewMode = queryParams.get('view');
    const isTableView = viewMode === 'table';

    // State management using custom hooks
    const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
    const [activeTab, setActiveTab] = useState(0);
    // Use double type assertion to handle both GridApiPro and GridApiCommon
    const apiRef = useGridApiRef() as unknown as React.MutableRefObject<GridApiPro & GridApiCommon<any, any>>;
  
    /**
     * Generate section rows based on process data
     * Memoized to prevent unnecessary recalculations
     */
    const sectionRows = useMemo(() => 
      generateSectionRows(processBloxes, processSections, showAllParameters), 
      [processBloxes, processSections, showAllParameters]
    );
    
    /**
     * Flatten all section rows into a single array for unified view
     */
    const allRows = useMemo(() => Object.values(sectionRows).flat(), [sectionRows]);
  
    /**
     * Custom hook for managing table state including pagination and section navigation
     */
    const {
      currentSection,
      paginationModel,
      setPaginationModel,
      scrollToSection,
    } = useProcessTableState({
      processBloxes,
      processSections,
      selectedBloxId,
      apiRef,
      allRows,
      isTableView
    });
  
    // Handle resize events to prevent layout issues
    useResizeObserver();
  
    /**
     * Handle row click events
     * Updates the selected block ID if the clicked row is not a section header
     */
    const handleRowClick = useCallback((params: GridRowParams) => {
      if (!params.row.isSectionHeader) {
        setSelectedBloxId(params.id.toString());
      }
    }, [setSelectedBloxId]);
  
    return (
      <div style={{ ...processTableStyles.root, height }}>
        {!splitWorksheet && (
          <TableViewHeader
            sections={processSections}
            currentSection={currentSection || processSections[0]?.sectionId || ''}
            onSectionChange={scrollToSection}
          />
        )}
  
        {splitWorksheet ? (
          <>
            <TableViewHeader
              sections={processSections}
              currentSection={processSections[activeTab]?.sectionId || ''}
              onSectionChange={(sectionId: string) => {
                const newIndex = processSections.findIndex(
                  (s) => s.sectionId === sectionId
                );
                if (newIndex >= 0) setActiveTab(newIndex);
              }}
            />
            <ProcessTableGrid
              rows={sectionRows[processSections[activeTab]?.sectionId] || []}
              apiRef={apiRef}
              selectedBloxId={selectedBloxId}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              onRowClick={handleRowClick}
              rowCount={sectionRows[processSections[activeTab]?.sectionId]?.length || 0}
              autoHeight={false}
              getRowClassName={(params) => {
                const classes = [];
                if (params.row.isSectionHeader) {
                  classes.push('section-header-row');
                }
                if (params.id === selectedBloxId) {
                  classes.push('selected-row');
                }
                return classes.join(' ');
              }}
            />
          </>
        ) : (
          <div style={tableStyles.gridContainer}>
            <ProcessTableGrid
              rows={allRows}
              apiRef={apiRef}
              selectedBloxId={selectedBloxId}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              onRowClick={handleRowClick}
              getRowClassName={(params) => {
                const classes = [];
                if (params.row.isSectionHeader) {
                  classes.push('section-header-row');
                }
                if (params.id === selectedBloxId) {
                  classes.push('selected-row');
                }
                return classes.join(' ');
              }}
            />
          </div>
        )}
      </div>
    );
  };
  
  export default ProcessTableView;