import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

// Base styles for common elements
export const baseStyles = {
  // Layout patterns
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  // Grid patterns
  grid: {
    main: {
      overflow: 'hidden'
    },
    virtualScroller: {
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingTop: '0px'
    },
    cell: {
      padding: '2px 4px',
      whiteSpace: 'normal',
      overflow: 'visible !important',
      content: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
        overflow: 'visible !important',
        marginTop: '2px',
        width: '100%'
      },
      borderRight: '1px solid var(--border-light)',
      borderBottom: '1px solid var(--border-light)'
    },
    row: {
      position: 'relative',
      minHeight: '28px !important',
      hover: {
        backgroundColor: `rgba(var(--black-rgb), 0.04)`
      }
    },
    header: {
      minHeight: '28px !important',
      maxHeight: '28px !important',
      lineHeight: '28px !important',
      padding: '0 4px',
      titleContainer: {
        padding: 0
      },
      backgroundColor: 'var(--background-light)',
      borderBottom: '1px solid var(--border-light)',
      borderRight: '1px solid var(--border-light)'
    },
    sizes: {
      headerHeight: '36px',
      rowHeight: '24px',
      sectionHeaderHeight: '32px'
    },
    transforms: {
      headerOffset: 'translateY(-3px)'
    }
  },
  // Common colors and backgrounds
  colors: {
    text: {
      primary: 'var(--text-primary)',
      secondary: 'var(--text-secondary)',
      dark: 'var(--text-dark)',
      light: 'var(--text-light)'
    },
    background: {
      light: 'var(--background-light)',
      hover: {
        light: 'var(--hover-background-color)',
        dark: `rgba(var(--black-rgb), 0.04)`
      },
      paper: 'background.paper',
      purple: {
        light: 'var(--accent-purple-light)'
      },
      blue: {
        light: 'var(--accent-blue-light)'
      }
    },
    border: {
      light: 'var(--border-light)'
    },
    primary: {
      blue: 'var(--primary-blue)'
    }
  },
  // Common borders
  borders: {
    standard: `1px solid var(--border-light)`,
    radius: {
      small: '4px',
      medium: '8px',
      large: '12px'
    }
  },
  // Font styles
  typography: {
    fontSize: {
      xs: 'var(--font-size-xs)',
      base: 'var(--font-size-base)',
      lg: 'var(--font-size-lg)',
      xl: 'var(--font-size-xl)'
    },
    fontWeight: {
      regular: 'var(--font-weight-regular)',
      medium: 'var(--font-weight-medium)',
      semibold: 'var(--font-weight-semibold)',
      bold: 'var(--font-weight-bold)'
    },
    fontFamily: {
      primary: 'var(--font-primary)',
      mono: 'var(--font-mono)'
    },
    lineHeight: {
      normal: 1.2,
      tight: 1,
      relaxed: 1.4
    }
  },
  // Common spacing
  spacing: {
    padding: {
      small: '2px 4px',
      medium: '4px 8px',
      large: '8px 16px'
    },
    gap: {
      xs: '2px',
      sm: '4px',
      md: '8px',
      lg: '16px'
    }
  },
  // Common transitions
  transitions: {
    standard: 'all 0.2s ease-in-out'
  },
  // Enhanced effects
  effects: {
    boxShadow: {
      sm: `0 2px 4px rgba(var(--black-rgb), 0.1)`,
      md: `0 4px 8px rgba(var(--black-rgb), 0.15)`
    },
    transform: {
      hover: 'translateY(-1px)',
      active: 'translateY(0)'
    }
  }
};

// Main table styles
export const tableStyles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    '& .MuiDataGrid-root': {
      border: baseStyles.borders.standard,
      fontFamily: baseStyles.typography.fontFamily.primary,
      overflow: 'hidden',
      '& .MuiDataGrid-main': baseStyles.grid.main,
      '& .MuiDataGrid-virtualScroller': baseStyles.grid.virtualScroller,
      '& .MuiDataGrid-columnHeaders': {
        borderBottom: baseStyles.borders.standard,
        minHeight: baseStyles.grid.header.minHeight,
        maxHeight: baseStyles.grid.header.maxHeight,
        lineHeight: baseStyles.grid.header.lineHeight,
        backgroundColor: baseStyles.colors.background.paper,
        fontSize: baseStyles.typography.fontSize.base,
        fontFamily: baseStyles.typography.fontFamily.primary
      },
      '& .MuiDataGrid-cell': {
        ...baseStyles.grid.cell,
        '& > div': {
          marginTop: baseStyles.spacing.gap.xs
        }
      },
      '& .MuiDataGrid-row': {
        ...baseStyles.grid.row,
        '&:hover': baseStyles.grid.row.hover
      },
      '& .MuiDataGrid-columnHeader': {
        padding: baseStyles.grid.header.padding,
        borderRight: baseStyles.borders.standard,
        '&:last-child': {
          borderRight: 'none'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': baseStyles.grid.header.titleContainer
      },
      '& .MuiDataGrid-cellContent': baseStyles.grid.cell.content
    }
  },
  gridContainer: {
    ...baseStyles.container,
    flex: 1,
    overflow: 'hidden',
    height: '100%',
    maxHeight: 'calc(100vh - 240px)',
    position: 'relative' as const,
    '& .MuiDataGrid-root': {
      height: '100% !important',
      maxHeight: '100% !important',
      overflow: 'hidden',
      '& .MuiDataGrid-virtualScroller': {
        overflow: 'auto',
        maxHeight: '100% !important'
      }
    }
  } as CSSProperties
};

// Header styles
export const tableViewStyles = {
  header: {
    container: {
      ...baseStyles.container,
      backgroundColor: 'transparent',
      padding: `${baseStyles.spacing.gap.sm} 0 ${baseStyles.spacing.gap.sm} ${baseStyles.spacing.gap.sm}`,
      marginTop: '-32px',
      position: 'sticky',
      top: '-8px',
      zIndex: 1,
      '& .MuiTableRow-root:hover': {
        backgroundColor: 'var(--hover-background-color)'
      }
    } as SxProps,
    content: {
      display: 'flex',
      alignItems: 'center',
      minHeight: baseStyles.grid.row.minHeight
    } as SxProps,
    navigation: {
      width: '100%',
      '& .MuiBreadcrumbs-ol': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: baseStyles.spacing.gap.sm,
        rowGap: baseStyles.spacing.gap.sm,
        margin: 0,
        padding: baseStyles.spacing.padding.small,
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start'
      },
      '& .MuiBreadcrumbs-li': {
        margin: 0,
        padding: '1px',
        display: 'flex',
        alignItems: 'center',
        flex: '0 0 auto'
      },
      '& .MuiBreadcrumbs-separator': {
        margin: 0,
        color: baseStyles.colors.text.secondary,
        opacity: 0.8,
        flex: '0 0 auto'
      },
      '& .section-item': {
        minWidth: 'auto',
        maxWidth: '240px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: baseStyles.spacing.padding.medium,
        fontSize: 'var(--font-size-base)',
        fontWeight: baseStyles.typography.fontWeight.medium,
        color: baseStyles.colors.text.secondary,
        backgroundColor: 'transparent',
        borderRadius: '16px',
        height: '24px',
        border: '1px solid transparent',
        transition: baseStyles.transitions.standard,
        textTransform: 'uppercase',
        letterSpacing: '0.3px',
        '&:hover': {
          backgroundColor: 'var(--hover-background-color)',
          color: baseStyles.colors.text.primary,
          transform: 'translateY(-1px)',
          boxShadow: `0 2px 4px rgba(var(--black-rgb), 0.1)`
        },
        '&:active': {
          transform: 'translateY(0)',
          boxShadow: 'none'
        },
        '&.active': {
          fontWeight: baseStyles.typography.fontWeight.semibold,
          color: baseStyles.colors.primary.blue,
          backgroundColor: 'var(--primary-blue-light)',
          borderColor: baseStyles.colors.primary.blue,
          '&:hover': {
            backgroundColor: 'var(--primary-blue-lighter)',
            color: 'var(--primary-blue-active)'
          }
        }
      }
    } as SxProps,
    separator: {
      color: baseStyles.colors.text.secondary,
      fontSize: baseStyles.typography.fontSize.base,
      fontFamily: baseStyles.typography.fontFamily.primary,
      fontWeight: baseStyles.typography.fontWeight.regular,
      mx: 0.5,
      display: 'flex',
      alignItems: 'center',
      opacity: 0.8
    } as SxProps
  }
};

// Process table specific styles
export const processTableStyles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const
  },
  dataGrid: {
    flex: 1,
    '& .MuiDataGrid-row': {
      minHeight: `${baseStyles.grid.sizes.rowHeight} !important`,
      '&.section-header-row': {
        minHeight: `${baseStyles.grid.sizes.sectionHeaderHeight} !important`,
        position: 'relative',
        zIndex: 1
      }
    },
    '& .MuiDataGrid-root': {
      border: baseStyles.borders.standard,
    },
    '& .MuiDataGrid-virtualScroller': baseStyles.grid.virtualScroller,
    '& .MuiDataGrid-cell': {
      ...baseStyles.grid.cell,
      padding: baseStyles.spacing.padding.small,
      fontSize: baseStyles.typography.fontSize.base
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: baseStyles.colors.background.paper,
      borderBottom: baseStyles.borders.standard,
      minHeight: `${baseStyles.grid.sizes.headerHeight} !important`,
      maxHeight: `${baseStyles.grid.sizes.headerHeight} !important`,
      lineHeight: `${baseStyles.grid.sizes.headerHeight} !important`,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      margin: 0,
      transform: baseStyles.grid.transforms.headerOffset
    },
    '& .MuiDataGrid-columnHeader': {
      borderRight: baseStyles.borders.standard
    },
    '& .section-header-row': {
      '& .MuiDataGrid-cell': {
        padding: '0',
        backgroundColor: `${baseStyles.colors.background.purple.light} !important`,
        borderRight: 'none !important',
        borderBottom: 'none !important',
        overflow: 'visible',
        whiteSpace: 'nowrap',
        position: 'relative',
        '&:not(:first-of-type)': {
          borderLeft: 'none !important'
        },
        '& .MuiDataGrid-cellContent': {
          height: '100%',
          padding: '0',
          marginTop: '0',
          overflow: 'visible',
          whiteSpace: 'nowrap',
          zIndex: 1,
          position: 'relative'
        }
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: baseStyles.colors.background.purple.light,
        zIndex: 0,
        pointerEvents: 'none'
      }
    },
    '& .selected-row': {
      backgroundColor: `${baseStyles.colors.background.blue.light} !important`,
    },
    '& .section-item': {
      borderRadius: baseStyles.borders.radius.large,
      height: baseStyles.grid.sizes.rowHeight,
      '&:hover': {
        backgroundColor: baseStyles.colors.background.hover.light,
        color: baseStyles.colors.text.primary,
        transform: baseStyles.effects.transform.hover,
        boxShadow: baseStyles.effects.boxShadow.sm
      },
      '&:active': {
        transform: baseStyles.effects.transform.active,
        boxShadow: 'none'
      }
    }
  },
  cellStyles: {
    sectionHeader: {
      color: baseStyles.colors.text.dark,
      fontSize: baseStyles.typography.fontSize.xl,
      fontWeight: baseStyles.typography.fontWeight.medium,
      letterSpacing: '0.3px',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: baseStyles.spacing.gap.md,
      whiteSpace: 'nowrap',
      overflow: 'visible',
      position: 'relative',
      zIndex: 2
    },
    stepNumber: {
      color: baseStyles.colors.text.secondary,
      fontSize: baseStyles.typography.fontSize.base,
      fontWeight: baseStyles.typography.fontWeight.medium,
      textAlign: 'left',
      paddingLeft: baseStyles.spacing.gap.xs
    },
    stepName: {
      color: baseStyles.colors.text.primary,
      fontSize: baseStyles.typography.fontSize.base,
      fontWeight: baseStyles.typography.fontWeight.medium,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word'
    },
    parameters: {
      display: 'flex',
      flexDirection: 'column',
      gap: baseStyles.spacing.gap.sm,
      fontSize: baseStyles.typography.fontSize.base,
      lineHeight: baseStyles.typography.lineHeight.normal,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word'
    },
    parameterField: {
      color: baseStyles.colors.text.dark,
      fontWeight: baseStyles.typography.fontWeight.medium,
      marginRight: baseStyles.spacing.gap.sm
    },
    parameterValue: {
      color: baseStyles.colors.primary.blue,
      fontWeight: baseStyles.typography.fontWeight.bold,
      wordBreak: 'break-word'
    },
    comments: {
      fontSize: baseStyles.typography.fontSize.base,
      lineHeight: baseStyles.typography.lineHeight.normal,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      normal: {
        color: baseStyles.colors.text.dark,
        fontStyle: 'normal'
      },
      empty: {
        color: baseStyles.colors.text.light,
        fontStyle: 'italic'
      }
    }
  }
} as const; 