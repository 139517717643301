import React, { useState } from 'react';
import { Button, Dialog, TextArea, Intent, FormGroup } from '@blueprintjs/core';
import { useAuth0 } from '@auth0/auth0-react';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { v4 as uuidv4 } from 'uuid';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { BASE_FABUBLOX_API_URL } from '../utils/constants';
import { CreateProcessRequest } from '../__generated__/Process';
import { FabuToaster } from '../components/toaster';

// Interface that matches the backend ReportProcessRequest
interface ClientReportRequest extends CreateProcessRequest {
    originalProcessId: string;
    stepNumber: number;
    message: string;
    username?: string;
}

interface ReportProblemProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    bloxIdx: number;
    bloxes: AllBloxes[];
}

export const ReportProblemDialog: React.FC<ReportProblemProps> = ({isOpen, setIsOpen, bloxIdx, bloxes}) => {
    const [message, setMessage] = useState('');
    const [processId] = useFabuState('processId');
    const [processSections] = useFabuState('processSections');
    const { getAccessTokenSilently, user } = useAuth0();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const issueId = uuidv4();

    const handleClose = () => {
        setIsOpen(false);
        setMessage('');
    }

    const handleSubmit = async () => {
        if (!message.trim()) {
            FabuToaster.then(toaster => {
                toaster.show({
                    message: "Please provide a description of the issue",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const token = await getAccessTokenSilently();

            // Create a request that matches the backend ReportProcessRequest interface
            const reportRequest: ClientReportRequest = {
                bloxes: bloxes,
                sections: processSections,
                processName: `Image Issue: ${issueId}`,
                originalProcessId: processId,
                stepNumber: bloxIdx + 1,
                message: message,
                username: user?.name
            };

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json', 
                    Authorization: `Bearer ${token}` 
                },
                body: JSON.stringify(reportRequest)
            };

            const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/process/report`, requestOptions);
            
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error reporting image issue');
            }

            // Show success toast
            FabuToaster.then(toaster => {
                toaster.show({
                    message: "Issue Reported Successfully",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            });

            handleClose();
        } catch (error) {
            console.error('Error reporting image issue:', error);
            
            // Show error toast
            FabuToaster.then(toaster => {
                toaster.show({
                    message: "There was a problem reporting this issue. Please email support@fabublox.com directly.",
                    intent: Intent.DANGER,
                    timeout: 5000
                });
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const defaultStyle = {
        minHeight: '200px',
        marginBottom: '10px',
    }

    return (
        <>
            <Dialog
                isOpen={isOpen}
                onClose={handleClose}
                title="Image Problem"
            >
                <div className="bp5-dialog-body">
                    <FormGroup>
                    <TextArea
                        placeholder="Your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        growVertically={true}
                        large={true}
                        fill={true}
                        style={defaultStyle}
                    />
                    </FormGroup>
                    The Fabublox Team will review a copy of your process along with the information submitted here. We appreciate your feedback!
                </div>
                <div className="bp5-dialog-footer">
                    <div className="bp5-dialog-footer-actions">
                        <Button 
                            text={isSubmitting ? "Submitting..." : "Submit"} 
                            intent={Intent.PRIMARY} 
                            onClick={handleSubmit}
                            disabled={isSubmitting} 
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
}