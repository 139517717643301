/**
 * @fileoverview Provides a custom React hook for managing the stack simulator feature
 * toggle in the Fabublox application. This hook interfaces with Auth0 authentication
 * and user data management to control access to the stack simulation functionality.
 */

import { useAuth0 } from '@auth0/auth0-react';
import { useReadUser, useUpdateUser } from './DataFetching/use-fetch-user';

/**
 * Custom hook that manages the stack simulator feature toggle for users.
 * This hook provides functionality to read and update a user's access to
 * the stack simulator feature in the Fabublox application.
 * 
 * @returns {Object} An object containing:
 *   - userData: The current user's data including their enabled features
 *   - userDataIsLoading: Boolean indicating if user data is being fetched
 *   - enableStackSimulator: Function to toggle the stack simulator feature
 */
export const useEnableStackSimulator = () => {
    const { user } = useAuth0();
    const userId = user?.sub;

    const { data: userData, isLoading: userDataIsLoading } = useReadUser(userId);
    const updateUserMutation = useUpdateUser();

    /**
     * Updates the user's stack simulator feature access.
     * 
     * @param {boolean} isEnabled - Whether to enable or disable the stack simulator
     * @returns {Promise<void>} A promise that resolves when the update is complete
     * 
     * @remarks
     * This function will silently return if:
     * - The user data is not yet loaded
     * - The user ID is not available (user not authenticated)
     */
    const enableStackSimulator = async (isEnabled: boolean) => {
        if (!userData || !userId) return;

        const updatedFeatures = { 
            ...userData.enabledFeatures, 
            stackSimulator: isEnabled 
        };
        
        updateUserMutation.mutate({ 
            userId, 
            data: { enabledFeatures: updatedFeatures } 
        });
    };

    return {
        userData,
        userDataIsLoading,
        enableStackSimulator
    };
};
