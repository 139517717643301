import { useState } from 'react';
import { BloxTypes } from '../Data/BloxSchema/base-blox';
import { Column, Row } from '../Layout/layouts';
import { BloxSvg } from './svg/BloxSvg';
import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

/**
 * Props for the `BloxImage` component.
 */
export interface BloxImageProps {
    /**
     * The type of the Blox (e.g., StartBlox, ProcessBlox).
     */
    bloxType: BloxTypes;

    /**
     * Indicates if the Blox is currently selected.
     */
    selected: boolean;

    /**
     * Indicates if dragging is disabled for the Blox.
     */
    noDrag: boolean;

    /**
     * The step number associated with the Blox.
     */
    stepNumber: number;

    /**
     * The display name of the Blox (optional).
     */
    displayName?: string;

    /**
     * The name of the tool associated with the Blox (optional).
     */
    toolName?: string;

    /**
     * The name of the module associated with the Blox (optional).
     */
    moduleName?: string;

    /**
     * The ID of the module associated with the Blox (optional).
     */
    moduleId?: string;
}

/**
 * Renders a visual representation of a Blox component with optional tooltips and interactivity.
 *
 * @param {BloxImageProps} props - The properties for the BloxImage component.
 * @returns {JSX.Element} The rendered BloxImage component.
 *
 * ### Behavior:
 * - Displays a different layout for `StartBlox` type and other Blox types.
 * - Shows tooltips with detailed information (e.g., display name, tool name, module link).
 * - Handles hover states for tooltips and target areas.
 * - Allows customization via props to define selection, dragging, and step numbers.
 */
export const BloxImage: React.FC<BloxImageProps> = (props) => {
    const { bloxType, displayName, toolName, selected, noDrag, moduleName, moduleId, stepNumber } = props;
    const [inTooltip, setInTooltip] = useState(false);
    const [inTarget, setInTarget] = useState(false);

    /**
     * The tooltip content displayed when hovering over the Blox label.
     */
    const labelToolTip = (
        <div onMouseEnter={() => setInTooltip(true)} onMouseLeave={() => setInTooltip(false)}>
            Blox Name: {displayName}
            <br />
            Tool Name: {toolName ?? <i>No tool selected</i>}
            {moduleName && moduleId && (
                <>
                    <br />
                    <a href={`/module-editor/${moduleId}`} target="_blank" rel="noopener noreferrer">
                        <Icon icon={IconNames.Share} /> {' ' + moduleName}
                    </a>
                </>
            )}
        </div>
    );

    // Space adjustment for step number when the display name is long
    const spaceForStepNumber = displayName && displayName.length > 15 ? 18 : 0;

    return (
        <Column className={`bloxTop ${selected ? 'selected' : ''} ${noDrag ? 'noDrag' : ''}`}>
            {bloxType === BloxTypes.StartBlox ? (
                // Special layout for StartBlox type
                <Column style={{ height: 'auto', width: '100%', padding: 0, display: 'flex', justifyContent: 'center' }}>
                    <Column style={{ margin: '0', padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '24px' }}>
                        <div style={{ height: '42px', display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                            <BloxSvg bloxType={bloxType} className="blox-library-image" />
                        </div>
                        <span className="processStart" style={{ fontSize: 'var(--font-size-lg)', lineHeight: 1, display: 'block', marginTop: '8px', position: 'relative', top: '20px' }}>
                            Start
                        </span>
                    </Column>
            </Column>
            ) : (
                // Layout for other Blox types
                <>
                    <Tooltip isOpen={inTooltip || inTarget} content={labelToolTip} position={Position.TOP}>
                        <div
                            onMouseEnter={() => setInTarget(true)}
                            onMouseLeave={() => setTimeout(() => setInTarget(false), 200)}
                        >
                            <Row style={{ alignItems: 'center', gap: '4px' }}>
                                <span style={{ fontSize: 'smaller' }}>{stepNumber}</span>
                                <span
                                    style={{ paddingLeft: spaceForStepNumber }}
                                    className="library-label"
                                >
                                    {displayName}
                                </span>
                            </Row>
                            {toolName && <strong className="library-label">{toolName}</strong>}
                        </div>
                    </Tooltip>

                    <BloxSvg bloxType={bloxType} className="blox-library-image" />
                </>
            )}
        </Column>
    );
};
