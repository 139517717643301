/**
 * @fileoverview Welcome and help component for the IFrame application.
 * This component provides user guidance, application information, and helpful
 * instructions for using the embedded process viewer. It includes collapsible
 * sections, tips on using different view modes, and branding elements.
 * Serves as the entry point for new users to understand the application functionality.
 */

import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton, 
  Collapse, 
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Link
} from '@mui/material';

// Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ViewListIcon from '@mui/icons-material/ViewList';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LinkOffOutlined from '@mui/icons-material/LinkOffOutlined';

// Import styles
import { SizeConfig } from './IFrameStyles';
import { OpenInNew } from '@mui/icons-material';
import { Colors } from '@blueprintjs/core';

/**
 * Props for IFrameWelcomeBox component
 */
interface IFrameWelcomeBoxProps {
  iframeStyles: any;
  sizeConfig: SizeConfig;
  isCollapsed: boolean;
  onToggleCollapsed: () => void;
  isMobile?: boolean;
}

/**
 * Fabublox logo with backlink component
 */
export const PoweredByFabuBloxLogo = () => (
  <Link 
    href="https://fabublox.com" 
    target="_blank" 
    rel="noopener noreferrer"
    sx={{
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
      paddingLeft: '12px',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        outline: 'none',
      },
    }}
  >
    <Box
      component="img"
      src="https://fabublox.com/logo192.png"
      alt="Fabublox Logo"
      sx={{
        height: '24px',
        marginRight: '8px',
      }}
    />
    <Typography
      variant="body2"
      sx={{
        fontWeight: 500,
        color: '#0A2540',
        fontSize: '0.85rem',
        whiteSpace: 'nowrap',
      }}
    >
      <Box component="span" sx={{ color: Colors.DARK_GRAY4, fontSize: '0.85rem' }}>Powered by</Box>{' '}
      <Box 
        component="span" 
        sx={{ 
          color: '#000000', 
          fontWeight: 600,
          letterSpacing: '0.02em',
          fontSize: '1.05rem'
        }}
      >
        Fab
      </Box>
      <Box 
        component="span" 
        sx={{ 
          background: 'linear-gradient(90deg, #B75CFF 0%, #FF7C7C 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          fontWeight: 700,
          letterSpacing: '0.02em',
          fontSize: '1.05rem'
        }}
      >
        u
      </Box>
      <Box 
        component="span" 
        sx={{ 
          color: '#000000', 
          fontWeight: 600,
          letterSpacing: '0.02em',
          fontSize: '1.05rem'
        }}
      >
        Blox
      </Box>
      <Box 
        component="span" 
        sx={{ 
          color: '#666',
          fontSize: '0.8rem',
          marginLeft: '2px',
          position: 'relative',
          top: '-5px'
        }}
      >
      </Box>
    </Typography>
  </Link>
);

/**
 * Welcome box component with tutorial content
 * This component can be collapsed to a question mark icon
 */
export const IFrameWelcomeBox: React.FC<IFrameWelcomeBoxProps> = ({
  iframeStyles,
  sizeConfig,
  isCollapsed,
  onToggleCollapsed,
  isMobile = false
}) => {
  // When collapsed, render just the question mark icon with logo
  if (isCollapsed) {
    return (
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <PoweredByFabuBloxLogo />
        </Box>
        
        {/* Welcome Guide button with text and icon */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'var(--background-white)',
            border: '1px solid #e0e0e0',
            borderRadius: '18px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '4px 12px 4px 8px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
          onClick={onToggleCollapsed}
        >
          <IconButton
            size="small"
            sx={{
              padding: '4px',
              marginRight: '4px',
            }}
          >
            <HelpOutlineIcon color="primary" fontSize="small" />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: 'primary.main',
              fontSize: '0.85rem',
            }}
          >
            Welcome Guide
          </Typography>
        </Box>
      </Box>
    );
  }

  // When expanded, render the full welcome box
  return (
    <Paper 
      elevation={0}
      sx={{
        width: '100%',
        marginBottom: '16px',
        borderRadius: sizeConfig.borderRadius,
        overflow: 'hidden',
        border: '1px solid #e0e0e0',
        backgroundColor: 'var(--background-white)',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
      }}
    >
      {/* Welcome box header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
          backgroundColor: 'rgba(225,230,255,0.6)', // Right Panel Color with 0.6 opacity for the header
          color: 'var(--text-primary)',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        {/* Keep the FabubloxLogo separate to maintain consistent positioning */}
        <PoweredByFabuBloxLogo />
        
        {/* Center title and close button */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          flex: 1
        }}>
          <div></div> {/* Empty div for centering */}
          <IconButton 
            size="small" 
            onClick={onToggleCollapsed}
            sx={{ 
              color: 'var(--text-primary)',
              marginLeft: '8px',
              padding: '2px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <ExpandLessIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      {/* Welcome content */}
      <Box
        sx={{
          padding: '16px',
          backgroundColor: 'var(--background-white)',
        }}
      >
        <Typography variant='h6' sx={{ marginBottom: '12px' }}>
        Welcome to the MOSIS2.0 Fab Service Explorer
        </Typography>
        <Typography variant='body2'>
        This interactive tool lets you explore nanofabrication capabilities, example processes, and device structures available through the MOSIS2.0
        prototyping and fabrication services. <br/> Submit a request to MOSIS2.0 engineers with any questions, comments, or custom process requests and references.
        </Typography>
        <Typography variant='body2' sx={{ marginTop: '12px' }}>
        Use the <b><Link target='_blank' rel='noopener noreferrer' color='inherit' href="https://fabublox.com/process-editor">FabuBlox Process Design UI </Link><OpenInNew fontSize='small' /></b>  to add custom process flows to your request.
        </Typography>
        
        <Box
    sx={{
      mr: 'auto',
      mt: 3,
      maxWidth: '1000px',
      textAlign: 'left',
      background: 'rgba(225,230,255,0.6)',
      borderRadius: '8px',
      p: 3,
    }}
  >
        <Typography variant="subtitle2">
          Getting Started:
        </Typography>
        
        <List dense disablePadding sx={{ padding: '0px 0px'}}>
          <ListItem sx={{ paddingY: '4px' }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <AssignmentIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Browse Example Processes"
              secondary="Explore complete process flows and device structures provided by MOSIS2.0 and partner nanofabrication facilities."
            />
          </ListItem>
          
          <ListItem sx={{ paddingY: '4px' }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <TouchAppIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Select a Process Flow of Interest"
              secondary="See process steps alongside fabricated device schematics. Click on a device schematic to see additional information on used materials."
            />
          </ListItem>
          
          <ListItem sx={{ paddingY: '4px' }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <ViewModuleIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Submit a Request"
              secondary={<>
              To submit a request, use the <b>"Contact MOSIS2.0 Team"</b> dialog. If you have selected an example process, it will be automatically included in your
request.
You can also provide additional details, such as references to published process flows or specific devices you're interested in. <br/>If you have a custom
 <b> FabuBlox process flow</b>, you can share a link for the MOSIS2.0 team to evaluate its feasibility within the offered fabrication and prototyping services.
</>}
            />
          </ListItem>
          {/* <ListItem sx={{ paddingY: '4px' }}>
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <QuestionAnswerIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Talk to our chatbot to find tools and capabilities in the Hub"
              secondary="Ask our AI assistant about fabrication and characterization capabilities available in the Hub, including questions about specific tools, locations,
supported wafer sizes, and more."
            />
          </ListItem> */}
        </List>
        </Box>
        
        <Divider sx={{ marginY: '16px' }} />
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="caption" color="textSecondary">
            You can always reopen this guide by clicking the question mark icon.
          </Typography>
          
          <Button 
            size="small" 
            variant="outlined" 
            color="primary"
            onClick={onToggleCollapsed}
            sx={{ marginLeft: '8px' }}
          >
            Close Guide
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default IFrameWelcomeBox; 
