/**
 * @fileoverview Component responsible for rendering process visualizations in different view modes.
 * This component acts as a renderer for process data stored in the context, supporting list, table,
 * and standard view modes. It handles transitions between processes with loading states, manages
 * section visibility, and provides interactive functionality for process elements.
 * Relies on both the ProcessContext and UIContext for state management.
 */

import React, { SetStateAction, useEffect, useState, useRef } from 'react';
import SectionContainer from "../SectionContainer";
import { renderProcessView } from "../ProcessViewRenderer";
import { SectionedItem } from "../../utils/processUtils";
import { Section } from "../../__generated__/Process";
import { Box, CircularProgress, Fade } from '@mui/material';
import { useIFrameProcess } from './IFrameProcessContext';
import { useIFrameUI } from './IFrameUIContext';
import { ViewMode } from './hooks/useFabuUIState';

// Helper to check the view mode type
const isListView = (mode: ViewMode): boolean => mode === 'list';
const isTableView = (mode: ViewMode): boolean => mode === 'table';
const isStandardView = (mode: ViewMode): boolean => mode === 'standard' || mode === 'print';

/**
 * Props for IFrameProcessView component
 * With refactored context, we only need a subset of the original props
 */
interface IFrameProcessViewProps {
  containerRef: React.RefObject<HTMLDivElement>;
  bloxSVGs: React.ReactElement[];
  // Temporary prop until we fully migrate to context
  setProcessSections: (value: SetStateAction<Section[]>) => void;
}

/**
 * Component that renders the process view based on selected view mode
 * Now uses the IFrameProcess and IFrameUI contexts for state management
 */
export function IFrameProcessView({
  containerRef,
  bloxSVGs,
  setProcessSections
}: IFrameProcessViewProps) {
  // Get process data from context
  const { state: processState } = useIFrameProcess();
  const { processBloxes, processSections, processId } = processState;
  
  // Get UI state from context
  const { state: uiState, actions: uiActions } = useIFrameUI();
  const { 
    selectedBloxId, 
    closedSections, 
    viewMode,
    initialScrollTarget
  } = uiState;
  
  const { 
    setSelectedBloxId, 
    setClosedSections,
    setInitialScrollTarget 
  } = uiActions;

  // Track loading state for process transitions
  const [isTransitioning, setIsTransitioning] = useState(false);
  const lastProcessIdRef = useRef<string | null>(null);
  const transitionTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Show loading effect when process changes
  useEffect(() => {
    if (processId && processId !== lastProcessIdRef.current) {
      // Clear any existing transition timer
      if (transitionTimerRef.current) {
        clearTimeout(transitionTimerRef.current);
      }
      
      // Set loading state
      setIsTransitioning(true);
      
      // Update reference to current process
      lastProcessIdRef.current = processId;
      
      // Set a minimum loading time to ensure users see the loading indicator
      transitionTimerRef.current = setTimeout(() => {
        setIsTransitioning(false);
      }, 700); // Minimum loading time of 700ms for visual feedback
    }
    
    // Cleanup on unmount
    return () => {
      if (transitionTimerRef.current) {
        clearTimeout(transitionTimerRef.current);
      }
    };
  }, [processId]);

  // No-op function to satisfy the linter
  const noop = () => { /* intentionally empty */ };

  // Check if we should show the loading state
  const showLoadingState = isTransitioning || 
    !processBloxes || 
    !processSections || 
    processBloxes.length === 0;

  return (
    <Box sx={{ 
      width: '100%', 
      height: 'auto',
      overflow: 'visible',
      position: 'relative'
    }}>
      {/* Loading overlay */}
      <Fade
        in={showLoadingState}
        timeout={{ enter: 100, exit: 300 }}
        unmountOnExit
      >
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: 10,
          backdropFilter: 'blur(2px)'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
          }}>
            <CircularProgress size={40} thickness={4} />
            <Box sx={{ mt: 2, fontSize: '0.9rem', color: '#555' }}>
              Loading process...
            </Box>
          </Box>
        </Box>
      </Fade>

      <SectionContainer containerRef={containerRef}>
        {renderProcessView({
          mode: viewMode,
          props: isListView(viewMode)
            ? {
                processBloxes,
                processSections,
                selectedBloxId: selectedBloxId || '',
                closedSections,
                containerRef,
                processIsReadOnly: true,
                initialScrollTarget,
                onScrollComplete: () => setInitialScrollTarget(null),
                onSelectItem: (item: SectionedItem) => {
                  if (item.type === 'blox') {
                    setSelectedBloxId(item.blox.id);
                  }
                },
                onToggleSection: (sectionId: string) => {
                  setClosedSections(prev => ({
                    ...prev,
                    [sectionId]: !prev[sectionId]
                  }));
                },
                bloxSVGs
              }
            : isTableView(viewMode)
            ? {
                processBloxes,
                processSections,
                selectedBloxId: selectedBloxId || '',
                closedSections,
                containerRef,
                processIsReadOnly: true,
                height: "calc(100% - 30px)",
                showAllParameters: true,
                splitWorksheet: false,
                bloxSVGs
              }
            : {
                processBloxes,
                processSections,
                selectedBloxId: selectedBloxId || '',
                closedSections,
                containerRef,
                processIsReadOnly: true,
                handleBloxActions: (bloxId: string, sectionId: string, idx: number) => ({
                  onClickBlox: () => setSelectedBloxId(bloxId),
                  onDelete: noop,
                  handleInsertBlox: noop,
                  handleInsertModule: noop
                }),
                getEmptySectionTarget: (sectionId: string, sectionIdx: number) => <></>,
                bloxDisplayProps: {
                  hideStack: false,
                  processIsReadOnly: true,
                },
                setClosedSections,
                setProcessSections,
                bloxSVGs
              }
        })}
      </SectionContainer>
    </Box>
  );
} 