// utils/scrollUtils.ts
import React from 'react';
import { Section } from '../__generated__/Process';

/**
 * Smoothly scrolls to a specific element within a container.
 * @param {string} sectionId - The ID of the section to scroll to.
 * @param {React.RefObject<HTMLDivElement>} containerRef - A reference to the scrollable container element.
 */
export const scrollToElement = (sectionId: string, containerRef: React.RefObject<HTMLDivElement>) => {
  const element = document.getElementById(`item-${sectionId}`);
  if (element && containerRef.current) {
      // Get the container's visible height
      const containerHeight = containerRef.current.clientHeight;
      // Get the element's height
      const elementHeight = element.clientHeight;

      const elementPosition = element.getBoundingClientRect().top;
      const containerPosition = containerRef.current.getBoundingClientRect().top;

      // Calculate position to center the element in the viewport
      const scrollPosition = elementPosition - containerPosition - (containerHeight / 2) + (elementHeight / 2);

      containerRef.current.scrollTo({
          top: containerRef.current.scrollTop + scrollPosition,
          behavior: "smooth"
      });
  }
};

/**
* Toggles a section's collapsed state and scrolls to it.
* @param {string} sectionId - The ID of the section to toggle and scroll to.
* @param {{ [key: string]: boolean }} closedSections - A dictionary of section IDs and their closed state.
* @param {React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>} setClosedSections - A setter function for updating the closed sections state.
* @param {React.RefObject<HTMLDivElement>} containerRef - A reference to the scrollable container element.
* @param {boolean} [forceOpen] - If true, only opens the section and never closes it (used for breadcrumb navigation)
*/
export const toggleSectionWithScroll = (
    sectionId: string,
    closedSections: { [key: string]: boolean },
    setClosedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
    containerRef: React.RefObject<HTMLDivElement>,
    forceOpen?: boolean
) => {
    const isCurrentlyClosed = closedSections[sectionId];
    
    // If forceOpen is true (from breadcrumb nav), only open sections
    // Otherwise allow both opening and closing
    if (isCurrentlyClosed || !forceOpen) {
        // Create a new state object with the target section being toggled
        const newState = { ...closedSections };
        newState[sectionId] = !isCurrentlyClosed && !forceOpen;
        setClosedSections(newState);
        
        // Small delay to ensure content is rendered before scrolling
        if (isCurrentlyClosed) {
            setTimeout(() => {
                scrollToElement(sectionId, containerRef);
            }, 100);
        }
    }
    
    // If the section is already open, just scroll to it
    if (!isCurrentlyClosed) {
        scrollToElement(sectionId, containerRef);
    }
};

/**
 * Scrolls to a specific section within a container with an optional offset.
 * @param {string} sectionId - The ID of the section to scroll to.
 * @param {React.RefObject<HTMLElement>} containerRef - A reference to the scrollable container element.
 * @param {number} [offset=0] - The offset to apply when scrolling.
 */
export const scrollToSection = (
  sectionId: string,
  containerRef: React.RefObject<HTMLElement>,
  offset = 0
) => {
  if (!containerRef.current) return;

  const sectionElement = containerRef.current.querySelector(`#item-${sectionId}`);
  if (!sectionElement) return;

  const container = containerRef.current;
  const sectionRect = sectionElement.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  
  // Calculate the scroll position with smooth transition buffer
  const scrollTop = container.scrollTop + sectionRect.top - containerRect.top - offset;
  
  // Use smooth scrolling with RAF for better performance
  const startPosition = container.scrollTop;
  const distance = scrollTop - startPosition;
  const duration = 300; // ms
  const startTime = performance.now();

  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const animateScroll = (currentTime: number) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    
    const eased = easeInOutQuad(progress);
    container.scrollTop = startPosition + distance * eased;

    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

/**
 * Scrolls a stack viewer and list view to a specific step based on the provided step number.
 * @param {number} stepNumber - The step number to scroll to.
 * @param {any[]} processBloxes - An array of process blocks with their metadata.
 */
export const scrollStackViewer = (stepNumber: number, processBloxes: any[]) => {
  if (stepNumber < 0 || !processBloxes || stepNumber >= processBloxes.length) return;

  const currentBlox = processBloxes[stepNumber];
  if (!currentBlox) return;

  // Scroll stack viewer
  const stackElement = document.getElementById(`stack-item-${currentBlox.id}`);
  if (stackElement) {
    const scrollContainer = document.querySelector('.stack-viewer-scroll-container');
    if (scrollContainer) {
      const containerRect = scrollContainer.getBoundingClientRect();
      const elementRect = stackElement.getBoundingClientRect();
      const relativeTop = elementRect.top - containerRect.top;
      
      scrollContainer.scrollTo({
        top: scrollContainer.scrollTop + relativeTop - (containerRect.height / 2) + (elementRect.height / 2),
        behavior: 'smooth'
      });
    }
  }

  // Scroll list view
  const listElement = document.getElementById(`item-${currentBlox.id}`);
  if (listElement) {
    const container = listElement.closest('.list-container');
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = listElement.getBoundingClientRect();
      const relativeTop = elementRect.top - containerRect.top;
      
      container.scrollTo({
        top: container.scrollTop + relativeTop - (containerRect.height / 2) + (elementRect.height / 2),
        behavior: 'smooth'
      });
    }
  }
};

/**
 * Scrolls to a selected blox and ensures its section is expanded.
 * This function:
 * 1. Finds the section containing the selected blox
 * 2. Expands that section if it's closed
 * 3. Scrolls to the section both vertically and horizontally
 * 4. Centers the selected blox in view
 * 
 * Note: This function uses two nested timeouts:
 * - First timeout (200ms): Waits for section to expand
 * - Second timeout (300ms): Waits for vertical scrolling before performing horizontal scrolling
 * 
 * @param {string | undefined} selectedBloxId - The ID of the selected blox
 * @param {Section[]} processSections - Array of process sections
 * @param {{ [key: string]: boolean }} closedSections - Current state of closed sections
 * @param {React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>} setClosedSections - Function to update closed sections
 * @param {React.RefObject<HTMLElement>} containerRef - Reference to the scrollable container
 * @returns {boolean} Whether the scroll operation was performed
 */
export const scrollToSelectedBlox = (
  selectedBloxId: string | undefined,
  processSections: Section[],
  closedSections: { [key: string]: boolean },
  setClosedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  containerRef: React.RefObject<HTMLElement>
): boolean => {
  if (!selectedBloxId) return false;
  
  // Check if panel is transitioning - if so, don't scroll
  const leftContainer = document.querySelector('.leftContainer');
  if (leftContainer && leftContainer.classList.contains('leftContainer--transitioning')) {
    return false;
  }
  
  const sectionIdx = processSections.findIndex(section => section.bloxIds.includes(selectedBloxId));
  const section = processSections[sectionIdx];
  if (!section) return false;

  // Expand the section
  setClosedSections(prevState => ({
    ...prevState,
    [section.sectionId]: false
  }));
  
  // Wait for section to expand and render - first timeout
  setTimeout(() => {
    // First find and scroll to the section vertically
    const sectionElement = document.getElementById(section.sectionId);
    
    if (sectionElement) {
      // Scroll section into view vertically
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      // Define horizontal scrolling function - will be called in nested timeout
      const scrollHorizontally = () => {
        // Check again for panel transition before horizontal scroll
        if (leftContainer && leftContainer.classList.contains('leftContainer--transitioning')) {
          return;
        }
        
        const selectedBloxElement = document.getElementById(`blox-${selectedBloxId}`);
        if (!selectedBloxElement) return;

        const scrollableSection = selectedBloxElement.closest('.section');
        if (!scrollableSection) return;

        // Get the blox's position relative to the section
        const bloxOffsetLeft = selectedBloxElement.offsetLeft;
        const sectionScrollWidth = scrollableSection.scrollWidth;
        const sectionClientWidth = scrollableSection.clientWidth;
        const bloxWidth = selectedBloxElement.offsetWidth;

        // Check if the right panel is open by looking at the leftContainer class
        const isPanelOpen = leftContainer && !leftContainer.classList.contains('leftContainer--expanded');
        const panelOffset = isPanelOpen ? 150 : 0; // Half of typical panel width (300px)

        // Check if this is the last blox in the section
        const isLastBlox = section.bloxIds[section.bloxIds.length - 1] === selectedBloxId;

        // Calculate scroll position
        let scrollLeft: number;
        if (isLastBlox) {
          // For the last blox, always scroll to the rightmost position
          scrollLeft = sectionScrollWidth - sectionClientWidth;
        } else {
          // For other bloxes, center them in view without adjusting for panel
          // No longer applying panel offset - fixes the movement during panel transitions
          scrollLeft = Math.max(0,
            Math.min(
              bloxOffsetLeft - (sectionClientWidth / 2) + (bloxWidth / 2),
              sectionScrollWidth - sectionClientWidth
            )
          );
        }

        // Perform a single scroll operation
        scrollableSection.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      };

      // Second nested timeout - wait for vertical scrolling to complete before scrolling horizontally
      setTimeout(scrollHorizontally, 300);
    }
  }, 100);
  
  return true;
};

/**
 * Scrolls to the last section and its rightmost content.
 * This function:
 * 1. Finds the last section
 * 2. Scrolls it into view vertically
 * 3. Scrolls to the rightmost content horizontally
 * 
 * Note: This function uses two nested timeouts:
 * - First timeout (150ms): Waits for section to open
 * - Second timeout (50ms): Waits for vertical scrolling before horizontal scrolling
 * 
 * @param setScrollToSelectedBlox - Function to trigger section expansion
 * @param onScrollComplete - Optional callback for when scrolling completes
 */
export const scrollToLastSectionEnd = (
    setScrollToSelectedBlox: (value: boolean) => void,
    onScrollComplete?: () => void
) => {
    // Function to scroll the last section
    const scrollLastSection = () => {
        const sections = document.querySelectorAll('.section');
        const lastSection = sections[sections.length - 1];
        
        if (lastSection) {
            // First scroll section into view
            lastSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
            
            // Then scroll horizontally to the end - second nested timeout
            setTimeout(() => {
                // The section itself is the scrollable container
                const scrollWidth = lastSection.scrollWidth;
                const clientWidth = lastSection.clientWidth;
                lastSection.scrollTo({
                    left: scrollWidth - clientWidth,
                    behavior: 'smooth'
                });
                onScrollComplete?.();
            }, 50); // Delay for horizontal scroll
        }
    };

    // Set scroll state first to ensure section opens
    setScrollToSelectedBlox(true);
    
    // Wait for section to open and render - first timeout
    setTimeout(scrollLastSection, 150); // Delay for section opening
};