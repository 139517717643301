/**
 * PowerPoint Export Dialog Component
 * 
 * A dialog component that provides user feedback during the PowerPoint export process.
 * Features include:
 * - Export content preview
 * - Status updates
 * - Export controls
 */

import React, { useState } from 'react';
import { Dialog, Button, Intent, Classes, Spinner, Icon, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface ExportStep {
    id: string;
    name: string;
    icon: typeof IconNames[keyof typeof IconNames];
}

/**
 * Props for the PowerPoint export dialog
 */
interface PowerPointExportDialogProps {
    isOpen: boolean;                // Controls dialog visibility
    onClose: () => void;           // Handler for dialog close
    onExport: () => Promise<void>; // Handler for export action
}

/**
 * Dialog component for PowerPoint export process
 * Provides visual feedback and controls for the export operation
 */
export const PowerPointExportDialog: React.FC<PowerPointExportDialogProps> = ({
    isOpen,
    onClose,
    onExport
}) => {
    // State for tracking export state
    const [isExporting, setIsExporting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const exportSteps: ExportStep[] = [
        { 
            id: 'svg', 
            name: 'Converts process steps to PowerPoint', 
            icon: IconNames.DIAGRAM_TREE
        },
        { 
            id: 'powerpoint', 
            name: 'Generates presentation slides', 
            icon: IconNames.PRESENTATION 
        },
        { 
            id: 'download', 
            name: 'Downloads file when ready', 
            icon: IconNames.DOWNLOAD 
        }
    ];

    const handleExport = async () => {
        setIsExporting(true);
        setError(null);
        
        try {
            await onExport();
            onClose();
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the PowerPoint presentation';
            setError(errorMessage);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Export to PowerPoint"
            icon={IconNames.PRESENTATION}
            style={{
                width: '400px',
                borderRadius: '12px',
                overflow: 'hidden'
            }}
        >
            {/* Dialog content area */}
            <div className={Classes.DIALOG_BODY} style={{ padding: '20px' }}>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '16px'
                }}>
                    {/* Export steps description */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        padding: '16px',
                        backgroundColor: '#F8F9FE',
                        borderRadius: '8px'
                    }}>
                        {exportSteps.map((step) => (
                            <div
                                key={step.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px'
                                }}>
                                    <Icon icon={step.icon} color={Colors.GRAY1} />
                                    <span style={{ 
                                        color: Colors.GRAY1,
                                        flex: 1
                                    }}>
                                        {step.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Error message */}
                    {error && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '12px',
                            backgroundColor: '#FDF3F4',
                            borderRadius: '8px',
                            border: '1px solid #F5969C',
                            color: '#C23030'
                        }}>
                            <Icon icon={IconNames.ERROR} />
                            <span style={{ fontSize: '13px' }}>{error}</span>
                        </div>
                    )}
                </div>
            </div>

            {/* Dialog footer with action buttons */}
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        onClick={onClose}
                        disabled={isExporting}
                        style={{ 
                            borderRadius: '6px',
                            backgroundColor: '#F8F9FE'
                        }}
                    >
                        {error ? 'Close' : 'Cancel'}
                    </Button>
                    {!error && (
                        <Button
                            intent={Intent.SUCCESS}
                            icon={isExporting ? <Spinner size={16} /> : IconNames.PRESENTATION}
                            onClick={handleExport}
                            disabled={isExporting}
                            style={{ 
                                borderRadius: '6px'
                            }}
                        >
                            {isExporting ? 'Generating...' : 'Export PowerPoint'}
                        </Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
}; 