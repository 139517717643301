import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';

/**
 * A simple test page for iframe functionality
 */
export const SimpleIframeTest = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      <Box sx={{ py: 1 }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
          Simple Iframe Test Page
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Testing iframe embedding
        </Typography>
        
        <Paper elevation={2}>
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" sx={{ fontSize: '0.9rem', mb: 0.5 }}>
              Iframe Test
            </Typography>
          </Box>
          <Box sx={{ p: 0 }}>
            <iframe
              src="/iframe-process/c54c548c-d3d0-4d04-814f-8a4a7501f16e?size=full&viewMode=standard"
              style={{
                width: '100%',
                height: '550px',
                border: 'none',
                borderRadius: '2px'
              }}
              title="Process Iframe"
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}; 