/**
 * @fileoverview Main embedded process viewer component for the IFrame application.
 * This component orchestrates all the IFrame subcomponents and serves as the entry point
 * for the embedded application. It handles responsive layout, process loading and display,
 * theme adaptation, and integrates all UI elements including templates, process views,
 * chatbot, and settings. This is the root component loaded when the application is 
 * embedded as an iframe in external websites.
 */

import { useContext, useEffect, useCallback } from "react";
import { SvgServiceContext } from "../../../hooks/state/svg-service-provider";
import { useFabuState } from "../../../hooks/state/use-fabu-state";
import { useTheme, useMediaQuery, Box } from "@mui/material";

// Import process utilities and components
import { validateViewMode } from "../IFrameProcessUtils";
import { useIFrameState } from "../IFrameUtils";
import { getBaseIframeStyles } from "../IFrameStyles";
import { isInIframe } from "../../../utils/iframeMessaging";

// Import our new component hooks
import { useProcessParameters } from "./ProcessParameterManager";
import { TemplatesManager } from "./TemplatesManager";
import { useProcessOperations } from "./ProcessOperations";

// Import context hooks
import { useIFrameProcess } from '../IFrameProcessContext';

// Import components
import { IFrameProcessInitializer } from '../IFrameProcessInitializer';
import { IFrameChatbot } from "../IFrameChatbot";
import { IFrameProcessHeader } from '../IFrameProcessHeader';
import { IFrameProcessStates } from '../IFrameProcessStates';
import { IFrameSvgGenerator } from '../IFrameSvgGenerator';
import { IFrameStylesInjector } from '../IFrameStylesInjector';
import { IFrameProcessView } from '../IFrameProcessView';
import { IFrameLayout } from '../IFrameLayout';
import { IFrameProcessNotes } from '../IFrameProcessNotes/index';
import { IFrameWelcomeBox } from '../IFrameWelcomeBox';

/**
 * IframeProcessViewer component provides a simplified version of the ProcessEditor
 * specifically designed for embedding in iframes. It includes only essential
 * features for viewing processes with a compact layout optimized for embedding.
 * 
 * This version uses multiple smaller components and custom hooks to manage different
 * aspects of the process viewer functionality.
 * 
 * @component
 * @returns {JSX.Element} Rendered IframeProcessViewer component
 */
export default function IframeProcessViewer() {
  // Get theme and media query for responsive design
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Use the Process Parameters Manager to handle parameter consolidation
  const {
    effectiveProcessId,
    signature,
    viewMode,
    sizeConfig,
    selectedProcessRef,
    handleChangeViewMode,
    location,
    history
  } = useProcessParameters();
  
  // Use centralized state management from iFrame state hook
  const { 
    // Templates state
    showTemplates, setShowTemplates, 
    // Core viewer state
    isLoading, setIsLoading, containerRef,
    // Control panel state
    showControls, setShowControls, copied, setCopied, anchorEl, setAnchorEl,
    // Section visibility state
    closedSections, setClosedSections,
    // SVG and scroll state
    bloxSVGs, setBloxSVGs, initialScrollTarget, setInitialScrollTarget, hasSetInitialBlox,
    // Reset initialization function
    resetInitialization,
    // Chatbot state
    showChatbot, setShowChatbot, hasUnread, processViewAdjusted, setHasUnread, 
    setProcessViewAdjusted, toggleChatbot,
    // Welcome box state
    showWelcomeBox, setShowWelcomeBox, welcomeBoxCollapsed, setWelcomeBoxCollapsed, toggleWelcomeBoxCollapsed,
    // Process view collapse state
    processViewCollapsed, setProcessViewCollapsed, toggleProcessViewCollapsed
  } = useIFrameState(isMobile);
  
  // Get context state and actions
  const { state: processState, actions: processActions } = useIFrameProcess();
  
  // Use the Process Operations hook for template loading and process operations
  const { 
    loadTemplate, 
    startNewProcess, 
    handleInitializationComplete,
    toastHandler
  } = useProcessOperations({
    history,
    location,
    selectedProcessRef,
    processContext: { actions: processActions }
  });
  
  // Core state from useFabuState - to be migrated to context API
  const [processBloxes] = useFabuState('processBloxes');
  const [processSections, setProcessSections] = useFabuState('processSections');
  const [processName] = useFabuState('processName');
  const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
  
  // Get SVG service for generating blox visualizations
  const { generateSvgs } = useContext(SvgServiceContext);
  
  // Get the styles
  const iframeStyles = getBaseIframeStyles(sizeConfig, isMobile, showTemplates);
  
  // Content container style with dynamic chatbot adjustment
  const contentContainerStyle = {
    ...iframeStyles.contentContainer,
    width: processViewAdjusted && !isMobile ? 'calc(100% - 380px)' : '100%',
    marginRight: processViewAdjusted && !isMobile ? '380px' : '0',
    transition: 'width 0.3s ease-out, margin-right 0.3s ease-out',
  };

  // Ensure templates are loaded with page - set showTemplates to true by default
  useEffect(() => {
    // Show templates when component mounts
    if (!showTemplates) {
      setShowTemplates(true);
    }
  }, [showTemplates, setShowTemplates]);

  // Create a wrapped initialization complete handler that includes the required callbacks
  const wrappedInitHandler = (processData?: any) => {
    handleInitializationComplete(
      processData,
      {
        setIsLoading,
        resetInitialization,
        setSelectedBloxId,
        setInitialScrollTarget,
        hasSetInitialBlox
      },
      viewMode
    );
  };

  // Validate the view mode
  const processViewMode = validateViewMode(viewMode);

  // Render the main header
  const renderHeader = (
    <IFrameProcessHeader 
      processName={processName}
      sizeConfig={sizeConfig}
      iframeStyles={iframeStyles}
      viewMode={viewMode}
      changeViewMode={handleChangeViewMode}
      showChatbot={showChatbot}
      toggleChatbot={toggleChatbot}
      hasUnread={hasUnread}
      showControls={showControls}
      setAnchorEl={setAnchorEl}
      setShowControls={setShowControls}
      showTemplates={showTemplates}
      setShowTemplates={setShowTemplates}
    />
  );

  // Wrap the entire component with our IFrameAppProvider
  return (
    <IFrameProcessInitializer
      onInitializationComplete={wrappedInitHandler}
      signature={signature}
      messageProcessId={effectiveProcessId}
      viewMode={viewMode}
    >
      {/* Inject custom styles for iframe mode */}
      <IFrameStylesInjector
        sizeConfig={sizeConfig}
        isMobile={isMobile}
      />
      
      {/* Generate SVGs for process bloxes */}
      <IFrameSvgGenerator
        generateSvgs={generateSvgs}
        onSvgsGenerated={setBloxSVGs}
      />
      
      {isLoading ? (
        <IFrameProcessStates
          type="loading"
          iframeStyles={iframeStyles}
          sizeConfig={sizeConfig}
          contentContainerStyle={contentContainerStyle}
        />
      ) : !processBloxes?.length ? (
        <Box 
          sx={{
            ...iframeStyles.outerContainer,
            height: 'auto',
            minHeight: '100vh',
            overflowY: 'auto',
            paddingBottom: '40px', // Add extra padding at the bottom
          }}
        >
          <Box 
            sx={{
              ...contentContainerStyle,
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            {/* Show welcome box for empty processes too */}
            {showWelcomeBox && (
              <IFrameWelcomeBox
                iframeStyles={iframeStyles}
                sizeConfig={sizeConfig}
                isCollapsed={welcomeBoxCollapsed}
                onToggleCollapsed={toggleWelcomeBoxCollapsed}
                isMobile={isMobile}
              />
            )}
            <IFrameProcessStates
              type="empty"
              iframeStyles={iframeStyles}
              sizeConfig={sizeConfig}
              contentContainerStyle={{
                ...contentContainerStyle, 
                width: '100%', 
                marginRight: 0,
                height: 'auto',
                overflow: 'visible'
              }}
              renderTemplates={() => (
                <TemplatesManager
                  iframeStyles={iframeStyles}
                  sizeConfig={sizeConfig}
                  isMobile={isMobile}
                  selectedProcessId={selectedProcessRef.current}
                  showTemplates={showTemplates}
                  setShowTemplates={setShowTemplates}
                  onLoadTemplate={loadTemplate}
                  onStartNewProcess={startNewProcess}
                  showToast={toastHandler}
                />
              )}
            />
          </Box>
        </Box>
      ) : (
        <IFrameLayout
          iframeStyles={iframeStyles}
          contentContainerStyle={contentContainerStyle}
          header={renderHeader}
          chatbot={
            <IFrameChatbot
              iframeStyles={iframeStyles}
              showChatbot={showChatbot}
              setShowChatbot={setShowChatbot}
              processBloxes={processBloxes}
              processSections={processSections}
              isLoading={isLoading}
              isMobile={isMobile}
              setProcessViewAdjusted={setProcessViewAdjusted}
              setHasUnread={setHasUnread}
            />
          }
          settingsPanel={null} // Commented out settings panel
          templates={
            <TemplatesManager
              iframeStyles={iframeStyles}
              sizeConfig={sizeConfig}
              isMobile={isMobile}
              selectedProcessId={selectedProcessRef.current}
              showTemplates={showTemplates}
              setShowTemplates={setShowTemplates}
              onLoadTemplate={loadTemplate}
              onStartNewProcess={startNewProcess}
              showToast={toastHandler}
            />
          }
          welcomeBox={
            <IFrameWelcomeBox
              iframeStyles={iframeStyles}
              sizeConfig={sizeConfig}
              isCollapsed={welcomeBoxCollapsed}
              onToggleCollapsed={toggleWelcomeBoxCollapsed}
              isMobile={isMobile}
            />
          }
          hasTemplatesVisible={showTemplates}
          hasWelcomeBoxVisible={showWelcomeBox}
          processViewCollapsed={processViewCollapsed}
          onToggleProcessViewCollapsed={toggleProcessViewCollapsed}
        >
          <IFrameProcessView
            containerRef={containerRef}
            bloxSVGs={bloxSVGs}
            setProcessSections={setProcessSections}
          />
        </IFrameLayout>
      )}
      
      {/* Update IFrameProcessNotes to use effectiveProcessId */}
      <IFrameProcessNotes
        processName={processName}
        processId={effectiveProcessId || 'unknown'}
        iframeStyles={iframeStyles}
        isMobile={isMobile}
      />
      
    </IFrameProcessInitializer>
  );
} 