import { ALD } from "./BloxSchema/ald";
import { APCVD } from "./BloxSchema/apcvd";
import { Bake } from "./BloxSchema/bake";
import { BloxTypes } from "./BloxSchema/base-blox";
import { CMP } from "./BloxSchema/cmp";
import { CustomClean } from "./BloxSchema/customclean";
import { Develop } from "./BloxSchema/develop";
import { DirectWriteLitho } from "./BloxSchema/direct-write-litho";
import { DopeDiffusion } from "./BloxSchema/doping-diffusion";
import { DropCast } from "./BloxSchema/drop-cast";
import { EbeamLitho } from "./BloxSchema/ebeam-litho";
import { EbeamEvap } from "./BloxSchema/ebeamevap";
import { Electroplate } from "./BloxSchema/electroplate";
import { FlipStack } from "./BloxSchema/flipstack";
import { FloodExpose } from "./BloxSchema/flood-expose";
import { HMDSVapor } from "./BloxSchema/HMDS-vapor";
import { ICPRIE } from "./BloxSchema/icp-rie";
import { ImprintLitho } from "./BloxSchema/imprint-litho";
import { InterferenceLitho } from "./BloxSchema/interference-litho";
import { IonImplantation } from "./BloxSchema/ion-implantation";
import { IonMilling } from "./BloxSchema/ion-milling";
import { Liftoff } from "./BloxSchema/liftoff";
import { LPCVD } from "./BloxSchema/lpcvd";
import { MaskAlign } from "./BloxSchema/mask-align";
import { MBE } from "./BloxSchema/mbe";
import { MOCVD } from "./BloxSchema/mocvd";
import { ParyleneDep } from "./BloxSchema/parylene-dep";
import { PECVD } from "./BloxSchema/pecvd";
import { PlasmaAsh } from "./BloxSchema/plasma-ash";
import { PlasmaDescum } from "./BloxSchema/plasma-descum";
import { RCAClean } from "./BloxSchema/rcaclean";
import { RIE } from "./BloxSchema/rie";
import { RTA } from "./BloxSchema/rta";
import { ScanningProbeLitho } from "./BloxSchema/scanning-probe-litho";
import { SpinCoat } from "./BloxSchema/spin-coat";
import { SpinDopant } from "./BloxSchema/spin-coat-dopant";
import { SpinCoatResist } from "./BloxSchema/spin-coat-resist";
import { SpinCoatUnified } from "./BloxSchema/spin-coat-unified";
import { Sputter } from "./BloxSchema/sputter";
import { StartBlox } from "./BloxSchema/start-blox";
import { StepperLitho } from "./BloxSchema/stepper-litho";
import { StripResist } from "./BloxSchema/strip-resist";
import { SubstrateStack } from "./BloxSchema/substrate-stack";
import { ThermalOxide } from "./BloxSchema/thermal-oxide";
import { ThermalEvap } from "./BloxSchema/thermalevap";
import { WaferBonding } from "./BloxSchema/wafer-bonding";
import { WetGrowth } from "./BloxSchema/wet-growth";
import { Wetetch } from "./BloxSchema/wetetch";

const emptyKeyFields: string[] = [];

export const bloxKeyFields:  {[key in BloxTypes]: string[]} = {
    SUBSTACK: emptyKeyFields, // ["material","thickness"] as (keyof SubstrateStack)[], // this will help with getting the actual keys to autocomplete as you add them
    STARTBLOX: emptyKeyFields, // ["layers"] as (keyof StartBlox)[],
    CUSTOMBLOX: emptyKeyFields,
    CUSTOMCLEAN: ["cleanChemical","cleanTime","cleanTemp"] as (keyof CustomClean)[],
    RCACLEAN: ["cleanChemicalSC1","cleanChemicalSC2","dipHF"] as (keyof RCAClean)[],
    SPINCOAT: ["material","filmThickness","spinSpeed","spinTime"] as (keyof SpinCoat)[],
    SPINCOATRESIST: ["resist","filmThickness","spinSpeed","spinTime"] as (keyof SpinCoatResist)[],
    SPINDOPANT: ["material","filmThickness","spinSpeed","spinTime"] as (keyof SpinDopant)[],
    SPINCOATUNIFIED: ["material", "spunMaterialType", "dopantType", "filmThickness", "spinSpeed", "spinTime"] as (keyof SpinCoatUnified & string)[],
    BAKE: ["bakeTemp","bakeTime"] as (keyof Bake)[],
    DEVELOP: ["developer","developTime"] as (keyof Develop)[],
    MASKALIGN: ["wavelength","expDose"] as (keyof MaskAlign)[],
    STEPPERLITHO: ["wavelength","expDose","reticleID"] as (keyof StepperLitho)[],
    DIRECTWRITELITHO: ["laserWavelength","expDose"] as (keyof DirectWriteLitho)[],
    IMPRINTLITHO: ["imprintDepth"] as (keyof ImprintLitho)[],
    SCANNINGPROBELITHO: ["imprintDepth","tipRadius"] as (keyof ScanningProbeLitho)[], 
    FLOODEXPOSE: ["wavelength","expDose"] as (keyof FloodExpose)[],
    EBL: ["exposureDose","accelVoltage","beamCurrent"] as (keyof EbeamLitho)[],
    ICPRIE: ["gas","etchDepth"] as (keyof ICPRIE)[],
    RIE: ["gas","etchDepth"] as (keyof RIE)[],
    IONMILL: ["gas","etchDepth"] as (keyof IonMilling)[],
    CMP: ["slurry","pad"] as (keyof CMP)[],
    HMDSVAPOR: ["material"] as (keyof HMDSVapor)[],
    DROPCAST: ["material","dispenseVolume"] as (keyof DropCast)[],
    WETGROWTH: ["material","thickness"] as (keyof WetGrowth)[],
    SPUTTER: ["sputterMaterial","sputterThickness"] as (keyof Sputter)[],
    WETETCH: ["etchChemical","etchDepth"] as (keyof Wetetch)[],
    FLIPSTACK: [] as (keyof FlipStack)[],
    MOCVD: ["material","thickness"] as (keyof MOCVD)[],
    MBE: ["material","thickness"] as (keyof MBE)[],
    ALD: ["material","thickness"] as (keyof ALD)[],
    PECVD: ["material","thickness"] as (keyof PECVD)[],
    LPCVD: ["material","thickness"] as (keyof LPCVD)[],
    APCVD: ["material","thickness"] as (keyof APCVD)[],
    EBEAMEVAP: ["material","thickness"] as (keyof EbeamEvap)[],
    THERMALEVAP: ["material","thickness"] as (keyof ThermalEvap)[],
    THERMALOXIDATION: ["oxide","thermalOxTemp","thermalOxTime"] as (keyof ThermalOxide)[],
    ELECTROPLATE: ["material","thickness"] as (keyof Electroplate)[],
    LIFTOFF: ["chemical","time","temp"] as (keyof Liftoff)[],
    PARYLENEDEP: ["paryleneType","thickness"] as (keyof ParyleneDep)[],
    STRIPRESIST: ["chemical"] as (keyof StripResist)[],
    IONIMPLANT: ["material","dose","depth","angle"] as (keyof IonImplantation)[],
    DOPEDIFFUSION: ["diffusionTime","temperature"] as (keyof DopeDiffusion)[],
    RTA: ["annealTemp1","annealTime1"] as (keyof RTA)[],
    WAFERBONDING: ["material","bondingAgentMaterial"] as (keyof WaferBonding)[],
    "INTERFERENCE-LITHO": ["lightWavelength","expDose"] as (keyof InterferenceLitho)[],
    "PLASMA-ASH": ["gas","etchTime","etchDepth"] as (keyof PlasmaAsh)[],
    "PLASMA-DESCUM": ["gas","etchTime"] as (keyof PlasmaDescum)[],
};
