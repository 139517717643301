import { displayMap } from '../Data/display-mappings';
import { Tab } from '../Data/enums';
import { StartBlox } from '../Data/BloxSchema/start-blox';
import { Paragraph, HeadingLevel, TextRun, Packer, Document } from 'docx';
import { Colors } from '@blueprintjs/core';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { useReadProcess } from '../hooks/DataFetching/use-fetch-process';
import { getMaterials, Material } from '../hooks/material-targets';

export const useProcessExporter = (processId: string, isModule?: boolean) => {
    const [processUsername] = useFabuState('processUsername');
    const { data, isLoading, error } = useReadProcess(processId, isModule);
    
    const [processSections] = useFabuState('processSections');

    const generateDocument = async (): Promise<Blob> => {
        if (!data) return new Blob();
        
        const paragraphs: Paragraph[] = [];
        const indentDelta = 720;
        const indent1 = {left: indentDelta };
        const indent2 = {left: indentDelta * 2 };
        const indent3 = {left: indentDelta * 3 };
        
        // Header with user name and process name
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: processUsername,
                    bold: true
                })
            ]
        }));
        
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: data.processName,
                    bold: true,
                    color: Colors.DARK_GRAY1,
                })
            ]
        }));
        paragraphs.push(new Paragraph({ text: ''}));
    
        paragraphs.push(new Paragraph({
            children: [
                new TextRun('Process Link: '),
                new TextRun({ 
                    text: `https://www.fabublox.com/process-editor/${data.processId}`, 
                    bold: true
                })
            ]
        }));
        
        // Process Fields
        paragraphs.push(new Paragraph({ 
            children: [
                new TextRun({ text: 'Description:', bold: true }),
                new TextRun(data.desc ?? 'None')
            ]
        }));
    
        paragraphs.push(new Paragraph({ 
            children: [
                new TextRun({ text: 'Reference:', bold: true }),
                new TextRun(data.reference ?? 'None')
            ]
        }));
        
        paragraphs.push(new Paragraph({ text: ''}));
        paragraphs.push(new Paragraph({ 
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({ 
                    text: 'Process Flow:', 
                    bold: true 
                })
            ]
        }));
        const sectionBeginningMap: {[key: string]: string} = {};
        for (const section of processSections) {
            if (section.bloxIds.length === 0 ) continue;
            sectionBeginningMap[section.bloxIds[0]] = section.sectionName ?? 'Unnamed Section';
        }
        
        data.bloxes.forEach((blox: any, index: number) => {
            if (index === 0) {
                if (sectionBeginningMap[blox.id]) {
                    paragraphs.push(new Paragraph({ text: ''}));
                    paragraphs.push(new Paragraph({
                        text: sectionBeginningMap[blox.id],
                        heading: HeadingLevel.HEADING_2
                    }));
                }
                paragraphs.push(new Paragraph({ text: ''}));
                paragraphs.push(new Paragraph({
                    text: 'Substrate Stack:',
                    heading: HeadingLevel.HEADING_4,
                    indent: indent1
                }));
        
                const startBlox = blox as StartBlox;
                startBlox.layers?.forEach((layer, index) => {
                    const layerLabel = layer.layerLabel?.length ? layer.layerLabel : `Layer ${index + 1}`;
                    const thicknessLabel = layer.layerSimulationThickness ? layer.layerSimulationThickness : '1000';
                    const thicknessUnitLabel = layer.layerSimulationThicknessUnit ? layer.layerSimulationThicknessUnit : 'nm';
                    paragraphs.push(new Paragraph({
                        text: `${layerLabel}: ${thicknessLabel} ${thicknessUnitLabel}`,
                        bullet: {
                            level: 0,
                        },
                        indent: indent2
                    }));
                });
                if (blox.commentField && blox.commentField !== "") {
                    paragraphs.push(new Paragraph({
                        text: `Comments: ${blox.commentField}`,
                        bullet: {
                            level: 0
                        },
                        indent: indent2
                    }));
                }
            } else {
                if (sectionBeginningMap[blox.id]) {
                    paragraphs.push(new Paragraph({ text: ''}));
                    paragraphs.push(new Paragraph({
                        text: sectionBeginningMap[blox.id],
                        heading: HeadingLevel.HEADING_2
                    }));
                }
                paragraphs.push(new Paragraph({ text: ''}));
                paragraphs.push(new Paragraph({ text: `Step ${index}:`, heading: HeadingLevel.HEADING_4, indent: indent1 }));
                const toolNameParagraph = new Paragraph({
                    bullet: {
                        level: 0
                    },
                    children: [
                        new TextRun({
                            text: `Tool Name: `,
                            bold: false
                        }),
                        new TextRun({
                            text: `${blox.toolName ?? 'None'}`,
                            bold: true
                        })
                    ],
                    indent: indent2
                });
                paragraphs.push(toolNameParagraph);
                paragraphs.push(new Paragraph({ 
                    text: `Name: ${blox.name}`,
                    bullet: {
                        level: 0
                    },
                    indent: indent2
                }));
        
                Object.keys(blox).forEach(key => {
                    const displayProps = (displayMap as any)[blox.bloxType];
                    
                    if ((displayProps && displayProps[key] && !displayProps[key].tabs.includes(Tab.EXPERIMENTAL)) || key.includes('Unit')) {
                        return;
                    }
                
                    if (!["stackChange", "id", "bloxType", "commentField", "customFields", "name", "toolName"].includes(key) && blox[key] !== null && blox[key] !== "" && displayProps[key]) {
                        const unitKey = `${key}Unit`;

                        const propLabel = displayProps[key].label ?? key;
                        let propValue = `${blox[key]}`;
                        
                        if (propValue && propValue !== "" && blox[unitKey]) {
                            propValue += ` ${blox[unitKey]}`;
                        }
                
                        const bulletParagraph = new Paragraph({
                            bullet: {
                                level: 1
                            },
                            children: [
                                new TextRun({
                                    text: `${propLabel}: `,
                                    bold: false
                                }),
                                new TextRun({
                                    text: propValue,
                                    bold: false
                                })
                            ],
                            indent: indent3
                        });
                
                        paragraphs.push(bulletParagraph);
                    }
                });
        
                if (blox.customFields) {
                    Object.keys(blox.customFields).forEach(key => {
                        const fieldValueArr = blox.customFields[key];
                        if(fieldValueArr.length === 2 && fieldValueArr[1]) {
                            const field = fieldValueArr[0];
                            const value = fieldValueArr[1];
                            paragraphs.push(new Paragraph({ 
                                bullet: {
                                    level: 1
                                },
                                children: [
                                    new TextRun({
                                        text: `${field}: `,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: value,
                                        bold: false
                                    })
                                ],
                                indent: indent3
                            }));
                        }
                        
                    });
                }
                if (blox.commentField && blox.commentField !== "") {
                    paragraphs.push(new Paragraph({
                        text: `Comments: ${blox.commentField}`,
                        bullet: {
                            level: 0
                        },
                        indent: indent2
                    }));
                }
            }
        });
    
        const doc = new Document({
            sections: [{
                properties: {},
                children: paragraphs
            }]
        });
    
        return Packer.toBlob(doc);
    };

    /**
     * Extracts unique tool names from process bloxes
     * @returns Array of unique tool names, sorted alphabetically
     */
    const extractUniqueTools = (): string[] => {
        if (!data || !data.bloxes) return [];
        
        // Use a Set to automatically handle uniqueness
        const uniqueTools = new Set<string>();
        
        // Start at index 1 to skip the StartBlox (substrate stack)
        for (let i = 1; i < data.bloxes.length; i++) {
            // Only add non-empty tool names
            if (data.bloxes[i].toolName && data.bloxes[i].toolName.trim() !== '') {
                uniqueTools.add(data.bloxes[i].toolName.trim());
            }
        }
        
        // Convert to array and sort alphabetically
        return Array.from(uniqueTools).sort();
    };

    /**
     * Generates a document containing only the process requirements (tools and materials)
     * @returns Promise resolving to a Blob containing the document
     */
    const generateRequirementsDocument = async (): Promise<Blob> => {
        if (!data) return new Blob();
        
        const paragraphs: Paragraph[] = [];
        const indentDelta = 720;
        const indent1 = {left: indentDelta};
        
        // Header with user name and process name
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: processUsername,
                    bold: true
                })
            ]
        }));
        
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: data.processName,
                    bold: true,
                    color: Colors.DARK_GRAY1,
                })
            ]
        }));
        paragraphs.push(new Paragraph({ text: ''}));

        // Add author information
        paragraphs.push(new Paragraph({
            children: [
                new TextRun({ text: 'Author: ', bold: true }),
                new TextRun(data.username || processUsername || 'Unknown')
            ]
        }));

        paragraphs.push(new Paragraph({
            children: [
                new TextRun('Process Link: '),
                new TextRun({ 
                    text: `https://www.fabublox.com/process-editor/${data.processId}`, 
                    bold: true
                })
            ]
        }));
        
        paragraphs.push(new Paragraph({ text: ''}));
        
        // Tools Section
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({ 
                    text: 'Required Tools:', 
                    bold: true 
                })
            ]
        }));
        
        const tools = extractUniqueTools();
        if (tools.length > 0) {
            tools.forEach(tool => {
                paragraphs.push(new Paragraph({
                    text: tool,
                    bullet: { level: 0 },
                    indent: indent1
                }));
            });
        } else {
            paragraphs.push(new Paragraph({
                text: 'No tools specified',
                indent: indent1
            }));
        }
        
        // Materials Section
        paragraphs.push(new Paragraph({ text: ''}));
        paragraphs.push(new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({ 
                    text: 'Required Materials:', 
                    bold: true 
                })
            ]
        }));
        
        // Use getMaterials from the imported hook
        const materials = getMaterials(data.bloxes, data.bloxes.length - 1);
        if (materials.length > 0) {
            materials.forEach((material: Material) => {
                paragraphs.push(new Paragraph({
                    text: material.materialLabel,
                    bullet: { level: 0 },
                    indent: indent1
                }));
            });
        } else {
            paragraphs.push(new Paragraph({
                text: 'No materials specified',
                indent: indent1
            }));
        }
        
        const doc = new Document({
            sections: [{
                properties: {},
                children: paragraphs
            }]
        });

        return Packer.toBlob(doc);
    };

    const exportData = async () => {
        const documentContent = await generateDocument();
    
        const blob = new Blob([documentContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        const effectiveProcessName = data?.processName || "Unnamed_Process";
        a.download = `${effectiveProcessName.replace(/[^a-z0-9]/gi, '_')}.docx`;
        a.click();
        URL.revokeObjectURL(url);
    };

    /**
     * Exports the process requirements (tools and materials) as a DOCX file
     */
    const exportRequirementsData = async () => {
        const documentContent = await generateRequirementsDocument();
    
        const blob = new Blob([documentContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        const effectiveProcessName = data?.processName || "Unnamed_Process";
        a.download = `${effectiveProcessName.replace(/[^a-z0-9]/gi, '_')}_requirements.docx`;
        a.click();
        URL.revokeObjectURL(url);
    };
    
    return {
        exportData,
        exportRequirementsData,
        isLoading,
        error
    };
};

