import React from 'react';
import { Box, Typography, Container, Button, Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/**
 * A simple redirect page to the actual process
 */
export const DirectTestPage = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      <Box sx={{ py: 1 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Direct Process Link
        </Typography>
        <Typography variant="body2" sx={{ mb: 1.5 }}>
          Click to open the process viewer directly
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary"
          href="/iframe-process/c54c548c-d3d0-4d04-814f-8a4a7501f16e?size=full&viewMode=standard"
          size="small"
          endIcon={<OpenInNewIcon sx={{ fontSize: '0.9rem' }} />}
          sx={{ py: 0.5, px: 1.5, textTransform: 'none', fontSize: '0.8rem' }}
        >
          Open Process Viewer
        </Button>
        
        <Box sx={{ mt: 2, p: 1.5, bgcolor: '#f5f5f5', borderRadius: '4px' }}>
          <Typography variant="caption" display="block" sx={{ mb: 0.5, color: '#666' }}>
            URL:
          </Typography>
          <Link 
            href="/iframe-process/c54c548c-d3d0-4d04-814f-8a4a7501f16e?size=full&viewMode=standard"
            sx={{ fontSize: '0.75rem', wordBreak: 'break-all' }}
          >
            /iframe-process/c54c548c-d3d0-4d04-814f-8a4a7501f16e?size=full&viewMode=standard
          </Link>
        </Box>
      </Box>
    </Container>
  );
}; 