/**
 * @fileoverview Process-specific utility functions for the IFrame components.
 * This file provides functionality for clipboard operations, embed code generation,
 * process visualization mode management, and various helper functions specifically
 * related to process data manipulation and presentation. It complements the more
 * general IFrameUtils by focusing on process-specific operations.
 */

import React from 'react';
import { History } from 'history';
import { Intent } from '@blueprintjs/core';
import { UseQueryResult } from 'react-query';
import { SizePreset, SizeConfig } from './IFrameStyles';
import { ViewMode } from './hooks/useFabuUIState';

/**
 * Clipboard and embed code utilities for the IFrameProcessViewer
 */

// Define a simple showToast function to avoid dependency issues
// This mimics the behavior of the main showToast function
const showToast = (props: { message: string; intent: string; timeout: number }) => {
  // In a real implementation, this would call the actual toast service
};

/**
 * Copy the current iframe URL to clipboard
 */
export const copyToClipboard = async (text: string, onCopy?: () => void, onError?: (error: any) => void) => {
  try {
    await navigator.clipboard.writeText(text);
    if (onCopy) {
      onCopy();
    }
  } catch (err) {
    if (onError) {
      onError(err);
    }
  }
};

/**
 * Get the shareable embed code for the current process
 */
export const getEmbedCode = (sizeConfig: SizeConfig) => {
  const currentUrl = window.location.href;
  return `<iframe src="${currentUrl}" width="${sizeConfig.width}" height="${sizeConfig.height}" frameborder="0" allowfullscreen></iframe>`;
};

/**
 * Copy the embed code to clipboard
 */
export const copyEmbedCode = (sizeConfig: SizeConfig, setCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
  navigator.clipboard.writeText(getEmbedCode(sizeConfig)).then(
    () => {
      setCopied(true);
      showToast({
        message: "Embed code copied to clipboard",
        intent: 'success',
        timeout: 2000
      });
      setTimeout(() => setCopied(false), 2000);
    },
    (err) => {
      showToast({
        message: "Failed to copy embed code",
        intent: 'danger',
        timeout: 2000
      });
    }
  );
};

/**
 * Change the view mode and update URL parameters
 */
export const changeViewMode = (
  newViewMode: ViewMode, 
  history: History, 
  location: {pathname: string, search: string}
) => {
  const params = new URLSearchParams(location.search);
  params.set('view', newViewMode);
  history.replace({
    pathname: location.pathname,
    search: params.toString()
  });
};

/**
 * Change the size preset and update URL parameters
 */
export const changeSize = (
  newSize: SizePreset, 
  history: History, 
  location: {pathname: string, search: string},
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const params = new URLSearchParams(location.search);
  params.set('size', newSize);
  history.replace({
    pathname: location.pathname,
    search: params.toString()
  });
  
  // Force re-render with new size
  setShowControls(false);
  // Adding this will trigger a refresh with the new size applied
  window.dispatchEvent(new Event('resize'));
};

/**
 * Helper function to log process structure for debugging
 */
export const logProcessStructure = (
  processBloxes: any[], 
  processSections: any[], 
  closedSections: {[key: string]: boolean}
) => {
  processSections.forEach((section, idx) => {
    // Check if all bloxes in this section exist in processBloxes
    const missingBloxes = section.bloxIds.filter(
      (bloxId: string) => !processBloxes.some(blox => blox.id === bloxId)
    );

  });
  
  // Check if all bloxes are assigned to sections
  const allSectionBloxIds = processSections.flatMap(section => section.bloxIds);
  const unassignedBloxes = processBloxes.filter(
    blox => !allSectionBloxIds.includes(blox.id)
  );
};

/**
 * Validates and normalizes view mode to ensure it's one of the supported values
 * @param viewMode The view mode to validate
 * @returns A valid view mode ('list', 'table', 'standard', or 'print')
 */
export const validateViewMode = (viewMode: string | null): ViewMode => {
  if (viewMode === 'list' || viewMode === 'table' || viewMode === 'standard' || viewMode === 'print') {
    return viewMode;
  }
  return 'standard';
}; 