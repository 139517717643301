import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { getDefaultColor } from "../material-mappings";

export interface SpinCoatUnified extends BaseBlox {
    material: string | null;
    spunMaterialType: SpunMaterialType | null;
    dopantType: string | null;
    dopantTypeHidden: () => boolean | null;
    dispenseVolume: number | null;
    dispenseVolumeUnit: Units | null;
    spinSpeed: number | null;
    spinSpeedUnit: Units | null;
    spinTime: number | null;
    spinTimeUnit: Units | null;
    filmThickness: number | null;
    filmThicknessUnit: Units | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    layerLabel: string | null;
    fillGaps: boolean | null;
    //SEMIFAB
}

export enum SpunMaterialType {
    POSITIVE = "Positive",
    NEGATIVE = "Negative",
    IMAGEREVERSAL = "Image Reversal",
    LOR = "Lift-Off Resist (LOR)",
    ADHESION_PROMOTER = "Adhesion Promoter",
    NONE = "Non-Resist",
    DOPANT = "Dopant",
    SEPERATOR = "──────────" // for display purposes only
}

// this mapping maps the enum (which is saved in the DB, so cannot be modified, to the labels which are displayed in the blox info)
export const SpunMaterialLabel: {[key in SpunMaterialType]: string} = {
    [SpunMaterialType.POSITIVE] : "Positive Resist",
    [SpunMaterialType.NEGATIVE] : "Negative Resist",
    [SpunMaterialType.IMAGEREVERSAL] : "Image Reversal Resist",
    [SpunMaterialType.LOR] : "Lift-Off Resist (LOR)",
    [SpunMaterialType.ADHESION_PROMOTER] : "Adhesion Promoter",
    [SpunMaterialType.NONE] : "Non-Resist",
    [SpunMaterialType.DOPANT] : "Dopant",
    [SpunMaterialType.SEPERATOR]: "──────────"
}

export function checkIfResist(spunMaterialType: SpunMaterialType) {
    if (spunMaterialType === SpunMaterialType.POSITIVE || 
        spunMaterialType === SpunMaterialType.NEGATIVE || 
        spunMaterialType === SpunMaterialType.IMAGEREVERSAL || 
        spunMaterialType === SpunMaterialType.LOR ||
        spunMaterialType === SpunMaterialType.ADHESION_PROMOTER) {
        return true;
    } else {
        return false;
    }
}

export function checkIfDevelopableResist(spunMaterialType: SpunMaterialType) {
    if (spunMaterialType === SpunMaterialType.POSITIVE || 
        spunMaterialType === SpunMaterialType.NEGATIVE || 
        spunMaterialType === SpunMaterialType.IMAGEREVERSAL) {
        return true;
    } else {
        return false;
    }
}


export const spinCoatUnifiedDisplay: DisplayMap = {
    
    
    spunMaterialType: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Material Type",
        options: [
            SpunMaterialType.POSITIVE, 
            SpunMaterialType.NEGATIVE, 
            SpunMaterialType.IMAGEREVERSAL, 
            SpunMaterialType.LOR, 
            SpunMaterialType.SEPERATOR, 
            SpunMaterialType.NONE,
            SpunMaterialType.ADHESION_PROMOTER,
            SpunMaterialType.DOPANT], 
        optionLabels: SpunMaterialLabel,
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Material",
        placeholder: "Enter material name",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    dopantType: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dopant Type",
        placeholder: "Enter e.g. p+/n+ or dopant species",
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    dispenseVolume: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dispense Volume",
        placeholder: "Enter dispense volume",
        isNumber: true,
        order: 4,
        units: [Units.NL,Units.UL,Units.ML],
        tabs: [Tab.EXPERIMENTAL],
    },
    filmThickness: {
      fieldType: DisplayFieldTypes.Input,
      label: "Film Thickness",
      placeholder: "Enter film thickness",
      isNumber: true,
      order: 5,
      units: [Units.NM, Units.MICRON],
      tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    spinSpeed: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Speed",
        placeholder: "Enter spin speed",
        isNumber: true,
        order: 6,
        units: [Units.RPM],
        tabs: [Tab.EXPERIMENTAL],
    },
    spinTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Time",
        placeholder: "Enter spin time",
        isNumber: true,
        order: 7,
        units: [Units.SEC,Units.MIN],
        tabs: [Tab.EXPERIMENTAL],
    },

    //DISPLAY
    fillGaps: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Fill Gaps",
        order: 0,
        tabs: [Tab.DISPLAY,Tab.SEMIFAB],
        defaultValue: true,
        infoContent: "If toggled off, a uniform, conformal coating is used. If toggled on, a planar layer is deposited where the thickness is referenced from the highest point in the cross section."
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },

    //SEMIFAB
}

export const getSpinCoatUnified = ({stepNumber}: BloxArgs): SpinCoatUnified => ({
        name: `Spin-Coat ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.SpinCoatUnified,
        material: null,
        spunMaterialType: SpunMaterialType.POSITIVE,
        dopantType: null,
        dopantTypeHidden: function () {
            return this.spunMaterialType !== SpunMaterialType.DOPANT;
        },
        fillGaps: true,
        dispenseVolume: null,
        dispenseVolumeUnit: Units.UL,
        spinSpeed: null,
        spinSpeedUnit: Units.RPM,
        spinTime: null,
        spinTimeUnit: Units.SEC,
        filmThickness: null,
        filmThicknessUnit: Units.MICRON,
        commentField: null,
        layerColor: getDefaultColor(BloxTypes.SpinCoatUnified),
        layerThickness: 10,
        layerLabel: null,
        customFields: { },
        //SEMIFAB
});
