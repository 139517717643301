/**
 * @fileoverview Provides a collection of React hooks for managing Blox data operations
 * in the Fabublox application. These hooks handle CRUD operations (Create, Read, Update, Delete)
 * for Blox entities, with built-in authentication and error handling.
 */

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Blox, CreateBloxRequest, UpdateBloxRequest } from '../../__generated__/Blox';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { showToast } from '../..';

/**
 * Fetches Bloxes from a specified endpoint with authentication.
 * 
 * @param {string} endpoint - The API endpoint to fetch Bloxes from ('owned' or 'shared')
 * @param {() => void} getAccessTokenSilently - Auth0 function to get access token
 * @returns {Promise<Blox[]>} Array of Blox objects
 * @throws {Error} If the fetch request fails
 */
async function fetchBloxes(endpoint: string, getAccessTokenSilently: () => void): Promise<Blox[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/bloxes/${endpoint}`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch bloxes from endpoint: ${endpoint}`);
    }
    return response.json();
}

/**
 * Hook to fetch Bloxes owned by the current user.
 * 
 * @returns {UseQueryResult<Blox[], Error>} Query result containing owned Bloxes
 */
export function useOwnedBloxes() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Blox[], Error>('ownedBloxes', () => fetchBloxes('owned', getAccessTokenSilently));
}

/**
 * Hook to fetch Bloxes shared with the current user.
 * 
 * @returns {UseQueryResult<Blox[], Error>} Query result containing shared Bloxes
 */
export function useSharedBloxes() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Blox[], Error>('sharedBloxes', () => fetchBloxes('shared', getAccessTokenSilently));
}

/**
 * Updates an existing Blox with new data.
 * 
 * @param {string} bloxId - ID of the Blox to update
 * @param {UpdateBloxRequest} data - New data for the Blox
 * @param {() => Promise<string>} getAccessTokenSilently - Auth0 function to get access token
 * @throws {Error} If the update request fails
 */
async function updateBlox(bloxId: string, data: UpdateBloxRequest, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/update/${bloxId}`, requestOptions);
    if (!response.ok) {
        throw new Error(`Failed to update blox: ${bloxId}`);
    }
}

/**
 * Hook to update an existing Blox.
 * Provides mutation functionality with automatic cache invalidation and error handling.
 * 
 * @returns {UseMutationResult} Mutation result for updating a Blox
 */
export function useUpdateBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(
        async ({ bloxId, data }: { bloxId: string; data: UpdateBloxRequest }) => 
            updateBlox(bloxId, data, getAccessTokenSilently),
        {
            onSuccess: (_data, _variables, _context) => {
                queryClient.invalidateQueries('ownedBloxes');
            },
            onError: (error: any, _variables, _context) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Update failed with ${error.message}`);
            },
        }
    );
}

/**
 * Creates a new Blox with the provided data.
 * 
 * @param {CreateBloxRequest} data - Data for the new Blox
 * @param {() => Promise<string>} getAccessTokenSilently - Auth0 function to get access token
 * @returns {Promise<Blox>} The created Blox object
 * @throws {Error} If the create request fails
 */
async function createBlox(data: CreateBloxRequest, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/create/`, requestOptions);
    if (!response.ok) {
        throw new Error('Save Failed');
    }
    return response.json();
}

/**
 * Hook to create a new Blox.
 * Provides mutation functionality with automatic cache invalidation and success/error handling.
 * 
 * @returns {UseMutationResult} Mutation result for creating a Blox
 */
export function useCreateBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(
        (bloxData: CreateBloxRequest) => createBlox(bloxData, getAccessTokenSilently),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('ownedBloxes');
                
                showToast({
                    message: "Blox created successfully",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            },
            onError: (error: any) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Create failed with ${error.message}`);
            },
        }
    );
}

/**
 * Deletes a Blox by its ID.
 * 
 * @param {string} id - ID of the Blox to delete
 * @param {() => Promise<string>} getAccessTokenSilently - Auth0 function to get access token
 * @throws {Error} If the delete request fails
 */
async function deleteBlox(id: string, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/delete/${id}`, requestOptions);
    if (!response.ok) {
        throw new Error(`Failed to delete blox: ${id}`);
    }
}

/**
 * Hook to delete a Blox.
 * Provides mutation functionality with automatic cache invalidation and success/error handling.
 * 
 * @returns {UseMutationResult} Mutation result for deleting a Blox
 */
export function useDeleteBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    
    return useMutation(
        (id: string) => deleteBlox(id, getAccessTokenSilently),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('ownedBloxes');
                
                showToast({
                    message: "Blox deleted successfully",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            },
            onError: (error: any) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Delete failed with ${error.message}`);
            },
        }
    );
}
