/**
 * @fileoverview Component for rendering different process states within the IFrame application.
 * This file provides UI components for loading states, empty states, and process placeholders.
 * It includes visual feedback for users during transitions and when no process is loaded,
 * providing a consistent user experience across different application states.
 */

import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  ButtonGroup, 
  Button, 
  Tooltip, 
  IconButton 
} from '@mui/material';

// Import components
import { Loading } from '../Loading';

// Import icons
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsIcon from '@mui/icons-material/Settings';

export interface ProcessStatesProps {
  type: 'loading' | 'empty';
  iframeStyles: any;
  sizeConfig: any;
  contentContainerStyle: any;
  renderTemplates?: () => JSX.Element;
}

/**
 * A component that renders different process states (loading/empty) for the iframe viewer
 */
export function IFrameProcessStates({
  type,
  iframeStyles,
  sizeConfig,
  contentContainerStyle,
  renderTemplates
}: ProcessStatesProps) {
  // Common header for both states
  const renderHeader = () => (
    <Box sx={{
      ...iframeStyles.miniHeader,
      borderTopLeftRadius: sizeConfig.borderRadius,
      borderTopRightRadius: sizeConfig.borderRadius,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: 0,
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
    }}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Process Viewer
      </Typography>
      <Box sx={iframeStyles.headerControls}>
        {/* Simplified controls for non-interactive states */}
        <ButtonGroup size="small" disabled>
          <Button><ViewModuleIcon fontSize="small" /></Button>
          <Button><ViewListIcon fontSize="small" /></Button>
          <Button><TableChartIcon fontSize="small" /></Button>
        </ButtonGroup>
        
        <Tooltip title="Settings">
          <span>
            <IconButton size="small" disabled>
              <SettingsIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );

  // Loading state content
  const renderLoadingContent = () => (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loading />
    </Box>
  );

  // Empty state content  
  const renderEmptyContent = () => (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        Select a process above
      </Typography>
    </Box>
  );

  return (
    <Box sx={{
      ...(type === 'empty' ? {} : iframeStyles.outerContainer),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
    }}>
      <Box sx={{
        ...(contentContainerStyle || {}),
        height: 'auto',
      }}>
        {/* Only show templates in empty state */}
        {type === 'empty' && renderTemplates && renderTemplates()}
        
        {/* Main frame */}
        <Paper elevation={3} sx={{
          ...iframeStyles.frameContainer,
          marginTop: type === 'empty' ? '16px' : '0px'
        }}>
          {/* Header */}
          {renderHeader()}
          
          {/* Content area */}
          <Box sx={{
            ...iframeStyles.container,
            height: type === 'empty' ? 'auto' : 'calc(100% - 56px)',
            minHeight: type === 'empty' ? '300px' : 'auto'
          }}>
            {type === 'loading' ? renderLoadingContent() : renderEmptyContent()}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default IFrameProcessStates; 