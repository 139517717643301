/**
 * @fileoverview Style injection component for the IFrame application.
 * This component dynamically injects CSS styles into the document head to modify
 * the appearance of various elements within the iframe. It provides responsive styling
 * based on device size and configuration parameters, ensuring consistent presentation
 * across different embedding contexts without requiring external stylesheets.
 */

import { useEffect } from 'react';
import { SizeConfig } from './IFrameStyles';

/**
 * Props for IFrameStylesInjector component
 */
interface IFrameStylesInjectorProps {
  sizeConfig: SizeConfig;
  isMobile: boolean;
}

/**
 * Component that injects custom CSS for iframe embedded view
 * This component modifies the DOM directly by adding style elements
 */
export function IFrameStylesInjector({ 
  sizeConfig, 
  isMobile 
}: IFrameStylesInjectorProps) {
  useEffect(() => {
    // Apply custom CSS for iframe mode
    const style = document.createElement('style');
    style.textContent = `
      /* Compact section headers */
      .sectionHeader {
        min-height: ${isMobile ? '24px' : '28px'} !important;
        padding: ${isMobile ? '2px 4px' : '2px 8px'} !important;
      }
      
      .sectionHeader h3 {
        font-size: ${isMobile ? '12px' : '14px'} !important;
        font-weight: 600 !important;
      }
      
      /* Smaller spacing between bloxes */
      .section {
        margin-bottom: ${isMobile ? '4px' : '8px'} !important;
      }
      
      /* Compact blox styling */
      .blox {
        transform: scale(${isMobile ? sizeConfig.scale * 0.85 : sizeConfig.scale}) !important;
        transform-origin: center left !important;
        margin: ${isMobile ? '3px 2px' : '6px 4px'} !important;
      }
      
      /* Smaller arrows between bloxes */
      .arrowContainer {
        transform: scale(${isMobile ? (sizeConfig.scale - 0.1) : (sizeConfig.scale - 0.05)}) !important;
        margin-left: -4px !important;
        margin-right: -4px !important;
      }

      /* No extra padding in container */
      .sectionContainer {
        padding: ${isMobile ? '2px' : '4px'} !important;
      }
      
      /* Ensure the body doesn't scroll */
      body {
        overflow: hidden;
      }
    `;
    
    document.head.appendChild(style);
    
    return () => {
      // Clean up the styles on unmount
      document.head.removeChild(style);
    };
  }, [sizeConfig, isMobile]);

  // This is a logic-only component with no visual output
  return null;
} 