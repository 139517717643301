import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Paper, 
  Divider, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  Tabs,
  Tab,
  Grid,
  SelectChangeEvent
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SvgServiceProvider } from "../hooks/state/svg-service-provider";
import { ProcessSettingsProvider } from "../hooks/state/process-settings-provider";
import { ParameterContextProvider } from "../hooks/state/parameter-context";
import { useFabuStateProvider } from "../hooks/state/fabu-state-provider";
import { IframeProcessViewer } from "../components/IFrameComponents/viewer";
import { IFrameAppProvider } from "../components/IFrameComponents/IFrameAppProvider";

/**
 * Test page for demonstrating and testing the iframe functionality
 * This page shows both a direct component embedding and an actual iframe embedding for testing.
 */
const IframeTestPage: React.FC = () => {
  const fabuState = useFabuStateProvider();
  const [tabValue, setTabValue] = useState(0);
  const [viewMode, setViewMode] = useState('standard');
  const [size, setSize] = useState('full');
  const [processId, setProcessId] = useState('c54c548c-d3d0-4d04-814f-8a4a7501f16e');
  const [iframeUrl, setIframeUrl] = useState(`/iframe-process/${processId}?size=${size}&viewMode=${viewMode}`);

  // Update the iframe URL when parameters change
  const updateIframeUrl = () => {
    setIframeUrl(`/iframe-process/${processId}?size=${size}&viewMode=${viewMode}`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleViewModeChange = (event: SelectChangeEvent) => {
    setViewMode(event.target.value);
  };

  const handleSizeChange = (event: SelectChangeEvent) => {
    setSize(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 1.5 }}>
      <Box sx={{ py: 1 }}>
        <Typography variant="h5" sx={{ mb: 0.5 }}>
          Iframe Test Page
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Test iframe process viewer in various configurations
        </Typography>

        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          sx={{ 
            mb: 1.5, 
            minHeight: 36,
            '& .MuiTab-root': { 
              minHeight: 36, 
              py: 0.5,
              px: 1.5,
              fontSize: '0.8rem',
              textTransform: 'none'
            } 
          }}
        >
          <Tab label="Component Test" />
          <Tab label="Iframe Test" />
        </Tabs>

        {/* Component Test Tab */}
        {tabValue === 0 && (
          <Paper elevation={2} sx={{ overflow: 'hidden' }}>
            <Box sx={{ p: 1, mb: 0.5 }}>
              <Typography variant="subtitle1" sx={{ fontSize: '0.85rem' }}>
                Direct Component Test
              </Typography>
              <Typography variant="caption" color="textSecondary">
                IframeProcessViewer component embedded directly
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ height: '65vh', width: '100%' }}>
              <ParameterContextProvider providers={fabuState}>
                <ProcessSettingsProvider>
                  <SvgServiceProvider>
                    <IFrameAppProvider>
                      <IframeProcessViewer />
                    </IFrameAppProvider>
                  </SvgServiceProvider>
                </ProcessSettingsProvider>
              </ParameterContextProvider>
            </Box>
          </Paper>
        )}

        {/* Iframe Test Tab */}
        {tabValue === 1 && (
          <Paper elevation={2} sx={{ overflow: 'hidden' }}>
            <Box sx={{ p: 1 }}>
              <Typography variant="subtitle1" sx={{ fontSize: '0.85rem', mb: 0.25 }}>
                Iframe Embedding Test
              </Typography>
              <Typography variant="caption" color="textSecondary" sx={{ mb: 1, display: 'block' }}>
                Using an iframe element to embed the process viewer
              </Typography>

              <Grid container spacing={1} sx={{ mb: 1 }}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Process ID"
                    value={processId}
                    onChange={(e) => setProcessId(e.target.value)}
                    variant="outlined"
                    size="small"
                    InputProps={{ style: { fontSize: '0.8rem' } }}
                    InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                    sx={{ '& .MuiOutlinedInput-root': { height: 32 } }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth size="small" sx={{ '& .MuiOutlinedInput-root': { height: 32 } }}>
                    <InputLabel sx={{ fontSize: '0.8rem' }}>View Mode</InputLabel>
                    <Select
                      value={viewMode}
                      label="View Mode"
                      onChange={handleViewModeChange}
                      sx={{ fontSize: '0.8rem' }}
                    >
                      <MenuItem value="standard" sx={{ fontSize: '0.8rem' }}>Standard</MenuItem>
                      <MenuItem value="list" sx={{ fontSize: '0.8rem' }}>List</MenuItem>
                      <MenuItem value="table" sx={{ fontSize: '0.8rem' }}>Table</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth size="small" sx={{ '& .MuiOutlinedInput-root': { height: 32 } }}>
                    <InputLabel sx={{ fontSize: '0.8rem' }}>Size</InputLabel>
                    <Select
                      value={size}
                      label="Size"
                      onChange={handleSizeChange}
                      sx={{ fontSize: '0.8rem' }}
                    >
                      <MenuItem value="small" sx={{ fontSize: '0.8rem' }}>Small</MenuItem>
                      <MenuItem value="medium" sx={{ fontSize: '0.8rem' }}>Medium</MenuItem>
                      <MenuItem value="large" sx={{ fontSize: '0.8rem' }}>Large</MenuItem>
                      <MenuItem value="full" sx={{ fontSize: '0.8rem' }}>Full</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button 
                    variant="contained" 
                    fullWidth 
                    onClick={updateIframeUrl}
                    sx={{ 
                      height: '32px', 
                      fontSize: '0.75rem', 
                      textTransform: 'none',
                      py: 0
                    }}
                    startIcon={<RefreshIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />}
                    size="small"
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>

              <Typography variant="caption" sx={{ mt: 1, mb: 0.5, display: 'block', color: '#666' }}>
                URL: <Box component="span" sx={{ fontFamily: 'monospace', fontSize: '0.7rem' }}>{iframeUrl}</Box>
              </Typography>
            </Box>
            <Divider />
            <iframe
              src={iframeUrl}
              style={{
                width: '100%',
                height: '550px',
                border: 'none',
                display: 'block'
              }}
              title="Process Iframe"
            />
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export { IframeTestPage }; 