import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
        <Button
        className="tw-text-white"
        variant="text"
        onClick={() =>
            logout({
                returnTo: window.location.origin,
            })
        }
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;
