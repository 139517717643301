export interface IColor {
    R: number;
    G: number;
    B: number;
    A: number;
}

export class Color {
    R: number;
    G: number;
    B: number;
    A: number;

    constructor(R: number, G: number, B: number, A = 1) {
        this.R = R;
        this.G = G;
        this.B = B;
        this.A = A;
    }

    getLighter(): Color {
        const hsl = this.rgbToHsl(this.R, this.G, this.B);
        const lighterRGB = this.hslToRgb(hsl[0], hsl[1], Math.min(hsl[2] * 1.3, 100));
        return new Color(lighterRGB[0], lighterRGB[1], lighterRGB[2], this.A)
    }

    getDarker(): Color {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);
        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.69);
        return new Color(darkerRGB[0], darkerRGB[1], darkerRGB[2], this.A)
    }

    getSlightlyDarker(): Color {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);
        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.85);
        return new Color(darkerRGB[0], darkerRGB[1], darkerRGB[2], this.A)
    }

    getCSS(): string {
        return "rgba(" + this.R + "," + this.G + "," + this.B + "," + this.A + ")";
    }
    
    getRGB(): string {
        return "rgb(" + this.R + "," + this.G + "," + this.B +  ")";
    }
    
    getA(): string {
        return String(this.A);
    }

    static getCSS(color: IColor) {
        return `rgba(${color?.R},${color?.G },${color?.B},${color?.A})`;
    }

    /**
   * Returns the hexadecimal color string (ignores alpha).
   * For example, an IColor with R:255, G:0, B:0 returns "#ff0000".
   */
    static getHex(color: IColor): string {
        const toHex = (n: number): string => Math.round(n).toString(16).padStart(2, "0");
        return `#${toHex(color.R)}${toHex(color.G)}${toHex(color.B)}`;
    }

    getJson() {
        return {r: this.R, g: this.G, b: this.B, a: this.A}
    }

    getFrontCSS(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const lighterRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.93);

        return "rgba(" + lighterRGB[0] + "," + lighterRGB[1] + "," + lighterRGB[2] + "," + this.A + ")";
    }
    
    getFrontRGB(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const lighterRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.93);

        return "rgb(" + lighterRGB[0] + "," + lighterRGB[1] + "," + lighterRGB[2] + ")";
    }

    getSideCSS(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.75);

        return "rgba(" + darkerRGB[0] + "," + darkerRGB[1] + "," + darkerRGB[2] + "," + this.A + ")";
    }

    getSideRGB(): string {
        const hsv = this.rgbToHsv(this.R, this.G, this.B);

        const darkerRGB = this.hsvToRgb(hsv[0], hsv[1], hsv[2] * 0.75);

        return "rgba(" + darkerRGB[0] + "," + darkerRGB[1] + "," + darkerRGB[2] + ")";
    }

    // utility functions
    // from: https://axonflux.com/handy-rgb-to-hsl-and-rgb-to-hsv-color-model-c
    rgbToHsv(r: number, g: number, b: number): number[] {
        r = r/255;
        g = g/255;
        b = b/255;
        const max: number = Math.max(r, g, b);
        const min: number = Math.min(r, g, b);
        let h = 0;
        const v: number = max;

        const d: number = max - min;
        const s = max === 0 ? 0 : d / max;

        if(max === min){
            h = 0; // achromatic
        }else{
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, v];
    }

    rgbToHsl(r: number, g: number, b: number): number[] {
        // Normalize the RGB values by dividing them by 255
        r /= 255;
        g /= 255;
        b /= 255;
    
        // Find the maximum and minimum values of R, G, and B
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
    
        // Calculate Lightness
        let h = 0, s = 0, l = (max + min) / 2;
    
        if (max !== min) {
            const d = max - min;
    
            // Calculate Saturation
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    
            // Calculate Hue
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
    
            h /= 6;
        }
    
        // Convert hue to degrees
        h = Math.round(h * 360);
        // Convert saturation and lightness to percentage
        s = Math.round(s * 100);
        l = Math.round(l * 100);
    
        return [h, s, l];
    }

    hsvToRgb(h: number, s: number, v:number):number[] {
        let r = 0;
        let g = 0;
        let b = 0;

        const i: number = Math.floor(h * 6);
        const f: number = h * 6 - i;
        const p: number = v * (1 - s);
        const q: number = v * (1 - f * s);
        const t: number = v * (1 - (1 - f) * s);

        switch(i % 6){
            case 0: r = v; g = t; b = p; break;
            case 1: r = q; g = v; b = p; break;
            case 2: r = p; g = v; b = t; break;
            case 3: r = p; g = q; b = v; break;
            case 4: r = t; g = p; b = v; break;
            case 5: r = v; g = p; b = q; break;
        }

        return [r * 255, g * 255, b * 255];
    }

    hslToRgb(h: number, s: number, l: number): number[] {
        // Normalize the hue to be between 0 and 1
        h /= 360;
        // Normalize saturation and lightness to be between 0 and 1
        s /= 100;
        l /= 100;
    
        let r: number, g: number, b: number;
    
        if (s === 0) {
            // If saturation is 0, the color is a shade of grey
            r = g = b = l; // R, G, and B are all equal to lightness
        } else {
            const hue2rgb = (p: number, q: number, t: number): number => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };
    
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
    
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }
    
        // Convert the RGB values to the range 0-255
        r = Math.round(r * 255);
        g = Math.round(g * 255);
        b = Math.round(b * 255);
    
        return [r, g, b ];
    }
    
}
