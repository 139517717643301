import { useCallback, useState } from 'react';

interface UseListNavigationProps<T> {
  items: T[];
  getItemKey: (item: T) => React.Key;
  onSelectItem: (item: T) => void;
  getSectionId?: (item: T) => string;
  closedSections?: { [key: string]: boolean };
  onToggleSection?: (sectionId: string) => void;
  isSectionHeader?: (item: T) => boolean;
}

interface UseListNavigationReturn<T> {
  selectedItemKey: React.Key | null;
  handleNext: () => void;
  handlePrevious: () => void;
  handleItemSelect: (item: T) => void;
}

/**
 * Custom hook for managing list navigation, including item selection
 * and next/previous navigation.
 */
export const useListNavigation = <T>({
  items,
  getItemKey,
  onSelectItem,
  getSectionId = () => '',
  closedSections = {},
  onToggleSection,
  isSectionHeader = () => false,
}: UseListNavigationProps<T>): UseListNavigationReturn<T> => {
  const [selectedItemKey, setSelectedItemKey] = useState<React.Key | null>(null);

  // Helper function to ensure section is open
  const ensureSectionOpen = useCallback((item: T) => {
    const sectionId = getSectionId(item);
    if (sectionId && closedSections[sectionId]) {
      onToggleSection?.(sectionId);
      return true; // Section was closed and is now being opened
    }
    return false; // Section was already open
  }, [getSectionId, closedSections, onToggleSection]);

  const handleNext = useCallback(() => {
    const nonHeaderItems = items.filter(item => !isSectionHeader(item));
    const selectedIndex = selectedItemKey 
      ? nonHeaderItems.findIndex(item => getItemKey(item) === selectedItemKey)
      : -1;

    if (selectedIndex < nonHeaderItems.length - 1) {
      const nextItem = nonHeaderItems[selectedIndex + 1];
      const key = getItemKey(nextItem);
      
      // Check if we need to open a section
      ensureSectionOpen(nextItem);
      
      setSelectedItemKey(key);
      onSelectItem(nextItem);
    }
  }, [items, selectedItemKey, getItemKey, onSelectItem, ensureSectionOpen, isSectionHeader]);

  const handlePrevious = useCallback(() => {
    const nonHeaderItems = items.filter(item => !isSectionHeader(item));
    const selectedIndex = selectedItemKey 
      ? nonHeaderItems.findIndex(item => getItemKey(item) === selectedItemKey)
      : -1;

    if (selectedIndex > 0) {
      const previousItem = nonHeaderItems[selectedIndex - 1];
      const key = getItemKey(previousItem);
      
      // Check if we need to open a section
      ensureSectionOpen(previousItem);
      
      setSelectedItemKey(key);
      onSelectItem(previousItem);
    }
  }, [items, selectedItemKey, getItemKey, onSelectItem, ensureSectionOpen, isSectionHeader]);

  const handleItemSelect = useCallback((item: T) => {
    const key = getItemKey(item);
    
    // Check if we need to open a section
    ensureSectionOpen(item);
    
    setSelectedItemKey(key);
    onSelectItem(item);
  }, [getItemKey, onSelectItem, ensureSectionOpen]);

  return {
    selectedItemKey,
    handleNext,
    handlePrevious,
    handleItemSelect,
  };
};