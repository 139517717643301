import { useMemo } from 'react';
import { BloxTypes } from '../../../Data/BloxSchema/base-blox';

interface UseItemStateProps<T> {
  items: T[];
  selectedItemKey: React.Key | null;
  isSectionHeader: (item: T) => boolean;
  getItemKey: (item: T) => React.Key;
}

/**
 * Hook for managing item state in the ProcessListView.
 * Provides:
 * - Current item selection
 * - Item metadata
 * - Stack visualization data
 * 
 * Features:
 * - Tracks selected item
 * - Filters header items
 * - Manages item metadata
 * - Feeds data to ProcessListViewStack for visualization
 * 
 * @template T - The type of items being managed
 */
export const useItemState = <T>({
  items,
  selectedItemKey,
  isSectionHeader,
  getItemKey
}: UseItemStateProps<T>) => {
  const nonHeaderItems = useMemo(() => 
    items.filter(item => !isSectionHeader(item)),
    [items, isSectionHeader]
  );

  const currentItem = useMemo(() => 
    selectedItemKey ? nonHeaderItems.find(item => getItemKey(item) === selectedItemKey) : null,
    [selectedItemKey, nonHeaderItems, getItemKey]
  );

  const currentBloxType = currentItem 
    ? (currentItem as any).bloxType || BloxTypes.CustomBlox 
    : BloxTypes.CustomBlox;
    
  const stepNumber = currentItem 
    ? (currentItem as any).stepNumber || 0 
    : 0;

  return {
    nonHeaderItems,
    currentItem,
    currentBloxType,
    stepNumber
  };
}; 