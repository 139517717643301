import { useContext, useEffect, useState } from 'react';
import { SvgServiceContext } from './state/svg-service-provider';
import { SVGDisplayMode } from '../Services/SVGEngine';
import { stackChangeMap } from '../Data/stack-change-mappings';
import { AllBloxes } from '../Data/BloxSchema/base-blox';

interface UseProcessPreviewProps {
    bloxes: AllBloxes[];
    thumbnail?: string;
}

export const useProcessPreview = ({ bloxes, thumbnail }: UseProcessPreviewProps) => {
    const threeDim = false;
    const { generateSvgs } = useContext(SvgServiceContext);
    const [loading, setLoading] = useState(true);
    const [svgIcon, setSvgIcon] = useState<JSX.Element | null>(null);

    useEffect(() => {
        setLoading(true);

        let stackChangeCount = 0;
        for (const { bloxType } of bloxes) {
            const stackChange = stackChangeMap[bloxType];
            if (stackChange) stackChangeCount += stackChange.length;
        }

        const timer = setTimeout(() => {
            let svg = <></>;
            if (!thumbnail) {
                const svgs = generateSvgs(bloxes, threeDim, SVGDisplayMode.Thumbnail);
                const hasSvgs = stackChangeCount > 0 && svgs.length > 0;
                svg = hasSvgs ? svgs[svgs.length - 1] : <></>;
            } else {
                svg = <div dangerouslySetInnerHTML={{ __html: thumbnail }} />
            }
            setSvgIcon(svg);
            setLoading(false);
        }, 0);

        return () => clearTimeout(timer);
    }, [bloxes, threeDim, generateSvgs, thumbnail]);

    return { loading, svgIcon };
}; 