/**
 * @fileoverview Styling utilities and configuration for the IFrame application.
 * This file contains style definitions, size presets, and utility functions for
 * generating responsive styles based on configuration parameters. It centralizes 
 * styling logic to maintain consistent visual appearance across components and
 * provides adaptable styling for different screen sizes and embedding scenarios.
 */

import { SxProps, Theme } from "@mui/material";
import React from "react";

// Size preset type
export type SizePreset = 'small' | 'medium' | 'large' | 'full';

// Size configuration interface
export interface SizeConfig {
  width: string;
  height: string;
  scale: number;
  borderRadius: string;
  label: string;
  minWidth?: string;
  minHeight?: string;
}

// Size configuration presets
export const sizeConfigs: Record<SizePreset, SizeConfig> = {
  small: {
    width: '480px',
    height: '360px',
    scale: 0.8,
    borderRadius: '12px',
    label: 'Small',
    minWidth: '320px',
    minHeight: '240px'
  },
  medium: {
    width: '800px',
    height: '550px',
    scale: 0.9,
    borderRadius: '16px',
    label: 'Medium',
    minWidth: '640px',
    minHeight: '400px'
  },
  large: {
    width: '1100px',
    height: '680px', 
    scale: 0.95,
    borderRadius: '20px',
    label: 'Full Width',
    minWidth: '900px',
    minHeight: '550px'
  },
  full: {
    width: '100%',
    height: 'calc(100vh - 32px)', // Constrain to viewport height minus margins
    scale: 1,
    borderRadius: '8px',
    label: 'Fullscreen',
    minWidth: '100%',
    minHeight: 'calc(100vh - 32px)'
  }
};

// Function to get size configuration from URL or default to fullscreen
export const getSizeConfig = (queryParams: URLSearchParams): SizeConfig => {
  const sizeParam = queryParams.get('size') as SizePreset || 'full';
  return sizeConfigs[sizeParam] || sizeConfigs.full;
};

// Custom styles for iframe compact mode
export const getBaseIframeStyles = (sizeConfig: SizeConfig, isMobile: boolean, showTemplates = false) => {
  return {
    // Main container
    outerContainer: {
      display: 'flex',
      flexDirection: 'column' as const,
      width: '100%',
      height: '100vh', // Fill the viewport height
      padding: isMobile ? '8px' : '16px',
      boxSizing: 'border-box' as const,
      backgroundColor: '#f5f5f5',
      overflowY: 'auto', // Add vertical scrolling to the outerContainer
    },

    // Mini header
    miniHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '12px',
      padding: '8px 12px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.08)',
    },

    // Header controls
    headerControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },

    // Frame container
    frameContainer: {
      width: sizeConfig.width,
      height: sizeConfig.height,
      maxHeight: 'calc(100vh - 32px - 60px)', // Maximum height to prevent overflow
      borderRadius: sizeConfig.borderRadius,
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      border: '1px solid #e0e0e0',
      backgroundColor: '#fff',
      position: 'relative' as const,
      flexGrow: 1,
      paddingRight: '0',
      maxWidth: '100%',
      margin: '0 auto',
    },

    // View modes container
    viewModesContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },

    // Process container
    processContainer: {
      display: 'flex',
      flexDirection: 'column' as const,
      height: '100%',
      width: '100%',
      overflow: 'auto',
      padding: isMobile ? '8px' : '16px',
      boxSizing: 'border-box' as const,
    },

    // Content container
    contentContainer: {
      display: 'flex',
      flexDirection: 'column' as const,
      width: '100%',
      transition: 'all 0.3s ease-in-out',
      paddingRight: '0',
      height: 'auto', // Change from 100% to auto
      minHeight: '100px', // Add minimum height
      maxHeight: 'none', // Remove maximum height constraint
      overflow: 'visible', // Change from auto to visible to prevent nested scrollbars
    },

    // Chatbot styles
    chatbotToggle: {
      position: 'fixed' as const,
      bottom: '24px',
      right: '24px',
      zIndex: 1050,
      width: '56px',
      height: '56px',
      backgroundColor: '#1976d2',
      color: 'white',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      '&:hover': {
        backgroundColor: '#1565C0',
      },
    },

    chatbotPanel: {
      position: 'fixed' as const,
      top: '16px',
      right: '16px',
      width: isMobile ? '300px' : '360px',
      height: 'calc(100vh - 32px)',
      backgroundColor: '#ffffff',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
      zIndex: 1050,
      display: 'flex',
      flexDirection: 'column' as const,
      overflow: 'hidden',
      padding: 0,
      margin: 0,
      borderRadius: '16px',
    },

    chatHeader: {
      padding: '12px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      background: 'linear-gradient(135deg, #3949AB 0%, #1976d2 100%)',
      color: 'white',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },

    chatBody: {
      padding: '16px',
      flexGrow: 1,
      overflowY: 'auto' as const,
      height: 'calc(100% - 136px)',
      backgroundColor: '#f8fafc',
      backgroundImage: 'radial-gradient(rgba(25, 118, 210, 0.05) 1px, transparent 1px)',
      backgroundSize: '20px 20px',
      display: 'flex',
      flexDirection: 'column' as const,
      gap: '12px',
    },

    chatFooter: {
      padding: '12px 16px 16px',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
      backgroundColor: 'white',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },

    chatInput: {
      flexGrow: 1,
      '& fieldset': {
        borderRadius: '24px',
        borderColor: 'rgba(25, 118, 210, 0.3)',
        borderWidth: '1.5px',
      },
    },

    botMessage: {
      backgroundColor: 'white',
      borderRadius: '18px 18px 18px 4px',
      padding: '12px 16px',
      marginBottom: '8px',
      maxWidth: '85%',
      alignSelf: 'flex-start' as const,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
      border: '1px solid rgba(230, 235, 245, 0.8)',
    },

    userMessage: {
      backgroundColor: '#E3F2FD',
      borderRadius: '18px 18px 4px 18px',
      padding: '12px 16px',
      marginBottom: '8px',
      maxWidth: '85%',
      alignSelf: 'flex-end' as const,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
      marginLeft: 'auto',
      border: '1px solid rgba(25, 118, 210, 0.1)',
      color: '#0D47A1',
      fontWeight: 500,
    },

    actionableMessage: {
      borderLeft: '3px solid #1565C0',
      backgroundColor: '#E8F5E9',
      cursor: 'pointer',
      transition: 'all 0.2s',
      '&:hover': {
        backgroundColor: '#C8E6C9',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      }
    },

    chatAvatar: {
      background: 'linear-gradient(45deg, #1565C0 30%, #42A5F5 90%)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      width: '40px',
      height: '40px',
    },

    chatSendButton: {
      marginLeft: '8px',
      borderRadius: '50%',
    },

    botTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },

    assistantAvatar: {
      width: '36px',
      height: '36px',
      backgroundColor: 'white',
      color: '#1976d2',
      fontWeight: 'bold',
      border: '2px solid white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    },

    chatLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      color: '#666',
      fontSize: '12px',
      marginTop: '4px',
      marginBottom: '8px',
    },

    glow: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'pulse 1.5s infinite',
      background: 'radial-gradient(circle, rgba(25, 118, 210, 0.2) 0%, rgba(25, 118, 210, 0) 70%)',
    } as SxProps<Theme>,

    // Process controls and settings
    controlsPanel: {
      position: 'fixed' as const,
      top: '16px',
      right: '16px',
      width: isMobile ? '300px' : '350px',
      height: 'auto', // Adjusted to content height
      maxHeight: 'calc(100vh - 32px)', // Constrain to viewport
      backgroundColor: '#ffffff',
      boxShadow: '-2px 0 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1050,
      display: 'flex',
      flexDirection: 'column' as const,
      overflow: 'hidden',
      padding: 0,
      margin: 0,
      borderRadius: '12px',
    },

    controlsHeader: {
      padding: '12px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      backgroundColor: '#1565C0',
      color: 'white',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },

    // Templates section
    templatesSection: {
      padding: showTemplates ? (isMobile ? '8px' : '16px') : '0',
      marginBottom: isMobile ? '8px' : '16px',
      backgroundColor: '#fff',
      borderRadius: sizeConfig.borderRadius,
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      maxHeight: showTemplates ? '560px' : 'auto', // Limit max height to prevent overflow
      opacity: 1,
      border: '1px solid #e0e0e0',
      position: 'relative',
    },

    templateHeader: {
      padding: '12px 16px',
      backgroundColor: '#f5f7fa',
      borderBottom: '1px solid #e0e0e0',
    },

    collapsedHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 16px',
      backgroundColor: 'white',
    },

    // New process button
    newProcessButton: {
      backgroundColor: '#4caf50',
      color: 'white',
      '&:hover': {
        backgroundColor: '#388e3c',
      },
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '0.75rem',
      padding: '4px 12px',
    },

    // Add container style - this was missing from our previous extraction
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: 0,
      margin: 0,
      backgroundColor: '#fff',
    },

    // Add missing styles
    sizeButtons: {
      display: 'flex',
      gap: isMobile ? '4px' : '8px',
      flexWrap: 'wrap' as const,
      justifyContent: 'center',
    },
    
    viewModeButtons: {
      marginTop: isMobile ? '4px' : '8px',
    },
    
    toggleTemplatesButton: {
      position: 'absolute' as const,
      top: isMobile ? '8px' : '16px',
      right: isMobile ? '16px' : '24px',
      zIndex: 999,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '50%',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    
    templateCard: {
      display: 'flex',
      flexDirection: 'column' as const,
      height: '100%',
      cursor: 'pointer',
      transition: 'transform 0.2s, box-shadow 0.2s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
      }
    },
    
    templateCardContent: {
      flexGrow: 1,
      padding: isMobile ? '8px' : '16px',
    },
    
    templateCardActions: {
      padding: isMobile ? '4px 8px' : '8px 16px',
    },
    
    templateIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f0f4ff',
      borderRadius: '50%',
      width: isMobile ? '32px' : '40px',
      height: isMobile ? '32px' : '40px',
      margin: '0 auto 8px auto'
    },
    
    templatesHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: isMobile ? '8px' : '16px',
    },
    
    controlsToggle: {
      position: 'absolute' as const,
      bottom: isMobile ? '16px' : '24px',
      right: isMobile ? '16px' : '24px',
      zIndex: 1000,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '50%',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
  };
};

// Export a helper to get styles with default values for optional parameters
export const getIframeStyles = (sizeConfig: SizeConfig, isMobile: boolean) => {
  return getBaseIframeStyles(sizeConfig, isMobile);
}; 