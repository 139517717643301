import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface Develop extends BaseBlox {
    developer: string | null;
    developTime: number | null;
    developTimeUnit: Units | null;
    developTemp: number | null;
    developTempUnit: Units | null;
    layerLabelsToDevelop: string[] | null;
}

export const developDisplay: DisplayMap = {
    layerLabelsToDevelop: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Develop",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        materialFilter: "ONLY_DEVELOPABLE_RESIST" // TODO: enum
    },
    
    developer: {
        fieldType: DisplayFieldTypes.Input,
        label: "Developer",
        placeholder: "Enter constituents and ratios",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    developTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Develop Time",
        placeholder: "Enter develop time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    developTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Develop Temperature",
        placeholder: "Enter develop temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    
}

export const getDevelop = ({stepNumber}: BloxArgs): Develop => ({
        name: `Develop ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.Develop,
        developer: null,
        developTime: null,
        developTimeUnit: Units.SEC,
        developTemp: null,
        developTempUnit: Units.CELSIUS,
        layerLabelsToDevelop: [],
        commentField: null,
        customFields: {},
    }
);
