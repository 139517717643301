import { IColor } from "../utils/Color";
import { BloxTypes } from "./BloxSchema/base-blox";

export interface MaterialParameters {
    materialFieldName: string;
    unknownLabel: string; // how to call a material if the field is empty
    defaultColor: IColor;
}

export interface MaterialMap {
    [key: string]: MaterialParameters | null
}

export function getDefaultColor(bloxType: BloxTypes | string): IColor {
    return materialFieldMap[bloxType]?.defaultColor ?? {R: 255, G: 255, B: 255, A: 1};
}

export function getMaterialUnknownLabel(bloxType: BloxTypes, property: string) {
    if (bloxType === BloxTypes.WaferBonding) {
        return getWaferBondingUnknownLabel(property);
    }
    return materialFieldMap[bloxType]?.unknownLabel ?? "Unknown Material";
}

export function getWaferBondingUnknownLabel(property: string) {
    if (property === 'material') {
        return materialFieldMap[BloxTypes.WaferBonding]?.unknownLabel ?? "Unnamed Wafer";
    } else {
        return materialFieldMap["WAFERBONDING-AGENT"]?.unknownLabel ??"Unnamed Wafer Bonding Agent";
    }
}


// maps between bloxes that deposit materials to the name of the field which has the material name
export const materialFieldMap: MaterialMap = {

    "STARTBLOX": null, // STARTBLOX is special, has multiple layers with multiple materials

    "CUSTOMABLOX": null,
    "BAKE": null,
    "CUSTOMCLEAN": null,
    "DEVELOP": null,
    "MASKALIGN": null,
    "STEPPERLITHO": null,
    "DIRECTWRITELITHO": null,
    "INTERFERENCE-LITHO": null,
    "IMPRINTLITHO": null,
    "SCANNINGPROBELITHO": null,
    "FLOODEXPOSE": null,
    "DOPEDIFFUSION": null,
    "EBL": null,
    "FLIPSTACK": null,
    "ICPRIE": null,
    "IONIMPLANT": null,
    "LIFTOFF": null,
    "PLASMA-ASH": null,
    "PLASMA-DESCUM": null,
    "RCACLEAN": null,
    "RIE": null,
    "SUBSTACK": null,
    "STRIPRESIST": null,
    "WETETCH": null,
    "RTA": null,
    "IONMILL": null,

    "SPINCOATRESIST": {
        materialFieldName: "resist",
        unknownLabel: "Unnamed Resist",
        defaultColor: {R: 208, G: 79, B: 79, A: 1}
    },
    "SPINCOAT": {
        materialFieldName: "material",
        unknownLabel: "Unnamed Spin-Coated Layer",
        defaultColor: {R: 80, G: 120, B: 80, A: 1}
    },
    "SPINDOPANT": {
        materialFieldName: "material",
        unknownLabel: "Unnamed Spin-On Dopant",
        defaultColor: {R: 100, G: 240, B: 240, A: 0.9}
    },
    "SPINCOATUNIFIED": {
        materialFieldName: "material",
        unknownLabel: "Unnamed Spin-Coated Layer",
        defaultColor: {R: 208, G: 79, B: 79, A: 1}
    },
    "HMDSVAPOR":{
        materialFieldName: "material",
        unknownLabel: "HMDS",
        defaultColor: {R: 50, G: 50, B: 100, A: 1}
    },
    "SPUTTER":{
        materialFieldName: "sputterMaterial",
        unknownLabel: "Unnamed Sputter Layer",
        defaultColor: {R: 140, G: 79, B: 225, A: 1}
    },
    "THERMALEVAP":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Thermal Evap Layer",
        defaultColor: {R: 108, G: 158, B: 238, A: 1}
    },
    "EBEAMEVAP":{
        materialFieldName: "material",
        unknownLabel: "Unnamed E-beam Evap Layer",
        defaultColor: {R: 255, G: 192, B: 0, A: 1}
    },
    "MOCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed MOCVD Layer",
        defaultColor: {R: 255, G: 127, B: 80, A: 1}
    },
    "MBE":{
        materialFieldName: "material",
        unknownLabel: "Unnamed MBE Layer",
        defaultColor: {R: 0, G: 125, B: 192, A: 1}
    },
    "ALD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed ALD Layer",
        defaultColor: {R: 75, G: 210, B: 75, A: 1}
    },
    "PECVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        defaultColor: {R: 0, G: 0, B: 192, A: 1}
    },
    "LPCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        defaultColor: {R: 60, G: 75, B: 192, A: 1}
    },
    "APCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        defaultColor: {R: 100, G: 125, B: 192, A: 1}
    },
    "DROPCAST":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Drop-Cast Layer",
        defaultColor: {R: 80, G: 120, B: 80, A: 1}
    },
    "PARYLENEDEP":{
        materialFieldName: "paryleneType",
        unknownLabel: "Unnamed Parylene film",
        defaultColor: { R: 200, G: 200, B: 200, A: 0.8 }
    },
    "WETGROWTH":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Wet-Grown Layer",
        defaultColor: {R: 100, G: 100, B: 100, A: 1}
    },
    "WAFERBONDING":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Wafer",
        defaultColor: {R: 210, G: 210, B: 210, A: 1}
    },
    "WAFERBONDING-AGENT":{
        materialFieldName: "bondingAgentLabel",
        unknownLabel: "Unnamed Wafer Bonding Agent",
        defaultColor: {R: 110, G: 110, B: 210, A: 1}
    },
    "THERMALOXIDATION":{
        materialFieldName: "oxide",
        unknownLabel: "Unnamed Oxide",
        defaultColor: {R: 211, G: 211, B: 211, A: 1}
    },
    "ELECTROPLATE":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Electroplated Layer",
        defaultColor: {R: 184, G: 115, B: 51, A: 1}
    }
}