/**
 * @fileoverview Provides utility functions, hooks, and constants for the IFrame component system.
 * This file includes process suggestions, tips, URL parameter handling, and various helper functions
 * that are used across different IFrame components. It centralizes common functionality to maintain
 * consistency and reduce code duplication throughout the application.
 */

/**
 * Process utilities and constants for iframe components
 */

import React, { useState, useRef, ReactElement } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getSizeConfig } from './IFrameStyles';

/**
 * Process step suggestions for different process types
 */
export const processStepSuggestions: Record<string, string[]> = {
  'semiconductor': [
    'Consider adding a photolithography step to pattern your silicon wafer',
    'You might need to include an etching process after photolithography',
    'Don\'t forget to add a thermal oxidation step to grow an oxide layer',
    'A doping step would be necessary for creating active regions in your device',
    'Consider adding a deposition step for metallization and contacts'
  ],
  'fabrication': [
    'Your process would benefit from a cleaning step before lithography',
    'Consider adding a resist development step after exposure',
    'Plasma etching would provide better resolution than wet etching here',
    'You should add a metrology step to measure critical dimensions',
    'Consider a final inspection step to ensure quality control'
  ],
  'sensor': [
    'Your MEMS sensor needs a sacrificial layer release step',
    'Consider adding a packaging step to protect the sensor',
    'You should include a calibration step in your process',
    'A testing step would help validate the sensor performance',
    'Consider adding environmental testing for robustness'
  ]
};

/**
 * Process engineering tips
 */
export const processTips = [
  'For better yield, optimize your cleaning steps before critical processes',
  'Consider temperature ramp rates carefully during thermal processes',
  'Vacuum quality significantly impacts deposition uniformity',
  'Particle control is critical for high-resolution lithography',
  'Monitor etch rates regularly to maintain process control',
  'In MEMS fabrication, stress management is essential for device performance',
  'Thermal budget management affects diffusion profiles and junction depths',
  'For precise doping, consider ion implantation over diffusion methods',
  'Edge bead removal improves lithography uniformity across the wafer',
  'Nitrogen purging can prevent oxidation during high-temperature steps'
];

/**
 * Helper function to get random suggestions for a process type
 */
export const getRandomSuggestions = (processType: string, count = 3): string[] => {
  // Get suggestions for this process type or default to semiconductor
  const suggestions = processStepSuggestions[processType] || processStepSuggestions.semiconductor;
  
  // Take random suggestions (up to count or all if fewer)
  const actualCount = Math.min(count, suggestions.length);
  return [...suggestions].sort(() => 0.5 - Math.random()).slice(0, actualCount);
};

/**
 * Helper function to get a random process tip
 */
export const getRandomProcessTip = (): string => {
  return processTips[Math.floor(Math.random() * processTips.length)];
};

/**
 * Get a random suggestion - will select either a process tip OR a step suggestion, not both
 * @param processType Optional process type for step suggestions
 */
export const getRandomProcessAdvice = (processType?: string): { text: string, isProcessTip: boolean } => {
  // Randomly decide whether to return a process tip or step suggestion
  const useProcessTip = Math.random() > 0.5;
  
  if (useProcessTip) {
    // Return a random process tip
    return {
      text: processTips[Math.floor(Math.random() * processTips.length)],
      isProcessTip: true
    };
  } else {
    // Return a random step suggestion
    const suggestions = processType 
      ? (processStepSuggestions[processType] || processStepSuggestions.semiconductor)
      : processStepSuggestions.semiconductor;
    
    return {
      text: suggestions[Math.floor(Math.random() * suggestions.length)],
      isProcessTip: false
    };
  }
};

/**
 * Custom hook for managing URL parameters in IFrame components
 * Centralizes URL parameter handling and ensures query parameters
 * are the highest level of truth for configuration
 */
export const useUrlParams = () => {
  const { processId } = useParams<{processId: string}>();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  
  // Extract common URL parameters - these are the primary source of truth
  const viewMode = queryParams.get('view') || 'standard';
  const signature = queryParams.get('signature');
  const sizeParam = queryParams.get('size') || 'full';
  const sizeConfig = getSizeConfig(queryParams);
  
  // Helper function to update a URL parameter
  const updateUrlParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set(key, value);
    
    history.replace({
      pathname: location.pathname,
      search: newParams.toString()
    });
  };
  
  return {
    processId,
    location,
    history,
    queryParams,
    viewMode,
    signature,
    sizeParam,
    sizeConfig,
    updateUrlParam
  };
};

/**
 * Custom hook for managing state in IFrame components
 * Centralizes common state management for IFrame-specific components
 */
export const useIFrameState = (isMobile = false) => {
  // Templates state
  const [showTemplates, setShowTemplates] = useState(true);
  
  // Core viewer state
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Control panel state
  const [showControls, setShowControls] = useState(false);
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  
  // Section visibility state
  const [closedSections, setClosedSections] = useState<{[key: string]: boolean}>({});
  
  // SVG and scroll state
  const [bloxSVGs, setBloxSVGs] = useState<ReactElement[]>([]);
  const [initialScrollTarget, setInitialScrollTarget] = useState<string | null>(null);
  
  // Track whether we've already set the initial blox
  // This flag prevents multiple selection attempts when components re-render
  const hasSetInitialBlox = useRef(false);
  
  // Chatbot state
  const [showChatbot, setShowChatbot] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [processViewAdjusted, setProcessViewAdjusted] = useState(false);
  
  // Welcome box state - new
  const [showWelcomeBox, setShowWelcomeBox] = useState(true); // Show by default
  const [welcomeBoxCollapsed, setWelcomeBoxCollapsed] = useState(false);
  
  // Process view collapse state - new
  const [processViewCollapsed, setProcessViewCollapsed] = useState(false);
  
  // Reset initialization flag when process changes
  // This allows for proper initialization when loading a new process
  const resetInitialization = () => {
    hasSetInitialBlox.current = false;
    setInitialScrollTarget(null);
  };
  
  // Toggle functions for collapse states
  const toggleWelcomeBoxCollapsed = () => {
    setWelcomeBoxCollapsed(!welcomeBoxCollapsed);
  };
  
  const toggleProcessViewCollapsed = () => {
    setProcessViewCollapsed(!processViewCollapsed);
  };
  
  // Toggle chatbot and adjust view accordingly
  const toggleChatbot = () => {
    const newState = !showChatbot;
    setShowChatbot(newState);
    // Only adjust process view if we're not on mobile
    if (!isMobile) {
      setProcessViewAdjusted(newState);
    } else {
      // On mobile, we don't adjust the process view
      setProcessViewAdjusted(false);
    }
    if (!newState) {
      setHasUnread(false);
    }
  };
  
  return {
    // Templates state
    showTemplates,
    setShowTemplates,
    
    // Core viewer state
    isLoading,
    setIsLoading,
    containerRef,
    
    // Control panel state
    showControls,
    setShowControls,
    copied,
    setCopied,
    anchorEl,
    setAnchorEl,
    
    // Section visibility state
    closedSections,
    setClosedSections,
    
    // SVG and scroll state
    bloxSVGs,
    setBloxSVGs,
    initialScrollTarget,
    setInitialScrollTarget,
    hasSetInitialBlox,
    resetInitialization,
    
    // Chatbot state
    showChatbot,
    setShowChatbot,
    hasUnread,
    setHasUnread,
    processViewAdjusted,
    setProcessViewAdjusted,
    toggleChatbot,
    
    // Welcome box state - new
    showWelcomeBox,
    setShowWelcomeBox,
    welcomeBoxCollapsed,
    setWelcomeBoxCollapsed,
    toggleWelcomeBoxCollapsed,
    
    // Process view collapse state - new
    processViewCollapsed,
    setProcessViewCollapsed,
    toggleProcessViewCollapsed,
  };
}; 