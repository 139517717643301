import { useEffect } from "react";
import { Prompt, useLocation } from "react-router-dom";

const stepLinks = ["/contact", "/education", "/about", "/confirm"];
// Parameters that should not trigger the unsaved changes prompt
const VIEW_PARAM = 'view';

interface FormPromptProps {
  hasUnsavedChanges: boolean;
}

export const UnsavedChangesDialog: React.FC<FormPromptProps> = ({ hasUnsavedChanges }) => {
  const location = useLocation();

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const stripViewParams = (params: URLSearchParams): string => {
    const cleanParams = new URLSearchParams(params);
    cleanParams.delete(VIEW_PARAM);
    return cleanParams.toString();
  };

  const onLocationChange = (nextLocation: any) => {
    if (stepLinks.includes(nextLocation.pathname)) {
      return true;
    }

    // If paths are same and only view parameters changed, allow navigation
    if (location.pathname === nextLocation.pathname) {
      const currentParams = new URLSearchParams(location.search);
      const nextParams = new URLSearchParams(nextLocation.search);
      
      const viewMode = currentParams.get(VIEW_PARAM);
      const newParams = new URLSearchParams(nextParams);
      
      if (stripViewParams(currentParams) === stripViewParams(newParams)) {
        if (viewMode) {
          newParams.set(VIEW_PARAM, viewMode);
        }
        return true;
      }
    }

    return "You have unsaved changes, are you sure you want to leave?";
  };

  return <Prompt when={hasUnsavedChanges} message={onLocationChange} />;
};
