import React, { RefObject } from 'react';
import { Box } from '@mui/material';

interface SectionContainerProps {
  children: React.ReactNode;
  containerRef: RefObject<HTMLDivElement>;
}

const SectionContainer: React.FC<SectionContainerProps> = ({ children, containerRef }) => (
  <Box
    id="sectionContainer"
    ref={containerRef}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      width: '100%',
      height: '100%',
      margin: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      gap: '12px',
      paddingTop: '8px',
      paddingLeft: '4px',
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 0,
    }}
  >
    {children}
  </Box>
);

export default SectionContainer;
