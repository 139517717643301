import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, User } from "@auth0/auth0-react";

/**
 * Interface for the application state passed during authentication
 */
interface AppState {
    returnTo?: string;
    [key: string]: unknown;
}

/**
 * Auth0ProviderWithHistory is a wrapper component that provides Auth0 authentication context
 * to its child components while handling routing history integration.
 * 
 * This component configures the Auth0Provider with the necessary credentials and settings,
 * and handles post-authentication redirects using React Router's history.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Child components that will have access to Auth0 context
 * 
 * @throws {Error} Throws an error if Auth0 domain or clientId environment variables are not configured
 * 
 */
const Auth0ProviderWithHistory: React.FC<{children: ReactNode}> = (props) => {
    const { children } = props;
    const history = useHistory();
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    if (!domain || !clientId)
        throw new Error("Missing auth configuration");

    /**
     * Handles the redirect after authentication
     * @param {AppState} [appState] - The application state passed during authentication
     * @param {User} [user] - The authenticated user information
     */
    const onRedirectCallback = (appState?: AppState, user?: User) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          audience={audience}
          scope="openid profile email"
        >
          {children}
        </Auth0Provider>
      );
    };

export default Auth0ProviderWithHistory;
