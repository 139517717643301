import { Label, HTMLSelect, EditableText, TextArea, ButtonGroup, Button, Divider, Switch, Slider, MenuItem, Menu, InputGroupProps2, Tag, Colors, Popover } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { SketchPicker, ColorResult } from 'react-color';
import React, { useState, useCallback, useContext, useMemo} from 'react';
import { DisplayFieldTypes, Tab, Units } from '../Data/enums';
import { commentDisplayParams, displayMap, layerDisplayParams} from '../Data/display-mappings';
import { setProperty, hasKey, getProperty, getValue, getCustomValue, deleteProperty, getLayerValue } from '../utils/ts-helpers';
import { Color, IColor } from '../utils/Color';
import { NumericInput } from './Fields/NumericInput';
import { PercentageSlider} from './Fields/PercentageSlider'
import { LabeledSwitch } from './Fields/LabeledSwitch';
import { CsvInput } from './Fields/CsvInput';
import { Input } from './Fields/Input';
import { BloxTypes } from '../Data/BloxSchema/base-blox';
import { IconNames } from '@blueprintjs/icons';
import { v4 as uuidv4 } from 'uuid'
import { useFabuState } from '../hooks/state/use-fabu-state';
import { Row, Column } from '../Layout/layouts';
import { BloxSvg } from './svg/BloxSvg';
import { UnsavedChangesContext } from '../hooks/state/unsaved-changes-provider';
import { useReadUser } from '../hooks/DataFetching/use-fetch-user';
import { useAuth0 } from '@auth0/auth0-react';
import { ItemPredicate, MultiSelect } from '@blueprintjs/select';
import { getColorForBlox, getMaterials } from '../hooks/material-targets';
import MaterialSelectorField from './Fields/MaterialSelector';
import MaterialDialog from '../dialogs/MaterialDialog/MaterialDialog';
import { SelectedBloxMaterialProperties } from '../dialogs/MaterialDialog/hooks/use-material-handlers';
import { SpunMaterialType } from '../Data/BloxSchema/spin-coat-unified';
import { LayerSelectivity, SelectivityRateInput } from './Fields/SelectivityRateInput';
import { MaterialLegendSelectivity, MaterialLegendVisibility } from './Fields/MaterialLegendVisibility';
import { updateGdsPattern } from '../utils/gds/update-gds-pattern';
import { LithoProperties } from '../Data/BloxSchema/litho-base';
import DisplayPatternFields from './Fields/DisplayPatternFields';
import SemifabPatternFields from './Fields/SemifabPatternFields';

const filterItem: ItemPredicate<string> = (query, val, _index, exactMatch) => {
    const normalizedItem = val.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedItem === normalizedQuery;
    } else {
        return normalizedItem.indexOf(normalizedQuery) >= 0;
    }
}

export const RightPanelBloxInfo: React.FC = () => {

    const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
    const [processBloxes, setProcessBloxes] = useFabuState('processBloxes');
    const [processIsReadOnly, ] = useFabuState('processIsReadOnly');
    const [processGdsInfo, ] = useFabuState('processGdsInfo');
    const processGdsLayers = useMemo(() => {
        if (!processGdsInfo) return [];

        const layersArray: string[] = [];
    
        // Get the first cutline from the regionsMap
        const firstCutlineKey = Object.keys(processGdsInfo.regionsMap)[0];
        if (firstCutlineKey) {
            const layers = processGdsInfo.regionsMap[firstCutlineKey].layers;
            
            // Push each layer (key) to the layersArray
            Object.keys(layers).forEach(layerName => {
                layersArray.push(layerName);
            });
        }
    
        return layersArray;
    }, [processGdsInfo]);

    const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);
    const {user} = useAuth0();
    const userId = user?.sub;
    const {data: userData} = useReadUser(userId);
    const showSimulateTab = !!userData?.enabledFeatures.stackSimulator;

    const [selectedBloxMaterialProperties, setSelectedBloxMaterialProperties] = useState<SelectedBloxMaterialProperties | null>(null);
    const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
    const onCloseMaterialDialog = () => {
        setSelectedBloxMaterialProperties(null);
        setMaterialDialogOpen(false);
    }

    const readOnly = processIsReadOnly;

    // holds the user input without saving. Allows validation on input
    const [inputValueDict, setInputValueDict] = useState({} as {[key: string]: string});
    const [inputCustomValueDict, setCustomInputValueDict] = useState({} as {[key: string]: string});

    const selectedBloxIdx = processBloxes.findIndex(blox => blox.id === selectedBloxId);
    const selectedBlox = processBloxes[selectedBloxIdx];

    // backward compatibility for multi layer blox
    if (selectedBlox && selectedBlox.bloxType === BloxTypes.StartBlox && !selectedBlox.layers) {
        selectedBlox.layers = [{
            "layerLabel": selectedBlox.layerLabel ?? "",
            "layerThickness": selectedBlox.layerThickness ?? 10,
            "layerSimulationThickness": 1000,
            "layerSimulationThicknessUnit": Units.NM,
            "layerColor": selectedBlox.layerColor ?? {R: 255, G: 255, B: 255, A: 1},
            showLabel: true,
            materialId: selectedBlox.id
        }];
    }

    const [tab, setTab] = useState(Tab.EXPERIMENTAL);

    const bloxDisplayMap = selectedBlox ? displayMap[selectedBlox.bloxType] : {};
    const fieldsToDisplay = selectedBlox ? Object.keys(bloxDisplayMap).filter(property => bloxDisplayMap[property].tabs.includes(tab)) : [];
    const customFields = selectedBlox && selectedBlox.customFields ? Object.keys(selectedBlox.customFields) : [];

    fieldsToDisplay.sort((a,b) => bloxDisplayMap[a].order -  bloxDisplayMap[b].order);


    const onChangeValue = useCallback(
        (
            property?: string,
            newVal?: any,
            updates?: { property: string, value: any }[] // Optional third argument
        ) => {
            if (!property && !updates) return;

            const safeProperty = property as string;

            // Normalize updates to an array of properties for consistent logic
            const normalizedUpdates = updates || [{ property: safeProperty, value: newVal }];

            // Start with the selected blox
            let updatedBlox = { ...processBloxes[selectedBloxIdx] };

            // Apply all updates
            normalizedUpdates.forEach(({ property: p, value }) => {
                // Handle properties not in the blox yet
                if (!hasKey(updatedBlox, p)) {
                    updatedBlox = { ...updatedBlox, [p]: value };
                }

                // Update property if it's already defined or meets the backwards compatibility condition
                if (hasKey(updatedBlox, p) || p === 'toolName') {
                    setProperty(updatedBlox, p, value);
                }
            });

            // Replace the old blox with the updated one
            const updatedBloxes = [...processBloxes];
            updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox);

            // Update the process bloxes and mark changes as unsaved
            setProcessBloxes([...updatedBloxes]);
            setEditorUnsavedChanges(true);
        },
        [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]
    );

    const onChangeLayerValue = useCallback((layerIndex: number, property: string, newVal: any) => {
        
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.layers) {
            let temp = {};

            // account for properties added after save
            // change them if the user can also see them as defined in the display map
            if (!hasKey(updatedBlox.layers[layerIndex], property)) {
                temp = {[property]: newVal}
            }

            updatedBlox.layers[layerIndex] = {
                ...updatedBlox.layers[layerIndex],
                ...temp
            }

            if (hasKey(updatedBlox.layers[layerIndex], property)) {
                setProperty(updatedBlox.layers[layerIndex], property, newVal);
            }
        }

        
        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);

    }, [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]);

    const onChangeCustomValue = useCallback((id: string, newVal: any) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }
        
        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            const curr = getProperty(updatedBlox.customFields, id);
            curr[1] = newVal;
            setProperty(updatedBlox.customFields, id, curr);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);


        setEditorUnsavedChanges(true);
    }, [processBloxes, setProcessBloxes, selectedBloxIdx, setEditorUnsavedChanges]);

    const onChangeCustomLabel = useCallback((id: string, newLabel: any) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            const curr = getProperty(updatedBlox.customFields, id);
            curr[0] = newLabel;
            setProperty(updatedBlox.customFields, id, curr);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, setProcessBloxes, setEditorUnsavedChanges, selectedBloxIdx]);

    const addCustomField = useCallback(() => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields) {
            const fieldCount = Object.keys(updatedBlox.customFields).length;
            const customFieldId = `${updatedBlox.id}-custom-${uuidv4()}`
            setProperty(updatedBlox.customFields, customFieldId, [`New Field ${fieldCount+1}`, ""]);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);
        
        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);

    const deleteCustomField = useCallback((id:string) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        if (updatedBlox.customFields && hasKey(updatedBlox.customFields, id)) {
            // delete it
            deleteProperty(updatedBlox.customFields, id);
        }

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);


    const addLayer = useCallback(() => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        const oldLayers = updatedBlox.layers ?? [];
        const showLabel = oldLayers.length > 1 ? oldLayers[0].showLabel : true;

        const newLayerIndex = oldLayers.length + 1;

        // calculate new color value
        let colorValue = 255 - (newLayerIndex * 20);
        if (colorValue < 0) {
            colorValue = 0;
        }

        // find label count and generate new label
        let newLayerLabel = "Unknown Material"
        const unknownNames = oldLayers.map(layerProperties => layerProperties.layerLabel ?? "").filter(label => label && label.startsWith(newLayerLabel));
        if (unknownNames.length > 0) {
            let largestNumber = 1;

            unknownNames.forEach((name) => {
              const match = name.match(/Unknown Material (\d+)/);
              if (match) {
                const number = parseInt(match[1], 10);
                if (number > largestNumber) {
                  largestNumber = number;
                }
              }
            });
          
            newLayerLabel += " " + (largestNumber + 1);
        }

        updatedBlox.layers = [
            ...oldLayers,
            {
                layerColor: {R: colorValue, G: colorValue, B: colorValue, A: 1},
                layerThickness: 8,
                layerSimulationThickness: 100,
                layerSimulationThicknessUnit: Units.NM,
                layerLabel: newLayerLabel,
                showLabel: showLabel,
                materialId: uuidv4()
            }
        ]

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);

        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);

    // TODO: ADD BUTTON TO DELETE LAYER ON MATERIAL EDITOR
    const deleteLayer = useCallback((layerIndex: number) => {
        const updatedBlox = {
            ...processBloxes[selectedBloxIdx]
        }

        const layers = updatedBlox.layers ?? [];
        layers.splice(layerIndex, 1);
        updatedBlox.layers = layers;

        //remove old blox and add updatedBlox
        const updatedBloxes = [...processBloxes];
        updatedBloxes.splice(selectedBloxIdx, 1, updatedBlox)
        
        setProcessBloxes([...updatedBloxes]);
        
        setEditorUnsavedChanges(true);
    }, [processBloxes, selectedBloxIdx, setProcessBloxes, setEditorUnsavedChanges]);
        
    if (!selectedBlox)
        return <span>No Blox Selected</span>;

    // used for bloxes that have multiple layers
    const getLayerDisplayElement = (layerIndex: number) => {
        let labelValue = getLayerValue(layerIndex, "layerLabel", selectedBlox);
        let thicknessValue = getLayerValue(layerIndex, "layerThickness", selectedBlox);
        let simulationThicknessValue = getLayerValue(layerIndex, "layerSimulationThickness", selectedBlox);
        let simulationThicknessUnitValue = getLayerValue(layerIndex, "layerSimulationThicknessUnit", selectedBlox);
        let colorValue = getLayerValue(layerIndex, "layerColor", selectedBlox);
        let showLabel = getLayerValue(layerIndex, "showLabel", selectedBlox);


        // check for default values
        if (labelValue === undefined)
            labelValue = layerDisplayParams["layerLabel"].defaultValue;
        if (thicknessValue === undefined)
            thicknessValue = layerDisplayParams["layerThickness"].defaultValue;
        if (simulationThicknessValue === undefined)
            simulationThicknessValue = layerDisplayParams["layerSimulationThickness"].defaultValue;
        if (simulationThicknessUnitValue === undefined && layerDisplayParams["layerSimulationThickness"].units && layerDisplayParams["layerSimulationThickness"].units.length > 0)
            simulationThicknessUnitValue = layerDisplayParams["layerSimulationThickness"].units[0];
        if (colorValue === undefined)
            colorValue = layerDisplayParams["layerColor"].defaultValue;
        if (showLabel === undefined)
            showLabel = layerDisplayParams["showLabel"].defaultValue;
        
        let thicknessNumVal = Number(thicknessValue);
        thicknessNumVal = typeof thicknessNumVal !== 'number' ? 0 : thicknessNumVal;

        let simulationThicknessNum = Number(simulationThicknessValue);
        simulationThicknessNum = typeof simulationThicknessNum !== 'number' ? 0 : simulationThicknessNum;
        
        const layerUnits = layerDisplayParams["layerSimulationThickness"].units ?? [];

        const unitElement = (
            <Popover2 className={'inputMenuPopover'}
                content={
                    <Menu className={'inputMenu'}>
                        {layerUnits.map(unit => <MenuItem onClick={() => onChangeLayerValue(layerIndex, 'layerSimulationThicknessUnit', unit)} text={unit} key={unit} />) }
                    </Menu>
                }
                disabled={readOnly}
                placement="bottom-end"
            >
                <Button text={simulationThicknessUnitValue ? simulationThicknessUnitValue.toString(): Units.NM} disabled={readOnly} minimal={true} rightIcon="caret-down"/>
            </Popover2>
        );

        return <div key={`${selectedBlox.id}-layer--${layerIndex}`} style={{"width":"100%", "paddingBottom": "15px"}}>
             <MaterialSelectorField
                key={`${selectedBlox.id}-${layerIndex}-label`}
                value={{name: labelValue as string, color: colorValue as IColor ?? {R: 255, G: 255, B: 255, A: 1}}}
                materialInfo={{
                    bloxId: selectedBlox.id,
                    property: 'layers',
                    propertyLabel: `Layer ${layerIndex + 1}`,
                    layerIndex: layerIndex,
                }}
                setMaterial={() => {
                    setSelectedBloxMaterialProperties({
                        bloxId: selectedBlox.id,
                        property: 'layers',
                        propertyLabel: `Layer ${layerIndex + 1}`,
                        layerIndex: layerIndex,
                    });
                }}
                setMaterialDialogOpen={setMaterialDialogOpen}
                bloxType={selectedBlox.bloxType}
                disabled={readOnly}
                label={"Layer " + (layerIndex + 1)}
                removeButton={layerIndex > 0 ? <Button disabled={processIsReadOnly} small={true} minimal={true} icon={IconNames.REMOVE} onClick={() => {console.log("remove"); deleteLayer(layerIndex)}} /> : <></>}
            />
            <Switch disabled={readOnly} checked={showLabel as boolean} label={"Show Label"} onChange={() => onChangeLayerValue(layerIndex, "showLabel", !showLabel)}  />
            <Row>
                {tab === Tab.DISPLAY ? <Column style={{ "width": "100%", "paddingTop": "5px" }}>
                    <Slider
                        key={`${selectedBlox.id}-layer--${layerIndex}-layerThickness`}
                        min={1}
                        max={50}
                        stepSize={1}
                        labelStepSize={49}
                        onChange={(val) => onChangeLayerValue(layerIndex, "layerThickness", val)}
                        value={thicknessNumVal} 
                        disabled = {readOnly} />
                </Column> : <NumericInput
                    key={`${selectedBlox.id}-layer--${layerIndex}-layerSimulationThickness`}
                    id={`${selectedBlox.id}-layer--${layerIndex}-layerSimulationThickness`}
                    label={layerDisplayParams["layerSimulationThickness"].label}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    inputGroupProps={{
                        title: layerDisplayParams["layerSimulationThickness"].placeholder,
                        value: simulationThicknessNum.toString(),
                        placeholder: layerDisplayParams["layerSimulationThickness"].placeholder,
                        disabled: readOnly,
                        rightElement: unitElement
                    }} 
                    onChange={(val) => onChangeLayerValue(layerIndex, "layerSimulationThickness", val)} 
                />}
            </Row>    
            <Divider/>
        </div>;
    }

    const getDisplayElement = (property: string, index?: number) => {
        const { fieldType,
            label,
            placeholder,
            options,
            optionLabels,
            isNumber,
            units,
            defaultValue,
            materialFilter,
            maxSliderValue,
            infoContent
        } = property === "commentField" ? commentDisplayParams : bloxDisplayMap[property];
        let value = getValue(property, selectedBlox);
        
        // check for default values
        if (value === undefined && defaultValue !== undefined)
            value = defaultValue;

        let rightEl;

        const inputGroupProps: InputGroupProps2 = {
            title: placeholder, 
            rightElement: rightEl,
            value: value as string ?? "", 
            disabled: readOnly,
            placeholder: placeholder 
        }

        if (units) {
            const unitPropertyName = `${property}Unit`;
            const currUnit = hasKey(selectedBlox, unitPropertyName) ? (getProperty(selectedBlox, unitPropertyName) as string) : units.length > 0 ? units[0] : undefined;
            inputGroupProps.rightElement = (
                <Popover2 className={'inputMenuPopover'}
                    content={
                        <Menu className={'inputMenu'}>
                            {units.map(unit => <MenuItem onClick={() => onChangeValue(unitPropertyName, unit)} text={unit} key={unit} />) }
                        </Menu>
                    }
                    disabled={readOnly}
                    placement="bottom-end"
                >
                    <Button text={currUnit} disabled={readOnly} minimal={true} rightIcon="caret-down"/>
                </Popover2>
            );
        }

        const materialLabels: string[] = getMaterials(processBloxes, selectedBloxIdx, materialFilter).map(material => material.materialLabel);

        let maxValue = 0;
        let labelStepSize = 0;
        let selectDisabled = readOnly;
        let multiSelectValue = value as string[] ?? [];
        let multiSelectPlaceholder = "Select one or more";
        const emptyLabels = "No targets found.";
                    
        let disabled = false
        const disableName = `${property}Disabled`;
        if (hasKey(selectedBlox, disableName) && typeof selectedBlox[disableName] === 'function'){
            const func = selectedBlox[disableName] as (() => boolean | null);
            disabled = func.call(selectedBlox) ?? false;
        }

        const materialColor = getColorForBlox(selectedBlox, property);
 
        const hiddenField = `${property}Hidden`;
        if (hasKey(selectedBlox, hiddenField) && typeof selectedBlox[hiddenField] === 'function'){
            const func = selectedBlox[hiddenField] as (() => boolean | null);
            if (func.call(selectedBlox)) {
                return <></>;
            }
        }

        const matFieldProps = { 
            bloxId: selectedBlox.id,
            property,
            propertyLabel: label ?? ""
        };

        switch (fieldType) {
            case DisplayFieldTypes.MaterialEditor:
                return <MaterialSelectorField
                    key={`${selectedBlox.id}-${label}`}
                    value={{name: value as string, color: materialColor}}
                    blox={selectedBlox}
                    materialInfo={matFieldProps}
                    setMaterial={() => setSelectedBloxMaterialProperties(matFieldProps)}
                    setMaterialDialogOpen={setMaterialDialogOpen}
                    bloxType={selectedBlox.bloxType}
                    disabled={readOnly || disabled}
                    label={label ?? ''}
                />;
            case DisplayFieldTypes.PatternFields:
                if (!selectedBlox.patternType) {
                    selectedBlox.patternType = "Manual";
                }
                return tab === Tab.DISPLAY ?  <DisplayPatternFields
                    key={`${selectedBlox.id}-${label}`}
                    processGdsLayers={processGdsLayers}
                    lithoBlox={selectedBlox as LithoProperties}
                    onChange={onChangeValue}
                    readOnly={disabled}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    baseId={`${selectedBlox.id}-${label}`}
                /> : tab === Tab.SEMIFAB ? <SemifabPatternFields
                    key={`${selectedBlox.id}-${label}-semifab`}
                    processGdsLayers={processGdsLayers}
                    lithoBlox={selectedBlox as LithoProperties}
                    onChange={onChangeValue}
                    readOnly={disabled}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    baseId={`${selectedBlox.id}-${label}`}
                /> : <></>;
            case DisplayFieldTypes.Input:
                if (isNumber) {
                    return <NumericInput
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        inputValueDictState={[inputValueDict, setInputValueDict]}
                        label={label}
                        inputGroupProps={inputGroupProps}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        disabled={disabled}
                    />
                } else {
                    return <Input
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        inputValueDictState={[inputValueDict, setInputValueDict]}
                        label={label}
                        inputGroupProps={inputGroupProps}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        disabled={disabled}
                    />
                }
            case DisplayFieldTypes.PatternInput:
                return <CsvInput
                    key={`${selectedBlox.id}-${label}`}
                    id={`${selectedBlox.id}-${label}`}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    label={label}
                    inputGroupProps={inputGroupProps}
                    infoContent={infoContent}
                    onChange={(val) => onChangeValue(property, val)}
                    disabled={disabled}
                />;
            case DisplayFieldTypes.Dropdown:
                if (optionLabels) {
                    return <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel">
                        {label}
                        <HTMLSelect value={optionLabels[value as string] ?? ""} disabled={readOnly} options={options?.map(option => optionLabels[option])} onChange={(event) => {
                            if (event.target.value === SpunMaterialType.SEPERATOR) {
                                return;
                            }
                            onChangeValue(property, Object.entries(optionLabels).find(([_, v]) => v === event.target.value)?.[0])
                        }} key={`${selectedBlox.id}-${index}`}/>
                    </Label>;
                } else {
                    return <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel">
                        {label}
                        <HTMLSelect value={value as string ?? ""} disabled={readOnly} options={options} onChange={(event) => onChangeValue(property, event.target.value)} key={`${selectedBlox.id}-${index}`}/>
                    </Label>;
                }
            case DisplayFieldTypes.MultiMaterialSelect:
                if (materialLabels.length === 0) {
                    materialLabels.push(emptyLabels);
                }
                
                if (property === "layerLabelsToGrow") {
                    // check if isotropic etch is turned on, if so, disable this field 
                    materialLabels.shift();
                    const selectiveGrowth = getValue("selectiveGrowth", selectedBlox);
                    if (selectiveGrowth) {
                        selectDisabled = false;
                    } else {
                        selectDisabled = true;
                        multiSelectValue = [];
                        multiSelectPlaceholder = "All Exposed Surfaces";
                    }   
                }
                selectDisabled = selectDisabled || disabled;
                
                //  NOTICE:
                // there is a bug in MultiSelect - if the component is under a Label - onRemove gets called twice
                return <div style={{marginBottom: 10}}>
                        <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel" style={{marginBottom: 5}}>
                            {label}
                        </Label> 
                        <MultiSelect 
                            fill={true}
                            openOnKeyDown={false}
                            selectedItems={multiSelectValue} 
                            disabled={selectDisabled}
                            items={materialLabels} 
                            itemPredicate={filterItem}
                            itemRenderer={(val,  { modifiers, handleClick }) => { 
                                if (val === emptyLabels) {
                                    return ( 
                                        <MenuItem 
                                            key={val} 
                                            text={val}
                                            disabled={true}
                                            active={false} 
                                        /> 
                                    ); 
                                } else {
                                    return ( 
                                        <MenuItem 
                                            key={val} 
                                            text={val} 
                                            onClick={handleClick} 
                                            active={multiSelectValue.includes(val)} 
                                        /> 
                                    ); 
                                }
                                
                                
                            }} 
                            onItemSelect={(val) => { 
                                // select / deselect item
                                if (multiSelectValue.includes(val)) {
                                    onChangeValue(property, multiSelectValue.filter(e => e !== val));
                                } else {
                                    onChangeValue(property, [val, ...multiSelectValue]);
                                }
                            }} 
                            onRemove={(val) => {
                                onChangeValue(property, multiSelectValue.filter(e => e !== val));
                            }}
                            placeholder={multiSelectPlaceholder}
                            tagRenderer={(item) => item} 
                        /> 
                    </div>;
            case DisplayFieldTypes.LayerSelectivity:
                return disabled ? undefined : <SelectivityRateInput
                    infoContent={infoContent}
                    key={`${selectedBlox.id}-${label}`}
                    id={`${selectedBlox.id}-${label}`}
                    onChange={(newValue: LayerSelectivity[]) => onChangeValue(property, newValue)}
                    value={value as LayerSelectivity[]}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    selectedMaterials={getValue('layerLabelsToEtch', selectedBlox) as string[]}
                />;
            case DisplayFieldTypes.MaterialSelectivity:
                return disabled ? undefined : <MaterialLegendVisibility
                    infoContent={infoContent}
                    key={`${selectedBlox.id}-${label}`}
                    id={`${selectedBlox.id}-${label}`}
                    onChange={(newValue: MaterialLegendSelectivity[]) => onChangeValue(property, newValue)}
                    value={value as MaterialLegendSelectivity[]}
                    inputValueDictState={[inputValueDict, setInputValueDict]}
                    selectedMaterials={getValue('layerLabelsToImplant', selectedBlox) as string[]}
                />;
            case DisplayFieldTypes.GdsLayerSelect:
                return <Label key={`${selectedBlox.id}-${index}`} className="righPanelLabel">
                    {label}
                    <HTMLSelect value={value as string ?? "Select Layer or Upload GDS"} disabled={readOnly || disabled} options={["Select Layer or Upload GDS", ...processGdsLayers]} onChange={(event) => {
                        if (processGdsInfo && event.target.value !== "Select Layer or Upload GDS") {
                            updateGdsPattern(processGdsInfo, processBloxes, selectedBloxIdx, event.target.value, setProcessBloxes)
                        } else {
                            onChangeValue(property, null);
                        }

                    }} key={`${selectedBlox.id}-${index}`} />
                </Label>;
            // For Title
            case DisplayFieldTypes.Multiline:
                return <Label key={`${selectedBlox.id}-comments`} className={"righPanelLabel rightPanelCommentsContainer"} >
                    {label}
                    <TextArea disabled={readOnly} className={"rightPanelComments"} fill={true} value={value as string ?? ""} onChange={(event) => onChangeValue(property, event.target.value)} title={value as string}  key={`${selectedBlox.id}-comments`}/>
                </Label>;
            case DisplayFieldTypes.Switch:
                {
                    return <LabeledSwitch
                        key={`${selectedBlox.id}-${label}`}
                        label = {label}
                        infoContent = {infoContent}
                        disabled = {readOnly || disabled}
                        onChange={() => onChangeValue(property, !value)}
                        checked = {value as boolean}
                    />
                }
            case DisplayFieldTypes.Separator:
                {
                    return <div style={{marginBottom: 20}}><Divider /></div>
                }
            case DisplayFieldTypes.Color:
                return <Label key={`${selectedBlox.id}-${index}`}>
                    {label}
                    <Popover
                        className={'inputMenuPopover'}
                        content={
                            <SketchPicker
                                color={{
                                    r: (value as IColor).R,
                                    g: (value as IColor).G,
                                    b: (value as IColor).B,
                                    a: (value as IColor).A
                                }}
                                onChange={(newColor) => {
                                    onChangeValue(property, {
                                        R: newColor.rgb.r,
                                        G: newColor.rgb.g,
                                        B: newColor.rgb.b,
                                        A: newColor.rgb.a ?? 1
                                    });
                                }}
                            />
                        }
                        disabled={readOnly || disabled}
                        placement='auto'
                    >
                        <div className='colorPreview' style={{ backgroundColor: Color.getCSS(value as IColor) }} />
                    </Popover>
                </Label>   
            case DisplayFieldTypes.Slider:
                {// backward compatibility for conversion of input field to slider
                    let numVal = Number(value);
                    numVal = typeof numVal !== 'number' ? 0 : numVal;
                    maxValue = maxSliderValue ?? 50;
                    labelStepSize = maxValue - 1;

                    return <Label key={`${selectedBlox.id}-${index}`} className={"righPanelLabel"}>
                        {label}
                        <Slider
                            min={1}
                            max={maxValue}
                            stepSize={1}
                            labelStepSize={labelStepSize}
                            onChange={(val) => onChangeValue(property, val)}
                            value={numVal}
                            disabled={readOnly || disabled}
                        // vertical={false}
                        />
                    </Label>
                }
            case DisplayFieldTypes.PercentageSlider:
                {// backward compatibility for conversion of input field to slider
                    let disabled = readOnly;

                    if (property === "sidewaysEtch") {
                        // check if isotropic etch is turned on, if so, disable this field 
                        const isotropicEtch = getValue("isotropicEtch", selectedBlox);
                        if (isotropicEtch) {
                            disabled = true;
                        }
                    }

                    return  <PercentageSlider
                        key={`${selectedBlox.id}-${label}`}
                        id={`${selectedBlox.id}-${label}`}
                        label={label}
                        onChange={(val) => onChangeValue(property, val)}
                        infoContent={infoContent}
                        value={Number(value)}
                        maxSliderValue={100}
                        stepSize={1}
                        disabled={disabled}
                    />  
                }   
            default:
                return <></>;
        }
    }

    const getCustomDisplayElement = (id: string) => {
        const [label, value] = getCustomValue(id, selectedBlox);

        const rightEl = <Button minimal={true} small={true} icon={IconNames.REMOVE} onClick={() => deleteCustomField(id)} />

        const inputGroupProps: InputGroupProps2 = {
            title: "", 
            rightElement: rightEl,
            value: value as string ?? "", 
            disabled: readOnly,
            placeholder: "" 
        }
          
        return <Input
                key={id}
                id={id}
                inputValueDictState={[inputCustomValueDict, setCustomInputValueDict]}
                label={label}
                inputGroupProps={inputGroupProps}
                onChange={(val) => onChangeCustomValue(id, val)}
                onChangeLabel={(val) => onChangeCustomLabel(id, val)}
            />
    }

    //todo choose a better key then index
    return <>
        {(selectedBloxMaterialProperties) && <MaterialDialog isOpen={materialDialogOpen} onClose={onCloseMaterialDialog} selectedBloxMaterialProperties={selectedBloxMaterialProperties} selectedBlox={selectedBlox} />}
        <EditableText placeholder='Click to edit Blox Name' disabled={readOnly} className={"rightPanelTitle"} value={selectedBlox.name} onChange={(val) => onChangeValue("name", val)}/>
        { selectedBlox.bloxType !== BloxTypes.StartBlox && <div className="rightPanelToolName">
            <EditableText placeholder='Click to edit Tool Name' value={selectedBlox.toolName ?? ""} onChange={(val) => onChangeValue("toolName", val)}/>
        </div> }
        <BloxSvg bloxType={selectedBlox.bloxType} className='rightPanelImage'/>
        <Divider/>
            <ButtonGroup className={'blox-tab-group'} fill={true} minimal={true}>
                <Button active={tab === Tab.EXPERIMENTAL} onClick={() => setTab(Tab.EXPERIMENTAL)} minimal={true}>Experimental</Button>
                <Button active={tab === Tab.DISPLAY} onClick={() => setTab(Tab.DISPLAY)} minimal={true}>Display</Button>
                <Button text='Simulate' className={`simulate-tab ${!showSimulateTab ? 'simulate-tab-hidden' : ''}`} active={tab === Tab.SEMIFAB} onClick={() => setTab(Tab.SEMIFAB)} minimal={true}/>
            </ButtonGroup>
        <Divider />        
        <div style={{'marginBottom': '6px'}}>
            {tab === Tab.SEMIFAB && <Row>
                <Tag minimal style={{marginLeft: 'auto', backgroundColor: Colors.BLUE2, color: Colors.WHITE}}>Beta</Tag>
            </Row>
                }
        </div>
        { selectedBlox && selectedBlox.bloxType === BloxTypes.StartBlox && <>
                { !readOnly && <Button 
                    onClick={() => addLayer()} 
                    minimal={false} 
                    icon={IconNames.PLUS}
                    style={{marginBottom: 15, marginTop:15}}>Add Layer</Button> 
                }
                { selectedBlox.layers && [...selectedBlox.layers].reverse().map((layer, index) => {
                    const realIndex = selectedBlox.layers ? selectedBlox.layers.length - index - 1 : 0;
                    return getLayerDisplayElement(realIndex);
                }) }
            </>
        }
        { fieldsToDisplay.map((property, index) => getDisplayElement(property, index)) }
        { tab === Tab.EXPERIMENTAL && <>
            { selectedBlox && selectedBlox.bloxType !== BloxTypes.StartBlox && <>
                { customFields.map((id, index) => getCustomDisplayElement(id)) }
                { !readOnly && <Button onClick={() => addCustomField()} minimal={false} style={{marginBottom: 15, marginTop:22}}>Add Custom Parameter</Button> }
            </>}
            { getDisplayElement("commentField")}
        </> }
    </>;
}
