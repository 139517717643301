import React, { createContext, useContext, useState } from 'react';

/**
 * State interface for the export dialog
 * @interface ExportDialogState
 * @property {("PNG" | "SVG" | "GIF" | "WEBM")} fileFormat - The current export file format
 */
export interface ExportDialogState {
    fileFormat: "PNG" | "SVG" | "GIF" | "WEBM";
}

/**
 * Context value interface for the export dialog
 * @interface ExportDialogContextValue
 * @property {ExportDialogState} state - Current state of the export dialog
 * @property {Object} actions - Actions to modify the export dialog state
 */
interface ExportDialogContextValue {
    state: ExportDialogState;
    setFileFormat: (format: "PNG" | "SVG" | "GIF" | "WEBM") => void;
}

const ExportDialogContext = createContext<ExportDialogContextValue | undefined>(undefined);

/**
 * Provider component for export dialog state
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 */
export const ExportDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [fileFormat, setFileFormat] = useState<"PNG" | "SVG" | "GIF" | "WEBM">("PNG");

    return (
        <ExportDialogContext.Provider value={{
            state: {
                fileFormat,
            },
            setFileFormat,
        }}>
            {children}
        </ExportDialogContext.Provider>
    );
};

/**
 * Hook to access export dialog state and actions
 * @returns {ExportDialogContextValue} Export dialog context value
 * @throws {Error} If used outside of ExportDialogProvider
 */
export const useExportDialogState = () => {
    const context = useContext(ExportDialogContext);
    if (!context) {
        throw new Error('useExportDialogState must be used within an ExportDialogProvider');
    }
    return context;
}; 