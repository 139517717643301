/**
 * @fileoverview ProcessListViewStack is a visualization component that provides a 2D stack representation
 * of process steps. It enhances the ProcessListView with interactive visual feedback of the process flow
 * and supports various export options.
 * 
 * Key Features:
 * - Interactive 2D stack visualization
 * - Process step navigation
 * - Export capabilities (GIF/Image)
 * - Visual step indicators
 * - Responsive layout
 * 
 * Component Hierarchy:
 * ProcessListView
 * └─ ProcessListViewStack (This Component)
 *    └─ StackViewer
 *       ├─ Step Visualizations
 *       └─ Navigation Controls
 * 
 * State Flow:
 * 1. Receives current step data from ProcessListView
 * 2. Manages stack visualization state
 * 3. Handles navigation and export interactions
 * 4. Updates visual representation
 * 
 * @component
 */

import React from 'react';
import { BloxTypes } from '../../Data/BloxSchema/base-blox';
import StackViewer from './StackViewer';
import { processListViewStyles } from './styles/ProcessListView.styles';
import { ExportDialogProvider } from '../../components/export-dialog/hooks/state/export-dialog-provider';

/**
 * Props interface for the ProcessListViewStack component.
 * Defines the properties required for stack visualization and navigation.
 * 
 * @interface ProcessListViewStackProps
 * 
 * @property {BloxTypes} currentBloxType - Type of the currently displayed process block
 * @property {number} stepNumber - Current step number in the process sequence
 * @property {any[]} nonHeaderItems - Array of process items excluding section headers
 * @property {() => void} onNext - Callback to navigate to the next step
 * @property {() => void} onPrevious - Callback to navigate to the previous step
 */
interface ProcessListViewStackProps {
  currentBloxType: BloxTypes;
  stepNumber: number;
  nonHeaderItems: any[];
  onNext: () => void;
  onPrevious: () => void;
}

/**
 * ProcessListViewStack Component
 * Provides an interactive 2D visualization of the process stack with navigation
 * and export capabilities.
 * 
 * Features:
 * - Interactive step visualization
 * - Process navigation controls
 * - Export functionality (GIF/Image)
 * - Visual step indicators
 * - Responsive layout adaptation
 * 
 * @component
 */
const ProcessListViewStack: React.FC<ProcessListViewStackProps> = ({
  currentBloxType,
  stepNumber,
  nonHeaderItems,
  onNext,
  onPrevious
}) => (
  <ExportDialogProvider>
    <StackViewer
      currentBloxType={currentBloxType}
      onNext={onNext}
      onPrevious={onPrevious}
      stepNumber={stepNumber}
      processBloxes={nonHeaderItems}
      sx={processListViewStyles.stack.fixedContainer}
    />
  </ExportDialogProvider>
);

export default ProcessListViewStack;