/**
 * Utils for iframe-to-parent messaging
 */

export interface ProcessNotesPayload {
  processId: string;
  processName: string;
  requestDetails: string;
  timestamp: string;
  processUrl: string;
  requesterName?: string;
  requesterOrg?: string;
  literatureReferences?: string;
  contactInfo?: string;
  customProcessLink?: string;
}

export interface MessageResponse {
  type: string;
  success: boolean;
  message: string;
  processUrl?: string;
}

// New message types for iframe communication
export enum MessageTypes {
  SEND_NOTES = 'FABUBLOX_REQUEST',
  NOTES_RECEIVED = 'FABUBLOX_REQUEST_RECEIVED',
  LOAD_PROCESS = 'FABUBLOX_LOAD_PROCESS',
  CHANGE_VIEW_MODE = 'FABUBLOX_CHANGE_VIEW_MODE',
  CHANGE_SIZE = 'FABUBLOX_CHANGE_SIZE',
  START_NEW_PROCESS = 'FABUBLOX_START_NEW_PROCESS',
  PROCESS_LOADED = 'FABUBLOX_PROCESS_LOADED',
  ERROR = 'FABUBLOX_ERROR'
}

// New interface for load process payload
export interface LoadProcessPayload {
  processId: string;
  signature?: string;
}

// New interface for change view mode payload
export interface ChangeViewModePayload {
  viewMode: 'list' | 'table' | 'standard';
}

// New interface for change size payload
export interface ChangeSizePayload {
  size: 'small' | 'medium' | 'large' | 'full';
}

/**
 * Send process notes to the parent window
 */
export async function sendProcessNotes(payload: ProcessNotesPayload): Promise<MessageResponse> {
  return new Promise((resolve, reject) => {
    // Track if we've received a response
    let responseReceived = false;
    
    // Create message event listener
    const messageListener = (event: MessageEvent) => {
      // Only process messages from our parent
      if (event.source !== window.parent) return;
      
      // Look for confirmation messages
      if (event.data?.type === MessageTypes.NOTES_RECEIVED) {
        responseReceived = true;
        window.removeEventListener('message', messageListener);
        resolve(event.data as MessageResponse);
      }
    };
    
    // Set up response listener
    window.addEventListener('message', messageListener);
    
    // Set timeout to prevent indefinite waiting
    const timeoutId = setTimeout(() => {
      if (!responseReceived) {
        window.removeEventListener('message', messageListener);
        reject(new Error('No Response. Please try again. If the issue persists contact the MOSIS2.0 team directly using the "Contact Us" link at the top of the page.'));
      }
    }, 10000); // 10 second timeout
    
    try {
      // Construct and send the message
      const message = {
        type: MessageTypes.SEND_NOTES,
        payload
      };
      
      // Send to parent window
      window.parent.postMessage(message, '*');
      
    } catch (error) {
      window.removeEventListener('message', messageListener);
      clearTimeout(timeoutId);
      reject(error);
    }
  });
}

/**
 * Check if the page is running inside an iframe
 */
export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    // If we can't access window.top due to security restrictions,
    // we're definitely in an iframe
    return true;
  }
}

/**
 * Set up message handler for iframe communication
 * This should be called once on initial app load to handle all incoming messages
 */
export function setupMessageHandler(handlers: {
  onLoadProcess?: (processId: string, signature?: string) => void;
  onChangeViewMode?: (viewMode: 'list' | 'table' | 'standard') => void;
  onChangeSize?: (size: 'small' | 'medium' | 'large' | 'full') => void;
  onStartNewProcess?: () => void;
}) {
  // Create message event listener for handling parent messages
  const messageListener = (event: MessageEvent) => {
    // Only process messages from our parent
    if (event.source !== window.parent) return;
    
    
    // Process different message types
    switch (event.data?.type) {
      case MessageTypes.LOAD_PROCESS:
        if (handlers.onLoadProcess && event.data.payload) {
          const { processId, signature } = event.data.payload as LoadProcessPayload;
          handlers.onLoadProcess(processId, signature);
        }
        break;
        
      case MessageTypes.CHANGE_VIEW_MODE:
        if (handlers.onChangeViewMode && event.data.payload) {
          const { viewMode } = event.data.payload as ChangeViewModePayload;
          handlers.onChangeViewMode(viewMode);
        }
        break;
        
      case MessageTypes.CHANGE_SIZE:
        if (handlers.onChangeSize && event.data.payload) {
          const { size } = event.data.payload as ChangeSizePayload;
          handlers.onChangeSize(size);
        }
        break;
        
      case MessageTypes.START_NEW_PROCESS:
        if (handlers.onStartNewProcess) {
          handlers.onStartNewProcess();
        }
        break;
    }
  };
  
  // Set up listener
  window.addEventListener('message', messageListener);
  
  // Return a cleanup function
  return () => {
    window.removeEventListener('message', messageListener);
  };
}

/**
 * Notify parent that a process has been loaded
 */
export function notifyProcessLoaded(processId: string, processName: string) {
  if (!isInIframe()) return;
  
  window.parent.postMessage({
    type: MessageTypes.PROCESS_LOADED,
    payload: {
      processId,
      processName
    }
  }, '*');
}

/**
 * Notify parent about an error
 */
export function notifyError(message: string) {
  if (!isInIframe()) return;
  
  window.parent.postMessage({
    type: MessageTypes.ERROR,
    payload: {
      message
    }
  }, '*');
} 