import React, { useEffect, useContext, useState, useCallback, useMemo, useRef } from 'react';
import { Classes } from '@blueprintjs/core';
import { AllBloxes } from '../../Data/BloxSchema/base-blox';
import { useExportDialogState } from './hooks/state/export-dialog-provider';
import { useStepNavigation } from './hooks/use-step-navigation';
import { useImageExport, SaveMode } from './hooks/use-image-export';
import { useStackExport } from '../../hooks/use-stack-export';
import { ExportFormatControls } from './components/ExportFormatControls';
import { PreviewDisplay } from './components/PreviewDisplay';
import { StepNavigation } from './components/StepNavigation';
import { ExportActions } from './components/ExportActions';
import { SvgServiceContext } from '../../hooks/state/svg-service-provider';
import { SVGDisplayMode } from '../../Services/SVGEngine';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import { getMaterials } from '../../hooks/material-targets';

/**
 * Props for the ExportDialogContent component
 * @interface ExportDialogContentProps
 * @property {string} filename - Base filename for exports
 * @property {AllBloxes[]} bloxes - Array of bloxes to export
 * @property {number} [initialIndex] - Initial step index
 * @property {{ sectionId: string, sectionName?: string, bloxIds: string[] }[]} [processSections] - Process sections information
 * @property {() => void} closeDialog - Function to close the dialog
 * @property {() => void} onContentReady - Function to signal that content is ready
 */
interface ExportDialogContentProps {
    filename: string;
    bloxes: AllBloxes[];
    initialIndex?: number;
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[];
    closeDialog: () => void;
    onContentReady: () => void;
}

const formatStepIndex = (index: number) => {
    return index.toString().padStart(3, '0');
};

// Move styles outside component
const previewStyles = `
    .bloxSVGPreview text {
        font-size: 5px;
        fill: var(--text-dark);
        font-family: Arial, sans-serif;
    }
    .hide-text text {
        display: none;
    }
`;

/**
 * Content component for the export dialog
 * @component
 * @param {ExportDialogContentProps} props - Component props
 */
export const ExportDialogContent: React.FC<ExportDialogContentProps> = ({
    filename,
    bloxes,
    initialIndex = 0,
    processSections,
    closeDialog,
    onContentReady
}) => {
    const svgRef = useRef<HTMLDivElement>(null);
    const [processName] = useFabuState('processName');
    const { 
        state: { fileFormat }, 
        setFileFormat,
    } = useExportDialogState();
    
    // Local state
    const [resolution, setResolution] = useState<"Normal" | "High">("Normal");
    const [speedMultiplier, setSpeedMultiplier] = useState(1);
    // For PNG/SVG: Controls whether to show text labels in the SVG
    // For GIF/WEBM: Always hidden (enforced in PreviewDisplay)
    const [showText, setShowText] = useState(true);
    // Step info is now always shown at the top for GIF/WEBM
    // This state is kept for backward compatibility
    const [showStepInfo, setShowStepInfo] = useState(true);

    const handleFormatChange = (format: "PNG" | "SVG" | "GIF" | "WEBM") => {
        setFileFormat(format);
    };

    const handleResolutionChange = (resolution: "Normal" | "High") => {
        setResolution(resolution);
    };

    const handleToggleShowText = () => {
        // This only affects PNG/SVG exports, GIF/WEBM will always hide labels
        setShowText(!showText);
    };

    const handleToggleStepInfo = () => {
        // This function is kept for backward compatibility
        // Step info is now always shown at the top for GIF/WEBM
        setShowStepInfo(!showStepInfo);
    };

    const [allSvgs, setAllSvgs] = useState<JSX.Element[]>([]);
    const { generateSvgs } = useContext(SvgServiceContext);

    const { currentIndex, handleNext, handlePrevious, handleKeyPress } = useStepNavigation({
        bloxes,
        initialIndex
    });

    const getCurrentBloxInfo = (bloxId: string, bloxName?: string) => {
        let sectionName = '';
        const currentBlox = bloxes.find(b => b.id === bloxId);
        
        if (processSections) {
            for (const section of processSections) {
                if (section.bloxIds.includes(bloxId)) {
                    sectionName = section.sectionName || '';
                    break;
                }
            }
        }
        return {
            name: bloxName || '',
            sectionName,
            toolName: currentBlox?.toolName || ''
        };
    };

    // Memoize current blox info to avoid redundant calculations
    const currentBloxInfo = useMemo(() => {
        const currentBlox = bloxes[currentIndex];
        return getCurrentBloxInfo(currentBlox?.id || '', currentBlox?.name);
    }, [bloxes, currentIndex, getCurrentBloxInfo]);

    const getExportFilename = useCallback((format: string) => {
        const effectiveProcessName = processName || "Unnamed Process";
        if (format === "PNG" || format === "SVG") {
            const stepIndex = formatStepIndex(currentIndex);
            const bloxName = getCurrentBloxInfo(bloxes[currentIndex]?.id || '', bloxes[currentIndex]?.name).name;
            return `${stepIndex}-${bloxName}-${effectiveProcessName}`;
        } else if (format === "WEBM" || format === "GIF") {
            return effectiveProcessName;
        }
        return effectiveProcessName;
    }, [processName, currentIndex, bloxes, getCurrentBloxInfo]);

    const currentFilename = useMemo(() => getExportFilename(fileFormat), 
        [getExportFilename, fileFormat]
    );

    const { generatePNG, generateSVG, SaveMode } = useImageExport({
        resolution,
        filename: currentFilename
    });

    const { handleExportGif, handleExportMp4: handleExportWebM, isExporting: isStackExporting } = useStackExport(
        bloxes, 
        currentIndex, 
        currentFilename,
        speedMultiplier,
        processSections, 
        showStepInfo,
        showText
    );

    // Generate SVGs when bloxes change
    useEffect(() => {
        if (bloxes.length > 0) {
            const svgs = generateSvgs(bloxes, false, SVGDisplayMode.ExportPreview);
            setAllSvgs(svgs);
            onContentReady();
        }
        
        // Clear SVGs when component unmounts
        return () => setAllSvgs([]);
    }, [bloxes, generateSvgs, onContentReady]);

    // Add keyboard navigation when dialog is open
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    // Helper function to prepare SVG for export
    const prepareSvgForExport = useCallback((): { svgClone: SVGElement, source: string } | null => {
        const svg = svgRef.current?.querySelector(".bloxSVGPreview");
        if (!svg) return null;

        const svgClone = svg.cloneNode(true) as SVGElement;
        svgClone.setAttribute('preserveAspectRatio', 'xMidYMax meet');
        svgClone.setAttribute('width', '320');
        svgClone.setAttribute('height', '320');
        
        if (!showText) {
            const texts = svgClone.getElementsByTagName('text');
            Array.from(texts).forEach(text => text.remove());
        }
        
        const serializer = new XMLSerializer();
        const source = serializer.serializeToString(svgClone);

        return { svgClone, source };
    }, [showText]);

    // Shared export logic
    const handleExport = useCallback((mode: SaveMode) => {
        const prepared = prepareSvgForExport();
        if (!prepared) return;

        const { svgClone, source } = prepared;
        if (fileFormat === "PNG") {
            generatePNG(svgClone, source, mode);
        } else {
            generateSVG(source, mode, showText);
        }
    }, [fileFormat, generatePNG, generateSVG, showText, prepareSvgForExport, SaveMode]);

    // Update handleCopy to use shared logic
    const handleCopy = useCallback(() => {
        handleExport(SaveMode.CopyToClipboard);
    }, [handleExport, SaveMode]);

    // Update handleDownload to use shared logic
    const handleDownload = useCallback(() => {
        handleExport(SaveMode.DownloadFile);
    }, [handleExport, SaveMode]);

    // Get all materials for the process
    const materials = useMemo(() => 
        getMaterials(bloxes, bloxes.length - 1), 
        [bloxes]  // Only recompute when bloxes change, not on currentIndex changes
    );

    return (
        <>
            <style>{previewStyles}</style>
            <div ref={svgRef}>
                <div 
                    className={Classes.DIALOG_BODY} 
                    style={{ 
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                    }} 
                >
                    {/* Title Section */}
                    <div style={{ 
                        width: '100%', 
                        textAlign: 'center',
                        color: 'var(--text-primary)',
                        fontFamily: 'var(--font-primary)',
                        padding: '14px',
                        // backgroundColor: '#F8F9FE',
                        borderRadius: '8px',
                        marginBottom: '4px'
                    }}>
                        {currentBloxInfo.sectionName && (
                            <div style={{ 
                                fontSize: 'var(--font-size-base)', 
                                color: 'var(--text-secondary)',
                                fontWeight: 'var(--font-weight-regular)',
                                fontFamily: 'var(--font-primary)',
                                marginBottom: '4px'
                            }}>
                                Section: {currentBloxInfo.sectionName}
                            </div>
                        )}
                        <div style={{ fontSize: 'var(--font-size-lg)', fontWeight: 'var(--font-weight-semibold)', marginBottom: '6px' }}>
                            {currentIndex === 0 ? 
                                'Substrate Stack' :
                                `Step ${currentIndex}: ${currentBloxInfo.name}`
                            }
                        </div>
                        {currentBloxInfo.toolName && currentIndex > 0 && (
                            <div style={{ 
                                fontSize: 'var(--font-size-base)', 
                                color: 'var(--black)',
                                fontWeight: 'var(--font-weight-regular)',
                                fontFamily: 'var(--font-primary)',
                                marginTop: '2px'
                            }}>
                                Tool: {currentBloxInfo.toolName}
                            </div>
                        )}
                    </div>

                    {/* Format Controls */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                    }}>
                        <ExportFormatControls
                            onFormatChange={handleFormatChange}
                            onResolutionChange={handleResolutionChange}
                            onToggleShowText={handleToggleShowText}
                            onToggleStepInfo={handleToggleStepInfo}
                            resolution={resolution}
                            showText={showText}
                            showStepInfo={showStepInfo}
                            speedMultiplier={speedMultiplier}
                            onSpeedMultiplierChange={setSpeedMultiplier}
                        />
                    </div>

                    {/* Preview Section */}
                    <div style={{
                        height: '360px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        overflow: 'hidden',
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        padding: '20px',
                        paddingBottom: 0
                    }}>
                        <PreviewDisplay
                            allSvgs={allSvgs}
                            currentIndex={currentIndex}
                            bloxes={bloxes}
                            getCurrentBloxInfo={getCurrentBloxInfo}
                            showText={showText}
                            showStepInfo={showStepInfo}
                            materials={materials}
                        />
                    </div>

                    {/* Navigation Section */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <StepNavigation
                            currentIndex={currentIndex}
                            totalSteps={bloxes.length}
                            onNext={handleNext}
                            onPrevious={handlePrevious}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '12px',
                        marginTop: '8px'
                    }}>
                        <ExportActions
                            onCopy={handleCopy}
                            onDownload={handleDownload}
                            onExportGif={handleExportGif}
                            onExportWebM={handleExportWebM}
                            isExporting={isStackExporting}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}; 