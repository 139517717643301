import React, { useState } from 'react';
import { Dialog, Classes, Button, Switch, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { Section } from '../__generated__/Process';
import { exportProcessBloxesToExcel } from '../utils/export-excel';

interface ExcelExportDialogProps {
    isOpen: boolean;
    onClose: () => void;
    processName: string;
    processBloxes: AllBloxes[];
    processSections: Section[];
}

export const ExcelExportDialog: React.FC<ExcelExportDialogProps> = ({
    isOpen,
    onClose,
    processName,
    processBloxes,
    processSections,
}) => {
    const [splitWorksheet, setSplitWorksheet] = useState(false);
    const [showAllParameters, setShowAllParameters] = useState(false);

    const handleExport = () => {
        const effectiveProcessName = processName || "Unnamed Process";
        exportProcessBloxesToExcel(
            effectiveProcessName,
            processBloxes,
            processSections,
            splitWorksheet,
            showAllParameters
        );
        onClose();
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Excel Export Settings"
            icon={IconNames.DOCUMENT}
            style={{
                width: '400px',
                borderRadius: '12px',
                overflow: 'hidden'
            }}
        >
            <div className={Classes.DIALOG_BODY} style={{ padding: '20px' }}>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '16px'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px',
                        backgroundColor: 'var(--background-lighter)',
                        borderRadius: '8px'
                    }}>
                        <span>Split into Multiple Worksheets</span>
                        <Switch
                            checked={splitWorksheet}
                            onChange={() => setSplitWorksheet(!splitWorksheet)}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px',
                        backgroundColor: 'var(--background-lighter)',
                        borderRadius: '8px'
                    }}>
                        <span>Show All Parameters</span>
                        <Switch
                            checked={showAllParameters}
                            onChange={() => setShowAllParameters(!showAllParameters)}
                            style={{ margin: 0 }}
                        />
                    </div>
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        onClick={onClose}
                        style={{ 
                            borderRadius: '6px',
                            backgroundColor: 'var(--background-lighter)'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        intent={Intent.SUCCESS}
                        icon={IconNames.DOWNLOAD}
                        onClick={handleExport}
                        style={{ 
                            borderRadius: '6px'
                        }}
                    >
                        Export Excel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}; 