import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    Tooltip
} from '@blueprintjs/core';
import { useState, isValidElement, useEffect } from 'react';
import { NumericInput } from './NumericInput';
import { StateAndSetter } from '../../hooks/state/parameter-context';
import { IconNames } from '@blueprintjs/icons';

export interface LayerSelectivity {
    layer: string;
    selectivity: number | null;
}

export interface SelectivityRateInputProps {
    id: string;
    value: LayerSelectivity[];
    onChange: (value: LayerSelectivity[]) => void;
    selectedMaterials: string[];
    inputValueDictState: StateAndSetter<{ [key: string]: string }>
    infoContent?: string | JSX.Element;
}

export const SelectivityRateInput: React.FC<SelectivityRateInputProps> = ({
    id,
    value,
    onChange,
    infoContent,
    selectedMaterials,
    inputValueDictState
}) => {
    
    useEffect(() => {
        const isOutOfSync = selectedMaterials.some(
            material => !value.find(layer => layer.layer === material)
        ) || value.some(
            layer => !selectedMaterials.includes(layer.layer)
        );
    
        if (isOutOfSync) {
            const newValue = selectedMaterials.map(material => {
                const existingLayer = value.find(layer => layer.layer === material);
                return existingLayer
                    ? existingLayer
                    : { layer: material, selectivity: 1};
            });
            onChange(newValue);
        }
    }, [selectedMaterials, value, onChange]);

    const handleInputChange = (material: string, selectivity: string) => {
        const updatedValue = selectivity === "" ? null : parseFloat(selectivity);
        const updatedLayers = value.map(layer => 
            layer.layer === material ? { ...layer, selectivity: updatedValue } : layer
        );

        if (!value.some(layer => layer.layer === material)) {
            updatedLayers.push({ layer: material, selectivity: updatedValue });
        }
        onChange(updatedLayers);
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(prev => !prev);

    let infoRender: JSX.Element = <></>;
    if (typeof infoContent === 'string') {
        infoRender = (
            <Tooltip content={<div style={{ width: '400px', whiteSpace: 'normal' }}>{infoContent}</div>}>
                <Button minimal icon={IconNames.INFO_SIGN} className={Classes.INPUT_ACTION} />
            </Tooltip>
        );
    } else if (isValidElement(infoContent)) {
        infoRender = (
            <>
                <Button
                    minimal
                    icon={IconNames.InfoSign}
                    className={Classes.INPUT_ACTION}
                    onClick={(e) => {
                        e.stopPropagation();  // This stops the click event from propagating to parent elements
                        toggleDialog();
                    }}
                />
                <Dialog
                    isOpen={isDialogOpen}
                    onClose={toggleDialog}
                    title="Information"
                >
                    {infoContent}
                </Dialog>
            </>
        );
    }

    const labelWithInfoContent = (label: string) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <span>{label}</span>
            {infoRender}
        </div>
    );

    return (
            <FormGroup
                label={labelWithInfoContent('Etch Selectivity')}
            >
                {selectedMaterials.map((material, index) => (
                    <FormGroup
                        style={{ justifyContent: 'right', alignItems: 'center', height: '100%' }}
                        key={index}
                        inline
                        label={`${material}:`}
                    >
                        <div style={{ marginLeft: 'auto', width: '100px' }}>
                            <NumericInput
                                id={`${id}-${material}`}
                                onChange={(value) => handleInputChange(material, value)}
                                inputValueDictState={inputValueDictState}
                                inputGroupProps={{
                                    style: {textAlign: 'right'},
                                    value: '1'
                                }}
                            />
                        </div>

                    </FormGroup>
                ))}
            </FormGroup>
            

    );
};



