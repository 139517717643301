/**
 * @fileoverview Main layout component for the IFrame application.
 * This component provides the structural foundation for the embedded application,
 * organizing the UI into distinct regions including header, process view, templates,
 * welcome box, chatbot, and settings panel. It handles collapsible sections and
 * manages the overall spatial arrangement of components within the iframe container.
 */

import React from 'react';
import { Box, Paper, Tooltip, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * Props for IFrameLayout component
 */
interface IFrameLayoutProps {
  iframeStyles: any;
  contentContainerStyle: any;
  children: React.ReactNode;
  header: React.ReactNode;
  chatbot: React.ReactNode;
  settingsPanel: React.ReactNode;
  templates: React.ReactNode;
  welcomeBox?: React.ReactNode; // New prop for welcome box
  hasTemplatesVisible?: boolean;
  hasWelcomeBoxVisible?: boolean; // New prop for welcome box visibility
  processViewCollapsed?: boolean; // New prop for process view collapse state
  onToggleProcessViewCollapsed?: () => void; // New prop for toggling process view collapse
}

/**
 * Component for the main layout structure of the iframe viewer
 * Organizes the UI into distinct regions: welcome box, templates, process view, chatbot, and settings
 */
export function IFrameLayout({
  iframeStyles,
  contentContainerStyle,
  children,
  header,
  chatbot,
  settingsPanel,
  templates,
  welcomeBox,
  hasTemplatesVisible = false,
  hasWelcomeBoxVisible = false,
  processViewCollapsed = false,
  onToggleProcessViewCollapsed
}: IFrameLayoutProps) {
  return (
    <Box sx={{
      ...iframeStyles.outerContainer,
      height: 'auto',
      minHeight: '100vh',
      overflowY: 'auto',
      paddingBottom: '40px', // Add extra padding at the bottom
    }}>
      <Box sx={{
        ...contentContainerStyle,
        height: 'auto',
        minHeight: '100px',
        overflow: 'visible',
      }}>
        {/* Welcome Box - displayed at the top */}
        {welcomeBox && hasWelcomeBoxVisible && (
          <Box sx={{ marginBottom: '16px' }}>
            {welcomeBox}
          </Box>
        )}
        
        {/* Templates section - displayed below welcome box */}
        {templates && hasTemplatesVisible && (
          <Box
            sx={{
              marginBottom: '16px',
            }}
          >
            {templates}
          </Box>
        )}
        
        {/* Main process view */}
        <Paper 
          elevation={3} 
          sx={{
            ...iframeStyles.frameContainer,
            // Adjust height based on collapsed state
            height: processViewCollapsed ? 'auto' : 'auto',
            maxHeight: processViewCollapsed ? '60px' : 'calc(100vh - 100px)',
            transition: 'height 0.3s ease-in-out, max-height 0.3s ease-in-out',
          }}
        >
          {/* Header container with collapse toggle */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            borderBottom: processViewCollapsed ? 'none' : '1px solid #e0e0e0'
          }}>
            {/* Original header */}
            <Box sx={{ flexGrow: 1 }}>
              {header}
            </Box>
            
            {/* Collapse/Expand toggle button */}
            {onToggleProcessViewCollapsed && (
              <Tooltip title={processViewCollapsed ? "Expand process view" : "Collapse process view"}>
                <IconButton 
                  size="small" 
                  onClick={onToggleProcessViewCollapsed}
                  sx={{ 
                    marginRight: '8px',
                    padding: '4px',
                  }}
                >
                  {processViewCollapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          
          {/* Only render process content if not collapsed */}
          {!processViewCollapsed && (
            <Box sx={{
              height: 'auto',
              maxHeight: 'calc(100vh - 160px)',
              overflow: 'auto',
              transition: 'height 0.3s ease-in-out'
            }}>
              <Box sx={{
                ...iframeStyles.processContainer,
                height: 'auto',
                minHeight: '200px',
              }}>
                {children}
              </Box>
            </Box>
          )}
        </Paper>
      </Box>

      {/* Chatbot */}
      {chatbot}

      {/* Settings Panel */}
      {settingsPanel}
    </Box>
  );
}

export default IFrameLayout; 