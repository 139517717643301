/**
 * @fileoverview PreviewDisplay component for rendering SVG previews with step information and material legend.
 * This component is used within the export dialog to display process visualizations with configurable
 * text labels and a dynamic material legend. It supports different file formats (PNG, SVG, GIF, WEBM)
 * and handles both single-step and animated previews.
 */

import React from 'react';
import { useExportDialogState } from '../hooks/state/export-dialog-provider';
import { AllBloxes } from '../../../Data/BloxSchema/base-blox';
import { Material } from '../../../hooks/material-targets';

/**
 * Constant dimensions for the preview display components.
 * All measurements are in pixels.
 */
const PREVIEW_DIMENSIONS = {
    container: {
        width: 640,
        height: 360
    },
    svg: {
        width: 320,
        height: 320
    },
    legend: {
        width: 140,
        colorBox: 16
    }
} as const;

/**
 * Common styles used throughout the component.
 * Includes layout and typography styles.
 */
const STYLES = {
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    text: {
        title: {
            fontSize: 'var(--font-size-small)',
            fontWeight: 'bold',
            color: 'var(--text-dark)'
        },
        subtitle: {
            fontSize: 'var(--font-size-xs)',
            color: 'var(--text-secondary)'
        }
    }
} as const;

/**
 * Props for the PreviewDisplay component
 * @interface PreviewDisplayProps
 * @property {JSX.Element[]} allSvgs - Array of SVG elements to display
 * @property {number} currentIndex - Current step index being displayed
 * @property {AllBloxes[]} bloxes - Array of bloxes for step information
 * @property {(bloxId: string, bloxName?: string) => { name: string, sectionName: string, toolName: string }} getCurrentBloxInfo - Function to get blox info for current step
 * @property {boolean} showText - Whether to show text in the preview
 * @property {boolean} showStepInfo - Whether to show step information
 * @property {Material[]} materials - Array of materials for the legend, displayed from bottom to top
 */
interface PreviewDisplayProps {
    allSvgs: JSX.Element[];
    currentIndex: number;
    bloxes: AllBloxes[];
    getCurrentBloxInfo: (bloxId: string, bloxName?: string) => { name: string, sectionName: string, toolName: string };
    showText: boolean;
    showStepInfo: boolean;
    materials: Material[];
}

/**
 * Component for displaying SVG preview with step information and material legend.
 * Renders a preview of the current process step with optional text labels and a
 * dynamic material legend. Supports different file formats and handles both
 * single-step and animated previews.
 * 
 * @component
 * @param {PreviewDisplayProps} props - Component props
 * @returns {JSX.Element | null} The rendered preview display or null if no SVGs are available
 */
export const PreviewDisplay: React.FC<PreviewDisplayProps> = ({
    allSvgs,
    currentIndex,
    bloxes,
    getCurrentBloxInfo,
    showText,
    showStepInfo,
    materials
}) => {
    const { state: { fileFormat } } = useExportDialogState();

    if (!allSvgs.length || currentIndex >= allSvgs.length) {
        return null;
    }

    const svg = allSvgs[currentIndex];
    const currentBlox = bloxes[currentIndex];
    const bloxInfo = getCurrentBloxInfo(currentBlox?.id || '', currentBlox?.name);
    const reversedMaterials = [...materials].reverse();

    return (
        <div 
            onContextMenu={(e) => e.preventDefault()}
            style={{ 
                position: 'relative',
                width: `${PREVIEW_DIMENSIONS.container.width}px`,
                height: `${PREVIEW_DIMENSIONS.container.height}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#DEE2FB',
                borderRadius: '12px',
                padding: '24px',
                userSelect: 'none'
            }}>
            {/* Main SVG Container */}
            <div style={{ 
                position: 'relative',
                width: `${PREVIEW_DIMENSIONS.svg.width}px`,
                height: `${PREVIEW_DIMENSIONS.svg.height}px`,
                backgroundColor: 'var(--accent-purple-light)',
                pointerEvents: 'none'
            }}>
                {/* SVG Element - Positioned first in the DOM to ensure it's always in the same place */}
                {React.cloneElement(svg, {
                    className: `bloxSVGPreview ${
                      // Always hide text for GIF/WEBM formats, respect showText setting only for PNG/SVG
                      (fileFormat === "GIF" || fileFormat === "WEBM") ? 'hide-text' : 
                      (showText ? 'show-text' : 'hide-text')
                    }`,
                    preserveAspectRatio: "xMidYMax meet",
                    style: {
                        width: `${PREVIEW_DIMENSIONS.svg.width}px`,
                        height: `${PREVIEW_DIMENSIONS.svg.height}px`,
                        objectFit: 'contain'
                    }
                })}
                
                {/* 
                 * Step Info Overlay - COMMENTED OUT to avoid redundancy
                 * This step info is now hidden for GIF/WEBM formats because it's redundant with 
                 * the title information already shown at the top of the export dialog.
                 * 
                {(fileFormat === "GIF" || fileFormat === "WEBM") && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        padding: '12px',
                        background: 'rgba(222, 226, 251, 0.8)',
                        zIndex: 2
                    }}>
                        {bloxInfo.sectionName && (
                            <div style={{
                                ...STYLES.text.subtitle,
                                marginBottom: '3px'
                            }}>
                                Section: {bloxInfo.sectionName}
                            </div>
                        )}
                        <div style={{ ...STYLES.text.title, marginBottom: '2px' }}>
                            {currentIndex === 0 ? 
                                'Substrate Stack' :
                                `Step ${currentIndex}: ${bloxInfo.name}`
                            }
                        </div>
                        {bloxInfo.toolName && currentIndex > 0 && (
                            <div style={STYLES.text.subtitle}>
                                {bloxInfo.toolName}
                            </div>
                        )}
                    </div>
                )}
                */}
            </div>

            {/* Legend */}
            <div style={{
                position: 'absolute',
                top: '8px',
                right: '10px',
                width: `${PREVIEW_DIMENSIONS.legend.width}px`,
                backgroundColor: 'var(--accent-purple-light)',
                borderRadius: '8px',
                padding: '8px',
                ...STYLES.flexColumn,
                maxHeight: 'calc(100% - 40px)',
                boxShadow: 'rgba(0, 0, 0, 0.4) 1px 2px 3px'
            }}>
                <div style={{
                    ...STYLES.flexColumn,
                    gap: '4px',
                    overflowY: 'auto',
                    paddingRight: '4px'
                }}>
                    {reversedMaterials.map((material) => (
                        <div key={material.materialId} style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}>
                            <div style={{
                                width: `${PREVIEW_DIMENSIONS.legend.colorBox}px`,
                                height: `${PREVIEW_DIMENSIONS.legend.colorBox}px`,
                                backgroundColor: material.color ? `rgba(${material.color.R}, ${material.color.G}, ${material.color.B}, ${material.color.A})` : '#CCCCCC',
                                border: '1px solid rgba(0,0,0,0.1)',
                                borderRadius: '2px',
                                flexShrink: 0
                            }} />
                            <div style={{
                                ...STYLES.text.subtitle,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                                wordBreak: 'break-word'
                            }}>
                                {material.materialLabel}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}; 