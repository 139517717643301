import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { useProcessHandlers } from "./use-process-handlers";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { scrollToLastSectionEnd } from "../../utils/scrollUtils";

/**
 * Hook for managing blox-related actions like adding blox and switching views.
 * Encapsulates the logic for double-click handling and view mode transitions.
 */
export const useBloxActions = () => {
    const { processHandleDrop } = useProcessHandlers();
    const [processBloxes,] = useFabuState('processBloxes');
    const [, setScrollToSelectedBlox] = useFabuState('scrollToSelectedBlox');
    const location = useLocation();
    const history = useHistory();

    const handleAddBloxWithScroll = useCallback((bloxType: BloxTypes, data?: AllBloxes) => {
        // Add the blox
        processHandleDrop({bloxType, id: undefined, data}, processBloxes.length - 1);
        
        // Wait for DOM update and section expansion
        setTimeout(() => {
            scrollToLastSectionEnd(setScrollToSelectedBlox);
        }, 50);
    }, [processHandleDrop, processBloxes, setScrollToSelectedBlox]);

    const switchToStandardView = useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('view');
        history.replace({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    }, [location, history]);

    const handleDoubleClick = useCallback((bloxType: BloxTypes, data?: AllBloxes) => {
        // Get current view mode
        const queryParams = new URLSearchParams(location.search);
        const viewMode = queryParams.get('view');
        const isListView = viewMode === 'list';
        const isTableView = viewMode === 'table';

        // If in list or table view, switch to standard view first
        if (isListView || isTableView) {
            switchToStandardView();

            // Wait for view transition, then add blox and scroll
            requestAnimationFrame(() => {
                handleAddBloxWithScroll(bloxType, data);
            });
        } else {
            // Already in standard view, just add blox and scroll
            handleAddBloxWithScroll(bloxType, data);
        }
    }, [switchToStandardView, handleAddBloxWithScroll]);

    return {
        handleDoubleClick
    };
}; 