import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { IColor } from "../../utils/Color";
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { getDefaultColor, materialFieldMap } from "../material-mappings";

export interface WaferBonding extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    bondingAgentMaterial: string | null;
    bondingAgentMaterialDisabled: () => boolean | null;
    bondingAgentThickness: number | null;
    bondingAgentThicknessUnit: Units | null;
    bondingAgentThicknessDisabled: () => boolean | null;
    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    bondingLayerThickness: number | null;
    bondingLayerThicknessDisabled: () => boolean | null;
    layerLabel: string | null;
    useBondingAgent: boolean | null;
    liquidBond: boolean | null;
    liquidBondDisabled: () => boolean | null;
    bondingAgentColor: IColor | null;
    bondingAgentColorDisabled: () => boolean | null;
    bondingAgentLabel: string | null;
    bondingAgentLabelDisabled: () => boolean | null;
    // SEMIFAB
    drawnThickness: number | null;
    drawnThicknessUnit: Units | null;
}

export const WaferBondingDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Wafer Material",
        placeholder: "Enter wafer material",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Wafer thickness",
        placeholder: "Enter wafer thickness",
        isNumber: true,
        units: [Units.NM,Units.MICRON,Units.MM,Units.CM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    bondingAgentMaterial: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Bonding Material",
        placeholder: "Enter bonding material",
        order: 6,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    bondingAgentThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Bonding agent thickness",
        placeholder: "Enter bonding agent thickness",
        isNumber: true,
        units: [Units.NM,Units.MICRON,Units.MM,Units.CM],
        order: 7,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    // DISPLAY
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Wafer Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Wafer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
    useBondingAgent: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Insert Bonding Agent",
        order: 5,
        tabs: [Tab.EXPERIMENTAL, Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "Insert a bonding agent between the substrate and the bonded wafer",
    },
    liquidBond: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Liquid Bond",
        order: 6,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "Selecting a liquid bonding agent will fill all gaps on a patterned surface to bond to. Otherwise, a planar layer between the bonding wafer and the bonding surface.",
    },
    bondingAgentLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Bonding Agent Label",
        order: 6,
        tabs: [Tab.DISPLAY],
    },
    bondingLayerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Bonding Layer Thickness",
        order: 7,
        maxSliderValue: 10,
        tabs: [Tab.DISPLAY],
    },
    // SEMIFAB
    drawnThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Simulated Wafer thickness",
        placeholder: "Enter wafer thickness",
        isNumber: true,
        units: [Units.NM,Units.MICRON,Units.MM,Units.CM],
        order: 2,
        tabs: [Tab.SEMIFAB],
        infoContent: "Simulating the full thickness can lead to unusual aspect ratios.\n Choose the smallest value that still portrays the intended effect of the wafer bond."
    },
}

export const getWaferBonding = ({stepNumber}: BloxArgs): WaferBonding => ({
        name: `Wafer-Bonding ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.WaferBonding,
        material: null,
        thickness: null,
        thicknessUnit: Units.MICRON,
        bondingAgentMaterial: materialFieldMap["WAFERBONDING-AGENT"]?.unknownLabel ?? null,
        bondingAgentMaterialDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        bondingAgentThickness: 0,
        bondingAgentThicknessUnit: Units.NM,
        bondingAgentThicknessDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        commentField: null,
        customFields: {},
        layerColor: getDefaultColor(BloxTypes.WaferBonding),
        layerThickness: 12,
        layerLabel: null,
        useBondingAgent: false,
        liquidBond: true,
        liquidBondDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        bondingLayerThickness: 4,
        bondingLayerThicknessDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        bondingAgentColor: getDefaultColor("WAFERBONDING-AGENT"),
        bondingAgentColorDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        bondingAgentLabel: null,
        bondingAgentLabelDisabled: function () {
            return !this.useBondingAgent ?? false;
        },
        drawnThickness: 2,
        drawnThicknessUnit: Units.MICRON,
});
