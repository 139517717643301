/**
 * @fileoverview Provides a type-safe context system for managing and sharing parameters
 * throughout a React component tree. This system enables strongly-typed state management
 * with automatic type inference for parameters and their setters.
 */
import React from "react";

/**
 * Represents a tuple containing a state value and its corresponding setter function.
 * This type follows React's useState pattern for consistent state management.
 * 
 * @template T The type of the state value
 */
export type StateAndSetter<T> = [T, React.Dispatch<React.SetStateAction<T>>];

/**
 * A registry that maps parameter names to their corresponding state-setter pairs.
 * Provides type safety for parameter management by ensuring each parameter maintains
 * its correct type throughout the application.
 * 
 * @template T An object type where each key represents a parameter name and its value represents the parameter type
 */
export type ParameterProviderRegistry<T=any> = {
    [K in keyof T]: StateAndSetter<T[K]>
};

/**
 * Utility type that extracts the state type from a StateAndSetter tuple.
 * Used internally to infer the correct types when accessing parameters.
 * 
 * @template T The StateAndSetter tuple type to extract from
 */
export type ExtractParameterType<T> = T extends StateAndSetter<infer U> ? U : never;

/**
 * Utility type that transforms a registry type into an object type containing
 * only the parameter values (without their setters).
 * 
 * @template T The ParameterProviderRegistry type to extract from
 */
export type ExtractParametersType<T> = {
    [K in keyof T]: ExtractParameterType<T[K]>
};

/**
 * Defines the shape of the parameter context, containing a registry of all
 * available parameters and their setters.
 * 
 * @template T The type defining the structure of the parameters
 * @property {ParameterProviderRegistry<T>} providers A map of parameter names to their state-setter pairs
 */
export interface ParameterContextType<T=any> {
    /**
     * A map of named parameter [state, setter] pairs to set as the parameter context.
     * Each entry consists of a parameter name mapped to its state and setter function.
     */
    providers: ParameterProviderRegistry<T>;
}

/**
 * React Context instance for the parameter system. This context allows components
 * to access and modify parameters from anywhere in the component tree.
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ParameterContext = React.createContext<ParameterContextType>(null!);

/**
 * Props type for the ParameterContextProvider component.
 * @template T The type defining the structure of the parameters
 */
export type ParameterContextProvierParams<T=any> = ParameterContextType<T>

/**
 * A Provider component that makes parameters available to all child components
 * in the React component tree. This component enables centralized parameter
 * management with type safety.
 * 
 * @template T The type defining the structure of the parameters
 * @param {Object} props The component props
 * @param {ParameterProviderRegistry<T>} props.providers The parameter registry containing all parameter state-setter pairs
 * @param {React.ReactNode} props.children The child components that will have access to the parameters
 */
export function ParameterContextProvider<T=any>({
    providers, 
    children
}: React.PropsWithChildren<ParameterContextProvierParams<T>>) {
    return <ParameterContext.Provider value={{providers}}>{children}</ParameterContext.Provider>;
}
