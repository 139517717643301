import React from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

/**
 * Props for the StepNavigation component
 * @interface StepNavigationProps
 * @property {number} currentIndex - Current step index
 * @property {number} totalSteps - Total number of steps
 * @property {() => void} onNext - Handler for next step
 * @property {() => void} onPrevious - Handler for previous step
 */
interface StepNavigationProps {
    currentIndex: number;
    totalSteps: number;
    onNext: () => void;
    onPrevious: () => void;
}

/**
 * Component for step-by-step navigation controls
 * @component
 * @param {StepNavigationProps} props - Component props
 */
export const StepNavigation: React.FC<StepNavigationProps> = ({
    currentIndex,
    totalSteps,
    onNext,
    onPrevious
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <Button
                    icon={IconNames.CHEVRON_LEFT}
                    onClick={onPrevious}
                    disabled={currentIndex === 0}
                    style={{ 
                        margin: '0 8px',
                        borderRadius: '6px',
                        minWidth: '32px',
                        backgroundColor: currentIndex === 0 ? undefined : 'var(--background-lighter)'
                    }}
                />
                <span style={{ 
                    margin: '0 12px', 
                    fontSize: 'var(--font-size-base)', 
                    color: 'var(--text-primary)',
                    fontFamily: 'var(--font-mono)',
                    fontWeight: 'var(--font-weight-medium)'
                }}>
                    {currentIndex === 0 ? 
                        'Substrate Stack' :
                        `Step ${currentIndex} of ${totalSteps - 1}`
                    }
                </span>
                <Button
                    icon={IconNames.CHEVRON_RIGHT}
                    onClick={onNext}
                    disabled={currentIndex === totalSteps - 1}
                    style={{ 
                        margin: '0 8px',
                        borderRadius: '6px',
                        minWidth: '32px',
                        backgroundColor: currentIndex === totalSteps - 1 ? undefined : 'var(--background-lighter)'
                    }}
                />
            </div>
            <div style={{ 
                fontSize: 'var(--font-size-xs)', 
                color: 'var(--text-secondary)',
                fontFamily: 'var(--font-primary)',
                fontWeight: 'var(--font-weight-regular)',
                marginBottom: '4px'
            }}>
                Pro tip: Try your arrow keys to navigate.
            </div>
        </div>
    );
}; 