/**
 * @fileoverview Process initialization component that handles loading process data.
 * This component is responsible for fetching process data from the API, managing
 * authentication state, handling loading states, and initializing the process context
 * with the retrieved data. It acts as a data fetching layer that coordinates between
 * external process IDs (from messages or URLs) and the application state.
 */

import React, { useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import { logProcessStructure } from './IFrameProcessUtils';
import { notifyProcessLoaded, notifyError } from '../../utils/iframeMessaging';
import { useIFrameProcess } from './IFrameProcessContext';
import { useIFrameUI } from './IFrameUIContext';
import { useIFrameProcessInitialization } from './hooks/useIFrameProcessInitialization';
import { validateViewMode } from './IFrameProcessUtils';
import { ViewMode } from './hooks/useFabuUIState';

export interface IFrameProcessInitializerProps {
  onInitializationComplete: (processData?: {
    processId: string,
    processName: string,
    processBloxes: any[],
    processSections: any[],
    firstBloxId?: string,
    isReloading?: boolean,
    error?: string
  }) => void;
  signature?: string | null;
  children: React.ReactNode;
  // Process ID to load - this is the effective ID (single source of truth)
  messageProcessId?: string;
  // View mode from parent component
  viewMode?: ViewMode;
}

/**
 * Component that handles the loading of process data from the API
 */
export function IFrameProcessInitializer({
  onInitializationComplete,
  signature,
  children,
  messageProcessId,
  viewMode
}: IFrameProcessInitializerProps) {
  // Reference to the process ID we've last synced with
  const lastSyncedProcessIdRef = useRef<string | null>(null);
  // Reference to the view mode we've last synced with
  const lastSyncedViewModeRef = useRef<ViewMode | null>(null);
  
  // Get context state and actions
  const { state: processState, actions: processActions } = useIFrameProcess();
  const { state: uiState, actions: uiActions } = useIFrameUI();
  
  // Use the process initialization hook
  const { 
    loadProcess, 
    isLoading, 
    error
  } = useIFrameProcessInitialization({
    onInitializationComplete
  });
  
  // Effect to sync view mode from parent component
  useEffect(() => {
    // Skip if no view mode provided or if it's the same as what we already synced
    if (!viewMode || viewMode === lastSyncedViewModeRef.current) {
      return;
    }
    
    const validatedMode = validateViewMode(viewMode);
    
    // Only update if the validated mode actually differs from current state
    if (validatedMode !== uiState.viewMode) {
      // Update our reference before updating context to prevent loops
      lastSyncedViewModeRef.current = viewMode;
      
      // Update the context
      uiActions.setViewMode(validatedMode);
    } else {
      // Still update our reference even if no change needed
      lastSyncedViewModeRef.current = viewMode;
    }
  }, [viewMode, uiActions, uiState.viewMode]);

  // Effect to load the process when messageProcessId changes
  useEffect(() => {
    // Skip if no message process ID or if it's the ID we already loaded
    if (!messageProcessId || messageProcessId === lastSyncedProcessIdRef.current) {
      return;
    }
    
    // Always load the process regardless of current state
    
    // Load the process
    loadProcess(messageProcessId, signature);
    
    // Update our reference
    lastSyncedProcessIdRef.current = messageProcessId;
  }, [
    messageProcessId, 
    signature, 
    loadProcess, 
    processState.processId, 
    processState.processBloxes, 
    processState.isLoading
  ]);
  
  return (
    <>
      {children}
    </>
  );
} 