import { 
  Box, 
  Grid, 
  Container,
  Typography
} from '@mui/material';
import { useOwnedProcesses } from '../hooks/DataFetching/use-fetch-process';
import { Loading } from '../components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { useReadUser, useUpdateUser } from '../hooks/DataFetching/use-fetch-user';
import GridBackground from '../components/GridBackground';
import { useState, useMemo } from 'react';
import ProfileCard, { DisplayedUser } from '../components/UserDash/ProfileCard';
import RecentProcessesCard from '../components/UserDash/RecentProcessesCard';
import FabuForumCard from '../components/UserDash/FabuForumCard';

export interface ProcessListResult {
  processName: string
  processId: string
  bloxesInfo: any
  createdAt: string
  lastUpdatedAt: string
}

export default function UserDashPage() {
    const { user: auth0User } = useAuth0();
    const { data: processes, isLoading: processesLoading, error: processesError } = useOwnedProcesses();
    const { data: userData } = useReadUser(auth0User?.sub);
    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState<DisplayedUser | null>(null);

    const displayedUser = useMemo(() => {
        if (isEditing && editedUser) {
            return editedUser;
        }
        return {
            firstName: userData?.firstName || '',
            lastName: userData?.lastName || '',
            displayName: userData?.displayName || '',
            affiliation: userData?.affiliation || '',
            experience: userData?.experience || '',
            profilePicture: userData?.profilePicture || 'default'
        };
    }, [userData, editedUser, isEditing]);
    
    const updateUserMutation = useUpdateUser();

    const handleSaveChanges = async () => {
      const userId = auth0User?.sub;
      if (!userId || !editedUser) return Promise.reject('No user ID or edited data found');

      await updateUserMutation.mutateAsync({ userId, data: editedUser });
      setEditedUser(null);
    };

    if (processesLoading) {
        return <Loading />;
    }

    if (processesError) {
        return (
            <Box className="tw-p-8">
                <Typography variant="h5" color="error" gutterBottom>Error loading data</Typography>
                {processesError && <Typography color="error">Processes error: {processesError.message}</Typography>}
            </Box>
        );
    }

    return (
        <>
            <GridBackground />
            <Box className="tw-bg-gray-50/30 md:tw-p-8 tw-p-0 tw-py-8 tw-overflow-y-auto">
                <Container maxWidth="xl">
                    <Grid container spacing={4} sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                        {/* Left Column */}
                        <Grid item xs={12} md={4} sx={{ minWidth: { md: '450px' }, flexShrink: 0 }}>
                            <ProfileCard 
                              userData={userData}
                              processesCount={processes?.length || 0}
                              isEditing={isEditing}
                              displayedUser={displayedUser}
                              setEditedUser={setEditedUser}
                              handleSaveChanges={handleSaveChanges}
                              setIsEditing={setIsEditing}
                            />
                        </Grid>
                        {/* Right Column */}
                        <Grid item xs={12} md={8} sx={{ flexGrow: 1, minWidth: 0 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <RecentProcessesCard processes={processes} />
                                    
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <FabuForumCard />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}