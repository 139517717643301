import ProcessEditor from "../components/editors/ProcessEditor"
import { FabuPage, PageNames } from "../components/FabuPage"
import { ProcessHeader } from "../components/ProcessHeader"
import { CreateProcessDialog } from "../dialogs/CreateProcessDialog"
import { SaveYourChangesDialog } from "../dialogs/SaveYourChangesDialog"
import { useFabuState } from "../hooks/state/use-fabu-state"
import { UnsavedChangesDialog } from "../dialogs/UnsavedChangesDialog"
import { useContext, useEffect, useRef, useState } from "react"
import { ProcessInfo } from "../components/ProcessInfo"
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider"
import { UnsavedChangesContext } from "../hooks/state/unsaved-changes-provider"
import { hideFeatureToast, showFeatureToast } from ".."
import { Colors, Icon, Intent, ToastProps } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { useEnableStackSimulator } from "../hooks/use-enable-stacksimulator"
import MaterialDialog from "../dialogs/MaterialDialog/MaterialDialog"

export const ProcessEditorPage = () => {
    const { editorUnsavedChanges } = useContext(UnsavedChangesContext);
    const [processIsReadOnly] = useFabuState('processIsReadOnly');
    const { isProcessInfoOpen } = useContext(ProcessSettingsContext);
    const [processSections] = useFabuState('processSections');
    const [closedSections, setClosedSections] = useState<{[key: string]: boolean}>({});

    useEffect(() => {
        const updatedClosedSections = processSections.reduce((acc, section) => {
            if (acc[section.sectionId] !== undefined) return acc;
            
            acc[section.sectionId] = false;
            return acc;
        }, closedSections);

        setClosedSections(updatedClosedSections);

    }, [processSections, closedSections]);

    const { userData, userDataIsLoading } = useEnableStackSimulator();

    const toastKeyRef = useRef<string | null>(null);

    useEffect(() => {
        if (userDataIsLoading) return;
        
        const StackSimulatorToast: ToastProps = {
            message: <div style={{maxWidth: '250px'}}>
                <p>The StackSimulator is available for beta testing!</p>
                <p>Click the blue <strong>'Try Now'</strong> button to turn on StackSimulator which generates dimensionally accurate cross-sections.</p>
            </div>,
            intent: Intent.PRIMARY,
            timeout: 1000 * 60 * 60 * 24,
            icon: <Icon icon={IconNames.LabTest} color={Colors.WHITE}/>,
            isCloseButtonShown: true
        };
        
        if (!!(userData?.enabledFeatures.stackSimulator) === false && !processIsReadOnly) {
            showFeatureToast(StackSimulatorToast).then(key => {
                toastKeyRef.current = key;
            });
        }
        return () => {
            if (toastKeyRef.current) {
                hideFeatureToast(toastKeyRef.current);
                toastKeyRef.current = null;
            }
        };
    }, [userData, userDataIsLoading, processIsReadOnly]);
    
    return (
        <>
                <UnsavedChangesDialog hasUnsavedChanges={editorUnsavedChanges && !processIsReadOnly} />
                <FabuPage
                    pageName={PageNames.ProcessEditor}
                    headerContent={
                        <ProcessHeader closedSectionState={[closedSections, setClosedSections]} />
                    }
                >
                    <CreateProcessDialog />
                    <SaveYourChangesDialog />
                    <ProcessInfo isOpen={isProcessInfoOpen} />
                    <ProcessEditor closedSectionState={[closedSections, setClosedSections]} />
                </FabuPage>
        </>
    );
}

