import { useCallback, useEffect, useState } from "react";
import { Dialog, Classes, Button, Intent } from "@blueprintjs/core";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { IColor } from "../../utils/Color";

import MaterialItem from "./MaterialItem";
import { showToast } from "../.."; // or wherever your toast function resides

import NewMaterial from "./NewMaterial";
import { Row } from "../../Layout/layouts";
import { SelectedBloxMaterialProperties, useMaterialHandlers, PrepareUpdateResult } from "./hooks/use-material-handlers";
import { useMaterialPrompts } from "./hooks/use-material-prompts";
import { getBloxRefByMaterial } from "../../hooks/material-targets";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { getMaterialUnknownLabel } from "../../Data/material-mappings";

interface MaterialDialogProps {
  selectedBlox: AllBloxes;
  selectedBloxMaterialProperties: SelectedBloxMaterialProperties;
  isOpen: boolean;
  onClose: () => void;
}

type UpdateType = "select" | "edit" | "new";

export default function MaterialDialog({
  selectedBlox,
  selectedBloxMaterialProperties,
  isOpen,
  onClose,
}: MaterialDialogProps) {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [pendingSelectionIndex, setPendingSelectionIndex] = useState<number | null>(null);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [isAddingNewMaterial, setIsAddingNewMaterial] = useState(false);
  const [processBloxes] = useFabuState("processBloxes");
  const [initialEditMode, setInitialEditMode] = useState(true);

  const {
    materials,
    currentMaterialName,
    prepareMaterialUpdate,
    finalizeMaterialUpdate,
  } = useMaterialHandlers(selectedBlox, selectedBloxMaterialProperties);

  const {
    showConfirmUpdateMaterial,
    showMultipleSourcesWarning,
    showMaterialInfo,
    ConfirmUpdateMaterialElement,
    WarningMultipleSourcesElement,
    MaterialInfoDialogElement,
  } = useMaterialPrompts();

  const handleShowMaterialInfo = useCallback((materialName: string) => {
    const material = materials.find((m) => m.materialLabel === materialName);
    if (!material) return;
    const bloxRefs = getBloxRefByMaterial(processBloxes, material);
    showMaterialInfo(selectedBlox, materialName, bloxRefs);
  }, [materials, processBloxes, selectedBlox, showMaterialInfo]);

  useEffect(() => {
    const foundIndex = materials.findIndex((m) => m.materialLabel === currentMaterialName);
    setSelectedIdx(foundIndex !== -1 ? foundIndex : 0);
    if (initialEditMode) setEditingIndex(foundIndex !== -1 ? foundIndex : 0);
  }, [materials, currentMaterialName, initialEditMode]);

  const closeDialog = () => {
    onClose();
    setSelectedIdx(null);
    setEditingIndex(null);
    setPendingSelectionIndex(null);
    setIsAddingNewMaterial(false);
    setInitialEditMode(true);
  };

  const handleConfirmNew = (newMaterialLabel: string, color: IColor) => {
    const result = prepareMaterialUpdate(newMaterialLabel, currentMaterialName, -1, color);
    handlePrepareResult("new", result);
  };

  const handleSelectDone = (materialLabel: string, materialIdx: number, color: IColor) => {
    const result = prepareMaterialUpdate(materialLabel, currentMaterialName, materialIdx, color);
    handlePrepareResult("select", result);
  };

  const handleEditDone = (
    newName: string,
    oldName: string,
    materialIdx: number,
    color: IColor
  ) => {
    const result = prepareMaterialUpdate(newName, oldName, materialIdx, color);
    handlePrepareResult("edit", result);
  };

  function resetStateAfterResult() {
    setInitialEditMode(false);
    setEditingIndex(null);
    setPendingSelectionIndex(null);
  }

  async function handlePrepareResult(updateType: UpdateType, result: PrepareUpdateResult) {
    switch (result.type) {
      case "duplicate":
        showToast({
          message: `Material "${result.name}" already exists. Please select the existing material.`,
          intent: Intent.DANGER,
          timeout: 2500,
        });
        break;

      case "warn": {
        // Use the promise-based confirmation
        const selectedUpdateSteps = await showMultipleSourcesWarning(selectedBlox, result.payload);
        // If the user canceled, selectedUpdateSteps will be null/undefined
        if (!selectedUpdateSteps) {
          resetStateAfterResult();
          return;
        }
        // The user confirmed: finalize with the steps they provided
        finalizeMaterialUpdate(
          result.payload.previousMaterialName,
          result.payload.newMaterialName,
          result.payload.newColor,
          selectedUpdateSteps
        );
        resetStateAfterResult();
        break;
      }

      case "update":
        // If it's a selection action, we show the "showConfirmUpdateMaterial" dialog
        // We don't show the dialog if the old material name is the default unknown label
        if (updateType === "select" && !result.oldName.includes(getMaterialUnknownLabel(selectedBlox.bloxType, selectedBloxMaterialProperties.property))) {
          const userConfirmed = await showConfirmUpdateMaterial(result.oldName, result.newName);
          if (!userConfirmed) {
            resetStateAfterResult();
            return;
          }
        }
        finalizeMaterialUpdate(result.oldName, result.newName, result.color);
        resetStateAfterResult();
        break;
    }
  }

  const waitingUserAction =
    pendingSelectionIndex !== null || editingIndex !== null || isAddingNewMaterial;

  // ----------------------------------------------------------------
  // Rendering
  // ----------------------------------------------------------------
  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeDialog}
      title={"Switch or Edit Material"}
      canOutsideClickClose={false}
      canEscapeKeyClose
    >
      <div className={Classes.DIALOG_BODY}>
        {materials.map((mat, idx) => {
          const isDimmed =
            (editingIndex !== null && idx !== editingIndex) ||
            (pendingSelectionIndex !== null && idx !== pendingSelectionIndex) ||
            isAddingNewMaterial;

          return (
            <MaterialItem
              key={mat.materialLabel}
              material={mat}
              isSelected={idx === selectedIdx && !isAddingNewMaterial}
              isEditing={idx === editingIndex && !isAddingNewMaterial}
              isDimmed={isDimmed}
              isCurrent={mat.materialLabel === currentMaterialName}
              isPendingSelection={idx === pendingSelectionIndex}
              showMaterialInfo={() => handleShowMaterialInfo(mat.materialLabel)}
              onStartSelect={() => setPendingSelectionIndex(idx)}
              onCancelSelect={() => setPendingSelectionIndex(null)}
              onStartEdit={() => setEditingIndex(idx)}
              onCancelEdit={() => {
                setEditingIndex(null);
                setInitialEditMode(false);
              }}
              onRequestMaterialEdit={(newName, oldName, newColor) =>
                handleEditDone(newName, oldName, idx, newColor)
              }
              onRequestMaterialSelect={() =>
                handleSelectDone(mat.materialLabel, idx, mat.color)
              }
            />
          );
        })}
        <Row>
          <NewMaterial
            isDimmed={pendingSelectionIndex !== null || editingIndex !== null}
            onConfirm={handleConfirmNew}
            onCancel={() => setIsAddingNewMaterial(false)}
            isAddingState={[isAddingNewMaterial, setIsAddingNewMaterial]}
          />
        </Row>
      </div>

      <Row className={Classes.DIALOG_FOOTER}>
        <Button
          style={{
            marginLeft: "auto",
            pointerEvents: waitingUserAction ? "none" : "auto",
            opacity: waitingUserAction ? 0.4 : 1,
          }}
          text="Done"
          onClick={closeDialog}
        />
      </Row>
      {WarningMultipleSourcesElement}
      {ConfirmUpdateMaterialElement}
      {MaterialInfoDialogElement}
    </Dialog>
  );
}
