import React, { useCallback, useState } from "react";
import { Popover, Menu, MenuItem, Button, EditableText, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Row } from "../Layout/layouts";
import { Section } from "../__generated__/Process";
import { useSectionManager } from "./hooks/use-section-manager";
import { ImportSectionDialog } from "../dialogs/ImportSectionDialog";
import { useFabuState } from "../hooks/state/use-fabu-state";
import { useProcessHandlers } from "./hooks/use-process-handlers";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { DeleteDialog } from "../dialogs/DeleteDialog";
import { scrollToSection } from "../utils/scrollUtils";

interface SectionHeaderProps {
  section: Section;
  sectionIndex: number;
  closedSections: { [key: string]: boolean };
  setClosedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  processSections: Section[];
  setProcessSections: React.Dispatch<React.SetStateAction<Section[]>>;
  onToggleSection: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  section,
  sectionIndex,
  processSections,
  setProcessSections,
  closedSections,
  setClosedSections,
  onToggleSection,
  containerRef,
}) => {
  const [processIsReadOnly] = useFabuState("processIsReadOnly");
  const [processBloxes] = useFabuState("processBloxes");
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [draftSectionName, setDraftSectionName] = useState<{ [key: string]: string | undefined }>({});
  
  const { createSection, rejoinSection } = useSectionManager();
  const { processHandleInsertSection, processHandleDeleteSection } = useProcessHandlers();

  // Find the last bloxId in this section or previous sections
  let lastBloxId: string | null = null;
  for (let i = sectionIndex; i >= 0; i--) {
    const currentSection = processSections[i];
    if (currentSection && currentSection.bloxIds.length > 0) {
      lastBloxId = currentSection.bloxIds[currentSection.bloxIds.length - 1];
      break;
    }
  }
  const bloxIdxToInsert = processBloxes.findIndex(blox => blox.id === lastBloxId);

  const handleInsertSection = useCallback((bloxes: AllBloxes[]) => {
    const newSectionId = createSection(section.sectionId);
    processHandleInsertSection(bloxes, bloxIdxToInsert, newSectionId);
    setTimeout(() => scrollToSection(newSectionId, containerRef));
  }, [processHandleInsertSection, bloxIdxToInsert, section.sectionId, createSection, containerRef]);

  const handleAddSection = useCallback(() => {
    const newSectionId = createSection(section.sectionId);
    setClosedSections(prevState => ({
      ...prevState,
      [newSectionId]: false
    }));
    setTimeout(() => scrollToSection(newSectionId, containerRef));
  }, [createSection, section.sectionId, setClosedSections, containerRef]);

  const toggleSection = useCallback((sectionId: string) => {
    setClosedSections(prevState => ({
      ...prevState,
      [sectionId]: !prevState[sectionId]
    }));
    onToggleSection();
  }, [setClosedSections, onToggleSection]);

  const handleRejoinSection = useCallback(() => {
    rejoinSection(section.sectionId);
    // After rejoining, we want to ensure the previous section is open
    if (sectionIndex > 0) {
      const previousSection = processSections[sectionIndex - 1];
      if (previousSection) {
        setClosedSections(prevState => ({
          ...prevState,
          [previousSection.sectionId]: false
        }));
        // Scroll to the previous section after a short delay to allow for state updates
        setTimeout(() => scrollToSection(previousSection.sectionId, containerRef), 100);
      }
    }
  }, [rejoinSection, section.sectionId, sectionIndex, processSections, setClosedSections, containerRef]);

  const rejoinIcon = <Icon icon={IconNames.Nest} style={{ transform: 'rotate(180deg)' }} />;
  
  const closeDialogCallback = useCallback(() => setIsOpenDeleteDialog(false), []);
  const deleteCallback = useCallback(() => {
    processHandleDeleteSection(section);
    closeDialogCallback();
  }, [processHandleDeleteSection, section, closeDialogCallback]);

  const sectionNameStyle: React.CSSProperties = {
    marginLeft: "10px",
    marginTop: "20px",
    fontWeight: "bold",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <>
      <Row style={{ height: "40px" }}>
        <Popover
          content={
            <Menu>
              <MenuItem
                disabled={processIsReadOnly}
                icon={IconNames.Plus}
                text="Add Section"
                onClick={handleAddSection}
              />
              <MenuItem
                disabled={processIsReadOnly}
                icon={IconNames.Import}
                text="Import Section"
                onClick={() => setIsOpenImportDialog(true)}
              />
              {sectionIndex !== 0 && (
                <>
                  <MenuItem
                    disabled={processIsReadOnly}
                    icon={rejoinIcon}
                    text="Rejoin Section"
                    onClick={handleRejoinSection}
                  />
                  <MenuItem
                    disabled={processIsReadOnly}
                    icon={IconNames.Delete}
                    text="Delete Section"
                    onClick={() => setIsOpenDeleteDialog(true)}
                  />
                </>
              )}
            </Menu>
          }
        >
          <Button
            style={{ marginTop: "17px" }}
            minimal
            icon={IconNames.Properties}
            small={true}
          />
        </Popover>
        <div style={sectionNameStyle}>
          <EditableText
            disabled={processIsReadOnly}
            placeholder={processIsReadOnly ? `Section ${sectionIndex + 1}` : "Click to add Section Name"}
            value={draftSectionName[section.sectionId] ?? section.sectionName}
            onChange={(value) => setDraftSectionName((prevValue) => ({
              ...prevValue,
              [section.sectionId]: value
            }))}
            onConfirm={() => {
              setProcessSections((prevValue) => prevValue.map((s) => {
                if (s.sectionId === section.sectionId) {
                  return { ...s, sectionName: draftSectionName[section.sectionId] ?? s.sectionName };
                }
                return s;
              }));
              setDraftSectionName((prevValue) => ({
                ...prevValue,
                [section.sectionId]: undefined
              }));
            }}
          />
        </div>
        <Button
          style={{ marginTop: "14px", paddingLeft: "0px" }}
          minimal
          icon={closedSections[section.sectionId] ? IconNames.ChevronRight : IconNames.ChevronDown}
          onClick={() => toggleSection(section.sectionId)}
        />
      </Row>
      <ImportSectionDialog
        insertSection={handleInsertSection}
        isOpen={isOpenImportDialog}
        setIsOpen={setIsOpenImportDialog}
      />
      <DeleteDialog
        name={section.sectionName ?? `Section ${sectionIndex + 1}`}
        isOpen={isOpenDeleteDialog}
        onDelete={deleteCallback}
        closeDialog={closeDialogCallback}
        customMessage="This will also delete all Blox in this section"
      />
    </>
  );
};

export default SectionHeader;