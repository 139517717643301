import React from 'react';
import { ProcessTemplate } from './types';
import { Bolt } from '@mui/icons-material';
import MemoryIcon from '@mui/icons-material/Memory';
import SensorsIcon from '@mui/icons-material/Sensors';
import CandlestickChart from '@mui/icons-material/CandlestickChart';

/**
 * Default process templates for semiconductor and process engineering
 */
export const processTemplates: ProcessTemplate[] = [
  {
    id: '74c0474d-625e-4f93-ad58-e98550c1b9d7',
    name: 'Si Waveguide',
    description: 'Complete process flow for fabrication of a Silicon waveguide',
    materials: 'SiO2, Silicon',
    icon: <SensorsIcon />,
    category: 'MOSIS2.0',
    tags: []
  },
  {
    id: '64a3de84-d902-4af4-a175-2eaa6ef45db2',
    name: 'InP HEMT',
    description: 'Complete process flow for fabrication of an InP HEMT developed at CalTech',
    materials: 'Au, Al203, Pt, Ti, InGaAs, InP, InAlAs',
    icon: <MemoryIcon />,
    category: 'CalTech',
    tags: []
  },
  {
    id: 'f9e49772-b951-408d-a3ba-58859004e59e',
    name: 'GaAs pHEMT',
    description: 'Complete process flow for fabrication of a GaAs pHEMT',
    materials: 'Ti, Au, PMMA, SiO2, Ge, Ni, GaAs, AlGaAs',
    icon: <MemoryIcon />,
    category: 'MOSIS2.0',
    tags: []
  },
  {
    id: '5a973365-490b-44df-a00a-749182918253',
    name: 'InP/InGaAs HBT',
    description: 'Complete process flow for fabrication of an InP/InGaAs HBT',
    materials: 'Au, Pt, Ti, InP, InGaAs',
    icon: <MemoryIcon />,
    category: 'MOSIS2.0',
    tags: []
  },
  {
    id: '49a08fb0-e10a-4644-9ca0-aafdb425ee29',
    name: 'Neural Probe',
    description: "Complete process flow for fabrication of a Neural Probe at USC's John O'Brien Nanofabrication facility. The process was originally documented by Alberto Esteban Linares at the USC Biomedical Microsystems Laboratory",
    materials: "Ti, Si, Polyimide-2611",
    icon: <CandlestickChart />,
    category: 'USC',
    tags: []
  },
  {
    id: "4eb456ec-ab9e-4429-b809-72298c1b1752",
    name: "MEMS Pressure Sensor",
    description: "Complete process flow for fabrication of a MEMS Pressure Sensor at USC's John O'Brien Nanofabrication facility.",
    materials: 'Au, Cr, SiN, Si',
    icon: <Bolt />,
    category: 'USC',
    tags: []
  },
  {
    id: "eeaaee04-be97-4a48-a92a-f53d8967eed0",
    name: "On-chip Thin-film Attenuator",
    description: "Complete process flow for fabrication of an on-chip thin-film attenuator. Provided by Anthony Ardizzi.",
    materials: 'Au, Al2O3, Ti, NiCr (80/20), Sapphire',
    icon: <Bolt />,
    category: 'CalTech',
    tags: []
  }
]; 