/**
 * @fileoverview Utility functions for the process table view component.
 * Provides core functionality for row generation, column definitions, and row styling.
 * These utilities are used across the table view components to maintain consistent
 * behavior and appearance.
 * 
 * Key Features:
 * - Row generation from process data
 * - Column definitions with custom renderers
 * - Row styling and class name generation
 * - Parameter formatting and display
 */

import { ReactNode } from 'react';
import {
  GridColDef,
  GridRowParams,
  GridRenderCellParams
} from '@mui/x-data-grid-pro';
import { AllBloxes, LayerProperties, bloxNamesMap } from '../../../Data/BloxSchema/base-blox';
import { Section } from '../../../__generated__/Process';
import { ProcessTableRow } from '../types/ProcessTableTypes';
import { bloxKeyFields } from '../../../Data/BloxKeyFields';
import { displayMap } from '../../../Data/display-mappings';
import { Tab } from '../../../Data/enums';
import { processTableStyles } from '../styles/TableView.styles';

/**
 * Base fields that should be excluded from parameter display
 * These fields are either metadata or handled separately from regular parameters
 */
const BASE_FIELDS = [
  'id', 'bloxType', 'name', 'toolName', 'commentField',
  'moduleId', 'moduleBloxId', 'moduleName', 'customFields'
];

/**
 * Reusable cell content components for the table view
 * Each component handles a specific type of cell content with consistent styling
 */
const CellContent = {
  /**
   * Renders a section header cell
   * @param {string} sectionName - Optional name of the section
   */
  SectionHeader: ({ sectionName }: { sectionName?: string }) => (
    <div 
      style={processTableStyles.cellStyles.sectionHeader}
      data-section-header="true"
    >
      {sectionName || 'Untitled Section'}
    </div>
  ),
  
  /**
   * Renders a step number cell
   * @param {number} value - The step number to display
   */
  StepNumber: ({ value }: { value: number }) => (
    <div style={processTableStyles.cellStyles.stepNumber}>
      {value}
    </div>
  ),
  
  /**
   * Renders a step name cell
   * @param {string} value - The name of the step
   */
  StepName: ({ value }: { value: string }) => (
    <div style={processTableStyles.cellStyles.stepName}>
      {value}
    </div>
  ),
  
  /**
   * Renders a parameters cell with formatted key-value pairs
   * @param {string} value - Newline-separated parameter string
   */
  Parameters: ({ value }: { value: string }) => (
    <div style={processTableStyles.cellStyles.parameters}>
      {value?.split('\n').map((value: string, index: number) => {
        if (!value.trim()) return null;
        const [fieldName, fieldValue] = value.split(': ');
        if (!fieldName || !fieldValue) return null;
        return (
          <div key={index}>
            <span style={processTableStyles.cellStyles.parameterField}>
              {fieldName}:
            </span>
            <span style={processTableStyles.cellStyles.parameterValue}>
              {' ' + fieldValue}
            </span>
          </div>
        );
      })}
    </div>
  ),
  
  /**
   * Renders a comments cell with appropriate styling based on content
   * @param {string} value - The comment text
   */
  Comments: ({ value }: { value: string }) => (
    <div style={value ? processTableStyles.cellStyles.comments.normal : processTableStyles.cellStyles.comments.empty}>
      {value || 'No comments'}
    </div>
  )
};

/**
 * Factory functions for creating different types of table rows
 */
const createRow = {
  /**
   * Creates a section header row
   * @param {string} sectionId - Unique identifier for the section
   * @param {string} sectionName - Optional display name for the section
   * @returns {ProcessTableRow} A section header row
   */
  section: (sectionId: string, sectionName?: string): ProcessTableRow => ({
    id: sectionId,
    stepNumber: 0,
    stepName: '',
    parameters: '',
    comments: '',
    isSectionHeader: true,
    sectionName: sectionName || 'Untitled Section'
  }),

  /**
   * Creates a row for a start blox (substrate)
   * @param {AllBloxes} blox - The start blox data
   * @returns {ProcessTableRow} A start blox row
   */
  startBlox: (blox: AllBloxes): ProcessTableRow => ({
    id: blox.id,
    stepNumber: 0,
    stepName: 'Substrate',
    parameters: formatParameters.startBlox(blox),
    comments: (blox.commentField ?? '').toString(),
    isSectionHeader: false
  }),

  /**
   * Creates a row for a process blox
   * @param {AllBloxes} blox - The process blox data
   * @param {number} stepNumber - Current step number in the process
   * @param {boolean} showAllParameters - Whether to show all parameters or only key fields
   * @returns {ProcessTableRow} A process blox row
   */
  processBlox: (blox: AllBloxes, stepNumber: number, showAllParameters: boolean): ProcessTableRow => ({
    id: blox.id,
    stepNumber,
    stepName: blox.name || bloxNamesMap[blox.bloxType] || blox.bloxType,
    parameters: formatParameters.processBlox(blox, showAllParameters),
    comments: (blox.commentField ?? '').toString(),
    isSectionHeader: false
  })
};

/**
 * Generates a unique row ID for a table row
 * @param {ProcessTableRow} row - The row data object
 * @returns {string} The unique identifier for the row
 */
export const getRowId = (row: ProcessTableRow): string => row.id;

/**
 * Generates class names for a table row based on its properties
 * @param {GridRowParams} params - The row parameters from the data grid
 * @param {string | null} selectedBloxId - Currently selected blox ID
 * @returns {string} Space-separated class names for the row
 */
export const getRowClassName = (
  params: GridRowParams<ProcessTableRow>,
  selectedBloxId: string | null
): string => {
  const classes = [];
  if (params.row.isSectionHeader) {
    classes.push('section-header-row');
  }
  if (params.id === selectedBloxId) {
    classes.push('selected-row');
  }
  return classes.join(' ');
};

/**
 * Generates section rows from process bloxes and sections
 * Transforms the raw process data into a format suitable for the data grid,
 * handling both section headers and process blocks.
 * 
 * @param {AllBloxes[]} processBloxes - Array of process bloxes
 * @param {Section[]} processSections - Array of process sections
 * @param {boolean} showAllParameters - Whether to show all parameters or only key fields
 * @returns {Object} Map of section IDs to their corresponding rows
 */
export const generateSectionRows = (
  processBloxes: AllBloxes[],
  processSections: Section[],
  showAllParameters: boolean
): { [key: string]: ProcessTableRow[] } => {
  const sectionMap: { [key: string]: ProcessTableRow[] } = {};
  let currentStepNumber = 1;

  processSections.forEach((section) => {
    const tableRows = [createRow.section(section.sectionId, section.sectionName)];

    section.bloxIds.forEach((bloxId: string) => {
      const blox = processBloxes.find((b) => b.id === bloxId);
      if (!blox) return;

      if (blox.bloxType === 'STARTBLOX') {
        tableRows.push(createRow.startBlox(blox));
      } else {
        tableRows.push(createRow.processBlox(blox, currentStepNumber++, showAllParameters));
      }
    });

    sectionMap[section.sectionId] = tableRows;
  });

  return sectionMap;
};

/**
 * Parameter formatting utilities for different blox types
 */
const formatParameters = {
  /**
   * Formats parameters for a start blox (substrate)
   * @param {AllBloxes} blox - The start blox data
   * @returns {string} Formatted parameter string with layer information
   */
  startBlox: (blox: AllBloxes): string => {
    if (!blox.layers?.length) return '';
    return blox.layers
      .slice()
      .reverse()
      .map((layer: LayerProperties) => {
        const label = layer.layerLabel || 'Unnamed Substrate Layer';
        const thickness = layer.layerSimulationThickness;
        const unit = layer.layerSimulationThicknessUnit;
        if (!thickness || !unit) return '';
        return `${label}: ${thickness} ${unit}`;
      })
      .filter(Boolean)
      .join('\n');
  },

  /**
   * Formats parameters for a process blox
   * @param {AllBloxes} blox - The process blox data
   * @param {boolean} showAllParameters - Whether to show all parameters or only key fields
   * @returns {string} Formatted parameter string with process parameters
   */
  processBlox: (blox: AllBloxes, showAllParameters: boolean): string => {
    const allParams = Object.entries(blox)
      .filter(([key, value]) =>
        !BASE_FIELDS.includes(key) &&
        value !== undefined &&
        value !== null &&
        !key.endsWith('Unit') &&
        typeof value !== 'function' &&
        typeof value !== 'object' &&
        !key.endsWith('Disabled')
      );

    const displayMapForBlox = displayMap[blox.bloxType];
    const filteredParams = allParams.filter(([key]) => {
      const paramDisplay = displayMapForBlox?.[key];
      if (!paramDisplay?.tabs.includes(Tab.EXPERIMENTAL)) return false;
      if (!showAllParameters) {
        const keyFields = bloxKeyFields[blox.bloxType as keyof typeof bloxKeyFields] || [];
        return keyFields.includes(key);
      }
      return true;
    });

    const paramStrings = filteredParams.map(([key, value]) => {
      if (!value) return '';
      const unitKey = `${key}Unit`;
      const unit = blox[unitKey as keyof AllBloxes];
      const displayLabel = displayMapForBlox?.[key]?.label || key.replace(/([A-Z])/g, ' $1').toLowerCase();
      return unit ? `${displayLabel}: ${value} ${unit}` : `${displayLabel}: ${value}`;
    }).filter(Boolean);

    const customFieldStrings = blox.customFields 
      ? Object.values(blox.customFields)
          .filter(([key, value]) => key && value)
          .map(([key, value]) => `${key}: ${value}`)
      : [];

    return [...paramStrings, ...customFieldStrings].join('\n');
  }
};

/**
 * Renders a step number cell
 */
export const renderStepNumberCell = (
  params: GridRenderCellParams<ProcessTableRow, any>
): ReactNode => {
  if (params.row.isSectionHeader) {
    return (
      <div style={processTableStyles.cellStyles.sectionHeader}>
        {params.row.sectionName || 'Untitled Section'}
      </div>
    );
  }
  return (
    <div style={processTableStyles.cellStyles.stepNumber}>
      {params.value}
    </div>
  );
};

/**
 * Renders a step name cell
 */
export const renderStepNameCell = (
  params: GridRenderCellParams<ProcessTableRow, any>
): ReactNode => {
  if (params.row.isSectionHeader) return null;
  return (
    <div style={processTableStyles.cellStyles.stepName}>
      {params.value}
    </div>
  );
};

/**
 * Renders a parameters cell
 */
export const renderParametersCell = (
  params: GridRenderCellParams<ProcessTableRow, string>
): ReactNode => {
  if (params.row.isSectionHeader) return '';
  return (
    <div style={processTableStyles.cellStyles.parameters}>
      {params.value
        ?.split('\n')
        .map((value: string, index: number) => {
          const [fieldName, fieldValue] = value.split(': ');
          return (
            <div key={index}>
              <span style={processTableStyles.cellStyles.parameterField}>
                {fieldName}:
              </span>
              <span style={processTableStyles.cellStyles.parameterValue}>
                {fieldValue}
              </span>
            </div>
          );
        })}
    </div>
  );
};

/**
 * Renders a comments cell
 */
export const renderCommentsCell = (
  params: GridRenderCellParams<ProcessTableRow, string>
): ReactNode => {
  if (params.row.isSectionHeader) return '';
  return (
    <div
      style={
        params.value
          ? processTableStyles.cellStyles.comments.normal
          : processTableStyles.cellStyles.comments.empty
      }
    >
      {params.value || 'No comments'}
    </div>
  );
};

/**
 * Gets the column definitions for the process table
 * @returns {GridColDef[]} Array of column definitions
 */
export const getColumnDefs = (): GridColDef[] => [
  {
    field: 'stepNumber',
    headerName: 'Step',
    width: 60,
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<ProcessTableRow>) => {
      if (params.row.isSectionHeader) {
        return (
          <div 
            style={processTableStyles.cellStyles.sectionHeader}
            data-section-header="true"
          >
            {params.row.sectionName || 'Untitled Section'}
          </div>
        );
      }
      return <CellContent.StepNumber value={params.value} />;
    }
  },
  {
    field: 'stepName',
    headerName: 'Name',
    width: 200,
    renderCell: (params: GridRenderCellParams<ProcessTableRow>) =>
      !params.row.isSectionHeader && <CellContent.StepName value={params.value} />
  },
  {
    field: 'parameters',
    headerName: 'Parameters',
    flex: 1,
    renderCell: (params: GridRenderCellParams<ProcessTableRow>) =>
      !params.row.isSectionHeader && <CellContent.Parameters value={params.value} />
  },
  {
    field: 'comments',
    headerName: 'Comments',
    width: 220,
    headerAlign: 'left',
    renderCell: (params: GridRenderCellParams<ProcessTableRow>) =>
      !params.row.isSectionHeader && <CellContent.Comments value={params.value} />
  }
];