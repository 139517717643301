/**
 * Custom hook providing handler functions for interacting with Blox components in the Fabu application.
 * These handlers cover actions such as dropping, deleting, clicking, saving, and creating Bloxes or processes.
 * The functions are centralized here for consistent state management and API interaction handling.
 * 
 * @module useHandlers
 */

import { useCallback } from "react";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { getNewBlox } from "../../hooks/new-blox-data";
import { Intent, ToastProps } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { BASE_FABUBLOX_API_URL } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from "react-query";
import { StateAndSetter } from "../../hooks/state/parameter-context";
import { showToast } from "../..";
import { materialFieldMap } from "../../Data/material-mappings";
import { getProperty, hasKey, setProperty } from "../../utils/ts-helpers";
import { getBloxWithDefaults } from "../../Data/fill-blox-defaults";


/**
 * Interface representing the arguments required for handler functions.
 * 
 * @interface HandlersArgs
 * @property {StateAndSetter<string>} selectedBloxIdState - State and setter for selected Blox ID.
 * @property {StateAndSetter<AllBloxes[]>} bloxesState - State and setter for the array of Bloxes.
 */
export interface HandlersArgs {
  selectedBloxIdState: StateAndSetter<string>;
  bloxesState: StateAndSetter<AllBloxes[]>;
}

/**
 * Hook providing a set of reusable handlers for managing Blox interactions.
 * These handlers manage the application's Blox state and interact with APIs as needed.
 * 
 * @param {HandlersArgs} args - Contains state and setter functions for selected Blox ID and Bloxes array.
 * @returns {object} An object with handler functions for Blox interactions: drop, delete, click, save, and create.
 */
export function useHandlers(args: HandlersArgs) {
  const {
    selectedBloxIdState: [, setSelectedBloxId],
    bloxesState: [bloxes, setBloxes],
  } = args; 

  const queryClient = useQueryClient();

  /**
   * Handles dropping a Blox into the specified position in the Blox array.
   * Supports both moving an existing Blox to a new position and creating a new Blox.
   * 
   * @param {object} item - The Blox item being dropped, including type, ID, and optional data.
   * @param {number} stepNumber - The target index for the Blox in the array.
   * @returns {string} The ID of the dropped Blox.
   */
  const handleDrop = useCallback((item: {bloxType: BloxTypes, id?: string, data?: AllBloxes}, stepNumber: number) => {
    const updateBloxes = [...bloxes]
    let bloxId = item.id;
    let data: AllBloxes | null = null;

    // reorder existing blox
    if(item.id) {
      const toMoveIdx = updateBloxes.findIndex(blox => blox.id === item.id);
      if (toMoveIdx === stepNumber || toMoveIdx === stepNumber + 1) return bloxId;
      const toMoveBlox = updateBloxes[toMoveIdx]; 
     
      // add to new position
      updateBloxes.splice(stepNumber + 1, 0, toMoveBlox);
      //if dropping before, the toRemoveBlox is in a new position
      const toRemoveIdx = stepNumber < toMoveIdx ? toMoveIdx + 1 : toMoveIdx;
      // remove old blox
      updateBloxes.splice(toRemoveIdx, 1);
    } else { 
      // add new blox

      // blox has data already if it is a MyBlox
      if (item.data) {
        data = {...item.data};
        bloxId = uuidv4();
        data.id = bloxId;
      }

      const newBlox = data !== null ? getBloxWithDefaults(data) : getNewBlox(item.bloxType, '');
      bloxId = newBlox.id;

      // if this blox adds a new material - fill in unknown material name
      const materialParams = materialFieldMap[newBlox.bloxType]
      if (materialParams && hasKey(newBlox, materialParams.materialFieldName)) {
        const unknownLabel = materialParams.unknownLabel;
        let newLabel = unknownLabel

        const unknownNames = bloxes.map(blox => {
          const tempMaterialParams = materialFieldMap[blox.bloxType]
          if (tempMaterialParams && hasKey(blox, tempMaterialParams.materialFieldName)) {
            return getProperty(blox, tempMaterialParams.materialFieldName) as string;
          } else {
            return "";
          }
        }).filter(label => label && label.startsWith(unknownLabel));

        if (unknownNames.length > 0) {
            let largestNumber = 1;

            unknownNames.forEach((name) => {
              const regexpString = "^" + unknownLabel + " (\\d+)$";
              const match = name.match(new RegExp(regexpString));
              if (match) {
                const number = parseInt(match[1], 10);
                if (number > largestNumber) {
                  largestNumber = number;
                }
              }
            });
          
            newLabel += " " + (largestNumber + 1);
        }

        setProperty(newBlox, materialParams.materialFieldName, newLabel)
      }

      updateBloxes.splice(stepNumber + 1, 0, newBlox);
    }
     
    setBloxes([...updateBloxes]);

    if (bloxId) {
      setSelectedBloxId(bloxId);
    }
    
    return bloxId;
  }, [ bloxes, setBloxes, setSelectedBloxId]); 

  /**
   * Deletes a Blox by its ID, removing it from the array if found.
   * 
   * @param {string} id - The ID of the Blox to delete.
   */
  const handleDelete = useCallback((id: string) => {
    const updateBloxes = [...bloxes];

    const toRemoveIdx = updateBloxes.findIndex((blox) => blox.id === id);
    if (toRemoveIdx === 0 || toRemoveIdx < 0) return;
    updateBloxes.splice(toRemoveIdx, 1);

    setBloxes([...updateBloxes]);
  }, [bloxes, setBloxes]);
 
  /**
   * Handles selecting a Blox by its ID, marking it as the currently active Blox.
   * Logs the selection for debugging or user feedback.
   * 
   * @param {string} bloxId - The ID of the Blox to select.
   */
  const handleClickBlox = useCallback((bloxId: string) => {
      if (bloxes.findIndex(blox => blox.id === bloxId) < 0) return;
      setSelectedBloxId(bloxId);
  }, [bloxes, setSelectedBloxId]);

  /**
   * Saves the current process or module by sending updated data to the API.
   * Displays a toast notification upon success or failure.
   * 
   * @param {string} id - The ID of the process or module to save.
   * @param {string} path - API endpoint path for saving the data.
   * @param {ToastProps} toast - Properties for the toast notification.
   * @param {object} body - JSON body containing the data to save.
   * @param {string} [token] - Optional authorization token for API requests.
   * @param {boolean} [isModule] - Whether the saved entity is a module (affects cache invalidation).
   */
  const handleSave = useCallback((id: string, path: string, toast: ToastProps, body: object, token?: string, isModule?: boolean) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(body)
    };

    fetch(`${BASE_FABUBLOX_API_URL}${path}${id}`, requestOptions)
      .then(res => {
        if (res.ok) {
          showToast(toast)
          queryClient.invalidateQueries([`${isModule ? 'module' : 'process'}`, id]);
        } else {
          showToast({
            message: "Save Failed",
            intent: Intent.DANGER,
            timeout: 3000
          });

      }})
      .catch((error: any) => console.error(`Save failed with ${error.message}`));
  }, [queryClient]);

  const history = useHistory();

  /**
   * Creates a new process or module, redirecting to the edit page upon success.
   * Displays a toast notification upon success or failure.
   * 
   * @param {string} path - API endpoint path for creating the entity.
   * @param {string} redirectPath - Path to redirect to upon successful creation.
   * @param {ToastProps} toast - Properties for the toast notification.
   * @param {object} body - JSON body containing the data to create.
   * @param {string} [token] - Optional authorization token for API requests.
   */
  const handleCreate = useCallback((path: string, redirectPath: string, toast: ToastProps, body: object, token?: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      body: JSON.stringify(body)
    };

    fetch(`${BASE_FABUBLOX_API_URL}${path}`, requestOptions)
      .then(res => {
        if (res.ok)
          showToast(toast)
        else
          showToast({
            message: "Save Failed",
            intent: Intent.DANGER,
            timeout: 3000
          });

        return res.json();
      })
      .then(res => {
        const {id} = res;
        history.push(`${redirectPath}/${id}`);
      })
      .catch((error: any) => console.error(`Create failed with ${error.message}`));
  }, [history]);

  return {
    handleClickBlox,
    handleDelete,
    handleDrop,
    handleSave,
    handleCreate,
  };
}