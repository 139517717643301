import React from 'react';
import { Box, Typography, FormControlLabel, Switch, Button, Collapse } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { FormContainerProps } from './types';
import { Row } from '../../../Layout/layouts';
import { PoweredByFabuBloxLogo } from '../IFrameWelcomeBox';
import FormField from './FormField';

/**
 * Container component for the form fields and submit button
 */
const FormContainer: React.FC<FormContainerProps> = ({
  isExpanded,
  processId,
  processName,
  includeProcess,
  setIncludeProcess,
  formFields,
  isSubmitting,
  copiedField,
  handleCopy,
  isFormValid,
  handleSubmit,
  isMobile
}) => (
  <Collapse in={isExpanded}>
    <Box sx={{ 
      padding: 2,
      maxHeight: "80vh", 
      overflowY: "auto", 
      display: "flex",
      flexDirection: "column",
    }}>
      <Row style={{ width: '100%' }}>
        <div style={{ margin: '0 0 12px auto' }}>
          <PoweredByFabuBloxLogo />
        </div>
      </Row>

      <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
        Submit a request to the MOSIS2.0 team with any comments, questions, and interests
        you may have related to our prototyping and fabrication services.
      </Typography>

      {processId === 'new' && (
        <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
          You currently have <b>no example process selected</b> to be included in your request.
        </Typography>
      )}

      {/* Render the toggle only if processId is not "new" */}
      {includeProcess && processId !== 'new' && (
        <Typography variant="body2" sx={{ mb: 2, lineHeight: 1.6 }}>
          You have selected the <strong>{processName}</strong> example process flow. Please add any questions
          and custom requests related to this process flow in the request form below.
        </Typography>
      )}

      {/* Render the toggle only if processId is not "new" */}
      {processId !== 'new' && (
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={includeProcess}
                onChange={(e) => setIncludeProcess(e.target.checked)}
                color="primary"
              />
            }
            label={<>Include reference to <b>{processName}</b> process flow?</>}
          />
        </Box>
      )}

      {/* Render form fields */}
      {formFields.map((field) => (
        <FormField
          key={field.fieldName}
          field={field}
          isSubmitting={isSubmitting}
          copiedField={copiedField}
          handleCopy={handleCopy}
        />
      ))}

      <Button
        variant="contained"
        color="primary"
        disabled={!isFormValid || isSubmitting}
        onClick={handleSubmit}
        startIcon={<SendIcon />}
        fullWidth
        sx={{ mb: 1 }}
      >
        {isSubmitting ? 'Sending...' : 'Submit Request to MOSIS2.0 Team'}
      </Button>
    </Box>
  </Collapse>
);

export default FormContainer; 