import { Loading } from '../components/Loading';
import { BloxesAndThumbnail } from '../pages/SearchPage';
import { useProcessPreview } from '../hooks/use-process-preview';

interface StandaloneProcessPreviewRendererProps {
    bloxesInfo: BloxesAndThumbnail;
}

export const StandaloneProcessPreviewRenderer = ({ bloxesInfo }: StandaloneProcessPreviewRendererProps) => {
    const { bloxes, thumbnail } = bloxesInfo;
    const { loading, svgIcon } = useProcessPreview({ bloxes, thumbnail });

    return (
        <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? <Loading size={5} /> : svgIcon}
        </div>
    );
}; 