import { useState, useCallback, useContext } from 'react';
import React from 'react';
import { toPng } from 'html-to-image';
import GIF from 'gif.js';
import ReactDOMServer from 'react-dom/server';
import { SVGDisplayMode } from '../Services/SVGEngine';
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { SvgServiceContext } from '../hooks/state/svg-service-provider';
import { useExportDialogState } from '../components/export-dialog/hooks/state/export-dialog-provider';

/**
 * TRANSPARENCY ISSUE NOTES:
 * 
 * There are ongoing challenges with GIF transparency in this export functionality:
 * 
 * 1. GIF transparency is implemented via the 'transparent: "0"' parameter in GIF.js
 * 2. Step info labels (text overlays) have been temporarily disabled to ensure transparent backgrounds
 * 3. ALL text elements in SVGs are now forcibly removed to show only stack colors (regardless of user settings)
 * 
 * Current workaround:
 * - Commented out step info display code 
 * - Removed all text elements from SVGs (ignoring the showText setting)
 * - Set all container backgrounds to transparent
 * - Added explicit transparent background to SVG elements
 * - Using the GIF.js transparent index parameter
 * 
 * Future improvements:
 * - Investigate better ways to overlay text while maintaining transparency
 * - Consider alternative export formats with better transparency support
 * - Possibly implement a different text rendering strategy that works with GIF transparency
 * - Restore respect for the showText setting once transparency issues are resolved
 */

// Base delay in ms
const BASE_FRAME_DELAY = 150;

interface UseStackExportParams {
    bloxes: AllBloxes[];
    currentIndex: number;
    filename: string;
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[];
    showStepInfo: boolean;
    showText: boolean;
    speedMultiplier: number;
}

/**
 * Custom hook for handling stack visualization exports in GIF, WebM, and PNG formats.
 * 
 * This hook provides functionality to:
 * 1. Export the entire stack animation as a GIF
 * 2. Export the entire stack animation as a WebM video
 * 3. Export the current stack frame as a PNG image
 * 
 * The hook manages its own loading state and handles all the complexity of
 * converting DOM elements to images and creating animations.
 * 
 * @param processBloxes - Array of blox objects representing the entire stack state
 * @param stepNumber - Current step number in the stack visualization
 * @param processName - Name of the current process (used for export filenames)
 * @param processSections - Array of section objects representing the stack sections
 * @param showStepInfo - Boolean indicating whether to show step and section names
 * @param showText - Boolean indicating whether to show text in the SVG
 * @param speedMultiplier - The speed multiplier for the animation
 * 
 * @returns {Object} An object containing:
 *   - handleExportGif: Function to export the stack as an animated GIF
 *   - handleExportImage: Function to export the current frame as a PNG
 *   - handleExportMp4: Function to export the stack as a WebM video
 *   - isExporting: Boolean indicating if an export is in progress
 */
export const useStackExport = (
    bloxes: AllBloxes[],
    currentIndex: number,
    filename: string,
    speedMultiplier: number,
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[],
    showStepInfo = true,
    showText = true
) => {
    const [isExporting, setIsExporting] = useState(false);
    const { generateSvgs } = useContext(SvgServiceContext);

    const getSectionName = useCallback((bloxId: string): string => {
        if (!processSections) return '';
        for (const section of processSections) {
            if (section.bloxIds.includes(bloxId)) {
                return section.sectionName || '';
            }
        }
        return '';
    }, [processSections]);

    const createStepInfo = useCallback((currentStep: number, currentBlox: AllBloxes) => {
        if (currentStep === 0) {
            return {
                title: 'Substrate Stack',
                subtitle: filename
            };
        }
        return {
            title: `Step ${currentStep}: ${currentBlox?.name || ''}`,
            subtitle: getSectionName(currentBlox.id)
        };
    }, [filename, getSectionName]);

    const getFileName = useCallback((currentStep: number, format: string, currentBlox?: AllBloxes) => {
        if (format === 'gif' || format === 'webm') {
            return `${filename || 'stack'}.${format}`;
        }
        
        const stepName = currentStep === 0 ? 'substrate-stack' : 
            `step-${currentStep}-${currentBlox?.name?.toLowerCase().replace(/\s+/g, '-') || 'unnamed'}`;
        return `${filename || 'stack'}-${stepName}.${format}`;
    }, [filename]);

    /**
     * Exports the entire stack visualization as an animated GIF.
     * Each frame represents a step in the stack, with a 1-second delay between frames.
     * 
     * @returns {Promise<void>}
     * @throws {Error} If there's an issue during the export process
     */
    const handleExportGif = useCallback(async () => {
        if (!bloxes || bloxes.length === 0) return;
        setIsExporting(true);

        try {
            const allSvgs = generateSvgs(bloxes, false, SVGDisplayMode.ExportPreview);
            const frames: HTMLImageElement[] = [];
            
            const containerWidth = 500;
            const containerHeight = 500;

            for (let currentStep = 0; currentStep < allSvgs.length; currentStep++) {
                const svg = allSvgs[currentStep];
                const currentBlox = bloxes[currentStep];
                const container = document.createElement('div');
                container.style.width = `${containerWidth}px`;
                container.style.height = `${containerHeight}px`;
                container.style.backgroundColor = 'transparent';
                container.style.position = 'relative';

                /* 
                 * NOTE: Temporarily commenting out step info labels to avoid transparency issues with GIF exports.
                 * 
                 * There are issues with GIF transparency when text labels are included:
                 * 1. The text container can interfere with transparency
                 * 2. GIF.js transparency handling is complex and requires careful configuration
                 * 3. The transparent: "0" setting helps but text overlay causes inconsistent results
                 * 
                 * TODO: Implement proper transparency support with text labels in a future update
                 */
                /* 
                if (showStepInfo) {
                    const textContainer = document.createElement('div');
                    textContainer.style.position = 'absolute';
                    textContainer.style.top = '20px';
                    textContainer.style.left = '20px';
                    textContainer.style.fontFamily = 'var(--font-primary)';
                    textContainer.style.color = 'var(--text-dark)';
                    textContainer.style.zIndex = '1';

                    const { title, subtitle } = createStepInfo(currentStep, currentBlox);

                    const stepName = document.createElement('div');
                    stepName.style.fontSize = '11px';
                    stepName.style.fontWeight = 'bold';
                    stepName.style.marginBottom = '2px';
                    stepName.textContent = title;
                    textContainer.appendChild(stepName);

                    if (subtitle) {
                        const sectionName = document.createElement('div');
                        sectionName.style.fontSize = '9px';
                        sectionName.style.color = 'var(--text-secondary)';
                        sectionName.textContent = subtitle;
                        textContainer.appendChild(sectionName);
                    }

                    container.appendChild(textContainer);
                }
                */

                // Simplified SVG setup with absolute positioning
                const scaledSvg = React.cloneElement(svg, {
                    width: '250px',
                    height: '250px',
                    preserveAspectRatio: 'xMidYMax meet',
                    style: {
                        display: 'block',
                        position: 'absolute',
                        bottom: '250px',
                        backgroundColor: 'transparent'
                    }
                });
                
                // Convert SVG to string and inject into container
                let svgString = ReactDOMServer.renderToString(scaledSvg);
                
                /* 
                 * NOTE: Temporarily removing all text elements from SVGs regardless of showText setting
                 * This ensures we only see the colors of the stack in exported animations
                 * Once transparency issues are resolved, this can be reverted to respect the showText setting
                 */
                // Always remove text elements, ignoring showText setting for now
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = svgString;
                const texts = tempDiv.getElementsByTagName('text');
                Array.from(texts).forEach(text => text.remove());
                svgString = tempDiv.innerHTML;
                
                /* Original conditional text removal - commented out for now
                // Remove text elements if showText is false
                if (!showText) {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = svgString;
                    const texts = tempDiv.getElementsByTagName('text');
                    Array.from(texts).forEach(text => text.remove());
                    svgString = tempDiv.innerHTML;
                }
                */
                
                // Ensure SVG has transparency enabled
                const enhancedSvgString = svgString.replace('<svg', '<svg style="background-color:transparent;"');
                
                container.innerHTML = container.innerHTML + enhancedSvgString;  // Append SVG while preserving text container
                
                // Capture the frame at higher resolution
                const dataUrl = await toPng(container, {
                    width: containerWidth,
                    height: containerHeight,
                    backgroundColor: 'transparent',
                    pixelRatio: 2  // Higher resolution capture
                });
                
                // Create image from data URL
                const img = new Image();
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = dataUrl;
                });
                frames.push(img);
            }

            // Create GIF with higher quality settings
            const gif = new GIF({
                workers: 4,
                quality: 1,
                width: containerWidth,
                height: containerHeight,
                transparent: "0",
                workerScript: process.env.PUBLIC_URL + '/gif.worker.js'
            });

            // Add frames to GIF with speed-adjusted animation
            for (const img of frames) {
                gif.addFrame(img, { delay: Math.round(BASE_FRAME_DELAY / speedMultiplier) });
            }

            gif.on('finished', (blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = getFileName(0, 'gif');
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                setIsExporting(false);
            });

            gif.render();
        } catch (error) {
            console.error('Error exporting GIF:', error);
            setIsExporting(false);
        }
    }, [bloxes, generateSvgs, showStepInfo, getFileName, speedMultiplier, createStepInfo]);

    /**
     * Exports the current stack frame as a PNG image.
     * The exported image will reflect the current state of the stack at the given step number.
     * 
     * @returns {Promise<void>}
     * @throws {Error} If there's an issue during the export process
     */
    const handleExportImage = useCallback(async () => {
        const currentBlox = bloxes[currentIndex];
        const svgElement = document.getElementById(`stack-item-${currentBlox?.id}`);
        if (!svgElement) return;

        try {
            const pngDataUrl = await toPng(svgElement, {
                backgroundColor: 'transparent',  
                width: 420,
                height: 400
            });

            const a = document.createElement('a');
            a.href = pngDataUrl;
            a.download = getFileName(currentIndex, 'png', currentBlox);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error exporting image:', error);
        }
    }, [bloxes, currentIndex, getFileName]);

    /**
     * Exports the entire stack visualization as a WebM video.
     * Each frame represents a step in the stack, with smooth transitions.
     * 
     * @returns {Promise<void>}
     * @throws {Error} If there's an issue during the export process
     */
    const handleExportWebM = useCallback(async () => {
        if (!bloxes || bloxes.length === 0) return;
        setIsExporting(true);

        try {
            const allSvgs = generateSvgs(bloxes, false, SVGDisplayMode.ExportPreview);
            
            const containerWidth = 500;
            const containerHeight = 500;

            const canvas = document.createElement('canvas');
            canvas.width = containerWidth;
            canvas.height = containerHeight;
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Failed to get canvas context');
            }

            const framePromises = allSvgs.map(async (svg, currentStep) => {
                const currentBlox = bloxes[currentStep];
                
                const container = document.createElement('div');
                container.style.width = `${containerWidth}px`;
                container.style.height = `${containerHeight}px`;
                container.style.backgroundColor = 'transparent';
                container.style.position = 'relative';

                /* 
                 * See note above in handleExportGif - temporarily disabling step info for transparency issues
                 */
                /*
                if (showStepInfo) {
                    const textContainer = document.createElement('div');
                    textContainer.style.position = 'absolute';
                    textContainer.style.top = '20px';
                    textContainer.style.left = '20px';
                    textContainer.style.fontFamily = 'var(--font-primary)';
                    textContainer.style.color = 'var(--text-dark)';
                    textContainer.style.zIndex = '1';

                    const { title, subtitle } = createStepInfo(currentStep, currentBlox);

                    const stepName = document.createElement('div');
                    stepName.style.fontSize = '11px';
                    stepName.style.fontWeight = 'bold';
                    stepName.style.marginBottom = '2px';
                    stepName.textContent = title;
                    textContainer.appendChild(stepName);

                    if (subtitle) {
                        const sectionName = document.createElement('div');
                        sectionName.style.fontSize = '9px';
                        sectionName.style.color = 'var(--text-secondary)';
                        sectionName.textContent = subtitle;
                        textContainer.appendChild(sectionName);
                    }

                    container.appendChild(textContainer);
                }
                */

                // Simplified SVG setup with absolute positioning
                const scaledSvg = React.cloneElement(svg, {
                    width: '250px',
                    height: '250px',
                    preserveAspectRatio: 'xMidYMax meet',
                    style: {
                        display: 'block',
                        position: 'absolute',
                        bottom: '250px',
                        backgroundColor: 'transparent'
                    }
                });
                
                // Convert SVG to string and inject into container
                let svgString = ReactDOMServer.renderToString(scaledSvg);
                
                /* 
                 * NOTE: Temporarily removing all text elements from SVGs regardless of showText setting
                 * This ensures we only see the colors of the stack in exported animations
                 * Once transparency issues are resolved, this can be reverted to respect the showText setting
                 */
                // Always remove text elements, ignoring showText setting for now
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = svgString;
                const texts = tempDiv.getElementsByTagName('text');
                Array.from(texts).forEach(text => text.remove());
                svgString = tempDiv.innerHTML;
                
                /* Original conditional text removal - commented out for now
                // Remove text elements if showText is false
                if (!showText) {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = svgString;
                    const texts = tempDiv.getElementsByTagName('text');
                    Array.from(texts).forEach(text => text.remove());
                    svgString = tempDiv.innerHTML;
                }
                */
                
                // Ensure SVG has transparency enabled
                const enhancedSvgString = svgString.replace('<svg', '<svg style="background-color:transparent;"');
                
                container.innerHTML = container.innerHTML + enhancedSvgString;

                /* Previous text removal - now handled above
                // If text should be hidden, remove all text elements
                if (!showText) {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = container.innerHTML;
                    const texts = tempDiv.getElementsByTagName('text');
                    Array.from(texts).forEach(text => text.remove());
                    container.innerHTML = tempDiv.innerHTML;
                }
                */

                // Convert the container to a data URL using html-to-image
                return await toPng(container, {
                    width: containerWidth,
                    height: containerHeight,
                    backgroundColor: 'transparent',
                    pixelRatio: 2  // Higher resolution capture
                });
            });

            // Wait for all frames to be processed
            const frameDataUrls = await Promise.all(framePromises);

            // Create MediaRecorder
            const stream = canvas.captureStream(30); // 30 fps
            const mediaRecorder = new MediaRecorder(stream, {
                mimeType: 'video/webm;codecs=vp9',
                videoBitsPerSecond: 5000000
            });

            const chunks: Blob[] = [];
            mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
            
            // When recording is done, create and download the video
            mediaRecorder.onstop = () => {
                const blob = new Blob(chunks, { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = getFileName(0, 'webm');
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                setIsExporting(false);
            };

            // Start recording
            mediaRecorder.start();

            // Load all images first
            const images = await Promise.all(
                frameDataUrls.map(url => new Promise<HTMLImageElement>((resolve) => {
                    const img = new Image();
                    img.onload = () => resolve(img);
                    img.src = url;
                }))
            );

            // Function to draw frames using requestAnimationFrame
            let currentFrame = 0;
            let lastDrawTime = 0;
            const frameInterval = BASE_FRAME_DELAY / speedMultiplier; // Adjust frame interval based on speed multiplier

            const drawFrame = (timestamp: number) => {
                if (!lastDrawTime) lastDrawTime = timestamp;
                
                const elapsed = timestamp - lastDrawTime;
                
                if (elapsed >= frameInterval) {
                    if (currentFrame < images.length) {
                        // Clear canvas and draw new frame
                        ctx.clearRect(0, 0, containerWidth, containerHeight);
                        ctx.drawImage(images[currentFrame], 0, 0);
                        currentFrame++;
                        lastDrawTime = timestamp;
                    } else {
                        mediaRecorder.stop();
                        return;
                    }
                }
                
                requestAnimationFrame(drawFrame);
            };

            // Start animation
            requestAnimationFrame(drawFrame);

        } catch (error) {
            console.error('Error exporting video:', error);
            setIsExporting(false);
        }
    }, [bloxes, generateSvgs, showStepInfo, showText, getFileName, speedMultiplier, createStepInfo]);

    return {
        handleExportGif,
        handleExportImage,
        handleExportMp4: handleExportWebM,
        isExporting
    };
};
