/**
 * Provides functions and custom hooks for fetching, creating, updating, and deleting processes and modules.
 * These hooks interact with the FabuBlox API, handling API calls, caching with react-query,
 * and providing feedback via toast notifications.
 * 
 * @module useFetchProcess
 */

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { Process, CreateProcessRequest, UpdateProcessRequest } from '../../__generated__/Process';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { showToast } from '../..';

/**
 * Fetches a list of processes or modules from the FabuBlox API based on the provided endpoint.
 * Uses an authentication token for secured access.
 * 
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {function} getAccessTokenSilently - Auth0 function to get an access token.
 * @param {boolean} [isModule=false] - Flag indicating whether to fetch modules instead of processes.
 * @returns {Promise<Process[]>} - Array of processes or modules.
 * @throws {Error} If the fetch operation fails.
 */
export async function fetchProcesses(endpoint: string, getAccessTokenSilently: () => Promise<string>, isModule?: boolean): Promise<Process[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'modules' : 'processes'}/${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'modules' : 'processes'} from endpoint: ${endpoint}`);
    }
    return response.json();
}

/**
 * Custom hook to retrieve a list of public processes or modules from the API.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to retrieve modules.
 * @returns {UseQueryResult<Process[], Error>} - The query result, including data and status.
 */
export function usePublicProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'publicModules' : 'publicProcesses', () => fetchProcesses('public', getAccessTokenSilently, isModule));
}

/**
 * Custom hook to retrieve a list of group-shared processes or modules from the API.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to retrieve modules.
 * @returns {UseQueryResult<Process[], Error>} - The query result, including data and status.
 */
export function useGroupProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'groupModules' : 'groupProcesses', () => fetchProcesses('shared', getAccessTokenSilently, isModule));
}

/**
 * Custom hook to retrieve a list of processes or modules owned by the user.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to retrieve modules.
 * @returns {UseQueryResult<Process[], Error>} - The query result, including data and status.
 */
export function useOwnedProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'ownedModules' : 'ownedProcesses', () => fetchProcesses('owned', getAccessTokenSilently, isModule));
}

/**
 * Creates a new process or module via a POST request.
 * 
 * @param {CreateProcessRequest} data - Data for creating a new process or module.
 * @param {function} getAccessTokenSilently - Auth0 function to get an access token.
 * @param {boolean} [isModule=false] - Flag indicating whether to create a module.
 * @returns {Promise<any>} - The response from the server after creating the process or module.
 * @throws {Error} If the creation request fails.
 */
async function fetchCreateProcess(data: CreateProcessRequest, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
    return response.json();
}

/**
 * Reads a specific process or module by its ID.
 * 
 * @param {string} processId - The ID of the process or module to fetch.
 * @param {function} getAccessTokenSilently - Auth0 function to get an access token.
 * @param {boolean} [isModule=false] - Flag indicating whether to fetch a module.
 * @returns {Promise<Process>} - The requested process or module data.
 * @throws {Error} If the fetch request fails or if no processId is provided.
 */
async function fetchReadProcess(processId: string | undefined, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    if (!processId) throw new Error('No processId provided');
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/read/${processId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
    return response.json();
}


/**
 * Updates an existing process or module by ID with new data.
 * 
 * @param {string} processId - The ID of the process or module to update.
 * @param {UpdateProcessRequest} data - The data to update in the process or module.
 * @param {function} getAccessTokenSilently - Auth0 function to get an access token.
 * @param {boolean} [isModule=false] - Flag indicating whether to update a module.
 * @throws {Error} If the update request fails.
 */
async function fetchUpdateProcess(processId: string, data: UpdateProcessRequest, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/update/${processId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
}

/**
 * Deletes a process or module by ID.
 * 
 * @param {string} processId - The ID of the process or module to delete.
 * @param {function} getAccessTokenSilently - Auth0 function to get an access token.
 * @param {boolean} [isModule=false] - Flag indicating whether to delete a module.
 * @throws {Error} If the delete request fails.
 */
async function fetchDeleteProcess(processId: string, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/delete/${processId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
}

/**
 * Custom hook to create a new process or module. Invalidates relevant cache queries upon success.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to create a module.
 * @returns {UseMutationResult} - Mutation result for creating a process or module.
 */
export function useCreateProcess(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    return useMutation(
        (data: CreateProcessRequest) => fetchCreateProcess(data, getAccessTokenSilently, isModule),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

/**
 * Custom hook to update an existing process or module. Optionally performs the update "quietly"
 * without showing success toasts or re-rendering.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to update a module.
 * @param {object} [options] - Options for customizing the update.
 * @param {boolean} [options.quietly=false] - If true, prevents user notifications on success.
 * @returns {UseMutationResult} - Mutation result for updating a process or module.
 */
export function useUpdateProcess(isModule?: boolean, options?: {quietly: boolean}) {
    const { getAccessTokenSilently } = useAuth0();
    const {quietly} = options ?? {quietly: false};
    const queryClient = useQueryClient();
    return useMutation(
        ({ processId, data }: { processId: string; data: UpdateProcessRequest }) => fetchUpdateProcess(processId, data, getAccessTokenSilently, isModule),
        {
            onSuccess: (_data, variables) => {
                if (quietly) return;
                
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                queryClient.invalidateQueries([isModule ? 'module' : 'process', variables.processId])
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

/**
 * Custom hook to delete an existing process or module. Invalidates relevant cache queries upon success.
 * 
 * @param {boolean} [isModule=false] - Flag indicating whether to delete a module.
 * @returns {UseMutationResult} - Mutation result for deleting a process or module.
 */
export function useDeleteProcess(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    return useMutation(
        ({processId}: {processId: string}) => fetchDeleteProcess(processId, getAccessTokenSilently, isModule),
        {
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                queryClient.invalidateQueries([isModule ? 'module' : 'process', variables.processId]);
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

/**
 * Custom hook to retrieve a specific process or module by ID.
 * Caches data and configures fetch options such as retry and stale time.
 * 
 * @param {string} [processId] - The ID of the process or module to fetch.
 * @param {boolean} [isModule=false] - Flag indicating whether to retrieve a module.
 * @returns {UseQueryResult<Process, Error>} - The query result, including data and status.
 */
export function useReadProcess(processId?: string, isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();

    const isEnabled = processId !== undefined && processId !== '' && processId !== 'new' && processId !== 'create';

    return useQuery<Process, Error>({
        queryKey: [isModule ? 'module' : 'process', processId],
        queryFn: () => fetchReadProcess(processId, getAccessTokenSilently, isModule),
            enabled: isEnabled,
            staleTime: 1000 * 5, // 5 seconds
            retry: false,
            meta: { 
                errorMessage: `Failed to fetch ${isModule ? 'module' : 'process'}: ${processId}`
            }
        }
    );
}
