/**
 * @fileoverview Utility functions and hooks for managing scroll behavior and grid layout in the process table view.
 * This module provides essential functionality for handling table scrolling, pagination, and grid resizing.
 * 
 * Key Features:
 * - Debounced resize handling
 * - Smooth scrolling to sections and rows
 * - Pagination-aware scroll operations
 * - Section tracking during scroll
 * - Grid layout optimization
 * - View switch handling
 * 
 * The utilities in this file work together to provide a seamless scrolling experience:
 * 1. ResizeObserver utilities handle grid layout updates
 * 2. Scroll utilities manage smooth scrolling to sections and rows
 * 3. Hooks provide reactive scroll behavior and cleanup
 * 
 * @module processTableViewScrollUtils
 */

import React, { useEffect, useRef } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { ProcessTableRow } from '../../TableViewComponents/types/ProcessTableTypes';
import { Section } from '../../../__generated__/Process';

/**
 * Debounces a function call to prevent excessive executions
 * Useful for handling resize events and other frequent updates
 * 
 * @template T - Type of the function to debounce
 * @param {T} fn - Function to debounce
 * @param {number} [ms=0] - Debounce delay in milliseconds
 * @returns {(...args: Parameters<T>) => void} Debounced function
 */
export const debounce = <T extends (...args: any[]) => any>(fn: T, ms = 0) => {
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

/**
 * Handles ResizeObserver errors and updates grid layout
 * Implements a debounced approach to prevent layout thrashing
 * 
 * This function:
 * 1. Checks for valid grid root element
 * 2. Verifies dimensions are positive
 * 3. Forces a reflow to fix layout issues
 * 
 * @param {ResizeObserverEntry[]} entries - ResizeObserver entries containing dimension information
 */
export const handleResizeObserverError = debounce((entries: ResizeObserverEntry[]) => {
  const gridRoot = document.querySelector('.MuiDataGrid-root') as HTMLDivElement | null;
  if (gridRoot && entries && entries[0]) {
    const { width, height } = entries[0].contentRect;
    if (width > 0 && height > 0) {
      // Update the grid's internal layout
      const virtualScroller = gridRoot.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        requestAnimationFrame(() => {
          (virtualScroller as HTMLElement).style.height = `${height}px`;
          // Dispatch a resize event to trigger the grid's internal layout update
          window.dispatchEvent(new Event('resize'));
        });
      }
    }
  }
}, 100);

/**
 * Custom hook to handle ResizeObserver setup and cleanup
 * Automatically manages the lifecycle of the ResizeObserver
 * 
 * This hook:
 * 1. Creates a ResizeObserver instance
 * 2. Attaches it to the grid root element
 * 3. Handles cleanup on unmount
 */
export const useResizeObserver = () => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      try {
        handleResizeObserverError(entries);
      } catch (e) {
        console.warn('ResizeObserver error:', e);
      }
    });

    const gridRoot = document.querySelector('.MuiDataGrid-root') as HTMLDivElement | null;
    if (gridRoot) {
      resizeObserver.observe(gridRoot);
    }

    return () => {
      if (gridRoot) {
        resizeObserver.unobserve(gridRoot);
      }
      resizeObserver.disconnect();
    };
  }, []);
};

/**
 * Parameters required for scrolling to a specific section in the table
 * This interface encapsulates all the necessary dependencies for section scrolling
 * 
 * @interface ScrollToSectionParams
 * @property {string} sectionId - Unique identifier of the target section
 * @property {ProcessTableRow[]} allRows - Complete array of table rows including section headers
 * @property {GridPaginationModel} paginationModel - Current pagination state
 * @property {function} setPaginationModel - Function to update pagination state
 * @property {React.MutableRefObject<any>} apiRef - Reference to the MUI DataGrid API
 * @property {function} setCurrentSection - Function to update the active section
 * @property {React.MutableRefObject<string | null>} pendingScrollRef - Tracks pending scroll operations
 * @property {React.MutableRefObject<boolean>} isViewSwitchRef - Tracks view switching state
 */
export interface ScrollToSectionParams {
  /** ID of the section to scroll to */
  sectionId: string;
  /** Array of all rows in the table */
  allRows: ProcessTableRow[];
  /** Current pagination model */
  paginationModel: GridPaginationModel;
  /** Function to update pagination model */
  setPaginationModel: (model: GridPaginationModel) => void;
  /** Reference to the grid API */
  apiRef: React.MutableRefObject<any>;
  /** Function to update current section */
  setCurrentSection: (sectionId: string) => void;
  /** Reference to track pending scroll operations */
  pendingScrollRef: React.MutableRefObject<string | null>;
  /** Reference to track view switching state */
  isViewSwitchRef: React.MutableRefObject<boolean>;
}

/**
 * Scrolls the table view to a specific section
 * Handles pagination changes and ensures smooth scrolling behavior
 * 
 * This function:
 * 1. Updates the current section
 * 2. Calculates the target row index
 * 3. Determines if a page change is needed
 * 4. Either:
 *    - Updates pagination and queues scroll operation
 *    - Or scrolls immediately if already on correct page
 * 
 * @param {ScrollToSectionParams} params - Object containing all required parameters
 */
export const scrollToSection = ({
  sectionId,
  allRows,
  paginationModel,
  setPaginationModel,
  apiRef,
  setCurrentSection,
  pendingScrollRef,
  isViewSwitchRef
}: ScrollToSectionParams) => {
  setCurrentSection(sectionId);
  
  const targetRowIndex = allRows.findIndex(
    (row) => row.isSectionHeader && row.id === sectionId
  );
    
  if (targetRowIndex >= 0) {
    const { pageSize, page } = paginationModel;
    const targetPage = Math.floor(targetRowIndex / pageSize);
        
    if (targetPage !== page) {
      // Need to switch pages first, then scroll after the page is rendered
      pendingScrollRef.current = sectionId;
      setPaginationModel({
        page: targetPage,
        pageSize: paginationModel.pageSize
      });
    } else {
      // Already on the correct page, scroll immediately
      scrollRowToTop(sectionId, apiRef, allRows);
    }
  }
};

/**
 * Handles pending scroll operations after pagination changes
 * Executes queued scroll operations in the next animation frame
 * 
 * This function:
 * 1. Checks for pending scroll operations
 * 2. Clears the pending reference
 * 3. Schedules the scroll operation for the next frame
 * 
 * @param {React.MutableRefObject<string | null>} pendingScrollRef - Reference to the pending scroll ID
 * @param {React.MutableRefObject<any>} apiRef - Reference to the grid API
 * @param {ProcessTableRow[]} allRows - Array of all table rows
 */
export const handlePendingScroll = (
  pendingScrollRef: React.MutableRefObject<string | null>,
  apiRef: React.MutableRefObject<any>,
  allRows: ProcessTableRow[]
) => {
  if (pendingScrollRef.current && apiRef.current) {
    const rowId = pendingScrollRef.current;
    pendingScrollRef.current = null;
    
    requestAnimationFrame(() => {
      scrollRowToTop(rowId, apiRef, allRows);
    });
  }
};

/**
 * Scrolls the table to a selected blox item
 * Handles section identification and pagination-aware scrolling
 * 
 * This function:
 * 1. Validates input parameters and conditions
 * 2. Locates the target row and section
 * 3. Updates current section
 * 4. Handles pagination changes if needed
 * 5. Initiates scroll operation
 * 
 * @param {string | null} selectedBloxId - ID of the selected blox
 * @param {React.MutableRefObject<any>} apiRef - Reference to the grid API
 * @param {React.MutableRefObject<boolean>} isViewSwitchRef - Reference to view switching state
 * @param {ProcessTableRow[]} allRows - Array of all table rows
 * @param {Section[]} processSections - Array of all process sections
 * @param {function} setCurrentSection - Function to update current section
 * @param {function} scrollToSectionFn - Function to handle section scrolling
 * @param {GridPaginationModel} paginationModel - Current pagination model
 * @param {function} setPaginationModel - Function to update pagination model
 * @param {React.MutableRefObject<string | null>} pendingScrollRef - Reference to pending scroll operations
 */
export const scrollToSelectedBlox = (
  selectedBloxId: string | null,
  apiRef: React.MutableRefObject<any>,
  isViewSwitchRef: React.MutableRefObject<boolean>,
  allRows: ProcessTableRow[],
  processSections: Section[],
  setCurrentSection: (sectionId: string) => void,
  scrollToSectionFn: (params: ScrollToSectionParams) => void,
  paginationModel: GridPaginationModel,
  setPaginationModel: (model: GridPaginationModel) => void,
  pendingScrollRef: React.MutableRefObject<string | null>
) => {
  // Only proceed if we have a selected blox, API is ready, and we're in a view switch
  if (!selectedBloxId || !apiRef.current || !isViewSwitchRef.current) return;

  const rowIndex = allRows.findIndex((row) => row.id === selectedBloxId);
  if (rowIndex === -1) return;

  // Find which section this belongs to
  const section = processSections.find((sec) => sec.bloxIds.includes(selectedBloxId));
  if (section?.sectionId) {
    setCurrentSection(section.sectionId);
    
    // Calculate target page
    const targetPage = Math.floor(rowIndex / paginationModel.pageSize);
    
    // If we're not on the correct page, update pagination and queue the scroll
    if (targetPage !== paginationModel.page) {
      pendingScrollRef.current = selectedBloxId;
      setPaginationModel({
        page: targetPage,
        pageSize: paginationModel.pageSize
      });
    } else {
      // If we're already on the correct page, scroll immediately to the blox
      scrollRowToTop(selectedBloxId, apiRef, allRows);
    }
  }
};

/**
 * Maximum number of retries for attaching scroll listener
 */
const MAX_RETRIES = 5;
const RETRY_DELAY = 500; // ms

/**
 * Custom hook to handle table scrolling behavior with retry mechanism
 * Sets up scroll event listeners and manages section tracking
 * 
 * @param {ProcessTableRow[]} allRows - Array of all table rows
 * @param {string} currentSection - Current active section ID
 * @param {function} setCurrentSection - Function to update current section
 */
export const useTableScroll = (
  allRows: ProcessTableRow[],
  currentSection: string,
  setCurrentSection: (sectionId: string) => void
) => {
  useEffect(() => {
    let retryCount = 0;
    let retryTimeout: NodeJS.Timeout;
    let isCleanedUp = false;

    const attachScrollListener = () => {
      const virtualScroller = document.querySelector('.MuiDataGrid-virtualScroller');
      
      if (virtualScroller && !isCleanedUp) {
        const handleScroll = () => handleTableScroll(allRows, currentSection, setCurrentSection);
        
        // Initial check
        handleScroll();
        
        virtualScroller.addEventListener('scroll', handleScroll);
        return () => {
          virtualScroller.removeEventListener('scroll', handleScroll);
        };
      } else if (retryCount < MAX_RETRIES && !isCleanedUp) {
        // Retry after delay
        retryCount++;
        retryTimeout = setTimeout(attachScrollListener, RETRY_DELAY);
        console.warn(`Virtual scroller not found, retrying... (${retryCount}/${MAX_RETRIES})`);
      } else if (!isCleanedUp) {
        console.error('Failed to attach scroll listener after max retries');
      }
    };

    const cleanup = attachScrollListener();

    // Cleanup function
    return () => {
      isCleanedUp = true;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
      if (cleanup) {
        cleanup();
      }
    };
  }, [currentSection, allRows, setCurrentSection]);
};

/**
 * Scrolls a specific row to the top of the table view
 * Handles row location and smooth scrolling behavior
 * 
 * This function:
 * 1. Locates the target row
 * 2. Validates row existence
 * 3. Calculates scroll position
 * 4. Performs smooth scroll operation
 * 
 * @param {string} rowId - ID of the row to scroll to
 * @param {React.MutableRefObject<any>} apiRef - Reference to the grid API
 * @param {ProcessTableRow[]} allRows - Array of all table rows
 */
export const scrollRowToTop = (
  rowId: string,
  apiRef: React.MutableRefObject<any>,
  allRows: ProcessTableRow[]
) => {
  // First ensure we can get the row index
  const rowIndex = allRows.findIndex(row => row.id === rowId);
  if (rowIndex === -1) {
    console.warn('Could not find row with id:', rowId);
    return;
  }

  // Get the grid root and virtualScroller
  const gridRoot = document.querySelector('.MuiDataGrid-root');
  const virtualScroller = gridRoot?.querySelector('.MuiDataGrid-virtualScroller');
  if (!virtualScroller) {
    console.warn('Could not find virtual scroller');
    return;
  }

  // Get the custom TableViewHeader height
  const navHeader = document.querySelector('[data-testid="table-view-header"]');
  const navHeaderHeight = navHeader?.getBoundingClientRect().height ?? 0;

  // Get the column headers height
  // const columnHeaders = gridRoot?.querySelector('.MuiDataGrid-columnHeaders');

  // Total offset we want to leave at the top (just the nav header height)
  // Adding a small negative offset (-#px) to pull the section header up slightly
  const tableViewOffset = -20;
  const totalOffset = navHeaderHeight - tableViewOffset;

  // First scroll to make the row visible
  apiRef.current.scrollToIndexes({
    rowIndex,
    align: 'start',
  });

  // Then use RAF to ensure the DOM has updated
  requestAnimationFrame(() => {
    // Get the row element after scrolling
    const rowEl = apiRef.current.getRowElement(rowId);
    if (!rowEl) {
      console.warn('Could not find row element for id:', rowId);
      return;
    }

    // Calculate the current position relative to the virtualScroller
    const rowRect = rowEl.getBoundingClientRect();
    const scrollerRect = virtualScroller.getBoundingClientRect();
    const currentOffset = rowRect.top - scrollerRect.top;

    // If the row is not at the top (accounting for just the nav header),
    // adjust the scroll position
    if (Math.abs(currentOffset - totalOffset) > 1) {
      const adjustment = currentOffset - totalOffset;
      virtualScroller.scrollTop += adjustment;
    }

    // Force a grid update
    apiRef.current.publishEvent('rowsScroll');
  });
};

/**
 * Enhanced scroll handler with error boundary
 */
export const handleTableScroll = (
  allRows: ProcessTableRow[],
  currentSection: string,
  setCurrentSection: (sectionId: string) => void
) => {
  try {
    requestAnimationFrame(() => {
      const gridRoot = document.querySelector('.MuiDataGrid-root');
      if (!gridRoot) {
        console.warn('Grid root not found during scroll');
        return;
      }

      const columnHeaders = gridRoot.querySelector('.MuiDataGrid-columnHeaders');
      if (!columnHeaders) {
        console.warn('Column headers not found during scroll');
        return;
      }

      const headerHeight = columnHeaders.getBoundingClientRect().height;
      const gridRect = gridRoot.getBoundingClientRect();
      const visibleTop = gridRect.top + headerHeight;

      const visibleRows = document.querySelectorAll('.MuiDataGrid-row');
      if (visibleRows.length === 0) {
        console.warn('No visible rows found during scroll');
        return;
      }

      // Find the first visible row that's at least 50% visible below the header
      let firstVisibleRow: Element | undefined;
      for (const row of visibleRows) {
        const rowRect = row.getBoundingClientRect();
        const rowVisibleHeight = Math.min(rowRect.bottom, gridRect.bottom) - Math.max(rowRect.top, visibleTop);
        const rowTotalHeight = rowRect.height;
        const visibilityRatio = rowVisibleHeight / rowTotalHeight;
        
        // Row must be below header and at least 50% visible
        if (rowRect.top >= visibleTop - (rowRect.height / 2) && visibilityRatio > 0.5) {
          firstVisibleRow = row;
          break;
        }
      }

      if (!firstVisibleRow) {
        console.warn('No visible row found meeting visibility criteria');
        return;
      }

      const rowId = firstVisibleRow.getAttribute('data-id');
      if (!rowId) {
        console.warn('Row ID not found on visible row');
        return;
      }

      // Find the row data
      const rowData = allRows.find(row => row.id === rowId);
      if (!rowData) {
        console.warn('Row data not found for ID:', rowId);
        return;
      }

      // If it's a section header
      if (rowData.isSectionHeader) {
        if (rowId !== currentSection) {
          setCurrentSection(rowId);
        }
        return;
      }

      // If it's a normal row, find which section it belongs to
      const rowIndex = allRows.findIndex((row) => row.id === rowId);
      if (rowIndex === -1) {
        console.warn('Row index not found for ID:', rowId);
        return;
      }

      for (let i = rowIndex; i >= 0; i--) {
        const row = allRows[i];
        if (row.isSectionHeader) {
          if (row.id !== currentSection) {
            setCurrentSection(row.id);
          }
          break;
        }
      }
    });
  } catch (error) {
    console.error('Error during table scroll handling:', error);
  }
}; 