import { createTheme } from '@mui/material/styles';
// import { Palette, PaletteOptions } from '@mui/material/styles/createPalette';

// Extend the Palette and PaletteOptions interfaces
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    lightblue: Palette['primary'];
  }
  interface PaletteOptions {
    lightblue?: PaletteOptions['primary'];
  }
}

export const fabutheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      // main: '#4285F4', // Google Blue
      main: '#8E4CA8', // FabuBlox Purple (gradient start)
      light: '#d35299', // FabuBlox Pink (gradient middle)
      dark: '#6b3880', // Darker purple for hover states
    },
    secondary: {
      // main: '#1A73E8', // Darker Google Blue
      main: '#ff9567', // FabuBlox Orange (gradient end)
      light: '#ffa883', // Lighter orange
      dark: '#e67d53', // Darker orange for hover states
    },
    error: {
      main: '#EA4335', // Google Red
    },
    warning: {
      main: '#FBBC04', // Google Yellow
    },
    info: {
      main: '#DEE2FB', // Light Purple
    },
    lightblue: {
      main: '#DEE2FB',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: 'linear-gradient(90deg, #8E4CA8 0%, #d35299 50%, #ff9567 100%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(90deg, #6b3880 0%, #b33d7e 50%, #e67d53 100%)',
          },
        },
        outlined: {
          borderColor: '#8E4CA8',
          color: '#8E4CA8',
          '&:hover': {
            borderColor: '#6b3880',
            color: '#6b3880',
            backgroundColor: `rgba(var(--gradient-start-rgb), 0.04)`,
          },
        },
      },
    },
  },
});