import {
  Button,
  Classes,
  Dialog,
  Intent,
  Tag,
} from "@blueprintjs/core";
import { BloxRef } from "../../hooks/material-targets";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { getBloxRefDisplayString } from "./hooks/use-material-prompts";

interface MaterialInfoDialogProps {
  isOpen: boolean;
  currentBlox: AllBloxes;
  materialName: string;
  bloxRefs: BloxRef[];
  onClose: () => void;
}

export default function MaterialInfoDialog({
  isOpen,
  currentBlox,
  materialName,
  bloxRefs,
  onClose,
}: MaterialInfoDialogProps) {
  const currentBloxId = currentBlox.id;
  const [processSections] = useFabuState("processSections");

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Material Information">
      <div className={Classes.DIALOG_BODY} style={{ maxWidth: "40rem" }}>
        <p style={{ fontWeight: 600 }}>Material Usage Information</p>
        <p style={{ marginBottom: "1em" }}>
          The material <Tag intent={Intent.PRIMARY}>{materialName}</Tag> is used in the following process steps:
        </p>
        
        <div
          style={{
            maxHeight: "250px",
            overflowY: "auto",
            border: "1px solid #ccc",
            padding: "0.5em",
            borderRadius: "4px",
          }}
        >
          {processSections.map((section) => {
            // Filter the bloxRefs that belong to this section.
            const sectionBloxRefs = bloxRefs.filter((bloxRef) =>
              section.bloxIds.includes(bloxRef.bloxId)
            ) || [];
            
            // Only render this section if there are any entries.
            if (sectionBloxRefs.length === 0) {
              return null;
            }
            
            return (
              <div key={section.sectionId} style={{ marginBottom: "1em" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    padding: "0.5em",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                  }}
                >
                  {section.sectionName || `Section ${section.sectionId}`}
                </div>
                <ul style={{ listStyle: "none", padding: "0.5em 0 0 0", margin: 0 }}>
                  {sectionBloxRefs.map((bloxRef) => (
                    <li key={bloxRef.bloxId} style={{ marginBottom: "0.5em" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                        <span style={{ fontWeight: bloxRef.bloxId === currentBloxId ? "bold" : "normal" }}>
                            {getBloxRefDisplayString(bloxRef.stepNumber, bloxRef.bloxName)}
                        </span>
                        <span className={`${Classes.TEXT_MUTED} ${Classes.TEXT_SMALL}`}>
                          {bloxRef.isTarget ? "Target" : "Source"}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
        <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ marginTop: "1em" }}>
          <Button intent={Intent.PRIMARY} onClick={onClose}>Close</Button>
      </div>
      </div>
      
      
    </Dialog>
  );
}
