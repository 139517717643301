import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { getUserDisplayName } from '../../utils/user-display';
import type { User } from '../../__generated__/User';

interface UserData {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  affiliation?: string;
  experience?: string;
}

export const ReadOnlyUserElements: React.FC<{ userData: UserData | null | undefined }> = ({ userData }) => {
  return (
    <>
      <Box className="tw-flex tw-items-center tw-justify-center tw-gap-2">
        <Typography variant="h5" className="tw-font-bold tw-mb-1">
          {getUserDisplayName(userData)}
        </Typography>
      </Box>
      <Box className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-flex-wrap -tw-mt-1">
        {userData?.displayName && (
          <Typography variant="body2" color="primary" className="tw-text-[#8E4CA8]">
            @{userData.displayName}
          </Typography>
        )}
        {userData?.affiliation && (
          <>
            <Typography variant="body2" color="textSecondary" className="tw-hidden sm:tw-block">
              •
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {userData.affiliation}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

interface EditingUserElementsProps {
  editedUser: Pick<User, 'firstName' | 'lastName' | 'displayName' | 'affiliation' | 'experience' | 'profilePicture'>;
  setEditedUser: React.Dispatch<React.SetStateAction<Pick<User, 'firstName' | 'lastName' | 'displayName' | 'affiliation' | 'experience' | 'profilePicture'> | null>>;
}

export const EditingUserElements: React.FC<EditingUserElementsProps> = ({
  editedUser,
  setEditedUser,
}) => {

  // Removing ability to change username for now
  // const [displayNameError, setDisplayNameError] = useState<string>('');

  // useEffect(() => {
  //   if (!editedUser.displayName) {
  //     setDisplayNameError('Display name is required');
  //     return;
  //   }
    
  //   if (editedUser.displayName.length < 3) {
  //     setDisplayNameError('Display name must be at least 3 characters');
  //     return;
  //   }
    
  //   if (!/^[a-zA-Z0-9]+$/.test(editedUser.displayName)) {
  //     setDisplayNameError('Display name can only contain letters and numbers');
  //     return;
  //   }
    
  //   setDisplayNameError('');
  // }, [editedUser.displayName]);

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '');
    
    setEditedUser((prev) => prev ? {
      ...prev,
      displayName: alphanumericValue
    } : null);
  };

  return (
    <Box className="tw-space-y-4">
      <Box className="tw-flex tw-gap-2">
        <TextField
          fullWidth
          label="First Name"
          value={editedUser.firstName}
          color="secondary"
          onChange={(e) =>
            setEditedUser((prev) => prev ? {
              ...prev,
              firstName: e.target.value
            } : null)
          }
          size="small"
          className="focus:tw-outline-[#8E4CA8] focus:tw-outline focus:tw-outline-2"
        />
        <TextField
          fullWidth
          label="Last Name"
          value={editedUser.lastName}
          color="secondary"
          onChange={(e) =>
            setEditedUser((prev) => prev ? {
              ...prev,
              lastName: e.target.value
            } : null)
          }
          size="small"
        />
      </Box>
      <TextField
        required
        fullWidth
        disabled={true}
        label="Display Name"
        value={editedUser.displayName}
        color="secondary"
        onChange={handleDisplayNameChange}
        size="small"
        helperText={"Username cannot be changed"}
        InputProps={{
          startAdornment: <span className="tw-text-gray-400 tw-mr-1">@</span>,
        }}
      />
      <TextField
        fullWidth
        label="Affiliation / Institution"
        value={editedUser.affiliation}
        color="secondary"
        onChange={(e) =>
          setEditedUser((prev) => prev ? {
            ...prev,
            affiliation: e.target.value
          } : null)
        }
        size="small"
      />
    </Box>
  );
}; 