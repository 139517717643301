// hooks/useMaterialHandlers.ts
import { useMemo, useCallback } from "react";
import { AllBloxes, BloxTypes, LayerProperties } from "../../../Data/BloxSchema/base-blox";
import { getMaterialUnknownLabel } from "../../../Data/material-mappings";
import { BloxRef, getMaterials, replaceSpecificBloxMaterial, getBloxRefByMaterial } from "../../../hooks/material-targets";
import { useFabuState } from "../../../hooks/state/use-fabu-state";
import { IColor } from "../../../utils/Color";
import { checkIfResist } from "../../../Data/BloxSchema/spin-coat-unified";

export interface SelectedBloxMaterialProperties {
  bloxId: string;
  property: string;
  propertyLabel: string;
  layerIndex?: number;
}

export interface MultipleReferencePayload {
  newMaterialName: string;
  previousMaterialName: string;
  newColor: IColor;
  bloxRefs: BloxRef[];
}

/**
 * The result of calling prepareMaterialUpdate:
 * - "duplicate": user tried to rename or create a material that already exists
 * - "multi": the old material is used by multiple steps, prompting a multi-reference warning
 * - "single": safe to directly finalize an update for a single reference
 */
export type PrepareUpdateResult =
  | { type: "duplicate"; name: string }
  | { type: "warn"; payload: MultipleReferencePayload }
  | { type: "update"; oldName: string; newName: string; color: IColor }
/**
 * This hook only manages the material retrieval and update logic,
 * without any UI/dialog responsibilities.
 */
export function useMaterialHandlers(
  selectedBlox: AllBloxes,
  selectedBloxMaterialProperties: SelectedBloxMaterialProperties
) {
  const [processBloxes, setProcessBloxes] = useFabuState("processBloxes");

  // ---------------------------------------------
  // 1) Build the list of materials for the process
  // ---------------------------------------------
  const materials = useMemo(() => {
    let filterMode = "ALL";
    if (selectedBlox.bloxType === BloxTypes.SpinCoatResist) {
      filterMode = "ONLY_RESIST";
    }  else if (selectedBlox.bloxType === BloxTypes.SpinCoatUnified) {
      if (selectedBlox.spunMaterialType && checkIfResist(selectedBlox.spunMaterialType)) {
        filterMode = "ONLY_RESIST";
      }
    } else {
      filterMode = "NOT_RESIST";
    }
    return getMaterials(processBloxes, processBloxes.length - 1, filterMode);
  }, [processBloxes, selectedBlox]);

  // ---------------------------------------------
  // 2) Determine the current material name
  // ---------------------------------------------
  const currentMaterialName = useMemo(() => {
    const targetBlox = processBloxes.find((blox) => blox.id === selectedBlox.id);
    if (!targetBlox) return "";

    let value = targetBlox[selectedBloxMaterialProperties.property as keyof AllBloxes] ?? "";

    if (Array.isArray(value) && selectedBloxMaterialProperties.layerIndex != null) {
      const layer = value[selectedBloxMaterialProperties.layerIndex] as LayerProperties;
      value = layer.layerLabel ?? "";
    }
    if (value === "") {
      return getMaterialUnknownLabel(selectedBlox.bloxType, selectedBloxMaterialProperties.property);
    }
    return value as string;
  }, [processBloxes, selectedBlox, selectedBloxMaterialProperties]);
  // ---------------------------------------------
  // 3) Check for duplicates, multiple references, etc.
  // ---------------------------------------------
  const prepareMaterialUpdate = useCallback(
    (newMaterialName: string, oldMaterialName: string, materialIdxToUpdate: number, newColor: IColor): PrepareUpdateResult => {
      // 1) Check if this material has been used somewhere else
      // we want to allow updating just the color if its the same material (e.g. the madterialIdxToUpdate matches)
      const alreadyUsed = materials.find(
        (m, idx) => {
          return materialIdxToUpdate !== idx && m.materialLabel === newMaterialName;
        }
      );
      if (alreadyUsed) {
        // Return a "duplicate" result
        return { type: "duplicate", name: newMaterialName };
      }

      // 2) Find old material object
      const oldMatObj = materials.find((m) => m.materialLabel === oldMaterialName);
      // If not found, treat it as single reference (we can just do a local update)
      if (!oldMatObj) {
        return { type: "update", oldName: oldMaterialName, newName: newMaterialName, color: newColor };
      }

      // 3) Count how many steps source the old material
      const bloxRefs = getBloxRefByMaterial(processBloxes, oldMatObj);
      const countDepositions = bloxRefs.reduce((acc, ref) => (!ref.isTarget ? acc + ref.count : acc), 0);

      if (countDepositions > 1) {
        // 4) If we're just changing the color of the material, we can skip the warning dialog as we'll only allow updating all sources/targets
        if (oldMaterialName === newMaterialName && newColor) {
          return { type: "update", oldName: oldMaterialName, newName: newMaterialName, color: newColor };
        }

        // Return a "multi" result
        return {
          type: "warn",
          payload: {
            newMaterialName,
            previousMaterialName: oldMaterialName,
            newColor,
            bloxRefs,
          },
        };
      }
      // Single reference: no warning needed
      return { type: "update", oldName: oldMaterialName, newName: newMaterialName, color: newColor };
    },
    [materials, processBloxes]
  );

  // ---------------------------------------------
  // 4) Finalize a material update (single step or all references)
  // ---------------------------------------------
  const finalizeMaterialUpdate = useCallback(
    (
      oldMaterialName: string,
      newMaterialName: string,
      color: IColor,
      selectedUpdateSteps?: BloxRef[]
    ) => {
      const updated = processBloxes.map(blox => {
        // If we have selected steps but this blox isn't one of them, do nothing.
        if (
          selectedUpdateSteps &&
          !selectedUpdateSteps.some(step => step.bloxId === blox.id)
        ) {
          return blox;
        }

        // Otherwise, replace the material.
        return replaceSpecificBloxMaterial(
          blox,
          selectedBloxMaterialProperties,
          newMaterialName,
          oldMaterialName,
          color
        );
      });

      setProcessBloxes(updated);
    },
    [processBloxes, setProcessBloxes, selectedBloxMaterialProperties]
  );



  // ---------------------------------------------
  // Return all relevant data & methods
  // ---------------------------------------------
  return {
    materials,
    currentMaterialName,
    prepareMaterialUpdate,
    finalizeMaterialUpdate,
  };
}
