/**
 * @fileoverview Provides React hooks for managing user data operations in the Fabublox application.
 * These hooks handle reading and updating user data with built-in authentication and error handling.
 * The module integrates with Auth0 for secure user authentication and React Query for efficient
 * data fetching and caching.
 */

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User, UpdateUserRequest } from '../../__generated__/User';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { showToast } from '../..';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';

/**
 * Fetches user data from the API with authentication.
 * 
 * @param {string | undefined} userId - The ID of the user to fetch
 * @param {() => Promise<string>} getAccessTokenSilently - Auth0 function to get access token
 * @returns {Promise<User>} The user data
 * @throws {Error} If userId is undefined or if the fetch request fails
 * 
 * @remarks
 * This function performs a secure fetch of user data using Auth0 authentication.
 * It will throw an error if:
 * - The userId is undefined (user not authenticated)
 * - The API request fails
 * - The authentication token cannot be obtained
 */
async function readUser(userId: string | undefined, getAccessTokenSilently: () => Promise<string>): Promise<User> {
    if (!userId) {
        throw new Error('User ID is undefined');
    }
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/user/read/${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to read user: ${userId}`);
    }
    return response.json();
}

/**
 * Hook to fetch user data.
 * Provides a React Query wrapper around the readUser function with automatic
 * caching and revalidation.
 * 
 * @param {string | undefined} userId - The ID of the user to fetch
 * @returns {UseQueryResult<User, Error>} Query result containing user data
 */
export function useReadUser(userId: string | undefined) {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery<User, Error>(
        ['user', userId],
        () => readUser(userId, getAccessTokenSilently),
        {
            enabled: !!userId, // This line ensures the query will not run if userId is undefined
        }
    );
}

/**
 * Updates user data in the API.
 * 
 * @param {string} userId - The ID of the user to update
 * @param {UpdateUserRequest} data - The new user data
 * @param {() => Promise<string>} getAccessTokenSilently - Auth0 function to get access token
 * @throws {Error} If the update request fails
 * 
 * @remarks
 * This function performs a secure update of user data with the following characteristics:
 * - Requires valid authentication token
 * - Validates user ID
 * - Handles API errors with descriptive messages
 */
async function updateUser(
  userId: string,
  data: UpdateUserRequest,
  getAccessTokenSilently: () => Promise<string>
) {
  const token = await getAccessTokenSilently();

  try {
    const response = await fetch(
      `${BASE_FABUBLOX_API_URL}/api/user/update/${userId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    // If fetch() didn't throw, check the status code
    if (!response.ok) {
      throw new Error(`Failed to update user: ${userId}, status: ${response.status}`);
    }

    return response.json();
  } catch (err) {
    // If fetch() throws (network error, CORS issue, blocked request, etc.)
    // we rethrow so the mutation sees a rejection and calls onError.
    throw new Error(`Network error updating user ${userId}: ${err}`);
  }
}

/**
 * Hook to update user data.
 * Provides mutation functionality with automatic cache invalidation and error handling.
 * 
 * @returns {UseMutationResult} Mutation result for updating user data
 */
export function useUpdateUser() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ userId, data }: { userId: string; data: UpdateUserRequest }) => {
      return updateUser(userId, data, getAccessTokenSilently);
    },
    {
      onSuccess: (_data, { userId }) => {
        queryClient.invalidateQueries(['user', userId]);
      },
      onError: (error: any) => {
        showToast({
          message: error instanceof Error ? error.message : 'An error occurred',
          intent: Intent.DANGER,
          timeout: 3000,
        });
        console.error(`Update failed: ${error}`);
      },
    }
  );
}
