/**
 * @fileoverview SVG generation utility component for the IFrame application.
 * This component handles the creation of SVG representations for process blocks (bloxes)
 * using the SVG engine. It serves as an intermediary between the process data and 
 * visual representation, generating optimized SVG elements that can be rendered
 * throughout the application. It's a utility component with no visual output.
 */

import React, { useEffect } from 'react';
import { SVGDisplayMode } from "../../Services/SVGEngine";
import { useIFrameProcess } from './IFrameProcessContext';

/**
 * Props for IFrameSvgGenerator component
 * Now simplified since we're using context for process data
 */
interface IFrameSvgGeneratorProps {
  generateSvgs: (bloxes: any[], arg2: boolean, mode: SVGDisplayMode) => React.ReactElement[];
  onSvgsGenerated: (svgs: React.ReactElement[]) => void;
}

/**
 * Component that handles SVG generation for process bloxes
 * Now uses IFrameProcessContext for process data
 */
export function IFrameSvgGenerator({
  generateSvgs,
  onSvgsGenerated
}: IFrameSvgGeneratorProps) {
  // Get process data and loading state from context
  const { state } = useIFrameProcess();
  const { processBloxes, isLoading } = state;

  useEffect(() => {
    if (!processBloxes?.length || isLoading) return;

    // Using setTimeout to ensure this happens after render
    const timer = setTimeout(() => {
      try {
        const svgs = generateSvgs(processBloxes, false, SVGDisplayMode.RawSVG);
        if (Array.isArray(svgs)) {
          onSvgsGenerated(svgs);
        }
      } catch (e) {
        // No console.error needed here
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [processBloxes, isLoading, generateSvgs, onSvgsGenerated]);

  // No rendering needed - this is a utility component
  return null;
} 