/**
 * @fileoverview ProcessListContainer is a versatile component that renders individual items within
 * the ProcessListView. It handles both section headers and regular items with different visual
 * representations and interaction patterns.
 * 
 * Key Features:
 * - Dual rendering modes (header/item)
 * - Interactive section headers
 * - Selection state management
 * - Custom content rendering
 * - Accessibility support
 * 
 * Component Hierarchy:
 * ProcessListViewContent
 * └─ ProcessListContainer (This Component)
 *    ├─ Section Header Variant
 *    │  ├─ Collapse Controls (ExpandMore/ChevronRight icons)
 *    │  └─ Section Information (Title + Optional Subtitle)
 *    └─ Regular Item Variant
 *       ├─ Custom Render Content (When renderItem provided)
 *       └─ Default Content
 *          ├─ Primary Text
 *          └─ Secondary Text
 * 
 * Note: While this component provides the container and basic item rendering,
 * specialized item rendering (like process blocks) is handled by BloxListItem
 * when used within ProcessEditor.
 * 
 * State Management:
 * - Manages item selection state
 * - Controls section collapse state
 * - Handles interaction events
 * 
 * Accessibility:
 * - ARIA roles (button/option)
 * - ARIA states (selected/expanded)
 * - Keyboard interaction
 * - Focus management
 * 
 * @component
 * @template T - The type of items being rendered
 */

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { listItemStyles } from './styles/ProcessListView.styles';

/**
 * Props interface for the ProcessListContainer component.
 * Defines the complete set of properties required for item rendering.
 * 
 * @interface ProcessListContainerProps
 * @template T - The type of items being rendered
 * 
 * @property {T} item - The item to be rendered
 * @property {boolean} isHeader - Whether this item is a section header
 * @property {boolean} isOpen - Whether the section is expanded (for headers)
 * @property {string} sectionId - Identifier for the section this item belongs to
 * @property {React.Key} itemKey - Unique key for the item
 * @property {boolean} isSelected - Whether this item is currently selected
 * @property {(item: T) => string} getItemPrimaryText - Required function to get item's primary text unless renderItem is provided
 * @property {(item: T) => string} [getItemSecondaryText] - Optional function to get item's secondary text
 * @property {(item: T) => React.ReactNode} [renderItem] - Optional custom render function
 * @property {(sectionId: string) => void} onSectionHeaderToggle - Callback when section header is toggled
 * @property {(item: T) => void} onItemSelect - Callback when item is selected
 */
interface ProcessListContainerProps<T> {
  item: T;
  isHeader: boolean;
  isOpen: boolean;
  sectionId: string;
  itemKey: React.Key;
  isSelected: boolean;
  getItemPrimaryText: (item: T) => string;
  getItemSecondaryText?: (item: T) => string;
  renderItem?: (item: T) => React.ReactNode;
  onSectionHeaderToggle: (sectionId: string) => void;
  onItemSelect: (item: T) => void;
}

/**
 * ProcessListContainer Component
 * A versatile component that renders both section headers and regular items
 * with appropriate styling and interaction handling.
 * 
 * Features:
 * - Dual rendering modes (header/item)
 * - Selection state visualization
 * - Section collapse controls
 * - Custom content rendering
 * - Accessibility support
 * - Keyboard interaction
 * 
 * @component
 * @template T - The type of items being rendered
 */
export const ProcessListContainer = <T,>({
  item,
  isHeader,
  isOpen,
  sectionId,
  itemKey,
  isSelected,
  getItemPrimaryText,
  getItemSecondaryText,
  renderItem,
  onSectionHeaderToggle,
  onItemSelect,
}: ProcessListContainerProps<T>): JSX.Element => {
  const handleClick = () => {
    if (isHeader) {
      onSectionHeaderToggle(sectionId);
    } else {
      onItemSelect(item);
    }
  };

  if (renderItem) {
    return (
      <Box
        onClick={handleClick}
        data-item-key={itemKey}
        role={isHeader ? 'button' : 'option'}
        aria-selected={isSelected}
        aria-expanded={isHeader ? isOpen : undefined}
      >
        {renderItem(item)}
      </Box>
    );
  }

  const primaryText = getItemPrimaryText(item);
  const secondaryText = getItemSecondaryText?.(item) || '';

  if (isHeader) {
    return (
      <Box
        sx={listItemStyles.sectionHeader.root}
        onClick={handleClick}
        data-item-key={itemKey}
        role="button"
        aria-expanded={isOpen}
        id={`item-${sectionId}`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton
            size="small"
            sx={listItemStyles.sectionHeader.expandIcon}
            onClick={(e) => {
              e.stopPropagation();
              onSectionHeaderToggle(sectionId);
            }}
          >
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography sx={listItemStyles.sectionHeader.title}>
            {primaryText}
          </Typography>
          {secondaryText && (
            <Typography sx={listItemStyles.sectionHeader.subtitle}>
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={[
        listItemStyles.item.root,
        isSelected && listItemStyles.item.selected,
        !isSelected && listItemStyles.item.unselected
      ]}
      onClick={handleClick}
      data-item-key={itemKey}
      role="option"
      aria-selected={isSelected}
    >
      <Box sx={listItemStyles.item.content}>
        <Typography
          variant="body1"
          sx={[
            listItemStyles.item.title,
            isSelected ? listItemStyles.item.titleSelected : listItemStyles.item.titleUnselected
          ]}
        >
          {primaryText}
        </Typography>
        {secondaryText && (
          <Typography
            variant="caption"
            sx={[
              listItemStyles.item.subtitle,
              isSelected ? listItemStyles.item.subtitleSelected : listItemStyles.item.subtitleUnselected
            ]}
          >
            {secondaryText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProcessListContainer; 