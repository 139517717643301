import { ICellRendererParams } from '@ag-grid-community/core';
import { Loading } from '../components/Loading';
import { BloxesAndThumbnail } from '../pages/SearchPage';
import { useProcessPreview } from '../hooks/use-process-preview';

export const ProcessPreviewRenderer = (props: ICellRendererParams) => {
    const { bloxes, thumbnail } = props.value as BloxesAndThumbnail;
    const { loading, svgIcon } = useProcessPreview({ bloxes, thumbnail });

    return (
        <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? <Loading size={5} /> : svgIcon}
        </div>
    );
};
