import { SxProps, Theme } from '@mui/material';

// Base styles specific to Blox List Items
const baseStyles = {
  // Layout patterns
  layout: {
    item: {
      width: 'calc(100% - 16px)',
      minHeight: '60px',
      margin: '12px 8px',
      padding: '4px 16px',
      gap: 1
    },
    icon: {
      size: '28px',
      borderRadius: '8px'
    },
    property: {
      minHeight: '24px',
      minWidth: '220px',
      padding: '2px 4px',
      labelWidth: '110px',
      valueMinWidth: '80px',
      rowHeight: '20px',
      rowPadding: '0 6px'
    },
    svg: {
      container: {
        size: '50px',
        position: {
          right: '12px',
          top: '50%'
        },
        padding: '4px',
        borderRadius: '10px'
      },
      zoom: {
        size: '18px',
        iconSize: '12px',
        offset: '-6px'
      }
    }
  },
  // Colors specific to Blox items
  colors: {
    background: {
      default: 'var(--background-white)',
      selected: `rgba(var(--selection-blue-rgb), 0.04)`,
      hover: `rgba(var(--black-rgb), 0.02)`,
      icon: 'var(--accent-purple-light)',
      iconHover: 'var(--accent-purple-lighter)',
      propertyRow: 'var(--background-lighter)',
      stepNumber: 'var(--primary-blue)',
      stepNumberHover: 'var(--primary-blue-hover)',
      zoomIcon: 'white'
    },
    text: {
      primary: 'var(--text-primary)',
      secondary: 'var(--text-secondary)',
      white: 'var(--text-white)',
      value: 'var(--primary-blue)'
    },
    border: {
      default: `rgba(var(--black-rgb), 0.12)`,
      selected: `rgba(var(--highlight-cyan-rgb), 0.7)`,
      property: `rgba(var(--black-rgb), 0.08)`,
      accent: `rgba(var(--primary-blue-rgb), 0.2)`
    }
  },
  // Typography patterns
  typography: {
    title: {
      size: 'var(--font-size-md)',
      weight: 'var(--font-weight-semibold)',
      lineHeight: 1.4,
      family: 'var(--font-primary)',
      maxHeight: '2.8em'
    },
    subtitle: {
      size: 'var(--font-size-sm)',
      lineHeight: 1.2,
      family: 'var(--font-mono)'
    },
    property: {
      size: 'var(--font-size-sm)',
      sizeSmall: 'var(--font-size-xs)',
      weight: 'var(--font-weight-semibold)',
      weightMedium: 'var(--font-weight-medium)',
      weightBold: 'var(--font-weight-bold)',
      letterSpacing: '0.01em',
      letterSpacingNeg: '-0.01em',
      family: 'var(--font-primary)',
      familyMono: 'var(--font-mono)',
      lineHeight: 1.5
    }
  },
  // Effects and animations
  effects: {
    transition: 'all 0.2s ease-in-out',
    transitionFast: 'all 0.15s ease-in-out',
    boxShadow: {
      container: `1px 2px 3px 0px rgba(var(--black-rgb), 0.1)`,
      icon: `0 2px 4px rgba(var(--primary-blue-rgb), 0.2)`,
      hover: `0 2px 6px rgba(var(--black-rgb), 0.08)`,
      zoomIcon: `0 1px 3px rgba(var(--black-rgb), 0.1)`
    },
    transform: {
      hover: 'translateY(2px)',
      iconHover: 'translateY(-50%) scale(1.01)',
      centerY: 'translateY(-50%)'
    }
  }
};

export const bloxListItemStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: baseStyles.layout.item.gap,
    width: baseStyles.layout.item.width,
    minHeight: baseStyles.layout.item.minHeight,
    height: 'auto',
    position: 'relative',
    borderRadius: '12px',
    transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
    margin: baseStyles.layout.item.margin,
    padding: baseStyles.layout.item.padding,
    cursor: 'pointer',
    border: `1.5px solid ${baseStyles.colors.border.default}`,
    backgroundColor: baseStyles.colors.background.default,
    transform: 'translateY(-1px)',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.1)',
    '&::before': undefined,
    '&:hover': {
      '& .zoom-icon': {
        opacity: 1
      },
      '& .svg-container': {
        transform: baseStyles.effects.transform.iconHover,
        boxShadow: baseStyles.effects.boxShadow.hover,
        backgroundColor: baseStyles.colors.background.iconHover,
        border: `1px solid ${baseStyles.colors.border.accent}`
      },
      transform: 'translateY(1px)',
      boxShadow: 'none',
    },
    '&:not(.start-blox)': {
      height: '84px'
    }
  } as SxProps<Theme>,

  containerVariants: (isSelected: boolean) => ({
    backgroundColor: isSelected ? baseStyles.colors.background.selected : baseStyles.colors.background.default,
    transform: isSelected ? 'translateY(1px)' : 'translateY(-1px)',
    boxShadow: isSelected ? 'none' : '0 2px 0 rgba(0, 0, 0, 0.1)',
    border: isSelected ? `2px solid ${baseStyles.colors.border.selected}` : `1.5px solid ${baseStyles.colors.border.default}`,
    '&:hover': {
      backgroundColor: isSelected ? baseStyles.colors.background.selected : baseStyles.colors.background.hover,
      transform: 'translateY(1px)',
      boxShadow: 'none',
    }
  }) as SxProps<Theme>,

  startBloxIcon: {
    width: baseStyles.layout.icon.size,
    height: baseStyles.layout.icon.size,
    borderRadius: baseStyles.layout.icon.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: baseStyles.colors.background.icon,
    flexShrink: 0,
    alignSelf: 'center',
    boxShadow: baseStyles.effects.boxShadow.icon,
    padding: 0,
    overflow: 'hidden',
    '& > svg, & > img': {
      width: '180%',
      height: '180%',
      objectFit: 'contain',
      transform: 'scale(1.6)',
      margin: '-2px'
    },
    '& .library-label, & text, & .processStart': {
      display: 'none'
    }
  } as SxProps<Theme>,

  stepNumberIcon: {
    width: baseStyles.layout.icon.size,
    height: baseStyles.layout.icon.size,
    borderRadius: baseStyles.layout.icon.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: baseStyles.colors.background.stepNumber,
    color: baseStyles.colors.text.white,
    fontWeight: baseStyles.typography.property.weight,
    fontSize: baseStyles.typography.property.size,
    flexShrink: 0,
    alignSelf: 'center',
    boxShadow: baseStyles.effects.boxShadow.icon,
    '&:hover': {
      backgroundColor: baseStyles.colors.background.stepNumberHover
    }
  } as SxProps<Theme>,

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    flex: 1,
    justifyContent: 'center',
    height: '100%'
  } as SxProps<Theme>,

  title: {
    fontSize: baseStyles.typography.title.size,
    color: baseStyles.colors.text.primary,
    fontWeight: baseStyles.typography.title.weight,
    lineHeight: baseStyles.typography.title.lineHeight,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    maxHeight: baseStyles.typography.title.maxHeight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: baseStyles.typography.title.family
  } as SxProps<Theme>,

  titleVariant: (hasTools: boolean) => ({
    mb: hasTools ? 0.5 : 0
  }) as SxProps<Theme>,

  subtitle: {
    fontFamily: baseStyles.typography.subtitle.family,
    color: baseStyles.colors.text.secondary,
    fontSize: baseStyles.typography.subtitle.size,
    display: 'block',
    wordBreak: 'break-word',
    lineHeight: baseStyles.typography.subtitle.lineHeight
  } as SxProps<Theme>,

  propertiesContainer: (hasBloxSVG: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 0.1,
    flex: 2,
    paddingRight: hasBloxSVG ? `${parseInt(baseStyles.layout.svg.container.size) + 40}px` : 0,
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    minWidth: 0,
    '&:not(.start-blox-properties)': {
      height: baseStyles.layout.item.minHeight,
      overflow: 'hidden'
    }
  }) as SxProps<Theme>,

  startBloxPropertyRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    minHeight: baseStyles.layout.property.minHeight,
    padding: baseStyles.layout.property.padding,
    minWidth: baseStyles.layout.property.minWidth,
    width: 'fit-content',
    borderRadius: '4px',
    backgroundColor: baseStyles.colors.background.propertyRow,
    borderLeft: `1px solid ${baseStyles.colors.border.property}`,
    borderRight: `1px solid ${baseStyles.colors.border.property}`,
    borderTop: `1px solid ${baseStyles.colors.border.property}`,
    '&:last-child': {
      borderBottom: `1px solid ${baseStyles.colors.border.property}`,
    }
  } as SxProps<Theme>,

  startBloxPropertyLabel: {
    color: baseStyles.colors.text.primary,
    fontSize: baseStyles.typography.property.size,
    letterSpacing: baseStyles.typography.property.letterSpacing,
    minWidth: baseStyles.layout.property.labelWidth,
    width: 'fit-content',
    flexShrink: 0,
    fontWeight: baseStyles.typography.property.weight,
    textTransform: 'capitalize',
    fontFamily: baseStyles.typography.property.family,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  } as SxProps<Theme>,

  startBloxPropertyValue: {
    color: baseStyles.colors.text.value,
    fontSize: baseStyles.typography.property.size,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: baseStyles.typography.property.lineHeight,
    flex: 1,
    minWidth: baseStyles.layout.property.valueMinWidth,
    fontWeight: baseStyles.typography.property.weight,
    fontFamily: baseStyles.typography.property.family,
    letterSpacing: baseStyles.typography.property.letterSpacingNeg,
    marginLeft: 0
  } as SxProps<Theme>,

  propertyRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    height: baseStyles.layout.property.rowHeight,
    padding: baseStyles.layout.property.rowPadding,
    borderRadius: '4px'
  } as SxProps<Theme>,

  propertyLabel: {
    color: baseStyles.colors.text.primary,
    fontSize: baseStyles.typography.property.sizeSmall,
    letterSpacing: baseStyles.typography.property.letterSpacing,
    width: baseStyles.layout.property.labelWidth,
    flexShrink: 0,
    fontWeight: baseStyles.typography.property.weightMedium,
    textTransform: 'capitalize',
    fontFamily: baseStyles.typography.property.family,
    marginRight: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  } as SxProps<Theme>,

  propertyValue: {
    color: baseStyles.colors.text.value,
    fontSize: baseStyles.typography.property.sizeSmall,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: baseStyles.typography.property.lineHeight,
    flex: 1,
    fontWeight: baseStyles.typography.property.weightBold,
    fontFamily: baseStyles.typography.property.familyMono,
    letterSpacing: baseStyles.typography.property.letterSpacingNeg,
    wordBreak: 'break-word'
  } as SxProps<Theme>,

  svgContainer: {
    position: 'absolute',
    right: baseStyles.layout.svg.container.position.right,
    top: baseStyles.layout.svg.container.position.top,
    transform: baseStyles.effects.transform.centerY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: baseStyles.layout.svg.container.size,
    height: baseStyles.layout.svg.container.size,
    flexShrink: 0,
    backgroundColor: baseStyles.colors.background.icon,
    borderRadius: baseStyles.layout.svg.container.borderRadius,
    padding: baseStyles.layout.svg.container.padding,
    transition: baseStyles.effects.transitionFast,
    border: '1px solid transparent',
    '& > svg, & > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'  
    },
    '& .library-label, & text': {
      display: 'none'
    },
    '& .zoom-icon': {
      position: 'absolute',
      top: baseStyles.layout.svg.zoom.offset,
      right: baseStyles.layout.svg.zoom.offset,
      width: baseStyles.layout.svg.zoom.size,
      height: baseStyles.layout.svg.zoom.size,
      borderRadius: '50%',
      backgroundColor: baseStyles.colors.background.zoomIcon,
      boxShadow: baseStyles.effects.boxShadow.zoomIcon,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      transition: baseStyles.effects.transitionFast,
      color: baseStyles.colors.text.value,
      '& svg': {
        width: baseStyles.layout.svg.zoom.iconSize,
        height: baseStyles.layout.svg.zoom.iconSize
      }
    }
  } as SxProps<Theme>
} as const; 