import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from "@google/generative-ai";

// For testing purposes only - use the key directly
// WARNING: This should be replaced with environment variable usage before pushing to production
const apiKey = "AIzaSyBVnnXmZG_CUIWUAT7sVsEHUWq-U4xwuPU"; // This has alreaady been rotated
// const apiKey = process.env.NEXT_PUBLIC_GEMINI_API_KEY || '';

// Initialize the API client
const genAI = new GoogleGenerativeAI(apiKey);

// Get the model with specific configuration
const model = genAI.getGenerativeModel({
    model: "gemini-2.0-flash-thinking-exp-01-21",
    systemInstruction: `You are PANDA (Process Assistant for Novel Device Applications), a helpful and intelligent process systems engineer for a semiconductor facility with over 25 years of experience.

EXTREME CONCISENESS IS CRITICAL: 
Engineers using this interface need immediate, precise information they can apply while working with complex semiconductor equipment. They have limited screen space and attention bandwidth. A 1-2 sentence response is often ideal.

WHY CONCISENESS MATTERS:
1. Users are viewing your responses in a small chat window while operating equipment
2. Engineers need quick, actionable information, not lengthy explanations
3. Technical clarity is lost in verbose responses
4. Time-sensitive fabrication decisions depend on your brevity

CONCISENESS GUIDELINES:
- Use 50 words or less whenever possible
- Never explain basic concepts engineers already understand
- Jump directly to the specific answer with zero preamble
- Prioritize practical parameters and tool recommendations
- Eliminate all unnecessary words, phrases and hedging

FORMATTING (always in service of conciseness):
- Use **bold** for critical terms only
- Use bullet points for multiple options
- Use numbered steps only when sequence matters
- Avoid unnecessary markdown formatting

When answering, assume deep technical expertise from the user and focus exclusively on providing the minimum necessary information to solve their specific semiconductor fabrication challenge.`,
});

// Configuration for generation
const generationConfig = {
  temperature: 0.7,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192, // Reduced to improve response time
  responseMimeType: "text/plain",
};

// Chat history to maintain context
let chatHistory: {
  role: "user" | "model";
  parts: { text: string }[];
}[] = [
  // Initial messages can be added here if needed
];

// Initialize or reset the chat session
export const initChatSession = () => {
  chatHistory = [];
  return model.startChat({
    generationConfig,
    history: chatHistory,
  });
};

// Send a message to the chat session and get a response
export const sendMessage = async (message: string): Promise<string> => {
  try {
    // Create a fresh chat session with the current history
    const chatSession = model.startChat({
      generationConfig,
      history: chatHistory,
    });
    
    // Add the user message to history
    chatHistory.push({
      role: "user",
      parts: [{ text: message }],
    });
    
    // Send the message and get response
    console.log("Sending message to Gemini:", message);
    console.log("Using API key:", apiKey.substring(0, 5) + "...");
    
    const result = await chatSession.sendMessage(message);
    // Use await here to properly resolve the promise
    const responseText = await result.response.text();
    
    console.log("Received response from Gemini:", responseText.substring(0, 50) + "...");
    
    // Add the model's response to history
    chatHistory.push({
      role: "model",
      parts: [{ text: responseText }],
    });
    
    // Keep chat history at a reasonable size (last 20 messages)
    if (chatHistory.length > 20) {
      chatHistory = chatHistory.slice(chatHistory.length - 20);
    }
    
    return responseText;
  } catch (error) {
    console.error("Error sending message to Gemini:", error);
    return "Sorry, I encountered an error processing your request. Please try again later.";
  }
};

// Clear chat history
export const clearChatHistory = () => {
  chatHistory = [];
};

// Test function to verify API connectivity
export const testGeminiConnection = async (): Promise<boolean> => {
  try {
    const response = await model.generateContent("Hello, can you confirm this test message is working?");
    const text = await response.response.text();
    console.log("Gemini test response:", text);
    return true;
  } catch (error) {
    console.error("Gemini connection test failed:", error);
    return false;
  }
}; 