import { AllBloxes, BloxTypes } from "../Data/BloxSchema/base-blox";
import { getDefaultColor, materialFieldMap } from "../Data/material-mappings";
import { IColor } from "../utils/Color";
import { getProperty, hasKey, setProperty } from "../utils/ts-helpers";
import { FillDeposit, getStackActionFromBloxes, StackAction, ThermalOxidation, ThinDeposit, WaferBonding } from "../Data/stack-change";
import { StackChange } from "../Data/enums";
import { SelectedBloxMaterialProperties } from "../dialogs/MaterialDialog/hooks/use-material-handlers";
import { checkIfDevelopableResist, checkIfResist } from "../Data/BloxSchema/spin-coat-unified";

export interface BloxRef {
    bloxId: string;
    bloxName: string;
    stepNumber: number;
    isTarget: boolean;
    count: number;
}

export type MaterialLabelsToIDs = {
    [label: string] : string[]
}

export interface Material {
    materialId: string,
    materialLabel:string,
    isResist: boolean,
    isDevelopableResist:  boolean,
    color: IColor;
}

export const propertiesThatTargetMaterials: string[] = [
    "layerLabelsToDevelop",
    "layerLabelsToDiffuseIn",
    "layerLabelsToEtch",
    "layerLabelsToExpose",
    "layerLabelsToFloat",
    "layerLabelsToGrow",
    "layerLabelsToImplant",
    "layerLabelsToImprint",
    "layerLabelsToOxidize",
    "layerLabelsToRemove",
    "layerLabelsToReverse"
]

export function getMaterials(processBloxes: AllBloxes[], untilBloxId: number, materialFilter?: string): Material[] {
    const previousMaterials: {[label: string] : number} = {}
    const previousBloxes = processBloxes.slice(0, untilBloxId+1);
    let materials = previousBloxes.reduce((mtls: Material[], blox, stepNumber) => {
        let materialParams = materialFieldMap[blox.bloxType];

        if (blox.isOxidized) {
            materialParams = materialFieldMap[BloxTypes.ThermalOxide];
        }

        if (blox.bloxType === BloxTypes.StartBlox) {
            // special case for start blox
            if (blox.layers) {
                blox.layers.forEach((layer, index) => {
                    const materialLabel = layer.layerLabel ? layer.layerLabel : "Unknown Material";
                    
                    if (materialLabel in previousMaterials) { 
                        previousMaterials[materialLabel] += 1
                    } else {
                        previousMaterials[materialLabel] = 1;

                        mtls.push({ 
                            materialId: layer.materialId, 
                            materialLabel: materialLabel,
                            isResist: false,
                            isDevelopableResist: false,
                            color: layer.layerColor ?? getDefaultColor(blox.bloxType)
                        });
                    }
                });
            } else {
                // backward compatability
                const materialLabel = blox.layerLabel ? blox.layerLabel : "Unknown Material";
                
                if (materialLabel in previousMaterials) { 
                    previousMaterials[materialLabel] += 1
                } else {
                    previousMaterials[materialLabel] = 1;

                    mtls.push({ 
                        materialId: blox.id, 
                        materialLabel: materialLabel,
                        isResist: false,
                        isDevelopableResist: false,
                        color: blox.layerColor ?? getDefaultColor(blox.bloxType) 
                    });
                }
            }
        } else if (materialParams && hasKey(blox, materialParams.materialFieldName)) {
            const materialId = blox.materialId ?? blox.id
            let materialLabel:string = getProperty(blox, materialParams.materialFieldName) as string;
            if (!materialLabel) {
                materialLabel = materialParams.unknownLabel;
            } 
            if (materialLabel in previousMaterials) { 
                previousMaterials[materialLabel] += 1
            } else {
                previousMaterials[materialLabel] = 1;

                let isResist = false;
                let isDevelopableResist = false;
                const spunMaterialType = blox.spunMaterialType ?? blox.resistType;
                if (spunMaterialType && checkIfResist(spunMaterialType)) {
                    isResist = true;
                }
                if (spunMaterialType && checkIfDevelopableResist(spunMaterialType)) {
                    isDevelopableResist = true;
                }

                mtls.push({ 
                    materialId: materialId, 
                    materialLabel: materialLabel,
                    isResist: isResist,
                    isDevelopableResist: isDevelopableResist,
                    color: getColorForBlox(blox, materialParams.materialFieldName)
                });
            }
        }

        if (blox.bloxType === BloxTypes.WaferBonding && blox.useBondingAgent) {
            // special case for wafer conding bonding agent
            let materialId = blox.materialId ?? blox.id
            materialId += "-bonding-agent";
            let materialLabel = blox.bondingAgentMaterial;
            if (!materialLabel) {
                materialLabel = materialFieldMap["WAFERBONDING-AGENT"]?.unknownLabel ?? "";
            } 
            if (materialLabel in previousMaterials) { 
                previousMaterials[materialLabel] += 1
            } else {
                previousMaterials[materialLabel] = 1;

                mtls.push({ 
                    materialId: materialId, 
                    materialLabel: materialLabel,
                    isResist: false,
                    isDevelopableResist: false,
                    color: blox.bondingAgentColor ?? getDefaultColor("WAFERBONDING-AGENT")
                });
            }
        }
        
        return mtls;
    }, []);

    if (materialFilter === "ONLY_RESIST") {
        materials = materials.filter((material) => material.isResist);
    } else if (materialFilter === "ONLY_DEVELOPABLE_RESIST") {
        materials = materials.filter((material) => material.isDevelopableResist);
    } else if (materialFilter === "NOT_RESIST") {
        materials = materials.filter((material) => !material.isResist);
    }

    return materials;
}

export function updateMaterialLabelsToIDs(blox: AllBloxes, stackChanges: StackAction[], materialLabelToIds: MaterialLabelsToIDs): MaterialLabelsToIDs {
    stackChanges.forEach(sc => {
        let materialId = "";
        if (sc.type === StackChange.FillDeposit) {
            materialId = (sc as FillDeposit).materialId;
        } else if (sc.type === StackChange.ThinDeposit) {
            materialId = (sc as ThinDeposit).materialId;
        } else if (sc.type === StackChange.ThermalOxidation) {
            materialId = (sc as ThermalOxidation).materialId;
        } else if (sc.type === StackChange.WaferBonding) {
            materialId = (sc as WaferBonding).materialId;
        }

        let materialParams = materialFieldMap[blox.bloxType];
        if (blox.isOxidized) {
          materialParams = materialFieldMap[BloxTypes.ThermalOxide];
        }

        if (materialId && sc.id.endsWith("bonding-agent")) {
          // special case for wafer bonding bonding agent - the material label is taken from a different place
          const materialLabel = blox.bondingAgentMaterial;
          if (materialLabel) {
            if (materialLabel in materialLabelToIds) {
              materialLabelToIds[materialLabel].push(materialId);
            } else {
              materialLabelToIds[materialLabel] = [materialId];
            }
          } else {
            const unknownLabel = materialFieldMap["WAFERBONDING-AGENT"]?.unknownLabel ?? "";
            if (unknownLabel in materialLabelToIds) {
              materialLabelToIds[unknownLabel].push(materialId);
            } else {
              materialLabelToIds[unknownLabel] = [materialId];
            }
          }
        } else if (materialId && materialParams && hasKey(blox, materialParams.materialFieldName)) {
          // common case - grab material name from materialFieldName as defined in material-mappings
          const materialLabel:string = getProperty(blox, materialParams.materialFieldName) as string;
          if (materialLabel) {
            if (materialLabel in materialLabelToIds) {
              materialLabelToIds[materialLabel].push(materialId);
            } else {
              materialLabelToIds[materialLabel] = [materialId];
            }
          } else {
            const unknownLabel = materialParams.unknownLabel;
            if (unknownLabel in materialLabelToIds) {
              materialLabelToIds[unknownLabel].push(materialId);
            } else {
              materialLabelToIds[unknownLabel] = [materialId];
            }
          }
        } else if (blox.bloxType === BloxTypes.StartBlox) {
          // special case for StartBlox - can deposit multiple layers
          const bloxLayers = blox.layers ?? [];
          
          bloxLayers.forEach(bloxLayer => {
            const materialLabel = bloxLayer.layerLabel;
            materialId = bloxLayer.materialId;
            if (materialLabel) {
              if (materialLabel in materialLabelToIds) {
                materialLabelToIds[materialLabel].push(materialId);
              } else {
                materialLabelToIds[materialLabel] = [materialId];
              }
            } else {
              const unknownLabel = "Unknown Material";
              if (unknownLabel in materialLabelToIds) {
                materialLabelToIds[unknownLabel].push(materialId);
              } else {
                materialLabelToIds[unknownLabel] = [materialId];
              }
            }
          })
        }
    })
    return materialLabelToIds;
}

export function getBloxRefByMaterial(processBloxes: AllBloxes[], material:Material): BloxRef[] {
    // generate material labels by IDs
    // TODO - this function does not need to run for every material
    // run it once outside this function and give it as input to this function
    let materialLabelToIds: MaterialLabelsToIDs = {};
    processBloxes.forEach(blox => {
        const stackChanges = getStackActionFromBloxes([blox], materialLabelToIds);
        materialLabelToIds = updateMaterialLabelsToIDs(blox, stackChanges, materialLabelToIds);
    })

    const materialIds = materialLabelToIds[material.materialLabel];
    if (!materialIds) {
        return [];
    }

    const relevantBloxes = processBloxes.map((blox, index) => {
        // Start blox
        if (blox.bloxType === BloxTypes.StartBlox && blox.layers) {
            let count = 0;
            blox.layers.forEach(layer => {
                if (materialIds.includes(layer.materialId)) {
                    count += 1;
                }
            })
            if (count > 0) {
                return {
                    bloxId : blox.id,
                    bloxName: blox.name,
                    stepNumber: index+1,
                    isTarget: false,
                    count: count
                } as BloxRef;
            }
        } else {
            // deposition blox
            const materialId = blox.materialId ?? blox.id;
            if (materialIds.includes(materialId)) {
                return {
                    bloxId : blox.id,
                    bloxName: blox.name,
                    stepNumber: index+1,
                    isTarget: false,
                    count: 1
                } as BloxRef;
            }

            // target bloxes
            const occurences = propertiesThatTargetMaterials.reduce((count, property) => {
                if (hasKey(blox, property)) {
                    const materialTargets = getProperty(blox, property) as string[];
                    if (materialTargets.includes(material.materialLabel)) {
                        return count+1;
                    }
                }
                return count;
            }, 0);
            if (occurences > 0) {
                return {
                    bloxId : blox.id,
                    bloxName: blox.name,
                    stepNumber: index+1,
                    isTarget: true,
                    count: occurences
                } as BloxRef;
            }
        }
        return null;
    }).filter(bloxRef => bloxRef !== null) as BloxRef[];

    return relevantBloxes;
}

/**
 * Replaces or updates the material name (and optionally the color) in a given blox.
 * This covers various scenarios, including:
 * - Updating a specific layer by index.
 * - Updating a specific property if it matches a provided blox ID.
 * - Handling oxidized bloxes, bonding agents, and field mappings.
 * - Checking and replacing materials in arrays of target materials.
 *
 * @param blox                              The original blox object to update.
 * @param selectedBloxMaterialDetails       Information about which property or layer to update.
 * @param newMaterialName                   The new material name to set.
 * @param previousMaterialName              The old material name being replaced (if applicable).
 * @param newColor                          An optional color to apply if updating color.
 * @returns                                 A new blox object with updated material information.
 */
export function replaceSpecificBloxMaterial(
    blox: AllBloxes,
    selectedBloxMaterialDetails: SelectedBloxMaterialProperties,
    newMaterialName: string,
    previousMaterialName: string,
    newColor: IColor
) {
    // Make a shallow copy so we don't mutate the original blox object.
    const updatedBlox = { ...blox };

    // ────────────────────────────────────────────────────────────────────────────────
    // 1) If a specific layerIndex is provided, update only that layer and return.
    // ────────────────────────────────────────────────────────────────────────────────
    if (selectedBloxMaterialDetails.layerIndex !== undefined && updatedBlox.layers) {
        const { layerIndex } = selectedBloxMaterialDetails;
        const originalLayer = updatedBlox.layers[layerIndex];

        // Create a new layers array to avoid mutating the existing one.
        const updatedLayers = [...updatedBlox.layers];

        // Replace only the target layer with the updated material name and optional color.
        updatedLayers[layerIndex] = {
            ...originalLayer,
            layerLabel: newMaterialName,
            ...(newColor && { layerColor: newColor }),
        };

        // Return a new blox object containing the updated layers.
        return { ...updatedBlox, layers: updatedLayers };
    }

    // ────────────────────────────────────────────────────────────────────────────────
    // 2) If the blox's ID matches the specified bloxId and it has the target property,
    //    update that property (and color if needed).
    // ────────────────────────────────────────────────────────────────────────────────
    if (
        updatedBlox.id === selectedBloxMaterialDetails.bloxId &&
        hasKey(updatedBlox, selectedBloxMaterialDetails.property)
    ) {
        setProperty(updatedBlox, selectedBloxMaterialDetails.property, newMaterialName);

        // If a new color is provided, handle which color property to update.
        if (newColor) {
            // If it's the bonding agent, set bondingAgentColor.
            if (selectedBloxMaterialDetails.property === 'bondingAgentMaterial') {
                updatedBlox.bondingAgentColor = newColor;
            }
            // If it's an oxidized blox, set oxidationLayerColor.
            else if (blox.isOxidized) {
                updatedBlox.oxidationLayerColor = newColor;
            }
            // Otherwise, set the default layer color.
            else {
                updatedBlox.layerColor = newColor;
            }
        }
    } else if (blox.bloxType === BloxTypes.StartBlox && updatedBlox.layers) {
        // We'll update each layer whose label matches previousMaterialName:
        const updatedLayers = updatedBlox.layers.map((layer) => {
            if (layer.layerLabel === previousMaterialName) {
                return {
                    ...layer,
                    layerLabel: newMaterialName,
                    // Always set layerColor to newColor, as requested:
                    layerColor: newColor,
                };
            }
            return layer;
        });
    
        // Return a new blox object with the updated layers
        return { ...updatedBlox, layers: updatedLayers };
    } else {
        // ──────────────────────────────────────────────────────────────────────────────
        // 3) Otherwise, use materialFieldMap to find out how to update the blox.
        //    This logic handles general cases and oxidized bloxes.
        // ──────────────────────────────────────────────────────────────────────────────
        let materialParams = materialFieldMap[blox.bloxType];

        // If the blox is oxidized, override materialParams with thermal oxide settings.
        if (blox.isOxidized) {
            materialParams = materialFieldMap[BloxTypes.ThermalOxide];
        }

        if (materialParams && hasKey(blox, materialParams.materialFieldName)) {
            // Get the current material label. If undefined, use the unknownLabel from params.
            let currentMaterialLabel = getProperty(
                blox,
                materialParams.materialFieldName
            ) as string;
            if (!currentMaterialLabel) {
                currentMaterialLabel = materialParams.unknownLabel;
            }

            // If the current material matches the previousMaterialName, replace it.
            if (currentMaterialLabel === previousMaterialName) {
                setProperty(updatedBlox, materialParams.materialFieldName, newMaterialName);

                // Update color if provided. Oxidized vs. normal color is handled here.
                if (newColor) {
                    if (blox.isOxidized) {
                        setProperty(updatedBlox, 'oxidationLayerColor', newColor);
                    } else {
                        setProperty(updatedBlox, 'layerColor', newColor);
                    }
                }
            }
        }

        // ──────────────────────────────────────────────────────────────────────────────
        // 4) Check and update bondingAgentMaterial if it matches previousMaterialName.
        // ──────────────────────────────────────────────────────────────────────────────
        if (
            blox.bondingAgentMaterial &&
            blox.bondingAgentMaterial === previousMaterialName
        ) {
            updatedBlox.bondingAgentMaterial = newMaterialName;
            if (newColor) {
                updatedBlox.bondingAgentColor = newColor;
            }
        }
    }

    // ────────────────────────────────────────────────────────────────────────────────
    // 5) Some bloxes have arrays of "target materials" in certain properties.
    //    Check each of those properties and replace matching entries.
    // ────────────────────────────────────────────────────────────────────────────────
    propertiesThatTargetMaterials.forEach((targetProperty) => {
        if (hasKey(blox, targetProperty)) {
            const materialTargets = getProperty(blox, targetProperty) as string[];
            const index = materialTargets.indexOf(previousMaterialName);

            // If we find the old material name in the array, replace it.
            if (index !== -1) {
                materialTargets[index] = newMaterialName;
                setProperty(updatedBlox, targetProperty, materialTargets);
            }
        }
    });

    // Return the final updated blox.
    return updatedBlox;
}

/**
 * Returns the appropriate color for a given blox and property,
 * handling bonding agent vs. oxidized vs. regular layer color.
 *
 * WARNING: Does not cover StartBlox special case.
 */
export function getColorForBlox(blox: AllBloxes, property: string): IColor {
    // Special case: bonding agent
    if (property === "bondingAgentMaterial") {
      return blox.bondingAgentColor ?? getDefaultColor("WAFERBONDING-AGENT");
    }
  
    // If this blox is oxidized, use oxidation color if present.
    if (blox.isOxidized) {
      return blox.oxidationLayerColor ?? getDefaultColor(BloxTypes.ThermalOxide);
    }
  
    // Fallback to regular layer color or the default if not set.
    return blox.layerColor ?? getDefaultColor(blox.bloxType);
  }