/**
 * @fileoverview Provides a context-based SVG generation service for the Fabublox process editor.
 * This service manages the generation and caching of SVG representations for process blocks (Bloxes),
 * handling both 2D and 3D visualizations with material mapping and stack changes.
 */
import React, { createContext, useReducer, ReactNode } from 'react';
import { DrawCommand, SGVEngineReturnType, SVGDisplayMode, SVGEngineService } from '../../Services/SVGEngine';
import { getStackActionFromBloxes } from '../../Data/stack-change';
import { AllBloxes } from '../../Data/BloxSchema/base-blox';
import { MaterialLabelsToIDs, updateMaterialLabelsToIDs } from '../material-targets';

/**
 * Defines the shape of the SVG service context value.
 * Provides methods for generating SVG representations of Blox elements.
 * 
 * @interface ContextValue
 * @property {Function} generateSvgs - Function to generate SVG elements for a list of Bloxes
 */
interface ContextValue {
  /**
   * Generates SVG representations for a list of Bloxes.
   * 
   * @param {AllBloxes[]} bloxes - Array of Blox elements to generate SVGs for
   * @param {boolean} threeDim - Whether to generate 3D representations
   * @param {SVGDisplayMode} svgDisplayMode - Display mode for the SVGs
   * @returns {JSX.Element[]} Array of SVG elements
   */
  generateSvgs: (bloxes: AllBloxes[], threeDim: boolean, svgDisplayMode: SVGDisplayMode) => JSX.Element[]
}

/**
 * Defines the possible actions for the SVG cache reducer.
 * Used to manage the caching of generated SVGs for better performance.
 * Creates the context with a default value.
 * 
 * @type {Action}
 */
type Action = 
  | { type: 'add', key: string, value: SGVEngineReturnType }  // Add a new SVG to cache
  | { type: 'remove', key: string };                          // Remove an SVG from cache

/**
 * Context instance for the SVG service. Provides access to SVG generation
 * functionality throughout the component tree.
 */
export const SvgServiceContext = createContext<ContextValue>({
  generateSvgs: () => []
});

/**
 * Props interface for the SVG service provider component.
 * 
 * @interface SvgServiceProviderProps
 * @property {ReactNode} children - Child components that will have access to the SVG service
 */
interface SvgServiceProviderProps {
  children: ReactNode;
}

/**
 * Reducer function for managing the SVG cache.
 * Handles adding and removing SVGs from the cache based on their keys.
 * 
 * @param {Record<string, SGVEngineReturnType>} state - Current cache state
 * @param {Action} action - Action to perform on the cache
 * @returns {Record<string, SGVEngineReturnType>} Updated cache state
 */
function cacheReducer(state: Record<string, SGVEngineReturnType>, action: Action) {
  switch (action.type) {
    case 'add':
      return { ...state, [action.key]: action.value };
    case 'remove':
      {
        const newState = { ...state };
        delete newState[action.key];
        return newState;
      }
    default:
      throw new Error();
  }
}

/**
 * Custom hook that provides SVG generation functionality with caching.
 * Manages the generation and caching of SVGs for Blox elements, handling
 * material mappings, stack changes, and different display modes.
 * 
 * @returns {Function} Function to generate SVGs with caching
 */
const useGenerateSvgs = () => {
  const [cache, dispatch] = useReducer(cacheReducer, {});
  
  const generateSvgs = (bloxes: AllBloxes[], threeDim: boolean, svgDisplayMode: SVGDisplayMode) => {
    //  if empty, then give a list of bloxes * bloxes.length (We want to give an array of empty JSX elements for each Blox)  
    let svgs: JSX.Element[] = new Array(bloxes.length).fill(<></>);

    // this could come from an svgServiceProvider and generateSvg could become a useCallback if we move to a singleton pattern
    const svgService = new SVGEngineService();

    try {
        let history: DrawCommand[] = [];
        let materialLabelToIds: MaterialLabelsToIDs = {};

        // leaving for debugging purposes
        //// const allStackChanges = getStackActionFromBloxes(bloxes);
        svgs = bloxes.map((blox) => {
          const stackChanges = getStackActionFromBloxes([blox], materialLabelToIds);

          // add new materialIDs to the mapping
          materialLabelToIds = updateMaterialLabelsToIDs(blox, stackChanges, materialLabelToIds);
          
          // Generate cache key and handle SVG generation
          const keyA = JSON.stringify(stackChanges);
          const keyB = JSON.stringify(history);
          const keyWithFlags = `${keyA}${keyB}${threeDim ? '1' : '0'}${svgDisplayMode}`

          // uncomment this to disable cache
          //const ret = svgService.generate(stackChanges, history, threeDim, svgDisplayMode);
          let ret = cache[keyWithFlags];
          if (!ret) {
              ret = svgService.generate(stackChanges, history, threeDim, svgDisplayMode);
              dispatch({ type: 'add', key: keyWithFlags, value: ret });
          }
          history = ret.history;
          
          return ret.svg;
        })
    } catch (e: any) {
        console.error('failed to generate svg in list preview' + e)
    }

    return svgs;
  };

  return generateSvgs;
}

/**
 * Provider component that makes SVG generation services available throughout the app.
 * Implements caching for better performance and manages SVG generation state.
 * 
 * @component
 * @param {SvgServiceProviderProps} props - Component props
 * @param {ReactNode} props.children - Child components that will have access to SVG services
 */
export const SvgServiceProvider: React.FC<SvgServiceProviderProps> = ({ children }) => {
  const generateSvgs = useGenerateSvgs();

  return (
    <SvgServiceContext.Provider value={{ generateSvgs }}>
      {children}
    </SvgServiceContext.Provider>
  );
};
