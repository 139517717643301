/**
 * @fileoverview Provides the core state management hook for the Fabublox process editor,
 * managing UI interactions, process data, and collaborative features.
 */
import { useState } from "react";
import { BloxTypes } from "../../Data/BloxSchema/base-blox";
import { AdditionalOwnerUser, Section } from "../../__generated__/Process";
import {v4 as uuidv4} from 'uuid';
import { getNewBlox } from "../new-blox-data";
import { GdsInfo } from "../../__generated__/Gds";

/**
 * Custom hook that provides centralized state management for the Fabublox process editor.
 * 
 * This hook initializes and manages all essential state variables required for the process editor,
 * including UI interactions, process metadata, and collaborative features. It creates a new process
 * with a default StartBlox and empty sections.
 * 
 * @returns {Object} An object containing state pairs (value and setter) for all process editor features
 * 
 * @property {[string, Function]} selectedBloxIdState - Controls the currently selected Blox
 * @property {[boolean, Function]} scrollToSelectedBlox - Manages automatic scrolling to selected Blox
 * @property {[Array<Blox>, Function]} processBloxes - Manages all Blox elements in the process
 * @property {[string, Function]} processName - Controls the process name
 * @property {[string, Function]} processUsername - Manages the process owner's username
 * @property {[string, Function]} processId - Unique identifier for the process
 * @property {[string, Function]} processDesc - Stores the process description
 * @property {[string, Function]} processReference - Manages external references/links
 * @property {[boolean, Function]} processIsPrivate - Controls process visibility
 * @property {[Array<AdditionalOwnerUser>, Function]} processAdditionalOwners - Manages collaborative access
 * @property {[boolean, Function]} processIsReadOnly - Controls edit permissions
 * @property {[Array<{id: string, groupName: string}>, Function]} processGroups - Manages Blox grouping
 * @property {[Array<Section>, Function]} processSections - Organizes Blox into sections
 */
export function useFabuStateProvider() {
    const startBlox = getNewBlox(BloxTypes.StartBlox, 1);
    return {
        // UI State Management
        /** 
         * Controls the currently selected Blox in the editor.
         * @type {[string, (id: string) => void]} 
         */
        selectedBloxIdState: useState(""),

        /** 
         * Controls automatic scrolling behavior to the selected Blox.
         * @type {[boolean, (scroll: boolean) => void]} 
         */
        scrollToSelectedBlox: useState(false),

        /** Right panel visibility state - persists across sessions */
        rightPanelOpenState: useState(true),

        // Process Data Management
        /** 
         * Manages all Blox elements in the process. Initialized with a StartBlox.
         * @type {[Array<Blox>, (bloxes: Array<Blox>) => void]} 
         */
        processBloxes: useState([startBlox]),

        /** 
         * Stores the process name.
         * @type {[string, (name: string) => void]} 
         */
        processName: useState(''),

        /** 
         * Stores the username of the process owner.
         * @type {[string, (username: string) => void]} 
         */
        processUsername: useState(''),

        /** 
         * Unique identifier for the process.
         * @type {[string, (id: string) => void]} 
         */
        processId: useState(''),

        /** 
         * Stores the process description/documentation.
         * @type {[string, (desc: string) => void]} 
         */
        processDesc: useState(''),

        /** 
         * Manages external references or links for the process.
         * @type {[string, (ref: string) => void]} 
         */
        processReference: useState(''),

        /** 
         * Controls process visibility. True means private, false means public.
         * @type {[boolean, (isPrivate: boolean) => void]} 
         */
        processIsPrivate: useState(true),

        /** 
         * Manages additional users who have ownership permissions.
         * @type {[Array<AdditionalOwnerUser>, (owners: Array<AdditionalOwnerUser>) => void]} 
         */
        processAdditionalOwners: useState<AdditionalOwnerUser[]>([]),

        /** 
         * Controls whether the process is in read-only mode.
         * @type {[boolean, (readOnly: boolean) => void]} 
         */
        processIsReadOnly: useState(false),

        /** 
         * Manages groups for organizing Blox elements.
         * @type {[Array<{id: string, groupName: string}>, (groups: Array<{id: string, groupName: string}>) => void]} 
         */
        processGroups: useState<{id: string, groupName: string}[]>([]),

        /** 
         * Organizes Blox into sections. Initialized with one section containing the StartBlox.
         * @type {[Array<Section>, (sections: Array<Section>) => void]} 
         */
        processSections: useState<Section[]>([{
            sectionId: uuidv4(),
            bloxIds: [startBlox].map(blox => blox.id)
        }]),

        /** 
         * Manages GDS (Graphic Design System) information for the process.
         * @type {[undefined | GdsInfo, (gdsInfo: GdsInfo) => void]} 
         */
        processGdsInfo: useState<undefined | GdsInfo>(undefined)
    }
}