/**
 * @fileoverview BloxListItem component is a core UI element that displays individual process blocks in a list format.
 * It provides a rich, interactive visualization of process blocks with support for both regular and start blocks.
 * 
 * Key Features:
 * - Displays block metadata (name, properties, tools)
 * - Supports SVG visualization with zoom capability
 * - Handles both regular process blocks and special start blocks
 * - Provides property formatting with units
 * - Integrates with export functionality
 * 
 * Component Hierarchy:
 * BloxListItem
 * ├─ Block Metadata (name, step number, tools)
 * ├─ Properties Display
 * └─ SVG Visualization (optional)
 *    └─ Export Dialog
 * 
 * State Management:
 * - Local state for zoom dialog visibility
 * - Prop-driven display states
 * - Export dialog integration
 * 
 * @component
*/

// External Dependencies
import React, { useState } from 'react';
import { Typography, Box, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// Internal Dependencies
import { BloxTypes, LayerProperties, AllBloxes } from '../../Data/BloxSchema/base-blox';
import { bloxKeyFields } from '../../Data/BloxKeyFields';
import { Units } from '../../Data/enums';
import { displayMap } from '../../Data/display-mappings';
import { BloxSvg } from '../svg/BloxSvg';
import { bloxListItemStyles } from './styles/BloxListItem.styles';
import { ExportDialog } from '../../dialogs/ExportDialog';

/**
 * Props interface for the BloxListItem component.
 * Defines the complete set of properties required to render a process block item.
 * 
 * @interface BloxListItemProps
 * @template T - The type of items being rendered
 * 
 * @property {number} stepNumber - Sequential number of this block in the process flow
 * @property {string} bloxName - Display name of the process block
 * @property {Object} bloxProperties - Key-value pairs of the block's properties and settings
 * @property {string[]} [tools] - Optional array of tool names associated with this block
 * @property {boolean} [isStartBlox] - Flag indicating if this is the starting block of the process
 * @property {BloxTypes} bloxType - Type identifier for the block (e.g., Deposition, Etch)
 * @property {boolean} [isSelected] - Flag indicating if this block is currently selected
 * @property {React.ReactElement} [bloxSVG] - Optional SVG visualization of the block's effect
 * @property {AllBloxes[]} [allBloxesUpToThisPoint] - Array of all blocks up to this point for context
 * @property {Array<{sectionId: string, sectionName?: string, bloxIds: string[]}>} [processSections] - Section information for process organization
 * @property {number} [initialIndex] - Initial index for SVG export dialog positioning
 */
interface BloxListItemProps {
  stepNumber: number;
  bloxName: string;
  bloxProperties: { [key: string]: any };
  tools?: string[];
  isStartBlox?: boolean;
  bloxType: BloxTypes;
  isSelected?: boolean;
  bloxSVG?: React.ReactElement;
  allBloxesUpToThisPoint?: AllBloxes[];
  processSections?: { sectionId: string; sectionName?: string; bloxIds: string[] }[];
  initialIndex?: number;
}

/**
 * Formats a property value with appropriate units if available.
 * Handles null/undefined values and unit conversion.
 * 
 * @param {any} value - The raw property value to format
 * @param {string} key - The property key name
 * @param {Object} bloxProperties - Complete properties object containing unit information
 * @returns {string} Formatted value with units if applicable
 */
const formatValue = (value: any, key: string, bloxProperties: { [key: string]: any }): string => {
  if (value === null || value === undefined) return 'N/A';
  
  const unitKey = `${key}Unit`;
  if (bloxProperties[unitKey]) {
    return `${value} ${bloxProperties[unitKey]}`;
  }
  
  return String(value);
};

/**
 * SVG container component that handles both visualization and export functionality
 */
const SvgContainer: React.FC<{
  bloxSVG?: React.ReactElement;
  bloxName: string;
  stepNumber: number;
  bloxProperties: { [key: string]: any };
  bloxType: BloxTypes;
  allBloxesUpToThisPoint?: AllBloxes[];
  processSections?: { sectionId: string; sectionName?: string; bloxIds: string[] }[];
  initialIndex?: number;
  isStartBlox?: boolean;
}> = ({
  bloxSVG,
  bloxName,
  stepNumber,
  bloxProperties,
  bloxType,
  allBloxesUpToThisPoint,
  processSections,
  initialIndex,
  isStartBlox
}) => {
  const [isZoomDialogOpen, setIsZoomDialogOpen] = useState(false);

  const handleSvgClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsZoomDialogOpen(true);
  };

  if (!bloxSVG && !isStartBlox) return null;

  const svgContent = isStartBlox ? (
    <Box sx={bloxListItemStyles.startBloxIcon}>
      <BloxSvg bloxType={BloxTypes.StartBlox} className="blox-library-image" />
    </Box>
  ) : (
    <Box 
      sx={[bloxListItemStyles.svgContainer, { cursor: 'pointer' }] as any}
      onClick={handleSvgClick}
      className="svg-container"
    >
      {bloxSVG}
      <Box className="zoom-icon">
        <ZoomInIcon />
      </Box>
    </Box>
  );

  return (
    <>
      {svgContent}
      {bloxSVG && !isStartBlox && (
        <ExportDialog
          isOpen={isZoomDialogOpen}
          closeDialog={() => setIsZoomDialogOpen(false)}
          filename={`${bloxName}_step_${stepNumber}`}
          bloxes={allBloxesUpToThisPoint || [{
            ...bloxProperties,
            name: bloxName,
            id: bloxProperties.id || `temp_${stepNumber}`,
            bloxType: bloxType
          }]}
          initialIndex={initialIndex || stepNumber}
          processSections={processSections}
        />
      )}
    </>
  );
};

/**
 * BloxListItem Component
 * A versatile component for displaying process blocks in a list format.
 * Supports both regular process blocks and special start blocks with different visual representations.
 * 
 * Features:
 * - Responsive layout with flexible content areas
 * - Interactive SVG visualization with zoom capability
 * - Property formatting with unit support
 * - Section-aware rendering
 * - Export dialog integration
 * 
 * @component
 */
const BloxListItem: React.FC<BloxListItemProps> = ({ 
  stepNumber, 
  bloxName, 
  bloxProperties, 
  tools = [], 
  isStartBlox = false, 
  bloxType, 
  isSelected = false, 
  bloxSVG,
  allBloxesUpToThisPoint,
  processSections,
  initialIndex
}) => {
  // Render start block content
  const renderStartBlock = () => (
    <>
      <SvgContainer
        isStartBlox={true}
        bloxType={bloxType}
        bloxName={bloxName}
        stepNumber={stepNumber}
        bloxProperties={bloxProperties}
      />
      <Box sx={bloxListItemStyles.contentContainer}>
        <Typography variant="body1" sx={bloxListItemStyles.title}>
          {bloxName}
        </Typography>
        <Typography variant="caption" sx={bloxListItemStyles.subtitle}>
          Start
        </Typography>
      </Box>
      <Box sx={bloxListItemStyles.propertiesContainer(!!bloxSVG)} className="start-blox-properties">
        {(bloxProperties.layers as LayerProperties[])?.slice().reverse().map((layer, index, array) => (
          <Box key={array.length - index - 1} sx={bloxListItemStyles.startBloxPropertyRow}>
            <Tooltip 
              title={layer.layerLabel || `Layer ${array.length - index}`}
              placement="top"
              enterDelay={500}
            >
              <Typography variant="caption" sx={bloxListItemStyles.startBloxPropertyLabel}>
                {layer.layerLabel || `Layer ${array.length - index}`}
              </Typography>
            </Tooltip>
            <Typography variant="body2" sx={bloxListItemStyles.startBloxPropertyValue}>
              {layer.layerSimulationThickness} {layer.layerSimulationThicknessUnit || Units.NM}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );

  // Render regular block content
  const renderRegularBlock = () => (
    <>
      <Box sx={bloxListItemStyles.stepNumberIcon}>
        {stepNumber}
      </Box>
      <Box sx={bloxListItemStyles.contentContainer}>
        <Typography variant="body1" sx={[bloxListItemStyles.title, bloxListItemStyles.titleVariant(!!tools.length)] as any}>
          {bloxName}
        </Typography>
        {tools.length > 0 && (
          <Typography variant="caption" sx={bloxListItemStyles.subtitle}>
            {tools.join(' • ')}
          </Typography>
        )}
      </Box>
      <Box sx={bloxListItemStyles.propertiesContainer(!!bloxSVG)}>
        {bloxKeyFields[bloxType].slice(0, 3).map((key: string) => (
          <Box key={key} sx={bloxListItemStyles.propertyRow}>
            <Tooltip
              title={displayMap[bloxType]?.[key]?.label || key.replace(/([A-Z])/g, ' $1').trim()}
              placement="top"
              enterDelay={500}
            >
              <Typography variant="caption" sx={bloxListItemStyles.propertyLabel}>
                {displayMap[bloxType]?.[key]?.label || key.replace(/([A-Z])/g, ' $1').trim()}
              </Typography>
            </Tooltip>
            <Typography variant="body2" sx={bloxListItemStyles.propertyValue}>
              {typeof bloxProperties[key] === 'object' && bloxProperties[key] !== null 
                ? JSON.stringify(bloxProperties[key]) 
                : formatValue(bloxProperties[key], key, bloxProperties)}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );

  return (
    <Box 
      sx={[bloxListItemStyles.container, bloxListItemStyles.containerVariants(isSelected)] as any}
      className={isStartBlox ? 'start-blox' : ''}
      id={`item-${bloxProperties.id}`}
    >
      {isStartBlox ? renderStartBlock() : renderRegularBlock()}
      {!isStartBlox && (
        <SvgContainer
          bloxSVG={bloxSVG}
          bloxName={bloxName}
          stepNumber={stepNumber}
          bloxProperties={bloxProperties}
          bloxType={bloxType}
          allBloxesUpToThisPoint={allBloxesUpToThisPoint}
          processSections={processSections}
          initialIndex={initialIndex}
        />
      )}
    </Box>
  );
};

export default BloxListItem;