import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useFabuState } from './state/use-fabu-state';
import { debounce } from 'lodash';

/**
 * Configuration interface for the panel manager hook.
 * Defines the behavior and interaction settings for the panel.
 * 
 * @interface PanelConfig
 * @property {Object} [keyboardShortcut] - Optional keyboard shortcut configuration
 * @property {string} keyboardShortcut.key - The key that triggers the shortcut (e.g., '\\')
 * @property {boolean} [keyboardShortcut.ctrl] - Whether Control key is required
 * @property {boolean} [keyboardShortcut.alt] - Whether Alt key is required
 * @property {boolean} [keyboardShortcut.shift] - Whether Shift key is required
 * @property {boolean} [autoOpenOnSelection] - Whether the panel should auto-open when a blox is selected (default: false)
 */
interface PanelConfig {
  keyboardShortcut?: {
    key: string;
    ctrl?: boolean;
    alt?: boolean;
    shift?: boolean;
  };
  autoOpenOnSelection?: boolean;
}

/**
 * A custom hook that manages the state and behavior of a panel component.
 * Provides functionality for:
 * - Opening/closing the panel
 * - Handling transitions
 * - Managing keyboard shortcuts
 * - Auto-opening when items are selected (optional)
 * 
 * Features:
 * 1. Debounced state updates to prevent rapid changes
 * 2. Configurable keyboard shortcuts
 * 3. Optional automatic panel opening on item selection
 * 4. Transition state management
 * 
 * @param {PanelConfig} config - Configuration object for the panel
 * @returns {Object} Panel management methods and state
 * @property {boolean} isPanelOpen - Current open state of the panel
 * @property {boolean} isTransitioning - Whether the panel is currently transitioning
 * @property {() => void} togglePanel - Function to toggle the panel open/closed
 * @property {(value: boolean) => void} setIsPanelOpen - Function to directly set panel state
 */
export const usePanelManager = (config: PanelConfig) => {
  // Default config
  const { autoOpenOnSelection = false } = config;
  
  // Panel state management
  const [isPanelOpen, setIsPanelOpen] = useFabuState('rightPanelOpenState');
  const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Create a stable debounced function that won't change on re-renders
  const debouncedSetIsPanelOpen = useMemo(
    () => debounce((value: boolean) => setIsPanelOpen(value), 10),
    [setIsPanelOpen]
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSetIsPanelOpen.cancel();
    };
  }, [debouncedSetIsPanelOpen]);

  /**
   * Handles the end of panel transitions
   * Resets transition flags
   */
  const handleTransitionEnd = useCallback(() => {
    setIsTransitioning(false);
  }, []);

  /**
   * Toggles the panel open/closed state
   * Manages transition state during the toggle
   * @param {boolean} keepSelection - Whether to maintain the current blox selection (default: false)
   */
  const togglePanel = useCallback((keepSelection = false) => {
    setIsTransitioning(true);
    // Only clear selection if:
    // 1. The panel is open (we're closing it)
    // 2. AND we're not explicitly keeping the selection
    // 3. AND there is a selection to clear
    if (isPanelOpen && !keepSelection && selectedBloxId) {
      setSelectedBloxId("");
    }
    debouncedSetIsPanelOpen(!isPanelOpen);
  }, [isPanelOpen, debouncedSetIsPanelOpen, setSelectedBloxId, selectedBloxId]);

  // Effect: Auto-open panel when a blox is selected (only if autoOpenOnSelection is true)
  useEffect(() => {
    if (autoOpenOnSelection && selectedBloxId && !isPanelOpen) {
      setIsTransitioning(true);
      debouncedSetIsPanelOpen(true);
    }
  }, [autoOpenOnSelection, selectedBloxId, isPanelOpen, debouncedSetIsPanelOpen]);

  // Effect: Handle keyboard shortcuts
  useEffect(() => {
    if (config.keyboardShortcut) {
      const { key, ctrl, alt, shift } = config.keyboardShortcut;
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === key && 
            (!ctrl || e.ctrlKey) && 
            (!alt || e.altKey) && 
            (!shift || e.shiftKey)) {
          togglePanel(true);
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [config.keyboardShortcut, togglePanel]);

  // Effect: Handle panel transitions
  useEffect(() => {
    window.addEventListener('rightPanelTransitionEnd', handleTransitionEnd);
    return () => {
      window.removeEventListener('rightPanelTransitionEnd', handleTransitionEnd);
    };
  }, [handleTransitionEnd]);

  return {
    isPanelOpen,
    isTransitioning,
    togglePanel,
    setIsPanelOpen: debouncedSetIsPanelOpen
  };
}; 