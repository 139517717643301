import React, { useMemo, useState } from 'react';
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import UploadGdsDialog from '../dialogs/GdsUpload/GdsUploadDialog';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { useIsProcessSaved } from './hooks/use-is-process-saved';

interface SimulateButtonProps {
    userDataIsLoading: boolean;
    stackSimulatorEnabled: boolean;
    setIsSemifabDialogOpen: (open: boolean) => void;
}

const SimulateButton: React.FC<SimulateButtonProps> = ({
    userDataIsLoading,
    stackSimulatorEnabled,
    setIsSemifabDialogOpen,
}) => {
    const [isGdsDialogOpen, setIsGdsDialogOpen] = useState(false); // State for GDS Upload Dialog
    const [gdsInfo] = useFabuState('processGdsInfo');
    const [processId] = useFabuState('processId');

    const isSavedProcess = useIsProcessSaved();
    const disableGdsButton = !isSavedProcess || userDataIsLoading || stackSimulatorEnabled === false;
    const handleOpenGdsDialog = () => {
        setIsGdsDialogOpen(true);
    };

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            {/* Simulate Button */}
            <Button
                style={{ padding: '0 5px', marginRight: '10px', marginLeft: '5px' }}
                minimal
                large
                onClick={() => setIsSemifabDialogOpen(true)}
                text="Simulate"
                disabled={userDataIsLoading || stackSimulatorEnabled === false}
            />

            {/* GDS Button with Tooltip */}
            <Tooltip
                content={!isSavedProcess ? "Save your process before uploading a GDS file." : undefined}
                disabled={isSavedProcess}
            >
                <Button
                    style={{ marginRight: '10px', backgroundColor: 'var(--panel-background-color)' }}
                    icon={<Icon icon={IconNames.SETTINGS} />}
                    text="GDS"
                    small={true}
                    disabled={disableGdsButton}
                    onClick={handleOpenGdsDialog}
                />
            </Tooltip>

            {/* Upload GDS Dialog */}
            <UploadGdsDialog 
                isOpen={isGdsDialogOpen}
                setIsOpen={setIsGdsDialogOpen} // Pass the state setter to manage dialog visibility
                savedGdsInfo={gdsInfo} // Pass the current
            />
        </div>
    );
};

export default SimulateButton;
