import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { NotificationsProps } from './types';

/**
 * Component to handle success and error notifications
 */
const Notifications: React.FC<NotificationsProps> = ({
  showSuccess,
  setShowSuccess,
  showError,
  setShowError,
  errorMessage
}) => (
  <>
    <Snackbar 
      open={showSuccess} 
      autoHideDuration={6000} 
      onClose={() => setShowSuccess(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => setShowSuccess(false)} severity="success" variant="filled">
        Message sent successfully to the MOSIS2.0 team!
      </Alert>
    </Snackbar>

    <Snackbar 
      open={showError} 
      autoHideDuration={6000} 
      onClose={() => setShowError(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => setShowError(false)} severity="error" variant="filled">
        {errorMessage || 'Failed to send message. Please try again. If the issue persists contact the MOSIS2.0 team directly using the "Contact Us" link at the top of the page.'}
      </Alert>
    </Snackbar>
  </>
);

export default Notifications; 