import { useState, useEffect, useRef } from "react";
import { Row, Column } from "../Layout/layouts";
import { RightPanel } from "./RightPanel";
import { BloxLibrary } from "./BloxLibrary";
import { RightPanelBloxInfo } from "./RightPanelBloxInfo";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { usePanelManager } from "../hooks/use-panel-manager";
import { debounce } from "lodash";

/**
 * Enum representing the possible page views for the FabuPage component.
 */
export const enum PageNames {
    ProcessEditor,
    ModuleEditor,
    Viewer
}

/**
 * Props interface for FabuPage component.
 * 
 * @interface FabuPageProps
 * @property {PageNames} pageName - The current page view for the component.
 * @property {JSX.Element} headerContent - Content to display in the header section.
 * @property {React.ReactNode} children - Child elements rendered in the main content area.
 */
export interface FabuPageProps { 
    pageName: PageNames, 
    headerContent: JSX.Element,
    children: React.ReactNode
}

/**
 * The FabuPage component provides the main layout structure for a FabuBlox page.
 * It includes a header, main content area, and optional footer with a right-side panel.
 * The layout and visibility of sections vary depending on the page view.
 * 
 * Features:
 * - Responsive Layout:
 *   - Collapsible right panel
 * - Interactive Elements:
 *   - Keyboard shortcuts (Ctrl + \)
 *   - Smooth transitions
 * - Context-Aware:
 *   - Different layouts for editor/viewer modes
 *   - Blox library integration
 *   - Right panel information display
 * 
 * @param {FabuPageProps} props - Props object adhering to FabuPageProps interface.
 * @returns {JSX.Element} Rendered FabuPage component.
 */
export const FabuPage: React.FC<FabuPageProps> = (props) => {
    const { pageName, children } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const viewMode = queryParams.get('view');
    const isListView = viewMode === 'list';
    const isTableView = viewMode === 'table';
    const [isFooterOpen, setIsFooterOpen] = useState(!isListView && !isTableView);
    const rightPanelRef = useRef<HTMLDivElement>(null);
    
    const { 
        isPanelOpen, 
        isTransitioning,
        togglePanel
    } = usePanelManager({
        keyboardShortcut: { key: '\\', ctrl: true },
        autoOpenOnSelection: false
    });

    useEffect(() => {
        if (isListView || isTableView) {
            setIsFooterOpen(false);
        }
    }, [isListView, isTableView]);

    // Handle responsive behavior
    useEffect(() => {
        const handleResize = debounce(() => {
            if (window.innerWidth < 768 && isPanelOpen) {
                togglePanel(false);
            }
        }, 150);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isPanelOpen, togglePanel]);

    const containerClassNames = classNames("leftContainer", {
        "leftContainer--expanded": !isPanelOpen,
        "leftContainer--transitioning": isTransitioning
    });

    return (
        <Row style={{"alignItems": "center", "height": "calc(100% - 50px)" }}> 
            <Column className={containerClassNames}>
                {props.headerContent}
                <Column className="processContainer">
                    {children}
                </Column>
                {pageName !== PageNames.Viewer &&
                    <Column className={"editor-footer"}>
                        <BloxLibrary pageName={pageName} isOpenState={[isFooterOpen, setIsFooterOpen]} />
                    </Column>
                }
            </Column>
            <RightPanel 
                ref={rightPanelRef}
                isOpen={isPanelOpen}
                onToggle={() => togglePanel(true)}
            >
                <RightPanelBloxInfo/>
            </RightPanel>
        </Row>
    );
}