import React, { useCallback, useMemo, useState } from "react";
import { InputGroup, Popover } from "@blueprintjs/core";
import { SketchPicker, ColorResult } from "react-color";
import { Color, IColor } from "../../utils/Color";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { materialFieldMap } from "../../Data/material-mappings";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { SelectedBloxMaterialProperties } from "../../dialogs/MaterialDialog/hooks/use-material-handlers";
import { getColorForBlox, replaceSpecificBloxMaterial } from "../../hooks/material-targets";

interface MaterialSelectorProps {
  value: { name: string; color: IColor };
  materialInfo: SelectedBloxMaterialProperties;
  blox?: AllBloxes
  setMaterialDialogOpen: (open: boolean) => void;
  setMaterial: () => void;
  disabled?: boolean;
  label: string;
  bloxType: BloxTypes;
  removeButton?: JSX.Element;
}

export default function MaterialSelectorField({
  value,
  materialInfo,
  blox,
  setMaterial,
  setMaterialDialogOpen,
  disabled = false,
  label,
  bloxType,
  removeButton,
}: MaterialSelectorProps) {
  const [colorPopoverOpen, setColorPopoverOpen] = useState(false);
  const [processBloxes, setProcessBloxes] = useFabuState("processBloxes");

  const materialColor = useMemo(() => blox ? getColorForBlox(blox, materialInfo.property) : value.color, [blox, materialInfo, value.color]);

  const updateMaterialColor = useCallback(
    (materialName: string, color: IColor) => {
      const updated = processBloxes.map((blox) => {
        return replaceSpecificBloxMaterial(
          blox,
          materialInfo, // the material properties to use for the update
          materialName, // newMaterialName
          materialName, // oldMaterialName (same, since we’re only updating the color)
          color         // the new color
        );
      });
      setProcessBloxes(updated);
    },
    [processBloxes, setProcessBloxes, materialInfo]
  );

  const handleButtonClick = useCallback(() => {
    if (disabled) return;
    setMaterial();
    setMaterialDialogOpen(true);
  }, [setMaterial, setMaterialDialogOpen, disabled]);

  // Use the provided material name or a fallback label from the materialFieldMap.
  let material = value.name ? value.name : null;
  if (!material) {
    material = materialFieldMap[bloxType]?.unknownLabel ?? "Unknown Material";
  }

  const handleColorChange = useCallback(
    (colorResult: ColorResult) => {
      const newColor: IColor = {
        R: colorResult.rgb.r,
        G: colorResult.rgb.g,
        B: colorResult.rgb.b,
        A: colorResult.rgb.a ?? 1,
      };
      updateMaterialColor(value.name, newColor);
    },
    [updateMaterialColor, value.name]
  );

  const getColorPicker = () => {
    if (disabled) return undefined;
    return (<Popover
      isOpen={colorPopoverOpen}
      onClose={() => setColorPopoverOpen(false)}
      content={
        <SketchPicker
          color={Color.getCSS(value.color)}
          onChange={handleColorChange}
        />
      }
      hasBackdrop
    >
      <div
        onClick={(e: React.MouseEvent) => {
          // Prevent the InputGroup's onClick from firing.
          e.preventDefault();
          e.stopPropagation();
          setColorPopoverOpen(true);
        }}
        style={{
          backgroundColor: Color.getCSS(materialColor),
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          border: "1px solid #ccc",
          marginRight: "7px",
          marginTop: "7px",
          marginLeft: "3px",
          cursor: "pointer",
        }}
      />
    </Popover>)
  }

  return (
    <div style={{ width: "100%" }}>
      {/* Row 1: Label text and remove button */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "2px",
        }}
      >
        <span style={{ fontSize: "14px", fontWeight: 500, color: "#333" }}>
          {label}
        </span>
        {removeButton}
      </div>

      {/* Row 2: Input group */}
      <div style={{ marginBottom: "8px" }}>
        <InputGroup
          value={material}
          onClick={handleButtonClick}
          readOnly
          disabled={disabled}
          style={{
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "40px", // ensure text doesn't overlap the preview
          }}
          rightElement={getColorPicker()}
        />
      </div>
    </div>
  );
}
