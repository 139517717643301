import React, { useCallback } from 'react';
import { Box, Breadcrumbs, Button, Tooltip } from '@mui/material';
import { Section } from '../__generated__/Process';
import BreadcrumbSeparator from './BreadcrumbSeparator';

// Styles
const styles = {
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0',
    flex: 1,
  },
  breadcrumbs: {
    width: '100%',
    overflowX: 'auto',
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
      rowGap: '4px',
      margin: 0,
      padding: '2px 4px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-start',
      minWidth: 'min-content'
    },
    '& .MuiBreadcrumbs-li': {
      margin: 0,
      padding: '1px',
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      maxWidth: '100%'
    },
    '& .MuiBreadcrumbs-separator': {
      margin: 0,
      color: 'var(--text-secondary)',
      opacity: 0.8,
      flex: '0 0 auto'
    }
  },
  separator: {
    color: 'var(--text-secondary)',
    fontSize: 'var(--font-size-base)',
    fontWeight: 'var(--font-weight-regular)',
    mx: 0.5,
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8
  },
  sectionButton: (isClosed: boolean, isActive: boolean) => ({
    minWidth: 'auto',
    maxWidth: '1200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '2px 10px',
    fontSize: 'var(--font-size-base)',
    lineHeight: 1.2,
    textAlign: 'left',
    textTransform: 'none',
    fontFamily: 'var(--font-primary)',
    fontWeight: isActive ? 'var(--font-weight-semibold)' : 'var(--font-weight-medium)',
    color: isActive ? 'var(--primary-blue)' : (isClosed ? 'var(--text-secondary)' : 'var(--text-primary)'),
    backgroundColor: isActive ? 'var(--primary-blue-light)' : 'transparent',
    borderRadius: '16px',
    height: '24px',
    border: '1px solid',
    borderColor: isActive ? 'var(--primary-blue)' : 'transparent',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: isActive ? 'var(--primary-blue-lighter)' : 'var(--background-lighter)',
      color: isActive ? 'var(--primary-blue-active)' : 'var(--text-primary)',
      transform: 'translateY(-1px)',
      boxShadow: `0 2px 4px rgba(var(--black-rgb), 0.1)`,
    },
    '&:active': {
      transform: 'translateY(0)',
      boxShadow: 'none'
    }
  })
};

// Types
/**
 * Props interface for the SectionBreadcrumbs component
 * @interface SectionBreadcrumbsProps
 * @property {Section[]} sections - Array of sections to display in the navigation
 * @property {{ [key: string]: boolean }} closedSections - Map tracking section collapse states
 * @property {React.RefObject<HTMLDivElement>} containerRef - Reference to the scrollable container
 * @property {(sectionId: string) => void} onSectionClick - Callback for section click events
 * @property {string | null} activeSection - ID of the currently visible/active section
 */
export interface SectionBreadcrumbsProps {
  sections: Section[];
  closedSections: { [key: string]: boolean };
  containerRef: React.RefObject<HTMLDivElement>;
  onSectionClick: (sectionId: string) => void;
  activeSection: string | null;
}

/**
 * Props interface for individual section buttons
 * @interface SectionButtonProps
 * @property {Section} section - Section data for this button
 * @property {number} index - Index of the section in the list
 * @property {boolean} isClosed - Whether the section is currently collapsed
 * @property {boolean} isActive - Whether the section is currently visible/active
 * @property {() => void} onClick - Click handler for the button
 */
interface SectionButtonProps {
  section: Section;
  index: number;
  isClosed: boolean;
  isActive: boolean;
  onClick: () => void;
}

/**
 * Hook for handling smooth scrolling to sections with proper timing and animations
 * 
 * Key Features:
 * - Smooth scrolling to target sections
 * - Automatic section expansion when needed
 * - Proper timing for animations and transitions
 * - Viewport position calculation and adjustment
 * 
 * Timing Sequence:
 * 1. For closed sections:
 *    - Triggers section expansion
 *    - Waits for expansion animation (300ms)
 *    - Executes smooth scroll
 * 2. For open sections:
 *    - Immediately executes smooth scroll
 * 
 * Position Calculation:
 * - Accounts for container scroll position
 * - Considers fixed header height
 * - Adds padding for visual comfort
 * - Prevents scrolling past container bounds
 * 
 * @param containerRef - Reference to the scrollable container
 * @param closedSections - Map of section collapse states
 * @param onSectionClick - Callback to handle section toggle
 */
const useScrollToSection = (
  containerRef: React.RefObject<HTMLDivElement>,
  closedSections: { [key: string]: boolean },
  onSectionClick: (sectionId: string) => void
) => {
  return useCallback((sectionId: string) => {
    // Only open the section if it's closed, never close it
    if (closedSections[sectionId]) {
      onSectionClick(sectionId);
    }

    const executeScroll = (immediate = false) => {
      const scrollLogic = () => {
        const sectionHeader = document.getElementById(`item-${sectionId}`);
        if (sectionHeader && containerRef.current) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const headerRect = sectionHeader.getBoundingClientRect();
          const headerTop = headerRect.top - containerRect.top + containerRef.current.scrollTop;

          // Account for fixed header if present
          const fixedHeaderHeight = document.querySelector('.MuiAppBar-root')?.getBoundingClientRect().height || 0;

          // Calculate final position with padding
          const scrollPosition = headerTop - fixedHeaderHeight;

          containerRef.current.scrollTo({
            top: Math.max(0, scrollPosition),
            behavior: 'smooth'
          });
        }
      };

      if (immediate) {
        scrollLogic();
      } else {
        requestAnimationFrame(scrollLogic);
      }
    };

    // Handle timing based on section state
    if (closedSections[sectionId]) {
      setTimeout(() => executeScroll(), 300); // Wait for section to expand
    } else {
      executeScroll(true);
    }
  }, [onSectionClick, containerRef, closedSections]);
};

/**
 * Individual section button component with tooltip
 * 
 * Features:
 * - Tooltip for long section names
 * - Visual feedback for section states:
 *   - Collapsed/expanded state
 *   - Active/inactive state
 *   - Hover and click animations
 * - Accessible button behavior
 * 
 * @component
 */
const SectionButton: React.FC<SectionButtonProps> = ({
  section,
  index,
  isClosed,
  isActive,
  onClick
}) => {
  const displayName = section.sectionName || 'Untitled Section';
  
  return (
    <Tooltip 
      title={displayName}
      placement="bottom"
      arrow
      enterDelay={500}
    >
      <Button
        onClick={onClick}
        sx={styles.sectionButton(isClosed, isActive)}
      >
        {displayName}
      </Button>
    </Tooltip>
  );
};

/**
 * SectionBreadcrumbs Component
 * 
 * Primary navigation component for the list view that provides:
 * 
 * Core Features:
 * - Section state visualization
 * - Smooth scrolling navigation
 * - Dynamic section highlighting
 * - Responsive layout
 * 
 * Visual Features:
 * - Active section highlighting
 * - Collapse state indication
 * - Hover and click animations
 * - Tooltips for long names
 * 
 * Behavior:
 * - Smooth scrolling to sections
 * - Automatic section expansion
 * - Dynamic viewport tracking
 * - Proper animation timing
 * 
 * Layout:
 * - Horizontal scrolling with wrapping
 * - Consistent spacing and alignment
 * - Proper overflow handling
 * - Mobile-friendly design
 * 
 * @component
 */
const SectionBreadcrumbs: React.FC<SectionBreadcrumbsProps> = ({
  sections,
  closedSections,
  onSectionClick,
  containerRef,
  activeSection
}) => {
  const handleSectionClick = useScrollToSection(containerRef, closedSections, onSectionClick);

  // Don't render if there's only one or no sections
  if (sections.length <= 1) return null;

  return (
    <Box sx={styles.root}>
      <Breadcrumbs
        maxItems={999}
        itemsBeforeCollapse={999}
        itemsAfterCollapse={999}
        separator={<BreadcrumbSeparator />}
        sx={styles.breadcrumbs}
      >
        {sections.map((section, index) => (
          <SectionButton
            key={section.sectionId}
            section={section}
            index={index}
            isClosed={closedSections[section.sectionId]}
            isActive={section.sectionId === activeSection}
            onClick={() => handleSectionClick(section.sectionId)}
          />
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default SectionBreadcrumbs;