import React, { useCallback, useMemo, useState } from 'react';
import { Button, Callout, ContextMenu, Divider, Menu, MenuItem, Tab, Tabs } from "@blueprintjs/core";
import { Column, Row } from '../Layout/layouts';
import { BloxDraggable } from './BloxDraggable';
import { AllBloxes, BloxTypes, bloxNamesMap } from '../Data/BloxSchema/base-blox';
import { useAuth0 } from '@auth0/auth0-react';
import { useBloxHandlers } from './hooks/use-blox-handlers';
import { IconName, IconNames } from '@blueprintjs/icons';
import { BloxDialog } from '../dialogs/BloxDialog';
import { GroupSharingInfo, SharingBloxDialog } from '../dialogs/SharingBloxDialog';
import { LibCategory } from '../Data/enums';
import { Select } from '@blueprintjs/select';
import { libCategoryMap } from '../Data/lib-category-mappings';
import { StateAndSetter } from '../hooks/state/parameter-context';
import { Blox } from '../__generated__/Blox';
import { Loading } from './Loading';
import { ErrorCallout } from './ErrorCallout';
import { useOwnedBloxes, useSharedBloxes } from '../hooks/DataFetching/use-fetch-blox';
import { useMyGroups } from '../hooks/DataFetching/use-fetch-groups';
import { PageNames } from './FabuPage';
import { useGroupProcesses, useOwnedProcesses } from '../hooks/DataFetching/use-fetch-process';
import { ModuleDraggable } from './ModuleDraggable';

export interface BloxLibraryProps {
    isOpenState: StateAndSetter<boolean>,
    pageName: PageNames
}

export type LibraryTabs = "MY_BLOX" | "TEMPLATE_BLOX" | "GROUP_BLOX" | "MY_MODULES" | "GROUP_MODULES";


export const BloxLibrary: React.FC<BloxLibraryProps> = (props) => {
    const [isOpen, setIsOpen] = props.isOpenState;
    const [selectedTab, setSelectedTab] = useState<LibraryTabs>("TEMPLATE_BLOX");
    const [selectedBlox, setSelectedBlox] = useState<Blox | null>(null)
    const [selectedCategory, setSelectedCategory] = useState<LibCategory | null>(null);

    const {
        data: myBlox,
        isLoading: isLoadingMyBlox,
        error: errorMyBlox,
    } = useOwnedBloxes();

    const {
        data: groupBlox,
        isLoading: isLoadingGroupBlox,
        error: errorGroupBlox,
    } = useSharedBloxes();

    const {
        data: myGroups,
        isLoading: isLoadingMyGroups,
        error: errorMyGroups,
    } = useMyGroups();

    const {
        data: myModules,
        isLoading: isLoadingMyModules,
        error: errorMyModules,
    } = useOwnedProcesses(true);

    const {
        data: allGroupModules,
        isLoading: isLoadingGroupModules,
        error: errorGroupModules,
    } = useGroupProcesses(true);

    const groupModules = useMemo(() => {
        if (!allGroupModules || !myModules) return undefined;
        return allGroupModules.filter(groupModule => {
            return myModules.findIndex(myModule => myModule.processId === groupModule.processId) === -1;
        });
    }, [allGroupModules, myModules]);

    const groupSharingInfo = useMemo<GroupSharingInfo[]>(() => {
        const currGroups = selectedBlox?.groups ?? [];

        return myGroups?.map(group => ({
            id: group.id,
            groupName: group.groupName,
            sharedWith: currGroups.some(currGroup => currGroup.id === group.id) ?? false,
        })) || []
    }, [myGroups, selectedBlox]);


    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const handleOpenEditDialog = useCallback((clickedBlox: Blox) => {
        setSelectedBlox(clickedBlox);
        setIsEditDialogOpen(true);
    }, [setIsEditDialogOpen]);
    const handleOpenShareDialog = useCallback((clickedBlox: Blox) => {
        setSelectedBlox(clickedBlox);
        setIsShareDialogOpen(true);
    }, [setIsShareDialogOpen]);

    const { bloxHandleDelete } = useBloxHandlers();
    const { getAccessTokenSilently } = useAuth0();


    const boundHandleDelete = useCallback(async (id: string) => {
        const token = await getAccessTokenSilently();
        bloxHandleDelete(id, token);
    }, [getAccessTokenSilently, bloxHandleDelete]);

    const getResourceGroupsToShow = useCallback((resourceGroupIds: string[]) => {
        const groupsToShow = [];
        for (const group of (myGroups ?? [])) {
            const { id } = group;
            if (resourceGroupIds.includes(id))
                groupsToShow.push(group.groupName);
        }

        return groupsToShow;

    }, [myGroups]);

    const handleToggleIsOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen, setIsOpen]);

    const handleTabChange = useCallback((tabId: LibraryTabs) => {
        setIsOpen(true);
        setSelectedTab(tabId);
    }, [setIsOpen, setSelectedTab]);

    const renderTemplateTab = () => {
        const disallowedTypes = [
            BloxTypes.StartBlox,
            BloxTypes.SubstrateStack,
            BloxTypes.SpinCoat // deprecated for SpinCoatUnified
            // ... any other BloxTypes you want to exclude
        ];

        let filteredTypes = selectedCategory ? libCategoryMap[selectedCategory] : Object.values(BloxTypes);
        filteredTypes = filteredTypes.filter(type => !disallowedTypes.includes(type));

        return (
            <>
                {filteredTypes.map(bloxType => {
                    if (bloxNamesMap[bloxType]) {
                        return <BloxDraggable key={bloxType} bloxName={bloxNamesMap[bloxType]} bloxType={bloxType} />;
                    }
                    return null;
                })}
            </>
        );
    };

    const renderCategoryDropdown = () => {
        const allCategories = ["All", ...Object.keys(libCategoryMap) as LibCategory[]];

        return (
            <Select
                items={allCategories}
                itemRenderer={(item, { handleClick }) => (
                    <MenuItem key={item} onClick={handleClick} text={item} />
                )}
                onItemSelect={category => {
                    setIsOpen(true);
                    if (category === "All") {
                        setSelectedCategory(null);
                    } else {
                        setSelectedCategory(category as LibCategory);
                    }
                }}
                filterable={false}
                noResults={<MenuItem disabled={true} text="No categories." />}
                popoverProps={{ modifiers: { preventOverflow: { enabled: true } }, rootBoundary: "viewport" }}
            >
                <Button style={{ padding: '0px 0px 0px 7px', margin: '0px 5px 0px 5px' }} text={selectedCategory || "All"} rightIcon="double-caret-vertical" />
            </Select>
        );
    };

    const renderMyBloxTab = () => {
        if (errorMyBlox) return <ErrorCallout />;
        if (isLoadingMyBlox) return <Row style={{ height: '100px', width: '100%' }}><Loading size={30} /></Row>
        if (!myBlox?.length) return renderCallout(IconNames.INFO_SIGN, 'Find Blox with pre-filled values here', "You haven't saved any Blox yet.");
        const selectedCategoryBlox = myBlox.filter(blox => !selectedCategory || libCategoryMap[selectedCategory].includes(blox.bloxData.bloxType))
        if (!selectedCategoryBlox.length) return renderCallout(IconNames.Filter, 'Filter applied', 'Select a different category to see your Blox.');

        return selectedCategoryBlox
            .map((blox) => {
                blox.bloxData.name = blox.bloxName;
                return <>
                    <ContextMenu
                        key={`${blox.bloxId}-context`}
                        content={
                            <Menu key={`${blox.bloxId}-menu`}>
                                <MenuItem key={`${blox.bloxId}-share`} icon={IconNames.PEOPLE} text="Share" onClick={handleOpenShareDialog.bind(this, blox)} />
                                <MenuItem key={`${blox.bloxId}-edit`} icon={IconNames.EDIT} text="Edit" onClick={handleOpenEditDialog.bind(this, blox)} />
                                <MenuItem key={`${blox.bloxId}-delete`} icon="delete" text="Delete" onClick={boundHandleDelete.bind(this, blox.bloxId)} intent="danger" />
                            </Menu>
                        }>
                        <BloxDraggable key={blox.bloxId} data={(blox.bloxData as AllBloxes)} bloxName={blox.bloxName} desc={blox.bloxDescription} bloxType={blox.bloxData.bloxType} />
                    </ContextMenu>
                </>
            });
    }

    const renderGroupBloxTab = () => {
        if (errorGroupBlox || errorMyGroups) return <ErrorCallout />;
        if (isLoadingGroupBlox || isLoadingMyGroups) return <Row style={{ height: '100px', width: '100%' }}><Loading size={30} /></Row>
        if (!groupBlox?.length) return renderCallout(IconNames.INFO_SIGN, 'Find Blox with pre-filled values here', 'You are not part of a group, or no one has shared a blox to your group yet.');
        const selectedCategoryGroups = groupBlox.filter(blox => !selectedCategory || libCategoryMap[selectedCategory].includes(blox.bloxData.bloxType));
        if (!selectedCategoryGroups.length) return renderCallout(IconNames.Filter, 'Filter applied', 'Select a different category to see your Blox.');

        return selectedCategoryGroups
            .map((blox) => {
                blox.bloxData.name = blox.bloxName;
                // only show groups that user is a part of
                const groupsToShow = getResourceGroupsToShow((blox?.groups ?? []).map(g => g.id))
                return <BloxDraggable key={blox.bloxId + 'group'} data={(blox.bloxData as AllBloxes)} bloxName={blox.bloxName} desc={blox.bloxDescription} bloxType={blox.bloxData.bloxType} groupNamesToShow={groupsToShow} />
            });
    }

    const renderCallout = (icon: IconName, title: string, description: string) => (
        <Callout className='library-callout' icon={icon} title={title}>
            <p>{description}</p>
        </Callout>
    );

    const renderMyModulesTab = () => {
        if (errorMyModules) return <ErrorCallout />;
        if (isLoadingMyModules) return <Row style={{ height: '100px', width: '100%' }}><Loading size={30} /></Row>
        if (!myModules?.length) return renderCallout(IconNames.INFO_SIGN, 'Find Modules here', "You haven't created any modules yet.");
        const filteredModules = myModules.filter(module => module.bloxes.length > 1);
        return filteredModules.map(module => {
            const groupsToShow = getResourceGroupsToShow((module.groups ?? []).map(g => g.id))
            return <ModuleDraggable
                data={module.bloxes}
                key={module.processId}
                moduleName={module.processName}
                moduleId={module.processId}
                desc={module.desc}
                imageBloxType={BloxTypes.StartBlox}
                groupNamesToShow={groupsToShow}
            />
        });
    };

    const renderGroupModulesTab = () => {
        if (errorGroupModules) return <ErrorCallout />;
        if (isLoadingGroupModules) return <Row style={{ height: '100px', width: '100%' }}><Loading size={30} /></Row>
        if (!groupModules?.length) return renderCallout(IconNames.INFO_SIGN, 'Find modules shared in groups here', 'You are not part of a group, or no one has shared a module to your group yet.');
        const filteredModules = groupModules.filter(module => module.bloxes.length > 1);
        return filteredModules.map(module => {
            const groupsToShow = getResourceGroupsToShow((module.groups ?? []).map(g => g.id))
            return <ModuleDraggable
                data={module.bloxes}
                key={module.processId + 'group'}
                moduleName={module.processName}
                moduleId={module.processId}
                desc={module.desc}
                imageBloxType={BloxTypes.StartBlox}
                groupNamesToShow={groupsToShow}
            />
        });
    }

    return <Column className={'blox-library-container'}>
        <Row className={`blox-library-tabs ${isOpen ? 'open' : 'closed'}`}>
            <Button minimal={true} onClick={handleToggleIsOpen}>
                {isOpen ? 'Hide' : 'Show'} Library
            </Button>
            <Divider />
            {renderCategoryDropdown()}
            <Divider />
            <Tabs
                id="blox-library"
                onChange={handleTabChange}
            >
                <Tab id="TEMPLATE_BLOX" title="Templates" style={{ marginLeft: '5px', outline: 'none' }} />
                <Tab icon={IconNames.Person} id="MY_BLOX" title="My Blox" style={{ outline: 'none' }} />
                <Tab icon={IconNames.People} id="GROUP_BLOX" title="Shared Blox" style={{ outline: 'none' }} />
                {props.pageName === PageNames.ProcessEditor && <Tab icon={IconNames.MultiSelect} id="MY_MODULES" title="My Modules" style={{ outline: 'none' }} />}
                {props.pageName === PageNames.ProcessEditor && <Tab icon={IconNames.PEOPLE} id="GROUP_MODULES" title="Shared Modules" style={{ outline: 'none' }} />}
            </Tabs>
        </Row>
        <div className={`library-content ${isOpen ? 'open' : 'closed'}`}>
            <Row style={{ overflowX: 'auto' }}>
                {
                    (selectedTab === "TEMPLATE_BLOX" && renderTemplateTab()) ||
                    (selectedTab === "MY_BLOX" && renderMyBloxTab()) ||
                    (selectedTab === "GROUP_BLOX" && renderGroupBloxTab()) ||
                    (selectedTab === "MY_MODULES" && renderMyModulesTab()) ||
                    (selectedTab === "GROUP_MODULES" && renderGroupModulesTab())
                }
                {selectedBlox && <BloxDialog isOpen={isEditDialogOpen} setIsOpen={setIsEditDialogOpen} blox={selectedBlox} bloxData={(selectedBlox.bloxData as AllBloxes)} />}
                {selectedBlox && <SharingBloxDialog key={selectedBlox.bloxId} isOpen={isShareDialogOpen} setIsOpen={setIsShareDialogOpen} blox={selectedBlox} groupSharingInfo={groupSharingInfo} />}
            </Row>
        </div>
    </Column>
}
