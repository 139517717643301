interface UserData {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  affiliation?: string;
  experience?: string;
}

export const getUserDisplayName = (userData: UserData | null | undefined): string => {
  if (!userData) return 'My Profile';
  
  if (userData.firstName && userData.lastName) {
    return `${userData.firstName} ${userData.lastName}`;
  }
  
  return userData.displayName || 'Profile';
};