import { useDrag } from "react-dnd";
import { AllBloxes, BloxTypes, reverseBloxTypes } from "../Data/BloxSchema/base-blox";
import { Draggables } from "../Data/enums";
import { Column } from "../Layout/layouts";
import { Icon, Tooltip } from '@blueprintjs/core';
import { useProcessHandlers } from "./hooks/use-process-handlers";
import { useFabuState } from "../hooks/state/use-fabu-state";
import { useCallback, useEffect } from "react";
import { BloxSvg } from "./svg/BloxSvg";
import { useLocation, useHistory } from "react-router-dom";
import { useBloxActions } from "./hooks/use-blox-actions";

export interface BloxInsertItem {   
    bloxType: BloxTypes;
    data?: AllBloxes;
}

interface BloxDraggableProps {
    bloxType: BloxTypes;
    bloxName: string;
    data?: AllBloxes;
    desc?: string;
    groupNamesToShow?: string[];
}

export const BloxDraggable: React.FC<BloxDraggableProps> = (props): JSX.Element => {
    const { bloxType, bloxName, data, desc, groupNamesToShow } = props;
    const location = useLocation();
    const history = useHistory();
    const { handleDoubleClick } = useBloxActions();

    const fabuKnowQueryStr = `https://fabuknow.com/blox-library.html?bloxtype=${reverseBloxTypes[bloxType]}`;

    const [{ isDragging }, dragBlox] = useDrag<BloxInsertItem, unknown, { isDragging: boolean }>(() => ({
        type: Draggables.Blox,
        item: { bloxType, data },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }));

    // Effect to switch to default view when dragging starts
    useEffect(() => {
        if (isDragging) {  // Only switch view if actually dragging
            const queryParams = new URLSearchParams(location.search);
            const viewMode = queryParams.get('view');
            const isListView = viewMode === 'list';
            const isTableView = viewMode === 'table';

            if (isListView || isTableView) {
                queryParams.delete('view');
                history.replace({  // Keep using replace here for drag behavior
                    pathname: location.pathname,
                    search: queryParams.toString()
                });
            }
        }
    }, [isDragging, location, history]);

    const dblClickHandle = useCallback(() => {
        handleDoubleClick(bloxType, data);
    }, [handleDoubleClick, bloxType, data]);

    const bloxTitleEl = <div>Name: {bloxName}
        {(desc || groupNamesToShow) && <>
            <br /><br />
        </>}
        {desc && <>
            <span>Description: {desc}</span>
            <br/>
        </>
        }
        {groupNamesToShow?.length && `Groups: ${groupNamesToShow.join(', ')}`}
    </div>;

    const infoUrlCallback = useCallback(() => {
        if (fabuKnowQueryStr) {
            window.open(fabuKnowQueryStr);
        }
    }, [fabuKnowQueryStr]);

    return (
        <Column>
            <Tooltip content={bloxTitleEl}>
                <div className="blox-library" ref={dragBlox} style={{ display: 'flex', flexDirection: 'column', opacity: isDragging ? 0.5 : 1 }} onDoubleClick={dblClickHandle}>
                    <div className="library-label top">{bloxName}</div>
                    {fabuKnowQueryStr && <Icon className={'library-image-icon-button'} icon="info-sign" size={13} onClick={infoUrlCallback} />}
                    <BloxSvg bloxType={bloxType} className='blox-library-image'/>
                </div>
            </Tooltip>
        </Column>
    );
};
