/**
 * @fileoverview Provides UI state management for the IFrame application components.
 * This context manages UI-related state such as view modes, selected elements,
 * and visibility controls. It implements a reducer pattern for state updates and
 * provides action creators for components to manage UI state.
 * 
 * The context now synchronizes with useFabuUIState for consistent state management.
 */

import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import { useFabuUIState, ViewMode } from './hooks/useFabuUIState';

// Define the IFrame UI state interface
export interface IFrameUIState {
  viewMode: ViewMode;
  selectedBloxId: string | null;
  showTemplates: boolean;
  showChatbot: boolean;
  showControls: boolean;
  mobileChatbotActive: boolean;
  closedSections: {[key: string]: boolean};
  bloxSVGs: Record<string, string>;
  initialScrollTarget: string | null;
  hasUnread: boolean;
  processViewAdjusted: boolean;
}

// Define action types
type UIAction =
  | { type: 'SET_VIEW_MODE', payload: ViewMode }
  | { type: 'SET_SELECTED_BLOX', payload: string | null }
  | { type: 'TOGGLE_TEMPLATES', payload?: boolean }
  | { type: 'TOGGLE_CHATBOT', payload?: boolean }
  | { type: 'TOGGLE_CONTROLS', payload?: boolean }
  | { type: 'SET_MOBILE_CHATBOT', payload: boolean }
  | { type: 'TOGGLE_SECTION', payload: string }
  | { type: 'SET_BLOX_SVG', payload: { id: string, svg: string } }
  | { type: 'SET_CLOSED_SECTIONS', payload: {[key: string]: boolean} }
  | { type: 'SET_INITIAL_SCROLL_TARGET', payload: string | null }
  | { type: 'SET_HAS_UNREAD', payload: boolean }
  | { type: 'SET_PROCESS_VIEW_ADJUSTED', payload: boolean }
  | { type: 'SYNC_WITH_FABU_UI_STATE', payload: { 
      viewMode: ViewMode, 
      closedSections: {[key: string]: boolean}, 
      initialScrollTarget: string | null 
    }};

// Initial state
const initialState: IFrameUIState = {
  viewMode: 'standard',
  selectedBloxId: null,
  showTemplates: false,
  showChatbot: false,
  showControls: true,
  mobileChatbotActive: false,
  closedSections: {},
  bloxSVGs: {},
  initialScrollTarget: null,
  hasUnread: false,
  processViewAdjusted: false
};

// Reducer function
function uiReducer(state: IFrameUIState, action: UIAction): IFrameUIState {
  switch (action.type) {
    case 'SET_VIEW_MODE':
      return { ...state, viewMode: action.payload };
    case 'SET_SELECTED_BLOX':
      return { ...state, selectedBloxId: action.payload };
    case 'TOGGLE_TEMPLATES':
      return { ...state, showTemplates: action.payload !== undefined ? action.payload : !state.showTemplates };
    case 'TOGGLE_CHATBOT':
      return { ...state, showChatbot: action.payload !== undefined ? action.payload : !state.showChatbot };
    case 'TOGGLE_CONTROLS':
      return { ...state, showControls: action.payload !== undefined ? action.payload : !state.showControls };
    case 'SET_MOBILE_CHATBOT':
      return { ...state, mobileChatbotActive: action.payload };
    case 'TOGGLE_SECTION':
      return {
        ...state,
        closedSections: { 
          ...state.closedSections, 
          [action.payload]: !state.closedSections[action.payload] 
        }
      };
    case 'SET_BLOX_SVG':
      return {
        ...state,
        bloxSVGs: { ...state.bloxSVGs, [action.payload.id]: action.payload.svg }
      };
    case 'SET_CLOSED_SECTIONS':
      return { ...state, closedSections: action.payload };
    case 'SET_INITIAL_SCROLL_TARGET':
      return { ...state, initialScrollTarget: action.payload };
    case 'SET_HAS_UNREAD':
      return { ...state, hasUnread: action.payload };
    case 'SET_PROCESS_VIEW_ADJUSTED':
      return { ...state, processViewAdjusted: action.payload };
    case 'SYNC_WITH_FABU_UI_STATE':
      return {
        ...state,
        viewMode: action.payload.viewMode,
        closedSections: action.payload.closedSections,
        initialScrollTarget: action.payload.initialScrollTarget
      };
    default:
      return state;
  }
}

// Create context
interface IFrameUIContextValue {
  state: IFrameUIState;
  dispatch: React.Dispatch<UIAction>;
  actions: {
    setViewMode: (mode: ViewMode) => void;
    setSelectedBlox: (id: string | null) => void;
    toggleTemplates: (show?: boolean) => void;
    toggleChatbot: (show?: boolean) => void;
    toggleControls: (show?: boolean) => void;
    setMobileChatbot: (active: boolean) => void;
    toggleSection: (sectionId: string) => void;
    setBloxSVG: (id: string, svg: string) => void;
    // Additional legacy actions
    setSelectedBloxId: (id: string | null) => void;
    setClosedSections: (sections: React.SetStateAction<{[key: string]: boolean}>) => void;
    setInitialScrollTarget: (target: string | null) => void;
    setHasUnread: (hasUnread: boolean) => void;
    setProcessViewAdjusted: (adjusted: boolean) => void;
  };
}

const IFrameUIContext = createContext<IFrameUIContextValue | undefined>(undefined);

// Provider component
export function IFrameUIProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(uiReducer, initialState);
  
  // Get UI state from useFabuUIState
  const fabuUIState = useFabuUIState();
  
  const actions = {
    setViewMode: useCallback((mode: ViewMode) => {
      // Update both local state and fabuUIState
      fabuUIState.setViewMode(mode);
      dispatch({ type: 'SET_VIEW_MODE', payload: mode });
    }, [fabuUIState]),
    
    setSelectedBlox: useCallback((id: string | null) => 
      dispatch({ type: 'SET_SELECTED_BLOX', payload: id }), []),
      
    // Also provide setSelectedBloxId as an alias for backward compatibility
    setSelectedBloxId: useCallback((id: string | null) => 
      dispatch({ type: 'SET_SELECTED_BLOX', payload: id }), []),
      
    toggleTemplates: useCallback((show?: boolean) => 
      dispatch({ type: 'TOGGLE_TEMPLATES', payload: show }), []),
      
    toggleChatbot: useCallback((show?: boolean) => 
      dispatch({ type: 'TOGGLE_CHATBOT', payload: show }), []),
      
    toggleControls: useCallback((show?: boolean) => 
      dispatch({ type: 'TOGGLE_CONTROLS', payload: show }), []),
      
    setMobileChatbot: useCallback((active: boolean) => 
      dispatch({ type: 'SET_MOBILE_CHATBOT', payload: active }), []),
      
    toggleSection: useCallback((sectionId: string) => 
      dispatch({ type: 'TOGGLE_SECTION', payload: sectionId }), []),
      
    setBloxSVG: useCallback((id: string, svg: string) => 
      dispatch({ type: 'SET_BLOX_SVG', payload: { id, svg } }), []),
    
    setClosedSections: useCallback((sections: React.SetStateAction<{[key: string]: boolean}>) => {
      if (typeof sections === 'function') {
        dispatch({ 
          type: 'SET_CLOSED_SECTIONS', 
          payload: sections(state.closedSections) 
        });
        fabuUIState.setClosedSections(sections);
      } else {
        dispatch({ type: 'SET_CLOSED_SECTIONS', payload: sections });
        fabuUIState.setClosedSections(sections);
      }
    }, [fabuUIState, state.closedSections]),
    
    setInitialScrollTarget: useCallback((target: string | null) => {
      dispatch({ type: 'SET_INITIAL_SCROLL_TARGET', payload: target });
      fabuUIState.setInitialScrollTarget(target);
    }, [fabuUIState]),
    
    setHasUnread: useCallback((hasUnread: boolean) => 
      dispatch({ type: 'SET_HAS_UNREAD', payload: hasUnread }), []),
      
    setProcessViewAdjusted: useCallback((adjusted: boolean) => 
      dispatch({ type: 'SET_PROCESS_VIEW_ADJUSTED', payload: adjusted }), []),
  };

  // Keep context state synchronized with useFabuUIState
  useEffect(() => {
    // Only update context if values have changed
    if (fabuUIState.viewMode !== state.viewMode || 
        fabuUIState.initialScrollTarget !== state.initialScrollTarget ||
        fabuUIState.closedSections !== state.closedSections) {
      
      dispatch({
        type: 'SYNC_WITH_FABU_UI_STATE',
        payload: {
          viewMode: fabuUIState.viewMode,
          closedSections: fabuUIState.closedSections,
          initialScrollTarget: fabuUIState.initialScrollTarget
        }
      });
    }
  }, [
    fabuUIState.viewMode, 
    fabuUIState.initialScrollTarget, 
    fabuUIState.closedSections, 
    state.viewMode,
    state.initialScrollTarget,
    state.closedSections
  ]);

  return (
    <IFrameUIContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </IFrameUIContext.Provider>
  );
}

// Custom hook for using the context
export function useIFrameUI() {
  const context = useContext(IFrameUIContext);
  if (context === undefined) {
    throw new Error('useIFrameUI must be used within an IFrameUIProvider');
  }
  return context;
} 