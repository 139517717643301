import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SubjectIcon from '@mui/icons-material/Subject';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import { AutoStories, OpenInNew } from '@mui/icons-material';
import { Link } from '@mui/material';
import { FormField } from './types';

/**
 * Creates the configuration for all form fields
 */
export const createFormFields = (
  requesterName: string,
  setRequesterName: React.Dispatch<React.SetStateAction<string>>,
  contactInfo: string,
  setContactInfo: React.Dispatch<React.SetStateAction<string>>,
  requestDetails: string,
  setRequestDetails: React.Dispatch<React.SetStateAction<string>>,
  requesterOrg: string,
  setRequesterOrg: React.Dispatch<React.SetStateAction<string>>,
  customProcessLink: string,
  setCustomProcessLink: React.Dispatch<React.SetStateAction<string>>,
  literatureReferences: string,
  setLiteratureReferences: React.Dispatch<React.SetStateAction<string>>
): FormField[] => [
  {
    value: requesterName,
    setValue: setRequesterName,
    fieldName: 'name',
    placeholder: 'Enter your name',
    icon: <PersonIcon fontSize="small" sx={{ color: "action.active", mr: 1 }} />,
    label: 'Your Name',
    required: true
  },
  {
    value: contactInfo,
    setValue: setContactInfo,
    fieldName: 'contact',
    placeholder: 'Your email or phone number',
    icon: <EmailIcon fontSize="small" sx={{ color: 'action.active', mr: 1 }} />,
    label: 'Contact Info',
    required: true
  },
  {
    value: requestDetails,
    setValue: setRequestDetails,
    fieldName: 'details',
    placeholder: 'Fill in your request here with any comments and questions for the MOSIS2.0 team.',
    icon: <SubjectIcon fontSize="small" sx={{ color: 'action.active', mr: 1 }} />,
    label: 'Request Details',
    multiline: true,
    rows: 5,
    required: true
  },
  {
    value: requesterOrg,
    setValue: setRequesterOrg,
    fieldName: 'org',
    placeholder: 'Enter your organization name',
    icon: <BusinessIcon fontSize="small" sx={{ color: "action.active", mr: 1 }} />,
    label: 'Your Organization',
    required: false
  },
  {
    value: customProcessLink,
    setValue: setCustomProcessLink,
    fieldName: 'link',
    placeholder: 'Link to a custom process flow',
    icon: <LinkIcon fontSize="small" sx={{ color: 'action.active', mr: 1 }} />,
    label: 'Custom Process Link',
    required: false,
    helperText: <>
      Get a process link from your{' '}  
      <b><Link color='inherit' href="https://fabublox.com/my-processes" target="_blank" rel="noopener noreferrer">FabuBlox processes</Link>
      <OpenInNew fontSize='small' /></b>
    </>
  },
  {
    value: literatureReferences,
    setValue: setLiteratureReferences,
    fieldName: 'references',
    placeholder: 'Enter any literature references or publications you are interested in.',
    icon: <AutoStories fontSize="small" sx={{ color: 'action.active', mr: 1 }} />,
    label: 'Literature References',
    multiline: true,
    rows: 3,
    required: false
  }
]; 