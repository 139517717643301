import { Row } from "../Layout/layouts";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { ExportDialog } from "../dialogs/ExportDialog";
import { AllBloxes } from "../Data/BloxSchema/base-blox";

export interface StackProps {
    bloxSVG: JSX.Element,
    renderMenu: () => JSX.Element,
    bloxName?: string,
    blox?: AllBloxes,
    processBloxes: AllBloxes[],
    bloxIdx: number,
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[],
    hideMenu?: boolean
}

export const Stack: React.FC<StackProps> = (props) => {
    const { bloxName, bloxSVG, renderMenu, blox, processBloxes, bloxIdx, processSections, hideMenu = false } = props;

    const [isOpen, setIsOpen] = useState(false); // State to manage dialog visibility

    const toggleDialog = () => setIsOpen(!isOpen); // Handler to toggle dialog

    return (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative' }}>
            <div className="bloxBottom" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative', minHeight: '140px' }}>
                <div onClick={toggleDialog} style={{
                    width: '120px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}>
                    {bloxSVG}
                </div>
                {!hideMenu && (
                    <div className="bloxMenuIcon">
                        <Popover2 content={renderMenu()}>
                            <Icon size={16} icon={IconNames.MORE} />
                        </Popover2>
                    </div>
                )}
            </div>
            {blox && (
                <ExportDialog
                    filename={bloxName || "export"}
                    isOpen={isOpen}
                    closeDialog={toggleDialog}
                    bloxes={processBloxes}
                    initialIndex={bloxIdx}
                    processSections={processSections}
                />
            )}
        </Row>
    );
}


