import ALD from './ALD.svg';
import BAKE from './BAKE.svg';
import CLEAN from './CLEAN.svg';
import CUSTOMBLOX from './CUSTOMBLOX.svg';
import DEVELOP from './DEVELOP.svg';
import DIRECTWRITELITHO from './DIRECTWRITELITHO.svg';
import DOPEDIFFUSION from './DOPEDIFFUSION.svg';
import DROPCAST from './DROPCAST.svg';
import EBEAMEVAP from './EBEAMEVAP.svg';
import EBL from './EBL.svg';
import ELECTROPLATE from './ELECTROPLATE.svg';
import FLIPSTACK from './FLIPSTACK.svg';
import HMDSVAPOR from './HMDSVAPOR.svg';
import ICPRIE from './ICPRIE.svg';
import INTERFERENCELITHO from './INTERFERENCE-LITHO.svg';
import IONIMPLANT from './IONIMPLANT.svg';
import IONMILL from './IONMILL.svg';
import LIFTOFF from './LIFTOFF.svg';
import MASKALIGN from './MASKALIGN.svg';
import MBE from './MBE.svg';
import MOCVD from './MOCVD.svg';
import PECVD from './PECVD.svg';
import LPCVD from './LPCVD.svg';
import APCVD from './APCVD.svg';
import PARYLENEDEP from './PARYLENE.svg';
import PLASMAASH from './PLASMA-ASH.svg';
import PLASMADESCUM from './PLASMA-DESCUM.svg';
import RCACLEAN from './RCACLEAN.svg';
import RIE from './RIE.svg';
import RTA from './RTA.svg';
import SPINCOAT from './SPINCOAT.svg';
import SPINDOPANT from './SPINDOPANT.svg';
import SPINCOATRESIST from './SPINCOATRESIST.svg';
import STARTBLOX from './STARTSTACK.svg';
import SPUTTER from './SPUTTER.svg';
import STEPPERLITHO from './STEPPERLITHO.svg';
import STRIPRESIST from './STRIPRESIST.svg';
import THERMALEVAP from './THERMALEVAP.svg';
import THERMALOXIDATION from './THERMALOXIDATION.svg';
import WETETCH from './WETETCH.svg';
import CMP from './CMP.svg';
import WETGROWTH from './WETGROWTH.svg';
import WAFERBOND from './WAFERBOND.svg';
import IMPRINTLITHO from './IMPRINTLITHO.svg';
import SCANNINGPROBELITHO from './SCANNINGPROBELITHO.svg';
import FLOODEXPOSE from './FLOODEXPOSE.svg';

import { BloxTypes } from '../../Data/BloxSchema/base-blox';

//todo update notion

export function BloxSvg(props: { bloxType: BloxTypes, className: string }) {
    const { bloxType, className } = props;
    if (bloxType === BloxTypes.ALD)
        return <img src={ALD} alt={'ALD'} className={className} />;
    if (bloxType === BloxTypes.Bake)
        return <img src={BAKE} alt={'BAKE'} className={className} />;
    if (bloxType === BloxTypes.CustomClean)
        return <img src={CLEAN} alt={'CLEAN'} className={className} />;
    if (bloxType === BloxTypes.CustomBlox)
        return <img src={CUSTOMBLOX} alt={'CUSTOMBLOX'} className={className} style={{width: '60%'}} />;   
    if (bloxType === BloxTypes.Develop)
        return <img src={DEVELOP} alt={'DEVELOP'} className={className} />;
    if (bloxType === BloxTypes.DirectWriteLitho)
        return <img src={DIRECTWRITELITHO} alt={'DIRECTWRITELITHO'} className={className} />;
    if (bloxType === BloxTypes.DopeDiffusion)
        return <img src={DOPEDIFFUSION} alt={'DOPEDIFFUSION'} className={className} />;
    if (bloxType === BloxTypes.DropCast)
        return <img src={DROPCAST} alt={'DROPCAST'} className={className} />;
    if (bloxType === BloxTypes.EbeamEvap)
        return <img src={EBEAMEVAP} alt={'EBEAMEVAP'} className={className} />;
    if (bloxType === BloxTypes.EbeamLitho) // check this
        return <img src={EBL} alt={'EBEAMLITHO'} className={className} />;
    if (bloxType === BloxTypes.Electroplate)
        return <img src={ELECTROPLATE} alt={'ELECTROPLATE'} className={className} />;
    if (bloxType === BloxTypes.FlipStack)
        return <img src={FLIPSTACK} alt={'FLIPSTACK'} className={className} />;
    if (bloxType === BloxTypes.FloodExpose)
        return <img src={FLOODEXPOSE} alt={'FLOODEXPOSE'} className={className} />;
    if (bloxType === BloxTypes.HMDSVapor)
        return <img src={HMDSVAPOR} alt={'HMDSVAPOR'} className={className} />;
    if (bloxType === BloxTypes.ICPRIE)
        return <img src={ICPRIE} alt={'ICPRIE'} className={className} style={{width: '60%'}} />;
    if (bloxType === BloxTypes.ImprintLitho)
        return <img src={IMPRINTLITHO} alt={'IMPRINTLITHO'} className={className} />;
    if (bloxType === BloxTypes.InterferenceLitho)
        return <img src={INTERFERENCELITHO} alt={'INTERFERENCELITHO'} className={className} />;
    if (bloxType === BloxTypes.ScanningProbeLitho)
        return <img src={SCANNINGPROBELITHO} alt={'SCANNINGPROBELITHO'} className={className} />;
    if (bloxType === BloxTypes.IonImplantation)
        return <img src={IONIMPLANT} alt={'IONIMPLANT'} className={className} />;
    if (bloxType === BloxTypes.IonMilling)
        return <img src={IONMILL} alt={'IONMILL'} className={className} />;
    if (bloxType === BloxTypes.LiftOff)
        return <img src={LIFTOFF} alt={'LIFTOFF'} className={className} />;
    if (bloxType === BloxTypes.MaskAlign)
        return <img src={MASKALIGN} alt={'MASKALIGN'} className={className} />;
    if (bloxType === BloxTypes.MOCVD)
        return <img src={MOCVD} alt={'MOCVD'} className={className} />;
    if (bloxType === BloxTypes.MBE)
        return <img src={MBE} alt={'MBE'} className={className} />;
    if (bloxType === BloxTypes.ParyleneDep)
        return <img src={PARYLENEDEP} alt={'PARYLENE'} className={className} />;
    if (bloxType === BloxTypes.PECVD)
        return <img src={PECVD} alt={'PECVD'} className={className} />;
    if (bloxType === BloxTypes.LPCVD)
        return <img src={LPCVD} alt={'LPCVD'} className={className} />;
    if (bloxType === BloxTypes.APCVD)
        return <img src={APCVD} alt={'APCVD'} className={className} />;
    if (bloxType === BloxTypes.PlasmaAsh)
        return <img src={PLASMAASH} alt={'PLASMAASH'} className={className} />;
    if (bloxType === BloxTypes.PlasmaDescum)
        return <img src={PLASMADESCUM} alt={'PLASMADESCUM'} className={className} />;
    if (bloxType === BloxTypes.RCAClean)
        return <img src={RCACLEAN} alt={'RCACLEAN'} className={className} />;
    if (bloxType === BloxTypes.RIE)
        return <img src={RIE} alt={'RIE'} className={className} style={{width: '60%'}}/>;
    if (bloxType === BloxTypes.RTA)
        return <img src={RTA} alt={'RTA'} className={className} />;
    if (bloxType === BloxTypes.CMP)
        return <img src={CMP} alt={'CMP'} className={className}/>;
    if (bloxType === BloxTypes.SpinCoat)
        return <img src={SPINCOAT} alt={'SPINCOAT'} className={className} />;
    if (bloxType === BloxTypes.SpinDopant)
        return <img src={SPINDOPANT} alt={'SPINDOPANT'} className={className} />;
    if (bloxType === BloxTypes.SpinCoatResist)
        return <img src={SPINCOATRESIST} alt={'SPINCOATRESIST'} className={className} />;
    if (bloxType === BloxTypes.SpinCoatUnified)
        return <img src={SPINCOAT} alt={'SPINCOAT'} className={className} />;
    if (bloxType === BloxTypes.StartBlox)
        return <img src={STARTBLOX} alt={'STARTBLOX'} className={className} style={{width: '55%'}} />;
    if (bloxType === BloxTypes.Sputter)
        return <img src={SPUTTER} alt={'SPUTTER'} className={className} />;
    if (bloxType === BloxTypes.StepperLitho)
        return <img src={STEPPERLITHO} alt={'STEPPERLITHO'} className={className} />;
    if (bloxType === BloxTypes.StripResist)
        return <img src={STRIPRESIST} alt={'STRIPRESIST'} className={className} />;
    if (bloxType === BloxTypes.ThermalEvap)
        return <img src={THERMALEVAP} alt={'THERMALEVAP'} className={className} />;
    if (bloxType === BloxTypes.ThermalOxide)
        return <img src={THERMALOXIDATION} alt={'THERMALOXIDATION'} className={className} />;
    if (bloxType === BloxTypes.Wetetch)
        return <img src={WETETCH} alt={'WETETCH'} className={className} />;
    if (bloxType === BloxTypes.WetGrowth)
        return <img src={WETGROWTH} alt={'WETGROWTH'} className={className} />;
    if (bloxType === BloxTypes.WaferBonding)
        return <img src={WAFERBOND} alt={'WAFERBONDING'} className={className} />;
    // if (bloxType === "floodexpose")
    // return <img src={floodexpose} alt={'floodexpose'} className={className} />;

    return <img src={STARTBLOX} alt={'STARTBLOX'} className={className} />
}