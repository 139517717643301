import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_FABUBLOX_API_URL } from "../../utils/constants";
import { useProcessHandlers } from "./../hooks/use-process-handlers";
import { Loading } from "../Loading";
import { SvgServiceContext } from "../../hooks/state/svg-service-provider";
import { SVGDisplayMode } from "../../Services/SVGEngine";
import { ProcessSettingsContext } from "../../hooks/state/process-settings-provider";
import { UnsavedChangesContext } from "../../hooks/state/unsaved-changes-provider";
import { BetweenBlox } from "../BetweenBlox";
import { showToast } from "../..";
import { useClearProcess } from "../hooks/use-clear-process";
import { StateAndSetter } from "../../hooks/state/parameter-context";
import { usePanelManager } from '../../hooks/use-panel-manager';

import SectionContainer from "../SectionContainer";
import { toggleSectionWithScroll, scrollToSelectedBlox } from "../../utils/scrollUtils";
import { SectionedItem } from "../../utils/processUtils";
import { 
  ensureLayerCompatibility, 
  getProcessViewMode,
  ProcessViewMode,
  findLastBloxInPreviousSections,
  handleViewModeTransition
} from "../../utils/processUtils";
import { determineProcessAccess, initializeProcessState } from "../../utils/processStateUtils";
import { renderProcessView } from "../ProcessViewRenderer";

export interface ProcessEditorProps {
  closedSectionState: StateAndSetter<{[key: string]: boolean}>;
}

/**
 * ProcessEditor component for editing, organizing, and displaying process sections and blocks (bloxes).
 * Integrates sections with blocks, handles loading and access control, 
 * and provides functionality for managing unsaved changes.
 * 
 * @component
 * @param {ProcessEditorProps} props - Props for managing closed section states.
 * @returns {JSX.Element} Rendered ProcessEditor component.
 */
export default function ProcessEditor(props: ProcessEditorProps) {
  const { closedSectionState } = props;
  const [closedSections, setClosedSections] = closedSectionState;
  const { processId } = useParams<{processId: string}>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isListView, isTableView, isStandardView } = getProcessViewMode(queryParams);
  const signature = queryParams.get('signature');
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const copiedBloxState = useState<AllBloxes | null>(null);
  const clearProcess = useClearProcess();
  
  const { 
    isStack3D, 
    isStackHidden,
    showAllParameters,
    splitWorksheet
  } = useContext(ProcessSettingsContext);

  const [ processBloxes, setProcessBloxes] = useFabuState('processBloxes');
  const [processSections,setProcessSections] = useFabuState('processSections');
  const [,setProcessName] = useFabuState('processName');
  const [,setProcessUsername] = useFabuState('processUsername');
  const [,setProcessId] = useFabuState('processId');
  const [,setProcessIsPrivate] = useFabuState('processIsPrivate');
  const [,setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');
  const [,setProcessDesc] = useFabuState('processDesc');
  const [,setProcessReference] = useFabuState('processReference');
  const [,setProcessGdsInfo] = useFabuState('processGdsInfo');

  const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
  const [,setProcessIsReadOnly] = useFabuState('processIsReadOnly');
  const [,setProcessGroups] = useFabuState('processGroups');
  const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);

  const { processHandleDrop, processHandleDelete, processHandleClickBlox, processHandleInsertSection } = useProcessHandlers();

  const { getAccessTokenSilently, user } = useAuth0();

  // Generate SVGs for each step in the process
  const { generateSvgs } = useContext(SvgServiceContext);
  const bloxSVGs = generateSvgs(processBloxes, isStack3D, SVGDisplayMode.RawSVG); // there is one of these per steps in the process
  const containerRef = useRef<HTMLDivElement>(null); // Access the container element

  /**
   * Controls the scroll position when switching views or selecting items.
   * Used primarily to maintain focus on the selected item when changing views.
   * This is particularly important for list view, where we want to ensure
   * the selected item is visible after switching from another view.
   */
  const [initialScrollTarget, setInitialScrollTarget] = useState<string | null>(null);
  
  /**
   * Tracks the previous view mode state to detect changes in view mode.
   * This state helps us handle transitions between different views by:
   * 1. Detecting when the view mode actually changes
   * 2. Preserving context when switching views (e.g., keeping the selected item visible)
   * 3. Ensuring proper section expansion states in different views
   */
  const [previousView, setPreviousView] = useState<ProcessViewMode>({
    isListView: false,
    isTableView: false,
    isStandardView: true
  });

  /**
   * State for managing scroll behavior and read-only status:
   * - shouldScrollToSelectedBlox: Triggers scrolling to the selected blox
   * - processIsReadOnly: Controls whether the process can be edited
   */
  const [shouldScrollToSelectedBlox, setShouldScrollToSelectedBlox] = useFabuState('scrollToSelectedBlox');
  const [processIsReadOnly] = useFabuState('processIsReadOnly');
  const hasSetInitialBlox = useRef(false);

  const { isPanelOpen } = usePanelManager({});
  const previousPanelState = useRef(isPanelOpen);

  /**
   * useEffect hook to fetch and initialize process data when a process ID is present.
   * Sets state with fetched process data, initializes sections, and handles access control.
   * 
   * Clears process data if fetching fails, redirecting to a new process creation page.
   */
  useEffect(() => {
    if (!processId || processId === 'new' || processId === 'create') {
      setIsLoading(false);
      return;
    }
    
    /**
     * Fetches a process from the FabuBlox API, given a processId.
     * Sets state with fetched process data, initializes sections, and handles access control.
     * If fetching fails, clears process data and redirects to a new process creation page.
     * @param {string} processId - The ID of the process to fetch.
     * @param {string} [signature] - The signature of the user making the request.
     */
    const callReadResource = async () => {
      const baseURL = match.path.split('/:')[0];
      const token = baseURL.includes('process-viewer') ? '' : await getAccessTokenSilently();
      const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
      let fetchUrl = `${BASE_FABUBLOX_API_URL}/api/process/read/${processId}`;
      if (signature) {
        fetchUrl += `?signature=${signature}`;
      }

      if (processId) {
        fetch(fetchUrl, { headers })
          .then(res => res.json())
          .then(
            (result) => {
              if (result?.bloxes) {
                const baseURL = match.path.split('/:')[0];
                const { readOnly } = determineProcessAccess(
                  result,
                  user?.sub,
                  baseURL.includes('process-viewer')
                );

                // Backward compatibility adjustments for layers
                if (result.bloxes[0].layers) {
                  result.bloxes[0].layers = ensureLayerCompatibility(result.bloxes[0].layers);
                }

                // Initialize all process state
                const { defaultedBloxes, sections, metadata } = initializeProcessState(result);
                setProcessGdsInfo(result.gdsInfo);

                // Set sections and their initial states
                setProcessSections(sections.tempSections);
                setClosedSections(sections.initialOpenSections);

                // Set process metadata and state
                setProcessIsReadOnly(readOnly);
                setProcessBloxes(defaultedBloxes);
                setProcessName(metadata.processName);
                setProcessUsername(metadata.username);
                setProcessId(metadata.processId);
                setProcessIsPrivate(metadata.isPrivate);
                setProcessAdditionalOwners(metadata.additionalOwners);
                setProcessDesc(metadata.desc);
                setProcessReference(metadata.reference);
                setProcessGroups(metadata.groups);
                
                setIsLoading(false);
                setEditorUnsavedChanges(false);
              }
            },
            () => {
              clearProcess();
              history.push('/process-editor/new');
              showToast({
                message: "Failed to fetch process or you do not have access",
                intent: 'danger',
                timeout: 3000
              });
            }
          );
      }
    }

    callReadResource();

    return () => {
      clearProcess();
    }
  }, [
    clearProcess,
    processId,
    getAccessTokenSilently,
    user,
    match.path,
    signature,
    history,
    setClosedSections,
    setProcessIsReadOnly,
    setProcessBloxes,
    setProcessName,
    setProcessUsername,
    setProcessId,
    setProcessIsPrivate,
    setProcessAdditionalOwners,
    setProcessDesc,
    setProcessReference,
    setProcessSections,
    setProcessGroups,
    setEditorUnsavedChanges
  ]);

  /**
   * Handles view mode transitions and ensures proper UI state when switching views.
   * Uses handleViewModeTransition utility to manage:
   * 1. View mode change detection
   * 2. Section expansion for selected items
   * 3. Scroll target setup for maintaining focus
   */
  useEffect(() => {
    const currentView: ProcessViewMode = {
      isListView,
      isTableView,
      isStandardView
    };

    const { shouldUpdate, newPreviousView } = handleViewModeTransition(
      currentView,
      previousView,
      selectedBloxId,
      processSections,
      setClosedSections,
      setInitialScrollTarget,
      setShouldScrollToSelectedBlox
    );

    if (shouldUpdate) {
      setPreviousView(newPreviousView);
    }
  }, [isListView, isTableView, isStandardView, selectedBloxId, processSections, setClosedSections, previousView, setInitialScrollTarget, setShouldScrollToSelectedBlox]);

  /**
   * Manages scrolling to the selected Blox in non-list views.
   * This effect:
   * 1. Checks if scrolling is needed and appropriate
   * 2. Uses the scrollToSelectedBlox utility to:
   *    - Find the section containing the selected blox
   *    - Expand that section if it's closed
   *    - Scroll to the section with proper timing
   * 3. Resets the scroll flag once complete
   * 
   * Note: This is disabled for list view since that has its own scroll handling
   */
  useEffect(() => {
    if (!shouldScrollToSelectedBlox || isListView) return;
    
    if (scrollToSelectedBlox(selectedBloxId, processSections, closedSections, setClosedSections, containerRef)) {
      setShouldScrollToSelectedBlox(false);
    }
  }, [shouldScrollToSelectedBlox, selectedBloxId, processSections, setShouldScrollToSelectedBlox, closedSections, setClosedSections, isListView, containerRef]);

  /**
   * Sets the first blox as selected by default only when:
   * 1. No blox is currently selected
   * 2. Process has loaded
   * 3. There are bloxes available
   * 4. We haven't set the initial blox yet
   * This ensures we don't override user selections or create race conditions.
   */
  useEffect(() => {
    if (!isLoading && processBloxes?.length > 0 && !selectedBloxId && !hasSetInitialBlox.current) {
      setSelectedBloxId(processBloxes[0].id);
      hasSetInitialBlox.current = true;
    }
  }, [isLoading, processBloxes, selectedBloxId, setSelectedBloxId]);

  // Add new effect to handle panel state changes
  useEffect(() => {
    if (!selectedBloxId || isListView) return;

    // Store the current selected blox to prevent it from being lost during transitions
    const currentSelectedBloxId = selectedBloxId;

    // During panel transition, disable other scroll mechanisms
    if (isPanelOpen !== previousPanelState.current) {
      // Immediately disable shouldScrollToSelectedBlox to prevent double scrolling
      setShouldScrollToSelectedBlox(false);
      
      // Set a flag to indicate we're in a panel transition
      const isInPanelTransition = true;
      
      // Handle panel transition completion - only scroll AFTER the transition is complete
      const handlePanelTransitionEnd = () => {
        // Re-assert the selection to ensure it's still active
        setSelectedBloxId(currentSelectedBloxId);
        
        // Find the section of the selected blox to check if it's the last blox
        const sectionIdx = processSections.findIndex(section => section.bloxIds.includes(currentSelectedBloxId));
        if (sectionIdx !== -1) {
          const section = processSections[sectionIdx];
          const isLastBlox = section.bloxIds[section.bloxIds.length - 1] === currentSelectedBloxId;
          
          // Only scroll for last bloxes after panel transitions
          if (isLastBlox) {
            // Use a timeout to ensure the DOM has updated after the transition
            setTimeout(() => {
              // Use existing scrollToSelectedBlox with a long enough delay
              scrollToSelectedBlox(currentSelectedBloxId, processSections, closedSections, setClosedSections, containerRef);
            }, 150);
          }
        }
      };

      // Listen for the transition end event, which guarantees the panel animation is complete
      window.addEventListener('rightPanelTransitionEnd', handlePanelTransitionEnd, { once: true });
    }

    previousPanelState.current = isPanelOpen;
  }, [isPanelOpen, selectedBloxId, processSections, closedSections, setClosedSections, containerRef, isListView, setSelectedBloxId, setShouldScrollToSelectedBlox]);

  // Add this new effect to handle blox selection and centering
  useEffect(() => {
    if (!selectedBloxId || isListView || isTableView) return;

    // Skip scrolling if panel state just changed (isPanelOpen !== previousPanelState.current)
    // Let the panel transition handler handle scrolling in that case
    if (isPanelOpen !== previousPanelState.current) {
      return;
    }

    // Use the existing scrollToSelectedBlox utility
    scrollToSelectedBlox(selectedBloxId, processSections, closedSections, setClosedSections, containerRef);
  }, [selectedBloxId, processSections, isListView, isTableView, isPanelOpen, previousPanelState, closedSections, setClosedSections, containerRef]);

  // Add keyboard navigation handler
  useEffect(() => {
    if (isListView || isTableView) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle if we're in blox view and have a selected blox
      if (!selectedBloxId || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
        return;
      }

      // Find current section and blox index
      const sectionIdx = processSections.findIndex(section => section.bloxIds.includes(selectedBloxId));
      if (sectionIdx === -1) return;

      const section = processSections[sectionIdx];
      const currentBloxIdx = section.bloxIds.indexOf(selectedBloxId);

      switch (e.key) {
        case 'ArrowLeft': {
          e.preventDefault(); // Prevent default scroll
          // Move to previous blox in section
          if (currentBloxIdx > 0) {
            const prevBloxId = section.bloxIds[currentBloxIdx - 1];
            setSelectedBloxId(prevBloxId);
          } else if (sectionIdx > 0) {
            // Move to last blox of previous section
            const prevSection = processSections[sectionIdx - 1];
            if (prevSection.bloxIds.length > 0) {
              const lastBloxId = prevSection.bloxIds[prevSection.bloxIds.length - 1];
              // Expand the previous section if it's closed
              if (closedSections[prevSection.sectionId]) {
                setClosedSections(prev => ({
                  ...prev,
                  [prevSection.sectionId]: false
                }));
              }
              setSelectedBloxId(lastBloxId);
              // Trigger scroll to ensure proper positioning
              setShouldScrollToSelectedBlox(true);
            }
          }
          break;
        }
        case 'ArrowRight': {
          e.preventDefault(); // Prevent default scroll
          // Move to next blox in section
          if (currentBloxIdx < section.bloxIds.length - 1) {
            const nextBloxId = section.bloxIds[currentBloxIdx + 1];
            setSelectedBloxId(nextBloxId);
          } else if (sectionIdx < processSections.length - 1) {
            // Move to first blox of next section
            const nextSection = processSections[sectionIdx + 1];
            if (nextSection.bloxIds.length > 0) {
              const firstBloxId = nextSection.bloxIds[0];
              // Expand the next section if it's closed
              if (closedSections[nextSection.sectionId]) {
                setClosedSections(prev => ({
                  ...prev,
                  [nextSection.sectionId]: false
                }));
              }
              setSelectedBloxId(firstBloxId);
              // Trigger scroll to ensure proper positioning
              setShouldScrollToSelectedBlox(true);
            }
          }
          break;
        }
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedBloxId, processSections, isListView, isTableView, setSelectedBloxId, closedSections, setClosedSections, setShouldScrollToSelectedBlox]);

  // Render loading screen while process data is loading
  if (isLoading) {
    return <Loading />;
  }

  /**
   * Returns a JSX element for an empty section target, providing a drop target for
   * inserting new bloxes if a section is empty. This component:
   * - Uses findLastBloxInPreviousSections to determine insertion point
   * - Creates a drop target for new blox insertion
   * - Handles both regular blox and module insertions
   * 
   * @param {string} sectionId - The ID of the current section
   * @param {number} sectionIdx - The index of the current section
   * @returns {JSX.Element} Empty section drop target for inserting new bloxes
   */
  const getEmptySectionTarget = (sectionId: string, sectionIdx: number) => {
    const { lastBloxId, bloxIdx } = findLastBloxInPreviousSections(processSections, sectionIdx, processBloxes);
    if (!lastBloxId) {
      return <></>;
    }
    return <BetweenBlox 
      copiedBlox={copiedBloxState[0]} 
      showDropTarget={true} 
      handleInsertBlox={processHandleDrop ? (item) => processHandleDrop(item, bloxIdx, sectionId) : undefined}
      handleInsertModule={processHandleInsertSection ? (item) => processHandleInsertSection(item.data, bloxIdx, sectionId, item.moduleId, item.moduleName) : undefined}
    />
  }

  return (
    <>
      <SectionContainer containerRef={containerRef}>
        {renderProcessView({
          mode: isListView ? 'list' : isTableView ? 'table' : 'standard',
          props: isListView ? {
            processBloxes,
            processSections,
            selectedBloxId,
            closedSections,
            containerRef,
            processIsReadOnly,
            initialScrollTarget,
            onScrollComplete: () => setInitialScrollTarget(null),
            onSelectItem: (item: SectionedItem) => {
              if (item.type === 'blox') {
                setSelectedBloxId(item.blox.id);
              }
            },
            onToggleSection: (sectionId: string) => toggleSectionWithScroll(sectionId, closedSections, setClosedSections, containerRef),
            bloxSVGs
          } : isTableView ? {
            processBloxes,
            processSections,
            selectedBloxId,
            closedSections,
            containerRef,
            processIsReadOnly,
            height: "calc(100vh - 120px)",
            showAllParameters,
            splitWorksheet
          } : {
            processBloxes,
            processSections,
            selectedBloxId,
            closedSections,
            containerRef,
            processIsReadOnly,
            bloxSVGs,
            handleBloxActions: (bloxId: string, sectionId: string, idx: number) => ({
              onClickBlox: () => processHandleClickBlox(bloxId),
              onDelete: () => processHandleDelete(bloxId),
              handleInsertBlox: processHandleDrop
                ? (item) => processHandleDrop(item, processBloxes.findIndex(b => b.id === bloxId))
                : undefined,
              handleInsertModule: processHandleInsertSection
                ? (item) =>
                    processHandleInsertSection(
                      item.data,
                      processBloxes.findIndex(b => b.id === bloxId),
                      sectionId,
                      item.moduleId,
                      item.moduleName
                    )
                : undefined,
            }),
            getEmptySectionTarget,
            bloxDisplayProps: {
              hideStack: isStackHidden,
            },
            setClosedSections,
            setProcessSections
          }
        })}
      </SectionContainer>
    </>
  );
}