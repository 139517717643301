import * as ExcelJS from 'exceljs';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { Section } from '../__generated__/Process';
import { displayMap } from '../Data/display-mappings';
import { Tab } from '../Data/enums';
import { bloxKeyFields } from '../Data/BloxKeyFields';

export async function exportProcessBloxesToExcel(
    processName: string,
    processBloxes: AllBloxes[],
    processSections: Section[],
    splitSections = false,
    showAllParameters = true
) {
    const sanitizeName = (name: string) => name.replace(/[*?:/\\[\]&]/g, '_');
    const cleanProcessName = sanitizeName(processName);
    const workbook = new ExcelJS.Workbook();

    let globalStepNameColumnWidth = 0;
    let globalParamColumnWidth = 0;

    // Keep track of section names to ensure uniqueness
    const usedSectionNames = new Set<string>();

    const getUniqueSectionName = (baseName: string): string => {
        const name = baseName || 'Unnamed Section';
        let counter = 1;
        let uniqueName = name;

        while (usedSectionNames.has(uniqueName)) {
            uniqueName = `${name} (${counter})`;
            counter++;
        }

        usedSectionNames.add(uniqueName);
        return uniqueName;
    };

    const createSheetForSection = (sectionName: string) => {
        const uniqueSectionName = getUniqueSectionName(sectionName);
        const cleanSectionName = sanitizeName(uniqueSectionName);
        const sheet = workbook.addWorksheet(cleanSectionName);
        setupHeader(sheet);
        return sheet;
    };

    const setupHeader = (sheet: ExcelJS.Worksheet) => {
        sheet.addRow(['Step #', 'Step Name', 'Parameters', 'Comments']);
        sheet.addRow([]);
        const headerRow = sheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, size: 14 };
            cell.alignment = { horizontal: 'left', vertical: 'middle' };
        });

        const commentCellStart = 4;
        const commentCellEnd = commentCellStart + 5;
        sheet.mergeCells(1, commentCellStart, 1, commentCellEnd);
        sheet.mergeCells(2, commentCellStart, 2, commentCellEnd);
    };

    const addSectionContent = (sheet: ExcelJS.Worksheet, section: Section) => {
        const sectionName = section.sectionName || 'Unnamed Section';

        const row = sheet.addRow([sectionName]);
        const currentRowNumber = row.number;

        const numberSectionColumns = 9;
        sheet.mergeCells(currentRowNumber, 1, currentRowNumber, numberSectionColumns);
        const sectionHeader = sheet.getCell(`A${currentRowNumber}`);
        sectionHeader.font = { bold: true, size: 14 };
        sectionHeader.alignment = { horizontal: 'left', vertical: 'middle' };
        sectionHeader.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE1E6FF' },
        };

        // local column width trackers
        let stepNameColumnWidth = 0;
        let paramColumnWidth = 0;

        section.bloxIds.forEach((bloxId) => {
            const matchingBlox = processBloxes.find((blox) => blox.id === bloxId);
            if (matchingBlox) {
                const stepNumber = processBloxes.indexOf(matchingBlox);

                const displayMapForBlox = displayMap[matchingBlox.bloxType];
                if (!displayMapForBlox) {
                    console.warn(`No display map found for blox type: ${matchingBlox.bloxType}`);
                    return;
                }

                let bloxParams: string;

                if (matchingBlox.bloxType === 'STARTBLOX'){
                    const substratelayersString = matchingBlox.layers
                        ? matchingBlox.layers
                            .slice()
                            .reverse()
                            .map(layer => {
                                const label = layer.layerLabel?.trim() || "Unnamed Substrate Layer";
                                const thickness = layer.layerSimulationThickness ?? "Unknown Thickness";
                                const unit = layer.layerSimulationThicknessUnit || "Unknown Unit";
                                return `${label}: ${thickness} ${unit}`;
                            })
                            .join('\n')
                        : '';
                    bloxParams = substratelayersString;
                } else {
                    // Extract fields based on showAllParameters setting
                    const experimentalFields = Object.entries(displayMapForBlox)
                        .filter(([key, params]) => {
                            if (!params.tabs.includes(Tab.EXPERIMENTAL)) {
                                return false;  // Only show experimental parameters
                            }
                            if (!showAllParameters) {
                                // When not showing all, only show key experimental parameters
                                const keyFields = bloxKeyFields[matchingBlox.bloxType] || [];
                                return keyFields.includes(key);
                            }
                            return true;  // Show all experimental parameters
                        })
                        .map(([key]) => key);

                    const experimentalParams = experimentalFields
                        .reduce<string[]>((acc, field) => {
                            const value = matchingBlox[field as keyof AllBloxes];
                            const unitKey = `${field}Unit`;
                            const unit = matchingBlox[unitKey as keyof AllBloxes];
                            const fieldLabel = displayMapForBlox[field]?.label || field;
                            if (value !== undefined && value !== null) {
                                if (unit !== undefined && unit !== null) {
                                    acc.push(`${fieldLabel}: ${value} ${unit}`);
                                } else {
                                    acc.push(`${fieldLabel}: ${value}`);
                                }
                            }
                            return acc;
                        }, [])
                        .concat(
                            Object.values(matchingBlox.customFields || {})
                                .map(([customKey, customValue]: [string, string]) => `${customKey}: ${customValue}`)
                        )
                        .join('\n');
                    bloxParams = experimentalParams;
                }

                const bloxRow = sheet.addRow([stepNumber, matchingBlox.name, bloxParams]);
                stepNameColumnWidth = Math.max(stepNameColumnWidth, matchingBlox.name.length + 1);
                paramColumnWidth = Math.max(paramColumnWidth, ...bloxParams.split('\n').map(line => line.length));

                // Update global widths first
                globalStepNameColumnWidth = Math.max(globalStepNameColumnWidth, stepNameColumnWidth);
                globalParamColumnWidth = Math.max(globalParamColumnWidth, paramColumnWidth);

                // Set column widths based on global values
                if (!splitSections) {
                    sheet.getColumn(2).width = globalStepNameColumnWidth;
                    sheet.getColumn(3).width = globalParamColumnWidth;
                } else {
                    sheet.getColumn(2).width = stepNameColumnWidth;
                    sheet.getColumn(3).width = paramColumnWidth;
                }

                const commentField = matchingBlox.commentField || '';
                const rowNumber = bloxRow.number;
                const commentCellStart = 4;
                const commentCellEnd = commentCellStart + 5;

                sheet.mergeCells(rowNumber, commentCellStart, rowNumber, commentCellEnd);
                const commentCell = sheet.getCell(rowNumber, commentCellStart);
                commentCell.value = commentField;

                bloxRow.eachCell((cell) => {
                    cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
                });

                const calculateHeight = (content: string, width: number) => {
                    if (!content) return 0;
                    return content.split('\n').reduce((acc, line) => {
                        return acc + Math.ceil(line.length / (width || 10));
                    }, 0);
                };

                // Get actual column widths after they've been set
                const stepNameWidth = sheet.getColumn(2).width || 10;
                const paramWidth = sheet.getColumn(3).width || 10;
                const commentWidth = (sheet.getColumn(commentCellStart).width || 10) * 4;

                const stepNameHeight = calculateHeight(matchingBlox.name, stepNameWidth);
                const paramsHeight = calculateHeight(bloxParams, paramWidth);
                const commentHeight = calculateHeight(commentField, commentWidth);

                // Ensure minimum height and scale appropriately
                const minHeight = 1;
                const heightMultiplier = 15; // Increased from 12 for better readability
                const maxHeight = Math.max(minHeight, Math.max(stepNameHeight, paramsHeight, commentHeight)) * heightMultiplier;
                bloxRow.height = maxHeight;
            }
        });
    };

    if (splitSections) {
        processSections.forEach((section) => {
            const sheet = createSheetForSection(section.sectionName || 'Unnamed Section');
            addSectionContent(sheet, section);
        });
    } else {
        const sheet = workbook.addWorksheet(cleanProcessName);
        setupHeader(sheet);
        processSections.forEach((section) => addSectionContent(sheet, section));

        sheet.getColumn(2).width = globalStepNameColumnWidth;
        sheet.getColumn(3).width = globalParamColumnWidth;
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const effectiveProcessName = processName || "Unnamed_Process";
    link.download = `${effectiveProcessName.replace(/[^a-z0-9]/gi, '_')}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}