/**
 * @fileoverview AI-powered chatbot component for the IFrame application.
 * This component provides an interactive assistant that can help users with
 * process-related questions, suggestions, and guidance. It integrates with
 * Google's Gemini AI model to provide context-aware responses based on the
 * current process being viewed. Features include message history, process-specific
 * suggestions, and a collapsible interface with notification capabilities.
 */

import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactMarkdown from 'react-markdown';
import { 
  Box, 
  Typography, 
  IconButton, 
  Avatar, 
  Badge, 
  TextField, 
  InputAdornment, 
  Button, 
  Grow, 
  Tooltip
} from "@mui/material";

// Import icons
import CloseIcon from '@mui/icons-material/Close';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SendIcon from '@mui/icons-material/Send';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BiotechIcon from '@mui/icons-material/Biotech';

// Import utilities
import { getRandomProcessAdvice } from "./IFrameUtils";
import { sendMessage, clearChatHistory, initChatSession, testGeminiConnection } from '../../utils/geminiService';
// import { performRagQuery } from '../../api/rag';

// Define chatbot message types
export interface ChatMessage {
  id: string;
  text: string;
  sender: 'user' | 'bot';
  timestamp: Date;
  isActionable?: boolean;
  action?: () => void;
  actionLabel?: string;
  icon?: React.ReactNode;
}

// Add a unique ID generator for chat messages
let messageIdCounter = 0;
export const generateUniqueMessageId = (prefix: string): string => {
  const timestamp = Date.now();
  const randomSuffix = Math.floor(Math.random() * 1000);
  return `${prefix}-${timestamp}-${messageIdCounter++}-${randomSuffix}`;
};

// Default no-op function
const noop = () => { /* intentionally empty */ };

interface IFrameChatbotProps {
  iframeStyles: any;
  showChatbot: boolean;
  setShowChatbot: (show: boolean) => void;
  processBloxes: any[];
  processSections: any[];
  isLoading: boolean;
  isMobile: boolean;
  setProcessViewAdjusted: (adjusted: boolean) => void;
  setHasUnread: (hasUnread: boolean) => void;
}

/**
 * A reusable chatbot component for the IFrame process viewer
 */
export function IFrameChatbot({
  iframeStyles,
  showChatbot,
  setShowChatbot,
  processBloxes,
  processSections,
  isLoading,
  isMobile,
  setProcessViewAdjusted,
  setHasUnread
}: IFrameChatbotProps) {
  // Process assistant name
  const botName = "PANDA";
  const botFullName = "Process Advisor for Nanofabrication and Device Assembly";
  
  // Chatbot state
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState('');
  const chatEndRef = useRef<HTMLDivElement>(null);
  
  // Add refs to track if we've already shown suggestions
  const hasShownProcessSuggestion = useRef(false);
  const isProvidingSuggestions = useRef(false);
  
  // Add state for loading status during API calls
  const [isProcessingMessage, setIsProcessingMessage] = useState(false);
  
  /**
   * Scroll chat to bottom when new messages appear
   */
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);
  
  /**
   * Initialize the chatbot with a welcome message
   */
  useEffect(() => {
    // Only show welcome message once
    if (chatMessages.length === 0) {
      setTimeout(() => {
        const welcomeMessages: ChatMessage[] = [
          {
            id: generateUniqueMessageId('bot'),
            text: `👋 Hi there! I'm ${botName} (${botFullName}), your process engineering assistant.`,
            sender: 'bot',
            timestamp: new Date(),
            icon: <AutoAwesomeIcon />
          },
          {
            id: generateUniqueMessageId('bot'),
            text: "I can help suggest next steps for your semiconductor process flow. What are you working on today?",
            sender: 'bot',
            timestamp: new Date()
          }
        ];
        
        // Add welcome messages one by one with a delay
        welcomeMessages.forEach((msg, index) => {
          setTimeout(() => {
            setChatMessages(prev => [...prev, msg]);
          }, 800 * (index + 1));
        });
      }, 1000);
    }
  }, [chatMessages.length, botName, botFullName]);
  
  /**
   * Add process-specific suggestions when a process is loaded
   */
  useEffect(() => {
    const provideSuggestionIfReady = () => {
      // Only provide suggestions once per process load
      if (processBloxes?.length && processSections?.length && !isLoading && !hasShownProcessSuggestion.current) {
        // Set the flag to prevent duplicate suggestions
        hasShownProcessSuggestion.current = true;
        
        // Determine what kind of process this is based on the name or content
        const processType = determineProcessType();
        
        setTimeout(() => {
          const suggestionMessage: ChatMessage = {
            id: generateUniqueMessageId('bot'),
            text: `I've analyzed your ${processType} process. Would you like some suggestions for potential next steps?`,
            sender: 'bot',
            timestamp: new Date(),
            isActionable: true,
            action: () => {
              if (!isProvidingSuggestions.current) {
                provideSuggestions(processType);
              }
            },
            actionLabel: "YES, SHOW SUGGESTIONS",
            icon: <LightbulbIcon />
          };
          
          setChatMessages(prev => [...prev, suggestionMessage]);
          // Don't mark as unread since chatbot is open by default
          setHasUnread(false);
        }, 2000);
      }
    };
    
    provideSuggestionIfReady();
  }, [processBloxes, processSections, isLoading, setHasUnread]);
  
  /**
   * Determine the process type based on process content
   */
  const determineProcessType = (): string => {
    // Instead of returning a random type, always return "semiconductor" as it's more generic
    // NOTE: In a real implementation, this function would analyze the actual process content
    // (bloxes, sections, parameters, etc.) to determine the appropriate process type and
    // provide tailored suggestions based on what the user is actually developing
    return 'semiconductor';
  };
  
  /**
   * Provide a single random suggestion based on process type
   */
  const provideSuggestions = (processType: string) => {
    // Prevent multiple calls
    if (isProvidingSuggestions.current) return;
    isProvidingSuggestions.current = true;
    
    // Get a process suggestion (not a tip) - with improved type handling
    const advice = getRandomProcessAdvice(processType);
    
    // If we got a process tip instead of a suggestion, try again
    if (advice.isProcessTip) {
      isProvidingSuggestions.current = false;
      provideSuggestions(processType);
      return;
    }
    
    // Add messages in sequence with appropriate delays
    const messages: ChatMessage[] = [
      {
        id: generateUniqueMessageId('bot'),
        text: `Based on my analysis of your ${processType} process, here's a suggestion for a next step:`,
        sender: 'bot',
        timestamp: new Date()
      },
      {
        id: generateUniqueMessageId('bot'),
        text: advice.text,
        sender: 'bot',
        timestamp: new Date(),
        icon: <ArrowRightIcon />,
        isActionable: true,
        actionLabel: "Add this step",
        action: () => {
          // Placeholder action - does nothing for now
        }
      },
      {
        id: generateUniqueMessageId('bot'),
        text: "Would you like me to explain this suggestion in more detail?",
        sender: 'bot',
        timestamp: new Date()
      }
    ];
    
    // Show messages with sequential timing
    messages.forEach((message, index) => {
      setTimeout(() => {
        setChatMessages(prev => [...prev, message]);
        
        // Reset the flag after all messages have been added
        if (index === messages.length - 1) {
          setTimeout(() => {
            isProvidingSuggestions.current = false;
          }, 500);
        }
      }, 800 * (index + 1));
    });
  };
  
  // Test Gemini connection when chatbot is opened
  useEffect(() => {
    if (showChatbot) {
      const testConnection = async () => {
        const isConnected = await testGeminiConnection();
        if (isConnected) {
          // Add a system message to inform the user
          setChatMessages(prev => [...prev, {
            id: generateUniqueMessageId('bot'),
            text: "## Connected to PANDA! 🐼\n\nI'm your **P**rocess **A**ssistant for **N**ovel **D**evice **A**pplications, specializing in semiconductor fabrication.\n\nI can help with:\n\n- Understanding semiconductor processes\n- Recommending appropriate fabrication tools\n- Explaining deposition, etching, and other techniques\n- Optimizing process parameters\n\nHow can I assist with your process engineering today?",
            sender: 'bot',
            timestamp: new Date()
          }]);
        } else {
          // Add an error message to inform the user
          setChatMessages(prev => [...prev, {
            id: generateUniqueMessageId('bot'),
            text: "Unable to connect to the AI service. Please check your API key or try again later.",
            sender: 'bot',
            timestamp: new Date()
          }]);
        }
      };
      
      testConnection();
      // Also initialize the chat session
      initChatSession();
    } else {
      clearChatHistory();
    }
  }, [showChatbot]);
  
  /**
   * Enhances user messages for better AI responses
   */
  const enhanceUserPrompt = (input: string): string => {
    // No need to add explicit formatting instructions anymore since
    // we have strong system instructions for formatting
    return input;
  };
  
  /**
   * Handle user input submission
   */
  const handleSendMessage = () => {
    if (userInput.trim() === '') return;
    
    const newMessage: ChatMessage = {
      id: generateUniqueMessageId('user'),
      text: userInput,
      sender: 'user',
      timestamp: new Date()
    };
    
    setChatMessages(prev => [...prev, newMessage]);
    setUserInput('');
    setHasUnread(false);
    
    // Show loading indicator
    setIsProcessingMessage(true);
    
    // Call Gemini API asynchronously
    // generateBotResponseAsync(userInput);
    // generateBotResponse(userInput);
    getGeminiResponse(userInput);
  };
  
  /**
   * Function to get response from Gemini API
   */
  const getGeminiResponse = async (prompt: string): Promise<string> => {
    // Call the Gemini API with the prompt
    const response = await sendMessage(prompt);
    return response;
  };
  
  /**
   * Generate bot responses using Gemini API
   */
  // const generateBotResponseAsync = async (input: string) => {
  //   setIsProcessingMessage(true);
  //   const botResponses: ChatMessage[] = [];

  //   try {
  //     // Check if this is a tool/process related query
  //     const isToolQuery = input.toLowerCase().includes('tool') || 
  //                         input.toLowerCase().includes('equipment') ||
  //                         input.toLowerCase().includes('machine') ||
  //                         input.toLowerCase().includes('process') ||
  //                         input.toLowerCase().includes('fabrication');

  //     if (isToolQuery) {
  //       // Use enhanced RAG system for tool queries
  //       console.log("Using RAG for tool query");
        
  //       try {
  //         // Use our API client to perform the RAG query
  //         const ragResponse = await performRagQuery(input);
          
  //         // Add the main response
  //         botResponses.push({
  //           id: generateUniqueMessageId('bot'),
  //           text: ragResponse.response,
  //           sender: 'bot',
  //           timestamp: new Date()
  //         });

  //         // If there's context, add as a collapsible section
  //         if (ragResponse.context) {
  //           botResponses.push({
  //             id: generateUniqueMessageId('bot'),
  //             text: `<details>
  //                     <summary>**Show Database Search Results**</summary>
  //                     ${ragResponse.context}
  //                   </details>`,
  //             sender: 'bot',
  //             timestamp: new Date()
  //           });
  //         }
  //       } catch (error) {
  //         console.error('Error using FastAPI backend:', error);
  //         // Fall back to Gemini for non-tool queries or if API fails
  //         const geminiResponse = await getGeminiResponse(enhanceUserPrompt(input));
  //         botResponses.push({
  //           id: generateUniqueMessageId('bot'),
  //           text: geminiResponse,
  //           sender: 'bot',
  //           timestamp: new Date()
  //         });
  //       }
  //     } else {
  //       // Use Gemini directly for non-tool queries
  //       const geminiResponse = await getGeminiResponse(enhanceUserPrompt(input));
  //       botResponses.push({
  //         id: generateUniqueMessageId('bot'),
  //         text: geminiResponse,
  //         sender: 'bot',
  //         timestamp: new Date()
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error generating bot response:", error);
      
  //     botResponses.push({
  //       id: generateUniqueMessageId('bot'),
  //       text: "I'm sorry, I encountered an error while processing your request. Please try again.",
  //       sender: 'bot',
  //       timestamp: new Date()
  //     });
  //   }

  //   setIsProcessingMessage(false);
  //   setChatMessages(prev => [...prev, ...botResponses]);
  //   setHasUnread(true);
  // };
  
  /**
   * Legacy bot response function (keeping for fallback)
   */
  const generateBotResponse = (input: string): ChatMessage[] => {
    const lowerInput = input.toLowerCase();
    
    // Check for common questions or keywords
    if (lowerInput.includes('hello') || lowerInput.includes('hi') || lowerInput.includes('hey')) {
      return [{
        id: generateUniqueMessageId('bot'),
        text: `Hello! How can I help with your process engineering today?`,
        sender: 'bot',
        timestamp: new Date()
      }];
    }
    
    if (lowerInput.includes('thank')) {
      return [{
        id: generateUniqueMessageId('bot'),
        text: `You're welcome! Let me know if you need any other assistance with your process.`,
        sender: 'bot',
        timestamp: new Date()
      }];
    }
    
    if (lowerInput.includes('suggestion') || lowerInput.includes('tip') || lowerInput.includes('advice')) {
      // Provide a random process advice (tip or suggestion)
      const advice = getRandomProcessAdvice();
      return [
        {
          id: generateUniqueMessageId('bot'),
          text: advice.isProcessTip 
            ? `Here's a process engineering tip that might be helpful:` 
            : `Here's a suggestion for your process:`,
          sender: 'bot',
          timestamp: new Date()
        },
        {
          id: generateUniqueMessageId('bot'),
          text: advice.text,
          sender: 'bot',
          timestamp: new Date(),
          icon: <LightbulbIcon />,
          isActionable: true,
          actionLabel: "Tell me more",
          action: () => {
            if (!isProvidingSuggestions.current) {
              provideSuggestions(determineProcessType());
            }
          }
        }
      ];
    }
    
    // Default response
    return [{
      id: generateUniqueMessageId('bot'),
      text: `I'm still learning about process engineering. Would you like some suggestions for your current process?`,
      sender: 'bot',
      timestamp: new Date(),
      isActionable: true,
      actionLabel: "Yes, please",
      action: () => {
        if (!isProvidingSuggestions.current) {
          provideSuggestions(determineProcessType());
        }
      }
    }];
  };
  
  /**
   * Toggle the chatbot panel
   */
  const toggleChatbot = () => {
    const newState = !showChatbot;
    setShowChatbot(newState);
    // Only adjust process view if we're not on mobile
    if (!isMobile) {
      setProcessViewAdjusted(newState);
    } else {
      // On mobile, we don't adjust the process view
      setProcessViewAdjusted(false);
    }
    if (!newState) {
      setHasUnread(false);
    }
  };

  /**
   * Render the chatbot UI
   */
  return (
    <>
      {/* Add markdown styling */}
      <style dangerouslySetInnerHTML={{
        __html: `
        .markdown-message {
          margin: 0;
          font-family: inherit;
          font-size: 0.875rem;
          line-height: 1.43;
          color: inherit;
        }
        
        .markdown-message p {
          margin: 0 0 8px 0;
        }
        
        .markdown-message p:last-child {
          margin-bottom: 0;
        }
        
        .markdown-message strong, 
        .markdown-message b {
          font-weight: 600;
        }
        
        .markdown-message ul, 
        .markdown-message ol {
          margin: 0;
          padding-left: 20px;
        }
        
        .markdown-message li {
          margin-bottom: 4px;
        }
        
        .markdown-message code {
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          padding: 2px 4px;
          font-family: 'Consolas', 'Monaco', monospace;
          font-size: 0.85em;
        }
        
        .markdown-message pre {
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          padding: 8px;
          overflow-x: auto;
          margin: 8px 0;
        }

        .markdown-message h1, 
        .markdown-message h2, 
        .markdown-message h3, 
        .markdown-message h4 {
          margin-top: 0;
          margin-bottom: 8px;
          font-weight: 600;
        }

        .markdown-message h1 {
          font-size: 1.5rem;
        }

        .markdown-message h2 {
          font-size: 1.25rem;
        }

        .markdown-message h3 {
          font-size: 1.1rem;
        }
      `}} />
      <Grow
        in={showChatbot}
        style={{ transformOrigin: 'top right' }}
        {...(showChatbot ? { timeout: 300 } : {})}
      >
        <Box sx={iframeStyles.chatbotPanel}>
          {/* Chat Header */}
          <Box sx={iframeStyles.chatHeader}>
            <Box sx={iframeStyles.botTitle}>
              <Avatar sx={iframeStyles.assistantAvatar}>
                <BiotechIcon />
              </Avatar>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, letterSpacing: 0.5 }}>
                  {botName}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.9, letterSpacing: 0.3 }}>
                  Process Engineering Assistant
                </Typography>
              </Box>
            </Box>
            <IconButton 
              size="small" 
              onClick={toggleChatbot}
              sx={{ 
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)'
                }
              }}
              aria-label="Close process assistant"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          
          {/* Chat Body */}
          <Box sx={iframeStyles.chatBody}>
            {chatMessages.map((message) => (
              <Box
                key={message.id}
                sx={{
                  ...message.sender === 'bot' ? iframeStyles.botMessage : iframeStyles.userMessage,
                  ...(message.isActionable ? iframeStyles.actionableMessage : {}),
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onClick={message.isActionable ? message.action : undefined}
              >
                {message.sender === 'bot' && message.icon && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                    {message.icon}
                  </Box>
                )}
                {message.sender === 'bot' ? (
                  <div className="markdown-message">
                    <ReactMarkdown>
                      {message.text}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <Typography variant="body2">{message.text}</Typography>
                )}
                {message.isActionable && message.actionLabel && (
                  <Button 
                    size="small" 
                    variant="contained" 
                    color="primary" 
                    sx={{ 
                      alignSelf: 'flex-end', 
                      mt: 1,
                      borderRadius: '12px',
                      px: 2,
                      py: 0.5,
                      fontSize: '0.75rem',
                      fontWeight: 600,
                    }}
                    onClick={message.action}
                  >
                    {message.actionLabel}
                  </Button>
                )}
                <Typography 
                  variant="caption" 
                  color="textSecondary" 
                  sx={{ 
                    mt: 0.5, 
                    alignSelf: message.sender === 'bot' ? 'flex-start' : 'flex-end',
                    opacity: 0.7,
                    fontSize: '0.65rem'
                  }}
                >
                  {message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </Typography>
              </Box>
            ))}
            {/* Empty div for auto-scrolling */}
            <div ref={chatEndRef} />
          </Box>
          
          {/* Chat Footer */}
          <Box sx={iframeStyles.chatFooter}>
            <div className="flex flex-row space-x-2 p-2 items-center">
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                placeholder="Type your message here..."
                className="flex-grow border rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isProcessingMessage}
              />
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 focus:outline-none"
                disabled={isProcessingMessage}
              >
                {isProcessingMessage ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                  </svg>
                )}
              </button>
            </div>
          </Box>
        </Box>
      </Grow>
    </>
  );
}

/**
 * A toggler button for the chatbot
 */
export function ChatbotToggle({
  showChatbot,
  toggleChatbot,
  hasUnread
}: {
  showChatbot: boolean;
  toggleChatbot: () => void;
  hasUnread: boolean;
}) {
  return (
    <Badge 
      color="error" 
      variant="dot" 
      invisible={!hasUnread}
      overlap="circular"
    >
      <Tooltip title={showChatbot ? "Close process assistant" : "Open process assistant"}>
        <IconButton
          size="small"
          color={showChatbot ? "default" : "primary"}
          onClick={toggleChatbot}
          aria-label={showChatbot ? "Close process assistant" : "Open process assistant"}
        >
          <SmartToyIcon />
        </IconButton>
      </Tooltip>
    </Badge>
  );
}

export default IFrameChatbot; 