import { useAuth0 } from '@auth0/auth0-react'
import { ReactElement, ReactNode, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Loading } from '../components/Loading'

/**
 * Interface extending RouteProps for protected routes that require authentication
 * @interface ProtectedRouteProps
 * @extends {RouteProps}
 */
interface ProtectedRouteProps extends RouteProps {
  /** The child components to render when authenticated */
  children: ReactNode
  /** The path pattern to match for this route */
  path: string
}

/**
 * ProtectedRoute is a wrapper component that ensures routes are only accessible to authenticated users.
 * If a user is not authenticated, they will be redirected to the Auth0 login page.
 * After successful authentication, they will be redirected back to the originally requested route.
 * 
 * @component
 * @param {ProtectedRouteProps} props - Component props
 * @param {ReactNode} props.children - The components to render when authenticated
 * @param {string} props.path - The route path to protect
 * @param {...RouteProps} args - Additional React Router route props
 * 
 * @returns {ReactElement} Returns either the protected route content when authenticated,
 *                        initiates a login redirect when not authenticated,
 *                        or shows a loading component during authentication check
 */
export const ProtectedRoute = ({ children, ...args }: ProtectedRouteProps): ReactElement => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }

    /**
     * Initiates the Auth0 login flow and sets up the return URL for post-authentication
     */
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      })
    })()
  }, [isLoading, isAuthenticated, loginWithRedirect])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    isAuthenticated ? <Route {...args}>{children}</Route> : <Loading/>
  )
}