import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useExportDialogState } from '../hooks/state/export-dialog-provider';

/**
 * Props for the ExportActions component
 * @interface ExportActionsProps
 * @property {() => void} onCopy - Handler for copy to clipboard action
 * @property {() => void} onDownload - Handler for download action
 * @property {() => void} onExportGif - Handler for GIF export
 * @property {() => void} onExportWebM - Handler for WebM export
 * @property {boolean} isExporting - Whether an export operation is in progress
 */
interface ExportActionsProps {
    onCopy: () => void;
    onDownload: () => void;
    onExportGif: () => void;
    onExportWebM: () => void;
    isExporting: boolean;
}

/**
 * Component for export action buttons
 * @component
 * @param {ExportActionsProps} props - Component props
 */
export const ExportActions: React.FC<ExportActionsProps> = ({
    onCopy,
    onDownload,
    onExportGif,
    onExportWebM,
    isExporting
}) => {
    const { state } = useExportDialogState();
    const { fileFormat } = state;

    const getExportAction = () => {
        switch (fileFormat) {
            case "GIF":
                return {
                    action: onExportGif,
                    text: isExporting ? "Exporting GIF..." : "Export GIF"
                };
            case "WEBM":
                return {
                    action: onExportWebM,
                    text: isExporting ? "Exporting Video..." : "Export Video"
                };
            default:
                return {
                    action: onDownload,
                    text: "Download"
                };
        }
    };

    const { action, text } = getExportAction();

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
            {fileFormat === "PNG" && 
                <Button 
                    onClick={onCopy} 
                    icon={IconNames.CLIPBOARD} 
                    text={'Copy to Clipboard'} 
                    style={{ 
                        borderRadius: '6px',
                        backgroundColor: 'var(--background-lighter)'
                    }} 
                />
            }
            <Button 
                onClick={action}
                icon={!isExporting ? (fileFormat === "GIF" || fileFormat === "WEBM" ? IconNames.EXPORT : IconNames.DOWNLOAD) : undefined}
                style={{ 
                    borderRadius: '6px',
                    minWidth: '120px'  // Ensure consistent width during loading
                }} 
                intent={Intent.SUCCESS}
                loading={isExporting && (fileFormat === "GIF" || fileFormat === "WEBM")}
                disabled={isExporting}
            >
                {text}
            </Button>
        </div>
    );
}; 