import { useState } from "react";
import {
  Button,
  InputGroup,
  Popover,
  Classes,
  Intent,
  Colors,
  Icon,
} from "@blueprintjs/core";
import { SketchPicker, ColorResult } from "react-color";
import { Color, IColor } from "../../utils/Color";
import { IconNames } from "@blueprintjs/icons";
import { Row } from "../../Layout/layouts";

interface NewMaterialProps {
  onConfirm: (label: string, color: IColor) => void;
  onCancel: () => void;
  isAddingState: [boolean, (state: boolean) => void];
  isDimmed: boolean;
}

export default function NewMaterial({
  onConfirm,
  onCancel,
  isAddingState,
  isDimmed,
}: NewMaterialProps) {
  const [isAdding, setIsAdding] = isAddingState;
  const [label, setLabel] = useState("");
  const [color, setColor] = useState<IColor>({ R: 0, G: 0, B: 0, A: 1 });
  const [colorPopoverOpen, setColorPopoverOpen] = useState(false);

  const handleColorChange = (colorResult: ColorResult) => {
    setColor({
      R: colorResult.rgb.r,
      G: colorResult.rgb.g,
      B: colorResult.rgb.b,
      A: colorResult.rgb.a ?? 1,
    });
  };

  const handleConfirm = () => {
    if (!label.trim()) return; // Safeguard if user tries pressing Enter
    onConfirm(label.trim(), color);
    resetState();
  };

  const handleCancel = () => {
    resetState();
    onCancel();
  };

  const resetState = () => {
    setIsAdding(false);
    setLabel("");
    setColor({ R: 0, G: 0, B: 0, A: 1 });
  };

  if (!isAdding) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "8px 0",
        }}
      >
        <Button
          icon={<Icon size={20} icon={IconNames.Plus} color={Colors.BLUE3} />}
          text="Switch to New Material"
          onClick={() => setIsAdding(true)}
          minimal
          style={{
            fontWeight: "bold",
            color: Colors.BLUE3,
            opacity: isDimmed ? 0.3 : 1,
            pointerEvents: isDimmed ? "none" : "auto",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={Classes.CARD}
      style={{
        padding: "8px",
        marginBottom: "8px",
        flex: "1",
      }}
    >
      <Row style={{ alignItems: "center", width: "100%" }}>
        {/* Material Name Input */}
        <InputGroup
          placeholder="Material Name"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          style={{ marginRight: "auto" }}
        />

        {/* Color Picker */}
        <Popover
          isOpen={colorPopoverOpen}
          content={
            <SketchPicker
              color={Color.getCSS(color)}
              onChange={handleColorChange}
            />
          }
          onClose={() => setColorPopoverOpen(false)}
          hasBackdrop
        >
          <div
            style={{
              backgroundColor: Color.getCSS(color),
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #ccc",
              cursor: "pointer",
              marginLeft: "8px",
            }}
            onClick={() => setColorPopoverOpen(true)}
          />
        </Popover>

        {/* Action Buttons (Match "editing mode") */}
        <Button
          minimal
          onClick={handleCancel}
          style={{ marginLeft: "auto", marginRight: "5px" }}
          intent={Intent.DANGER}
          text="Cancel"
        />
        <Button
          minimal
          onClick={handleConfirm}
          intent={Intent.PRIMARY}
          disabled={!label.trim()}
          text="Done"
        />
      </Row>
    </div>
  );
}
