import { Dialog, Classes, Button, Intent, Tag } from "@blueprintjs/core";

interface ConfirmUpdateMaterialProps {
  /** Whether the dialog is currently open */
  isOpen: boolean;
  /**
   * Dialog data containing:
   * - The old material name to be replaced
   * - The new material name replacing it
   */
  data: {
    oldMaterialName: string;
    newMaterialName: string;
  };
  /** Callback when user confirms merging materials */
  onConfirm: () => void;
  /** Callback when user cancels merging materials */
  onCancel: () => void;
}

/**
 * A simple dialog to warn the user about merging materials:
 * "This action will replace oldMaterialName with newMaterialName."
 */
export function ConfirmUpdateMaterialDialog({
  isOpen,
  data: { oldMaterialName, newMaterialName },
  onConfirm,
  onCancel,
}: ConfirmUpdateMaterialProps) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onCancel}
      title="Merging Materials"
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          This action will replace <Tag>{oldMaterialName}</Tag> and all its targets
          {" "}with <Tag intent={Intent.PRIMARY}>{newMaterialName}</Tag>.
        </p>
        <p><Tag>{oldMaterialName}</Tag> will be removed from the material list.</p>
        <p>Are you sure you want to continue?</p>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={onConfirm}
          >
            Yes, Merge
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
