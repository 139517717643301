import { useHistory } from 'react-router-dom'
import { Row } from '../Layout/layouts'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginLogoutButton from './login-logout-button';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useReadUser } from '../hooks/DataFetching/use-fetch-user'; 
import { Avatar } from '@mui/material';
import { getUserDisplayName } from '../utils/user-display';

export const HamburgerMenu: React.FC = () => {
  const history = useHistory()
  const [open, setOpen] = React.useState(false);
  const { user: auth0User } = useAuth0();
  const { data: userData, isLoading: userLoading, error: userError } = useReadUser(auth0User?.sub);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <div className='tw-flex tw-flex-col tw-min-h-[100vh] '>
    <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
      {[{
        text: getUserDisplayName(userData),
        path: '/profile'
      }].map((item) => (
        <ListItem key={item.text} disablePadding>
          <ListItemButton sx={{ alignItems: 'center' }} onClick={() => history.push(item.path)}>
            <ListItemIcon sx={{ 
              minWidth: 24, 
              width: 24, 
              marginLeft: '-8px',
              marginRight: '14px',
              display: 'flex',
              alignItems: 'center'
            }}>
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: 'transparent' }}
                src={
                  userData?.profilePicture && userData.profilePicture !== 'default'
                    ? `ProfileAvatars/${userData.profilePicture}.webp`
                    : undefined
                }
              >
                {(!userData?.profilePicture || userData.profilePicture === 'default') && (
                  <PersonIcon sx={{ fontSize: 24, color: '#8E4CA8' }} />
                )}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
      </List>
      <Divider />
      <List>
      {[
      { text: 'Process Editor', path: '/process-editor' },
        { text: 'My Processes', path: '/my-processes' },
        { text: 'Group Processes', path: '/my-group-processes' }
      ].map((item, index) => (
        <ListItem key={item.text} disablePadding>
          <ListItemButton sx={{ alignItems: 'center' }} onClick={() => history.push(item.path)}>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
      </List>
      <Divider />
      <List>
      {[
        { text: 'Module Editor', path: '/module-editor' },
        { text: 'My Modules', path: '/my-modules' },
        { text: 'Group Modules', path: '/my-group-modules' }
      ].map((item) => (
        <ListItem key={item.text} disablePadding>
          <ListItemButton sx={{ alignItems: 'center' }} onClick={() => history.push(item.path)}>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
      </List>
      <Divider />
      <List>
        {[
          { text: 'Fabubase', path: '/fabubase' }
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ alignItems: 'center' }} onClick={() => history.push(item.path)}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
    </div>
  
  );

  return (
    <Row style={{ margin: 'auto' }}>
    <div>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon style={{ color: 'var(--text-white)' }} />
      </IconButton>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        >
        <Box 
          display="flex"
          flexDirection="column"
          height="100%"
        >
          {DrawerList}
          <Box 
          mt="auto" pl={1} pb={2}>
            <LoginLogoutButton 
            />
          </Box>
        </Box>
      </Drawer>
    </div>
  </Row>
  )
}