import { useState, useCallback } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TemplatesSection } from '../templates';

interface TemplatesManagerProps {
  iframeStyles: any;
  sizeConfig: any;
  isMobile: boolean;
  selectedProcessId: string | undefined;
  showTemplates: boolean;
  setShowTemplates: (show: boolean) => void;
  onLoadTemplate: (templateId: string) => void;
  onStartNewProcess: () => void;
  showToast: { message: (msg: string) => void; intent: string; timeout: number };
}

/**
 * Component for managing and displaying templates in a collapsible container
 */
export function TemplatesManager({
  iframeStyles,
  sizeConfig,
  isMobile,
  selectedProcessId,
  showTemplates,
  setShowTemplates,
  onLoadTemplate,
  onStartNewProcess,
  showToast
}: TemplatesManagerProps) {
  // Local state for the collapsible templates container
  const [templatesCollapsed, setTemplatesCollapsed] = useState(false);

  // Ensure templates are loaded with page - set showTemplates to true by default
  // This could be moved to a useEffect in the parent component
  
  // Toggle templates collapsed state
  const toggleTemplatesCollapsed = useCallback(() => {
    setTemplatesCollapsed(!templatesCollapsed);
    
    // If templates were collapsed and now being expanded, show them
    if (templatesCollapsed) {
      setShowTemplates(true);
    }
  }, [templatesCollapsed, setShowTemplates]);

  // Render the collapsible templates container
  return (
    <Paper 
      elevation={0}
      sx={{
        width: templatesCollapsed ? 'auto' : '100%', // Reduce width when collapsed
        maxWidth: templatesCollapsed ? '220px' : '100%', // Slightly wider when collapsed
        marginBottom: '16px', // Gap between templates and content below
        borderRadius: sizeConfig.borderRadius, // Match the container below it
        overflow: 'hidden',
        border: '1px solid #e0e0e0', // Same border as frameContainer
        backgroundColor: 'var(--background-white)',
        transition: 'width 0.3s ease-in-out, max-width 0.3s ease-in-out',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)', // Bottom-right shadow for depth
      }}
    >
      {/* Collapsible header for Example Processes */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: templatesCollapsed ? '8px 16px' : '10px 16px',
          backgroundColor: 'var(--background-white)',
          color: 'var(--text-primary)',
          cursor: 'pointer',
          borderRadius: templatesCollapsed ? sizeConfig.borderRadius : `${sizeConfig.borderRadius} ${sizeConfig.borderRadius} 0 0`,
          borderBottom: templatesCollapsed ? 'none' : '1px solid #e0e0e0',
        }}
        onClick={toggleTemplatesCollapsed}
      >
        <Typography 
          variant="subtitle1" 
          fontWeight="600"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.95rem',
            letterSpacing: '0.01em',
            whiteSpace: templatesCollapsed ? 'nowrap' : 'normal',
            overflow: templatesCollapsed ? 'hidden' : 'visible',
            textOverflow: templatesCollapsed ? 'ellipsis' : 'clip',
          }}
        >
          Example Processes
        </Typography>
        <IconButton 
          size="small" 
          sx={{ 
            color: 'var(--text-primary)',
            marginLeft: '8px',
            padding: '2px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleTemplatesCollapsed();
          }}
        >
          {templatesCollapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
        </IconButton>
      </Box>
      
      {/* Collapsible content for Example Processes */}
      <Box
        sx={{
          maxHeight: templatesCollapsed ? '0px' : '380px',
          transition: 'max-height 0.3s ease-in-out',
          overflow: 'hidden',
          backgroundColor: 'var(--background-white)',
        }}
      >
        {!templatesCollapsed && showTemplates && (
          <TemplatesSection
            iframeStyles={iframeStyles}
            sizeConfig={sizeConfig}
            isMobile={isMobile}
            showTemplates={showTemplates}
            setShowTemplates={setShowTemplates}
            onLoadTemplate={onLoadTemplate}
            onStartNewProcess={onStartNewProcess}
            hideTitle={true} /* Pass prop to hide redundant title */
            showToast={showToast}
            selectedProcessId={selectedProcessId} /* Pass the current process ID */
          />
        )}
      </Box>
    </Paper>
  );
} 