import { SvgServiceProvider } from "../hooks/state/svg-service-provider";
import { ProcessSettingsProvider } from "../hooks/state/process-settings-provider";
import { ParameterContextProvider } from "../hooks/state/parameter-context";
import { useFabuStateProvider } from "../hooks/state/fabu-state-provider";
import { IframeProcessViewer } from "../components/IFrameComponents/viewer";
import { IFrameAppProvider } from "../components/IFrameComponents/IFrameAppProvider";
import { Box } from "@mui/material";

/**
 * IframeProcessViewerPage component that provides all necessary context providers
 * for the IframeProcessViewer component to function properly.
 * 
 * This is a clean, simplified version of the ProcessViewerPage specifically
 * designed for embedding in iframes or direct linking.
 * 
 * @component
 * @returns {JSX.Element} Rendered IframeProcessViewerPage component
 */
export const IframeProcessViewerPage = () => {
  const fabuState = useFabuStateProvider();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        bgcolor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <ParameterContextProvider providers={fabuState}>
        <ProcessSettingsProvider>
          <SvgServiceProvider>
            <IFrameAppProvider>
              <Box
                sx={{
                  flex: 1,
                  width: '100%',
                  height: '100%',
                  overflow: 'auto'
                }}
              >
                <IframeProcessViewer />
              </Box>
            </IFrameAppProvider>
          </SvgServiceProvider>
        </ProcessSettingsProvider>
      </ParameterContextProvider>
    </Box>
  );
}; 