/**
 * @fileoverview ProcessViewRenderer is a high-level orchestrator component that manages different visualization modes
 * for process workflows. It serves as a central hub for rendering processes in list, table, or standard views.
 * 
 * Key Features:
 * - Multiple view mode support (list, table, standard)
 * - Unified interface for process visualization
 * - Section-aware rendering
 * - Responsive layout management
 * - Integrated navigation and interaction handling
 * 
 * Component Hierarchy:
 * ProcessEditor
 * └─ ProcessViewRenderer (This Component)
 *    ├─ List View Mode
 *    │  └─ ProcessListView
 *    │     ├─ Section Headers
 *    │     └─ BloxListItems
 *    ├─ Table View Mode
 *    │  └─ ProcessTableView
 *    │     └─ Parameter Grid
 *    └─ Standard View Mode
 *       ├─ SectionHeader
 *       └─ SectionRenderer
 *          └─ ProcessBlox
 * 
 * View Modes:
 * 1. List View: Vertical, compact representation with collapsible sections
 * 2. Table View: Spreadsheet-style view with parameter columns
 * 3. Standard View: Horizontal process flow with visual section organization
 * 
 * State Handling:
 * - View mode selection
 * - Section visibility states
 * - Selection management
 * - Scroll position tracking
 */

import React from 'react';
import { Box, Typography, IconButton } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ProcessListView from './ListViewComponents/ProcessListView';
import BloxListItem from './ListViewComponents/BloxListItem';
import ProcessTableView from './TableViewComponents/ProcessTableView';
import SectionHeader from './SectionHeader';
import SectionRenderer from './SectionRenderer';
import { processListViewStyles } from './ListViewComponents/styles/ProcessListView.styles';
import { ListViewProps, TableViewProps, StandardViewProps, RenderProcessViewProps, SectionedItem, prepareSectionedItems } from '../utils/processUtils';
import { BloxTypes } from "../Data/BloxSchema/base-blox";
import { scrollToSection } from '../utils/scrollUtils';

/**
 * Renders the list view mode of the process visualization.
 * Provides a vertical, section-based representation with collapsible sections
 * and integrated navigation controls.
 *
 * @param props - Configuration and data for list view rendering
 * @returns Rendered list view component
 */
const renderListView = (props: ListViewProps): JSX.Element => {
  const {
    processBloxes,
    processSections,
    selectedBloxId,
    closedSections,
    initialScrollTarget,
    onScrollComplete,
    onSelectItem,
    onToggleSection,
    bloxSVGs
  } = props;

  const sectionedItems = prepareSectionedItems(processSections, processBloxes);

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center'
    }}>
      <ProcessListView<SectionedItem>
        items={sectionedItems}
        initialScrollTarget={initialScrollTarget}
        onScrollComplete={onScrollComplete}
        onSelectItem={onSelectItem}
        onToggleSection={onToggleSection}
        getItemKey={(item: SectionedItem) =>
          item.type === 'blox' ? item.blox.id : item.sectionId
        }
        isSectionHeader={(item: SectionedItem) => item.type === 'sectionHeader'}
        getSectionId={(item: SectionedItem) =>
          item.type === 'sectionHeader' ? item.sectionId : item.sectionId
        }
        getItemPrimaryText={(item: SectionedItem) =>
          item.type === 'blox'
            ? item.blox.name
            : item.sectionName
        }
        getItemSecondaryText={(item: SectionedItem) =>
          item.type === 'blox' ? `Step ${item.stepNumber}` : ''
        }
        renderItem={(item: SectionedItem) => {
          if (item.type === 'blox') {
            return (
              <BloxListItem
                stepNumber={item.stepNumber}
                bloxName={item.blox.name}
                bloxProperties={item.blox}
                tools={item.blox.toolName ? [item.blox.toolName] : []}
                isStartBlox={item.blox.bloxType === BloxTypes.StartBlox}
                bloxType={item.blox.bloxType}
                isSelected={item.blox.id === selectedBloxId}
                bloxSVG={bloxSVGs[item.stepNumber]}
                allBloxesUpToThisPoint={processBloxes}
                initialIndex={item.stepNumber}
                processSections={processSections}
              />
            );
          }
          
          const isOpen = !closedSections[item.sectionId];
          return (
            <Box
              sx={processListViewStyles.sectionHeader.root}
              role="button"
              aria-expanded={isOpen}
              id={`item-${item.sectionId}`}
            >
              <Typography sx={processListViewStyles.sectionHeader.title}>
                {item.sectionName}
              </Typography>
              <IconButton
                size="small"
                sx={processListViewStyles.sectionHeader.expandIcon}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onToggleSection(item.sectionId);
                }}
              >
                {isOpen ? <ExpandMore /> : <ChevronRight />}
              </IconButton>
            </Box>
          );
        }}
        containerStyle={{
          width: '60%',
          maxWidth: '800px'
        }}
        sections={processSections}
        closedSections={closedSections}
      />
    </Box>
  );
};

/**
 * Renders the table view mode of the process visualization.
 * Displays process data in a spreadsheet-like format with parameter columns
 * and section organization.
 *
 * @param props - Configuration and data for table view rendering
 * @returns Rendered table view component
 */
const renderTableView = (props: TableViewProps): JSX.Element => {
  const {
    processBloxes,
    processSections,
    height,
    showAllParameters,
    splitWorksheet
  } = props;

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: '20px'
    }}>
      <ProcessTableView
        processBloxes={processBloxes}
        processSections={processSections}
        height={height}
        showAllParameters={showAllParameters}
        splitWorksheet={splitWorksheet}
      />
    </Box>
  );
};

/**
 * Renders the standard view mode of the process visualization.
 * Shows a horizontal process flow with section headers and detailed
 * blox representations.
 *
 * @param props - Configuration and data for standard view rendering
 * @returns Rendered standard view component
 */
const renderStandardView = (props: StandardViewProps): JSX.Element => {
  const {
    processBloxes,
    processSections,
    selectedBloxId,
    closedSections,
    containerRef,
    processIsReadOnly,
    bloxSVGs,
    handleBloxActions,
    getEmptySectionTarget,
    bloxDisplayProps,
    setClosedSections,
    setProcessSections
  } = props;

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }}>
      {processSections.map((section, sectionIndex) => (
        <Box key={section.sectionId}>
          <SectionHeader
            section={section}
            sectionIndex={sectionIndex}
            processSections={processSections}
            setProcessSections={setProcessSections}
            closedSections={closedSections}
            setClosedSections={setClosedSections}
            containerRef={containerRef}
            onToggleSection={() => {
              const newState = !closedSections[section.sectionId];
              setClosedSections(prevState => ({
                ...prevState,
                [section.sectionId]: newState
              }));
              if (!newState) {
                setTimeout(() => scrollToSection(section.sectionId, containerRef), 100);
              }
            }}
          />
          <SectionRenderer
            section={section}
            sectionIndex={sectionIndex}
            processSections={processSections}
            bloxes={processBloxes}
            bloxSVGs={bloxSVGs}
            selectedBloxId={selectedBloxId || null}
            closedSections={closedSections}
            containerRef={containerRef}
            processIsReadOnly={processIsReadOnly}
            handleBloxActions={handleBloxActions}
            getEmptySectionTarget={getEmptySectionTarget}
            bloxDisplayProps={bloxDisplayProps}
          />
        </Box>
      ))}
    </Box>
  );
};

/**
 * Primary function for rendering process visualizations based on the selected view mode.
 * Acts as a dispatcher that delegates rendering to the appropriate view-specific function.
 *
 * @param viewConfig - Configuration object containing view mode and associated props
 * @returns Rendered view component based on selected mode
 * @throws Error if an unknown view mode is provided
 */
export const renderProcessView = (
  viewConfig: RenderProcessViewProps
): JSX.Element => {
  const { mode, props } = viewConfig;
  
  switch (mode) {
    case 'list':
      return renderListView(props as ListViewProps);
    case 'table':
      return renderTableView(props as TableViewProps);
    case 'print':
      return renderStandardView(props as StandardViewProps);
    case 'standard':
    default:
      return renderStandardView(props as StandardViewProps);
  }
}; 