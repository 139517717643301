import React from 'react';
import { Menu, MenuItem, Switch, Tooltip, MenuDivider } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

export interface TableSettingsMenuProps {
    showAllParameters: boolean;
    setShowAllParameters: (show: boolean) => void;
    splitWorksheet: boolean;
    setSplitWorksheet: (split: boolean) => void;
}

export const TableSettingsMenu: React.FC<TableSettingsMenuProps> = ({
    showAllParameters,
    setShowAllParameters,
    splitWorksheet,
    setSplitWorksheet
}) => (
    <Menu>
        <MenuItem
            icon={IconNames.PROPERTIES}
            text="Show All Experimental Parameters"
            shouldDismissPopover={false}
            labelElement={
                <Switch
                    checked={showAllParameters}
                    onChange={() => setShowAllParameters(!showAllParameters)}
                    style={{ margin: 0 }}
                />
            }
        />
        <MenuItem
            icon={IconNames.SPLIT_COLUMNS}
            text="Split Worksheet"
            shouldDismissPopover={false}
            labelElement={
                <Switch
                    checked={splitWorksheet}
                    onChange={() => setSplitWorksheet(!splitWorksheet)}
                    style={{ margin: 0 }}
                />
            }
        />
    </Menu>
);

export interface ExportSettingsMenuProps {
    exportData: () => void;
    exportRequirementsData?: () => void;
    isLoading: boolean;
    onExcelExport: () => void;
    onPowerPointExport: () => void;
}

export const ExportSettingsMenu: React.FC<ExportSettingsMenuProps> = ({
    exportData,
    exportRequirementsData,
    isLoading,
    onExcelExport,
    onPowerPointExport
}) => (
    <Menu>
        <MenuItem 
            icon={IconNames.DOCUMENT} 
            text="Download as DocX" 
            onClick={exportData} 
            disabled={isLoading} 
        />
        <MenuItem 
            icon={IconNames.GRID_VIEW} 
            text="Download as Excel" 
            onClick={onExcelExport}
        />
        <MenuItem 
            icon={IconNames.PRESENTATION} 
            text="Download as PowerPoint" 
            onClick={onPowerPointExport}
        />
        {exportRequirementsData && (
            <>
                <MenuDivider />
                <MenuItem 
                    icon={IconNames.LIST_DETAIL_VIEW}
                    text="Download Process Requirements" 
                    onClick={exportRequirementsData} 
                    disabled={isLoading} 
                />
            </>
        )}
    </Menu>
);

export interface ListSettingsMenuProps {
    smoothScroll: boolean;
    setSmoothScroll: (smooth: boolean) => void;
}

export const ListSettingsMenu: React.FC<ListSettingsMenuProps> = ({
    smoothScroll,
    setSmoothScroll
}) => (
    <Menu>
        <Tooltip
            content="Disable this for instant scroll"
            position="left"
        >
            <MenuItem
                icon={IconNames.ARROWS_HORIZONTAL}
                text="Smooth Scrolling"
                shouldDismissPopover={false}
                labelElement={
                    <Switch
                        checked={smoothScroll}
                        onChange={() => setSmoothScroll(!smoothScroll)}
                        style={{ margin: 0 }}
                    />
                }
            />
        </Tooltip>
    </Menu>
); 