/**
 * @fileoverview Provides a strongly-typed hook for accessing Fabublox application state.
 * This module combines the parameter context system with the Fabu state provider
 * to offer a type-safe way to access and modify application state parameters.
 */
import { useFabuStateProvider } from './fabu-state-provider';
import { ExtractParametersType } from './parameter-context';
import { useParameter } from './use-parameter';

/**
 * Type definition that extracts the parameter types from the Fabu state provider.
 * This type represents all available application state parameters and their types.
 * 
 * @typedef {ExtractParametersType<ReturnType<typeof useFabuStateProvider>>} AppParamsType
 */
export type AppParamsType = ExtractParametersType<ReturnType<typeof useFabuStateProvider>>;

/**
 * Custom hook for accessing and modifying Fabublox application state parameters.
 * Provides type-safe access to specific state parameters using the parameter context system.
 * 
 * @template K - The key of the parameter to access, must be a valid key of AppParamsType
 * @param {K} param - The name of the parameter to access
 * @returns {[AppParamsType[K], React.Dispatch<React.SetStateAction<AppParamsType[K]>>]} A tuple containing the parameter value and its setter function
 */
export function useFabuState<K extends keyof AppParamsType>(param: K) {
    return useParameter<AppParamsType, K>(param);
}
