import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { ShadowmaskDialogContent } from "../../dialogs/ShadowMaskDialogContent";
import { getDefaultColor } from "../material-mappings";

export interface ThermalEvap extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    depRate: number | null;
    depRateUnit: Units | null;
    appliedPower: number | null;
    appliedPowerUnit: Units | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    hasShadowMask: boolean | null;
    shadowMaskPattern: string | null;
    shadowMaskPatternDisabled: () => boolean | null;
    shadowMaskInvertPattern: boolean;
    // SEMIFAB
    depAngle: number | null;
    depAngleUnit: Units | null;
}

export const thermalEvapEvapDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    appliedPower: {
        fieldType: DisplayFieldTypes.Input,
        label: "Applied Power or Current",
        placeholder: "Enter applied power/current",
        isNumber: true,
        units: [Units.PERCENT, Units.WATT, Units.AMP],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.BAR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 0
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
    separator: {
        fieldType: DisplayFieldTypes.Separator,
        order: 10,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    hasShadowMask: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Shadow Mask",
        order: 11,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    shadowMaskPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Shadow Mask Pattern",
        order: 12,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: ShadowmaskDialogContent,
        isOptionalSemifab: true,
    },
    shadowMaskInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 13,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },

    // SEMIFAB
    depAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Angle",
        placeholder: "Enter deposition angle",
        isNumber: true,
        units: [Units.DEG],
        order: 4,
        tabs: [Tab.SEMIFAB],
        infoContent: "0° refers to a perfectly vertical depositions. Positive angles will deposit from the left, and negative angles will deposit from the right."
    },
}

export const getThermalEvap = ({stepNumber}: BloxArgs): ThermalEvap => ({
        name: `Thermal Evaporation ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.ThermalEvap,
        material: null,
        thickness: null,
        thicknessUnit: Units.ANG,
        depRate: null,
        depRateUnit: Units.ANGPERSEC,
        appliedPower: null,
        appliedPowerUnit: Units.AMP,
        chamberPressure: null,
        chamberPressureUnit: Units.TORR,
        commentField: null,
        layerColor: getDefaultColor(BloxTypes.ThermalEvap),
        layerThickness: 8,
        sidewallThickness: 30,
        layerLabel: null,
        customFields: {},
        hasShadowMask: false,
        shadowMaskPattern: "1,2,4,2,1",
        shadowMaskPatternDisabled: function () {
            return !this.hasShadowMask;
        },
        shadowMaskInvertPattern: false,       
        depAngle: 0,
        depAngleUnit: Units.DEG,
});
