import { Intent } from "@blueprintjs/core";
import pptxgen from "pptxgenjs";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { Section, Process } from '../__generated__/Process';
import { showToast } from "..";
import { UpdateUserRequest, User } from "../__generated__/User";
import { UseMutationResult } from "react-query";
import FabuLogo from '../components/svg/logo_ppt.svg';
import { Console } from "console";

function layerListToString(layerList?: string[] | null | undefined): string{
    let layerListToString = "";
    if ((layerList === undefined) || (layerList === null)){
        return layerListToString
    }
    for ( let i = 0; i < layerList.length; i++){
        if (i > 0){
            layerListToString += ", ";
        }
        layerListToString += layerList[i];
    }
    return layerListToString;
}

function customFieldsToPptString(data: { [fieldId: string]: [string, string]; } | undefined): { text: string; options: { bold: boolean } }[] {
    const paramArray: { text: string; options: { bold: boolean } }[] = [];
    
    if (data) {
        Object.values(data).forEach(([key, value]) => {
            paramArray.push({ text: `${key}: `, options: { bold: true } });
            paramArray.push({ text: `${value}\n`, options: { bold: false } });
        });
    }
    
    return paramArray;
}

function generateParamString(blox: AllBloxes, idMap: Map<string, string>): object[]{
    const paramArray: { text: string; options: { bold: boolean } }[] = [];
    if (blox.bloxType === "STARTBLOX"){
        paramArray.push({text: `Displayed Width: ` , options:{bold: true}});
        paramArray.push({text: `${blox.substrateWidth || "<none>"} ${blox.substrateWidthUnit}\n`, options:{bold: false}});    
        blox.layers?.forEach((layer, index) => {
            paramArray.push({text: `Layer ${index + 1}: ` , options:{bold: true}});
            paramArray.push({text: `${layer.layerLabel}\n`, options:{bold: false}})
            paramArray.push({text: `Thickness: ` , options:{bold: true}});
            const layerThicknessUnit = layer.layerSimulationThicknessUnit || "nm";
            if (index <  (blox.layers?.length || 0)-1){
                paramArray.push({text: `${layer.layerSimulationThickness} ${layerThicknessUnit}\n`, options:{bold: false}})   
            }
            else{
                paramArray.push({text: `${layer.layerSimulationThickness} ${layerThicknessUnit}\n`, options:{bold: false}})   
            }
        });
        
    }    
    else if (blox.bloxType === "SUBSTACK"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})        
    }    
    else if (blox.bloxType === "CUSTOMCLEAN"){
        paramArray.push({text: `Chemical: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanChemical}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTime || "<none>"} ${blox.cleanTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTemp || "<none>"} ${blox.cleanTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Ultra Sonic: ` , options:{bold: true}});
        paramArray.push({text: `${blox.ultraSonication}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "RCACLEAN"){
        paramArray.push({text: `SC1: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanChemicalSC1}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTimeSC1 || "<none>"} ${blox.cleanTimeSC1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTempSC1 || "<none>"} ${blox.cleanTempSC1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `SC2: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanChemicalSC2}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTimeSC2 || "<none>"} ${blox.cleanTimeSC2Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cleanTempSC2 || "<none>"} ${blox.cleanTempSC2Unit}\n`, options:{bold: false}})
        paramArray.push({text: `HF: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dipHF}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dipHFTime || "<none>"} ${blox.dipHFTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dipHFTemp || "<none>"} ${blox.dipHFTempUnit}\n`, options:{bold: false}})        
    }    
    else if (blox.bloxType === "SPINCOATUNIFIED"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        if (blox.spunMaterialType === "Dopant")
        {
            paramArray.push({text: `Dopant: ` , options:{bold: true}});
            paramArray.push({text: `${blox.dopantType}\n`, options:{bold: false}})
        }
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.filmThickness || "<none>"} ${blox.filmThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Volume: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dispenseVolume || "<none>"} ${blox.dispenseVolumeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Speed: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinSpeed || "<none>"} ${blox.spinSpeedUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinTime || "<none>"} ${blox.spinTimeUnit}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "SPINCOAT"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.filmThickness || "<none>"} ${blox.filmThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Volume: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dispenseVolume || "<none>"} ${blox.dispenseVolumeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Speed: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinSpeed || "<none>"} ${blox.spinSpeedUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinTime || "<none>"} ${blox.spinTimeUnit}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "SPINCOATRESIST"){
        paramArray.push({text: "Material: ", options:{bold: true}});
        paramArray.push({text: `${blox.resist}\n`, options:{bold: false}})
        paramArray.push({text: "Thickness: ", options:{bold: true}});
        paramArray.push({text: `${blox.filmThickness || "<none>"} ${blox.filmThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: "Spin Speed: ", options:{bold: true}});
        paramArray.push({text: `${blox.spinSpeed || "<none>" || "<none>"} ${blox.spinSpeedUnit}\n`, options:{bold: false}})
        paramArray.push({text: "Spin Time: ", options:{bold: true}});
        paramArray.push({text: `${blox.spinTime || "<none>"} ${blox.spinTimeUnit}\n`, options:{bold: false}});
    }
    else if (blox.bloxType === "SPINDOPANT"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Dopant: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dopantType}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.filmThickness || "<none>"} ${blox.filmThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Volume: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dispenseVolume || "<none>"} ${blox.dispenseVolumeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Speed: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinSpeed || "<none>"} ${blox.spinSpeedUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.spinTime || "<none>"} ${blox.spinTimeUnit}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "BAKE"){
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.bakeTemp || "<none>"} ${blox.bakeTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.bakeTime || "<none>"} ${blox.bakeTimeUnit}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "MASKALIGN"){
        paramArray.push({text: `Wavelength: ` , options:{bold: true}});
        paramArray.push({text: `${blox.wavelength}\n`, options:{bold: false}})
        paramArray.push({text: `Intensity: ` , options:{bold: true}});
        paramArray.push({text: `${blox.intensity || "<none>"} ${blox.intensityUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expTime || "<none>"} ${blox.expTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expDose || "<none>"} ${blox.expDoseUnit}\n`, options:{bold: false}})   
    }    
    else if (blox.bloxType === "STEPPERLITHO"){
        paramArray.push({text: `Wavelength: ` , options:{bold: true}});
        paramArray.push({text: `${blox.wavelength}\n`, options:{bold: false}})
        paramArray.push({text: `Intensity: ` , options:{bold: true}});
        paramArray.push({text: `${blox.intensity || "<none>"} ${blox.intensityUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expTime || "<none>"} ${blox.expTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expDose || "<none>"} ${blox.expDoseUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Job Name: ` , options:{bold: true}});
        paramArray.push({text: `${blox.jobName}\n`, options:{bold: false}})
        paramArray.push({text: `Layer ID: ` , options:{bold: true}});
        paramArray.push({text: `${blox.layerID}\n`, options:{bold: false}})
        paramArray.push({text: `Reticle ID: ` , options:{bold: true}});
        paramArray.push({text: `${blox.reticleID}\n`, options:{bold: false}})
        paramArray.push({text: `Focus Offset: ` , options:{bold: true}});
        paramArray.push({text: `${blox.focusOffset}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "DIRECTWRITELITHO"){
        paramArray.push({text: `Laser Wavelength: ` , options:{bold: true}});
        paramArray.push({text: `${blox.laserWavelength} nm\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expDose || "<none>"} ${blox.expDoseUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Invert: ` , options:{bold: true}});
        paramArray.push({text: `${blox.layerInvertPattern}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "INTERFERENCE-LITHO"){
        paramArray.push({text: `Wavelength: ` , options:{bold: true}});
        paramArray.push({text: `${blox.lightWavelength}\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expDose || "<none>"} ${blox.expDoseUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Intensity: ` , options:{bold: true}});
        paramArray.push({text: `${blox.intensity || "<none>"} ${blox.intensityUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.expTime || "<none>"} ${blox.expTimeUnit}\n`, options:{bold: false}})   
    }    
    else if (blox.bloxType === "EBL"){
        paramArray.push({text: `Voltage: ` , options:{bold: true}});
        paramArray.push({text: `${blox.accelVoltage || "<none>"} ${blox.accelVoltageUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Current: ` , options:{bold: true}});
        paramArray.push({text: `${blox.beamCurrent || "<none>"} ${blox.beamCurrentUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.exposureDose || "<none>"} ${blox.exposureDoseUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Dwell Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dwellTime || "<none>"} ${blox.dwellTimeUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "IMPRINTLITHO"){
        paramArray.push({text: `Layer to Imprint: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToImprint) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.imprintDepth || "<none>"} ${blox.imprintDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Sidewall Angle: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sidewallAngle}${blox.sidewallAngleUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Invert: ` , options:{bold: true}});
        paramArray.push({text: `${blox.layerInvertPattern}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "DEVELOP"){
        paramArray.push({text: `Developer: ` , options:{bold: true}});
        paramArray.push({text: `${blox.developer || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.developTime || "<none>"} ${blox.developTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.developTemp || "<none>"} ${blox.developTempUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "ICPRIE"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        // paramArray.push({text: ` ${idMap.get(blox.layerLabelsToEtch[0] || "") || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToEtch) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Gases: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gas || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Gas Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gasFlow || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Etch Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchRate || "<none>"} ${blox.etchRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `RF Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfPower || "<none>"} ${blox.rfPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Bias Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.biasPower || "<none>"} ${blox.biasPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pressure || "<none>"} ${blox.pressureUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "RIE"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToEtch) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Gasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gas}\n`, options:{bold: false}})
        paramArray.push({text: `Gas Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gasFlow}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchRate || "<none>"} ${blox.etchRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `RF Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfPower || "<none>"} ${blox.rfPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pressure || "<none>"} ${blox.pressureUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "IONMILL"){
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Gasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gas}\n`, options:{bold: false}})
        paramArray.push({text: `Gas Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gasFlow}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchRate || "<none>"} ${blox.etchRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Angle: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchAngle}${blox.etchAngleUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pressure || "<none>"} ${blox.pressureUnit}\n`, options:{bold: false}})
    }  
    else if (blox.bloxType === "CMP"){
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Slurry: ` , options:{bold: true}});
        paramArray.push({text: `${blox.slurry}\n`, options:{bold: false}})
        paramArray.push({text: `Pad: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pad}\n`, options:{bold: false}})
        paramArray.push({text: `Slurry Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.slurryFlow || "<none>"} ${blox.slurryFlowUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pressure || "<none>"} ${blox.pressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.polishTime || "<none>"} ${blox.polishTimeUnit}\n`, options:{bold: false}})
    }    
    else if (blox.bloxType === "PLASMA-ASH"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToEtch) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Gasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gas}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchRate || "<none>"} ${blox.etchRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `RF Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfPower || "<none>"} ${blox.rfPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pressure || "<none>"} ${blox.pressureUnit}\n`, options:{bold: false}})

    }    
    else if (blox.bloxType === "PLASMA-DESCUM"){
        paramArray.push({text: `Gases: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gas}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `RF Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfPower || "<none>"} ${blox.rfPowerUnit}\n`, options:{bold: false}})
        
    } 
    else if (blox.bloxType === "HMDSVAPOR"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "SPUTTER"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterMaterial || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterThickness || "<none>"} ${blox.sputterThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterRate || "<none>"} ${blox.sputterRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterTime || "<none>"} ${blox.sputterTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Type: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterType || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfDcSputterPower || "<none>"} ${blox.rfDcSputterPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Gasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sputterGasses}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "THERMALEVAP"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.appliedPower || "<none>"} ${blox.appliedPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Angle: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depAngle}${blox.depAngleUnit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "EBEAMEVAP"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Beam Current: ` , options:{bold: true}});
        paramArray.push({text: `${blox.beamCurrent || "<none>"} ${blox.beamCurrentUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Beam Voltage: ` , options:{bold: true}});
        paramArray.push({text: `${blox.beamVoltage || "<none>"} ${blox.beamVoltageUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Angle: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depAngle}${blox.depAngleUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "MBE"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Growth Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.growthRate || "<none>"} ${blox.growthRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.growthTime || "<none>"} ${blox.growthTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Cell Fluxes: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cellFluxes}\n`, options:{bold: false}})
        paramArray.push({text: `Cell Temps: ` , options:{bold: true}});
        paramArray.push({text: `${blox.cellTemps}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.subTemp || "<none>"} ${blox.subTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTime || "<none>"} ${blox.annealTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTemp || "<none>"} ${blox.annealTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Gasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealGasses}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "MOCVD"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Growth Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.growthRate || "<none>"} ${blox.growthRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.growthTime || "<none>"} ${blox.growthTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.subTemp || "<none>"} ${blox.subTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursors: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursors}\n`, options:{bold: false}})
        paramArray.push({text: `Precursor Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursorFlow}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTime || "<none>"} ${blox.annealTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTemp || "<none>"} ${blox.annealTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `AnnealGasses: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealGasses}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "ALD"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Number of Cycles: ` , options:{bold: true}});
        paramArray.push({text: `${blox.numCycles}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.substrateTemp || "<none>"} ${blox.substrateTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Base Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursor 1: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursor1}\n`, options:{bold: false}})
        paramArray.push({text: `Pulse Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pulseTimePre1 || "<none>"} ${blox.pulseTimePre1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Purge Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.waitTimePre1 || "<none>"} ${blox.waitTimePre1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursor 2: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursor2}\n`, options:{bold: false}})
        paramArray.push({text: `Pulse Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.pulseTimePre2 || "<none>"} ${blox.pulseTimePre2Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Purge Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.waitTimePre2 || "<none>"} ${blox.waitTimePre2Unit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "ELECTROPLATE"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depTime || "<none>"} ${blox.depTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Voltage: ` , options:{bold: true}});
        paramArray.push({text: `${blox.voltage || "<none>"} ${blox.voltageUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Selective Growth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.selectiveGrowth}\n`, options:{bold: false}})
        if (blox.selectiveGrowth)
        {
            paramArray.push({text: `Layers: ` , options:{bold: true}});
            paramArray.push({text: ` ${layerListToString(blox.layerLabelsToGrow) || "<none>"} \n`, options:{bold: false}})
        }
        paramArray.push({text: `Double Sided: ` , options:{bold: true}});
        paramArray.push({text: `${blox.doubleSided}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "PECVD"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.subTemp || "<none>"} ${blox.subTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Showerhead Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.showerTemp || "<none>"} ${blox.showerTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursors: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursors || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Flow Rates: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursorFlow || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `RF Power: ` , options:{bold: true}});
        paramArray.push({text: `${blox.rfPower || "<none>"} ${blox.rfPowerUnit}\n`, options:{bold: false}})
        paramArray.push({text: `DC Bias: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dcBias || "<none>"} ${blox.dcBiasUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "LPCVD"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.subTemp || "<none>"} ${blox.subTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Shower Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.showerTemp || "<none>"} ${blox.showerTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursors: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursors}\n`, options:{bold: false}})
        paramArray.push({text: `Precursor Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursorFlow}\n`, options:{bold: false}})
        paramArray.push({text: `Pressure: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chamberPressure || "<none>"} ${blox.chamberPressureUnit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "APCVD"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depRate || "<none>"} ${blox.depRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Substrate Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.subTemp || "<none>"} ${blox.subTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Shower Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.showerTemp || "<none>"} ${blox.showerTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Precursors: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursors}\n`, options:{bold: false}})
        paramArray.push({text: `Precursor Flow: ` , options:{bold: true}});
        paramArray.push({text: `${blox.precursorFlow}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "DROPCAST"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.filmThickness || "<none>"} ${blox.filmThicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Solution: ` , options:{bold: true}});
        paramArray.push({text: `${blox.solution}\n`, options:{bold: false}})
        paramArray.push({text: `Volume: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dispenseVolume || "<none>"} ${blox.dispenseVolumeUnit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "WETGROWTH"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Solvents: ` , options:{bold: true}});
        paramArray.push({text: `${blox.solvents}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Deposition Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depositTime || "<none>"} ${blox.depositTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depositTemp || "<none>"} ${blox.depositTempUnit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "THERMALOXIDATION"){
        paramArray.push({text: `Layer to Oxidize: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToOxidize) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Oxide: ` , options:{bold: true}});
        paramArray.push({text: `${blox.oxide || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Wet?: ` , options:{bold: true}});
        paramArray.push({text: `${blox.wetOxidation || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.thermalOxTemp || "<none>"} ${blox.thermalOxTempUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Gas Flows: ` , options:{bold: true}});
        paramArray.push({text: `${blox.gasFlows || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Oxide Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.oxideThickness || "<none>"} ${blox.oxideThicknessUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "WETETCH"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToEtch) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Etchant: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchChemical || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchDepth || "<none>"} ${blox.etchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Sideways Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.sidewaysEtchDepth || "<none>"} ${blox.sidewaysEtchDepthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Rate: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchRate || "<none>"} ${blox.etchRateUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Etch Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTime || "<none>"} ${blox.etchTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.etchTemp || "<none>"} ${blox.etchTempUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "LIFTOFF"){
        paramArray.push({text: `Solvent: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chemical}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.time || "<none>"} ${blox.timeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.temp || "<none>"} ${blox.tempUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "STRIPRESIST"){
        paramArray.push({text: `Solvent: ` , options:{bold: true}});
        paramArray.push({text: `${blox.chemical || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.time || "<none>"} ${blox.timeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.temp || "<none>"} ${blox.tempUnit}\n`, options:{bold: false}})
    }
    // No parameters
    // else if (blox.bloxType === "FLIPSTACK"){
    //     continue;  
    // } 
    else if (blox.bloxType === "IONIMPLANT"){
        paramArray.push({text: `Implant Ion: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Implant Targe: ` , options:{bold: true}});
        paramArray.push({text: ` ${layerListToString(blox.layerLabelsToImplant) || "<none>"} \n`, options:{bold: false}})
        paramArray.push({text: `Depth: ` , options:{bold: true}});
        paramArray.push({text: `${blox.depth || "<none>"} ${blox.depthUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Energy: ` , options:{bold: true}});
        paramArray.push({text: `${blox.implantEnergy || "<none>"} ${blox.implantEnergyUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Dose: ` , options:{bold: true}});
        paramArray.push({text: `${blox.dose || "<none>"} ${blox.doseUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Angle: ` , options:{bold: true}});
        paramArray.push({text: `${blox.angle}${blox.angleUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temperature: ` , options:{bold: true}});
        paramArray.push({text: `${blox.temperature || "<none>"} ${blox.temperatureUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "DOPEDIFFUSION"){
        paramArray.push({text: `Distance: ` , options:{bold: true}});
        paramArray.push({text: `${blox.diffusionDistance || "<none>"} ${blox.diffusionDistanceUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.diffusionTime || "<none>"} ${blox.diffusionTimeUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Temp: ` , options:{bold: true}});
        paramArray.push({text: `${blox.temperature || "<none>"} ${blox.temperatureUnit}\n`, options:{bold: false}})
    }
    else if (blox.bloxType === "RTA"){
        paramArray.push({text: `Anneal Temp 1: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTemp1 || "<none>"} ${blox.annealTemp1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTime1 || "<none>"} ${blox.annealTime1Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Temp 2: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTemp2 || "<none>"} ${blox.annealTemp2Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTime2 || "<none>"} ${blox.annealTime2Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Anneal Temp 3: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTemp3 || "<none>"} ${blox.annealTemp3Unit}\n`, options:{bold: false}})
        paramArray.push({text: `Time: ` , options:{bold: true}});
        paramArray.push({text: `${blox.annealTime3 || "<none>"} ${blox.annealTime3Unit}\n`, options:{bold: false}})
    } 
    else if (blox.bloxType === "WAFERBONDING"){
        paramArray.push({text: `Material: ` , options:{bold: true}});
        paramArray.push({text: `${blox.material}\n`, options:{bold: false}})
        paramArray.push({text: `Thickness (not to scale): ` , options:{bold: true}});
        paramArray.push({text: `${blox.thickness || "<none>"} ${blox.thicknessUnit}\n`, options:{bold: false}})
        paramArray.push({text: `Drawn Thickness: ` , options:{bold: true}});
        paramArray.push({text: `${blox.drawnThickness || "<none>"} ${blox.drawnThicknessUnit}\n`, options:{bold: false}})
    } 
    // else if (blox.bloxType === "CUSTOMBLOX"){
    //     continue;
    // } 
    // Check if paramArray has at least one element

    // Get Custom Fields
    const customFieldArray = customFieldsToPptString(blox.customFields);
    paramArray.push(...customFieldArray);
    // Strip trailing \n to avoid extra bullet point
    if (paramArray.length > 0) {
        const lastElement = paramArray[paramArray.length - 1];
        // Modify the text property to strip "\n" if it exists at the end
        if (lastElement.text.endsWith("\n")) {
            lastElement.text = lastElement.text.slice(0, -1);
        }
    }

    return paramArray
}

// I think there is a better way to get the process name without passing it to this function. Seems to work for now - JP
export function makeSemifabPpt(
    svgsResult:  {[key: string]: string}, 
    processBloxes: AllBloxes[], 
    legend:string, 
    processName:string, 
    processSections:Section[], 
    processId:string, 
    username:string,
    updateUserMutation: UseMutationResult<void, any, {
        userId: string;
        data: UpdateUserRequest;
    }, unknown>, 
    fabuUser?: User) {

    const pres = new pptxgen();
    function svgToDataURL(svg: string) {
        return 'data:image/svg+xml;base64,' + btoa(svg);
    }

    function svgCalcHeight(svg: string, width: number) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        const originalWidth = parseFloat(svgElement.getAttribute('width') || '100');
        const originalHeight = parseFloat(svgElement.getAttribute('height') || '100');
        // Calculate the height to maintain the aspect ratio
        const aspectRatio = originalWidth / originalHeight;
        const calculatedHeight = width / aspectRatio;
        return calculatedHeight;
    }

    function svgCalcWidth(svg: string, height: number) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        const originalWidth = parseFloat(svgElement.getAttribute('width') || '100');
        const originalHeight = parseFloat(svgElement.getAttribute('height') || '100');
        // Calculate the height to maintain the aspect ratio
        const aspectRatio = originalWidth / originalHeight;
        const calculatedWidth = aspectRatio * height;
        return calculatedWidth;
    }

    function gatherLabels(processBloxes: AllBloxes[]): Map<string, string> {
        const idMap = new Map();
        for (let i = 0; i < processBloxes.length; i++) {
            const blox = processBloxes[i];
                switch (blox.bloxType){
                    case 'SPINCOAT':
                    case "SPINDOPANT": 
                    case "HMDSVAPOR": 
                    case "THERMALEVAP": 
                    case "EBEAMEVAP": 
                    case "MBE": 
                    case "MOCVD": 
                    case "ALD": 
                    case "PECVD": 
                    case "LPCVD": 
                    case "APCVD": 
                    case "DROPCAST": 
                    case "WETGROWTH": 
                    case "IONIMPLANT": 
                    case "WAFERBONDING": 
                    idMap.set(blox.id, blox.material);
                    break;
                    case "SPINCOATRESIST":  
                    idMap.set(blox.id, blox.resist);
                    break;
                    case "SPUTTER": 
                    idMap.set(blox.materialId, blox.sputterMaterial);
                    break;
                    case "THERMALOXIDATION": 
                    idMap.set(blox.id, blox.oxide);
                    break;
                }
            }
            return idMap;
        }    
        
        function bloxToSectionMap(sections: Section[]): Map<string, string>{
            const sectionMap = new Map();
            for (let i = 0; i < sections.length; i++){
                const section = sections[i];
                for (let j = 0; j < section.bloxIds.length; j++){
                    sectionMap.set(section.bloxIds[j], section.sectionName)
                }
            }
            return sectionMap
        }
        
    const idMap = gatherLabels(processBloxes);
    const sectionMap = bloxToSectionMap(processSections);
    let prevSection = "empty section";
    const section = "Title"
    // Title Slide
    pres.addSection({ title: section });
    const slide = pres.addSlide({sectionTitle: section});
    // Website
    slide.addText(`www.fabublox.com/process-editor/${processId}`, { x: 0, y: 5.28, w:10, h:0.35, valign:"bottom", align:"center", color: "666666", fontSize:10, fontFace:"Montserrat"});
    // Title
    slide.addText(`${processName}`, { x: 1, y: 1.5, w:8, h:1.5, valign:"middle", align:"center", color: "222222", fontSize:36, fontFace:"Montserrat"}); 
    // User Name
    slide.addText(`Designed by ${username} at`, { x: 0, y:3, w:10, h:0.5, valign:"bottom", align:"center", color: "666666", fontSize:16, fontFace:"Montserrat"}); 

    slide.addImage({
        path: FabuLogo,
        x: 3,
        y: 3.5,
        w: 4,
        h: 1
    });

    Object.entries(svgsResult).forEach(([name, contents], index) => {
        const blox = processBloxes[index];
        const bloxName = blox.name;
        const stepNumber = index;
        const heading = `Step ${stepNumber}: ${bloxName}`;
        const section = sectionMap.get(blox.id || "");
        

        if ((section !== prevSection) && (section) && (blox.bloxType !== "STARTBLOX")){
            pres.addSection({ title: section });
            const slide = pres.addSlide({sectionTitle: section});
            slide.addText([
                {text: ` ${section}`, options:{bold: true}}]
                , { x: 1, y: 1.8125, w:8, h:2, valign:"middle", align:"center", color: "000000", fontSize:36, fontFace:"Montserrat"});
        }
        if (blox.bloxType !== "STARTBLOX"){
            prevSection = section || "";
        }

        const slide = pres.addSlide({sectionTitle: section});
        // Title
        slide.addText(heading, { x: 0.5, y: 0.25, color: "363636", fontSize:24, fontFace:"Montserrat", w:7, h:0.5, valign:'top', margin:0.5});
        // Tool Name
        if (blox.bloxType !== "STARTBLOX"){
            slide.addText(`${blox.toolName === undefined ? "Tool not provided" : blox.toolName }`,{ x: 0.75, y: 0.75, w:5, h:0.5, valign:"top", color: "000000", fontSize:16, fontFace:"Montserrat"})
        }
        // Parameters
        const paramString = generateParamString(blox, idMap);
        slide.addText(paramString || "",{ x: 0.5, y: 1.25, w:2.75, h:3.5, valign:'top', color: "000000", fontSize:11, fontFace:"Montserrat", paraSpaceAfter:6, bullet:{indent: 10}})
        // Comments
        slide.addText([
            {text: `Comments: ` , options:{bold: true}},
            {text: ` ${blox.commentField || ""}`, options:{bold: false}}]
            , { x: 0.5, y: 4.3, w:2.75, h:1.2, valign:"top", align:"left", color: "000000", fontSize:10, fontFace:"Montserrat"});
        // Blox Type
        slide.addText(blox.bloxType, { x: 0, y: 5.38, w:3, h:0.25, valign:"bottom", align:"left", color: "000000", fontSize:10, fontFace:"Montserrat"});
        // Website
        slide.addText("www.fabublox.com", { x: 8, y: 5.38, w:2, h:0.25, valign:"top", align:"right", color:"333333", fontSize:10, fontFace:"Montserrat"});
        // Module ID if it exists
        if (blox.moduleName){
            slide.addText([
                {text: `Module: ` , options:{bold: true}},
                {text: ` ${blox.moduleName}`, options:{bold: false}}]
            , { x: 4, y: 5.15, w:2, h:0.25, valign:"bottom", align:"right", color: "666666", fontSize:10, fontFace:"Montserrat"});
        }
        // Section
        if (section){          
            slide.addText([
                {text: `Section: ` , options:{bold: false}},
                {text: ` ${section}`, options:{bold: true}}]
            , { x: 2, y: 5.28, w:6, h:0.35, valign:"bottom", align:"center", color: "666666", fontSize:14, fontFace:"Montserrat"});
        }


        const dataUrl = svgToDataURL(contents);
        let imageWidth = 5;
        let imageHeight = svgCalcHeight(contents, imageWidth);
        if (imageHeight > 4.25){
            imageHeight = 4.25;
            imageWidth = svgCalcWidth(contents, imageHeight);
        }
        slide.addImage({
            data: dataUrl,
            x: 3.3 + (5 - imageWidth)/2,
            y: 5-imageHeight,
            w: imageWidth,
            h: imageHeight
        });        
        // Legend
        const legendUrl = svgToDataURL(legend);
        let legendWidth = 1.5;
        let legendHeight = svgCalcHeight(legend, 1.5);
        if (legendHeight > 4){
            legendHeight = 4;
            legendWidth = svgCalcWidth(legend, legendHeight);
        }
        slide.addImage({
            data: legendUrl,
            x: 8.5,
            y: 5-legendHeight,
            w: legendWidth,
            h: legendHeight
        });        
        // Logo
        slide.addImage({
            path: FabuLogo,
            x: 7.9,
            y: 0.1,
            w: 2,
            h: 0.5
        });        

    });

    // Trigger a download of the PowerPoint file
    const processSaveName = processName.replaceAll(' ', "_");
    pres.writeFile({ fileName: `${processSaveName}.pptx` })
        .then(() => {
            showToast({
                message: "File has been downloaded.",
                intent: Intent.SUCCESS,
                timeout: 3000
              });
              if (fabuUser === undefined) return;
              updateUserMutation.mutate({ 
                  userId: fabuUser.userId, 
                  data: { metrics: {
                      ...fabuUser.metrics,
                      pptDownloads: (fabuUser.metrics?.pptDownloads ?? 0) + 1
                  }} 
              });
        })
        .catch(() => {
            showToast({
                message: "Download Failed",
                intent: Intent.WARNING,
                timeout: 3000
              });
        });
}