/**
 * @fileoverview Custom hook for managing process table state and scroll behavior.
 * This hook centralizes all state management related to table scrolling, pagination,
 * and section tracking. It handles complex interactions between scroll position,
 * selected items, and section navigation.
 * 
 * Key Features:
 * - Pagination state management
 * - Section tracking
 * - Scroll position management
 * - Initial scroll handling
 * - View switch tracking
 */

import { useState, useRef, useCallback, useEffect } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { Section } from '../../../__generated__/Process';
import { ProcessTableRow } from '../types/ProcessTableTypes';
import { AllBloxes } from '../../../Data/BloxSchema/base-blox';
import {
  scrollToSection as scrollToSectionUtil,
  handlePendingScroll,
  scrollToSelectedBlox,
  useTableScroll,
} from '../utils/processTableViewScrollUtils';

/**
 * Props interface for the useProcessTableState hook
 * @interface UseProcessTableStateProps
 * 
 * @property {AllBloxes[]} processBloxes - Array of process bloxes
 * @property {Section[]} processSections - Array of process sections
 * @property {string | null} selectedBloxId - ID of the currently selected blox
 * @property {any} apiRef - Reference to the grid API
 * @property {ProcessTableRow[]} allRows - Array of all table rows
 * @property {boolean} isTableView - Flag to track if this is the table view
 */
interface UseProcessTableStateProps {
  processBloxes: AllBloxes[];
  processSections: Section[];
  selectedBloxId: string | null;
  apiRef: any;
  allRows: ProcessTableRow[];
  isTableView?: boolean;
}

/**
 * Custom hook for managing process table state
 * 
 * Manages all state related to table scrolling, pagination, and section tracking.
 * Provides utilities for scrolling to sections and handling pagination changes.
 * 
 * @param {UseProcessTableStateProps} props - Hook configuration
 * @returns {Object} Object containing state and utility functions
 */
export const useProcessTableState = ({
  processBloxes,
  processSections,
  selectedBloxId,
  apiRef,
  allRows,
  isTableView = true,
}: UseProcessTableStateProps) => {
  // Current section tracking
  const [currentSection, setCurrentSection] = useState<string>('');
  
  // Pagination state
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25
  });

  // Refs for tracking scroll and view state
  const isViewSwitchRef = useRef(true);
  const pendingScrollRef = useRef<string | null>(null);
  const hasScrolledRef = useRef(false);

  // Use the custom hook for scroll handling
  useTableScroll(allRows, currentSection, setCurrentSection);

  /**
   * Scrolls the table to a specific section
   * @param {string} sectionId - ID of the section to scroll to
   */
  const scrollToSection = useCallback(
    (sectionId: string) => {
      scrollToSectionUtil({
        sectionId,
        allRows,
        paginationModel,
        setPaginationModel,
        apiRef,
        setCurrentSection,
        pendingScrollRef,
        isViewSwitchRef
      });
    },
    [allRows, paginationModel, apiRef]
  );

  // Reset scroll state when view mode changes
  useEffect(() => {
    if (isTableView) {
      hasScrolledRef.current = false;
      isViewSwitchRef.current = true;
      // Reset pagination to ensure we're starting from the first page
      setPaginationModel({
        page: 0,
        pageSize: 25
      });
    }
  }, [isTableView]);

  // Effect for initial scroll to the selected blox
  useEffect(() => {
    if (
      !hasScrolledRef.current && 
      selectedBloxId && 
      apiRef.current && 
      allRows.length > 0 && 
      processSections.length > 0
    ) {
      requestAnimationFrame(() => {
        scrollToSelectedBlox(
          selectedBloxId,
          apiRef,
          isViewSwitchRef,
          allRows,
          processSections,
          setCurrentSection,
          scrollToSectionUtil,
          paginationModel,
          setPaginationModel,
          pendingScrollRef
        );
        hasScrolledRef.current = true;
      });
    }
  }, [selectedBloxId, apiRef.current, allRows, processSections, paginationModel, scrollToSectionUtil]);

  // Reset refs when unmounting
  useEffect(() => {
    return () => {
      isViewSwitchRef.current = true;
      hasScrolledRef.current = false;
    };
  }, []);

  // Handle pending scroll operations after pagination changes
  useEffect(() => {
    handlePendingScroll(pendingScrollRef, apiRef, allRows);
  }, [paginationModel.page, apiRef, allRows]);

  return {
    currentSection,
    setCurrentSection,
    paginationModel,
    setPaginationModel,
    scrollToSection,
    isViewSwitchRef,
    pendingScrollRef,
  };
}; 