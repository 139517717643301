/**
 * PowerPoint Export Utility
 * 
 * This module handles the generation of PowerPoint presentations from process data.
 * It includes functionality for creating slides with process steps, sections,
 * and visual representations of the manufacturing process.
 */

import { Intent } from "@blueprintjs/core";
import pptxgen from "pptxgenjs";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { showToast } from "..";
import { UpdateUserRequest } from "../__generated__/User";
import { UseMutationResult } from "react-query";
import FabuLogo from '../components/svg/logo_ppt.svg';
import { displayMap } from "../Data/display-mappings";
import { Tab } from "../Data/enums";

export interface Section {
    sectionId: string;
    sectionName?: string;
    bloxIds: string[];
}

/**
 * Minimal user type for export functionality
 */
interface ExportUser {
    userId: string;
    metrics: UpdateUserRequest['metrics'];
}

/**
 * Material information for the legend
 */
interface LegendMaterial {
    color: string;
    label: string;
}

/**
 * Common PowerPoint style definitions used throughout the presentation
 * Centralizes all styling to maintain consistency across slides
 */
const PPT_STYLES = {
    fonts: {
        primary: "Montserrat",
    },
    colors: {
        primary: "222222",
        secondary: "666666",
        dark: "363636",
        black: "000000",
        darkGrey: "333333",
    },
    text: {
        title: {
            fontSize: 36,
            color: "222222",
            fontFace: "Montserrat",
        },
        heading: {
            fontSize: 24,
            color: "363636",
            fontFace: "Montserrat",
        },
        subheading: {
            fontSize: 16,
            color: "000000",
            fontFace: "Montserrat",
        },
        body: {
            fontSize: 11,
            color: "000000",
            fontFace: "Montserrat",
        },
        footer: {
            fontSize: 10,
            color: "666666",
            fontFace: "Montserrat",
        },
    },
    layout: {
        mainSvg: {
            maxSize: 4.25,
        },
        legend: {
            maxSize: 1.5,
        },
        logo: {
            width: 2,
            height: 0.5,
        },
    },
};

/**
 * Legend layout configuration
 */
const LEGEND_LAYOUT = {
    itemHeight: 0.25,      // Height for each legend item
    verticalPadding: 0.05, // Padding between items
    xPosition: 8.3,        // Fixed horizontal position
    yMiddle: 2.75,         // Middle of the content area (excluding header/footer)
    labelOffset: 0.3,      // Horizontal offset for label from color box
    boxSize: 0.2,          // Size of the color box
    labelWidth: 1.45,      // Width of the label text
    fontSize: 9,           // Font size for legend labels
} as const;

/**
 * Adds kerning to text by inserting zero-width spaces between characters
 * @param text - The text to add kerning to
 * @returns The text with kerning applied
 */
function addKerning(text: string): string {
    return text.split('').join('\u200B') + '\u200B';  // Use ZERO WIDTH SPACE for minimal spacing
}

/**
 * Converts a layer list array to a comma-separated string
 * @param layerList - Array of layer names
 * @returns Comma-separated string of layer names
 */
function layerListToString(layerList?: string[] | null | undefined): string {
    if (!layerList || layerList.length === 0) return "";
    return layerList.join(", ");
}

/**
 * Interface for parameter text items in the presentation
 */
interface ParamArrayItem {
    text: string;
    options: { bold: boolean };
}

/**
 * Formats a value with its optional unit
 * @param value - The value to format
 * @param unit - Optional unit to append
 * @returns Formatted string with value and unit
 */
function formatValue(value: any, unit?: string): string {
    if (value === undefined || value === null) return "<none>";
    const baseValue = String(value);
    return unit ? `${baseValue} ${unit}` : baseValue;
}

/**
 * Converts SVG to a data URL with error handling
 * @param svg - SVG string to convert
 * @returns Data URL representation of the SVG
 */
function svgToDataURL(svg: string): string {
    try {
        // Clean and validate SVG
        const cleanSvg = svg.trim()
            .replace(/>\s+</g, '><') // Remove whitespace between tags
            .replace(/\s{2,}/g, ' ') // Replace multiple spaces with single space
            .replace(/<text[^>]*>.*?<\/text>/g, '') // Remove text elements with content
            .replace(/<text[^>]*\/>/g, '') // Remove self-closing text elements
            .replace(/<tspan[^>]*>.*?<\/tspan>/g, '') // Remove tspan elements
            .replace(/<textPath[^>]*>.*?<\/textPath>/g, '') // Remove textPath elements
            .replace(/<foreignObject[^>]*>.*?<\/foreignObject>/g, ''); // Remove foreignObject elements

        // Ensure SVG has proper XML declaration and namespace
        if (!cleanSvg.includes('<?xml')) {
            const xmlDeclaration = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>';
            if (!cleanSvg.includes('<svg')) {
                throw new Error('Invalid SVG: Missing svg tag');
            }
            const svgWithNamespace = cleanSvg.replace(
                '<svg',
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"'
            );
            return 'data:image/svg+xml;base64,' + btoa(xmlDeclaration + svgWithNamespace);
        }

        return 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(cleanSvg)));
    } catch (error) {
        // console.error('Error converting SVG to data URL:', error);
        // Return a simple transparent SVG as fallback
        const fallbackSvg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
            <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                <rect width="100%" height="100%" fill="none"/>
            </svg>`;
        return 'data:image/svg+xml;base64,' + btoa(fallbackSvg);
    }
}

/**
 * Helper function to ensure square dimensions for SVG
 */
function getSquareDimensions(svg: string, maxSize = 4.25): { width: number; height: number } {
    try {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
        
        if (svgDoc.querySelector('parsererror')) {
            return { width: maxSize, height: maxSize };
        }

        const svgElement = svgDoc.documentElement;
        const viewBox = svgElement.getAttribute('viewBox');
        let aspectRatio = 1;

        if (viewBox) {
            const [, , vbWidth, vbHeight] = viewBox.split(' ').map(Number);
            if (!isNaN(vbWidth) && !isNaN(vbHeight) && vbWidth > 0 && vbHeight > 0) {
                aspectRatio = vbWidth / vbHeight;
            }
        }

        // Always return square dimensions
        return { width: maxSize, height: maxSize };
    } catch (error) {
        console.warn('Error calculating SVG dimensions:', error);
        return { width: maxSize, height: maxSize };
    }
}

/**
 * Creates a mapping of blox IDs to their section names
 */
function bloxToSectionMap(sections: Section[]): Map<string, string> {
    const sectionMap = new Map();
    for (const section of sections) {
        for (const bloxId of section.bloxIds) {
            sectionMap.set(bloxId, section.sectionName);
        }
    }
    return sectionMap;
}

/**
 * Generates parameter string array for a blox
 */
function generateParamString(blox: AllBloxes, idMap: Map<string, string>): ParamArrayItem[] {
    const paramArray: ParamArrayItem[] = [];

    // Handle STARTBLOX
    if (blox.bloxType === "STARTBLOX") {
        if (blox.substrateWidth) {
            paramArray.push({ text: addKerning("Displayed Width: "), options: { bold: true } });
            paramArray.push({ text: `${blox.substrateWidth} ${blox.substrateWidthUnit}\n`, options: { bold: false } });
        }
        
        if (blox.layers) {
            blox.layers.forEach((layer, index) => {
                paramArray.push({ text: addKerning(`Layer ${index + 1}: `), options: { bold: true } });
                paramArray.push({ text: `${layer.layerLabel}\n`, options: { bold: false } });
                paramArray.push({ text: addKerning("Thickness: "), options: { bold: true } });
                const layerThicknessUnit = layer.layerSimulationThicknessUnit || "nm";
                paramArray.push({ text: `${layer.layerSimulationThickness} ${layerThicknessUnit}\n`, options: { bold: false } });
            });
        }
        return paramArray;
    }

    // Get display map for this blox type
    const bloxDisplayMap = displayMap[blox.bloxType];
    if (!bloxDisplayMap) {
        return paramArray;
    }

    // Extract experimental fields with proper formatting
    const experimentalFields = Object.entries(bloxDisplayMap)
        .filter(([_, params]) => params.tabs.includes(Tab.EXPERIMENTAL))
        .sort((a, b) => a[1].order - b[1].order);

    experimentalFields.forEach(([field, params]) => {
        const value = (blox as any)[field];
        if (value !== undefined && value !== null) {
            const unitField = `${field}Unit`;
            const unit = (blox as any)[unitField];
            const fieldLabel = params.label || field;
            
            paramArray.push({ text: addKerning(`${fieldLabel}: `), options: { bold: true } });
            
            if (unit !== undefined && unit !== null) {
                paramArray.push({ text: `${value} ${unit}\n`, options: { bold: false } });
            } else {
                paramArray.push({ text: `${value}\n`, options: { bold: false } });
            }
        }
    });

    // Handle selective growth
    if ('selectiveGrowth' in blox && blox.selectiveGrowth) {
        paramArray.push({ text: addKerning("Selective Growth: "), options: { bold: true } });
        paramArray.push({ text: "Yes\n", options: { bold: false } });
        
        if (blox.layerLabelsToGrow) {
            paramArray.push({ text: addKerning("Growth Layers: "), options: { bold: true } });
            paramArray.push({ text: layerListToString(blox.layerLabelsToGrow) + "\n", options: { bold: false } });
        }
    }

    // Handle double sided deposition
    if ('doubleSided' in blox && blox.doubleSided) {
        paramArray.push({ text: addKerning("Double Sided: "), options: { bold: true } });
        paramArray.push({ text: "Yes\n", options: { bold: false } });
    }

    // Handle layer labels for various operations
    const layerLabelFields = [
        { field: 'layerLabelsToEtch', label: 'Layers to Etch' },
        { field: 'layerLabelsToImplant', label: 'Layers to Implant' },
        { field: 'layerLabelsToOxidize', label: 'Layers to Oxidize' },
        { field: 'layerLabelsToGrow', label: 'Layers to Grow' },
        { field: 'layerLabelsToImprint', label: 'Layers to Imprint' },
        { field: 'layerLabelsToExpose', label: 'Layers to Expose' }
    ];

    layerLabelFields.forEach(({ field, label }) => {
        if (field in blox && (blox as any)[field]) {
            paramArray.push({ text: addKerning(`${label}: `), options: { bold: true } });
            paramArray.push({ text: layerListToString((blox as any)[field]) + "\n", options: { bold: false } });
        }
    });

    // Add custom fields if they exist
    if (blox.customFields) {
        Object.entries(blox.customFields).forEach(([_, [customKey, customValue]]) => {
            paramArray.push({ text: addKerning(`${customKey}: `), options: { bold: true } });
            paramArray.push({ text: `${customValue}\n`, options: { bold: false } });
        });
    }

    // Remove trailing newline from last item if it exists
    if (paramArray.length > 0) {
        const lastItem = paramArray[paramArray.length - 1];
        if (lastItem.text.endsWith('\n')) {
            lastItem.text = lastItem.text.slice(0, -1);
        }
    }

    return paramArray;
}

/**
 * Converts SVG color format to PowerPoint compatible color format
 * @param svgColor - The SVG color string to convert
 * @returns PowerPoint compatible color string
 */
function convertSvgColorToPpt(svgColor: string): string {
    // Remove any spaces
    svgColor = svgColor.trim();
    
    // Handle rgb/rgba format
    if (svgColor.startsWith('rgb')) {
        const values = svgColor.match(/\d+/g);
        if (values && values.length >= 3) {
            const [r, g, b] = values.map(Number);
            return (r * 65536 + g * 256 + b).toString(16).padStart(6, '0');
        }
    }
    
    // Handle hex format
    if (svgColor.startsWith('#')) {
        return svgColor.slice(1); // Remove the # prefix
    }
    
    return svgColor;
}

/**
 * Adds a legend to a PowerPoint slide
 * @param slide - The PowerPoint slide to add the legend to
 * @param materials - Array of materials to display in the legend
 */
function addLegendToSlide(slide: pptxgen.Slide, materials: LegendMaterial[]): void {
    // Calculate total height and starting position
    const totalLegendHeight = materials.length * (LEGEND_LAYOUT.itemHeight + LEGEND_LAYOUT.verticalPadding);
    const legendStartY = LEGEND_LAYOUT.yMiddle - (totalLegendHeight / 2);

    // Add each material to the legend
    materials.forEach((material, index) => {
        const yPosition = legendStartY + (index * (LEGEND_LAYOUT.itemHeight + LEGEND_LAYOUT.verticalPadding));
        const pptColor = convertSvgColorToPpt(material.color);
        
        // Add color box with stroke
        slide.addShape('rect', {
            x: LEGEND_LAYOUT.xPosition,
            y: yPosition,
            w: LEGEND_LAYOUT.boxSize,
            h: LEGEND_LAYOUT.boxSize,
            fill: { color: pptColor },
            line: { color: PPT_STYLES.colors.dark, width: 0.5 }
        });

        // Add text label
        slide.addText(material.label.trim(), {
            x: LEGEND_LAYOUT.xPosition + LEGEND_LAYOUT.labelOffset,
            y: yPosition,
            w: LEGEND_LAYOUT.labelWidth,
            h: LEGEND_LAYOUT.itemHeight,
            fontSize: LEGEND_LAYOUT.fontSize,
            fontFace: PPT_STYLES.fonts.primary,
            color: PPT_STYLES.colors.black,
            valign: 'middle',
            wrap: true
        });
    });
}

/**
 * Main function to generate and save a PowerPoint presentation
 * @param svgs - Object containing SVG strings for each process step
 * @param processBloxes - Array of process blocks
 * @param materials - Array of materials with their colors and labels
 * @param processName - Name of the process
 * @param processSections - Array of process sections
 * @param processId - Unique identifier for the process
 * @param processUsername - Username of the process creator
 * @param updateUserMutation - Mutation function to update user metrics
 * @param exportUser - Optional minimal user object for metrics tracking
 */
export async function exportPowerPoint(
    svgs: { [key: string]: string },
    processBloxes: AllBloxes[],
    materials: LegendMaterial[],
    processName: string,
    processSections: Section[],
    processId: string,
    processUsername: string,
    updateUserMutation: UseMutationResult<void, any, { userId: string; data: UpdateUserRequest; }>,
    exportUser?: ExportUser
): Promise<void> {
    try {
        const pptx = new pptxgen();
        pptx.layout = 'LAYOUT_16x9';
        pptx.author = processUsername;
        pptx.title = processName || "Unnamed Process";

        const sectionMap = bloxToSectionMap(processSections);
        let prevSection = "empty section";

        // Title slide
        pptx.addSection({ title: "Title" });
        const slide1 = pptx.addSlide({ sectionTitle: "Title" });
        
        // [offset: website-link] Website link positioned at bottom (y: 5.28)
        slide1.addText(`www.fabublox.com/process-editor/${processId}`, {
            x: 0, y: 5.28, w: 10, h: 0.35,
            valign: "bottom", align: "center",
            ...PPT_STYLES.text.footer
        });

        // [offset: title-text] Title text positioned in upper third (y: 1.5)
        slide1.addText(processName, {
            x: 1, y: 1.5, w: 8, h: 1.5,
            valign: "middle", align: "center",
            ...PPT_STYLES.text.title
        });

        // User Name
        slide1.addText(`Designed by ${processUsername} at`, {
            x: 0, y: 3, w: 10, h: 0.5,
            valign: "bottom", align: "center",
            ...PPT_STYLES.text.subheading,
            color: PPT_STYLES.colors.secondary
        });

        // Logo
        slide1.addImage({
            path: FabuLogo,
            x: 3, y: 3.5, w: 4, h: 1
        });

        // Process steps slides
        for (let i = 0; i < processBloxes.length; i++) {
            const blox = processBloxes[i];
            const svgData = svgs[`step_${i}`];
            
            if (!svgData) {
                continue;
            }

            const section = sectionMap.get(blox.id || "");

            // Add section slide if new section
            if (section !== prevSection && section && blox.bloxType !== "STARTBLOX") {
                pptx.addSection({ title: section });
                const sectionSlide = pptx.addSlide({ sectionTitle: section });
                sectionSlide.addText([
                    { text: " " + section, options: { bold: true }}
                ], {
                    x: 1, y: 1.8125, w: 8, h: 2,
                    valign: "middle", align: "center",
                    color: PPT_STYLES.colors.black,
                    fontSize: PPT_STYLES.text.title.fontSize,
                    fontFace: PPT_STYLES.fonts.primary
                });
            }

            if (blox.bloxType !== "STARTBLOX") {
                prevSection = section || "";
            }

            const slide = pptx.addSlide({ sectionTitle: section });

            // Step title with step number
            const heading = i === 0 ? (blox.name || "Substrate Stack") : "Step " + i + ": " + (blox.name || "");
            slide.addText(heading, {
                x: 0.5, y: 0.25,
                w: 7, h: 0.5,
                valign: "top", margin: 0.5,
                ...PPT_STYLES.text.heading
            });

            // Tool name
            if (blox.bloxType !== "STARTBLOX") {
                slide.addText(blox.toolName || "Tool not provided", {
                    x: 0.75, y: 0.75, w: 5, h: 0.5,
                    valign: "top",
                    ...PPT_STYLES.text.subheading
                });
            }

            // Parameters
            const paramString = generateParamString(blox, new Map());
            slide.addText([
                ...paramString.map(item => ({
                    text: item.options.bold ? "• " + item.text : item.text,
                    options: { 
                        bold: item.options.bold,
                        fontSize: PPT_STYLES.text.body.fontSize
                    }
                }))
            ], {
                x: 0.5, y: 1.25, w: 4.5, h: 3.5,
                valign: "top",
                fontFace: PPT_STYLES.fonts.primary,
                color: PPT_STYLES.colors.black,
                paraSpaceAfter: 6,
                align: "left",
                indentLevel: 1
            });

            // Comments
            slide.addText([
                { text: "Comments: ", options: { bold: true }},
                { text: (blox.commentField || "") + "\n", options: { bold: false }}
            ], { 
                x: 0.5, y: 4.3, w: 2.75, h: 1.2,
                valign: "top", align: "left",
                ...PPT_STYLES.text.footer,
                color: PPT_STYLES.colors.black
            });

            // Website
            slide.addText("www.fabublox.com", {
                x: 8, y: 5.38, w: 2, h: 0.25,
                valign: "top", align: "right",
                ...PPT_STYLES.text.footer,
                color: PPT_STYLES.colors.darkGrey
            });

            // Module name if exists
            if (blox.moduleName) {
                slide.addText([
                    { text: "Module: ", options: { bold: true }},
                    { text: blox.moduleName + "\n", options: { bold: false }}
                ], {
                    x: 4, y: 5.15, w: 2, h: 0.25,
                    valign: "bottom", align: "right",
                    ...PPT_STYLES.text.footer,
                    color: PPT_STYLES.colors.secondary
                });
            }

            // Section name
            if (section) {
                slide.addText([
                    { text: "Section: ", options: { bold: false }},
                    { text: section + "\n", options: { bold: true }}
                ], {
                    x: 2, y: 5.28, w: 6, h: 0.35,
                    valign: "bottom", align: "center",
                    color: PPT_STYLES.colors.secondary,
                    fontSize: 14,
                    fontFace: PPT_STYLES.fonts.primary
                });
            }

            // Main SVG
            const dataUrl = svgToDataURL(svgData);
            const dimensions = getSquareDimensions(svgData, PPT_STYLES.layout.mainSvg.maxSize);

            slide.addImage({
                data: dataUrl,
                x: 3.2 + (5 - dimensions.width) / 2,
                y: 5 - dimensions.height - 0.25,
                w: dimensions.width,
                h: dimensions.height
            });

            // Add legend
            addLegendToSlide(slide, materials);

            // Logo
            slide.addImage({
                path: FabuLogo,
                x: 7.9, y: 0.1,
                w: PPT_STYLES.layout.logo.width,
                h: PPT_STYLES.layout.logo.height
            });
        }

        // Save and download
        const effectiveProcessName = processName || "Unnamed Process";
        const fileName = `${effectiveProcessName.replace(/[^a-z0-9]/gi, '_')}.pptx`;
        await pptx.writeFile({ fileName });

        if (exportUser) {
            updateUserMutation.mutate({
                userId: exportUser.userId,
                data: {
                    metrics: {
                        ...exportUser.metrics,
                        pptDownloads: (exportUser.metrics?.pptDownloads ?? 0) + 1
                    }
                }
            });
        }
    } catch (error) {
        showToast({
            message: "Download Failed",
            intent: Intent.WARNING,
            timeout: 3000
        });
        throw new Error(`PowerPoint generation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
} 