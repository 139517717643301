import { useCallback } from 'react';
import { showToast } from '../../../index';
import { Intent } from '@blueprintjs/core';

/**
 * Enum for save modes
 * @enum {number}
 */
export enum SaveMode {
    CopyToClipboard,
    DownloadFile
}

/**
 * Props for the useImageExport hook
 * @interface UseImageExportProps
 * @property {string} resolution - Image resolution ("Normal" | "High")
 * @property {string} filename - Base filename for exports
 */
interface UseImageExportProps {
    resolution: "Normal" | "High";
    filename: string;
}

/**
 * Hook for handling image export functionality
 * @param {UseImageExportProps} props - Hook props
 * @returns {Object} Export handlers and SaveMode enum
 */
export const useImageExport = ({ resolution, filename }: UseImageExportProps) => {
    const triggerDownload = useCallback((url: string, ext: string) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename + ext;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, [filename]);

    const generatePNG = useCallback((svgElement: Element, source: string, saveMode: SaveMode) => {
        const canvas = document.createElement('canvas');
        const width = resolution === "High" ? 2048 : 512;
        // Use a 1:1 aspect ratio since our SVGs are square
        const height = width;
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');

        const DOMURL = window.URL || window.webkitURL || window;

        const img = new Image();
        const svgBlob = new Blob([source], {type: 'image/svg+xml;charset=utf-8'});

        const url = DOMURL.createObjectURL(svgBlob);

        img.onload = function () {
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                try {
                    if (saveMode === SaveMode.CopyToClipboard) {
                        canvas.toBlob(function (blob) {
                            if (blob) {
                                const item = new ClipboardItem({ "image/png": blob });
                                navigator.clipboard.write([item]);
                            }
                        });
                    } else if (saveMode === SaveMode.DownloadFile) {
                        DOMURL.revokeObjectURL(url);

                        const imgURI = canvas
                            .toDataURL('image/png')
                            .replace('image/png', 'image/octet-stream');

                        triggerDownload(imgURI, ".png");
                    }
                } catch (error) {
                    showToast({
                        message: `Make sure you have granted permission to ${saveMode === SaveMode.CopyToClipboard ? 'access clipboard.' : 'download file.'}.`,
                        intent: Intent.WARNING,
                        timeout: 3000
                    });
                }
            }
        };

        img.src = url;
    }, [triggerDownload, resolution]);

    const generateSVG = useCallback((source: string, saveMode: SaveMode, showText: boolean) => {
        // Clone the SVG and modify it based on text visibility
        const parser = new DOMParser();
        const doc = parser.parseFromString(source, 'image/svg+xml');
        const svgElement = doc.documentElement;

        if (!showText) {
            const texts = svgElement.getElementsByTagName('text');
            Array.from(texts).forEach(text => {
                text.remove();
            });
        }

        const serializer = new XMLSerializer();
        let modifiedSource = serializer.serializeToString(svgElement);

        //add name spaces.
        if(!modifiedSource.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)){
            modifiedSource = modifiedSource.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!modifiedSource.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)){
            modifiedSource = modifiedSource.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        modifiedSource = '<?xml version="1.0" standalone="no"?>\r\n' + modifiedSource;
        
        //convert svg source to URI data scheme and trigger download
        const svgUrl = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(modifiedSource);
        triggerDownload(svgUrl, ".svg");
    }, [triggerDownload]);

    return {
        generatePNG,
        generateSVG,
        SaveMode
    };
}; 