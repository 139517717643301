import { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { ButtonGroup, Button, Divider, Classes, Intent, ButtonProps, Menu, MenuItem, EditableText, Tag, Tooltip, Popover, Switch, Colors, Icon, MenuDivider } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router-dom";
import { Row } from '../Layout/layouts';
import { IconNames } from '@blueprintjs/icons';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { useClearProcess } from './hooks/use-clear-process';
import { useAuth0 } from '@auth0/auth0-react';
import { useProcessHandlers } from './hooks/use-process-handlers';
import { DuplicateDialogButton } from "../dialogs/DuplicateDialogButton";
import { SharingPicker } from "./SharingPicker";
import { openDialogAndWaitForInput } from "../dialogs/SaveYourChangesDialog";
import { SharingProcessButton } from "../dialogs/SharingProcessDialog";
import { useProcessExporter } from "../utils/export-process";
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider";
import { UnsavedChangesContext } from "../hooks/state/unsaved-changes-provider";
import { StateAndSetter } from "../hooks/state/parameter-context";
import SemifabDialog from "../dialogs/SemifabDialog";
import { useEnableStackSimulator } from "../hooks/use-enable-stacksimulator";
import { showToast } from "..";
import SimulateButton from "./SimulateButton";
import { ExcelExportDialog } from '../dialogs/ExcelExportDialog';
import { usePanelManager } from '../hooks/use-panel-manager';

import { usePowerPointExport } from '../hooks/use-powerpoint-export';
import { PowerPointExportDialog } from '../dialogs/PowerPointExportDialog';

import { TableSettingsMenu, ExportSettingsMenu, ListSettingsMenu } from './menus/ProcessHeaderMenus';

/**
 * Represents the available view modes for process visualization
 * - undefined/null: Blox View (Grid View) - Default view showing visual block representation
 * - 'list': List View - Shows processes in a linear list format
 * - 'table': Table View - Shows processes in a tabular format
 */
type ProcessViewMode = undefined | 'list' | 'table';

/**
 * Configuration for different view modes including their icons and tooltips
 */
const VIEW_MODE_CONFIG = {
    blox: {
        icon: IconNames.GRID_VIEW,
        tooltip: 'Blox View',
        queryParam: undefined, // No query param for default view
        settingsMenu: (props: { isStackHidden: boolean; setIsStackHidden: (hidden: boolean) => void }) => (
            <Menu>
                <MenuItem 
                    onClick={() => props.setIsStackHidden(!props.isStackHidden)} 
                    icon={props.isStackHidden ? IconNames.EYE_OPEN : IconNames.EYE_OFF} 
                    text={`${props.isStackHidden ? "Show" : "Hide"} Stack`} 
                />
            </Menu>
        )
    },
    list: {
        icon: IconNames.PROPERTIES,
        tooltip: 'List View',
        queryParam: 'list',
        settingsMenu: ListSettingsMenu
    },
    table: {
        icon: IconNames.TH,
        tooltip: 'Table View',
        queryParam: 'table',
        settingsMenu: TableSettingsMenu
    }
} as const;

/**
 * Get view mode button props based on mode type
 * @param mode - The view mode type
 * @param config - The configuration for this mode
 * @param isListView - Whether list view is active
 * @param isTableView - Whether table view is active
 * @param switchViewMode - Function to switch view modes
 * @returns Object containing onClick, active, and style props for the button
 */
const getViewModeButtonProps = (
    mode: keyof typeof VIEW_MODE_CONFIG,
    config: typeof VIEW_MODE_CONFIG[keyof typeof VIEW_MODE_CONFIG],
    isListView: boolean,
    isTableView: boolean,
    switchViewMode: (mode: ProcessViewMode) => void
) => {
    return {
        icon: config.icon,
        onClick: () => switchViewMode(config.queryParam),
        active: mode === 'blox' ? (!isListView && !isTableView) :
                mode === 'list' ? isListView :
                mode === 'table' ? isTableView : false,
        style: {
            transition: 'background-color 0.2s ease'
        }
    };
};

export interface ProcessHeaderProps {
    isModule?: boolean;
    closedSectionState?: StateAndSetter<{[key: string]: boolean}>;
}

export const ProcessHeader: React.FC<ProcessHeaderProps> = (props) => {
    const { closedSectionState, isModule } = props;
    const { isPanelOpen, togglePanel } = usePanelManager({ autoOpenOnSelection: false });

    const allSectionsClosed = useMemo(() => {
        if (!closedSectionState) return false;
        const closedSections = closedSectionState[0];
        const values = Object.values(closedSections);
        return values.length > 0 ? values.every(val => val) : false;
    }, [closedSectionState]);

    // Process settings and state
    const {
        isStack3D, 
        setIsStack3D, 
        isStackHidden, 
        setIsStackHidden,
        showAllParameters,
        setShowAllParameters,
        splitWorksheet,
        setSplitWorksheet,
        isProcessInfoOpen,
        setIsProcessInfoOpen,
        smoothScroll,
        setSmoothScroll
    } = useContext(ProcessSettingsContext);
    const [processId] = useFabuState('processId');
    const [processName, setProcessName] = useFabuState('processName');
    const [processBloxes] = useFabuState('processBloxes');
    const [processSections] = useFabuState('processSections');
    const [tempProcessName, setTempProcessName] = useState<string | undefined>(undefined);
    const [processIsReadOnly] = useFabuState('processIsReadOnly');
    
    // Context and state management
    const { editorUnsavedChanges, setEditorUnsavedChanges, setShowSaveYourChangesDialog } = useContext(UnsavedChangesContext);
    const [isSemifabDialogOpen, setIsSemifabDialogOpen] = useState(false);
    const initialRenderRef = useRef(true); // Used for on load logic

    // Handlers and utilities
    const toggleIsProcessInfoOpen = useCallback(() => setIsProcessInfoOpen(!isProcessInfoOpen), [isProcessInfoOpen, setIsProcessInfoOpen]);
    const { exportData, isLoading, exportRequirementsData } = useProcessExporter(processId, isModule);
    const { processHandleSave, processHandleDuplicate } = useProcessHandlers();
    const { user, getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const location = useLocation();

    // View state management with typed viewMode
    const queryParams = new URLSearchParams(location.search);
    const viewMode = queryParams.get('view') as ProcessViewMode;
    const isListView = viewMode === 'list';
    const isTableView = viewMode === 'table';
    const [isExcelExportDialogOpen, setIsExcelExportDialogOpen] = useState(false);

    // Process state management
    const clearProcess = useClearProcess();

    /**
     * Updates the current view mode by modifying URL query parameters
     * @param newMode - The view mode to switch to
     */
    const switchViewMode = useCallback((newMode: ProcessViewMode) => {
        const newQueryParams = new URLSearchParams(location.search);
        if (newMode) {
            newQueryParams.set('view', newMode);
        } else {
            newQueryParams.delete('view');
        }
        history.replace({
            pathname: location.pathname,
            search: newQueryParams.toString()
        });
    }, [history, location.search]);

    // Add PowerPoint export hook
    const { isDialogOpen, setIsDialogOpen, handleExport } = usePowerPointExport({
        processBloxes,
        processSections,
        processName,
        processId
    });

    /**
     * Handles creation of a new process/module
     * Checks for unsaved changes and prompts user if necessary
     */
    const createNewProcess = useCallback(async () => {
        if (editorUnsavedChanges) {
            const shouldContinue = await openDialogAndWaitForInput(setShowSaveYourChangesDialog, setEditorUnsavedChanges);
            if (!shouldContinue) return;
        }
        
        clearProcess();
        history.push(`/${isModule ? 'module' : 'process'}-editor/create`);
    }, [editorUnsavedChanges, setEditorUnsavedChanges, setShowSaveYourChangesDialog, history, clearProcess, isModule])

    // Default props for buttons
    const defaultProps: ButtonProps = {
        intent: Intent.NONE,
        minimal: true,
        large: true,
    };

    // Table settings menu configuration
    const tableSettingsMenu = (
        <TableSettingsMenu
            showAllParameters={showAllParameters}
            setShowAllParameters={setShowAllParameters}
            splitWorksheet={splitWorksheet}
            setSplitWorksheet={setSplitWorksheet}
        />
    );

    // Update the exportSettingsMenu to include PowerPoint export
    const exportSettingsMenu = (
        <ExportSettingsMenu
            exportData={exportData}
            exportRequirementsData={exportRequirementsData}
            isLoading={isLoading}
            onExcelExport={() => setIsExcelExportDialogOpen(true)}
            onPowerPointExport={() => setIsDialogOpen(true)}
        />
    );

    // Process name management handlers
    const onConfirmProcessName = useCallback(() => {
        if (tempProcessName) {
            setProcessName(tempProcessName);
            setTempProcessName(undefined);
        }
    }, [setProcessName, tempProcessName]);

    const onChangeProcessName = useCallback((value: string) => {
        setEditorUnsavedChanges(true);
        setTempProcessName(value);
    }, [setTempProcessName, setEditorUnsavedChanges]);

    // Handlers for section collapse/expand
    const handleCollapseSections = useCallback(() => {
        if (!closedSectionState) return;
        const [, setClosedSections] = closedSectionState;
        setClosedSections(prevClosedSections => {
            const newState = {...prevClosedSections};
            for (const sectionId in newState) {
                newState[sectionId] = true;
            }
            return newState;
        
        });
    }, [closedSectionState]);

    const handleExpandSections = useCallback(() => {
        if (!closedSectionState) return;
        const [, setClosedSections] = closedSectionState;
        setClosedSections(prevClosedSections => {
            const newState = {...prevClosedSections};
            for (const sectionId in newState) {
                newState[sectionId] = false;
            }
            return newState;
        
        });
    }, [closedSectionState]);
    
    /*
    View state management:
    - Toggle view between list, table and blox view
    - Open in list view if process is read-only
    - Show toast if process is read-only
    */
    useEffect(() => {
        if (initialRenderRef.current && processId && processIsReadOnly && !isListView) {
            const newQueryParams = new URLSearchParams(location.search);
            newQueryParams.set('view', 'list');
            history.replace({
                pathname: location.pathname,
                search: newQueryParams.toString()
            });
            showToast({
                message: "Opened in list view because this is a read-only process",
                intent: Intent.PRIMARY,
                timeout: 3000
            });
        }
        initialRenderRef.current = false;
    }, [processId, processIsReadOnly, isListView, history, location]);

    /*
    StackSimulator state management:
    - Get user data and enable stack simulator
    - Show toast if stack simulator is activated
    */
    const { userData, userDataIsLoading, enableStackSimulator } = useEnableStackSimulator();
    const stackSimulatorEnabled = !!userData?.enabledFeatures.stackSimulator;

    // Toast configuration stack simulator activation
    const activateToast = {
        message: (
            <div>
                <strong><Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />  StackSimulator Activated</strong>
                <br/><br/>
                <p>
                    <em>(required)</em> Fill out the <strong>'Simulate'</strong> tab for each Blox in the right panel.
                </p>
            </div>
        ),
        intent: Intent.SUCCESS,
        timeout: 10000,
    };

    return <Row>
        {/* Sharing and read-only status */}
        {!processIsReadOnly && !isModule && <SharingPicker />}
        {processIsReadOnly && (
            <div 
                style={{ marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10px' }}
            >
                <Tooltip content={`Duplicate this ${isModule ? 'module' : 'process'}, or create your own to make changes. Opens in list view by default.`}>
                    <Tag intent={Intent.WARNING}>Read-only</Tag>
                </Tooltip>
            </div>
        )}

        {/* Process name editor */}
        <h5 className={Classes.HEADING} style={{ margin: 'auto 0px auto 10px' }}>
            <Tooltip content={processName} placement={'bottom'}>
                <div style={{ 
                    maxWidth: '250px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <EditableText 
                        disabled={processIsReadOnly} 
                        placeholder={`${isModule ? 'Module' : 'Process'} Name...`} 
                        value={tempProcessName ?? processName} 
                        onChange={onChangeProcessName} 
                        onConfirm={onConfirmProcessName} 
                    />
                </div>
            </Tooltip>
        </h5>

        {/* Info and section controls */}
        <Tooltip content={`${isProcessInfoOpen ? 'Hide' : 'Show'} ${isModule ? 'Module' : 'Process'} Info`}>
            <Button 
                style={{marginTop: '5px'}} 
                minimal={true} 
                icon={IconNames.InfoSign} 
                onClick={toggleIsProcessInfoOpen} 
            />
        </Tooltip>
        {!isModule && (
            <Tooltip content={`${allSectionsClosed ? 'Expand' : 'Collapse'} Sections`}>
                <Button 
                    style={{marginTop: '5px'}} 
                    minimal={true} 
                    icon={allSectionsClosed ? IconNames.ExpandAll : IconNames.CollapseAll} 
                    onClick={allSectionsClosed ? handleExpandSections : handleCollapseSections} 
                />
            </Tooltip>
        )}

        {/* Main action buttons group */}
        <ButtonGroup style={{ marginLeft: 'auto' }}>
            <Tooltip content={`Create a new ${isModule ? 'module' : 'process'}`}>
                <Button 
                    icon={IconNames.PLUS} 
                    onClick={createNewProcess} 
                    text={'New'} 
                    className="responsive-button"
                    {...defaultProps} 
                />
            </Tooltip>
                
            <Divider />
            
            {!processIsReadOnly && <>
                <Tooltip content="Save your changes">
                    <Button 
                        icon={IconNames.CONFIRM} 
                        onClick={async () => {
                            const token = await getAccessTokenSilently();
                            processHandleSave(processId, token)
                        }} 
                        text={'Save'} 
                        className="responsive-button"
                        {...defaultProps}
                    />
                </Tooltip>
                <Divider /></>
            }

            {!processIsReadOnly && !isModule && 
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        {(stackSimulatorEnabled) && 
                            <Tooltip content="This feature is in beta testing">
                                <Tag
                                    minimal
                                    style={{ backgroundColor: Colors.BLUE3, color: Colors.WHITE }}
                                >
                                    Beta
                                </Tag>
                            </Tooltip>
                        }
                        <SimulateButton userDataIsLoading={userDataIsLoading} stackSimulatorEnabled={stackSimulatorEnabled} setIsSemifabDialogOpen={setIsSemifabDialogOpen}/>

                        {(!userDataIsLoading && stackSimulatorEnabled === false) && 
                            <Tooltip content="Enable the stack simulator feature">
                                <Tag
                                    minimal
                                    style={{ backgroundColor: Colors.BLUE3, color: Colors.WHITE, cursor: 'pointer' }}
                                    onClick={() => {
                                        showToast(activateToast);
                                        enableStackSimulator(true);
                                    }}
                                >
                                    Activate!
                                </Tag>
                            </Tooltip>
                        }
                    </div>
                    <Divider />
                </>
            }

            <Tooltip content={`Create a copy of this ${isModule ? 'module' : 'process'}`}>
                <DuplicateDialogButton 
                    buttonProps={{
                        ...defaultProps,
                        className: "responsive-button",
                        text: 'Duplicate'
                    }} 
                    onDuplicate={async (newProcessName: string) => {
                        if (!user) {
                            history.push(`/${isModule ? 'module' : 'process'}-editor/${processId}`);
                            return;
                        }
                        const token = await getAccessTokenSilently();
                        processHandleDuplicate(newProcessName, token)
                    }}
                />
            </Tooltip>

            <Divider />

            {!processIsReadOnly && <>
                <Tooltip content="Share this process with others">
                    <SharingProcessButton isModule={isModule} />
                </Tooltip>
                <Divider />
            </>}

            {user && !isModule && (
                <>
                    <Tooltip content="Export options">
                        <Popover content={exportSettingsMenu} placement={'bottom'}>
                            <Button icon={IconNames.DOWNLOAD} {...defaultProps} />
                        </Popover>
                    </Tooltip>
                    <Divider />
                </>
            )}

            {/* View-specific settings menus */}
            {!isModule && (
                <>
                    {/* Show settings menu based on current view */}
                    {isTableView && (
                        <Popover 
                            content={
                                <VIEW_MODE_CONFIG.table.settingsMenu
                                    showAllParameters={showAllParameters}
                                    setShowAllParameters={setShowAllParameters}
                                    splitWorksheet={splitWorksheet}
                                    setSplitWorksheet={setSplitWorksheet}
                                />
                            } 
                            placement="bottom"
                            minimal={true}
                        >
                            <Tooltip content="Table Settings">
                                <Button 
                                    icon={IconNames.COG} 
                                    {...defaultProps}
                                    style={{
                                        transition: 'background-color 0.2s ease'
                                    }}
                                />
                            </Tooltip>
                        </Popover>
                    )}
                    {(!isListView && !isTableView) && (
                        <Tooltip content="Blox Settings">
                            <Popover 
                                content={
                                    <VIEW_MODE_CONFIG.blox.settingsMenu
                                        isStackHidden={isStackHidden}
                                        setIsStackHidden={setIsStackHidden}
                                    />
                                } 
                                placement="bottom"
                            >
                                <Button icon={IconNames.COG} {...defaultProps} />
                            </Popover>
                        </Tooltip>
                    )}
                </>
            )}

            {/* Only show divider when settings cog is visible */}
            {!isModule && (isTableView || (!isListView && !isTableView)) && <Divider />}

            {/* View switching buttons - only shown for processes, not modules */}
            {!isModule && (
                <>
                    <ButtonGroup>
                        {Object.entries(VIEW_MODE_CONFIG).map(([mode, config]) => (
                            <Tooltip key={mode} content={config.tooltip}>
                                <Button 
                                    {...defaultProps}
                                    {...getViewModeButtonProps(
                                        mode as keyof typeof VIEW_MODE_CONFIG,
                                        config,
                                        isListView,
                                        isTableView,
                                        switchViewMode
                                    )}
                                />
                            </Tooltip>
                        ))}
                    </ButtonGroup>

                    {/* Panel toggle as the last item - only show when panel is closed */}
                    {!isPanelOpen && <Divider />}
                    {!isPanelOpen && (
                        <Tooltip content="Open parameter panel">
                            <Button 
                                icon="chevron-left"
                                {...defaultProps}
                                onClick={() => togglePanel(true)}
                                className="responsive-button"
                                style={{ marginLeft: 'auto' }}
                            />
                        </Tooltip>
                    )}
                </>
            )}

        </ButtonGroup>
        <SemifabDialog isOpen={isSemifabDialogOpen} setIsOpen={setIsSemifabDialogOpen} />
        <ExcelExportDialog
            isOpen={isExcelExportDialogOpen}
            onClose={() => setIsExcelExportDialogOpen(false)}
            processName={processName}
            processBloxes={processBloxes}
            processSections={processSections}
        />
        <PowerPointExportDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onExport={handleExport}
        />
    </Row>
}
