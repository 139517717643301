import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Paper,
  Chip,
  TextField,
  Tooltip,
} from '@mui/material';

import {
  Person,
  EditOutlined,
  Check,
  Close,
  Science,
  WorkspacePremium
} from '@mui/icons-material';

import GroupsIcon from '@mui/icons-material/Groups';
import { ContactDialog } from '../../dialogs/ContactDialog';
import { User } from '../../__generated__/User';
import { ReadOnlyUserElements, EditingUserElements } from './UserDisplayElements';

export type DisplayedUser = Pick<User, 'firstName' | 'lastName' | 'displayName' | 'affiliation' | 'experience' | 'profilePicture'>;

interface ProfileCardProps {
  userData?: User;
  processesCount: number;
  isEditing: boolean;
  displayedUser: DisplayedUser
  setEditedUser: React.Dispatch<React.SetStateAction<DisplayedUser | null>>;
  handleSaveChanges: () => Promise<void>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  userData,
  processesCount,
  isEditing,
  displayedUser,
  setEditedUser,
  handleSaveChanges,
  setIsEditing
  
}) => {
  const history = useHistory();
  const [showAvatarSelection, setShowAvatarSelection] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Removing ability to change username for now
  // const isDisplayNameValid = useMemo(() => {
  //   return displayedUser.displayName.trim().length >= 3 && 
  //          /^[a-zA-Z0-9]+$/.test(displayedUser.displayName);
  // }, [displayedUser.displayName]);

  const handleCancelEdit = () => {
    setEditedUser(null);
    setIsEditing(false);
  };

  const handleSave = async () => {
    // if (!isDisplayNameValid) return;
    
    setIsSaving(true);
    
    try {
      await handleSaveChanges();
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const handleStartEditing = () => {
    setEditedUser(displayedUser);
    setIsEditing(true);
  };

  const currentAvatar = displayedUser.profilePicture || 'default';

  return (
    <div className="tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-to-[#ff9567] tw-p-0.5 tw-rounded-2xl md:tw-sticky md:tw-top-0 tw-max-w-[420px] tw-mx-auto">
      <Card className="tw-rounded-[14px] tw-overflow-hidden tw-shadow-xl hover:tw-shadow-2xl tw-relative tw-min-h-[620px] ">
        
        <Box className="tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-to-[#ff9567] tw-h-16">
        {isSaving && (
          <div className="tw-absolute tw-inset-0 tw-z-50 tw-bg-white/75 tw-animate-pulse " />
        )}
          <Box className="tw-absolute tw-right-4 tw-top-4 tw-z-10">
            {isEditing ? (
              <Box className="tw-flex tw-gap-2">
                <Tooltip
                title={!userData?.displayName ? "Looks like you haven't given yourself a name on FabuBlox. Don't be shy... a username is required" : ""}
                followCursor
                >
                  <span>
                    <IconButton
                      onClick={handleCancelEdit}
                      className={`hover:tw-bg-white/30 ${!userData?.displayName ? 'tw-opacity-50' : ''}`}
                      size="small"
                      disabled={!userData?.displayName || isSaving}
                    >
                      <Close className="tw-text-white" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip 
                title={""}
                followCursor
                >
                  <span>
                    <IconButton
                      onClick={handleSave}
                      className={`hover:tw-bg-white/30`}
                      size="small"
                      disabled={isSaving}
                    >
                      <Check className="tw-text-white" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            ) : (
              <IconButton
                onClick={handleStartEditing}
                className="hover:tw-bg-white/30"
                size="small"
              >
                <EditOutlined className="tw-text-white" />
              </IconButton>
            )}
          </Box>
        </Box>
        <CardContent className="tw-relative">
          <Box className="tw-relative -tw-top-16 -tw-mb-28 tw-w-full tw-flex tw-flex-col tw-items-center">
            <Box 
              className="tw-relative tw-group"
              onClick={() => {
                if (isEditing) {
                  setShowAvatarSelection(!showAvatarSelection);
                }
              }}
            >
              <Avatar
                sx={{
                  width: 128,
                  height: 128,
                  border: '4px solid white',
                  boxShadow: '0 4px 14px rgba(0,0,0,0.1)',
                  cursor: isEditing ? 'pointer' : 'default'
                }}
                className="tw-bg-white"
                src={
                  currentAvatar !== 'default'
                    ? `ProfileAvatars/${currentAvatar}.webp`
                    : undefined
                }
              >
                {currentAvatar === 'default' && (
                  <Person sx={{ fontSize: 64, color: '#8E4CA8' }} />
                )}
              </Avatar>
              {isEditing && (
                <div className="tw-absolute tw-inset-0 tw-bg-[#404040]/50
                                tw-rounded-full tw-items-center tw-justify-center
                              tw-text-white tw-hidden group-hover:tw-flex group-hover:tw-cursor-pointer
                              tw-select-none">
                  Change Avatar
                </div>
              )}
            </Box>

            {/* Avatar Selection */}
            {isEditing && showAvatarSelection && (
              <Paper elevation={0} className="tw-p-3 tw-bg-white tw-rounded-xl">
                <Typography variant="subtitle2" className="tw-font-medium tw-mb-2 tw-text-center">
                  Select Avatar
                </Typography>
                <Box className="tw-flex tw-gap-2 tw-justify-center">
                  {[
                    { value: 'default', label: 'Default' },
                    { value: 'fabuChicken', label: 'Fabu Chicken' },
                    { value: 'fabuDog', label: 'Fabu Dog' },
                    { value: 'fabuPanda', label: 'Fabu Panda' }
                  ].map((option) => (
                    <IconButton
                      key={option.value}
                      onClick={() => {
                        setEditedUser((prev) => prev ? {
                          ...prev,
                          profilePicture: option.value
                        } : null);
                        setShowAvatarSelection(false);
                      }}
                      className={`${
                        displayedUser.profilePicture === option.value
                          ? 'tw-border-2 tw-border-blue-500'
                          : ''
                      }`}
                    >
                      {option.value === 'default' ? (
                        <Person sx={{ fontSize: 40, color: '#8E4CA8' }} />
                      ) : (
                        <img
                          src={`ProfileAvatars/${option.value}.webp`}
                          alt={option.label}
                          style={{ width: 40, height: 40 }}
                        />
                      )}
                    </IconButton>
                  ))}
                </Box>
              </Paper>
            )}
          </Box>
          <Box className="tw-mt-16 tw-text-center">
            {isEditing ? (
              <EditingUserElements
                editedUser={displayedUser}
                setEditedUser={setEditedUser}
              />
            ) : (
              <ReadOnlyUserElements userData={userData} />
            )}

            <Paper
              elevation={0}
              className="tw-bg-[#b3b3b3]/10 tw-p-4 tw-rounded-md tw-mt-6"
            >
              <Box className="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-mb-3">
                <WorkspacePremium className="tw-text-[#8E4CA8]" />
                <Typography variant="subtitle2" className="tw-font-medium">
                  Experience
                </Typography>
              </Box>
              {isEditing ? (
                <TextField
                  placeholder="Describe your professional background..."
                  fullWidth
                  multiline
                  rows={3}
                  value={displayedUser.experience}
                  color="secondary"
                  onChange={(e) =>
                    setEditedUser((prev) => prev ? {
                      ...prev,
                      experience: e.target.value
                    } : null)
                  }
                  size="small"
                />
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {userData?.experience || (
                    <>
                      Describe your professional background...
                    </>
                  )}
                </Typography>
              )}
            </Paper>

            <Box className="tw-flex tw-flex-col max-[350px]:tw-flex-col min-[450px]:tw-flex-row tw-justify-center tw-gap-4 tw-my-6">
              <Box className="tw-flex tw-items-center tw-gap-3 tw-w-full tw-bg-[#b3b3b3]/10 
                              tw-px-4 tw-py-2 tw-rounded-xl hover:tw-cursor-pointer hover:tw-bg-[#b3b3b3]/20"
                    onClick={() => history.push('/my-processes')}>
                <Avatar className="tw-bg-[#8E4CA8]/10">
                  <Science className="tw-text-[#8E4CA8]" />
                </Avatar>
                <Box className="tw-flex-1 max-[450px]:tw-text-center max-[450px]:-tw-ml-[48px]">
                  <Typography variant="subtitle1" className="tw-font-normal">
                    {processesCount} Processes
                  </Typography>
                </Box>
              </Box>
              <Box className="tw-flex tw-w-full tw-items-center tw-gap-3 
                              tw-bg-[#b3b3b3]/10 tw-px-4 tw-py-2 tw-rounded-xl 
                              hover:tw-cursor-pointer hover:tw-bg-[#b3b3b3]/20"
                    onClick={() => history.push('/my-group-processes')}>
                <Avatar className="tw-bg-[#8E4CA8]/10">
                  <GroupsIcon className="tw-text-[#8E4CA8]" />
                </Avatar>
                <Box className="tw-flex-1 max-[450px]:tw-text-center max-[450px]:-tw-ml-[48px]">
                  <Typography variant="subtitle1" className="tw-font-normal">
                    {userData?.groups?.length || 0} Group{(userData?.groups?.length || 0) > 1 ? "s" : "s"}
                    </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="tw-mb-6">
              <Typography variant="subtitle2" className="tw-font-medium tw-mb-3">
                Groups
              </Typography>
              <Box className="tw-flex tw-flex-wrap tw-gap-2 tw-justify-center">
                {userData?.groups && userData.groups.length > 0 ? (
                  userData.groups.map((group: any) => (
                    <Chip
                      key={group.id}
                      label={group.groupName}
                      size="small"
                      className="tw-bg-gradient-to-r tw-from-[#8E4CA8]/5 tw-to-[#ff9567]/5 hover:tw-bg-gradient-to-r hover:tw-from-[#8E4CA8]/15 hover:tw-to-[#ff9567]/25 tw-cursor-pointer hover:tw-shadow-md"
                      onClick={() =>
                        history.push(
                          `/my-group-processes/?groupFilter=${encodeURIComponent(
                            group.groupName
                          )}`
                        )
                      }
                    />
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="tw-text-center"
                  >
                    You haven't joined any groups yet
                    <br />
                    <br />
                    <ContactDialog>
                      {(handleOpen: any) => (
                        <span
                          onClick={handleOpen}
                          className="tw-text-[#8E4CA8] tw-cursor-pointer hover:tw-underline"
                        >
                          Contact us
                        </span>
                      )}
                    </ContactDialog>{' '}
                    to create a group, or reach out to your organization's leader
                    to join your team
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProfileCard; 