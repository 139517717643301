import { useState, useRef } from "react";
import { AllBloxes } from "../../../Data/BloxSchema/base-blox";
import { BloxRef } from "../../../hooks/material-targets";
import { MultipleReferencePayload } from "./use-material-handlers";
import { ConfirmUpdateMaterialDialog } from "../ConfirmUpdateMaterialDialog";
import WarningDialogMultipleSources from "../WarningDialogMultipleMaterials";
import MaterialInfoDialog from "../MaterialInfoDialog";


export function getBloxRefDisplayString(stepNumber: number, materialLabel: string) {
  console.log("getBloxRefDisplayString", stepNumber, materialLabel);
  const stepNumberStr = stepNumber === 1 ? "Start" : `Step ${stepNumber - 1}`;
  return `${stepNumberStr}: ${materialLabel}`;
}

/** 
 * This hook centralizes all the logic for showing/hiding both 
 * the ConfirmUpdateMaterialDialog and WarningDialogMultipleSources 
 * in a promise-based manner.
 */
export function useMaterialPrompts() {

  // --- For ConfirmUpdateMaterialDialog ---
  const [confirmUpdateProps, setConfirmUpdateProps] =
    useState<{ oldMaterialName: string; newMaterialName: string } | null>(null);

  const confirmResolveRef = useRef<((val: boolean) => void) | null>(null);

  /**
   * Show the ConfirmUpdateMaterialDialog and return a promise 
   * that resolves with a boolean (true = user confirmed).
   */
  async function showConfirmUpdateMaterial(
    oldName: string,
    newName: string
  ): Promise<boolean> {
    setConfirmUpdateProps({ oldMaterialName: oldName, newMaterialName: newName });
    return new Promise<boolean>((resolve) => {
      confirmResolveRef.current = resolve;
    });
  }

  /** Called by the dialog’s onConfirm/onCancel */
  function handleConfirmMaterialResolve(userConfirmed: boolean) {
    if (confirmResolveRef.current) {
      confirmResolveRef.current(userConfirmed);
    }
    confirmResolveRef.current = null;
    setConfirmUpdateProps(null);
  }

  // --- For WarningDialogMultipleSources ---
  const [warningDialogData, setWarningDialogData] = useState<{
    currentBlox: AllBloxes;
    payload: MultipleReferencePayload;
  } | null>(null);

  const warningDialogResolveRef = useRef<((selectedSteps: BloxRef[] | null) => void) | null>(null);

  /**
   * Show the WarningDialogMultipleSources and return a promise 
   * that resolves with an array of BloxRef (or null) if the user cancels.
   */
  async function showMultipleSourcesWarning(
    currentBlox: AllBloxes,
    payload: MultipleReferencePayload
  ): Promise<BloxRef[] | null> {
    setWarningDialogData({ currentBlox, payload });
    return new Promise<BloxRef[] | null>((resolve) => {
      warningDialogResolveRef.current = resolve;
    });
  }

  /** Called by the WarningDialogMultipleSources on confirm/cancel */
  function handleMultipleSourcesResolve(selectedUpdateSteps: BloxRef[] | null) {
    if (warningDialogResolveRef.current) {
      warningDialogResolveRef.current(selectedUpdateSteps);
    }
    warningDialogResolveRef.current = null;
    setWarningDialogData(null);
  }

  // --- For MaterialInfoDialog (read-only) ---
  // This dialog is purely informational so no promise or ref is needed.
  const [materialInfoDialogData, setMaterialInfoDialogData] = useState<{
    currentBlox: AllBloxes;
    materialName: string;
    bloxRefs: BloxRef[];
  } | null>(null);

  /**
   * Show the MaterialInfoDialog. This dialog is read-only and
   * simply displays the material usage info.
   */
  function showMaterialInfo(
    currentBlox: AllBloxes,
    materialName: string,
    bloxRefs: BloxRef[]
  ): void {
    setMaterialInfoDialogData({ currentBlox, materialName, bloxRefs });
  }

  // --- Renderable dialog elements ---

  const ConfirmUpdateMaterialElement = confirmUpdateProps && (
    <ConfirmUpdateMaterialDialog
      isOpen={!!confirmUpdateProps}
      data={confirmUpdateProps}
      onConfirm={() => handleConfirmMaterialResolve(true)}
      onCancel={() => handleConfirmMaterialResolve(false)}
    />
  );

  const WarningMultipleSourcesElement = warningDialogData && (
    <WarningDialogMultipleSources
      isOpen={!!warningDialogData}
      currentBlox={warningDialogData.currentBlox}
      pendingUpdate={warningDialogData.payload}
      onConfirm={(selectedUpdateSteps) => handleMultipleSourcesResolve(selectedUpdateSteps ?? null)}
      onClose={() => handleMultipleSourcesResolve(null)}
    />
  );

  const MaterialInfoDialogElement = materialInfoDialogData && (
    <MaterialInfoDialog
      isOpen={true}
      currentBlox={materialInfoDialogData.currentBlox}
      materialName={materialInfoDialogData.materialName}
      bloxRefs={materialInfoDialogData.bloxRefs}
      onClose={() => setMaterialInfoDialogData(null)}
    />
  );

  return {
    // Functions that trigger the dialogs
    showConfirmUpdateMaterial,
    showMultipleSourcesWarning,
    showMaterialInfo,

    // Renderable React elements (to be inlined in the parent component)
    ConfirmUpdateMaterialElement,
    WarningMultipleSourcesElement,
    MaterialInfoDialogElement,
  };
}