/**
 * @fileoverview Header component for process views in the IFrame application.
 * This component provides the title bar and primary controls for interacting with 
 * process views, including view mode toggles, template visibility, chatbot access,
 * and settings. It serves as the main navigation and control panel for the process
 * viewer and adapts its appearance based on available screen space.
 */

import React from 'react';
import { 
  Box, 
  Typography, 
  ButtonGroup, 
  Button, 
  Tooltip, 
  IconButton 
} from '@mui/material';

// Import icons
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsIcon from '@mui/icons-material/Settings';
import TemplateIcon from '@mui/icons-material/Dashboard';

// Import the chatbot toggle component
import { ChatbotToggle } from './IFrameChatbot';
import { ViewMode } from './hooks/useFabuUIState';

export interface IFrameProcessHeaderProps {
  processName: string;
  sizeConfig: any;
  iframeStyles: any;
  viewMode: ViewMode;
  changeViewMode: (mode: ViewMode) => void;
  showChatbot: boolean;
  toggleChatbot: () => void;
  hasUnread: boolean;
  showControls: boolean;
  setAnchorEl: (el: HTMLButtonElement | null) => void;
  setShowControls: (show: boolean) => void;
  showTemplates: boolean;
  setShowTemplates: (show: boolean) => void;
}

/**
 * Header component for the iframe process viewer with view controls
 */
export function IFrameProcessHeader({
  processName,
  sizeConfig,
  iframeStyles,
  viewMode,
  changeViewMode,
  showChatbot,
  toggleChatbot,
  hasUnread,
  showControls,
  setAnchorEl,
  setShowControls,
  showTemplates,
  setShowTemplates
}: IFrameProcessHeaderProps) {
  return (
    <Box sx={{
      ...iframeStyles.miniHeader,
      borderTopLeftRadius: sizeConfig.borderRadius,
      borderTopRightRadius: sizeConfig.borderRadius,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: 0,
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
    }}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {processName || 'Process Viewer'}
      </Typography>
      <Box sx={iframeStyles.headerControls}>
        {/* Templates Button */}
        {/* Commented out to prevent users from closing the process templates
        <Tooltip title={showTemplates ? "Hide Templates" : "Show Templates"}>
          <IconButton 
            size="small" 
            onClick={() => setShowTemplates(!showTemplates)}
            aria-label="Toggle Templates"
            color={showTemplates ? "primary" : "default"}
            sx={{
              mr: 1,
              bgcolor: showTemplates ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
              '&:hover': {
                bgcolor: 'rgba(25, 118, 210, 0.12)'
              }
            }}
          >
            <TemplateIcon />
          </IconButton>
        </Tooltip>
        */}
        
        {/* View Mode Controls */}
        <ButtonGroup size="small">
          <Tooltip title="Standard View">
            <Button
              variant={viewMode === 'standard' ? "contained" : "outlined"}
              onClick={() => changeViewMode('standard')}
            >
              <ViewModuleIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="List View">
            <Button
              variant={viewMode === 'list' ? "contained" : "outlined"}
              onClick={() => changeViewMode('list')}
            >
              <ViewListIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Table View">
            <Button
              variant={viewMode === 'table' ? "contained" : "outlined"}
              onClick={() => changeViewMode('table')}
            >
              <TableChartIcon fontSize="small" />
            </Button>
          </Tooltip>
        </ButtonGroup>
        
        {/* Chatbot Toggle Button */}
        {/* <ChatbotToggle 
          showChatbot={showChatbot} 
          toggleChatbot={toggleChatbot} 
          hasUnread={hasUnread}
        /> */}
        
        {/* Settings button */}
        {/* Commented out to hide settings from users for now
        <Tooltip title="Process Settings">
          <IconButton 
            size="small" 
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setShowControls(true);
            }}
            aria-label="Process Settings"
            color={showControls ? "primary" : "default"}
            sx={{
              bgcolor: showControls ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
              '&:hover': {
                bgcolor: 'rgba(25, 118, 210, 0.12)'
              }
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        */}
      </Box>
    </Box>
  );
}

export default IFrameProcessHeader; 