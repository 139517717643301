import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { getDefaultColor } from "../material-mappings";

export interface SpinDopant extends BaseBlox {
    material: string | null;
    dopantType: string | null;
    dispenseVolume: number | null;
    dispenseVolumeUnit: Units | null;
    spinSpeed: number | null;
    spinSpeedUnit: Units | null;
    spinTime: number | null;
    spinTimeUnit: Units | null;
    filmThickness: number | null;
    filmThicknessUnit: Units | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    layerLabel: string | null;
    fillGaps: boolean | null;
}

export const spinDopantDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Spin-On Dopant Name",
        placeholder: "Enter material name",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    dopantType: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dopant Type",
        placeholder: "Enter e.g. p+/n+ or dopant species",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    dispenseVolume: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dispense Volume",
        placeholder: "Enter dispense volume",
        isNumber: true,
        order: 3,
        units: [Units.NL,Units.UL,Units.ML],
        tabs: [Tab.EXPERIMENTAL],
    },
    filmThickness: {
      fieldType: DisplayFieldTypes.Input,
      label: "Film Thickness",
      placeholder: "Enter film thickness",
      isNumber: true,
      order: 4,
      units: [Units.NM, Units.MICRON],
      tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    spinSpeed: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Speed",
        placeholder: "Enter spin speed",
        isNumber: true,
        order: 5,
        units: [Units.RPM],
        tabs: [Tab.EXPERIMENTAL],
    },
    spinTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Time",
        placeholder: "Enter spin time",
        isNumber: true,
        order: 6,
        units: [Units.SEC,Units.MIN],
        tabs: [Tab.EXPERIMENTAL],
    },

    //DISPLAY
    fillGaps: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Fill Gaps",
        order: 0,
        tabs: [Tab.DISPLAY,Tab.SEMIFAB],
        defaultValue: true,
        infoContent: "If toggled off, a uniform, conformal coating is used. If toggled on, a planar layer is deposited where the thickness is referenced from the highest point in the cross section."
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
}

export const getSpinDopant = ({stepNumber}: BloxArgs): SpinDopant => ({
        name: `Spin-On Dopant ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.SpinDopant,
        material: null,
        fillGaps: true,
        dopantType: null,
        dispenseVolume: null,
        dispenseVolumeUnit: Units.UL,
        spinSpeed: null,
        spinSpeedUnit: Units.RPM,
        spinTime: null,
        spinTimeUnit: Units.SEC,
        filmThickness: null,
        filmThicknessUnit: Units.MICRON,
        commentField: null,
        layerColor: getDefaultColor(BloxTypes.SpinDopant),
        layerThickness: 10,
        layerLabel: null,
        customFields: { },
});
