/**
 * @fileoverview TableViewHeader component provides section navigation for the ProcessTableView.
 * It implements a breadcrumb-style navigation system that allows users to switch between different
 * sections of a process. The component uses Material-UI's Breadcrumbs component with custom styling
 * and tooltips for better user experience.
 * 
 * The header is sticky-positioned at the top of the view and includes section buttons that highlight
 * the currently active section. Each section button displays a tooltip with the full section name
 * on hover for better accessibility.
 */

import React from 'react';
import { Box, Breadcrumbs, Button, Tooltip } from '@mui/material';
import { Section } from '../../__generated__/Process';
import { tableViewStyles } from './styles/TableView.styles';
import BreadcrumbSeparator from '../BreadcrumbSeparator';

/**
 * Props for the TableViewHeader component
 * @interface TableViewHeaderProps
 * @property {Section[]} sections - Array of section objects to display in the header
 * @property {string} currentSection - ID of the currently active section
 * @property {(sectionId: string) => void} onSectionChange - Callback function when a section is selected
 */
interface TableViewHeaderProps {
  sections: Section[];
  currentSection: string;
  onSectionChange: (sectionId: string) => void;
}

/**
 * Props for the SectionButton component
 * @interface SectionButtonProps
 * @property {Section} section - Section object containing the section data
 * @property {boolean} isActive - Whether this section is currently active
 * @property {() => void} onClick - Callback function when the button is clicked
 */
interface SectionButtonProps {
  section: Section;
  isActive: boolean;
  onClick: () => void;
}

/**
 * Individual section button component with tooltip
 * Displays a single section as a button with a tooltip showing the full section name
 * 
 * @component
 * @param {SectionButtonProps} props - The component props
 * @returns {React.ReactElement} A button wrapped in a tooltip
 */
const SectionButton: React.FC<SectionButtonProps> = ({
  section,
  isActive,
  onClick
}) => {
  const displayName = section.sectionName || 'Untitled Section';
  
  return (
    <Tooltip 
      title={displayName}
      placement="bottom"
      arrow
      enterDelay={500}
    >
      <Button
        onClick={onClick}
        className={`section-item ${isActive ? 'active' : ''}`}
      >
        {displayName}
      </Button>
    </Tooltip>
  );
};

/**
 * TableViewHeader Component
 * Provides section navigation for the ProcessTableView using a breadcrumb-style navigation
 * The header is sticky-positioned at the top and shows all available sections as clickable buttons
 * 
 * @component
 * @param {TableViewHeaderProps} props - The component props
 * @returns {React.ReactElement} The header component, visible with multiple sections or invisible with one section
 */
const TableViewHeader: React.FC<TableViewHeaderProps> = ({
  sections,
  currentSection,
  onSectionChange,
}) => {
  // For single section, render an invisible header to maintain layout and scrolling behavior
  const isSingleSection = sections.length <= 1;
  
  return (
    <Box sx={{
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: 'var(--background-white)',
      borderRadius: '12px',
      padding: '4px',
      visibility: isSingleSection ? 'hidden' : 'visible',
      // Maintain layout space even when invisible
      height: isSingleSection ? '12px' : 'auto'
    }}
    data-testid="table-view-header"
    >
      <Box sx={tableViewStyles.header.container}>
        <Box sx={tableViewStyles.header.content}>
          <Breadcrumbs
            maxItems={999}
            itemsBeforeCollapse={999}
            itemsAfterCollapse={999}
            separator={<BreadcrumbSeparator />}
            sx={tableViewStyles.header.navigation}
          >
            {sections.map((section) => (
              <SectionButton
                key={section.sectionId}
                section={section}
                isActive={section.sectionId === currentSection}
                onClick={() => onSectionChange(section.sectionId)}
              />
            ))}
          </Breadcrumbs>
        </Box>
      </Box>
    </Box>
  );
};

export default TableViewHeader; 