import React, { useCallback, forwardRef } from 'react';
import classNames from 'classnames';
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

/**
 * Props interface for the RightPanel component
 */
interface RightPanelProps {
    /** Content to be rendered inside the right panel */
    children: React.ReactNode;
    /** Controls whether the panel is expanded or collapsed */
    isOpen: boolean;
    /** Callback function triggered when the panel close button is clicked */
    onToggle: () => void;
}

/**
 * A collapsible right panel component that provides a sidebar-like interface.
 * Features smooth transitions and accessibility support.
 * 
 * The panel's visibility is controlled through both the ProcessHeader button
 * and a dedicated close button at the top left of the panel when it's open.
 */
export const RightPanel = forwardRef<HTMLDivElement, RightPanelProps>(({ 
    children, 
    isOpen,
    onToggle
}, ref) => {
    // Handle transition end to update parent state and trigger any necessary side effects
    const handleTransitionEnd = useCallback((e: React.TransitionEvent) => {
        // Only handle transitions of the panel itself
        if (e.target === e.currentTarget) {
            const event = new CustomEvent('rightPanelTransitionEnd');
            window.dispatchEvent(event);
        }
    }, []);

    return (
        <div 
            ref={ref}
            className={classNames('right-panel', {
                'right-panel--open': isOpen,
                'right-panel--closed': !isOpen,
            })}
            onTransitionEnd={handleTransitionEnd}
            role="complementary"
            aria-label="Parameter Panel"
            aria-expanded={isOpen}
        >
            {isOpen && (
                <Button
                    icon={IconNames.CHEVRON_RIGHT}
                    minimal
                    onClick={onToggle}
                    aria-label="Close parameter panel"
                    className="right-panel-close-button"
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '10px',
                        zIndex: 10,
                        padding: 0,
                        height: '40px'
                    }}
                />
            )}
            <div className="right-panel-content">
                {children}
            </div>
        </div>
    );
});