import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { HeaderProps } from './types';

/**
 * Header component for the process notes form
 */
const Header: React.FC<HeaderProps> = ({ isExpanded, toggleExpanded }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: '#1976d2',
      color: 'white',
      cursor: 'pointer',
    }}
    onClick={toggleExpanded}
  >
    <Typography variant="subtitle1">
      Contact MOSIS2.0 Team
    </Typography>
    <IconButton size="small" sx={{ color: 'white' }}>
      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  </Box>
);

export default Header; 