import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import {Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { unionTypeAnnotation } from "@babel/types";
import { getDefaultColor } from "../material-mappings";

export interface Electroplate extends BaseBlox {
    material: string | null;
    materialId: string;
    electrolyte: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    depRate: number | null;
    depRateUnit: Units | null;
    depTime: number | null;
    depTimeUnit: Units | null;
    voltage: number | null;
    voltageUnit: Units | null;
    //DISPLAY
    doubleSided: boolean | null;
    selectiveGrowth : boolean | null;
    layerLabelsToGrow: string[] | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    // SEMIFAB
    layerLabelsToGrowDisabled: () => boolean | null;
}

export const electroplateDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Material",
        placeholder: "Enter material to deposit",
        order: 0,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    electrolyte: {
        fieldType: DisplayFieldTypes.Input,
        label: "Electrolyte Composition",
        placeholder: "Enter electrolyte details",
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film Thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.NMPERMIN,Units.UMPERMIN,Units.UMPERHOUR],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    depTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Time",
        placeholder: "Enter deposition time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    voltage: {
        fieldType: DisplayFieldTypes.Input,
        label: "Voltage",
        placeholder: "Enter voltage",
        isNumber: true,
        units: [Units.VOLT],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY

    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    selectiveGrowth: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Selective Growth",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB]
    },
    layerLabelsToGrow: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Growth Substrate Material",
        order: 2,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB]
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 4,
        tabs: [Tab.DISPLAY],
    },
    // sidewallThickness: {
    //     fieldType: DisplayFieldTypes.PercentageSlider,
    //     label: "Sidewall Thickness",
    //     order: 3,
    //     tabs: [Tab.DISPLAY],
    //     defaultValue: 100
    // },
    // SEMIFAB
}

export const getElectroplate = ({stepNumber}: BloxArgs): Electroplate => ({
        name: `Electroplate ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.Electroplate,
        material: null,
        materialId: uuidv4(),
        electrolyte: null,
        thickness: null,
        thicknessUnit: Units.NM,
        depRate: null,
        depRateUnit: Units.NMPERMIN,
        depTime: null,
        depTimeUnit: Units.SEC,
        voltage: null,
        voltageUnit: Units.VOLT,
        commentField: null,
        //DISPLAY
        layerColor: getDefaultColor(BloxTypes.Electroplate),
        layerThickness: 8,
        sidewallThickness: 100,
        layerLabel: null,
        customFields: {},
        selectiveGrowth: false,
        layerLabelsToGrow: [],
        doubleSided: false,
        // SEMIFAB
        layerLabelsToGrowDisabled: function () {
            return !this.selectiveGrowth ?? false;
        },
});
