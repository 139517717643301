import React, { ReactElement, useState } from 'react';
import { Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_FABUBLOX_API_URL } from '../utils/constants';
import { FabuToaster } from '../components/toaster';
import { Intent } from '@blueprintjs/core';

interface MailComponentProps {
    children: (handleOpen: () => void) => ReactElement;
    messagePlaceholder?: string;
}

export const ContactDialog: React.FC<MailComponentProps> = (props) => {
    const { user } = useAuth0();
    const loggedInEmail = user?.email;
    const loggedInSub = user?.sub;
    const { messagePlaceholder } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        setIsOpen(false);
        setName('');
        setEmail('');
        setMessage('');
        setMessageError(false);
        setEmailError(false);
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+$/;
        return email === '' || emailRegex.test(email);
    };

    const handleBlurEmail = () => {
        setEmailError(email !== '' && !validateEmail(email));
    };

    const handleBlurMessage = () => {
        setMessageError(message.trim() === '');
    };

    const handleSubmit = async () => {
        if (messageError || emailError) return;

        if (message === '') {
            setMessageError(true);
            return;
        }

        // Using the generic feedback endpoint without authentication
        try {
            setIsSubmitting(true);
            
            const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/feedback/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    email,
                    message,
                    loggedInEmail,
                    loggedInSub
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error sending feedback');
            }

            // Show success toast
            FabuToaster.then(toaster => {
                toaster.show({
                    message: "Thank you for your feedback!",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            });

            handleClose();
        } catch (error) {
            console.error('Error sending feedback:', error);
            
            // Show error toast with support email
            FabuToaster.then(toaster => {
                toaster.show({
                    message: "Please email support@fabublox.com directly.",
                    intent: Intent.DANGER,
                    timeout: 5000
                });
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {props.children(handleOpen)}

            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle className="tw-text-xl tw-font-bold tw-text-gray-800">Fabublox Feedback</DialogTitle>
                <DialogContent className="tw-bg-white tw-p-6">
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        className="tw-mb-4"
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleBlurEmail}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email." : ""}
                        className="tw-mb-4"
                    />
                    <TextField
                        label={messagePlaceholder ?? "Your message..."}
                        value={message}
                        onChange={(e) => {
                            if (messageError && e.target.value.trim() !== '') setMessageError(false);
                            setMessage(e.target.value)
                        }}
                        onBlur={handleBlurMessage}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        className="tw-mb-4"
                        error={messageError}
                        helperText={messageError ? "Message is required." : ""}
                    />
                </DialogContent>
                <DialogActions className="tw-bg-gray-50 tw-p-4">
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        color="primary" 
                        className="tw-bg-blue-600 tw-text-white tw-px-6 tw-py-2 tw-rounded"
                        disabled={messageError || emailError || isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};