/**
 * @fileoverview Provides the main application context providers wrapper for the IFrame component system.
 * This component encapsulates the Process and UI context providers, creating a hierarchical state
 * management system for the embedded IFrame application. It serves as the root provider that should
 * wrap all IFrame components to ensure they have access to shared state.
 */

import React from 'react';
import { IFrameProcessProvider } from './IFrameProcessContext';
import { IFrameUIProvider } from './IFrameUIContext';

// This component combines all our context providers
export function IFrameAppProvider({ children }: { children: React.ReactNode }) {
  return (
    <IFrameProcessProvider>
      <IFrameUIProvider>
        {children}
      </IFrameUIProvider>
    </IFrameProcessProvider>
  );
} 