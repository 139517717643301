import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  IconButton,
  Snackbar,
  Tooltip,
  Chip
} from '@mui/material';
import { ArrowForward, Science, Link, Groups, Add } from '@mui/icons-material';
import { StandaloneProcessPreviewRenderer } from '../../list/StandaloneProcessPreviewRenderer';
import { Process } from '../../__generated__/Process';

interface RecentProcessesCardProps {
  processes: Process[] | undefined;
}

const RecentProcessesCard: React.FC<RecentProcessesCardProps> = ({ processes }) => {
  const history = useHistory();

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const sortedProcesses = useMemo(() => {
    if (!processes || processes.length === 0) return [];
    
    return [...processes].sort((a, b) => {
      const dateA = new Date(a.lastUpdatedAt).getTime();
      const dateB = new Date(b.lastUpdatedAt).getTime();
      return dateB - dateA; 
    });
  }, [processes]);

  const handleClick = () => {
    setOpenSnackBar(true);
  };

  return (
    <div className="tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-to-[#ff9567] tw-p-0.5 tw-rounded-xl">
      {/* Header */}
      <Card className="tw-rounded-[10px] tw-shadow-xl hover:tw-shadow-2xl tw-min-h-[620px]">
        <CardContent>
          <Box className="tw-flex tw-justify-between tw-items-center tw-mb-6">
            <Typography variant="h4" className="tw-font-bold">
              Recent Processes
            </Typography>
            <Box className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 tw-items-end">
            <Button
                variant="text"
                endIcon={<Add />}
                onClick={() => history.push('/process-editor')}
                className="tw-border-[#8E4CA8] tw-text-[#8E4CA8] hover:tw-bg-[#8E4CA8]/5 tw-transition-all tw-duration-300 tw-whitespace-nowrap"
              >
                NEW PROCESS
              </Button>
              <Button
                variant="text"
                endIcon={<ArrowForward />}
                onClick={() => history.push('/my-processes')}
                className="tw-border-[#8E4CA8] tw-bg-transparent tw-text-[#8E4CA8] hover:tw-bg-[#8E4CA8]/5 tw-whitespace-nowrap"
              >
                View All
              </Button>
            </Box>
          </Box>

          {/* Recent Processes */}
          <Box className="tw-space-y-4">
            {sortedProcesses.length > 0 ? (
              sortedProcesses.slice(0, 5).map((process: any) => (
                <Paper
                  key={process.processId}
                  elevation={0}
                  className="tw-p-2 tw-rounded-lg tw-bg-[#B3B3B3]/15 hover:tw-bg-[#B3B3B3]/30 tw-transition-all 
                             tw-cursor-pointer tw-outline tw-outline-1 tw-outline-[#8E4CA8]/50 "
                  onClick={() => history.push(`/process-editor/${process.processId}`)}
                >
                  <Box className="tw-flex tw-justify-between tw-items-center ">
                    <Box className="tw-flex tw-items-center tw-gap-3">
                      <Box className="tw-min-w-[64px] tw-py-2 tw-bg-white tw-rounded-[3px]">
                        <StandaloneProcessPreviewRenderer
                          bloxesInfo={{
                            bloxes: process.bloxes,
                            thumbnail: process.thumbnail
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" className="tw-font-medium">
                          {process.processName}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          className="tw-text-black"
                        >
                          Last updated:{' '}
                          {new Date(process.lastUpdatedAt).toLocaleDateString()}
                        </Typography>
                        {process.groups && process.groups.length > 0 && (
                          <Box className="tw-flex tw-gap-1 tw-mt-1">
                            <Groups className="tw-text-[#8E4CA8] tw-text-xl tw-mt-0" />
                            {process.groups.map((group: { id: string, groupName: string }) => (
                              <Chip
                                key={group.id}
                                label={group.groupName}
                                size="small"
                                className="tw-bg-[#8E4CA8]/10 tw-text-[#8E4CA8] tw-text-xs tw-font-medium tw-h-5 
                                            hover:tw-bg-gradient-to-r hover:tw-from-[#8E4CA833] hover:tw-to-[#ff956733] 
                                            tw-cursor-pointer tw-select-none"
                                sx={{
                                  '& .MuiChip-label': {
                                    padding: '0 8px'
                                  }
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push(
                                    `/my-group-processes/?groupFilter=${encodeURIComponent(
                                      group.groupName
                                    )}`
                                  )
                                }}
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Tooltip 
                    title="Copy link" 
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10]
                            }
                          }
                        ]
                    }}}
                    arrow > 
                    <IconButton
                      size="large"
                      onClick={(e) => { 
                        e.stopPropagation();
                        navigator.clipboard.writeText('https://fabublox.com/process-editor/' + process.processId);
                        handleClick();

                      }}
                      className="-tw-rotate-45 tw-text-[#8E4CA8]/75 tw-transition-all  tw-duration-[0.5s] hover:tw-text-[#ff9567]  hover:tw-translate-x-1"
                    >
                      <Link /> 
                    </IconButton>
                    </Tooltip>
                  </Box>
                </Paper>
                
              ))
            ) : (
              // No Processes
              <Box className="tw-text-center tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[400px]">
                <Science sx={{ fontSize: 48 }} className="tw-text-gray-300 tw-mb-2" />
                <Typography color="textSecondary">
                  Looks like you haven't been fabbing... <br />
                  <span
                    onClick={() => history.push('/process-editor')}
                    className="tw-text-[#8E4CA8] tw-cursor-pointer hover:tw-underline"
                  >
                    Start designing
                  </span>{' '}
                  your own process, or browse the{' '}
                  <span
                    onClick={() => history.push('/fabubase')}
                    className="tw-text-[#8E4CA8] tw-cursor-pointer hover:tw-underline"
                  >
                    FabuBase
                  </span>{' '}
                  to get started
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setOpenSnackBar(false)}
        message="Process link copied to clipboard"
        sx={{
          '& .MuiSnackbarContent-root': {
            color: 'white',
            fontWeight: 'bold',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(10px)',
            boxShadow: 'none',
            pointerEvents: 'none'
          }
        }}
        
      />
    </div>
  );
};

export default RecentProcessesCard; 