import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { Section, AdditionalOwnerUser } from "../__generated__/Process";
import { getBloxArrayWithDefaults } from "../Data/fill-blox-defaults";

/**
 * Represents the access control state for a process.
 * Determines what actions a user can perform on a process based on:
 * - Original ownership
 * - Additional ownership
 * - Viewer mode status
 */
export interface ProcessAccessControl {
  /** Whether the current user is the original creator of the process */
  isOriginalOwner: boolean;
  /** Whether the current user is in the additional owners list */
  isAdditionalOwner: boolean;
  /** Whether the process is in read-only mode */
  readOnly: boolean;
}

/**
 * Determines the access control state for a process based on user permissions and context.
 * This function evaluates:
 * 1. Original ownership by comparing user ID with process owner
 * 2. Additional ownership through the additionalOwners list
 * 3. Read-only status based on ownership and viewer mode
 * 
 * @param processData - Process data containing ownership information
 * @param userId - Current user's ID
 * @param isViewerMode - Whether the process is being accessed in viewer mode
 * @returns ProcessAccessControl object containing access rights
 */
export const determineProcessAccess = (
  processData: { userId: string; additionalOwners?: AdditionalOwnerUser[] },
  userId: string | undefined,
  isViewerMode: boolean
): ProcessAccessControl => {
  const isOriginalOwner = Boolean(processData.userId && processData.userId === userId);
  const isAdditionalOwner = ((processData.additionalOwners ?? []) as AdditionalOwnerUser[])
    .some(owner => owner.userId === userId);
  const readOnly = !(isOriginalOwner || isAdditionalOwner) || isViewerMode;

  return {
    isOriginalOwner,
    isAdditionalOwner,
    readOnly
  };
};

/**
 * Represents the initial state data for a process.
 * Contains all the necessary fields to initialize a process in the editor.
 */
export interface ProcessInitialState {
  /** Processed bloxes with default values applied */
  defaultedBloxes: AllBloxes[];
  /** Sections with their initial open/closed states */
  sections: {
    /** The processed sections from the API */
    tempSections: Section[];
    /** Initial open/closed state for each section, keyed by section ID */
    initialOpenSections: { [key: string]: boolean };
  };
  /** Process metadata and configuration */
  metadata: {
    processName: string;
    username: string;
    processId: string;
    isPrivate: boolean;
    additionalOwners: AdditionalOwnerUser[];
    desc: string;
    reference: string;
    groups: any[];
  };
}

/**
 * Initializes the process state from API response data.
 * This function handles three main aspects of process initialization:
 * 1. Processing bloxes with default values using getBloxArrayWithDefaults
 * 2. Setting up sections with their initial open/closed states
 * 3. Extracting and organizing process metadata
 * 
 * Note: The section initialization must happen first as noted in the original code:
 * "Not great that this has to happen first, don't necessarily move this code lightly"
 * 
 * Important:
 * - Default values for bloxes are handled by getBloxArrayWithDefaults, which ensures
 *   all required properties have appropriate values
 * 
 * @param result - Process data from the API
 * @returns ProcessInitialState object containing all initialized state
 */
export const initializeProcessState = (
  result: { 
    bloxes: any[];
    sections?: Section[];
    processName: string;
    username: string;
    processId: string;
    isPrivate: boolean;
    additionalOwners?: AdditionalOwnerUser[];
    desc?: string;
    reference?: string;
    groups?: any[];
  }
): ProcessInitialState => {
  // Process bloxes with defaults
  const defaultedBloxes = getBloxArrayWithDefaults(result.bloxes);

  // Process sections and their initial states
  // Note: This needs to happen first as per original code comment
  const tempSections = (result.sections ?? []) as Section[];
  const initialOpenSections: { [key: string]: boolean } = {};
  tempSections.forEach((section, sectionIndex) => {
    // This makes the first section open on load
    initialOpenSections[section.sectionId] = sectionIndex !== 0;
  });

  // Process metadata with defaults
  const metadata = {
    processName: result.processName,
    username: result.username,
    processId: result.processId,
    isPrivate: result.isPrivate,
    additionalOwners: result.additionalOwners ?? [],
    desc: result.desc ?? '',
    reference: result.reference ?? '',
    groups: result.groups ?? []
  };

  return {
    defaultedBloxes,
    sections: {
      tempSections,
      initialOpenSections
    },
    metadata
  };
}; 