import { OverlayToaster, Position } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";
 
/** Singleton toaster instance. Create separate instances for different options. */
export const FabuToaster = OverlayToaster.createAsync({
    position: Position.BOTTOM_LEFT,
    maxToasts: 3,
}, {
    domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster)
});

/** Singleton toaster instance. Create separate instances for different options. */
export const FeatureToaster = OverlayToaster.createAsync({
    position: Position.BOTTOM_RIGHT,
    maxToasts: 1,
    canEscapeKeyClear: false,
    autoFocus: false,
}, {
    domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster)
});