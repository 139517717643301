import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CategoryIcon from '@mui/icons-material/Category';
import { ProcessTemplate } from './types';

interface TemplateDetailsProps {
  template: ProcessTemplate | null;
  open: boolean;
  onClose: () => void;
  onLaunch: () => void;
}

/**
 * TemplateDetails component for displaying detailed information about a template
 */
export const TemplateDetails: React.FC<TemplateDetailsProps> = ({
  template,
  open,
  onClose,
  onLaunch
}) => {
  if (!template) {
    return null;
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          overflow: 'visible',
          boxShadow: '0 12px 32px rgba(0, 0, 0, 0.18)',
          background: 'linear-gradient(to bottom, #ffffff, #fafcff)',
          maxHeight: '100%',
          height: 'auto'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        bgcolor: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        px: 3,
        pt: 3,
        pb: 2.5
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{
            width: '56px',
            height: '56px',
            background: 'linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%)',
            color: '#1565C0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
            boxShadow: '0 4px 8px rgba(21, 101, 192, 0.12)',
            '& .MuiSvgIcon-root': {
              fontSize: '32px'
            }
          }}>
            {template.icon}
          </Box>
          <Box>
            <Typography variant="h5" fontWeight={600} sx={{ color: '#102A43', letterSpacing: '-0.01em' }}>
              {template.name}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: '#5E718D', mt: 0.5 }}>
              {template.category.charAt(0).toUpperCase() + template.category.slice(1)} Process
            </Typography>
          </Box>
        </Box>
        <IconButton 
          edge="end" 
          onClick={onClose}
          sx={{ 
            color: '#5E718D',
            bgcolor: 'rgba(0, 0, 0, 0.04)',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.08)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 0 }}>
        <Typography variant="body1" sx={{ 
          my: 3,
          px: 0.5,
          color: '#334e68',
          lineHeight: 1.6,
          fontSize: '1rem'
        }}>
          {template.description}
        </Typography>
        
        <Typography 
          variant="h6" 
          fontWeight={600} 
          sx={{ 
            mb: 2,
            color: '#102A43',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            '&::before': {
              content: '""',
              display: 'block',
              width: '4px',
              height: '20px',
              backgroundColor: '#1976d2',
              borderRadius: '4px'
            }
          }}
        >
          Process Specifications
        </Typography>
        
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 0, 
            mb: 3, 
            borderRadius: '16px',
            borderColor: 'rgba(0, 0, 0, 0.08)',
            overflow: 'hidden',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)'
          }}
        >
          <List disablePadding>
            <ListItem 
              sx={{ 
                px: 3, 
                py: 1.5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)'
                }
              }}
            >
              <ListItemIcon sx={{ minWidth: 50 }}>
                <CategoryIcon 
                  sx={{ 
                    color: '#1976d2', 
                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                    padding: '8px',
                    borderRadius: '10px',
                    fontSize: '24px'
                  }} 
                />
              </ListItemIcon>
              <ListItemText 
                primary="Provided By"
                secondary={template.category.charAt(0).toUpperCase() + template.category.slice(1)}
                primaryTypographyProps={{ 
                  variant: 'body2', 
                  fontWeight: 600,
                  color: '#334e68'
                }}
                secondaryTypographyProps={{ 
                  variant: 'body1',
                  color: '#102A43',
                  fontWeight: 500
                }}
              />
            </ListItem>
            <ListItem 
              sx={{ 
                px: 3, 
                py: 1.5,
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)'
                }
              }}
            >
              <ListItemIcon sx={{ minWidth: 50 }}>
                <CategoryIcon 
                  sx={{ 
                    color: '#1976d2', 
                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                    padding: '8px',
                    borderRadius: '10px',
                    fontSize: '24px'
                  }}
                />
              </ListItemIcon>
              <ListItemText 
                primary="Materials"
                secondary={template.materials}
                primaryTypographyProps={{ 
                  variant: 'body2', 
                  fontWeight: 600,
                  color: '#334e68'
                }}
                secondaryTypographyProps={{ 
                  variant: 'body1',
                  color: '#102A43',
                  fontWeight: 500
                }}
              />
            </ListItem>
          </List>
        </Paper>
        
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          p: 3,
          mb: 3,
          borderRadius: '16px',
          position: 'relative',
          overflow: 'hidden'
        }}>
          <Button 
            variant="contained" 
            size="medium"
            sx={{ 
              bgcolor: '#1565C0',
              '&:hover': {
                bgcolor: '#0D47A1'
              }
            }}
            onClick={onLaunch}
            startIcon={<PlayArrowIcon />}
          >
            Continue to Process Flow
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}; 