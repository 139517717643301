/**
 * @fileoverview StackViewer is a sophisticated visualization component that provides an interactive
 * 2D representation of process steps with advanced controls and export capabilities. It serves as
 * the core visualization engine for the ProcessListView's stack display.
 * 
 * Key Features:
 * - Interactive 2D process visualization
 * - Step-by-step navigation controls
 * - Multiple export formats (GIF/Image)
 * - Customizable display options
 * - Responsive layout with scroll handling
 * 
 * Component Hierarchy:
 * ProcessListViewStack
 * └─ StackViewer (This Component)
 *    ├─ ControlButtons
 *    │  ├─ Navigation Controls
 *    │  ├─ Export Controls
 *    │  └─ Display Options
 *    └─ StackContent
 *       └─ Step Visualizations
 * 
 * State Management:
 * - Manages visualization settings
 * - Handles export state
 * - Controls navigation state
 * - Manages scroll position
 * 
 * Context Integration:
 * - SvgServiceContext for rendering
 * - ProcessSettingsContext for configuration
 * - ExportDialogProvider for export handling
 * 
 * @component
 */

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GifIcon from '@mui/icons-material/Gif';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { BloxTypes } from '../../Data/BloxSchema/base-blox';
import { SvgServiceContext } from '../../hooks/state/svg-service-provider';
import { SVGDisplayMode } from '../../Services/SVGEngine';
import { ProcessSettingsContext } from '../../hooks/state/process-settings-provider';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import { scrollStackViewer } from '../../utils/scrollUtils';
import { useStackExport } from '../../hooks/use-stack-export';
import { stackViewerStyles } from './styles/ProcessListView.styles';
import { ExportDialog } from '../../dialogs/ExportDialog';

/**
 * Props interface for the StackViewer component.
 * Defines the complete set of properties required for stack visualization.
 * 
 * @interface StackViewerProps
 * 
 * @property {BloxTypes} currentBloxType - Type of the currently displayed process block
 * @property {() => void} onNext - Callback to navigate to the next step
 * @property {() => void} onPrevious - Callback to navigate to the previous step
 * @property {number} stepNumber - Current step number in the process sequence
 * @property {any[]} processBloxes - Array of process blocks to visualize
 * @property {any} [sx] - Optional style overrides using MUI's sx prop
 * @property {(item: any) => void} [onSelectItem] - Optional callback when an item is selected
 */
interface StackViewerProps {
  currentBloxType: BloxTypes;
  onNext: () => void;
  onPrevious: () => void;
  stepNumber: number;
  processBloxes: any[];
  sx?: any;
  onSelectItem?: (item: any) => void;
}

/**
 * Props interface for the control buttons component.
 * Defines properties for the visualization control panel.
 * 
 * @interface ControlButtonsProps
 * 
 * @property {boolean} isOpen - Whether the control panel is expanded
 * @property {() => void} onPrevious - Callback for previous step navigation
 * @property {() => void} onNext - Callback for next step navigation
 * @property {boolean} showText - Whether to display SVG text elements
 * @property {boolean} showStepInfo - Whether to display step information
 * @property {(show: boolean) => void} setShowText - Callback to toggle text visibility
 * @property {(show: boolean) => void} setShowStepInfo - Callback to toggle step info visibility
 * @property {(open: boolean) => void} setIsOpen - Callback to toggle control panel expansion
 * @property {() => void} handleExportGif - Callback to trigger GIF export
 * @property {() => void} onExportClick - Callback to trigger single frame export
 * @property {boolean} isExporting - Whether an export operation is in progress
 */
interface ControlButtonsProps {
  isOpen: boolean;
  onPrevious: () => void;
  onNext: () => void;
  showText: boolean;
  showStepInfo: boolean;
  setShowText: (show: boolean) => void;
  setShowStepInfo: (show: boolean) => void;
  setIsOpen: (open: boolean) => void;
  handleExportGif: () => void;
  onExportClick: () => void;
  isExporting: boolean;
}

/**
 * Control panel component for the stack viewer.
 * Provides navigation, export, and display controls in a compact interface.
 * 
 * Features:
 * - Step navigation controls
 * - Export options (GIF/Image)
 * - Display settings toggles
 * - Loading state indication
 * - Responsive layout
 * 
 * @component
 */
const ControlButtons: React.FC<ControlButtonsProps> = ({
  isOpen,
  onPrevious,
  onNext,
  showText,
  showStepInfo,
  setShowText,
  setShowStepInfo,
  setIsOpen,
  handleExportGif,
  onExportClick,
  isExporting
}) => (
  <Box sx={stackViewerStyles.controls(isOpen)}>
    {isOpen && (
      <>
        <IconButton onClick={onPrevious} size="small">
          <SkipPreviousIcon />
        </IconButton>
        <Tooltip title="Export as GIF">
          <IconButton onClick={handleExportGif} size="small" disabled={isExporting}>
            {isExporting ? <CircularProgress size={20} /> : <GifIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Export current frame">
          <IconButton onClick={onExportClick} size="small">
            <ImageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={showText ? "Hide SVG text" : "Show SVG text"}>
          <IconButton
            onClick={() => setShowText(!showText)}
            size="small"
            sx={{
              backgroundColor: showText ? 'var(--border-light)' : 'var(--background-white)',
            }}
          >
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={showStepInfo ? "Hide step info" : "Show step info"}>
          <IconButton
            onClick={() => setShowStepInfo(!showStepInfo)}
            size="small"
            sx={{
              backgroundColor: showStepInfo ? 'var(--border-light)' : 'var(--background-white)',
            }}
          >
            <FormatListNumberedIcon sx={{ fontSize: 'var(--font-size-base)' }} />
          </IconButton>
        </Tooltip>
        <IconButton onClick={onNext} size="small">
          <SkipNextIcon />
        </IconButton>
      </>
    )}
    {/* <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
      {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton> DEPRECATED STACK VIEWER*/ } 
  </Box>
);

/**
 * Props interface for the stack content component.
 * Defines properties for the main visualization area.
 * 
 * @interface StackContentProps
 * 
 * @property {boolean} isOpen - Whether the content area is expanded
 * @property {any[]} currentBloxes - Array of current process blocks
 * @property {React.ReactElement[]} bloxSVGs - Array of SVG elements for each block
 * @property {boolean} showText - Whether to display SVG text elements
 * @property {boolean} showStepInfo - Whether to display step information
 * @property {{ sectionId: string, sectionName?: string, bloxIds: string[] }[]} [processSections] - Optional section information
 */
interface StackContentProps {
  isOpen: boolean;
  currentBloxes: any[];
  bloxSVGs: React.ReactElement[];
  showText: boolean;
  showStepInfo: boolean;
  processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[];
}

/**
 * Stack content component that renders the process visualization.
 * Provides a scrollable container for step visualizations with section awareness.
 * 
 * Features:
 * - Scrollable visualization container
 * - Section-aware rendering
 * - Step information display
 * - Customizable text visibility
 * - Responsive layout
 * 
 * @component
 */
const StackContent: React.FC<StackContentProps> = ({ 
  isOpen, 
  currentBloxes, 
  bloxSVGs, 
  showText,
  showStepInfo,
  processSections
}) => {
  const getSectionName = (bloxId: string): string => {
    if (!processSections) return '';
    for (const section of processSections) {
      if (section.bloxIds.includes(bloxId)) {
        return section.sectionName || '';
      }
    }
    return '';
  };

  return (
    <Box 
      className="stack-viewer-scroll-container"
      sx={{ 
        display: isOpen ? 'block' : 'none',
        height: '250px',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'var(--panel-background-color)',
        borderRadius: '12px',
        '&::-webkit-scrollbar': {
          width: '8px',
          backgroundColor: 'var(--panel-background-color)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'var(--accent-purple-light)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'var(--accent-purple-lighter)',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px',
        gap: '8px'
      }}>
        {currentBloxes.map((blox, index) => (
          <Box
            key={blox.id}
            id={`stack-item-${blox.id}`}
            sx={{
              width: '100%',
              height: '234px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: 'var(--panel-background-color)',  
              flexShrink: 0,
              padding: '8px',
              '& svg': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                '& text': {
                  fontSize: 'var(--font-size-base)',
                  fill: 'var(--text-dark)',
                  fontFamily: 'var(--font-primary)',
                  display: showText ? 'block' : 'none'
                }
              }
            }}
          >
            {showStepInfo && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '4px',
                  left: '8px',
                  zIndex: 1,
                  textAlign: 'left'
                }}
              >
                <Box sx={{ 
                  fontSize: 'var(--font-size-base)',
                  fontWeight: 'var(--font-weight-bold)',
                  marginBottom: '2px',
                  color: 'var(--text-dark)'
                }}>
                  Step {index + 1}: {blox.name}
                </Box>
                <Box sx={{ 
                  fontSize: 'var(--font-size-small)',
                  color: 'var(--text-secondary)'
                }}>
                  {getSectionName(blox.id)}
                </Box>
              </Box>
            )}
            {bloxSVGs[index]}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

/**
 * StackViewer component for visualizing and interacting with a stack of bloxes.
 * 
 * This component provides a collapsible sidebar that displays a stack visualization
 * with controls for:
 * - Navigating through the stack steps
 * - Exporting the visualization as GIF or PNG
 * - Toggling text visibility
 * - Collapsing/expanding the viewer
 * 
 * The component integrates with the SVG service for rendering and supports
 * both 2D and 3D stack visualizations.
 */
const StackViewer: React.FC<StackViewerProps> = ({
  onNext,
  onPrevious,
  stepNumber,
  processBloxes,
  onSelectItem
}) => {
  const { generateSvgs } = useContext(SvgServiceContext);
  const { isStack3D } = useContext(ProcessSettingsContext);
  const [processBloxesState] = useFabuState('processBloxes');
  const [processName] = useFabuState('processName');
  const [processSections] = useFabuState('processSections');
  const [isOpen, setIsOpen] = useState(false);
  const [showText, setShowText] = useState(true);
  const [showStepInfo, setShowStepInfo] = useState(true);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);

  const currentBloxes = processBloxesState || processBloxes;
  const bloxSVGs = generateSvgs(currentBloxes, isStack3D, SVGDisplayMode.Process);
  
  const { handleExportGif, isExporting } = useStackExport(
    currentBloxes, 
    stepNumber, 
    processName,
    1, // default speed multiplier
    processSections,
    showStepInfo
  );

  const handleExportClick = useCallback(() => {
    setExportDialogOpen(true);
  }, []);

  useEffect(() => {
    if (currentBloxes && stepNumber >= 0) {
      scrollStackViewer(stepNumber, currentBloxes);
      
      if (currentBloxes[stepNumber] && onSelectItem) {
        onSelectItem(currentBloxes[stepNumber]);
      }
    }
  }, [stepNumber, currentBloxes, onSelectItem]);

  return (
    <Box
      className="stack-viewer-container"
      sx={{
        position: 'fixed',
        left: 0,
        top: '50%',
        transform: `translate(${isOpen ? '0' : '-100%'}, -50%)`,
        width: '18%',
        maxWidth: '250px',
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 20,
      }}
    >
      <ControlButtons
        isOpen={isOpen}
        onPrevious={onPrevious}
        onNext={onNext}
        showText={showText}
        showStepInfo={showStepInfo}
        setShowText={setShowText}
        setShowStepInfo={setShowStepInfo}
        setIsOpen={setIsOpen}
        handleExportGif={handleExportGif}
        onExportClick={handleExportClick}
        isExporting={isExporting}
      />
      <StackContent
        isOpen={isOpen}
        currentBloxes={currentBloxes}
        bloxSVGs={bloxSVGs}
        showText={showText}
        showStepInfo={showStepInfo}
        processSections={processSections}
      />
      <ExportDialog
        filename={`${processName}_step_${stepNumber + 1}`}
        isOpen={exportDialogOpen}
        closeDialog={() => setExportDialogOpen(false)}
        bloxes={currentBloxes.slice(0, stepNumber + 1)}
        processSections={processSections}
      />
    </Box>
  );
};

export default StackViewer;