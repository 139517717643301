// Base styles for common elements
const baseStyles = {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
      bgcolor: 'background.default'
    },
    header: {
      width: '100%',
      position: 'sticky',
      top: -1,
      zIndex: 1,
      bgcolor: 'background.paper'
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'var(--scrollbar-track)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--primary-blue)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: 'var(--primary-blue-hover)',
        },
      }
    },
    transition: {
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    },
    colors: {
      text: {
        primary: 'var(--text-primary)',
        secondary: 'var(--text-secondary)',
        dark: 'var(--text-dark)'
      },
      background: {
        white: 'var(--background-white)',
        light: 'var(--background-light)',
        lighter: 'var(--background-lighter)',
        panel: 'var(--panel-background-color)'
      },
      accent: {
        purple: {
          light: 'var(--accent-purple-light)',
          lighter: 'var(--accent-purple-lighter)'
        },
        blue: {
          light: 'var(--accent-blue-light)',
          lighter: 'var(--accent-blue-lighter)'
        }
      },
      primary: {
        blue: 'var(--primary-blue)',
        blueHover: 'var(--primary-blue-hover)',
        blueActive: 'var(--primary-blue-active)'
      },
      border: {
        light: 'var(--border-light)',
        dark: 'var(--border-dark)'
      }
    },
    shadows: {
      small: `0 2px 4px rgba(var(--black-rgb), 0.05), 0 1px 2px rgba(var(--black-rgb), 0.1)`,
      medium: `0 4px 8px rgba(var(--black-rgb), 0.08), 0 2px 4px rgba(var(--black-rgb), 0.12)`,
      large: `0 4px 12px rgba(var(--black-rgb), 0.08)`,
    },
    borders: {
      standard: `1px solid rgba(var(--black-rgb), 0.08)`,
      accent: '3px solid var(--primary-blue)'
    },
    hover: {
      transform: {
        up: 'translateY(-1px)',
        down: 'translateY(2px)',
        scale: 'scale(1.1)'
      },
      background: {
        standard: `rgba(var(--black-rgb), 0.04)`,
        hover: 'var(--hover-background-color)'
      }
    }
  };
  
  // List Item Styles
  export const listItemStyles = {
    transition: baseStyles.transition,
    sectionHeader: {
      root: {
        padding: '14px 18px',
        backgroundColor: 'var(--accent-purple-light)',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '6px',
        marginTop: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)',
        borderLeft: '3px solid var(--primary-blue)',
        '&:hover': {
          backgroundColor: 'var(--accent-purple-lighter)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.12)',
          transform: 'translateY(-1px)'
        }
      },
      title: {
        fontSize: '1rem',
        fontWeight: 600,
        color: 'var(--text-primary)',
        transition: 'color 0.2s ease'
      },
      subtitle: {
        color: 'var(--text-secondary)',
        fontSize: '0.875rem',
        opacity: 0.8,
        transition: 'opacity 0.2s ease'
      },
      expandIcon: {
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        display: 'flex',
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        backgroundColor: 'var(--background-white)',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: 'var(--background-lighter)',
          transform: 'scale(1.1)',
        }
      }
    },
    item: {
      root: {
        marginBottom: '16px',
        marginTop: '8px',
        borderRadius: '6px',
        overflow: 'hidden',
        position: 'relative',
      },
      content: {
        padding: { xs: '8px 12px', sm: '10px 16px' },
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0.75, sm: 1.25 },
        borderRadius: '6px',
      },
      selected: {
        backgroundColor: 'var(--accent-blue-lighter)',
        boxShadow: '0 0 0 1px var(--accent-blue-light), 0 0 8px 1px var(--accent-blue-light)',
      },
      unselected: {
        backgroundColor: 'var(--background-white)',
        '&:hover': {
          backgroundColor: 'var(--hover-background-color)',
          transform: 'translateY(2px)',
        }
      },
      title: {
        transition: 'color 0.2s ease',
        '&:hover': {
          color: 'var(--primary-blue)'
        },
        wordBreak: 'break-word',
        whiteSpace: 'normal'
      },
      titleSelected: {
        color: 'var(--primary-blue)',
        fontWeight: 500,
      },
      titleUnselected: {
        color: 'var(--text-primary)',
        fontWeight: 400,
      },
      subtitle: {
        transition: 'color 0.2s ease',
        '&:hover': {
          color: 'var(--primary-blue-hover)'
        },
        wordBreak: 'break-word',
        whiteSpace: 'normal'
      },
      subtitleSelected: {
        color: 'var(--primary-blue-hover)',
        opacity: 0.9,
      },
      subtitleUnselected: {
        color: 'var(--text-secondary)',
        opacity: 0.8,
      }
    }
  };
  
  // Stack Styles
  export const stackStyles = {
    fixedContainer: {
      position: 'fixed !important',
      left: '0 !important',
      top: '0 !important',
      transform: 'none !important',
      width: { 
        xs: '100%', 
        sm: '500px', 
        md: '600px' 
      },
      height: '100vh !important',
      paddingTop: { 
        xs: '48px', 
        sm: '64px' 
      },
      boxSizing: 'border-box !important',
      zIndex: 1200
    }
  };
  
  // Main ProcessListView Styles
  export const processListViewStyles = {
    root: {
      ...baseStyles.container,
      height: '100vh',
      mt: -1,
      overflow: 'hidden'
    },
    header: {
      container: {
        width: '100%',
        position: 'sticky',
        top: -1,
        zIndex: 1,
        bgcolor: 'background.paper',
      },
      content: {
        width: '100%',
        margin: 0,
        px: { xs: 1, sm: 2, md: 3 },
        py: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: { xs: '22px', sm: '26px' },
      },
      color: 'var(--text-dark)',
    },
    list: {
      container: {
        width: '100%',
        flex: 1,
        overflow: 'auto',
        px: { xs: 1, sm: 2, md: 3 },
        py: { xs: 1, sm: 2 },
      },
      scrollbar: baseStyles.scrollbar
    },
    transition: baseStyles.transition,
    item: listItemStyles.item,
    sectionHeader: listItemStyles.sectionHeader,
    stack: stackStyles
  };
  
  // Stack Viewer Styles
  export const stackViewerStyles = {
    container: {
      position: 'fixed',
      left: 0,
      top: '50%',
      zIndex: 20,
      transition: 'transform 0.3s ease-in-out',
      width: '18%',
      maxWidth: '250px',
    },
    controls: (isOpen: boolean) => ({
      display: 'flex',
      flexDirection: isOpen ? 'row' : 'column',
      gap: '6px',
      backgroundColor: `rgba(var(--background-white-rgb), 0.95)`,
      padding: '8px',
      borderRadius: isOpen ? '0 16px 16px 0' : '0 16px 16px 0',
      marginLeft: isOpen ? '0' : '100%',
      boxShadow: baseStyles.shadows.large,
      backdropFilter: 'blur(8px)',
      '& .MuiIconButton-root': {  
        padding: '4px',
        width: '32px',
        height: '32px',
        backgroundColor: baseStyles.colors.background.white,
        color: baseStyles.colors.text.secondary,
        borderRadius: '12px',
        transition: baseStyles.transition.transition,
        border: baseStyles.borders.standard,
        '&:hover': {
          backgroundColor: baseStyles.colors.background.lighter,
          transform: baseStyles.hover.transform.up,
          boxShadow: baseStyles.shadows.medium,
          color: baseStyles.colors.text.dark
        },
        '& svg': { 
          fontSize: '18px'
        }
      }
    }),
    viewer: {
      width: '100%',
      aspectRatio: '1 / 1',
      backgroundColor: baseStyles.colors.background.panel,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    }
  };
  