/**
 * @fileoverview Process Notes component that provides a form interface for users to submit
 * feedback, requests, and notes about processes to the MOSIS 2.0 team. 
 * This component uses iframe messaging to communicate with the parent application and 
 * includes form validation, clipboard functionality, and notification handling.
 * Part of the modular iframe-based architecture of the application.
 */

import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import * as iframeMessaging from '../../../utils/iframeMessaging';
import { copyToClipboard } from '../IFrameProcessUtils';
import { IFrameProcessNotesProps } from './types';
import { ProcessNotesPayload } from '../../../utils/iframeMessaging';
import { createFormFields } from './formConfig';
import Header from './Header';
import FormContainer from './FormContainer';
import Notifications from './Notifications';

/**
 * Component for submitting process notes to the MOSIS 2.0 team via postMessage API.
 * Uses window.postMessage to communicate with the parent container (MOSIS 2.0 website).
 */
export function IFrameProcessNotes({
  processName,
  processId = 'unknown',
  iframeStyles,
  isMobile
}: IFrameProcessNotesProps) {
  // State for notes panel and form fields
  const [isExpanded, setIsExpanded] = useState(false);
  const [requesterName, setRequesterName] = useState('');
  const [requesterOrg, setRequesterOrg] = useState('');
  const [requestDetails, setRequestDetails] = useState('');
  const [literatureReferences, setLiteratureReferences] = useState('');
  const [customProcessLink, setCustomProcessLink] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isInIframe, setIsInIframe] = useState(false);
  const [copiedField, setCopiedField] = useState<string | null>(null);
  
  // Controls whether the process reference is included
  const [includeProcess, setIncludeProcess] = useState(processId !== 'new');

  // Update includeProcess state when processId changes
  useEffect(() => {
    setIncludeProcess(processId !== 'new');
  }, [processId]);

  // Check if running in iframe
  useEffect(() => {
    setIsInIframe(iframeMessaging.isInIframe());
  }, []);

  // Toggle panel expansion
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle copying text to clipboard
  const handleCopy = (text: string, fieldName: string) => {
    copyToClipboard(
      text, 
      () => {
        setCopiedField(fieldName);
        setTimeout(() => setCopiedField(null), 2000);
      },
      () => {
        setErrorMessage('Failed to copy text. Please try again.');
        setShowError(true);
      }
    );
  };

  // Create form field configurations for reusability
  const formFields = createFormFields(
    requesterName, setRequesterName,
    contactInfo, setContactInfo,
    requestDetails, setRequestDetails,
    requesterOrg, setRequesterOrg,
    customProcessLink, setCustomProcessLink,
    literatureReferences, setLiteratureReferences
  );

  // Clear form fields function for reuse
  const clearForm = () => {
    setRequestDetails('');
    setLiteratureReferences('');
    setCustomProcessLink('');
    setContactInfo('');
    setRequesterName('');
    setRequesterOrg('');
  };

  // Handle notes submission using postMessage API
  const handleSubmit = async () => {
    if (!requestDetails.trim() || !contactInfo.trim() || !requesterName.trim()) return;
    
    setIsSubmitting(true);

    try {
      // Get current URL to include in message
      const processUrl = window.location.href;
      // Conditionally display the process name and ID based on includeProcess
      const displayId = !includeProcess || processId === 'new' ? 'n/a' : processId;
      const displayProcessName = !includeProcess || processId === 'new' ? 'n/a' : processName;
      const displayProcessUrl = !includeProcess || processId === 'new' ? 'n/a' : processUrl;

      // Create payload
      const payload: ProcessNotesPayload = {
        processId: displayId,
        processName: displayProcessName,
        requestDetails: requestDetails.trim(),
        timestamp: new Date().toISOString(),
        processUrl: displayProcessUrl,
        requesterName: requesterName.trim() || undefined,
        requesterOrg: requesterOrg.trim() || undefined,
        contactInfo: contactInfo.trim() || undefined,
        customProcessLink: customProcessLink.trim() || undefined,
        literatureReferences: literatureReferences.trim() || undefined,
      };

      // In development and not in iframe, simulate success
      if (process.env.NODE_ENV === 'development' && !isInIframe) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        clearForm();
        setShowSuccess(true);
        setIsExpanded(false);
      } else {
        // Send notes via postMessage and wait for response
        const response = await iframeMessaging.sendProcessNotes(payload);

        if (response.success) {
          clearForm();
          setShowSuccess(true);
          setIsExpanded(false);
        } else {
          throw new Error(response.message || 'Error. Please try again. If the issue persists contact the MOSIS2.0 team directly using the "Contact Us" link at the top of the page.');
        }
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'Error. Please try again. If the issue persists contact the MOSIS2.0 team directly using the "Contact Us" link at the top of the page.');
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Check if form is valid for submission - explicitly return a boolean value
  const isFormValid = requestDetails.trim() !== '' && contactInfo.trim() !== '' && requesterName.trim() !== '';

  return (
    <>
      <Paper 
        elevation={3}
        sx={{
          position: 'fixed',
          top: isMobile ? 0 : 16,
          right: isMobile ? 0 : 24,
          width: isMobile ? '100%' : '40%',
          minWidth: isMobile ? '100%' : '350px',
          maxWidth: isMobile ? '100%' : '600px',
          borderRadius: isMobile ? '8px 8px 0 0' : 2,
          overflow: 'hidden',
          zIndex: 1000,
        }}
      >
        {/* Header/Toggle Bar */}
        <Header 
          isExpanded={isExpanded} 
          toggleExpanded={toggleExpanded} 
        />

        {/* Form Container */}
        <FormContainer
          isExpanded={isExpanded}
          processId={processId}
          processName={processName}
          includeProcess={includeProcess}
          setIncludeProcess={setIncludeProcess}
          formFields={formFields}
          isSubmitting={isSubmitting}
          copiedField={copiedField}
          handleCopy={handleCopy}
          isFormValid={isFormValid}
          handleSubmit={handleSubmit}
          isMobile={isMobile}
        />
      </Paper>

      {/* Notifications */}
      <Notifications
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        showError={showError}
        setShowError={setShowError}
        errorMessage={errorMessage}
      />
    </>
  );
} 