import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { getDefaultColor } from "../material-mappings";

export interface DopeDiffusion extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    materialDisabled: () => boolean | null;
    diffusionTime: number | null;
    diffusionTimeUnit: Units | null; 
    temperature: number | null;
    temperatureUnit: Units | null;

    // DISPLAY
    environmentalDiffusion: boolean | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    isOxidized: boolean | null;

    // SEMIFAB
    diffusionDistance: number | null;
    diffusionDistanceUnit: Units | null; 
    // dopantLabelsToDiffuse: string[] | null;
    layerLabelsToDiffuseIn: string[] | null;

    // OXIDATION PARAMS
    layerLabelsToOxidize: string[] | null;
    layerLabelsToOxidizeDisabled: () => boolean | null;
    doubleSided: boolean | null;
    doubleSidedDisabled: () => boolean | null;
    oxidationLayerColor: IColor | null;
    oxidationLayerColorDisabled: () => boolean | null;
    oxidationDepth: number | null;
    oxidationDepthDisabled: () => boolean | null;
    layerLabel: string | null;
    layerLabelDisabled: () => boolean | null;
    // SEMIFAB
    oxide: string | null;
    oxideDisabled: () => boolean | null;
    oxideThickness: number | null;
    oxideThicknessDisabled: () => boolean | null;
    oxideThicknessUnit: Units | null;
    oxideGrowthRatio: number | null;
    oxideGrowthRatioDisabled: () => boolean | null;
}

export const DopeDiffusionDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Ion Species",
        placeholder: "Enter dopant species",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    diffusionTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Time",
        placeholder: "Enter diffusion time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    temperature: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Temperature",
        placeholder: "Enter temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    environmentalDiffusion: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Environmental",
        order: 0,
        tabs: [Tab.EXPERIMENTAL, Tab.DISPLAY,Tab.SEMIFAB],
        defaultValue: false,
        infoContent: "Use this to enable dopant diffusion from the environment such as in a diffusion furnace."
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Dopant Color",
        order: 6,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Diffusion Distance",
        order: 4,
        tabs: [Tab.DISPLAY],
    },
    oxidizedSeparator: {
        fieldType: DisplayFieldTypes.Separator,
        order: 99,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    isOxidized: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Oxidize Surface",
        order: 100,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },

    // SEMIFAB
    diffusionDistance: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Distance",
        placeholder: "Enter diffusion distance",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 1,
        tabs: [Tab.SEMIFAB],
    },
    // dopantLabelsToDiffuse: {
    //     fieldType: DisplayFieldTypes.MultiMaterialSelect,
    //     label: "Dopant(s) to Diffuse",
    //     order: 19,
    //     tabs: [Tab.SEMIFAB],
    // },
    layerLabelsToDiffuseIn: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Diffuse In",
        order: 5,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },

    // OXIDATION:
     // DISPLAY
     layerLabelsToOxidize: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Oxidize",
        order: 103,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    //  layerLabel: {
    //      fieldType: DisplayFieldTypes.Input,
    //      label: "Layer Label",
    //      order: 102,
    //      tabs: [Tab.DISPLAY],
    //  },
     doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Oxidation",
        order: 105,
        tabs: [Tab.DISPLAY],
    },
    oxidationDepth: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Oxidation Depth",
        order: 101,
        tabs: [Tab.DISPLAY],
        defaultValue: 10,
        stepSize: 1,
        maxSliderValue: 50
    },


    // SEMIFAB
    oxide: {
        fieldType: DisplayFieldTypes.MaterialEditor,
        label: "Oxide Composition",
        placeholder: "Enter oxide composition",
        order: 102,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    oxideThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxide Thickness",
        placeholder: "Enter oxide thickness",
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 106,
        tabs: [Tab.SEMIFAB],
    },
    oxideGrowthRatio: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxide Thickness / Consumption",
        placeholder: "Enter oxide consumption ratio",
        units: [Units.PERCENT],
        order: 107,
        tabs: [Tab.SEMIFAB],
    },
}

export const getDopeDiffusion = ({stepNumber}: BloxArgs): DopeDiffusion => ({
        name: `Dopant Diffusion ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.DopeDiffusion,
        material: null,
        materialDisabled: function () {
            return !this.environmentalDiffusion;
        },
        diffusionTime: null,
        diffusionTimeUnit: Units.MIN,
        diffusionDistance: null,
        diffusionDistanceUnit: Units.NM,
        temperature: null,
        temperatureUnit: Units.CELSIUS,
        environmentalDiffusion: false,
        layerColor: {R: 0, G: 0, B: 0, A: 0.25},
        layerThickness: 4,
        isOxidized: false,
        commentField: null,
        customFields: {},
        // dopantLabelsToDiffuse: [],
        layerLabelsToDiffuseIn: [],
        // OXIDATION
        layerLabelsToOxidize: [],
        layerLabelsToOxidizeDisabled: function () {
            return !this.isOxidized;
        },
        oxidationLayerColor: getDefaultColor(BloxTypes.ThermalOxide),
        oxidationLayerColorDisabled: function () {
            return !this.isOxidized;
        },
        layerLabel: null,
        layerLabelDisabled: function () {
            return !this.isOxidized;
        },
        doubleSided: false,
        doubleSidedDisabled: function () {
            return !this.isOxidized;
        },
        oxidationDepth: 4,
        oxidationDepthDisabled: function () {
            return !this.isOxidized;
        },
        // SEMIFAB
        oxide: "Thermal Oxide",
        oxideDisabled: function () {
            return !this.isOxidized;
        },
        oxideThickness: null,
        oxideThicknessDisabled: function () {
            return !this.isOxidized;
        },
        oxideThicknessUnit: Units.NM,
        oxideGrowthRatio: 1,
        oxideGrowthRatioDisabled: function () {
            return !this.isOxidized;
        },
});
