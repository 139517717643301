/**
 * @fileoverview Hook for unified UI state management in IFrame components.
 * This hook provides structured access to UI state variables and handles
 * all UI-related state locally until they are fully migrated to useFabuState.
 */

import { useState, useCallback } from 'react';

// Define the supported view modes
export type ViewMode = 'standard' | 'print' | 'list' | 'table';

/**
 * Custom hook that provides access to UI-related state variables.
 * Currently implemented with local state, but designed to allow
 * future migration to useFabuState when those parameters are added.
 * 
 * @returns An object containing UI-related state variables and their setters
 */
export function useFabuUIState() {
  // Local state implementation
  const [viewMode, setLocalViewMode] = useState<ViewMode>('standard');
  
  // Local state for other UI elements that will eventually be in useFabuState
  const [initialScrollTarget, setLocalInitialScrollTarget] = useState<string | null>(null);
  const [closedSections, setLocalClosedSections] = useState<{[key: string]: boolean}>({});
  
  // Wrapper function to prepare for future useFabuState integration
  const setViewMode = useCallback((newMode: ViewMode) => {
    setLocalViewMode(newMode);
    // In the future, when viewMode is added to useFabuState:
    // const [, setViewModeState] = useFabuState('viewMode');
    // setViewModeState(newMode);
  }, []);
  
  // Setters for other UI elements
  const setInitialScrollTarget = useCallback((target: string | null) => {
    setLocalInitialScrollTarget(target);
  }, []);
  
  const setClosedSections = useCallback((value: React.SetStateAction<{[key: string]: boolean}>) => {
    setLocalClosedSections(prev => 
      typeof value === 'function' ? value(prev) : value
    );
  }, []);
  
  return {
    // View mode
    viewMode,
    setViewMode,
    
    // Scroll target
    initialScrollTarget,
    setInitialScrollTarget,
    
    // Section visibility
    closedSections,
    setClosedSections,
  };
} 