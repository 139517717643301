/**
 * @fileoverview ProcessListView is the primary component for displaying process items in a sectioned,
 * collapsible list format. It serves as the main orchestrator component, managing state and layout
 * through custom hooks and child components.
 * 
 * Key Features:
 * - Section-based content organization
 * - Keyboard navigation support
 * - Collapsible sections
 * - Stack visualization integration
 * - Smooth scrolling
 * 
 * Component Hierarchy:
 * ProcessListView (This Component)
 * ├─ ProcessListViewHeader
 * │  └─ SectionBreadcrumbs
 * ├─ ProcessListViewStack
 * │  └─ StackViewer
 * └─ ProcessListViewContent
 *    └─ ProcessListContainer[]
 *       ├─ Section Headers
 *       │  ├─ Collapse Controls
 *       │  └─ Section Information
 *       └─ Process Items
 *          ├─ BloxListItem (When rendering process blocks)
 *          │  ├─ Block Metadata
 *          │  ├─ SVG Visualization
 *          │  └─ Property Display
 *          └─ Custom Item Content (When using renderItem)
 * 
 * State Management:
 * - useSectionState: Section collapse/expand states
 * - useItemState: Item selection and metadata
 * - useListNavigation: Keyboard navigation
 * - useVisibleSection: Scroll-based section tracking
 * 
 * Keyboard Navigation:
 * - Arrow Up/Down: Navigate items
 * - Arrow Left/Right: Alternative navigation
 * - J/K: Vim-style navigation
 * 
 * @component
 * @template T - The type of items being rendered
 */

import React, { useRef, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import ProcessListViewHeader from './ProcessListViewHeader';
import ProcessListViewStack from './ProcessListViewStack';
import ProcessListViewContent from './ProcessListViewContent';
import { useListNavigation } from '../hooks/use-list-navigation';
import { useSectionState } from './hooks/useSectionState';
import { useItemState } from './hooks/useItemState';
import { processListViewStyles } from './styles/ProcessListView.styles';
import { Section } from '../../__generated__/Process';
import { useVisibleSection } from './hooks/useVisibleSection';
import { ProcessSettingsContext } from '../../hooks/state/process-settings-provider';

/**
 * Props interface for the ProcessListView component.
 * Defines the complete set of properties required for list rendering and interaction.
 * 
 * @interface ProcessListViewProps
 * @template T - The type of items being rendered
 * 
 * @property {T[]} items - Array of items to be rendered in the list
 * @property {(item: T) => void} onSelectItem - Callback when an item is selected
 * @property {(item: T) => React.Key} getItemKey - Function to generate unique keys for items
 * @property {(item: T) => React.ReactNode} [renderItem] - Optional custom render function for items
 * @property {(item: T) => string} getItemPrimaryText - Required function to get item's primary text unless renderItem is provided
 * @property {(item: T) => string} [getItemSecondaryText] - Optional function to get item's secondary text
 * @property {(sectionId: string) => void} [onToggleSection] - Optional callback when a section is toggled
 * @property {(item: T) => boolean} [isSectionHeader] - Optional function to identify section headers
 * @property {(item: T) => string} [getSectionId] - Optional function to get section ID from item
 * @property {React.CSSProperties} [containerStyle] - Optional container style overrides
 * @property {Section[]} [sections] - Optional array of section definitions
 * @property {{ [key: string]: boolean }} [closedSections] - Optional map of closed section states
 * @property {{ [key: string]: boolean }} [openSections] - Optional map of open section states
 * @property {string | null} [initialScrollTarget] - Optional key of item to scroll to initially
 * @property {() => void} [onScrollComplete] - Optional callback when initial scroll completes
 * @property {boolean} [smoothScroll] - Optional flag to control scroll behavior
 */
export interface ProcessListViewProps<T> {
  items: T[];
  onSelectItem: (item: T) => void;
  getItemKey: (item: T) => React.Key;
  renderItem?: (item: T) => React.ReactNode;
  getItemPrimaryText: (item: T) => string;
  getItemSecondaryText?: (item: T) => string;
  onToggleSection?: (sectionId: string) => void;
  isSectionHeader?: (item: T) => boolean;
  getSectionId?: (item: T) => string;
  containerStyle?: React.CSSProperties;
  sections?: Section[];
  closedSections?: { [key: string]: boolean };
  openSections?: { [key: string]: boolean };
  initialScrollTarget?: string | null;
  onScrollComplete?: () => void;
}

/**
 * ProcessListView Component
 * A versatile list view component that supports sections, keyboard navigation,
 * and stack visualization with extensive customization options.
 * 
 * Features:
 * - Section-based organization
 * - Keyboard navigation support
 * - Stack visualization integration
 * - Smooth scrolling behavior
 * - Flexible item rendering
 * - Section state management
 * - Accessibility support
 * 
 * @component
 * @template T - The type of items being rendered
 */
export const ProcessListView = <T,>({
  items,
  onSelectItem,
  getItemKey,
  renderItem,
  getItemPrimaryText,
  getItemSecondaryText,
  onToggleSection = (sectionId: string): void => { /* noop */ },
  isSectionHeader = (): boolean => false,
  getSectionId = (): string => '',
  containerStyle = {},
  sections = [],
  closedSections = {},
  openSections,
  initialScrollTarget,
  onScrollComplete,
}: ProcessListViewProps<T>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { smoothScroll } = useContext(ProcessSettingsContext);

  const visibleSectionId = useVisibleSection({
    containerRef,
    sections
  });

  const {
    closedSectionsState,
    handleSectionToggle,
  } = useSectionState({
    closedSections,
    openSections,
    onToggleSection
  });

  const {
    selectedItemKey,
    handleNext,
    handlePrevious,
    handleItemSelect,
  } = useListNavigation({
    items,
    getItemKey,
    onSelectItem,
    getSectionId,
    closedSections: closedSectionsState,
    onToggleSection,
    isSectionHeader,
  });

  const {
    nonHeaderItems,
    currentBloxType,
    stepNumber
  } = useItemState({
    items,
    selectedItemKey,
    isSectionHeader,
    getItemKey
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Only handle keyboard navigation when the user isn't typing in an input
      if (event.target instanceof HTMLInputElement || 
          event.target instanceof HTMLTextAreaElement) {
        return;
      }

      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowRight':
        case 'j':
          event.preventDefault();
          handleNext();
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'k':
          event.preventDefault();
          handlePrevious();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNext, handlePrevious]);

  useEffect(() => {
    if (!initialScrollTarget || !containerRef.current) return;

    const scrollTimeout = setTimeout(() => {
      const container = containerRef.current;
      const items = container?.querySelectorAll('[data-item-key]');
      let targetItem: Element | undefined;
      for (const item of items || []) {
        if (item.getAttribute('data-item-key') === initialScrollTarget) {
          targetItem = item;
          break;
        }
      }

      if (targetItem && container) {
        // Get the header height to offset the scroll position
        const headerHeight = headerRef.current?.getBoundingClientRect().height || 0;
        
        // Calculate dynamic offset based on number of sections
        const isSingleSection = sections.length <= 1;
        // When there's only one section, we need less offset since the header is smaller/hidden
        const additionalOffset = isSingleSection ? -16 : -38;
        
        // Calculate the scroll target position
        const targetRect = targetItem.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const scrollTarget = container.scrollTop + 
          (targetRect.top - containerRect.top - headerHeight - additionalOffset);
        
        // Perform the scroll with configurable behavior
        container.scrollTo({
          top: scrollTarget,
          behavior: smoothScroll ? 'smooth' : 'auto'
        });

        // Only use timeout for completion if smooth scrolling
        if (onScrollComplete) {
          if (smoothScroll) {
            setTimeout(onScrollComplete, 500);
          } else {
            onScrollComplete();
          }
        }
      }
    }, 100);

    return () => clearTimeout(scrollTimeout);
  }, [initialScrollTarget, onScrollComplete, smoothScroll, sections.length]);

  return (
    <Box 
      sx={processListViewStyles.root}
      role="listbox"
      aria-label="List view with keyboard navigation"
    >
      <Box ref={headerRef}>
        <ProcessListViewHeader
          sections={sections}
          closedSections={closedSectionsState}
          containerRef={containerRef}
          onSectionClick={handleSectionToggle}
          visibleSectionId={visibleSectionId}
        />
      </Box>

      <Box
        ref={containerRef}
        className="list-container"
        sx={{
          ...processListViewStyles.list.container,
          ...processListViewStyles.list.scrollbar,
          ...containerStyle
        }}
      >
        <ProcessListViewStack
          currentBloxType={currentBloxType}
          stepNumber={stepNumber}
          nonHeaderItems={nonHeaderItems}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
        
        <ProcessListViewContent<T>
          items={items}
          selectedItemKey={selectedItemKey}
          getItemKey={getItemKey}
          getSectionId={getSectionId}
          isSectionHeader={isSectionHeader}
          closedSections={closedSectionsState}
          getItemPrimaryText={getItemPrimaryText}
          getItemSecondaryText={getItemSecondaryText}
          renderItem={renderItem}
          onSectionHeaderToggle={handleSectionToggle}
          onItemSelect={handleItemSelect}
        />
      </Box>
    </Box>
  );
};

export default ProcessListView; 