import React from 'react';
import { Box } from '@mui/material';

const styles = {
  separator: {
    color: 'var(--text-secondary)',
    fontSize: 'var(--font-size-base)',
    fontWeight: 'var(--font-weight-regular)',
    mx: 0.5,
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8
  }
};

/**
 * Shared breadcrumb separator component with chevron icon
 * Used in both TableViewHeader and SectionBreadcrumbs
 */
const BreadcrumbSeparator: React.FC = () => (
  <Box sx={styles.separator}>
    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M9.29 6.71a.996.996 0 000 1.41L13.17 12l-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/>
    </svg>
  </Box>
);

export default BreadcrumbSeparator; 