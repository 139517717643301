import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { ExportDialogContent } from '../components/export-dialog/ExportDialogContent';
import { ExportDialogProvider } from '../components/export-dialog/hooks/state/export-dialog-provider';

/**
 * Props for the ExportDialog component
 * @interface ExportDialogProps
 * @property {string} filename - Base filename for exports
 * @property {boolean} isOpen - Whether the dialog is open
 * @property {() => void} closeDialog - Function to close the dialog
 * @property {AllBloxes[]} bloxes - Array of bloxes to export
 * @property {number} [initialIndex] - Initial step index
 * @property {{ sectionId: string, sectionName?: string, bloxIds: string[] }[]} [processSections] - Process sections information
 */
interface ExportDialogProps {
    filename: string;
    isOpen: boolean;
    closeDialog: () => void;
    bloxes: AllBloxes[];
    initialIndex?: number;
    processSections?: { sectionId: string, sectionName?: string, bloxIds: string[] }[];
}

/**
 * Dialog component for exporting process visualizations
 * @component
 * @param {ExportDialogProps} props - Component props
 */
export const ExportDialog: React.FC<ExportDialogProps> = ({
    filename,
    isOpen,
    closeDialog,
    bloxes,
    initialIndex = 0,
    processSections
}) => {
    const [isContentReady, setIsContentReady] = useState(false);

    // Reset content ready state when dialog opens/closes
    useEffect(() => {
        if (!isOpen) {
            setIsContentReady(false);
        }
    }, [isOpen]);

    return (
        <ExportDialogProvider>
            <div onContextMenu={(e: React.MouseEvent) => e.preventDefault()}>
                <Dialog 
                    isOpen={isOpen} 
                    title='View & Export' 
                    icon='export' 
                    onClose={() => isContentReady && closeDialog()}
                    canOutsideClickClose={isContentReady}
                    canEscapeKeyClose={isContentReady}
                    style={{
                        paddingBottom: 20,
                        WebkitUserSelect: 'none',
                        MozUserSelect: 'none',
                        msUserSelect: 'none',
                        userSelect: 'none',
                        width: '750px',
                        maxWidth: '95vw',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        margin: '40px auto'
                    }}
                >
                    <div 
                        onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            gap: '20px',
                            overflow: 'hidden'
                        }}>
                        <div 
                            onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                minHeight: 0,
                                overflow: 'hidden'
                            }}>
                            <ExportDialogContent
                                filename={filename}
                                bloxes={bloxes}
                                initialIndex={initialIndex}
                                processSections={processSections}
                                closeDialog={closeDialog}
                                onContentReady={() => setIsContentReady(true)}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </ExportDialogProvider>
    );
}; 