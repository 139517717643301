/**
 * @fileoverview SectionRenderer is a core component responsible for rendering individual process sections
 * and their contained bloxes. It handles the visual representation of process sections in the standard view mode.
 * 
 * Key Features:
 * - Section-based blox organization
 * - Conditional rendering based on section state (open/closed)
 * - Blox selection and interaction handling
 * - Support for empty section states
 * - Integration with process handlers and section management
 * 
 * Component Hierarchy:
 * ProcessViewRenderer
 * └─ SectionRenderer (This Component)
 *    └─ ProcessBlox[]
 *       ├─ Blox Content
 *       ├─ SVG Visualization
 *       └─ Action Handlers
 * 
 * State Management:
 * - Section visibility through closedSections
 * - Blox selection state
 * - Copied blox state for paste operations
 * 
 * Interaction Flow:
 * 1. Receives section data and blox information
 * 2. Manages section visibility
 * 3. Renders ProcessBlox components for each blox
 * 4. Handles blox interactions and section operations
 */

import React, { useState } from "react";
import { Box } from "@mui/material";
import { Row } from "../Layout/layouts";
import { ProcessBlox, ProcessBloxProps } from "./ProcessBlox";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { Section } from "../__generated__/Process";
import { useProcessHandlers } from "./hooks/use-process-handlers";
import { useSectionManager } from "./hooks/use-section-manager";

export interface SectionRendererProps {
  // Core section props
  section: Section;
  sectionIndex: number;
  processSections: Section[];
  
  // Blox management
  bloxes: AllBloxes[];
  bloxSVGs: React.ReactElement[];
  selectedBloxId: string | null;
  
  // Section state management
  closedSections: { [key: string]: boolean };
  
  // UI/Display props
  containerRef: React.RefObject<HTMLDivElement>;
  processIsReadOnly: boolean;
  
  // Action handlers
  handleBloxActions: (
    bloxId: string,
    sectionId: string,
    idx: number
  ) => Partial<Pick<ProcessBloxProps, keyof ProcessBloxProps>>;
  getEmptySectionTarget: (sectionId: string, sectionIdx: number) => React.ReactNode;
  
  // Display configuration
  bloxDisplayProps?: Partial<{
    hideStack: boolean;
    processIsReadOnly?: boolean;
  }>;
}

const SectionRenderer: React.FC<SectionRendererProps> = ({
  // Core section props
  section,
  sectionIndex,
  processSections,
  
  // Blox management
  bloxes,
  bloxSVGs,
  selectedBloxId,
  
  // Section state management
  closedSections,
  
  // UI/Display props
  containerRef,
  
  // Action handlers
  handleBloxActions,
  getEmptySectionTarget,

  
  // Display configuration
  bloxDisplayProps = {},
}) => {
  const isSectionClosed = closedSections[section.sectionId];
  const { processHandleClickBlox } = useProcessHandlers();
  const { createSection } = useSectionManager();

  // Local state for copied blox
  const [copiedBlox, setCopiedBlox] = useState<AllBloxes | null>(null);
  const copiedBloxState: [AllBloxes | null, React.Dispatch<React.SetStateAction<AllBloxes | null>>] = [copiedBlox, setCopiedBlox];

  // Return null if section is closed to completely hide the container
  if (isSectionClosed) {
    return null;
  }

  return (
    <Box 
      ref={containerRef}
      key={section.sectionId}
      style={{
        width: "100%",
        padding: "0",
        backgroundColor: "white",
      }}
    >
      <Row
        id={section.sectionId}
        className="section"
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          gap: "12px",
          padding: "8px 0",
        }}
      >
        {section.bloxIds.length > 0 ? (
          section.bloxIds.map((bloxId, idx) => {
            const blox = bloxes.find((b) => b.id === bloxId);
            if (!blox) return null;

            // Calculate the global index by finding the blox's position in the entire process
            const globalIdx = bloxes.findIndex(b => b.id === bloxId);
            const bloxSVG = bloxSVGs[globalIdx];
            const bloxActions = handleBloxActions(blox.id, section.sectionId, idx);

            return (
              <ProcessBlox
                key={blox.id + idx}
                bloxData={blox}
                bloxSVG={bloxSVG}
                processBloxes={bloxes}
                copiedBloxState={copiedBloxState}
                handleInsertBlox={bloxActions.handleInsertBlox}
                handleInsertModule={bloxActions.handleInsertModule}
                onDelete={bloxActions.onDelete}
                onClickBlox={() => processHandleClickBlox(blox.id)}
                handleSplitSection={createSection.bind(null, section.sectionId, blox.id)}
                hideStack={bloxDisplayProps.hideStack ?? false}
                leftMargin={idx === 0}
                showDropTarget={idx === section.bloxIds.length - 1}
                isSelected={selectedBloxId === blox.id}
                processSections={processSections}
                processIsReadOnly={bloxDisplayProps.processIsReadOnly ?? false}
              />
            );
          })
        ) : (
          getEmptySectionTarget(section.sectionId, sectionIndex)
        )}
      </Row>
    </Box>
  );
};

export default SectionRenderer;
