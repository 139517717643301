import { useEffect, useState, useCallback, RefObject } from 'react';

interface UseVisibleSectionProps {
  containerRef: RefObject<HTMLElement>;
  sections: { sectionId: string }[];
}

/**
 * Hook to track which section is currently visible at the top of the viewport
 * Uses scroll position to efficiently track section header visibility
 */
export const useVisibleSection = ({ containerRef, sections }: UseVisibleSectionProps) => {
  const [visibleSectionId, setVisibleSectionId] = useState<string | null>(null);

  const updateVisibleSection = useCallback(() => {
    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const containerTop = containerRect.top;
    const threshold = 50; // Pixels from top to consider as "visible"
    
    // Find all section headers
    const sectionElements = sections.map(section => 
      document.getElementById(`item-${section.sectionId}`)
    ).filter(Boolean) as HTMLElement[];

    // Find the first section that's near the top of the viewport
    let visibleSection = null;
    
    for (const element of sectionElements) {
      const rect = element.getBoundingClientRect();
      const relativeTop = rect.top - containerTop;
      
      // If section is above the threshold but not too far up
      if (relativeTop <= threshold && relativeTop > -rect.height) {
        visibleSection = element;
        break;
      }
    }

    // If no section is found near the top, find the last section that's above the viewport
    if (!visibleSection) {
      let lastSectionAbove = null;
      for (const element of sectionElements) {
        const rect = element.getBoundingClientRect();
        const relativeTop = rect.top - containerTop;
        
        if (relativeTop <= threshold) {
          lastSectionAbove = element;
        } else {
          break;
        }
      }
      visibleSection = lastSectionAbove;
    }

    if (visibleSection) {
      const sectionId = visibleSection.id.replace('item-', '');
      setVisibleSectionId(sectionId);
    }
  }, [containerRef, sections]);

  // Set up scroll listener with throttling
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateVisibleSection();
          ticking = false;
        });
        ticking = true;
      }
    };

    // Update on scroll
    container.addEventListener('scroll', handleScroll);
    // Update on resize
    window.addEventListener('resize', updateVisibleSection);
    
    // Initial check
    updateVisibleSection();

    return () => {
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateVisibleSection);
    };
  }, [containerRef, updateVisibleSection]);

  return visibleSectionId;
}; 