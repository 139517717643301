import { useCallback } from 'react';

interface ProcessOperationsProps {
  history: any;
  location: any;
  selectedProcessRef: React.MutableRefObject<string | undefined>;
  processContext?: any;
}

/**
 * Provides process operations like loading templates and creating new processes
 */
export function useProcessOperations({
  history,
  location,
  selectedProcessRef,
  processContext
}: ProcessOperationsProps) {
  // Create toast handler for template operations
  const toastHandler = { 
    message: (msg: string) => { /* intentionally left empty for production */ },
    intent: 'success',
    timeout: 3000
  };

  /**
   * Load a process template with URL-based navigation
   */
  const loadTemplate = useCallback((templateId: string) => {
    // Prevent loading the same template that's already selected
    if (templateId === selectedProcessRef.current) {
      return;
    }
    
    // Show loading toast 
    toastHandler.message(`Loading process: ${templateId}`);
    
    // Update the selected process ref immediately to prevent race conditions
    selectedProcessRef.current = templateId;
    
    // IMPORTANT: Force process reload by resetting the lastLoadedProcessId
    // This will ensure the process is reloaded even if the ID is the same
    if (processContext?.actions?.setLastLoadedId) {
      processContext.actions.setLastLoadedId('');
    }
    
    // We'll only update the URL here since that's our source of truth
    // The context will be updated via the URL sync mechanism
    const currentPath = location.pathname;
    const basePath = currentPath.split('/iframe-process/')[0] || '';
    const newPath = `${basePath}/iframe-process/${templateId}`;
    
    // Only update the URL - all other state updates happen as a result of this
    history.replace({
      pathname: newPath,
      search: location.search
    });
  }, [
    history, 
    location.pathname, 
    location.search,
    selectedProcessRef,
    processContext
  ]);

  /**
   * Start a new empty process with URL-based navigation
   */
  const startNewProcess = useCallback(() => {
    // Prevent loading if 'new' is already selected
    if (selectedProcessRef.current === 'new') {
      return;
    }
    
    // Show toast notification
    toastHandler.message('Creating a new process');
    
    // Update the selected process ref immediately to prevent race conditions
    selectedProcessRef.current = 'new';
    
    // We'll only update the URL here since that's our source of truth
    // The context will be updated via the URL sync mechanism
    const currentPath = location.pathname;
    const basePath = currentPath.split('/iframe-process/')[0] || '';
    const newPath = `${basePath}/iframe-process/new`;
    
    // Only update the URL - all other state updates happen as a result of this
    history.replace({
      pathname: newPath,
      search: location.search
    });
  }, [
    history, 
    location.pathname, 
    location.search,
    selectedProcessRef
  ]);

  // Create initialization complete handler
  const handleInitializationComplete = useCallback((processData?: {
    processId: string,
    processName: string,
    processBloxes: any[],
    processSections: any[],
    firstBloxId?: string,
    isReloading?: boolean,
    error?: string
  }, 
  callbacks?: {
    setIsLoading: (isLoading: boolean) => void;
    resetInitialization: () => void;
    setSelectedBloxId: (id: string) => void;
    setInitialScrollTarget: (id: string) => void;
    hasSetInitialBlox: React.MutableRefObject<boolean>;
  },
  viewMode?: string
  ) => {
    if (!callbacks) return;
    const { setIsLoading, resetInitialization, setSelectedBloxId, setInitialScrollTarget, hasSetInitialBlox } = callbacks;
    
    // Only process if for the current selected process or if this is a reloading signal
    if (processData?.processId && 
        processData.processId !== selectedProcessRef.current && 
        !processData.isReloading) {
      return;
    }
    
    // Capture the start time to measure loading performance
    const startTime = performance.now();
    
    // Always set loading state with a slight delay to ensure UI updates
    // This helps avoid flash of loading state for fast operations
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
    
    // Handle errors
    if (processData?.error) {
      // Display error state here if needed
      return;
    }
    
    // Handle process reload signal
    if (processData?.isReloading) {
      // Reset initialization state to prepare for the new data
      resetInitialization();
      return; // Exit early as we're just resetting state, actual data will come in next callback
    }
    
    // Only proceed if we have process data and bloxes
    if (processData && processData.processBloxes && processData.processBloxes.length > 0) {
      // Set the first blox as the initial selection when data is loaded
      if (!hasSetInitialBlox.current && processData.firstBloxId) {
        // Set selected blox ID
        setSelectedBloxId(processData.firstBloxId);
        hasSetInitialBlox.current = true;

        // Set the first blox as the initial scroll target for list view
        if (viewMode === 'list') {
          setInitialScrollTarget(processData.firstBloxId);
        }
      }
    }
  }, [selectedProcessRef]);

  return {
    loadTemplate,
    startNewProcess,
    handleInitializationComplete,
    toastHandler
  };
} 