import { useState, useRef, useEffect, useCallback } from 'react';
import { useUrlParams } from '../IFrameUtils';
import { isInIframe, setupMessageHandler } from '../../../utils/iframeMessaging';
import { sizeConfigs } from '../IFrameStyles';
import { validateViewMode } from '../IFrameProcessUtils';
import { useIFrameUI } from '../IFrameUIContext';
import { ViewMode } from '../hooks/useFabuUIState';

// Create debounce hook for process selection to prevent rapid changes
export const useProcessSelectionDebounce = <T extends (...args: any[]) => any>(
  fn: T, 
  delay: number
) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  
  return useCallback((...args: Parameters<T>) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    
    timeout.current = setTimeout(() => {
      fn(...args);
    }, delay);
  }, [fn, delay]);
};

interface ProcessParamsOutput {
  // The effective process ID to use (from URL or message)
  effectiveProcessId: string | undefined;
  // The signature to use for secure process loading
  signature: string | undefined;
  // The view mode to use for process display
  viewMode: ViewMode;
  // The size configuration to use
  sizeConfig: any;
  // Reference to the currently selected process
  selectedProcessRef: React.MutableRefObject<string | undefined>;
  // Message-based state
  messageProcessId: string | undefined;
  messageSignature: string | undefined;
  messageViewMode: ViewMode | undefined;
  messageSize: 'small' | 'medium' | 'large' | 'full' | undefined;
  // Location and history from router
  location: any;
  history: any;
  // Update methods
  setMessageProcessId: (id: string | undefined) => void;
  setMessageSignature: (signature: string | undefined) => void;
  setMessageViewMode: (mode: ViewMode | undefined) => void;
  setMessageSize: (size: 'small' | 'medium' | 'large' | 'full' | undefined) => void;
  // UI handlers
  handleChangeViewMode: (newMode: string) => void;
  redirectToEmptyProcess: () => void;
}

/**
 * Custom hook to manage process parameters from multiple sources (URL, message, etc.)
 * and provide a unified interface for the viewer component
 */
export function useProcessParameters(): ProcessParamsOutput {
  // URL parameters - primary source of truth
  const { 
    processId: urlProcessId, location, history, 
    viewMode: urlViewMode, signature: urlSignature, sizeConfig: urlSizeConfig,
    updateUrlParam
  } = useUrlParams();
  
  // Get UI context for direct view mode updates
  const { actions: uiActions, state: uiState } = useIFrameUI();
  
  // Message-based parameters - secondary source of truth (for iframes)
  const [messageProcessId, setMessageProcessId] = useState<string | undefined>();
  const [messageSignature, setMessageSignature] = useState<string | undefined>();
  const [messageViewMode, setMessageViewMode] = useState<ViewMode | undefined>();
  const [messageSize, setMessageSize] = useState<'small' | 'medium' | 'large' | 'full' | undefined>();
  
  // Track currently selected process to prevent rapid oscillation
  const selectedProcessRef = useRef<string | undefined>(urlProcessId || messageProcessId);
  
  // Consolidate parameters - URL parameters take precedence over message parameters
  const effectiveProcessId = urlProcessId || messageProcessId;
  const viewMode = urlViewMode || messageViewMode || 'standard';
  const signature = urlSignature || messageSignature;
  const sizeConfig = urlSizeConfig || (messageSize ? sizeConfigs[messageSize] : sizeConfigs.full);
  
  // Keep track of last view mode we synced to prevent loops
  const lastSyncedViewModeRef = useRef<ViewMode | null>(null);
  
  // Sync view mode from URL with UI context on initial load and when URL changes
  useEffect(() => {
    // Skip if we've already processed this view mode or no URL view mode exists
    if (!urlViewMode || lastSyncedViewModeRef.current === urlViewMode) {
      return;
    }
    
    // Validate the view mode
    const validatedMode = validateViewMode(urlViewMode);
    
    // Only update if the mode is different from current state
    if (uiState.viewMode !== validatedMode) {
      // Update our reference before calling setViewMode to prevent loops
      lastSyncedViewModeRef.current = urlViewMode as ViewMode;
      
      // Update the UI context
      uiActions.setViewMode(validatedMode);
    } else {
      // Still update our reference even if no change was needed
      lastSyncedViewModeRef.current = urlViewMode as ViewMode;
    }
  }, [urlViewMode, uiActions, uiState.viewMode]);
  
  // Create debounced setter for process ID
  const debouncedSetMessageProcessId = useProcessSelectionDebounce((processId: string) => {
    setMessageProcessId(processId);
    selectedProcessRef.current = processId;
  }, 200);

  // Setup message handlers for iframe communication
  useEffect(() => {
    if (!isInIframe()) return;
    
    const cleanupMessageHandler = setupMessageHandler({
      onLoadProcess: (processId, signature) => {
        // Avoid rapid oscillation between processes
        if (processId !== selectedProcessRef.current) {
          debouncedSetMessageProcessId(processId);
          
          if (signature) {
            setMessageSignature(signature);
          }
        }
      },
      onChangeViewMode: (viewMode) => {
        // Use updateUrlParam to ensure consistent parameter handling
        updateUrlParam('view', viewMode);
        // Also update message-based state for iframe
        setMessageViewMode(validateViewMode(viewMode));
      },
      onChangeSize: (size) => {
        // Use updateUrlParam to ensure consistent parameter handling
        updateUrlParam('size', size);
        setMessageSize(size as 'small' | 'medium' | 'large' | 'full');
      },
      onStartNewProcess: () => {
        debouncedSetMessageProcessId('new');
      }
    });
    
    return cleanupMessageHandler;
  }, [debouncedSetMessageProcessId, updateUrlParam]);
  
  // Update selectedProcessRef when effectiveProcessId changes
  useEffect(() => {
    if (effectiveProcessId && effectiveProcessId !== selectedProcessRef.current) {
      selectedProcessRef.current = effectiveProcessId;
    }
  }, [effectiveProcessId]);
  
  // Handler for view mode changes - uses URL parameters and UI context as sources of truth
  const handleChangeViewMode = useCallback((newViewMode: string) => {
    // Validate the view mode
    const validatedMode = validateViewMode(newViewMode);
    
    // Always update URL parameters as the primary source of truth
    updateUrlParam('view', validatedMode);
    
    // Update UI context directly to ensure the view changes immediately
    uiActions.setViewMode(validatedMode);
    
    // Also update message-based state if in iframe
    if (isInIframe()) {
      setMessageViewMode(validatedMode);
    }
  }, [updateUrlParam, uiActions]);

  // Function to redirect to empty process if no processId is specified
  const redirectToEmptyProcess = useCallback(() => {
    // Get current path and construct new path with 'new' process ID
    const currentPath = location.pathname;
    const basePath = currentPath.split('/iframe-process/')[0] || '';
    const newPath = `${basePath}/iframe-process/new`;
    
    // Navigate to the empty process while preserving query parameters
    history.replace({
      pathname: newPath,
      search: location.search
    });
  }, [history, location.pathname, location.search]);

  // Check if we need to redirect to an empty process
  useEffect(() => {
    // Exit if we already have an effective process ID
    if (effectiveProcessId) return;
    
    redirectToEmptyProcess();
  }, [effectiveProcessId, redirectToEmptyProcess]);

  return {
    effectiveProcessId,
    signature,
    viewMode: viewMode as ViewMode,
    sizeConfig,
    selectedProcessRef,
    messageProcessId,
    messageSignature,
    messageViewMode,
    messageSize,
    location,    // Include location from useUrlParams
    history,     // Include history from useUrlParams
    setMessageProcessId,
    setMessageSignature,
    setMessageViewMode,
    setMessageSize,
    handleChangeViewMode,
    redirectToEmptyProcess
  };
} 