/**
 * PowerPoint Export Hook
 * 
 * This hook manages the PowerPoint export functionality, including:
 * - SVG generation and conversion
 * - PowerPoint creation
 * - User metrics tracking
 * - Error handling and progress reporting
 */

import { useContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { SvgServiceContext } from './state/svg-service-provider';
import { ProcessSettingsContext } from './state/process-settings-provider';
import { SVGDisplayMode } from '../Services/SVGEngine';
import { exportPowerPoint } from '../utils/export-powerpoint';
import { AllBloxes, BloxTypes } from '../Data/BloxSchema/base-blox';
import { Section } from '../__generated__/Process';
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateUserRequest } from '../__generated__/User';
import { showToast } from '..';
import { Intent } from '@blueprintjs/core';
import { getMaterials, Material } from './material-targets';
import { Color } from '../utils/Color';
import { getDefaultColor } from '../Data/material-mappings';
import { useUpdateUser } from './DataFetching/use-fetch-user';

/**
 * Minimal user type for export functionality
 */
interface ExportUser {
    userId: string;
    metrics: UpdateUserRequest['metrics'];
}

/**
 * Props for the PowerPoint export hook
 */
interface UsePowerPointExportProps {
    processBloxes: AllBloxes[];     // Array of process blocks to export
    processSections: Section[];      // Array of process sections
    processName: string;            // Name of the process
    processId: string;              // Unique identifier for the process
}

/**
 * Return type for the PowerPoint export hook
 */
interface UsePowerPointExportResult {
    isDialogOpen: boolean;          // Controls visibility of export dialog
    setIsDialogOpen: (open: boolean) => void;  // Function to toggle dialog
    handleExport: () => Promise<void>;         // Function to trigger export
}

/**
 * Hook for managing PowerPoint export functionality
 * 
 * @param props - Configuration for the PowerPoint export
 * @returns Object containing dialog state and export handler
 */
export const usePowerPointExport = ({ 
    processBloxes, 
    processSections, 
    processName, 
    processId 
}: UsePowerPointExportProps): UsePowerPointExportResult => {
    // Context and state hooks
    const { generateSvgs } = useContext(SvgServiceContext);
    const { isStack3D } = useContext(ProcessSettingsContext);
    const { user: auth0User } = useAuth0();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const updateUserMutation = useUpdateUser();

    // Convert Auth0 user to minimal export user type
    const exportUser: ExportUser | undefined = auth0User ? {
        userId: auth0User.sub || '',
        metrics: {}
    } : undefined;

    /**
     * Main export handler function
     * Manages the entire export process including:
     * 1. SVG generation
     * 2. SVG to string conversion
     * 3. PowerPoint creation
     * 4. User metrics update
     */
    const handleExport = async () => {
        try {
            // Step 1: Generate SVGs for all process blocks
            const svgs = generateSvgs(processBloxes, isStack3D, SVGDisplayMode.ExportPreview);

            // Get materials for legend
            const materials = getMaterials(processBloxes, processBloxes.length - 1).map(material => ({
                color: Color.getCSS(material.color ?? getDefaultColor(BloxTypes.StartBlox)),
                label: material.materialLabel
            }));

            // Step 2: Convert JSX.Elements to strings
            const svgsResult: { [key: string]: string } = {};
            
            try {
                await Promise.all(svgs.map(async (svg, index) => {
                    try {
                        // Create temporary container for SVG rendering
                        const div = document.createElement('div');
                        div.style.position = 'absolute';
                        div.style.left = '-9999px';
                        div.style.top = '-9999px';
                        document.body.appendChild(div);

                        // Render SVG using React
                        const root = createRoot(div);
                        root.render(svg);

                        // Wait for render and extract SVG string
                        await new Promise<void>((resolve, reject) => {
                            requestAnimationFrame(async () => {
                                try {
                                    const svgElement = div.querySelector('svg');
                                    if (svgElement) {
                                        // Configure SVG for PowerPoint compatibility
                                        svgElement.setAttribute('width', '320');
                                        svgElement.setAttribute('height', '320');
                                        svgElement.setAttribute('preserveAspectRatio', 'xMidYMax meet');

                                        // Serialize SVG with proper XML formatting
                                        const serializer = new XMLSerializer();
                                        let source = serializer.serializeToString(svgElement);
                                        
                                        // Ensure proper XML namespaces in a single pass
                                        const requiredNamespaces = {
                                            'xmlns="http://www.w3.org/2000/svg"': '<svg xmlns="http://www.w3.org/2000/svg"',
                                            'xmlns:xlink="http://www.w3.org/1999/xlink"': '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
                                        };
                                        
                                        Object.entries(requiredNamespaces).forEach(([namespace, replacement]) => {
                                            if (!source.includes(namespace)) {
                                                source = source.replace(/^<svg/, replacement);
                                            }
                                        });

                                        // Add XML declaration and ensure proper encoding
                                        source = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\r\n' + source;

                                        // Convert CSS variables to actual values
                                        source = source.replace(/var\(--[^)]+\)/g, (match) => {
                                            const style = getComputedStyle(document.documentElement);
                                            const value = style.getPropertyValue(match.slice(4, -1));
                                            return value.trim() || '#000000'; // Default to black if not found
                                        });

                                        // // Verify the SVG can be loaded as an image
                                        // await new Promise<void>((resolveImage, rejectImage) => {
                                        //     const img = new Image();
                                        //     img.onload = () => resolveImage();
                                        //     img.onerror = () => rejectImage(new Error('Failed to load SVG image'));
                                        //     img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(source)));
                                        // });

                                        svgsResult[`step_${index}`] = source;
                                        resolve();
                                    } else {
                                        reject(new Error('Failed to find SVG element'));
                                    }
                                } catch (e) {
                                    reject(e);
                                } finally {
                                    // Cleanup
                                    root.unmount();
                                    document.body.removeChild(div);
                                }
                            });
                        });
                    } catch (error) {
                        throw new Error('Failed to process process step');
                    }
                }));

            } catch (error) {
                throw new Error('Failed to process process steps');
            }

            // Validate SVG conversion results
            if (Object.keys(svgsResult).length === 0) {
                throw new Error('Failed to generate process visualizations');
            }

            if (Object.keys(svgsResult).length !== svgs.length) {
                throw new Error('Some process steps could not be processed');
            }

            // Step 3: Generate PowerPoint
            await exportPowerPoint(
                svgsResult,
                processBloxes,
                materials,
                processName,
                processSections,
                processId,
                auth0User?.name || 'Anonymous',
                updateUserMutation,
                exportUser as any // Type assertion to satisfy the function signature
            );

            // Show success message
            showToast({
                message: "PowerPoint file has been generated successfully",
                intent: Intent.SUCCESS,
                timeout: 3000
            });
        } catch (error) {
            const errorMessage = "PowerPoint generation failed. Please contact FabuBlox support if the issue persists.";
            
            // Show user-friendly error message
            showToast({
                message: errorMessage,
                intent: Intent.DANGER,
                timeout: 5000
            });

            // Preserve error for debugging with consistent message
            throw new Error(errorMessage);
        }
    };

    return {
        isDialogOpen,
        setIsDialogOpen,
        handleExport
    };
}; 