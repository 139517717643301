import { Typography } from "@mui/material"
import JobPosting, { KeyInformationItem } from "../../components/Careers/JobPosting"
import { InfoList } from "../../components/Careers/InfoList";
import { WhyJoinFabuBlox } from "../../components/Careers/WhyJoinFabuBlox";
import { ApplicationDialog } from "../../components/Careers/ApplicationDialog";

export function PlatformEngineerPage() {
    const keyInformationItems: KeyInformationItem[] = [
        {
            label: "Location",
            value: "Boston Office or Hybrid",
        },
        {
            label: "Title",
            value: "Platform Engineer",
        },
        {
            label: "Experience",
            value: "4+ years",
        },
    ];

    const aboutTheRole = <><Typography variant="h6" className="tw-font-semibold">
        About the Role
    </Typography>
        <Typography className="tw-my-4">
            As a <strong>Platform Engineer</strong> at FabuBlox, you'll establish the core infrastructure and standards that will scale with our growth, while actively contributing across our entire stack. This is a unique opportunity to shape our technical foundation.
        </Typography>
        <Typography className="tw-my-4">
            This role isn't just about coding—it's about smart, pragmatic engineering that balances immediate product needs with long-term scalability. You'll work directly with the founders to create systems that can handle both individual users and enterprise clients, all while staying nimble enough for a startup environment.
        </Typography>
        <Typography className="tw-my-4">
            FabuBlox is committed to creating a friendly, inclusive and diverse workplace. We encourage applications from underrepresented groups in tech!
        </Typography>
        </>;

    const jobSpecs = <>
        <InfoList
            title="Technical Experience"
            items={[
                "Bachelor's degree in Computer Science, Engineering, or a related field, or equivalent practical experience.",
                "4+ years of experience in software platform engineering or a similar role.",
                <>Experience building scalable backend systems using <strong>Node.js</strong> and <strong>TypeScript</strong>. Or, similar proficiency with an established OOP language (e.g., <strong>Go</strong>, <strong>C#</strong>, <strong>Java</strong>).</>,
                <>Experience designing and implementing RESTful APIs and/or GraphQL services.</>,
                <>Proficiency with relational and NoSQL database systems and data modeling techniques.</>,
                <>Experience with cloud platforms like <strong>AWS</strong>, <strong>Google Cloud</strong>, or <strong>Azure</strong>.</>,
                <>Familiarity with industry standard observability platforms and tooling (e.g., <strong>Prometheus/Grafana</strong>, <strong>Splunk</strong>...).</>,
                <>Solid understanding of containerization technologies (<strong>Docker</strong>, <strong>Kubernetes</strong>).</>,
                "Knowledge of security best practices and compliance standards for cloud-based platforms."
            ]}
        />

        <InfoList
            title="You Will"
            items={[
                "Work across the stack to expose the core platform to the frontend working in React as needed to add UI components to integrate it all.",
                "Set precedent for rapidly creating and deploying new microservices and user-facing APIs.",
                "Design and implement secure, performant data access layers.",
                "Ensure consistency with monitoring, logging, and error handling across the platform.",
                "Drive automation and continuous integration/continuous delivery (CI/CD) pipelines for efficient deployment cycles.",
                "Stay updated with industry trends and advancements to incorporate new technologies that improve the platform.",
                "Participate in code reviews, provide feedback, and mentor junior engineers."
            ]}
        />

        <InfoList
            title="Bonus Points"
            items={[
                <>Experience with infrastructure-as-code tools (e.g., <strong>Terraform</strong>, <strong>CloudFormation</strong>).</>,
                <>Experience designing <strong>extensible platforms</strong> with robust SDKs, webhooks, or integration capabilities.</>,
                <>Prior experience in an <strong>early stage company</strong>.</>,
                <>Knowledge of the <strong>semiconductor</strong>, <strong>nanotechnology</strong>, or adjacent cutting-edge industries.</>
            ]}
        />
    </>;

    const jobPostingProps = {
        keyInformationItems,
        jobTitle: "Platform Engineer"
    };

    return (
        <JobPosting {...jobPostingProps}>
            {aboutTheRole}
            <ApplicationDialog />
            {jobSpecs}
            <WhyJoinFabuBlox />
        </JobPosting>
    );
}