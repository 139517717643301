import { 
  createRef, 
  useEffect, 
  useState 
} from "react";
import {
  InputGroup,
  Popover,
  Button,
  Colors,
  Intent,
  Classes,
  Icon,
  Tooltip,
  PopoverInteractionKind // <-- Import here
} from "@blueprintjs/core";
import { SketchPicker, ColorResult } from "react-color";
import { Material } from "../../hooks/material-targets";
import { Row } from "../../Layout/layouts";
import { Color, IColor } from "../../utils/Color";
import { IconNames } from "@blueprintjs/icons";

interface MaterialItemProps {
  material: Material;
  showMaterialInfo: () => void;
  onRequestMaterialEdit: (newName: string, oldName: string, color: IColor) => void;
  onRequestMaterialSelect: () => void;
  onStartEdit: () => void;
  onCancelEdit: () => void;
  onStartSelect: () => void;
  onCancelSelect: () => void;
  isSelected: boolean;
  isEditing: boolean;
  isDimmed: boolean;
  isCurrent: boolean;
  isPendingSelection: boolean;
}

/** 
 * Main Component 
 */
export default function MaterialItem(props: MaterialItemProps) {
  const {
    material,
    showMaterialInfo,
    onRequestMaterialEdit,
    onRequestMaterialSelect,
    onStartEdit,
    onCancelEdit,
    onStartSelect,
    onCancelSelect,
    isSelected,
    isEditing,
    isDimmed,
    isCurrent,
    isPendingSelection,
  } = props;

  const [tempMaterial, setTempMaterial] = useState<Material>(material);
  const [colorPopoverOpen, setColorPopoverOpen] = useState(false);
  const [copyAnimation, setCopyAnimation] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [initialSelection, setInitialSelection] = useState(true);

  const itemRef = createRef<HTMLInputElement>();

  // ------------------------
  // Effects
  // ------------------------
  useEffect(() => {
    if (!isEditing) {
      // Reset local state if we stop editing
      setTempMaterial(material);
    }
  }, [isEditing, material]);

  useEffect(() => {
    if (isEditing) {
      // Auto-focus if we just became editing
      if (initialSelection) {
        itemRef.current?.focus();
        itemRef.current?.select();
        setInitialSelection(false);
      }
    }
  }, [isEditing, itemRef, initialSelection]);

  // ------------------------
  // Handlers
  // ------------------------
  const handleColorChange = (color: ColorResult) => {
    const newColor = {
      R: color.rgb.r,
      G: color.rgb.g,
      B: color.rgb.b,
      A: color.rgb.a ?? 1,
    };
    setTempMaterial((prev) => ({ ...prev, color: newColor }));
  };

  const handleFinishEdit = () => {
    onRequestMaterialEdit(
      tempMaterial.materialLabel,
      material.materialLabel,
      tempMaterial.color
    );
  };

  const handleCancelEditInternal = () => {
    onCancelEdit();
    setTempMaterial(material);
    setInitialSelection(true);
  };

  const handleItemClick = () => {
    if (!isSelected) {
      onStartSelect();
    }
  };

  const handleColorCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    const hexCode = Color.getHex(material.color!);
    navigator.clipboard.writeText(hexCode);
    setCopyAnimation(true);
    setTimeout(() => setCopyAnimation(false), 200);
  };

  // ------------------------
  // Helpers
  // ------------------------
  const hasChanges = () =>
    tempMaterial.materialLabel !== material.materialLabel ||
    JSON.stringify(tempMaterial.color) !== JSON.stringify(material.color);

  const getWrapperStyles = (): React.CSSProperties => ({
    opacity: isDimmed ? 0.3 : 1,
    pointerEvents: isDimmed ? "none" : "auto",
    borderLeft: isSelected
      ? `4px solid ${Colors.BLUE3}`
      : isCurrent || isPendingSelection
      ? `4px solid ${Colors.GRAY3}`
      : "none",
    padding: "8px",
    marginBottom: "4px",
    cursor: isSelected ? "default" : "pointer",
    minHeight: "46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });

  // ------------------------
  // Sub-Renderers
  // ------------------------
  const renderEditingMode = () => (
    <Row style={{ alignItems: "center", width: "100%" }}>
      <InputGroup
        value={tempMaterial.materialLabel}
        onChange={(e) =>
          setTempMaterial((prev) => ({ ...prev, materialLabel: e.target.value }))
        }
        inputRef={itemRef}
        style={{ marginRight: "auto" }}
      />
      <Popover
        isOpen={colorPopoverOpen}
        content={
          <SketchPicker
            color={Color.getCSS(tempMaterial.color!)}
            onChange={handleColorChange}
          />
        }
        onClose={() => setColorPopoverOpen(false)}
        hasBackdrop
      >
        <div
          style={{
            backgroundColor: Color.getCSS(tempMaterial.color!),
            width: "20px",
            height: "20px",
            cursor: "pointer",
            borderRadius: "4px",
            border: "1px solid #ccc",
            marginLeft: "8px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setColorPopoverOpen(true);
          }}
        />
      </Popover>

      <Button
        minimal
        onClick={handleCancelEditInternal}
        style={{ marginLeft: "auto", marginRight: "5px" }}
        intent={Intent.DANGER}
        text="Cancel"
      />
      <Button
        minimal
        onClick={handleFinishEdit}
        intent={Intent.PRIMARY}
        disabled={!hasChanges()}
        text="Done"
      />
    </Row>
  );

  const renderEditButton = () => {
    if (isSelected && !isEditing && !isPendingSelection) {
      return (
        <Button
          minimal
          icon={<Icon icon={IconNames.EDIT} color={Colors.BLUE3} />}
          onClick={(e) => {
            e.stopPropagation();
            onStartEdit();
          }}
        />
      );
    }
    return null;
  };

  const renderPendingSelectionButtons = () => {
    if (!isPendingSelection) return null;

    return (
      <>
        <span>Switch Material?</span>
        <Button
          minimal
          icon={<Icon icon={IconNames.TICK} color={Colors.GREEN3} />}
          onClick={(e) => {
            e.stopPropagation();
            onRequestMaterialSelect();
          }}
        />
        <Button
          minimal
          icon={<Icon icon={IconNames.CROSS} color={Colors.RED3} />}
          onClick={(e) => {
            e.stopPropagation();
            onCancelSelect();
          }}
        />
      </>
    );
  };

  const renderReadOnlyView = () => {
    const hexCode = Color.getHex(material.color!);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <span style={{ fontWeight: "bold", marginRight: "8px", flexGrow: 1 }}>
          {material.materialLabel}
        </span>
        {renderEditButton()}
        {(!isPendingSelection || !isEditing) && (
          <Button
            minimal
            className={isSelected ? "" : "material-item__info-hover"}
            icon={<Icon icon={IconNames.InfoSign} />}
            onClick={(e) => {
              e.stopPropagation();
              showMaterialInfo();
            }}
          />
        )}
        {renderPendingSelectionButtons()}
        <div style={{width: "6px"}} /> {/* Spacer */}
        {/* Controlled Tooltip around the color circle */}
        <Tooltip
          // Control open state manually
          isOpen={tooltipOpen}
          onInteraction={(nextOpenState) => setTooltipOpen(nextOpenState)}
          interactionKind={PopoverInteractionKind.HOVER}
          position="top"
          hoverCloseDelay={300}
          // The content is now a custom JSX element 
          // that includes the hex code and copy icon:
          content={
            <div
              style={{ 
                display: "flex", 
                alignItems: "center", 
                padding: 8 
              }}
            >
              <span style={{ marginRight: 8 }}>{hexCode}</span>
              <Button
                icon={<Icon color="white" icon={IconNames.Duplicate} />}
                minimal
                onClick={handleColorCopy}
              />
            </div>
          }
        >
          <div
            style={{
              backgroundColor: Color.getCSS(material.color!),
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #ccc",
              cursor: isSelected ? "pointer" : "default",
              transform: copyAnimation ? "scale(1.1)" : "scale(1)",
              transition: "transform 0.2s ease",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (!isSelected) return;
              onStartEdit();
              setColorPopoverOpen(true);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  // ------------------------
  // Main Render
  // ------------------------
  return (
    <div
      className={isSelected || isPendingSelection ? Classes.CARD : "material-item__wrapper"}
      style={getWrapperStyles()}
      onClick={handleItemClick}
    >
      {isEditing ? renderEditingMode() : renderReadOnlyView()}
    </div>
  );
}
