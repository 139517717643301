import { useState, useCallback } from 'react';
import { AllBloxes } from '../../../Data/BloxSchema/base-blox';

/**
 * Props for the useStepNavigation hook
 * @interface UseStepNavigationProps
 * @property {AllBloxes[]} bloxes - Array of bloxes to navigate through
 * @property {number} [initialIndex] - Initial step index
 */
interface UseStepNavigationProps {
    bloxes: AllBloxes[];
    initialIndex?: number;
}

/**
 * Hook for managing step navigation in the export dialog
 * @param {UseStepNavigationProps} props - Hook props
 * @returns {Object} Navigation state and handlers
 */
export const useStepNavigation = ({ bloxes, initialIndex = 0 }: UseStepNavigationProps) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handleNext = useCallback(() => {
        if (currentIndex < bloxes.length - 1) {
            setCurrentIndex(prev => prev + 1);
        }
    }, [currentIndex, bloxes.length]);

    const handlePrevious = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex(prev => prev - 1);
        }
    }, [currentIndex]);

    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        // Ignore key events if focus is in an input element
        if (document.activeElement?.tagName === 'INPUT' || 
            document.activeElement?.tagName === 'SELECT' || 
            document.activeElement?.tagName === 'TEXTAREA') {
            return;
        }

        const key = event.key;
        if (key === 'ArrowRight' || key === 'ArrowDown') {
            event.preventDefault(); // Prevent default scrolling and event bubbling
            event.stopPropagation();
            handleNext();
        } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
            event.preventDefault(); // Prevent default scrolling and event bubbling
            event.stopPropagation();
            handlePrevious();
        }
    }, [handleNext, handlePrevious]);

    return {
        currentIndex,
        handleNext,
        handlePrevious,
        handleKeyPress
    };
}; 