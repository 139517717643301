import React from 'react';
import { 
  Box, 
  Chip, 
  TextField, 
  InputAdornment, 
  IconButton 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface TemplateFiltersProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  categories: string[];
  isMobile: boolean;
}

/**
 * TemplateFilters component for filtering and searching templates
 */
export const TemplateFilters: React.FC<TemplateFiltersProps> = ({
  searchQuery,
  setSearchQuery,
  selectedCategory,
  setSelectedCategory,
  categories,
  isMobile
}) => {
  return (
    <>
      {/* Search and filter row */}
      <Box sx={{ 
        p: 0.75,
        pb: 0.5,
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? 0.5 : 1,
        alignItems: isMobile ? 'stretch' : 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        animation: 'fadeInDelayed 0.3s ease-in-out',
        animationDelay: '0.1s',
        '@keyframes fadeInDelayed': {
          '0%': {
            opacity: 0
          },
          '100%': {
            opacity: 1
          }
        }
      }}>
        {/* Search bar */}
        <TextField
          placeholder="Search templates..."
          size="small"
          fullWidth={isMobile}
          sx={{ 
            backgroundColor: '#fff',
            flex: isMobile ? 'auto' : 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              height: '32px',
            },
            '& .MuiInputBase-input': {
              padding: '6px 10px',
            }
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: searchQuery ? (
              <InputAdornment position="end">
                <IconButton 
                  size="small" 
                  onClick={() => setSearchQuery('')}
                  edge="end"
                  aria-label="Clear search"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />

        {/* Category filters for desktop */}
        {!isMobile && (
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            <Chip 
              label="All"
              size="small"
              color={selectedCategory === null ? "primary" : "default"} 
              variant={selectedCategory === null ? "filled" : "outlined"}
              onClick={() => setSelectedCategory(null)}
              sx={{ 
                fontWeight: selectedCategory === null ? 600 : 400,
                height: '24px',
                '& .MuiChip-label': { px: 1 }
              }}
            />
            {categories.map(category => (
              <Chip 
                key={category}
                label={category}
                size="small"
                color={selectedCategory === category ? "primary" : "default"}
                variant={selectedCategory === category ? "filled" : "outlined"}
                onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
                sx={{ 
                  fontWeight: selectedCategory === category ? 600 : 400,
                  height: '24px',
                  '& .MuiChip-label': { px: 1 }
                }}
              />
            ))}
          </Box>
        )}
      </Box>

      {/* Mobile category filters */}
      {isMobile && (
        <Box sx={{ 
          display: 'flex', 
          gap: 0.5,
          flexWrap: 'wrap', 
          px: 1.5,
          pt: 0,
          pb: 1,
          overflow: 'auto' 
        }}>
          <Chip 
            label="All"
            size="small"
            color={selectedCategory === null ? "primary" : "default"} 
            variant={selectedCategory === null ? "filled" : "outlined"}
            onClick={() => setSelectedCategory(null)}
            sx={{ 
              height: '24px',
              '& .MuiChip-label': { px: 1 }
            }}
          />
          {categories.map(category => (
            <Chip 
              key={category}
              label={category}
              size="small"
              color={selectedCategory === category ? "primary" : "default"}
              variant={selectedCategory === category ? "filled" : "outlined"}
              onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
              sx={{ 
                height: '24px',
                '& .MuiChip-label': { px: 1 }
              }}
            />
          ))}
        </Box>
      )}
    </>
  );
}; 