import React from 'react';
import { HTMLSelect, Button, Popover, NumericInput, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useExportDialogState } from '../hooks/state/export-dialog-provider';

// Base delay in ms
const BASE_FRAME_DELAY = 80;

// Convert internal format to display format
const getDisplayFormat = (format: string) => {
    switch (format) {
        case "GIF":
            return "GIF (Process)";
        case "WEBM":
            return "Video (Process)";
        default:
            return format;
    }
};

/**
 * Props for the ExportFormatControls component
 * @interface ExportFormatControlsProps
 */
interface ExportFormatControlsProps {
    onFormatChange: (format: "PNG" | "SVG" | "GIF" | "WEBM") => void;
    onResolutionChange: (resolution: "Normal" | "High") => void;
    onToggleShowText: () => void;
    onToggleStepInfo: () => void;
    resolution: "Normal" | "High";
    showText: boolean;
    showStepInfo: boolean;
    speedMultiplier: number;
    onSpeedMultiplierChange: (multiplier: number) => void;
}

/**
 * Component for controlling export format settings
 * @component
 * @param {ExportFormatControlsProps} props - Component props
 */
export const ExportFormatControls: React.FC<ExportFormatControlsProps> = ({
    onFormatChange,
    onResolutionChange,
    onToggleShowText,
    onToggleStepInfo,
    resolution,
    showText,
    showStepInfo,
    speedMultiplier,
    onSpeedMultiplierChange
}) => {
    const { state: { fileFormat } } = useExportDialogState();

    const SpeedControls = (
        <div style={{ padding: '10px' }}>
            <NumericInput
                min={0.1}
                max={10}
                stepSize={0.1}
                value={speedMultiplier}
                onValueChange={(value) => onSpeedMultiplierChange(value)}
                style={{ width: '80px' }}
            />
            <div style={{
                fontSize: 'var(--font-size-base)',
                color: 'var(--text-secondary)',
                fontFamily: 'var(--font-primary)',
                fontWeight: 'var(--font-weight-medium)',
                marginTop: '5px',
                marginBottom: '8px'
            }}>
                {Math.round(BASE_FRAME_DELAY / speedMultiplier)} ms per frame
            </div>
        </div>
    );

    return (
        <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '24px',
            width: '100%'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '24px'
            }}>
                <label style={{
                    marginLeft: '20px',
                    color: 'var(--text-primary)',
                    fontSize: 'var(--font-size-base)',
                    fontWeight: 'var(--font-weight-medium)',
                    fontFamily: 'var(--font-primary)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    whiteSpace: 'nowrap'
                }}>
                    Format:
                    <HTMLSelect 
                        value={getDisplayFormat(fileFormat)}
                        options={["PNG", "SVG", "GIF (Process)", "Video (Process)"]} 
                        onChange={(e) => {
                            const format = e.target.value;
                            const internalFormat = format === "GIF (Process)" ? "GIF" : 
                                                 format === "Video (Process)" ? "WEBM" : 
                                                 format;
                            onFormatChange(internalFormat as "PNG" | "SVG" | "GIF" | "WEBM");
                        }}
                        style={{
                            borderRadius: '6px',
                            border: `1.5px solid rgba(var(--black-rgb), 0.12)`,
                            fontSize: 'var(--font-size-base)'
                        }}
                    />
                </label>

                {(fileFormat === "PNG" || fileFormat === "SVG") && (
                    <label style={{
                        margin: 0,
                        color: 'var(--text-primary)',
                        fontSize: 'var(--font-size-base)',
                        fontWeight: 'var(--font-weight-medium)',
                        fontFamily: 'var(--font-primary)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        whiteSpace: 'nowrap'
                    }}>
                        Resolution:
                        <HTMLSelect 
                            value={resolution}
                            options={["Normal", "High"]} 
                            onChange={(e) => onResolutionChange(e.target.value as "Normal" | "High")}
                            style={{
                                borderRadius: '6px',
                                border: `1.5px solid rgba(var(--black-rgb), 0.12)`,
                                fontSize: 'var(--font-size-base)'
                            }}
                        />
                    </label>
                )}

                {(fileFormat === "GIF" || fileFormat === "WEBM") && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Popover content={SpeedControls} position={Position.BOTTOM}>
                            <Button
                                icon={IconNames.FAST_FORWARD}
                                style={{ 
                                    borderRadius: '6px',
                                    backgroundColor: 'var(--background-lighter)',
                                    height: '30px',
                                    minHeight: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 'var(--font-size-base)',
                                    padding: '0 10px'
                                }}
                                text={`${speedMultiplier}x`}
                            />
                        </Popover>
                    </div>
                )}
            </div>

            <div style={{ 
                display: 'flex', 
                gap: '8px',
                marginLeft: 'auto'
            }}>
                {(fileFormat === "PNG" || fileFormat === "SVG") && (
                    <Button
                        icon={IconNames.TEXT_HIGHLIGHT}
                        onClick={onToggleShowText}
                        style={{ 
                            borderRadius: '6px',
                            backgroundColor: showText ? 'var(--accent-purple-light)' : 'var(--background-lighter)',
                            height: '30px',
                            minHeight: '30px',
                            fontSize: 'var(--font-size-base)',
                            padding: '0 10px',
                            marginRight: '20px'
                        }}
                        minimal
                    >
                        {showText ? 'Hide Labels' : 'Show Labels'}
                    </Button>
                )}
            </div>
        </div>
    );
}; 