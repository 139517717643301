import React from 'react';
import { 
  Card, 
  CardContent, 
  CardActions, 
  Chip, 
  Box, 
  Typography 
} from '@mui/material';
import { ProcessTemplate } from './types';

interface TemplateCardProps {
  template: ProcessTemplate;
  index: number;
  isSelected: boolean;
  selectedCategory: string | null;
  onSelectTemplate: (template: ProcessTemplate) => void;
  onSelectCategory: (category: string) => void;
}

/**
 * TemplateCard component for displaying individual template cards
 */
export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  index,
  isSelected,
  selectedCategory,
  onSelectTemplate,
  onSelectCategory
}) => {
  return (
    <Card 
      key={template.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '140px',
        height: '160px',
        margin: '4px',
        flexShrink: 0,
        outline: isSelected ? '2px solid #1976d2' : 'none',
        borderRadius: '8px',
        transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
        animation: 'cardFadeIn 0.4s ease-in-out',
        animationDelay: `${0.05 * index}s`,
        '@keyframes cardFadeIn': {
          '0%': {
            opacity: 0,
            transform: 'translateY(10px)'
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)'
          }
        },
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f9f9ff'
        },
        position: 'relative',
        overflow: 'visible',
        cursor: 'pointer'
      }}
      onClick={() => onSelectTemplate(template)}
      elevation={1}
    >
      <CardContent sx={{
        padding: '10px',
        paddingBottom: '32px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        gap: '6px'
      }}>
        {/* Icon */}
        <Box sx={{
          width: '32px',
          height: '32px',
          backgroundColor: template.category === selectedCategory ? '#e3f2fd' : '#f0f4ff',
          color: template.category === selectedCategory ? '#1976d2' : 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          mb: 0.5,
          flexShrink: 0,
          '& .MuiSvgIcon-root': {
            fontSize: '18px',
          }
        }}>
          {template.icon}
        </Box>
        
        {/* Title */}
        <Typography 
          variant="subtitle2"
          align="center" 
          sx={{ 
            fontWeight: template.category === selectedCategory ? 600 : 500,
            color: '#333',
            fontSize: '0.8rem',
            lineHeight: 1.2,
            mb: 0.5,
            width: '100%',
            flexShrink: 0,
          }}
        >
          {template.name}
        </Typography>
        
        {/* Description */}
        <Typography 
          variant="body2" 
          color="textSecondary" 
          align="center" 
          sx={{
            overflow: 'hidden',
            maxHeight: '4.2em', // Allow around 3 lines of text
            fontSize: '0.75rem',
            lineHeight: 1.4,
            color: '#666',
            width: '100%',
            mb: 0.5,
            flexShrink: 1,
          }}
        >
          {template.description}
        </Typography>
      </CardContent>
      <CardActions 
        disableSpacing
        sx={{
          justifyContent: 'center',
          borderTop: '1px solid rgba(0, 0, 0, 0.06)',
          p: 0,
          m: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '28px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
      >
        <Chip 
          label={template.category} 
          size="small" 
          variant={template.category === selectedCategory ? "filled" : "outlined"}
          color={template.category === selectedCategory ? "primary" : "default"}
          sx={{ 
            fontSize: '0.65rem',
            fontWeight: template.category === selectedCategory ? 600 : 400,
            height: '20px',
            '& .MuiChip-label': { px: 0.75 }
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelectCategory(template.category);
          }}
        />
      </CardActions>
    </Card>
  );
}; 