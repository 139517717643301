/**
 * @fileoverview ProcessListViewHeader is a navigation component that provides section-based navigation
 * through a breadcrumb interface. It maintains a sticky position at the top of the ProcessListView
 * and provides visual feedback for the current section.
 * 
 * Key Features:
 * - Sticky header positioning
 * - Breadcrumb-based section navigation
 * - Visual section state feedback
 * - Smooth scrolling to sections
 * - Responsive layout
 * 
 * Component Hierarchy:
 * ProcessListView
 * └─ ProcessListViewHeader (This Component)
 *    └─ SectionBreadcrumbs
 *       └─ Section Navigation Items
 * 
 * State Flow:
 * 1. Receives section states from ProcessListView
 * 2. Manages breadcrumb interactions
 * 3. Triggers section visibility updates
 * 4. Updates active section indication
 * 
 * @component
 */

import React from 'react';
import { Box } from '@mui/material';
import SectionBreadcrumbs from '../SectionBreadcrumbs';
import { processListViewStyles } from './styles/ProcessListView.styles';
import { Section } from '../../__generated__/Process';

/**
 * Props interface for the ProcessListViewHeader component.
 * Defines the properties required for section navigation and state management.
 * 
 * @interface ProcessListViewHeaderProps
 * 
 * @property {Section[]} sections - Array of sections to display in the navigation
 * @property {{ [key: string]: boolean }} closedSections - Map of section IDs to their collapsed state
 * @property {React.RefObject<HTMLDivElement>} containerRef - Reference to the main container for scroll management
 * @property {(sectionId: string) => void} onSectionClick - Callback when a section is selected
 * @property {string | null} visibleSectionId - ID of the currently visible section
 */
interface ProcessListViewHeaderProps {
  sections: Section[];
  closedSections: { [key: string]: boolean };
  containerRef: React.RefObject<HTMLDivElement>;
  onSectionClick: (sectionId: string) => void;
  visibleSectionId: string | null;
}

/**
 * ProcessListViewHeader Component
 * Provides navigation and section management through a breadcrumb interface.
 * 
 * Features:
 * - Sticky positioning at the top of the view
 * - Section-based navigation through breadcrumbs
 * - Visual feedback for current section
 * - Smooth scrolling to selected sections
 * - Responsive layout adaptation
 * 
 * @component
 */
export const ProcessListViewHeader: React.FC<ProcessListViewHeaderProps> = ({
  sections,
  closedSections,
  containerRef,
  onSectionClick,
  visibleSectionId
}) => (
  <Box sx={processListViewStyles.header.container}>
    <Box sx={processListViewStyles.header.content}>
      <SectionBreadcrumbs
        sections={sections}
        closedSections={closedSections}
        containerRef={containerRef}
        onSectionClick={onSectionClick}
        activeSection={visibleSectionId}
      />
    </Box>
  </Box>
);

export default ProcessListViewHeader;
