/**
 * @fileoverview Provides state management for process data within the IFrame application.
 * This context manages the core process data including process details, sections, and bloxes.
 * It implements a reducer pattern for state updates and provides a set of action creators
 * for components to interact with process data. This is a fundamental part of the state 
 * management system and is used in conjunction with IFrameUIContext.
 * 
 * This context now syncs with useFabuState to ensure useFabuState remains the source of truth.
 */

import React, { createContext, useContext, useReducer, useCallback, useEffect, useState } from 'react';
import { useFabuState } from '../../hooks/state/use-fabu-state';

// Define the process state interface
export interface IFrameProcessState {
  processId: string | null;
  processName: string;
  processBloxes: any[];
  processSections: any[];
  isLoading: boolean;
  error: string | null;
  lastLoadedProcessId: string | null;
}

// Define action types
type ProcessAction =
  | { type: 'SET_PROCESS_ID', payload: string }
  | { type: 'SET_LOADING', payload: boolean }
  | { type: 'SET_PROCESS_DATA', payload: { processBloxes: any[], processSections: any[], processName: string } }
  | { type: 'SET_ERROR', payload: string | null }
  | { type: 'CLEAR_PROCESS' }
  | { type: 'SET_LAST_LOADED_ID', payload: string }
  | { type: 'SYNC_WITH_FABU_STATE', payload: { processId: string | null, processName: string, processBloxes: any[], processSections: any[], isLoading: boolean } };

// Initial state
const initialState: IFrameProcessState = {
  processId: null,
  processName: '',
  processBloxes: [],
  processSections: [],
  isLoading: false,
  error: null,
  lastLoadedProcessId: null
};

// Reducer function
function processReducer(state: IFrameProcessState, action: ProcessAction): IFrameProcessState {
  switch (action.type) {
    case 'SET_PROCESS_ID':
      return { ...state, processId: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_PROCESS_DATA':
      return {
        ...state,
        processBloxes: action.payload.processBloxes,
        processSections: action.payload.processSections,
        processName: action.payload.processName,
        isLoading: false
      };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'CLEAR_PROCESS':
      return {
        ...state,
        processBloxes: [],
        processSections: [],
        processName: '',
        error: null
      };
    case 'SET_LAST_LOADED_ID':
      return { ...state, lastLoadedProcessId: action.payload };
    case 'SYNC_WITH_FABU_STATE':
      return {
        ...state,
        processId: action.payload.processId,
        processName: action.payload.processName,
        processBloxes: action.payload.processBloxes,
        processSections: action.payload.processSections,
        isLoading: action.payload.isLoading
      };
    default:
      return state;
  }
}

// Create context
interface IFrameProcessContextValue {
  state: IFrameProcessState;
  dispatch: React.Dispatch<ProcessAction>;
  actions: {
    setProcessId: (id: string) => void;
    setLoading: (isLoading: boolean) => void;
    setProcessData: (data: { processBloxes: any[], processSections: any[], processName: string }) => void;
    setError: (error: string | null) => void;
    clearProcess: () => void;
    setLastLoadedId: (id: string) => void;
  };
}

const IFrameProcessContext = createContext<IFrameProcessContextValue | undefined>(undefined);

// Provider component
export function IFrameProcessProvider({ children }: { children: React.ReactNode }) {
  // Use a reducer for local context state
  const [state, dispatch] = useReducer(processReducer, initialState);
  
  // Maintain loading state locally
  const [localIsLoading, setLocalIsLoading] = useState(false);

  // Get state from useFabuState - call hooks at the component level
  const [fabuProcessId, setFabuProcessId] = useFabuState('processId');
  const [fabuProcessName, setFabuProcessName] = useFabuState('processName');
  const [fabuProcessBloxes, setFabuProcessBloxes] = useFabuState('processBloxes');
  const [fabuProcessSections, setFabuProcessSections] = useFabuState('processSections');
   
  // Create action functions that update useFabuState
  const actions = {
    setProcessId: useCallback((id: string) => {
      setFabuProcessId(id);
      dispatch({ type: 'SET_PROCESS_ID', payload: id });
    }, [setFabuProcessId]),
    
    setLoading: useCallback((isLoading: boolean) => {
      // Store loading state locally
      setLocalIsLoading(isLoading);
      dispatch({ type: 'SET_LOADING', payload: isLoading });
    }, []),
    
    setProcessData: useCallback((data: { processBloxes: any[], processSections: any[], processName: string }) => {
      setFabuProcessBloxes(data.processBloxes);
      setFabuProcessSections(data.processSections);
      setFabuProcessName(data.processName);
      
      // When setting process data, we're no longer loading
      setLocalIsLoading(false);
      
      dispatch({ type: 'SET_PROCESS_DATA', payload: data });
    }, [setFabuProcessBloxes, setFabuProcessSections, setFabuProcessName]),
    
    setError: useCallback((error: string | null) => {
      // When setting an error, we're no longer loading
      setLocalIsLoading(false);
      dispatch({ type: 'SET_ERROR', payload: error });
    }, []),
    
    clearProcess: useCallback(() => {
      setFabuProcessBloxes([]);
      setFabuProcessSections([]);
      setFabuProcessName('');
      
      dispatch({ type: 'CLEAR_PROCESS' });
    }, [setFabuProcessBloxes, setFabuProcessSections, setFabuProcessName]),
    
    setLastLoadedId: useCallback((id: string) => {
      dispatch({ type: 'SET_LAST_LOADED_ID', payload: id });
    }, [])
  };

  // Keep context state synchronized with useFabuState
  useEffect(() => {
    // Only update context if values are different, to prevent loops
    if (fabuProcessId !== state.processId || 
        fabuProcessName !== state.processName ||
        fabuProcessBloxes !== state.processBloxes ||
        fabuProcessSections !== state.processSections ||
        localIsLoading !== state.isLoading) {
      
      dispatch({
        type: 'SYNC_WITH_FABU_STATE',
        payload: {
          processId: fabuProcessId,
          processName: fabuProcessName,
          processBloxes: fabuProcessBloxes,
          processSections: fabuProcessSections,
          isLoading: localIsLoading
        }
      });
    }
  }, [fabuProcessId, fabuProcessName, fabuProcessBloxes, fabuProcessSections, localIsLoading, state]);

  return (
    <IFrameProcessContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </IFrameProcessContext.Provider>
  );
}

// Custom hook for using the context
export function useIFrameProcess() {
  const context = useContext(IFrameProcessContext);
  if (context === undefined) {
    throw new Error('useIFrameProcess must be used within an IFrameProcessProvider');
  }
  return context;
} 